import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Box, Grid } from "@mui/material";

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

function Editors({ onChange, value, placeholder, error, defaultValue }) {
  return (
    <React.Fragment>
      <Helmet title="Editors" sx={{ borderRadius: "20px" }} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Box mt={4} alignItems="center">
            <QuillWrapper style={{ borderRadius: "20px" }}>
              <ReactQuill
                style={{
                  height: "auto",
                  width: "auto",
                  borderRadius: "8px",
                }}
                theme="snow"
                value={value}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
                defaultValue={defaultValue}
              />
            </QuillWrapper>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Editors;
