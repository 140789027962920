import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  TextField,
  Card,
  Checkbox,
  Avatar,
  Tooltip,
  tooltipClasses,
  CircularProgress,
  Alert,
} from "@mui/material";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";

import InfoIcon from "@mui/icons-material/Info";

import { Dialog } from "@material-ui/core";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import CoverImageModal from "./SingleImageModal/CoverImageModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import noImageGray from "../../../../../../assets/images/noImageGray.png";
import noImageGray from "../../../../../../../assets/images/noImageGray.png";
import addImage from "../../../../../../../assets/images/download.png";
import { styled } from "@mui/system";
import { SettingsOutlined } from "@mui/icons-material";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CoverImages = ({
  title,
  tooltipText,
  imagesArray,
  handleOpenModal,
  handleDeleteImage,
  alignment,
  locationId,
  handleClose,
  open,
  loading,
  setTouchedValue,
  category,
  validation,
  identifier,
  setTitle,
  handleOpenPreviewImage,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputFileRef = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const [displayError, setDisplayError] = useState(false);

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };

  const onFileChangeCapture = (e) => {
    const selectedFile = e.target.files[0];

    let tempImages = [];
    if (selectedFile) {
      const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (allowedImageTypes.includes(selectedFile.type)) {
        tempImages.push({
          picture: selectedFile,
          picturePreview: URL.createObjectURL(selectedFile),
        });

        handleOpenModal(tempImages);
      } else {
        setDisplayError(true);
        setTimeout(() => {
          setDisplayError(false);
        }, 5000);
      }
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    let tempImages = [];
    tempImages.push({
      picture: e.dataTransfer.files[0],
      picturePreview: URL.createObjectURL(e.dataTransfer.files[0]),
    });

    handleOpenModal(tempImages);
  };

  return (
    <>
      <Grid container sx={{ padding: "18px" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box sx={{ display: "flex" }}>
            <Typography className={classes.modalHeader}>{title}</Typography>
            <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{tooltipText}</h1>}
            >
              <IconButton>
                <InfoIcon
                  sx={{
                    fontSize: "large",
                    paddingBottom: "3px",
                    color: "#8D9298",
                  }}
                />
              </IconButton>
            </BootstrapTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography className={classes.modalSubheader}>
            {t("image_guide")}
          </Typography>
        </Grid>
        {displayError && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Alert mt={2} mb={3} severity="error">
              {t("logo_error")}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ cursor: "pointer" }}>
          {imagesArray?.length === 0 ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                paddingTop: "18px",
                paddingBottom: "18px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  width: "258px",
                  // /    border: "2px dashed #E0E0E0", /
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "166px",
                  borderWidth: "2px",
                  borderRadius: "1rem",
                  borderStyle: "dashed",
                  borderColor: "#cbd5e1",

                  // height: "70vh",
                }}
              >
                <form
                  className={classes.formUpload}
                  onDragEnter={handleDrag}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <label
                    className={classes.lableFileUpload}
                    htmlFor={`file-${identifier}`}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <AddAPhotoOutlinedIcon
                            sx={{
                              height: "40px",
                              width: "40px",
                              cursor: "pointer",
                              color: "#8D9298",
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: "14px",
                              marginTop: "8px",
                              fontWeight: "400",
                              color: "#495059",
                            }}
                          >
                            {t("Uploading_Images_Text")}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "14px",
                              marginTop: "8px",
                              fontWeight: "400",
                              color: "#495059",
                            }}
                          >
                            {t("or_key")}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "14px",
                              marginTop: "8px",
                              fontWeight: "400",
                              color: "#495059",
                            }}
                          >
                            {t("browse_file")}
                          </Typography>
                        </Box>
                      </>
                    )}
                    <input
                      id={`file-${identifier}`}
                      type="file"
                      accept=".jpg, .png"
                      multiple={false}
                      ref={inputFileRef}
                      onChange={onFileChangeCapture}
                      style={{ display: "none" }}
                    />
                  </label>
                  {dragActive && (
                    <div
                      className={classes.dragFile}
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
              </Box>
            </Grid>
          ) : (
            /*   <Box
              className={classes.addImageAvatar}
              onClick={() => handleOpenModal()}
            >
              <AddAPhotoOutlinedIcon
                sx={{
                  height: "79.38px",
                  width: "91.67px",
                  cursor: "pointer",
                  color: "#8D9298",
                }}
              />

            </Box> */
            imagesArray?.length > 0 &&
            imagesArray?.map((item, index) => {
              return (
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  sx={{ position: "relative" }}
                >
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      display: "inline-block",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Avatar
                      variant="square"
                      className={classes.imageAvatar}
                      onClick={() => {
                        setTitle(title);
                        handleOpenPreviewImage(imagesArray, false);
                      }}
                    >
                      <LazyLoadImage
                        loading="lazy"
                        src={loadImg ? noImageGray : item?.sourceUrl}
                        beforeLoad={handleBeforeLOad}
                        afterLoad={handleAfterLOad}
                        style={{
                          height: "80px",
                          width: "80px",
                        }}
                        PlaceholderSrc={noImageGray}
                        alt="Image Alt"
                        effect="blur"
                      />
                    </Avatar>
                    <IconButton
                      className={classes.imageCrossIcon2}
                      onClick={() => handleDeleteImage(item, index)}
                    >
                      <CloseOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#FFFF" }}
                      />
                    </IconButton>
                  </div>
                </Grid>
              );
            })
          )}
        </Grid>
      </Grid>

      {/*  <CoverImageModal
        locationId={locationId}
        handleClose={handleClose}
        open={open}
        loading={loading}
        setImagesUploadData={setImagesUploadData}
        imagesUploadData={imagesUploadData}
        handleImagesShow={handleImagesShow}
        setTouchedValue={setTouchedValue}
        alreadyAddedImages={alreadyAddedImages}
        setAlreadyAddedImages={setAlreadyAddedImages}
        category={category}
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
        validation={validation}
        // handlePictureUpload={handleProfilePictureUpload}
        // setPostData={setPostData}
        // handleCloseNavigate={handleCloseNavigate}
        // postData={postData}
        // images={images}
        // setImages={setImages}
      /> */}
    </>
  );
};

export default CoverImages;
