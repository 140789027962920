import React from "react";
import styled, { withTheme } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { Card as MuiCard, CardContent, CardHeader, Grid } from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const DoughnutChart = ({ difficulty, theme }) => {
  const { t } = useTranslation();

  return (
    <Card mb={6} sx={{ height: "500px" }}>
      <CardHeader title={t("Difficulty_update")} />
      <CardContent>
        <Grid container width="200px">
          <Grid item justifyContent={"center"}>
            <CircularProgressbar
              justifyContent="center"
              value={difficulty}
              text={`${difficulty}%`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withTheme(DoughnutChart);
