import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

import React, { useState } from "react";
import { api } from "../../../contexts/JWTContext";
import { useNavigate, useLocation } from "react-router-dom";

import SearchField from "../textfields/searchfield/SearchField";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CommonButton from "../Buttons/CommonButton";

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
const CommonSearch = () => {
  const [reportLoading, setReportLoading] = React.useState(false);
  const [searchData, setSearchData] = useState(null);
  const [domain, setDomain] = useState("domain");
  const [domainRequired, setDomainRequired] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const onChangeSearch = (e) => {
    setSearchData(e.target.value);
  };
  const handleChange = (event) => {
    setDomainRequired(false);
    setDomain(event.target.value);
  };

  const createReport = async () => {
    const validity = validURL(searchData);
    if (!validity) {
      toast.error("PLease enter a valid URL");
      return;
    }

    if (!domain) {
      setDomainRequired(true);
      return;
    }

    if (searchData && domain) {
      setReportLoading(true);

      try {
        const res = await api.post(`/seo/backlink-reports`, {
          mode: domain,
          target: searchData,
        });

        if (res.status === 200 && res?.data?.data?.length > 0) {
          setReportLoading(true);

          location.pathname === "/user/backlink"
            ? navigate(`/user/backlink/${res?.data?.data[0]?.reportId}`)
            : navigate(`/user/backlink/${res?.data?.data[1]?.reportId}`);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setReportLoading(false);
        setDomainRequired(false);
      }
    }
  };
  return (
    <>
      <Grid container spacing={2} marginBottom="20px">
        <Grid item xs={4} sm={4} md={1.5}>
          <FormControl style={{ background: "white" }} fullWidth>
            <InputLabel id="demo-simple-select-label">
              {" "}
              {t("Domain")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              //  value={domain}
              label="Domain"
              defaultValue={"domain"}
              onChange={handleChange}
              error={domainRequired}
            >
              <MenuItem value={"domain"}>{t("Domain")}</MenuItem>
              <MenuItem value="host">{t("Host")}</MenuItem>
              <MenuItem value="url">{t("Url")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={5} sm={5} md={9.5}>
          <SearchField
            filterByName={onChangeSearch}
            onSubmit={createReport}
            placeHolder={t("SearchDomian")}
          />
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={1}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CommonButton
            onSubmit={createReport}
            label={t("Search")}
            variant="contained"
            color="primary"
            // size="large"
            loading={reportLoading}
          >
            {t("DomainSearchButton")}
          </CommonButton>
        </Grid>
      </Grid>
    </>
  );
};

export default CommonSearch;
