import React from "react";
import { TableBody } from "@mui/material";

import CustomizedTableCell from "../../../../../../components/CustomComponents/table/tableCell";
import CustomizedTableHead from "../../../../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../../../../components/CustomComponents/table/tableRow";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useStyles } from "../../../Style/style";
const NoDataRatingBreakdown = ({ headCells, handleDisplayName, locations }) => {
  const classes = useStyles();
  return (
    <>
      <CustomizedTableHead>
        <CustomizedTableRow>
          {headCells.map((headCell) => (
            <CustomizedTableCell
              sx={{ fontWeight: 700 }}
              key={headCell.id}
              align={headCell.alignment}
            >
              {headCell.label}
            </CustomizedTableCell>
          ))}
        </CustomizedTableRow>
      </CustomizedTableHead>

      <TableBody>
        {locations?.map((item) => {
          return (
            <>
              <CustomizedTableRow sx={{ fontWeight: "700px" }} tabIndex={-1}>
                <CustomizedTableCell align="left" sx={{ width: "300px" }}>
                  {handleDisplayName(item?.id)}
                </CustomizedTableCell>

                <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                  {"0"}

                  <ArrowForwardIcon className={classes.orangeIconClass} />
                </CustomizedTableCell>
                <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                  {"0"}
                  <ArrowForwardIcon className={classes.orangeIconClass} />
                </CustomizedTableCell>
                <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                  {"0"}
                  <ArrowForwardIcon className={classes.orangeIconClass} />
                </CustomizedTableCell>
                <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                  {"0"}
                  <ArrowForwardIcon className={classes.orangeIconClass} />
                </CustomizedTableCell>
                <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
                  {"0"}
                  <ArrowForwardIcon className={classes.orangeIconClass} />
                </CustomizedTableCell>
              </CustomizedTableRow>
            </>
          );
        })}
      </TableBody>
    </>
  );
};

export default NoDataRatingBreakdown;
