import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Tooltip,
  tooltipClasses,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { TablePagination } from "@mui/material";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../components/Loaders/Loader";
import styled from "styled-components/macro";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import ReadMore from "../../../../components/Models/ReadMore";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import Checkbox from "@mui/material/Checkbox";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Title from "../../../../components/CustomComponents/title/index.js";
import NotFound from "../../../../components/NotFound/NotFound";
import useAuth from "../../../../hooks/useAuth";
import { useStyles } from "../Styles/style";
const Paper = styled(MuiPaper)(spacing);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="bottom"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const PrivateReviews = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { signOut, permissionsAcess } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteTask, setDeleteTask] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteTasks, setDeleteTasks] = useState([]);
  const [selectAll, setSelectAll] = useState(null);
  const [openReadModal, setOpenReadModal] = React.useState(false);
  const [allPrivateData, setAllPrivateData] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [reviewText, setReviewText] = useState("");
  const [showFullText, setShowFullText] = useState(false);

  const [viewPermission, setViewPermission] = useState(false);
  //  const [addPermission, setAddPermission] = useState(false);
  // const [editPermission, setEditPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const params = useParams();
  const { id } = params;
  const slicedReview = showFullText ? reviewText : "";
  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/review"
      );
      // setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.forEach((item) => {
        /*   if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        } */
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDeleteTasks(id);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { t } = useTranslation();

  const handleClose = () => {
    setOpenModal(false);
    //  setOpenDeleteModal(false);
    setOpen(false);
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    //  setOpenDeleteModal(false);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleDeleteTask = async (id) => {
    try {
      const res = await api.patch(`campaign/bulkdelete-campaigndatausers`, {
        campaignDataUserIds: [id],
        campaignId: parseInt(params.id),
      });
      if (res.status === 200) {
        setOpen(false);

        setDeleteTask([]);
        toast.success("Deleted successfully");
        getSelectedCampaign();
      }
    } catch (error) {}
  };

  const handleOpenDeleteModel = () => {
    // setOpen(deleteTask);
    // openDeleteModal(deleteTask);
    setAnchorEl(null);
    setOpenModal(true);
  };
  const handleOpenModel = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleDeleteSelectedTasks = async () => {
    try {
      const res = await api.patch(`campaign/bulkdelete-campaigndatausers`, {
        campaignDataUserIds: deleteTask,
        campaignId: parseInt(params.id),
      });

      if (res.status === 200) {
        getSelectedCampaign();
        toast.success("Deleted successfully");
        setOpenModal(false);
        setDeleteTask([]);
        // setOpenDeleteModal(false);
        setSelectAll(false);

        setDeleteTask([]);
      }
    } catch (error) {
      setDeleteTask([]);
      toast.error(t("Campaign_Delete_Failed"));
    }
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };

  const getSelectedCampaign = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `/campaign/campaignUsers?campaignId=${id}&pageNumber=${
          page + 1
        }&limit=${rowsPerPage}`
      );
      if (res.status === 200) {
        setAllPrivateData(res?.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    let dummyArr = [];
    if (e.target.checked) {
      for (var i = 0; i < allPrivateData?.result?.length; i++) {
        deleteTask.push(allPrivateData?.result[i]?.id);
        dummyArr?.push(allPrivateData?.result[i]?.id);
      }

      const uniqueTasks = new Set(deleteTask);

      setDeleteTask([...uniqueTasks]);
    } else {
      setDeleteTask([]);
    }
    /*  if (deleteTask?.length===dummyArr?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    } */
  };

  const handleTaskChecked = (e, index, taskId) => {
    if (e.target.checked) {
      deleteTask.push(taskId);
    } else {
      var taskIndex = deleteTask.indexOf(taskId);
      deleteTask.splice(taskIndex, 1);
    }
    setDeleteTask([...deleteTask]);
    if (allPrivateData?.result?.length === deleteTask?.length) {
      //  setDeleteTask([]);
      setSelectAll(true);
    } else {
      setSelectAll(false);
      // setDeleteTask(allPrivateData);
    }
  };

  const handleSeeMore = (rowReview) => {
    setReviewText(rowReview);
    setShowFullText(true);
    setOpenReadModal(true);
  };
  useEffect(() => {
    getSelectedCampaign();
  }, [rowsPerPage, page]);

  const handleCloseReadMore = () => {
    setOpenReadModal(false);
  };
  return (
    <>
      <Title
        title={`${allPrivateData !== null ? allPrivateData?.count : "0"} ${t(
          "Private_Reviews"
        )}`}
        subHeading={t("List_of_reviews")}
        showButton={true}
        navigateToReviewsCampaigns={true}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {deleteTask.length > 0 &&
            (viewPermission && deletePermission ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.editMainGrid}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    sx={{
                      color: "white",
                      backgroundColor: "#FC3652",
                      "&:hover": {
                        backgroundColor: "#ff3366",
                        cursor: "pointer",
                      },
                    }}
                    onClick={handleOpenDeleteModel}
                  >
                    {t("Delete")}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.editMainGrid}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <Button
                        sx={{
                          backgroundColor: "#e0e0e0",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#e0e0e0",
                            cursor: "pointer",
                          },
                        }}
                        // onClick={handleOpenDeleteModel}
                      >
                        {t("Delete")}
                      </Button>
                    </span>
                  </BootstrapTooltip>
                </Grid>
              </Grid>
            ))}
          {allPrivateData !== null && allPrivateData?.result?.length > 0 ? (
            <>
              <Grid container spacing={8} sx={{ marginTop: "1rem" }}>
                <TableContainer
                  borderRadius="12"
                  component={Paper}
                  marginLeft="2rem"
                >
                  <Table aria-label="simple table">
                    <CustomizedTableHead variant="h1">
                      <CustomizedTableRow>
                        <CustomizedTableCell align="start">
                          <Checkbox
                            checked={selectAll}
                            onChange={(e) => handleChangeCheckButton(e)}
                          />
                        </CustomizedTableCell>
                        <CustomizedTableCell align="start">
                          {t("IDD")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>{t("Nameee")}</CustomizedTableCell>
                        <CustomizedTableCell>{t("Email")}</CustomizedTableCell>
                        <CustomizedTableCell>
                          {t("Phone_No")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>{t("Reach")}</CustomizedTableCell>

                        <CustomizedTableCell>
                          {t("Ratingg")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>{t("Review")}</CustomizedTableCell>
                        <CustomizedTableCell>
                          {t("Actionss")}
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    </CustomizedTableHead>
                    <TableBody>
                      {allPrivateData?.result?.map((row, index) => (
                        <CustomizedTableRow>
                          <CustomizedTableCell hover>
                            <Checkbox
                              checked={deleteTask.includes(row.id)}
                              onChange={(e) => {
                                handleTaskChecked(e, index, row.id);
                              }}
                              label=""
                            />
                          </CustomizedTableCell>
                          <CustomizedTableCell hover align="start">
                            {" "}
                            {row?.id !== null ? row?.id : "--"}
                          </CustomizedTableCell>
                          <CustomizedTableCell>
                            {row?.name !== null &&
                            row?.name !== "" &&
                            row?.name !== undefined
                              ? row?.name
                              : "--"}
                          </CustomizedTableCell>
                          <CustomizedTableCell>
                            {row?.email !== null &&
                            row?.email !== "" &&
                            row?.email !== undefined
                              ? row?.email
                              : "--"}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="left">
                            {row?.phoneNumber !== null &&
                            row?.phoneNumber !== "" &&
                            row?.phoneNumber !== undefined
                              ? row?.phoneNumber
                              : "--"}
                          </CustomizedTableCell>
                          <CustomizedTableCell
                            align="left"
                            sx={{
                              color:
                                row?.survey_reach === "pending"
                                  ? "#F8A92B"
                                  : "" || row?.survey_reach === "positive"
                                  ? "#13CF8F"
                                  : "" || row?.survey_reach === "negative"
                                  ? "#FC3652"
                                  : "",
                            }}
                          >
                            {row?.survey_reach === "positive"
                              ? t("Positive")
                              : row?.survey_reach === "pending"
                              ? t("Pendingg")
                              : t("Negative")}
                          </CustomizedTableCell>
                          <CustomizedTableCell>
                            {row?.rating !== null &&
                            row?.rating !== "" &&
                            row?.rating !== undefined
                              ? row?.rating
                              : "--"}
                          </CustomizedTableCell>
                          <CustomizedTableCell>
                            {row?.review !== null &&
                            row?.review !== "" &&
                            row?.review !== undefined ? (
                              row?.review.length <= 15 ? (
                                row?.review
                              ) : (
                                <>
                                  {row?.review.slice(0, 15)}...
                                  <span
                                    style={{
                                      color: "#06BDFF",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleSeeMore(row?.review)}
                                  >
                                    {t("Read_more")}
                                  </span>
                                </>
                              )
                            ) : (
                              "--"
                            )}
                          </CustomizedTableCell>

                          <CustomizedTableCell align="end">
                            <IconButton
                              onClick={(e) => handleClick(e, row?.id)}
                              variant="outlined"
                            >
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              {viewPermission && deletePermission ? (
                                <MenuItem
                                  onClick={() => {
                                    // handleOpenDeleteModel();
                                    handleOpenModel();

                                    // handleDeleteSelectedTasks(row?.id);
                                    // handleOpenModal(row?.id);
                                  }}
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: "#545353",
                                      paddingRight: "5px",
                                    }}
                                  />
                                  <Typography aria-label="delete" size="large">
                                    {t("Delete")}
                                  </Typography>
                                </MenuItem>
                              ) : (
                                <BootstrapTooltip
                                  title={t("authorized_access")}
                                >
                                  <span>
                                    <MenuItem disabled>
                                      <DeleteIcon
                                        sx={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                        }}
                                      />
                                      <Typography
                                        aria-label="delete"
                                        size="large"
                                      >
                                        {t("Delete")}
                                      </Typography>
                                    </MenuItem>
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </Menu>
                          </CustomizedTableCell>
                        </CustomizedTableRow>
                      ))}
                    </TableBody>
                    {/* <Dialog
                      //fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <Delete
                        title={t("Delete_Task")}
                        description={t("del_task_subtitle")}
                        onConfirm={() => handleDeleteTask(deleteTask)}
                        onCancel={handleClose}
                      />
                    </Dialog> */}
                  </Table>
                </TableContainer>
              </Grid>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allPrivateData?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Box sx={{ marginTop: "3rem", marginInline: "auto" }}>
              <NotFound text={t("No_Private_Reviews")} />
            </Box>
          )}
        </>
      )}
      {openModal && (
        <Dialog
          // fullScreen={fullScreen}
          open={openModal}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Selected_Campaign")}
            description={t("delete_campaign_selected")}
            // loading={loading}
            onConfirm={handleDeleteSelectedTasks}
            // onConfirm={() => isLoading ?  <> <CircularProgress/> </> : handleDeleteBulkTasks(deleteTasks)}
            onCancel={handleClose}
          />
        </Dialog>
      )}
      {open && (
        <Dialog
          // fullScreen={fullScreen}
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Selected_Campaign")}
            description={t("delete_campaign_selected")}
            // loading={loading}
            // onConfirm={handleDeleteTask}
            onConfirm={() => handleDeleteTask(deleteTasks)}
            // onConfirm={() => isLoading ?  <> <CircularProgress/> </> : handleDeleteBulkTasks(deleteTasks)}
            onCancel={handleCloseModal}
          />
        </Dialog>
      )}

      {/* </Paper> */}
      {/* <PrivateReviewRead
        openReadModal={openReadModal}
        setOpenReadModal={setOpenReadModal}
        slicedReview={slicedReview}
      /> */}
      {openReadModal && (
        <Dialog
          fullScreen={fullScreen}
          open={openReadModal}
          onClose={handleCloseReadMore}
          aria-labelledby="responsive-dialog-title"
        >
          <ReadMore
            title={t("Review")}
            description={slicedReview}
            onCancel={handleCloseReadMore}
          />
        </Dialog>
      )}
    </>
  );
};

export default PrivateReviews;
