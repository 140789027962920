import React from "react";
import LocationForm from "./Components/LocationForm";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import TitleHeader from "../../../../components/CustomComponents/title";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

function Index() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title="Profile" />
      <TitleHeader title={t("Ad_Location")} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <LocationForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Index;
