// ** React Imports ================================================================
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { languagesData } from "../../../../assets/defaultLanguage";

// ** File Imports ================================================================
import { api } from "../../../../contexts/JWTContext";
import ShowTemplates from "./createTemplateCard";
import TitleHeader from "../../../../components/CustomComponents/title";
import Loader from "../../../../components/Loaders/Loader";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { useMediaQuery } from "@mui/material";
// ** Material ui Imports ================================================================
import {
  Box,
  Grid,
  Paper,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  Divider as MuiDivider,
  IconButton,
  Dialog,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { spacing } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useStyles } from "../styles/style";
import styled from "styled-components/macro";
import Autocomplete from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import useAuth from "../../../../hooks/useAuth";
// ** Other Imports ================================================================
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Divider = styled(MuiDivider)(spacing);

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const CreateTemplate = () => {
  // ** Others ================================================================
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const { signOut } = useAuth();

  // ** States ================================================================

  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [dataArray, setDataArray] = useState([{ text: "", language: "en" }]);
  const [title, setTile] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [checkRequired, setCheckRequired] = useState(false);
  const [editData, setEditData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [titleRequired, setTitleRequired] = useState(false);
  const [companyIdRequired, setCompanyIdRequired] = useState(false);
  const [defaultTemplate, setDefaultTemplate] = useState([]);
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getAllDefaultTemplate();
    // deleteTemplates();
  }, []);
  useEffect(async () => {
    getAllCompanies();
  }, []);
  // ** Handler Functions ================================================================
  // ** Helper Function to remove th last word of on curly brace is removed ============================
  function removeLastWord(str) {
    const lastIndexOfSpace = str.lastIndexOf(" ");

    if (lastIndexOfSpace === -1) {
      return str;
    }

    return str.substring(0, lastIndexOfSpace);
  }
  const getAllDefaultTemplate = async () => {
    try {
      const res = await api.get(`/template/default`);
      if (res.status === 200) {
        setDefaultTemplate(res?.data?.data);
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  // ** Change handler for text language and personalize based on index
  const changeHandler = (e, index, isLanguage, isReviewer, currentRef) => {
    let tempArray = [...dataArray];
    let tempData = { ...dataArray[index] };

    // Checking language and converting to code ============================
    const { value } = e.target;

    let tempValueForDelete = value;

    // CHecking for one which selctebox was clicked ============================
    if (isLanguage === true) {
      tempData["language"] = value;
    } else {
      if (isReviewer === true) {
        let textBeforeCursorPosition = tempData["text"].substring(
          0,
          currentRef.current.selectionStart
        );
        let textAfterCursorPosition = tempData["text"].substring(
          currentRef.current.selectionStart,
          tempData["text"].length
        );

        tempData["text"] =
          textBeforeCursorPosition +
          " " +
          value +
          " " +
          textAfterCursorPosition;
        currentRef.current.selectionStart =
          currentRef.current.selectionStart + value.length + 2;
        // tempData["text"] = `${tempData["text"]} ${value}`;
      } else {
        if (tempValueForDelete && tempValueForDelete?.slice(-1) === "}") {
          tempData["text"] = removeLastWord(tempValueForDelete);
        } else {
          tempData["text"] = value;
        }
      }
    }

    tempArray.splice(index, 1, tempData);
    setDataArray(tempArray);
  };

  //** Delete Handler ============================
  const deleteTemplate = (index) => {
    let tempArray = [...dataArray];
    tempArray.splice(index, 1); // 2nd parameter means remove one item only
    setDataArray(tempArray);
  };

  //** Add Handler ============================
  const handleAdd = () => {
    const data = { text: "", language: "" };
    setDataArray([...dataArray, data]);
  };

  //** Save all templates to database ============================
  const saveTemplateHandler = async () => {
    let tempRequiredCount = 0;

    dataArray.map((item) => {
      if (item.text.length < 1 || item.language.length < 1) {
        tempRequiredCount++;
      }
    });

    // CHecking for one english template ============================
    const result = dataArray.filter((item) => item.language === "en");
    if (result.length <= 0) {
      toast.warn(t("Language_selection_msg"));
      return;
    }

    // CHecking if all the required fields are filled call api ============================
    if (tempRequiredCount === 0 && result.length > 0) {
      if (title?.length < 1) {
        setTitleRequired(true);
      }
      if (companyId === null) {
        setCompanyIdRequired(true);
      } else {
        setIsLoading(true);

        const data = {
          title: title,
          data: dataArray,
          companyId: companyId?.id,
        };

        try {
          const res = await api.post(`/template`, data);
          if (res.status === 200) {
            navigate("/admin/template");
            toast.success("Templates added successfully");
            setIsLoading(false);
          }
        } catch (error) {
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      }
    } else {
      setCheckRequired(!checkRequired);
    }
  };
  const handleOpenTranslationMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTranslationMenu = () => {
    setOpenDefaultModel(false);
  };
  const translateDescription = async () => {
    let reqObject = {};

    // CHecking for one english template ============================
    const result = dataArray.filter((item) => item.language === "en");
    if (result.length <= 0 || result[0]?.text?.length === 0) {
      toast.warn(t("English_description_msg"));
      setIsLoading(false);
      setOpenDefaultModel(false);
      return;
    } else {
      reqObject["text"] = result[0]?.text;
      setIsLoading(false);
      setOpenDefaultModel(false);
    }
    setIsLoading(true);

    reqObject["language"] = "en";

    reqObject["languageArray"] = [];
    languagesData.map((item) => {
      const result = dataArray.filter(
        (filterItem) => filterItem.language === item?.value
      );

      if (result?.length === 0) {
        reqObject["languageArray"].push(item?.value);
      }
    });

    // CHecking if all the required fields are filled call api ============================

    try {
      const res = await api.patch(
        `/template/translateInAllLanguges`,
        reqObject
      );
      if (res.status === 200) {
        setDataArray(dataArray.concat(res?.data?.data));
        // let tempArray = [...dataArray, res?.data];
        toast.success("Translated successfully");

        setIsLoading(false);
        setOpenDefaultModel(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
      setOpenDefaultModel(false);
    }
  };
  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/admin/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
      }
    } catch (error) {}
  };

  const handleCompanyChange = (option) => {
    setCompanyId(option);
    //  setLocationId(null);
  };

  return (
    <>
      {openDefaultModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openDefaultModel}
          onClose={handleCloseTranslationMenu}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Translate_descriptions")}
            description={t("Translation_modal")}
            onConfirm={translateDescription}
            onCancel={handleCloseTranslationMenu}
          />
        </Dialog>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TitleHeader
            title={t("Create_Template_head")}
            subHeading={t("sub_Update_Template")}
            showButton={true}
            isLoading={isLoading}
            createButton={true}
            name={t("Translate_lang_desc")}
            onClick={handleOpenTranslationMenu}
            // editData={editData}
            addPermission={true}
            viewPermission={true}
          />

          <Paper style={{ height: "100%", boxShadow: "none" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={4} md={4}>
                <Box sx={{ ml: isMobile ? 0 : 2 }}>
                  {editData ? (
                    <>
                      <Grid sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "14px",
                            color: "#1B2430",
                            marginBottom: "8px",
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          {title}
                        </Typography>
                        <CustomWidthTooltip title={t("Edit_Title")}>
                          <IconButton
                            aria-label="edit"
                            sx={{ fontSize: "14px" }}
                            onClick={() => {
                              setEditData(!editData);
                            }}
                          >
                            {" "}
                            <Edit />
                          </IconButton>
                        </CustomWidthTooltip>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "14px",
                            color: "#1B2430",
                            marginBottom: "8px",
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          {t("Title")}{" "}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <TextField
                            id="standard-basic"
                            // label="Standard"
                            rows={1}
                            maxRows={10}
                            style={{
                              // border: "1px solid #E0E0E0",
                              fontFamily: "Segoe UI",
                              fontStyle: "Normal",
                              //  padding: "12px 12px 12px 12px",
                              width: "100%",

                              borderRadius: "8px",
                              //  minHeight: "30px",
                              color: "#1B2430",
                              fontWeight: "400",
                              fontSize: "16px",
                              //   padding: "15px",
                            }}
                            label={null}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                setEditData(false);
                              }
                            }}
                            value={title}
                            error={titleRequired}
                            onChange={(event) => {
                              if (event.target.value.length === 0) {
                                setTitleRequired(true);
                              } else {
                                setTitleRequired(false);
                              }

                              setTile(event.target.value);
                            }}
                            multiline
                            fullWidth
                            /*   InputProps={{
                    disableUnderline: true,
                  }} */
                            inputProps={{ maxLength: 1000 }}
                            /*   InputProps={{
          inputComponent: TextareaAutosize,
        }} */
                          />
                          {/*  <TextareaAutosize
                            id="filled-multiline-static"
                            multiline
                            style={{
                              border: "1px solid #E0E0E0",
                              fontFamily: "Segoe UI",
                              fontStyle: "Normal",
                              padding: "12px 12px 12px 12px",
                              width: "100%",

                              borderRadius: "8px",
                              minHeight: "30px",
                              color: "#1B2430",
                              fontWeight: "400",
                              fontSize: "16px",
                              //   padding: "15px",
                            }}
                            error={titleRequired}
                            disabled={isLoading}
                            required
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                setEditData(false);
                              }
                            }}
                            value={title}
                            onChange={(event) => {
                              setTitleRequired(false);
                              setTile(event.target.value);
                            }}
                            //fullWidth
                            variant="outlined"
                          /> */}
                          <IconButton
                            aria-label="done"
                            size="large"
                            onClick={() => {
                              setEditData(!editData);
                            }}
                            disabled={!title}
                          >
                            {" "}
                            <CheckIcon />
                          </IconButton>
                        </Box>
                        {titleRequired && (
                          <Typography
                            sx={{
                              color: "#d32f2f",
                              fontWeight: 400,
                              fontSize: "0.6964285714285714rem",
                              lineHeight: "1.66",
                              textAlign: "left",
                              marginTop: "3px",
                              marginRight: "14px",
                              marginBottom: 0,
                              marginLeft: "14px",
                            }}
                          >
                            {t("title_req")}
                          </Typography>
                        )}
                        <Grid container lg={11}>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "14px",
                              color: "#1B2430",
                              marginBottom: "8px",
                              marginTop: "10px",
                            }}
                          >
                            {t("Company")}
                          </Typography>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={
                              allCompanies?.results?.length > 0
                                ? allCompanies?.results
                                : []
                            }
                            getOptionLabel={(option) => option?.name}
                            className="Autocomplete-field"
                            defaultValue={companyId}
                            onChange={(e, value) => {
                              handleCompanyChange(value);
                              if (value) {
                                setCompanyIdRequired(false);
                              } else {
                                setCompanyIdRequired(true);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                style={{ width: "100%" }}
                                placeholder={t("Select_Company")}
                                {...params}
                                required
                                helperText={
                                  companyIdRequired && "Company is required"
                                }
                                name="language"
                                error={companyIdRequired}
                              />
                            )}
                          ></Autocomplete>
                        </Grid>
                      </Box>
                    </>
                  )}

                  {/* <CustomWidthTooltip title="Edit Title">
                      <IconButton
                        aria-label="edit"
                        size="large"
                        onClick={() => {
                          setEditData(true);
                        }}
                      >
                        {" "}
                        <Edit />
                      </IconButton>
                    </CustomWidthTooltip> */}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} lg={8} md={8}>
                <Grid container>
                  {dataArray?.map((item, index) => {
                    return (
                      <>
                        <ShowTemplates
                          item={item}
                          index={index}
                          changeHandler={changeHandler}
                          deleteTemplate={deleteTemplate}
                          checkRequired={checkRequired}
                          defaultTemplate={defaultTemplate}
                          dataArray={dataArray}

                          // languageRequired={languageRequired}
                        />
                      </>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={6} lg={6}></Grid>
                <Grid item md={0.6} lg={0.6}></Grid>
                <Grid item xs={12} sm={12} md={5.4} lg={5.4}>
                  <IconButton
                    onClick={handleAdd}
                    // loading={loading}
                    displayWhite="true"
                  >
                    <AddIcon sx={{ color: "#0638C1", fontWeight: "700" }} />{" "}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#0638C1",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      {t("Add_More_Description")}{" "}
                    </Typography>
                  </IconButton>
                </Grid>
              </Grid>
              <Divider my={4} />

              {/*  <Grid spacing={2} container>
                <Grid item md={3}></Grid>
                <Grid item md={2.3}></Grid>
              </Grid> */}
            </Grid>

            <Grid container sx={{ justifyContent: "flex-end" }}>
              <CommonButton
                displayWhite="true"
                // onSubmit={onCancel}
                onSubmit={() => {
                  navigate(-1);
                }}
                label={t("Cancel")}
              />

              <CommonButton
                label={t("Save_Template")}
                onSubmit={saveTemplateHandler}
                loading={isLoading}
              >
                {t("Save")}
              </CommonButton>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
};

export default CreateTemplate;
