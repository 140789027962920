export const channelNames = [
  { typeName: "Apple Maps", type: "APPLE_MAPS" },
  { typeName: "Bing", type: "BING" },
  { typeName: "TripAdvisor", type: "TRIP_ADVISOR" },
  { typeName: "Yelp", type: "YELP_API" },
  { typeName: "Foursquare", type: "FOURSQUARE" },
  { typeName: "Trustpilot", type: "TRUSTPILOT" },
  { typeName: "Nextdoor", type: "NEXT_DOOR" },
  { typeName: "Waze", type: "WAZE" },
  { typeName: "Here", type: "HERE_EV" },
  { typeName: "Cylex", type: "CYLEX" },
  { typeName: "Hotfrog", type: "HOTFROG" },
  { typeName: "Brownbook", type: "BROWNBOOK" },
  { typeName: "Infobel", type: "INFOBEL" },
  { typeName: "Alexa", type: "ALEXA" },
  { typeName: "iGlobal", type: "I_GLOBAL" },
];
