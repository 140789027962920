import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import logo from "../../../assets/Logo/logoblack.svg";
import { api } from "../../../contexts/JWTContext";
import { Paper, Typography } from "@mui/material";
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Menu as MenuIcon } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import RegisterComponent from "./registerForm";
import NavbarLanguagesDropdown from "../../../components/navbar/NavbarLanguages";
import { useStyles } from "../styles/styles";
import { languageOptions } from "../../../assets/defaultLanguage";
// import { ReactComponent as Logo } from "../../assets/Logo/Logo.svg";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 256.67px;
//   height: 80.25px;
//   margin-bottom: 18px;
//   // margin-top: 28.87px;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 28px;
    height: 28px;
  }
`;

function SignUp({ onDrawerToggle }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { token } = useParams();
  const [tokenAlreadyUsedError, setTokenAlreadyUsedError] = useState(false);
  const [tokenExpiredError, setTokenExpiredError] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [emailAlreadyUsedError, setEmailAlreadyUsedError] = useState(false);
  const [openSuccessModel, setOpenSuccessModel] = useState(false);
  const [invalidTokenError, setInvalidTokenError] = useState(false);
  const [userLanguage, setUserLanguage] = React.useState();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
  });
  const { i18n } = useTranslation();
  const stateData = JSON.parse(localStorage.getItem("user"));
  const languageData = localStorage.getItem("i18nextLng");
  let userLang = "";

  let selectedLanguage = "";
  selectedLanguage = languageOptions[userLang];

  useEffect(() => {
    getUserAcceptLinkAuth();
  }, []);

  const getUserAcceptLinkAuth = async () => {
    // setLoading(true);

    try {
      const res = await api.get(`user/acceptInvitation/${token}`);
      if (res.status === 201) {
        // setLoading(false);
        if (res?.data?.data?.email) {
          setEmailDisabled(true);
          setUserInfo({
            ...userInfo,
            email: res?.data?.data?.email,
          });
        } else {
          setEmailDisabled(false);
        }
      }
    } catch (error) {
      if (error?.response?.data?.message === "Token already used") {
        setTokenAlreadyUsedError(true);
      }

      if (
        error?.response?.data?.message === "Token has expired" ||
        (error?.response?.data?.message === "jwt expired" &&
          error?.response?.status === 500)
      ) {
        setTokenExpiredError(true);
      }

      if (error?.response?.data?.message === "invalid token") {
        setInvalidTokenError(true);
      }
    }
  };

  return (
    <React.Fragment>
      {/* <Brand /> */}

      {invalidTokenError ||
      tokenAlreadyUsedError ||
      tokenExpiredError ? null : (
        <>
          <img
            alt=""
            src={logo}
            alt="logo"
            style={{
              width: "256.67px",
              height: "100.81px",
              marginBottom: "18px",
              top: "83.32px",
              left: "27.68px",
            }}
          />
          <Wrapper>
            <Helmet title="Sign Up" />
            <Grid container alignItems="center" sx={{ padding: "3px" }}>
              <Grid item sx={{ display: { xs: "block", md: "none" } }}>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs />
              <Grid item>
                <NavbarLanguagesDropdown />
              </Grid>
            </Grid>
            <Typography
              component="h1"
              className={classes.signupHead}
              gutterBottom
            >
              {t("Get_started")}
            </Typography>
            <Typography component="h2" className={classes.signupsubHead}>
              {t("user_register")}
            </Typography>

            {emailAlreadyUsedError && (
              <Grid container className={classes.mainContainerUserRegister}>
                <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                  <ErrorOutlineIcon className={classes.errorIconUserRegister} />
                </Grid>
                <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className={classes.errorTextUserRegister}>
                        {"This email already exists."}
                      </Typography>

                      {/* ))} */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <RegisterComponent
              openSuccessModel={openSuccessModel}
              setOpenSuccessModel={setOpenSuccessModel}
              setTokenAlreadyUsedError={setTokenAlreadyUsedError}
              setTokenExpiredError={setTokenExpiredError}
              setInvalidTokenError={setInvalidTokenError}
              token={token}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              emailAlreadyUsedError={emailAlreadyUsedError}
              setEmailAlreadyUsedError={setEmailAlreadyUsedError}
              emailDisabled={emailDisabled}
            />
          </Wrapper>
        </>
      )}

      {tokenExpiredError && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
          open={tokenExpiredError}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <WarningAmberIcon sx={{ fontSize: "70px", color: "red" }} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "35px",
                    lineHeight: "60px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("invitation_link_error")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("invitation_link_error_msg")}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("contact_admin")}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}

      {tokenAlreadyUsedError && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
          open={tokenAlreadyUsedError}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <ErrorOutlineIcon sx={{ fontSize: "70px", color: "#F8A92B" }} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "35px",
                    lineHeight: "60px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("Invitation_link_already_used")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("Invitation_link_already_used_msg")}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("contact_admin")}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}

      {invalidTokenError && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
          open={invalidTokenError}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <WarningAmberIcon sx={{ fontSize: "70px", color: "red" }} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "35px",
                    lineHeight: "60px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("invalid_link")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("invalid_token_msg")}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("contact_admin")}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}

export default SignUp;
