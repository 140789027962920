import React, { useState, useEffect } from "react";

import styled, { withTheme } from "styled-components/macro";

import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";

import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../../../../../contexts/JWTContext";
import Loader from "../../../../../components/Loaders/Loader";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Select } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Alert as MuiAlert,
  Button,
  TextField,
  Tooltip,
  tooltipClasses,
  Box,
  IconButton,
} from "@mui/material";
import { languagesData } from "../../../../../assets/defaultLanguage";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import { makeStyles } from "@mui/styles";
import { alpha } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  Mainbox: {
    padding: "24px",
  },
  searchIcon: {
    bgcolor: "background.paper",

    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
  },
}));
const Alert = styled(MuiAlert)(spacing);
const LocationForm = ({
  setIsLoading,
  isLoading,
  singleLocation,
  getLocationById,
  getSingleLocation,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [CompanyData, setCompanyData] = useState({});
  const [message, setMessage] = useState("");
  const [selectLanguage, setSelectLanguage] = useState("");
  const [SelectDefaultLocation, setDefaultLocation] = useState(false);
  const [companyRequired, setCompanyRequired] = useState(false);
  const [displayCompanyError, setDisplayCompanyError] = useState(false);

  const [partnerAccount, setPartnerName] = React.useState([]);

  useEffect(() => {
    if (singleLocation?.defaultLanguage) {
      const userDefaultLanguage = languagesData?.find(
        (item) => item?.value === singleLocation?.defaultLanguage
      );
      setSelectLanguage(userDefaultLanguage?.value);
    }
  }, [singleLocation]);
  const partner = [];

  useEffect(() => {
    if (displayCompanyError === true) {
      if (companyId === null) {
        setCompanyRequired(true);
      } else {
        setCompanyRequired(false);
      }
    }
  }, [displayCompanyError]);
  useEffect(() => {
    setCompanyId(singleLocation?.company);
  }, []);
  useEffect(async () => {
    try {
      const res = await api.get(`/company`);
      if (res.status === 200) {
        setCompanyData(res.data.data);
      }
    } catch (error) {}
  }, []);

  var companyIds = [];
  let partnerAccount1 = partnerAccount.toString();
  const getSelectedCompanyId = companyIds
    .map((i) => i.name)
    .indexOf(partnerAccount1);
  //Select Partner Field End*******************************************
  const [checked, setChecked] = React.useState(false);
  const [companyId, setCompanyId] = useState(null);

  const handleChangeCheckButton = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ width: "100%" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid>
            <Grid sx={{ padding: "10px", bgcolor: "white" }}>
              {singleLocation !== null && (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    accountName: singleLocation?.name
                      ? singleLocation?.name
                      : "",
                    addressOne: singleLocation?.addressLine1
                      ? singleLocation?.addressLine1
                      : "",
                    addressTwo: singleLocation?.addressLine2
                      ? singleLocation?.addressLine2
                      : "",
                    postalCode: singleLocation?.postalCode
                      ? singleLocation?.postalCode
                      : "",
                    city: singleLocation?.city ? singleLocation?.city : "",
                    countryName: singleLocation?.country
                      ? singleLocation?.country
                      : "",
                    telephone: singleLocation?.telephone
                      ? singleLocation?.telephone
                      : "",
                    website: singleLocation?.website
                      ? singleLocation?.website
                      : "",
                    /*    accountExternalReference: externalReference
                      ? externalReference
                      : "", */
                    defaultLanguage: singleLocation?.defaultLanguage,
                    businessEmail: singleLocation?.businessEmail
                      ? singleLocation?.businessEmail
                      : "",
                    /*  soConnectEmail: singleLocation?.soConnectEmail
                      ? singleLocation?.soConnectEmail
                      : "", */
                    internalName: singleLocation?.internalName
                      ? singleLocation?.internalName
                      : "",
                    submit: false,
                    companyId: singleLocation?.company
                      ? singleLocation?.company
                      : "",
                  }}
                  validationSchema={Yup.object().shape({
                    accountName: Yup.string()
                      .max(255)
                      .required(`${t("account_req")}`),

                    //  companyId: Yup.string().required(`${t("company_req")}`),
                    //  accountExternalReference: Yup.string().max(255),
                    internalName: Yup.string().required(
                      `${t("internal_name_req")}`
                    ),
                    postalCode: Yup.string().required(`${t("postal_req")}`),
                    // .matches(/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/, `${t("invalid_postal")}`),
                    // .min(9999, `${t("limit exceed")}`)
                    // .max(99999, `${t("limit exceed")}`),

                    // telephone: Yup.string()
                    //   .required(`${t("telephone_req")}`)
                    //   .matches(
                    //     /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
                    //     "Invalid phone number"
                    //   ),
                    businessEmail: Yup.string()
                      .max(255)
                      .required(`${t("email_req")}`)
                      .matches(/\S+@\S+\.\S+/, `${t("valid_email")}`),
                    /*   email: Yup.string()
                .email(`${t("valid_email")}`)
                .max(255)
                .required(`${t("email_req")}`)
                .matches(/\S+@\S+\.\S+/, `${t("valid_email")}`), */
                    /*   soConnectEmail: Yup.string()
                      .email(`${t("valid_email")}`)
                      .max(255)
                      // .required(`${t("list_email_req")}`)
                      .matches(/\S+@\S+\.\S+/, `${t("valid_email")}`), */
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      setIsLoading(true);
                      const res = await api.patch(
                        `/location/${getLocationById}`,
                        {
                          name: values.accountName,
                          addressLine1: values.addressOne,
                          addressLine2: values.addressTwo,
                          postalCode: values.postalCode,
                          city: values.city,
                          country: values.countryName,
                          telephone: values.telephone,
                          website: values.website,
                          /*   externalReference: checked
                          ? ""
                          : values.accountExternalReference, */
                          partnerId: getSelectedCompanyId + 1,
                          defaultLanguage: selectLanguage,
                          businessEmail: values.businessEmail
                            ? values.businessEmail
                            : "",
                          /*   soConnectEmail: values.soConnectEmail
                            ? values.soConnectEmail
                            : "", */
                          internalName: values?.internalName,
                          isDefault: SelectDefaultLocation,
                          companyId: companyId
                            ? companyId.id
                            : singleLocation?.company.id,
                        }
                      );
                      if (res.status === 200) {
                        setIsLoading(false);
                        setMessage(res.data.message);
                        getSingleLocation(getLocationById);
                        toast.success("Updated Successfully", {
                          onClose: () => {
                            navigate(-1);
                          },
                        });
                        // setTimeout(() => {
                        //   setMessage(navigate("/user/locations"));
                        // }, );
                      }
                    } catch (error) {
                      const message =
                        error?.response?.data?.message ||
                        "Something went wrong";
                      setStatus({ success: false });
                      setErrors({ submit: message });
                      setSubmitting(false);
                      toast.error(error?.response?.data?.message);
                      setIsLoading(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      {errors.submit && (
                        <Alert mt={2} mb={1} severity="error">
                          {errors.submit}
                        </Alert>
                      )}

                      <Grid>
                        {/*   <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h3">{t("Accounts")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Grid item xs={9}>
                        <AccountsCard
                          socialLink={socialLink}
                          id={id}
                          placeId={placeId}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider variant="middle" /> */}

                        <Grid
                          container
                          sx={{
                            marginTop: "20px",
                            marginLeft: "2px",
                            marginRight: "2px",
                            marginBottom: "20px",
                          }}
                        >
                          <Grid item xs={12} sm={3}>
                            <Typography variant="h3">
                              {t("Company_Account")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Typography
                              sx={{
                                marginTop: "10px",
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "rgba(0,0,0,0.87)",
                              }}
                            >
                              {t("Select_Company")}
                            </Typography>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={
                                CompanyData?.results ? CompanyData?.results : []
                              }
                              getOptionLabel={(option) => option?.name}
                              fullWidth
                              value={companyId}
                              onChange={(e, value) => {
                                setCompanyId(value);
                                if (value) {
                                  setCompanyRequired(false);
                                  setDisplayCompanyError(false);
                                } else {
                                  setCompanyRequired(true);
                                  setDisplayCompanyError(true);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label=""
                                  name="companyId"
                                  error={Boolean(companyRequired === true)}
                                  helperText={
                                    companyRequired === true && t("company_req")
                                  }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        <Divider variant="middle" />
                        <Grid
                          container
                          sx={{
                            marginTop: "20px",
                            marginLeft: "2px",
                            marginRight: "2px",
                            marginBottom: "20px",
                          }}
                        >
                          <Grid item xs={12} sm={3}>
                            <Typography variant="h3">{t("Name")}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            {" "}
                            <Typography
                              sx={{
                                marginTop: "10px",
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "rgba(0,0,0,0.87)",
                              }}
                            >
                              {t("Location_Name")}
                            </Typography>
                            <TextField
                              type="text"
                              name="accountName"
                              value={values?.accountName}
                              error={Boolean(
                                touched?.accountName && errors?.accountName
                              )}
                              fullWidth
                              helperText={
                                touched?.accountName && errors?.accountName
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            marginTop: "20px",
                            marginLeft: "2px",
                            marginRight: "2px",
                            marginBottom: "20px",
                          }}
                        >
                          <Grid item xs={12} sm={3}>
                            <Typography variant="h3">
                              {" "}
                              {t("Internal_name")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            {" "}
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  marginTop: "10px",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  color: "rgba(0,0,0,0.87)",
                                }}
                              >
                                {t("Internal_name_label")}
                              </Typography>
                              <BootstrapTooltip
                                title={
                                  <h1 style={{ fontSize: "12px" }}>
                                    {t("internalName_tooltip_text")}
                                  </h1>
                                }
                              >
                                <IconButton>
                                  <ErrorOutlineIcon
                                    sx={{
                                      color: "#1B2430",
                                    }}
                                  />
                                </IconButton>
                              </BootstrapTooltip>
                            </Box>
                            <TextField
                              type="text"
                              name="internalName"
                              value={values?.internalName}
                              error={Boolean(
                                touched?.internalName && errors?.internalName
                              )}
                              fullWidth
                              helperText={
                                touched?.internalName && errors?.internalName
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Divider variant="middle" />
                      <Grid
                        container
                        sx={{
                          marginTop: "20px",
                          marginLeft: "2px",
                          marginRight: "2px",
                          marginBottom: "20px",
                        }}
                      >
                        <Grid item xs={12} sm={3}>
                          <Typography variant="h3">{t("Address")}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("AddressLineOne")}
                          </Typography>
                          <TextField
                            type="text"
                            name="addressOne"
                            value={values.addressOne}
                            error={Boolean(
                              touched.addressOne && errors.addressOne
                            )}
                            fullWidth
                            helperText={touched.addressOne && errors.addressOne}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />

                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("AddressLineTwo")}
                          </Typography>
                          <TextField
                            type="text"
                            name="addressTwo"
                            value={values.addressTwo}
                            error={Boolean(
                              touched.addressTwo && errors.addressTwo
                            )}
                            fullWidth
                            helperText={touched.addressTwo && errors.addressTwo}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <Grid container>
                            <Grid item xs={5} sx={{ backgroundColor: "" }}>
                              <Typography
                                sx={{
                                  marginTop: "10px",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  color: "rgba(0,0,0,0.87)",
                                }}
                              >
                                {t("PostalCode")}
                              </Typography>
                              <TextField
                                name="postalCode"
                                value={values.postalCode}
                                error={Boolean(
                                  touched.postalCode && errors.postalCode
                                )}
                                fullWidth
                                inputProps={{
                                  maxLength: 12,
                                }}
                                helperText={
                                  touched.postalCode && errors.postalCode
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid xs={1}></Grid>
                            <Grid item xs={6} sx={{ backgroundColor: "" }}>
                              <Typography
                                sx={{
                                  marginTop: "10px",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  color: "rgba(0,0,0,0.87)",
                                }}
                              >
                                {t("City")}
                              </Typography>
                              <TextField
                                type="text"
                                name="city"
                                value={values.city}
                                error={Boolean(touched.city && errors.city)}
                                fullWidth
                                helperText={touched.city && errors.city}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Country")}
                          </Typography>
                          <TextField
                            type="text"
                            name="countryName"
                            value={values.countryName}
                            error={Boolean(
                              touched.countryName && errors.countryName
                            )}
                            fullWidth
                            helperText={
                              touched.countryName && errors.countryName
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      {/* {isLoading && <Loader />} */}
                      <Divider variant="middle" />
                      <Grid
                        container
                        sx={{
                          marginTop: "20px",
                          marginLeft: "2px",
                          marginRight: "2px",
                          marginBottom: "20px",
                        }}
                      >
                        <Grid item xs={12} sm={3}>
                          <Typography variant="h3">
                            {t("ContactDetails")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Telephone")}
                          </Typography>
                          <TextField
                            type="text"
                            name="telephone"
                            value={values.telephone}
                            // error={Boolean(
                            //   touched.telephone && errors.telephone
                            // )}
                            fullWidth
                            // helperText={touched.telephone && errors.telephone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />

                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Website")}
                          </Typography>
                          <TextField
                            type="text"
                            name="website"
                            value={values.website}
                            error={Boolean(touched.website && errors.website)}
                            fullWidth
                            helperText={touched.website && errors.website}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Email_Address_Buisness")}
                          </Typography>
                          <TextField
                            disabled={isLoading}
                            type="email"
                            name="businessEmail"
                            placeholder="e.g.  john@obenan.com"
                            value={values.businessEmail}
                            error={Boolean(
                              touched.businessEmail && errors.businessEmail
                            )}
                            fullWidth
                            helperText={
                              touched.businessEmail && errors.businessEmail
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      {/*  {singleLocation?.soconnect_connectivity_status ==
                        true && (
                        <>
                          <Divider variant="middle" />
                          <Grid
                            container
                            sx={{
                              marginTop: "20px",
                              marginLeft: "2px",
                              marginRight: "2px",
                              marginBottom: "20px",
                            }}
                          >
                            <Grid item xs={12} sm={3}>
                              <Typography variant="h3">
                                {t("So_Connect_heading")}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <Typography
                                sx={{
                                  marginTop: "10px",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  color: "rgba(0,0,0,0.87)",
                                }}
                              >
                                {t("So_Connect_email")}
                              </Typography>
                              <TextField
                                disabled={isLoading}
                                type="email"
                                name="soConnectEmail"
                                placeholder="e.g.  john@obenan.com"
                                value={values.soConnectEmail}
                                error={Boolean(
                                  touched.soConnectEmail &&
                                    errors.soConnectEmail
                                )}
                                fullWidth
                                helperText={
                                  touched.soConnectEmail &&
                                  errors.soConnectEmail
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )} */}
                      <Divider variant="middle" />
                      <Grid
                        container
                        sx={{
                          marginTop: "20px",
                          marginLeft: "2px",
                          marginRight: "2px",
                          marginBottom: "20px",
                        }}
                      >
                        <Grid item xs={12} sm={3}>
                          <Typography variant="h3">
                            {t("Auto_Replyy")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Default_Review")}
                          </Typography>
                          <Select
                            required
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectLanguage}
                            // label="Select"
                            onChange={(e, value) => {
                              setSelectLanguage(e.target.value);
                            }}
                          >
                            {languagesData?.map((item) => (
                              <MenuItem value={item?.value}>
                                {item?.language}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                      {/*      <Divider variant="middle" /> */}
                      {/*  <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">
                      {t("Default_Location_lable")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Default_Location")}
                    </Typography>
                    <Select
                      required
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled={defaultLocation === null ? false : true}
                      defaultValue={defaultLocation === null ? false : true}
                      // label="Select"
                      onChange={(e, value) => {

                        setDefaultLocation(e.target.value);
                      }}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false} selected>
                        False
                      </MenuItem>
                    </Select>

                  </Grid>
                </Grid> */}
                      {/*    <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("Reference")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {!checked && (
                      <>
                        <Typography
                          sx={{
                            marginTop: "10px",
                            fontSize: "14px",
                            fontWeight: "700",
                            color: "rgba(0,0,0,0.87)",
                          }}
                        >
                          {t("AccountReference")}
                        </Typography>
                        <TextField
                          type="text"
                          name="accountExternalReference"
                          value={values.accountExternalReference}
                          error={Boolean(
                            touched.accountExternalReference &&
                              errors.accountExternalReference
                          )}
                          fullWidth
                          helperText={
                            touched.accountExternalReference &&
                            errors.accountExternalReference
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </>
                    )}

                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      <Checkbox
                        checked={checked}
                        onChange={(e) => handleChangeCheckButton(e)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      {t("NoExternalReference")}
                    </Typography>
                  </Grid>
                </Grid> */}

                      <Divider variant="middle" />
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        my={3}
                      >
                        <Grid item>
                          <CommonButton
                            displayWhite="true"
                            onSubmit={() => navigate("/user/locations")}
                            label={t("Cancel")}
                          />
                        </Grid>
                        <Grid item>
                          <CommonButton
                            type="submit"
                            //  onSubmit={() => setDisplayCompanyError(true)}
                            disabled={isSubmitting}
                            loading={isLoading}
                            label={t("UpdateLocation")}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withTheme(LocationForm);
