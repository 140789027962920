import {
  Grid,
  Card,
  Divider,
  Typography,
  Checkbox,
  TableBody,
  Table,
  Paper as MuiPaper,
  TableContainer,
} from "@mui/material";
import styled from "styled-components/macro";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TitleHeader from "../../../../components/CustomComponents/title";

import { api } from "../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import Loader from "../../../../components/Loaders/Loader";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";

import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import { useStyles } from "../../../../styles/style";
const Paper = styled(MuiPaper)(spacing);

function Accounts(props) {
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [accountId, setAccountId] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState();
  const [accountSearch, setAccountSearch] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [locationId, setLocationId] = useState();
  const [refreshToken, setRefreshToken] = useState("");
  const [refrenceId, setRefrenceId] = useState(null);

  const [filterWithName, setFilterWithName] = useState("");
  //  const [allLocations, setAllLocations] = useState([]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const getCode = location?.search !== "" && location?.search.split("=");
  const code = getCode && getCode[1];

  useEffect(() => {
    const locId = location?.search !== "" && location?.search.split("=");
    //
    if (locId?.length > 0) {
      setLocationId(locId[2]);
    }
  }, []);
  const getAllAccounts = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `facebookConnect/auth-pages?code=${code === false ? "" : code}`
      );

      if (res?.data?.status === true) {
        setLoading(false);
        setAccounts(res?.data?.data?.page);
        setAccount(res?.data?.data?.account);
        //  setAccountSearch(res?.data?.data?.pages?.data);
        setFilterWithName("");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(async () => {
    getAllAccounts();
  }, [location?.search]);

  {
  }

  const handleChangeCheckButton = (e, data) => {
    if (e.target.checked) {
      setSelectedData(data);
      const str = data?.name?.split("/");
      setAccountId(str[1]);

      setRefreshToken(data?.access_token);
      setRefrenceId(data?.referenceId);
    } else {
      setSelectedData(null);
      setAccountId("");
    }
    {
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await api.post(`/facebookConnect/create/socialLinks`, {
        locationId: locationId,
        accountId: account,
        refreshToken: refreshToken,
        referenceId: refrenceId,
      });

      if (res?.data?.status === true) {
        setLoading(false);
        toast.success(res?.data?.message);
        // navigate(`/user/edit_location?state=${locationId}/`);

        navigate(`/user/edit_location?state=${locationId}/`, {
          state: {
            showProduct: true,
            displayOn: true,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  /*   const createSocialLink = async (data) => {
     try {
       setLoading(true);
       const res = await api.post(`/googleConnect/create/socialLinks`, {
         account: location?.state?.accountId,
         refreshToken: location?.state?.refreshToken,
         locations: selectedLocations,
       });

       if (res?.data?.status===true) {
         setLoading(false);
         setOpen(false);
         toast.success(res?.data?.message);
         navigate("/user/locations");
       }
     } catch (error) {
       setLoading(false);
       setOpen(false);
       toast.error(error?.response?.data?.message);
     }
   }; */
  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };
  const getSearchedLocation = () => {
    if (filterWithName.length > 0) {
      setLoading(true);
      const data = accountSearch.filter((item) =>
        item?.name.toLowerCase().includes(filterWithName)
      );
      if (data?.length > 0) {
        // setDisplayError(false);
        setAccountSearch(data);
      } else {
        setAccountSearch(data);
        // setDisplayError(true);
      }

      setLoading(false);
    } else {
      getAllAccounts();
    }
  };

  return (
    <>
      {" "}
      <TitleHeader title={t("Connect_facebook_buisness")} />
      {/*    <Grid container spacing={2} sx={{paddingY:"8px"}}>
            <Grid item xs={12} sm={10} md={10}>
              <SearchField
                filterByName={filterByName}
                onSubmit={getSearchedLocation}
                placeholder={t("Filter Page Name")}
                filterWithName={filterWithName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
          <CommonButton
            isLoading={loading}
                onSubmit={getSearchedLocation}
                label={t("Search")}
                alignment={true}
              />
            </Grid>
          </Grid> */}
      {loading ? (
        <Loader />
      ) : accounts?.length > 0 ? (
        <Grid container marginTop="1rem" spacing={6}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <CustomizedTableHead>
                  <CustomizedTableRow>
                    <CustomizedTableCell padding="checkbox" align="left">
                      {t("Select")}
                      {/* <Checkbox
                                    color="primary"
                                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                                    // checked={selectAll}
                                    onChange={(e) => handleChangeCheckButton(e)}
                                    inputProps={{
                                      "aria-label": "select all desserts",
                                    }}
                                  /> */}
                    </CustomizedTableCell>

                    <CustomizedTableCell>{t("PageName")}</CustomizedTableCell>

                    <CustomizedTableCell>
                      {t("LocationTableName")}
                    </CustomizedTableCell>
                    <CustomizedTableCell>{t("Company")}</CustomizedTableCell>
                  </CustomizedTableRow>
                </CustomizedTableHead>
                <TableBody>
                  {accounts?.length > 0 ? (
                    accounts?.map((row, index) => {
                      return (
                        <CustomizedTableRow
                          // hover
                          tabIndex={-1}
                          key={`${row.id}-${index}`}
                        >
                          <CustomizedTableCell padding="checkbox" align="left">
                            <Checkbox
                              color="primary"
                              disabled={row.disable}
                              checked={
                                selectedData?.referenceId === row?.referenceId
                              }
                              onChange={(e) => handleChangeCheckButton(e, row)}
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                            />
                          </CustomizedTableCell>

                          <CustomizedTableCell
                            align="left"
                            sx={{ width: "300px" }}
                          >
                            {row?.name ? row?.name : "--"}
                          </CustomizedTableCell>
                          <CustomizedTableCell
                            align="left"
                            sx={{ width: "300px" }}
                          >
                            {row?.location !== null
                              ? row?.location?.internalName
                                ? row?.location?.internalName +
                                  " " +
                                  ((row?.location?.addressLine1 !== "" &&
                                    row?.location?.addressLine1 !== null) ||
                                  (row?.location?.city !== "" &&
                                    row?.location?.city !== null)
                                    ? "(" +
                                      (row?.location?.addressLine1 !== "" &&
                                      row?.addressLine1 !== null &&
                                      row?.location?.addressLine1 !== undefined
                                        ? row?.location?.addressLine1 +
                                          "," +
                                          " "
                                        : "") +
                                      (row?.location?.city
                                        ? row?.location?.city
                                        : "") +
                                      ")"
                                    : "")
                                : "--"
                              : "--"}
                            {/*   {row?.phoneNumbers?.primaryPhone} */}
                          </CustomizedTableCell>
                          <CustomizedTableCell
                            align="left"
                            sx={{ width: "300px" }}
                          >
                            {row?.company !== null ? row?.company?.name : "--"}

                            {/*   {row?.phoneNumbers?.primaryPhone} */}
                          </CustomizedTableCell>

                          {/* <TableCell align="left">{getSubscription}</TableCell> */}
                        </CustomizedTableRow>
                      );
                    })
                  ) : (
                    <Typography variant="h3">{t("data_found")}</Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            my={3}
            className={classes.buttonContainerAccount}
          >
            <CommonButton
              onSubmit={() => navigate("/user/locations")}
              // type="submit"
              displayWhite="true"
              variant="contained"
              // disabled={isSubmitting}

              label={t("Cancel")}
            />

            <CommonButton
              type="submit"
              disabled={selectedData === null}
              onSubmit={handleSubmit}
              label={t("Confirm")}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            padding: "20px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Typography className={classes.headingAccount}>
            {t("No_Pages_Found_Text")}
          </Typography>
        </Grid>
      )}
    </>
  );
}

export default Accounts;
