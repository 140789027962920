import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../../../../../Styles/style";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "8px",
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

/* const AccordionSummary = styled((props) => {
  return (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  );
})((props) => {
  const { theme } = props;

  return {
    // backgroundColor: alpha(theme.palette.primary.main, 0.7),
    backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
    //  border: "1px solid #E0E0E0",
    borderRadius: "8px",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  };
}); */

/* const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
})); */

export default function CollapsibleForm({
  title,
  ComponentToRender,
  CustomTitle,
  id,
  handleChange,
  expanded,
}) {
  const classes = useStyles();
  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={(e, newExpanded) => handleChange(e, newExpanded, id)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={id}
        >
          {CustomTitle ? (
            CustomTitle
          ) : (
            <Typography className={classes.colapsibleTitle}>{title}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>{ComponentToRender}</AccordionDetails>
      </Accordion>
    </div>
  );
}
