import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  TextField as MuiTextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  createFilterOptions,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import Editors from "../../../../components/CustomComponents/Editor/Editors";
import ReviewsCard from "../../../../components/ReviewSource/ReviewsCard";
import { api } from "../../../../contexts/JWTContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useStyles } from "../styles/style";
import Loader from "../../../../components/Loaders/Loader";
const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const EditTask = ({ onCancel, selectedTaskId, alignment }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [isLoading, setIsLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [locationId, setLocationId] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [titleRequired, setTitleRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [userRequired, setUserRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskLoading, setTaskLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const filter = createFilterOptions();

  useEffect(() => {
    if (selectedTask !== null) {
      let tempUsersArray = [];
      selectedTask?.taskUsers?.length > 0 &&
        selectedTask?.taskUsers?.map((item) => {
          let newItem = {
            ...item,
            User: item.user,
          };
          delete newItem.user;
          tempUsersArray.push(newItem);
        });
      setSelectedUser(tempUsersArray);
      setTitle(selectedTask?.title);
      setDescription(selectedTask?.description);
      setCompanyId(selectedTask.company);
      setLocationId(selectedTask?.location);
      setStatus(selectedTask?.status);
      setSelectedReview(selectedTask?.review);
    }
  }, [selectedTask]);
  useEffect(() => {
    getSingleTask();
    getAllUsers();
  }, []);

  useEffect(async () => {
    getAllCompanies();

    //  getTasks()
  }, [companyId]);

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleLocationChange = (option) => {
    setLocationId(option);
  };

  const handleUserChange = (option) => {
    setSelectedUser(option);
  };

  const getSingleTask = async () => {
    setTaskLoading(true);
    try {
      const res = await api.get(`tasks/${selectedTaskId?.id}`);
      if (res.status === 200) {
        setSelectedTask(res.data.data);

        setTaskLoading(false);
      }
    } catch (error) {
      setTaskLoading(false);
    }
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `/companyUserRoles?pageNumber=1&limit=5000&companyId=${companyData?.id}`
      );
      if (res.status === 200) {
        const activeUsers = res?.data?.data?.rows?.filter(
          (user) =>
            user?.User?.inActive === false &&
            (user?.User?.status === "active" ||
              user?.User?.status === "pending")
        );

        setUsers(activeUsers);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/company/` + companyData?.id);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
      }
    } catch (error) {}
  };

  const createTask = async () => {
    setTaskLoading(true);
    const newStr = description.replace(/(<([^>]+)>)/gi, "");

    if (title === "") {
      setTitleRequired(true);
    }

    if (newStr === "") {
      setDescriptionRequired(true);
    }
    if (locationId === null) {
      setLocationRequired(true);
    }
    if (selectedUser?.length < 1) {
      setUserRequired(true);
    }
    let userIds = [];
    selectedUser?.map((item) => {
      userIds.push(item?.User?.id);
    });

    if (
      newStr !== "" &&
      title !== "" &&
      locationId !== null &&
      selectedUser?.length > 0
    ) {
      try {
        if (selectedTask) {
          const res = await api.patch("/tasks/" + selectedTask?.id, {
            title: title,
            description: description,
            companyId: companyId?.id,
            locationId: locationId?.id,
            status: status,
            reviewId: selectedReview?.id,
            userIds: userIds?.length > 0 ? userIds.toString() : "",
          });
          if (res.status === 200) {
            setTaskLoading(false);
            toast.success("Task Updated Successfully", {
              onClose: () => {
                onCancel(true);
              },
            });
            // setAllCompanies(res.data.data);
          }
        }
      } catch (error) {
        setTaskLoading(false);

        toast.error("error");
      }
    }

    //
  };

  return (
    <>
      <Box className={classes.addTaskField}>
        {" "}
        <Box className={classes.editTaskMainBox}>
          {taskLoading ? (
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "55vh",
              }}
            >
              <Loader />
            </Grid>
          ) : (
            <>
              <Grid container>
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <Box sx={{ marginBottom: "8px" }}>
                    {selectedReview && (
                      <>
                        <Typography className={classes.addTaskTypo}>
                          {t("Linked_Review")}
                        </Typography>
                        <ReviewsCard
                          item={selectedReview}
                          location={selectedReview?.location}
                          fromEditTask="true"
                        />
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Typography className={classes.addTaskTypo}>
                {t("Task_Tile")}
              </Typography>
              <TextField
                sx={{ width: "100%" }}
                error={titleRequired}
                style={{ borderRadius: "8px" }}
                value={title}
                onChange={(e) => {
                  setTitleRequired(false);
                  setTitle(e.target.value);
                }}
                InputProps={{
                  inputProps: {
                    style: {
                      marginLeft: "0px",
                      marginRight: "0px",
                      marginBottom: "0px",
                      width: "100%",
                    },
                  },
                }}
                helperText={titleRequired && t("Field_req_Title")}
              />

              <Box sx={{ minWidth: alignment === true ? 120 : "auto" }}>
                <Typography className={classes.addTaskTypo}>
                  {t("Status")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"open"}>{t("Open")}</MenuItem>
                    <MenuItem value={"inprogress"}>{t("InProgress")}</MenuItem>
                    <MenuItem value={"completed"}>{t("Completed")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Typography className={classes.addTaskTypo}>
                {t("Select_Locations")}
              </Typography>

              {companyId && (
                <Autocomplete
                  error={locationRequired}
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allCompanies?.locations?.length > 0
                      ? allCompanies?.locations
                      : []
                  }
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        "(" +
                        (option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? option?.addressLine1 + "," + " "
                          : "") +
                        (option?.city ? option?.city : "") +
                        ")"
                      : ""
                  }
                  sx={{ width: "100%" }}
                  value={locationId}
                  onChange={(e, value) => {
                    setLocationRequired(false);
                    handleLocationChange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      name="companies"
                      error={locationRequired}
                      helperText={locationRequired && t("Field_req_loc")}
                    />
                  )}
                ></Autocomplete>
              )}

              <Typography className={classes.addTaskTypo}>
                {t("Select_User")}
              </Typography>

              <Autocomplete
                multiple
                error={userRequired}
                disablePortal
                id="combo-box-demo"
                options={users?.length > 0 ? users : []}
                getOptionLabel={(option) =>
                  option?.User?.firstName !== null &&
                  option?.User?.lastName !== null
                    ? option?.User?.firstName.charAt(0).toUpperCase() +
                      option?.User?.firstName.slice(1) +
                      " " +
                      (option?.User?.lastName !== null &&
                      option?.User?.lastName !== ""
                        ? option?.User?.lastName.charAt(0).toUpperCase() +
                          option?.User?.lastName.slice(1) +
                          " " +
                          "(" +
                          option?.User?.email +
                          ")"
                        : "")
                    : option?.User?.email
                }
                sx={{ width: "100%" }}
                defaultValue={selectedUser && selectedUser}
                value={selectedUser}
                onChange={(event, value) => {
                  if (value.find((option) => option.id === 0)) {
                    if (users?.length === selectedUser?.length) {
                      handleUserChange([]);
                      setSelectAll(false);
                    } else {
                      setSelectAll(true);
                      handleUserChange(users);
                      setUserRequired(false);
                    }
                  } else {
                    handleUserChange(value);
                    if (value?.length === users?.length) {
                      setSelectAll(true);
                    } else {
                      setSelectAll(false);
                    }
                  }
                }}
                renderOption={(props, option, { selected }) => {
                  if (option.id === 0) {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selectAll}
                        />
                        {option?.firstName}
                      </li>
                    );
                  } else {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected || selectAll}
                        />
                        {option?.User?.firstName !== null &&
                        option?.User?.lastName !== null
                          ? option?.User?.firstName.charAt(0).toUpperCase() +
                            option?.User?.firstName.slice(1) +
                            " " +
                            (option?.User?.lastName !== null &&
                            option?.User?.lastName !== ""
                              ? option?.User?.lastName.charAt(0).toUpperCase() +
                                option?.User?.lastName.slice(1) +
                                " " +
                                "(" +
                                option?.User?.email +
                                ")"
                              : "")
                          : option?.User?.email}
                      </li>
                    );
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (users?.length > 0) {
                    return [{ id: 0, firstName: t("Select_All") }, ...filtered];
                  } else {
                    return [];
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("Select_User")}
                    {...params}
                    required
                    helperText={userRequired && t("Field_req_user")}
                    error={userRequired}
                    name="language"
                  />
                )}
              ></Autocomplete>

              <Grid container>
                <Typography className={classes.descText}>
                  {t("Description")}
                </Typography>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Editors
                    value={description}
                    onChange={(e) => {
                      setDescriptionRequired(false);
                      setDescription(e);
                    }}
                  />
                  {descriptionRequired && (
                    <Typography sx={{ color: "#d32f2f", marginLeft: "8px" }}>
                      {t("Field_req_dec")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <Divider />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Box className={classes.editTaskBox}>
            <CommonButton
              displayWhite="true"
              onSubmit={onCancel}
              label={t("Cancel")}
            />
            <CommonButton
              onSubmit={() => {
                createTask();
              }}
              variant="contained"
              disabled={isLoading || taskLoading}
              //  loading={isLoading || }
              label={t("Update_Task")}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default EditTask;
