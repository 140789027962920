import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";

import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useStyles } from "../../../../Styles/style";
import TimePickers from "./timePickers";
import moment from "moment";
import useDateFormat from "../../../../../../../hooks/useDateFormat";

const Hours = ({
  periods,
  handleChangeDate,
  removeDate,
  index,
  selectedData,
  setSelectedData,
  filteredPeriodsArr,
  handleChangeOpen,
  handleChangeOpenTime,
  handleChangeCloseTime,
  handleAddHours,
  handleRemoveHours,
  setFilteredPeriodsArr,
  setDuplicateDate,
  disableFields,
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState("");
  const { format } = useDateFormat();

  const classes = useStyles();

  useEffect(() => {
    if (
      periods?.startDate?.year &&
      periods?.startDate?.month &&
      periods?.startDate?.day
    ) {
      const date = new Date(
        `${periods?.startDate?.month}/${periods?.startDate?.day}/${periods?.startDate?.year} 10:05:00`
      );

      setStartDate(date);
    }
  }, [filteredPeriodsArr]);

  const disableDatesInArray = (date) => {
    const dateDayjs = dayjs(date);

    const isInPeriods = () => {
      const start = dayjs(new Date(periods?.startDate?.year, periods?.startDate?.month - 1, periods?.startDate?.day));
      return dateDayjs.isSame(start, 'day')
    };

    const isInFilteredPeriodsArr = () => {
      return filteredPeriodsArr.some(({ startDate, endDate }) => {
        const start = dayjs(new Date(startDate?.year, startDate?.month - 1, startDate?.day));
        return dateDayjs.isSame(start, 'day')
      });
    };

    return isInFilteredPeriodsArr() && !isInPeriods()
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={3} md={3} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dayjs(startDate)}
              format={format}
              onChange={(newValue, newDate) => {
                handleChangeDate(newValue, index, periods);
                setSelectedData(periods);
              }}
              disabled={disableFields}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ background: "#FFFF" }}
                  error={periods?.sameDate}
                  helperText={periods?.sameDate && t("Duplicate_date")}
                />
              )}
              shouldDisableDate={disableDatesInArray}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Box sx={{ marginTop: "4px" }}>
            <Typography className={classes.switchTypo}>
              {" "}
              <Switch
                className={
                  periods?.closed === true
                    ? classes.toggleChecked
                    : classes.toggleUnChecked
                }
                disabled={disableFields}
                checked={periods?.closed === true ? false : true}
                onChange={(e) => handleChangeOpen(e, periods, index)}
              />
              {periods?.closed === false ? t("Open") : t("Closed")}
            </Typography>
          </Box>
        </Grid>
        {periods?.closed === true && (
          <Grid item xs={3} sm={3} md={1} lg={0.5}>
            <IconButton
              disabled={disableFields}
              onClick={() => removeDate(index)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
        {periods?.closed === false && (
          <Grid item xs={6.5} sm={6.5} md={6.5} lg={6.5}>
            {periods?.mergedTimes?.map((item, ind) => (
              <Grid container key={index} sx={{ marginBottom: "10px" }}>
                <TimePickers
                  periodsIndex={index}
                  periods={periods}
                  itemIndex={ind}
                  item={item}
                  handleChangeCloseTime={handleChangeCloseTime}
                  handleChangeOpenTime={handleChangeOpenTime}
                  // open={open}
                  filteredPeriodsArr={filteredPeriodsArr}
                  setSelectedData={setSelectedData}
                  selectedData={selectedData}
                  //  getLocationDetails={getLocationDetails}
                  handleAddHours={handleAddHours}
                  handleRemoveHours={handleRemoveHours}
                  disableFields={disableFields}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {periods?.closed === false && (
          <Grid
            item
            xs={0.5}
            sm={0.5}
            md={0.5}
            lg={0.5}
            sx={{ textAlign: "end" }}
          >
            <IconButton
              disabled={disableFields}
              onClick={() => removeDate(index)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Hours;
