import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Grid,
  Zoom,
  Stack,
  styled,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
// import PictureModalCard from "./PictureModalCard";
import Compressor from "compressorjs";
// import CoverImagesCard from "./CoverImageUploadCard";
import InteriorImageCard from "./InteriorImageUpload";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
  formUpload: {
    height: "16rem",
    width: "28rem",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
  },
  lableFileUpload: {
    height: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: "#cbd5e1",
    backgroundColor: "#f8fafc",
  },
  dragFile: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  modelTypoHeading: {
    fontWeight: "700",
    color: "#1B2430",
    fontSize: "14px",
    // letterSpacing: "0.5px",
    lineHeight: "20px",
    color: "#1B2430",
    marginBottom: "10px",
  },
  modelTypoText: {
    fontWeight: "400",
    color: "#1B2430",
    fontSize: "14px",
    // letterSpacing: "0.5px",
    lineHeight: "18px",
    color: "#1B2430",
  },
  modelTypoGuildlinesGrid: {
    background: "#E0F7FF",
    padding: "5px 12px 5px 12px",
    borderRadius: "8px",
  },
}));
const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));
Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const InteriorImagesModal = ({
  locationId,
  open,
  handleClose,
  setPostData,

  postData,
  handleImagesShow,
  setTouchedValue,
  category,

  validation,
  selectedImages,
  setSelectedImages,
  title,
  tooltipText,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const [picturePreview, setPicturePreview] = useState("");
  const [dragActive, setDragActive] = React.useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [compressedFile, setCompressedFile] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!open) {
      setPicturePreview("");
    }
  }, [open]);
  /*   useEffect(() => {
    if (selectedImages?.length <= 0) {
      handleClose();
    }
  }, [selectedImages]); */

  const handleDelete = (index) => {
    /*    let deleteCount = alreadyAddedImages;
    if (deleteCount > 0) {
      setAlreadyAddedImages(deleteCount - 1);
    } */
    const deleteImage = selectedImages.filter((item, indx) => {
      return indx !== index;
    });
    const findDeleted = selectedImages.find((item, indx) => {
      return indx === index;
    });

    if (findDeleted) {
      const filterDelete = uploadedImages.filter((item) => {
        return item !== findDeleted?.key;
      });
      setUploadedImages(filterDelete);
    }
    setSelectedImages(deleteImage);
    setTouchedValue(true);
  };

  const handleImageError = (index) => {
    let temmpArray = [...selectedImages];
    let temmpObj = temmpArray[index];
    temmpObj["error"] = true;
    temmpArray[index] = temmpObj;
    setSelectedImages(temmpArray);
  };

  const onFileChangeCapture = (e) => {
    setLoading(true);
    // let tempImages = [...selectedImages];
    for (const [key, value] of Object.entries(e.target.files)) {
      /*  if (key !=="length") {
        let tempObj = {
          picture: value,
          picturePreview: URL.createObjectURL(value),
          uploaded: false,
        };
        selectedImages?.push(tempObj);
      } */
      new Compressor(value, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          setCompressedFile(compressedResult);

          let tempObj = {
            picture: compressedResult,
            picturePreview: URL.createObjectURL(compressedResult),
            uploaded: false,
          };

          selectedImages?.push(tempObj);
          setSelectedImages([...selectedImages]);
        },
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setIsUploading(false);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    for (const [key, value] of Object.entries(e?.dataTransfer?.files)) {
      /*  let tempObj = {
         picture: value,
         picturePreview: URL.createObjectURL(value),
         uploaded: false,
       };
       selectedImages?.push(tempObj);
     }
     setSelectedImages([...selectedImages]); */
      new Compressor(value, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          setCompressedFile(compressedResult);

          let tempObj = {
            picture: compressedResult,
            picturePreview: URL.createObjectURL(compressedResult),
            uploaded: false,
          };

          selectedImages?.push(tempObj);
          setSelectedImages([...selectedImages]);
        },
      });
    }
  };
  const handleSubmitImages = () => {
    handleClose();
    handleImagesShow(uploadedImages);
  };
  const handleUploadButtonClick = () => {
    // Trigger the hidden file input when the upload button is clicked
    fileInputRef.current.click();
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
              borderRadius: "8px",
            },
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <DialogTitle
              id="alert-dialog-slide-title"
              sx={{
                fontWeight: "700",
                color: "#1B2430",
                fontSize: "20px",
                letterSpacing: "0.5px",
                lineHeight: "28px",
              }}
            >
              {title}
            </DialogTitle>
            <IconButton
              onClick={() => handleClose()}
              aria-label="save"
              hover="none"
              sx={{ hover: "none", marginRight: "22px" }}
            >
              X{/* <Cancel /> */}
            </IconButton>
          </Stack>
          <Divider />
          <DialogContent>
            <Grid container>
              {isUploading ? (
                <Box
                  sx={{
                    p: 2,
                    width: "100%",
                    // /    border: "2px dashed #E0E0E0", /
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "204px",
                    borderWidth: "2px",
                    borderRadius: "1rem",
                    borderStyle: "dashed",
                    borderColor: "#cbd5e1",

                    // height: "70vh",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "15px",
                    }}
                  >
                    <AddAPhotoOutlinedIcon
                      sx={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        color: isUploading ? "#cbd5e1" : "#8D9298",
                      }}
                      disabled={isUploading}
                      // onClick={handleUploadButtonClick}
                    />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        marginTop: "8px",
                        fontWeight: "400",
                        color: isUploading ? "#cbd5e1" : "#8D9298",
                      }}
                    >
                      {t("Uploading_Images_Text")}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "14px",
                        marginTop: "8px",
                        fontWeight: "400",
                        color: isUploading ? "#cbd5e1" : "#8D9298",
                      }}
                    >
                      {t("or_key")}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "14px",
                        marginTop: "8px",
                        fontWeight: "400",
                        color: isUploading ? "#cbd5e1" : "#8D9298",
                      }}
                    >
                      {t("browse_file")}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    p: 2,
                    width: "100%",
                    // /    border: "2px dashed #E0E0E0", /
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "204px",
                    borderWidth: "2px",
                    borderRadius: "1rem",
                    borderStyle: "dashed",
                    borderColor: "#cbd5e1",

                    // height: "70vh",
                  }}
                >
                  <form
                    className={classes.formUpload}
                    onDragEnter={handleDrag}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "15px",
                      }}
                    >
                      <label
                        //  className={classes.lableFileUpload}
                        htmlFor="file-identifier3"
                      >
                        <input
                          type="file"
                          id="file-identifier3"
                          accept=".jpg, .png, .jpeg"
                          multiple={true}
                          onChange={onFileChangeCapture}
                          ref={fileInputRef}
                          style={{ display: "none" }}
                        />

                        <button
                          onClick={handleUploadButtonClick}
                          style={{ background: "none", border: "none" }}
                          disabled={isUploading}
                        >
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <AddAPhotoOutlinedIcon
                              sx={{
                                height: "40px",
                                width: "40px",
                                cursor: "pointer",
                                color: "#8D9298",
                              }}

                              // onClick={handleUploadButtonClick}
                            />
                          )}
                        </button>

                        <Typography
                          sx={{
                            fontSize: "14px",
                            marginTop: "8px",
                            fontWeight: "400",
                            color: "#8D9298",
                          }}
                        >
                          {t("Uploading_Images_Text")}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "14px",
                            marginTop: "8px",
                            fontWeight: "400",
                            color: "#8D9298",
                          }}
                        >
                          {t("or_key")}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "14px",
                            marginTop: "8px",
                            fontWeight: "400",
                            color: "#8D9298",
                          }}
                        >
                          {t("browse_file")}
                        </Typography>
                      </label>

                      {dragActive && (
                        <div
                          className={classes.dragFile}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </Box>
                  </form>
                </Box>
              )}
            </Grid>
            <Grid container sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <Typography className={classes.modelTypoHeading}>
                {t("Image_Guidelines")}
              </Typography>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.modelTypoGuildlinesGrid}
              >
                <Typography className={classes.modelTypoText}>
                  {tooltipText}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{ maxHeight: "300px", overflowY: "scroll", width: "100%" }}
            >
              {selectedImages && (
                <>
                  {selectedImages?.map((img, index) => {
                    return (
                      <Grid xs={12} sm={12} md={12} lg={12}>
                        <InteriorImageCard
                          img={img}
                          handleDelete={handleDelete}
                          locationId={locationId}
                          setPostData={setPostData}
                          handleClose={handleClose}
                          index={index}
                          isUploading={isUploading}
                          setIsUploading={setIsUploading}
                          postData={postData}
                          uploadedImages={uploadedImages}
                          setUploadedImages={setUploadedImages}
                          handleImageError={handleImageError}
                          selectedImages={selectedImages}
                          setSelectedImages={setSelectedImages}
                          setTouchedValue={setTouchedValue}
                          category={category}
                          validation={validation}
                        />
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </DialogContent>
        </>

        <DialogActions>
          <Grid container display="flex" justifyContent="flex-end">
            {selectedImages?.length > 0 && (
              <>
                <Button
                  sx={{
                    fontWeight: "700",
                    size: "large",
                    color: "#1B2430",
                    borderRadius: "8px",
                    padding: " 14px 20px",
                    border: "1px solid #E0E0E0",
                    hover: "none",
                  }}
                  variant="outlined"
                  // color="#1B2430"

                  onClick={() => {
                    handleClose();
                    setSelectedImages([]);
                    setUploadedImages([]);
                  }}
                >
                  {t("Cancel")}
                </Button>
                &nbsp; &nbsp;
                <CommonButton
                  disabled={
                    selectedImages?.length === 0 ||
                    selectedImages.find((item) => item?.error === true)
                      ? true
                      : false
                  }
                  onSubmit={() =>
                    uploadedImages?.length === selectedImages?.length
                      ? handleSubmitImages()
                      : setIsUploading(true)
                  }
                  // onSubmit={selectedImages.length > 0 && handleClose}
                  label={
                    uploadedImages?.length === selectedImages?.length
                      ? t("Submit")
                      : t("Upload")
                  }
                />
              </>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InteriorImagesModal;
