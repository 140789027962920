import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Checkbox,
  Paper,
  MenuItem,
  Divider,
  Menu,
  Button,
  Dialog,
} from "@mui/material";
import Loader from "../../../../../../components/Loaders/Loader";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton";
import { api } from "../../../../../../contexts/JWTContext";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CrossIcon } from "../../../../../../assets/Icons/crossIcon.svg";
import Delete from "../../../../../../components/Models/DeleteModal/Delete";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../styles/style";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { WarningAmberOutlined } from "@mui/icons-material";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { checkLocalStorageData } from "../../../../../../utils/LocalStorageHelpers";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ProductActivationFeatures = ({ }) => {
  const params = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDataValid = checkLocalStorageData();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const [companyFeatures, setCompanyFeatures] = useState([]);
  const [permissionsArr, setPermissionsArr] = useState([]);
  const [anchorBulk, setAnchorBulk] = useState(null);
  const [openSubscribeModel, setOpenSubscribeModel] = useState(false);
  const [openUnSubscribeModel, setOpenUnSubscribeModel] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [isLoading, setIsLoading] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [alreadySubscribed, setAlreadySubscribed] = useState([]);
  const [alreadyUnSubscribed, setAlreadyUnSubscribed] = useState([]);
  const [newlySubscribed, setNewlySubscribed] = useState([]);

  useEffect(() => {
    if (isDataValid) {
      getAllEnabledFeatures();
    }
  }, []);

  const getAllEnabledFeatures = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`companyFeatures/${companyData?.id}/features`);
      if (res.status === 200) {
        const filteredFeatures = res?.data?.data?.filter(feature => feature.name !== "Reports") || [] // filtering reports
        setCompanyFeatures(filteredFeatures);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSelectPermission = (featureData) => {
    let tempArr = [...permissionsArr];
    const findFeature = tempArr?.find(
      (item) => item?.name === featureData?.name
    );

    if (findFeature === undefined) {
      tempArr?.push(featureData);
    } else {
      tempArr = tempArr?.filter((item) => item?.name !== findFeature?.name);
    }

    setPermissionsArr(tempArr);
    if (tempArr?.length === companyFeatures?.length) {
      setSelectAllFeatures(true);
    } else {
      setSelectAllFeatures(false);
    }
  };

  const handleClose = () => {
    setAnchorBulk(null);
  };

  const handleClickMenu = (event) => {
    setAnchorBulk(event.currentTarget);
  };

  const handleOpenSubscribeModel = () => {
    setOpenSubscribeModel(true);
    handleClose();
  };
  const handleCloseSubscribeModel = () => {
    setOpenSubscribeModel(false);
  };

  const handleOpenUnSubscribeModel = () => {
    setOpenUnSubscribeModel(true);
    handleClose();
  };
  const handleCloseUnSubscribeModel = () => {
    setOpenUnSubscribeModel(false);
  };
  const handleCloseWarning = () => {
    setDisplayWarning(false);
    setAlreadySubscribed([]);
    setNewlySubscribed([]);
  };

  const handleSendSubscribeRequest = async () => {
    setSubscribeLoading(true);
    let data = [];
    let arr1 = [];
    let arr2 = [];
    if (permissionsArr?.length > 0) {
      permissionsArr?.map((item) => {
        if (
          item?.CompanyFeatures?.length > 0 &&
          item?.CompanyFeatures[0]?.subscribed === true
        ) {
          arr1.push(item?.name);
        } else {
          let obj = {
            appId: item?.id,
            subscribedRequested: true,
          };
          data.push(obj);
          arr2.push(item?.name);
        }
      });
    }
    try {
      const res = await api.put(`companyFeatures/request`, {
        companyId: companyData?.id,
        data: data,
      });

      if (res.status === 200) {
        toast.success(t("toast_subscriptions"));
        handleCloseSubscribeModel();
        setSubscribeLoading(false);
        getAllEnabledFeatures();
        if (arr1?.length > 0) {
          setDisplayWarning(true);
        } else {
          setDisplayWarning(false);
        }
        setPermissionsArr([]);
        setAlreadySubscribed(arr1);
        setNewlySubscribed(arr2);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setSubscribeLoading(false);
      handleCloseSubscribeModel();
    }
  };

  const handleSendUnSubscribeRequest = async () => {
    setSubscribeLoading(true);
    let data = [];
    let arr1 = [];
    let arr2 = [];
    if (permissionsArr?.length > 0) {
      permissionsArr?.map((item) => {
        if (
          item?.CompanyFeatures?.length === 0 ||
          (item?.CompanyFeatures?.length > 0 &&
            item?.CompanyFeatures[0]?.subscribed === true)
        ) {
          let obj = {
            appId: item?.id,
            unSubscribedRequested: true,
          };
          data.push(obj);
          arr1.push(item?.name);
        } else {
          arr2.push(item?.name);
        }
      });
    }
    try {
      const res = await api.put(`companyFeatures/request`, {
        companyId: companyData?.id,
        data: data,
      });

      if (res.status === 200) {
        toast.success(t("toast_subscriptions"));
        handleCloseUnSubscribeModel();
        setSubscribeLoading(false);
        getAllEnabledFeatures();
        if (arr1?.length > 0) {
          setDisplayWarning(true);
        } else {
          setDisplayWarning(false);
        }
        setPermissionsArr([]);
        setAlreadyUnSubscribed(arr1);
        setNewlySubscribed(arr2);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setSubscribeLoading(false);
      handleCloseSubscribeModel();
    }
  };

  const handleReset = () => {
    setPermissionsArr([]);
    getAllEnabledFeatures();
  };
  return (
    <>
      {openSubscribeModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openSubscribeModel}
          onClose={handleCloseSubscribeModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("subscribeProduct")}
            description={t("subscribeProductDes")}
            onConfirm={handleSendSubscribeRequest}
            onCancel={handleCloseSubscribeModel}
            schedule={true}
            loading={subscribeLoading}
          />
        </Dialog>
      )}
      {openUnSubscribeModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openUnSubscribeModel}
          onClose={handleCloseUnSubscribeModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("unsubscribeProduct")}
            description={t("unsubscribeProductDes")}
            onConfirm={handleSendUnSubscribeRequest}
            onCancel={handleCloseUnSubscribeModel}
            schedule={true}
          />
        </Dialog>
      )}
      {/*    {permissionsArr?.length > 0 && (
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sm={12}
          className={classes.ActionButtonGrid}
        >
          <Button
            variant="contained"
            color="primary"
            aria-owns={anchorBulk ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClickMenu}
            endIcon={<KeyboardArrowDownIcon sx={{ color: "#E0E0E0" }} />}
            className={classes.ActionButton}
          >
            {t("Actions")}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorBulk}
            open={Boolean(anchorBulk)}
            onClose={handleClose}
          >
            <MenuItem
              className={classes.menuItemStyle}
              onClick={(e) => handleOpenSubscribeModel()}
            >
              {t("Subscribe")}
            </MenuItem>

            <Divider />

            <MenuItem
              className={classes.menuItemStyle}
              onClick={(e) => handleOpenUnSubscribeModel()}
            >
              {t("Unsubscribe")}
            </MenuItem>
          </Menu>
        </Grid>
      )}
 */}
      {/*    {displayWarning && alreadySubscribed?.length > 0 && (
        <Box className={classes.toast}>
          <Box className={classes.toastBox}>
            <Box className={classes.toastBoxBorder}></Box>
            <Box className={classes.toastBoxContent}>
              <Box className={classes.toastBoxText}>
                <WarningAmberOutlined sx={{ color: "#FAB431" }} />

                <Typography>
                  {alreadySubscribed?.toString() +
                    t("subscribeWaringText") +
                    newlySubscribed?.toString() +
                    t("subscribeWaringTextTwo")}{" "}
                </Typography>
              </Box>

              <CloseIcon
                onClick={() => handleCloseWarning()}
                fontSize="small"
                className={classes.closeIconStyle}
              />
            </Box>
          </Box>
        </Box>
      )} */}
      {/*   {displayWarning && newlySubscribed?.length > 0 && (
        <Box className={classes.toast}>
          <Box className={classes.toastBox}>
            <Box className={classes.toastBoxBorder}></Box>
            <Box className={classes.toastBoxContent}>
              <Box className={classes.toastBoxText}>
                <WarningAmberOutlined sx={{ color: "#FAB431" }} />

                <Typography>
                  {newlySubscribed?.toString() +
                    t("unsubscribeWaringText") +
                    alreadyUnSubscribed?.toString() +
                    t("subscribeWaringTextTwo")}{" "}
                </Typography>
              </Box>

              <CloseIcon
                onClick={() => handleCloseWarning()}
                fontSize="small"
                className={classes.closeIconStyle}
              />
            </Box>
          </Box>
        </Box>
      )} */}

      <Paper className={classes.paperStyle}>
        {isLoading ? (
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
            }}
          >
            <Loader />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box className={classes.addUserBox}>
                <Typography className={classes.addUserFieldLable}>
                  {t("features_label")}
                </Typography>

                {/*    <Box>
                <Checkbox
                  name="selectAll"
                  color="primary"
                  checked={selectAllFeatures}
                  onChange={(e) => handleChangeSelectAllFeatures(e)}
                />
                {t("Select_All")}
              </Box> */}
              </Box>
            </Grid>

            <Grid container className={classes.featureGrid}>
              {companyFeatures?.length > 0 &&
                companyFeatures?.map((feature, ind) => {
                  return (
                    <Grid
                      xs={12}
                      sm={12}
                      lg={12}
                      md={12}
                      className={
                        feature?.CompanyFeatures?.length === 0 ||
                          (feature?.CompanyFeatures?.length > 0 &&
                            feature?.CompanyFeatures[0]?.subscribed === false &&
                            feature?.CompanyFeatures[0]?.subscribedRequested ==
                            false &&
                            feature?.CompanyFeatures[0]?.unSubscribedRequested ==
                            false)
                          ? classes.permissionGridGranted
                          : classes.permissionGrid
                      }
                      onClick={() => {
                        if (
                          feature?.CompanyFeatures?.length === 0 ||
                          (feature?.CompanyFeatures?.length > 0 &&
                            feature?.CompanyFeatures[0]?.subscribed === false &&
                            feature?.CompanyFeatures[0]?.subscribedRequested ==
                            false &&
                            feature?.CompanyFeatures[0]
                              ?.unSubscribedRequested === false)
                        ) {
                          handleSelectPermission(feature);
                        }
                      }}
                    >
                      <Box className={classes.permissionBoxStyle}>
                        <Box className={classes.permissionNameBox}>
                          <Typography className={classes.permissionName}>
                            {feature?.name}
                          </Typography>
                          {feature?.CompanyFeatures?.length > 0 &&
                            feature?.CompanyFeatures[0]?.subscribed === true &&
                            feature?.CompanyFeatures[0]
                              ?.unSubscribedRequested === false && (
                              <CheckCircleIcon
                                className={classes.activatedPermission}
                              />
                            )}

                          {feature?.CompanyFeatures?.length > 0 &&
                            feature?.CompanyFeatures[0]?.subscribedRequested ==
                            true && (
                              <Item className={classes.subscribeItemStyle}>
                                <Typography
                                  className={classes.subscribeItemTextStyle}
                                >
                                  {t("Subscription_request_sent")}
                                </Typography>
                              </Item>
                            )}
                          {/*   {feature?.CompanyFeatures?.length > 0 &&
                          feature?.CompanyFeatures[0]?.unSubscribedRequested ==
                            true && (
                            <Item className={classes.unsubscribeItemStyle}>
                              <Typography
                                className={classes.unsubscribeItemTextStyle}
                              >
                                Unsubscription request sent
                              </Typography>
                            </Item>
                          )} */}
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          {feature?.CompanyFeatures?.length > 0 &&
                            (feature?.CompanyFeatures[0]?.unSubscribedRequested ==
                              true ||
                              feature?.CompanyFeatures[0]?.subscribedRequested ==
                              true) ? (
                            <CrossIcon className={classes.crossIconstyle} />
                          ) : (
                            <Checkbox
                              checked={
                                permissionsArr?.length > 0 &&
                                permissionsArr?.find(
                                  (item) => item?.name === feature?.name
                                ) !== undefined
                              }
                              disabled={
                                feature?.CompanyFeatures?.length > 0 &&
                                feature?.CompanyFeatures[0]?.subscribed ==
                                true &&
                                feature?.CompanyFeatures[0]
                                  ?.unSubscribedRequested === false
                              }
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Paper>
      <Grid container className={classes.buttonContainer}>
        <CommonButton
          displayWhite="true"
          onSubmit={handleReset}
          label={t("Reset")}
        />

        <CommonButton
          label={t("Update")}
          onSubmit={handleOpenSubscribeModel}
          disabled={permissionsArr?.length === 0}
        />
      </Grid>
    </>
  );
};

export default ProductActivationFeatures;
