import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Modal,
  TextField,
  Autocomplete,
  Checkbox,
  MenuItem,
  Select,
  createFilterOptions,
  TableBody,
  Paper,
  TablePagination,
  TableContainer,
  Table,
  IconButton,
  Tooltip,
  tooltipClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import Loader from "../../../../components/Loaders/Loader";
import styled from "styled-components/macro";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import GoogleIcon from "@mui/icons-material/Google";
import { toast } from "react-toastify";
import { api } from "../../../../contexts/JWTContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import ReplayIcon from "@mui/icons-material/Replay";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  // maxHeight: "550px",
  // width: "500px",
  bgcolor: "background.paper",
  // maxHeight: "87vh",
  width: "650px",
  boxShadow: 24,
  p: 4,
  // overflowY: "scroll",
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const EnhancedTableHead = (props) => {
  const { t } = props;

  const headCells = [
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "name", alignment: "left", label: t("LocationTableName") },
    { id: "actions", alignment: "right", label: t("Actions") },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <CustomizedTableCell
            key={headCell?.id}
            align={headCell?.alignment}
            padding={headCell?.disablePadding ? "none" : "normal"}
          >
            <Typography sx={{ fontWeight: 600 }}>{headCell.label}</Typography>
          </CustomizedTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const AddBusinessModel = ({
  open,
  handleCloseAddEmailModel,
  allLocations,
  setAllLocations,
  handleOpenGoogleRetryModel,
  handleOpenGoogleModel,
  handleNavigation,
  setLocationId,
}) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { t } = useTranslation();
  const filter = createFilterOptions();

  const [inputValue, setInputValue] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [isLoading, setIsLoading] = useState([]);
  const [allModelLocations, setAllModelLocations] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const userData = JSON.parse(localStorage.getItem("user"));
  let timer = 0;

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={1}>
            <Grid item md={11.5}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "28px",
                  //  marginBottom: "8px",
                  // marginTop: "8px",
                }}
              >
                {t("Fix_Locations")}
              </Typography>
            </Grid>

            <Grid item md={0.5} sx={{ textAlign: "end" }}>
              <IconButton
                autoFocus
                onClick={handleCloseAddEmailModel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />

          <Grid
            container
            sx={{
              paddingTop: "10px",
              paddingBottom: "15px",
              height: "300px",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper}>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  t={t}
                  rowCount={rowCount}
                  selectAll={selectAll}
                />
                <TableBody>
                  {allLocations?.length > 0 ? (
                    allLocations?.map((row, index) => {
                      return (
                        <CustomizedTableRow
                          // hover
                          tabIndex={-1}
                          key={`${row.id}-${index}`}
                        >
                          <CustomizedTableCell sx={{ width: "50px" }}>
                            {row.id}
                          </CustomizedTableCell>
                          <CustomizedTableCell
                            align="left"
                            // sx={{ width: "220px" }}
                          >
                            {/* {row?.name ? row?.name + "(" + row?.city + ")" : ""} */}
                            <Typography>
                              {row?.internalName
                                ? row?.internalName +
                                  (row?.city !== null && row?.city !== undefined
                                    ? "(" + row?.city + ")"
                                    : "")
                                : ""}
                              <span
                                style={{
                                  textAlign: "center",
                                  marginLeft: "8px",
                                  color:
                                    row?.socialLink?.length === 0 &&
                                    row?.status === "approved"
                                      ? "#FC3652"
                                      : row?.status === "requested"
                                      ? "#99cc33"
                                      : row?.socialLink?.length > 0 &&
                                        row?.socialLink?.find(
                                          (item) =>
                                            item?.type === "google" &&
                                            item?.statusOfGoogleInsight ===
                                              "error"
                                        )
                                      ? "#F86F35"
                                      : row?.socialLink?.length > 0 &&
                                        row?.socialLink?.find(
                                          (item) =>
                                            item?.type === "google" &&
                                            item?.statusOfGoogleInsight ===
                                              "inProcess"
                                        ) !== undefined
                                      ? "#ffcc00"
                                      : row?.socialLink?.length > 0 &&
                                        row?.socialLink?.find(
                                          (item) =>
                                            item?.type === "google" &&
                                            item?.validToken === false
                                        ) !== undefined
                                      ? "#cc3300"
                                      : "",
                                }}
                              >
                                {row?.socialLink?.length === 0 &&
                                row?.status === "approved"
                                  ? `${t("disconnected")}`
                                  : ""}

                                {row?.status === "requested" &&
                                row?.socialLink?.length === 0
                                  ? `${t("requested_state")}`
                                  : ""}
                                {row?.socialLink?.length > 0 &&
                                row?.socialLink?.find(
                                  (item) =>
                                    item?.type === "google" &&
                                    item?.statusOfGoogleInsight === "error"
                                ) !== undefined
                                  ? `${t("Error")}`
                                  : ""}
                                {row?.socialLink?.length > 0 &&
                                row?.socialLink?.find(
                                  (item) =>
                                    item?.type === "google" &&
                                    item?.statusOfGoogleInsight === "inProcess"
                                ) !== undefined
                                  ? `${t("inProgress")}`
                                  : ""}
                                {row?.socialLink?.length > 0 &&
                                row?.socialLink?.find(
                                  (item) =>
                                    item?.type === "google" &&
                                    item?.validToken === false
                                ) !== undefined
                                  ? `${t("token_expired_text")}`
                                  : ""}
                              </span>
                            </Typography>
                          </CustomizedTableCell>
                          <CustomizedTableCell
                            align="right"
                            sx={{ width: "100px" }}
                          >
                            {row?.socialLink?.length === 0 &&
                            row?.status === "approved" ? (
                              <BootstrapTooltip
                                title={t("Connect_With_Google_Text")}
                              >
                                <IconButton
                                  aria-label="edit"
                                  size="large"
                                  onClick={() => {
                                    handleOpenGoogleModel();
                                  }}
                                >
                                  <GoogleIcon
                                    sx={{
                                      color: "#8D9298",
                                    }}
                                  />
                                </IconButton>
                              </BootstrapTooltip>
                            ) : row?.socialLink?.length > 0 &&
                              row?.socialLink?.find(
                                (itemTwo) =>
                                  itemTwo?.type === "google" &&
                                  itemTwo?.validToken === false
                              ) !== undefined ? (
                              <BootstrapTooltip title={t("token_expired")}>
                                <IconButton
                                  aria-label="edit"
                                  size="large"
                                  onClick={() => {
                                    handleNavigation(row);
                                  }}
                                >
                                  <GoogleIcon
                                    sx={{
                                      color: "#FF1616",
                                    }}
                                  />
                                </IconButton>
                              </BootstrapTooltip>
                            ) : row?.socialLink?.length > 0 &&
                              row?.socialLink?.find(
                                (itemTwo) =>
                                  itemTwo?.type === "google" &&
                                  itemTwo?.validToken === true &&
                                  itemTwo?.statusOfGoogleInsight === "error"
                              ) !== undefined ? (
                              <BootstrapTooltip title={t("Retry_With_Google")}>
                                <IconButton
                                  sx={{
                                    //  width: "20px",
                                    //  height: "18px",
                                    color: "#06BDFF",
                                    //  marginRight: "3px",
                                  }}
                                  // disabled={isLoading ? true : false}
                                  aria-label="retry"
                                  size="large"
                                  onClick={() =>
                                    handleOpenGoogleRetryModel(row?.id)
                                  }
                                >
                                  <ReplayIcon />
                                </IconButton>
                              </BootstrapTooltip>
                            ) : (
                              ""
                            )}
                          </CustomizedTableCell>
                        </CustomizedTableRow>
                      );
                    })
                  ) : (
                    <Typography variant="h3">{t("data_found")}</Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default AddBusinessModel;
