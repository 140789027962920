import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Paper as MuiPaper,
  Autocomplete,
  Divider as MuiDivider,
  TextField as MuiTextField,
  Checkbox,
  Button,
  Modal,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";
import Loader from "../../../../components/Loaders/Loader";
import styled from "styled-components/macro";
import SearchIcon from "@mui/icons-material/Search";
import MoreVert from "@mui/icons-material/MoreVert";
import InputBase from "@material-ui/core/InputBase";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { spacing, style } from "@mui/system";
import { api } from "../../../../contexts/JWTContext";
import Editors from "../../../../components/CustomComponents/Editor/Editors";
import ThemeButton from "../../../../components/CustomComponents/Buttons/ThemeButton";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useStyles } from "../styles/style";
// import { Title } from 'chart.js';

const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const AddTask = ({ onCancel, task, isSubmitting }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [Submitting, setSubmitting] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [selectedDueDate, setSelectedDueDate] = useState("7");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState(null);
  const [getTask, setGetTask] = useState([]);
  const [getStatus, setGetStatus] = useState();
  const [status, setStatus] = useState("open");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [companyIdRequired, setCompanyIdRequired] = useState(false);
  const [titleRequired, setTitleRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  const [userRequired, setUserRequired] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  useEffect(async () => {
    getAllCompanies();
  }, [companyId]);

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleCompanyChange = (option) => {
    if (option !== null) {
      // getAllCompanies();
      setCompanyId(option);
      setLocationId(null);
      if (allCompanies?.results?.locations?.length !== 0) {
        setSelectedCompany(option?.locations);
        setUsers(option?.users);
      } else {
        setUsers([]);
        setSelectedCompany(null);
      }
    } else {
      setUsers([]);
      setSelectedCompany(null);
    }
  };

  const handleLocationChange = (option) => {
    setLocationId(option);
  };
  {
  }
  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/admin/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
      }
    } catch (error) {}
  };

  const createTask = async () => {
    if (title === undefined) {
      setTitleRequired(true);
    }

    if (!description) {
      setDescriptionRequired(true);
    }
    if (locationId === undefined) {
      setLocationRequired(true);
    }
    if (companyId === undefined) {
      setCompanyIdRequired(true);
    }
    if (selectedUser?.length === 0) {
      setUserRequired(true);
    }
    let userIds = [];
    selectedUser?.map((item) => {
      userIds.push(item?.id);
    });
    const data = {
      title: title,
      description: description,
      dueDate: selectedDueDate,
      companyId: companyId?.id,
      locationId: locationId?.id,
      status: status,
      userIds: userIds?.length > 0 ? userIds.toString() : "",
    };

    if (
      description !== undefined &&
      title !== undefined &&
      locationId !== undefined &&
      companyId !== undefined
    ) {
      try {
        setIsLoading(true);

        const res = await api.post("/tasks", {
          title: title,
          description: description,
          companyId: companyId?.id,
          locationId: locationId?.id,
          status: status,
          dueDate: selectedDueDate,
          userIds: userIds?.length > 0 ? userIds.toString() : "",
          // getStatus: task.status,
        });
        if (res.status === 201) {
          toast.success("Task Added Successfully", {
            onClose: () => {
              setIsLoading(false);

              onCancel(true);
            },
          });
          // setAllCompanies(res.data.data);
        }
      } catch (error) {
        setIsLoading(false);
        setSubmitting(false);
        toast.error("error");
      }
    }

    //
  };
  const handleUserChange = (option) => {
    setSelectedUser(option);
  };

  return (
    <>
      <Box className={classes.mainBoxx}>
        <Typography className={classes.title}>{t("AddTask")}</Typography>
      </Box>
      <Divider my={4} />

      <Box className={classes.allfieldss}>
        <Box sx={{ overflowY: "scroll", maxHeight: "65vh" }}>
          <Box>
            <Typography className={classes.taskTitle}>
              {t("Task_Tile")}
            </Typography>
            <TextField
              fullWidth
              error={titleRequired}
              value={title}
              placeholder={t("Enter_Title")}
              onChange={(e) => {
                setTitleRequired(false);
                setTitle(e.target.value);
              }}
              InputProps={{
                inputProps: {
                  style: {
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "100%",
                  },
                },
              }}
              margin="normal"
              // label={t("TitleForm")}
            />
          </Box>
          <Box>
            <Typography className={classes.locations}>{t("Status")}</Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                defaultValue={status}
                // label={"Title"}
                onChange={handleChangeStatus}
              >
                <MenuItem value={"open"}>{t("Open")}</MenuItem>
                <MenuItem value={"inprogress"}>{t("InProgress")}</MenuItem>
                <MenuItem value={"completed"}>{t("Completed")}</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={task}
        getOptionLabel={(option) => option.status}
        sx={{ width: "100%" }}
        // value={companyId}
        onChange={(e, value) => {

          // handleCompanyChange(value);
        }}
        renderInput={(params) => (
          <TextField {...params} required label="Status" name="Status" />
        )}
      ></Autocomplete> */}
          <Typography className={classes.locations}>{t("Company")}</Typography>

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={allCompanies?.results}
            getOptionLabel={(option) => option?.name}
            sx={{ width: "100%" }}
            value={companyId}
            onChange={(e, value) => {
              handleCompanyChange(value);
              setCompanyIdRequired(false);
            }}
            renderInput={(params) => (
              <TextField
                placeholder={t("Select_Company")}
                {...params}
                required
                // label={t("TaskCompany")}
                name="language"
                error={companyIdRequired}
              />
            )}
          ></Autocomplete>
          <Typography className={classes.locations}>
            {t("Select_User")}
          </Typography>
          <Autocomplete
            error={userRequired}
            disablePortal
            multiple
            id="combo-box-demo"
            options={users ? users : []}
            getOptionLabel={(option) =>
              option?.firstName ? option.firstName : ""
            }
            sx={{ width: "100%" }}
            value={selectedUser}
            onChange={(e, value) => {
              handleUserChange(value);
              setUserRequired(false);
            }}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.firstName + " " + option?.lastName}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                placeholder={t("Select_User")}
                {...params}
                required
                // label={t("TaskLocation")}
                error={userRequired}
                name="language"
              />
            )}
          ></Autocomplete>
          <Typography className={classes.locations}>
            {t("Select_Locations")}
          </Typography>

          <Autocomplete
            error={locationRequired}
            disablePortal
            id="combo-box-demo"
            options={selectedCompany?.length > 0 ? selectedCompany : []}
            // getOptionLabel={(option) => (option?.internalName ? option?.internalName : "")}
            getOptionLabel={(option) =>
              option?.internalName
                ? option?.internalName +
                  " " +
                  "(" +
                  (option?.addressLine1 !== "" &&
                  option?.addressLine1 !== null &&
                  option?.addressLine1 !== undefined
                    ? option?.addressLine1 + "," + " "
                    : "") +
                  (option?.city ? option?.city : "") +
                  ")"
                : ""
            }
            sx={{ width: "100%" }}
            value={locationId}
            onChange={(e, value) => {
              handleLocationChange(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                placeholder={t("Select_Location")}
                name="language"
                error={locationRequired}
              />
            )}
          ></Autocomplete>
          <Grid container>
            <Typography
              // className="Description"
              className={classes.locations}
            >
              {t("Duee_Date")}
            </Typography>
            <Select
              required
              style={{ width: "100%" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedDueDate}
              onChange={(e, value) => {
                setSelectedDueDate(e.target.value);
              }}
            >
              <MenuItem value={"3"}>{t("3-Days")}</MenuItem>
              <MenuItem value={"7"}>{t("7-Days")}</MenuItem>
            </Select>
            <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
          </Grid>
          <Grid container>
            <Typography
              sx={{
                mt: "8px",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#1B2430",
                width: "438px",
                height: "20px",
              }}
            >
              {t("Description")}
            </Typography>

            <Grid item xs={12} sm={12} md={12}>
              <Editors
                value={description}
                onChange={(e) => {
                  setDescription(e);
                  setDescriptionRequired(false);
                }}
              />
              {descriptionRequired && (
                <Typography sx={{ color: "#d32f2f" }}>
                  {t("Field_req")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ alignItems: "flex-end", marginTop: "8px" }}>
          <Grid
            item
            md={12}
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            {/*  <LoadingButton
              onClick={() => {
                createTask();
              }}
              // sx={{ ml: 4 }}
              // type="submit"
              variant="contained"
              loading={isLoading}
              // disabled={isSubmitting}
              style={{
                width: "100%",

                color: "primary",
                fontWeight: "600",
                borderRadius: "8px",
                fontSize: "1.25rem",
                lineHeight: 1.5,
                padding: "0.5rem 1rem",
              }}
            >
              {t("Add_Task")}
            </LoadingButton> */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CommonButton
                displayWhite="true"
                onSubmit={onCancel}
                label={t("Cancel")}
              />

              <CommonButton
                onSubmit={() => {
                  createTask();
                  setSubmitting(true);
                }}
                type="submit"
                disabled={Submitting}
                loading={isLoading}
                label={t("Add_Task")}
              />
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AddTask;
