import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
} from "@mui/material";
import FetchModels from "./FetchModels.css";

import { useTranslation } from "react-i18next";

const FetchModel = ({ title, description }) => {
  const [open, setOpen] = useState(false);
  const [deletePost, setDeletePost] = useState(null);
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Box className="box_delete">
          <DialogTitle
            sx={{
              lineHeight: "28px",
              fontWeight: 700,
              fontSize: "20px",
              color: "#1b2430",
              padding: "12px",
            }}
          >
            {title}
          </DialogTitle>
          <Divider />
          <DialogContentText
            id="responsive-dialog-title"
            className="delete_dialog_content2"
          >
            {description}
          </DialogContentText>
        </Box>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default FetchModel;
