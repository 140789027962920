import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Checkbox } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../../../styles/style";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === "dark"
  //     ? "rgba(255, 255, 255, .05)"
  //     : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CreateRoleFeature = ({
  selectAllChecked,
  setSelectAllChecked,
  expanded,
  setExpanded,
  checkboxes,
  setCheckboxes,
  companyFeatures,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSelectAllChange = (event) => {
    setSelectAllChecked(event.target.checked);
    setCheckboxes({
      view: event.target.checked,
      add: event.target.checked,
      edit: event.target.checked,
      delete: event.target.checked,
    });
  };

  const handleCheckboxChange = (name, checked) => {
    setCheckboxes({ ...checkboxes, [name]: checked });
    setSelectAllChecked(
      Object.values({ ...checkboxes, [name]: checked }).every(Boolean)
    );
  };
  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: "1.125rem" }}>
              {"Features"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {companyFeatures?.length > 0 &&
        companyFeatures?.map((feature) => (
          <Box>
            <Accordion defaultExpanded={true} expanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={handleAccordionToggle}
              >
                <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
                  {feature?.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2.4}>
                    <Box className={classes.socialBoxUsers}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        name="view"
                        color="primary"
                        checked={checkboxes.view}
                        onChange={(e) =>
                          handleCheckboxChange("view", e.target.checked)
                        }
                      />
                      {t("Add_label")}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2.4}>
                    <Box className={classes.socialBoxUsers}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        name="add"
                        color="primary"
                        checked={checkboxes.add}
                        onChange={(e) =>
                          handleCheckboxChange("add", e.target.checked)
                        }
                      />
                      {"Add"}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2.4}>
                    <Box className={classes.socialBoxUsers}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        name="edit"
                        color="primary"
                        checked={checkboxes.edit}
                        onChange={(e) =>
                          handleCheckboxChange("edit", e.target.checked)
                        }
                      />
                      {"Edit"}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2.4}>
                    <Box className={classes.socialBoxUsers}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        name="delete"
                        color="primary"
                        checked={checkboxes.delete}
                        onChange={(e) =>
                          handleCheckboxChange("delete", e.target.checked)
                        }
                      />
                      {"Delete"}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2.4}>
                    <Box className={classes.socialBoxUsers}>
                      <Checkbox
                        sx={{ marginTop: "4px" }}
                        name="selectAll"
                        color="primary"
                        checked={selectAllChecked}
                        onChange={handleSelectAllChange}
                      />
                      {"Select All"}
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
    </>
  );
};

export default CreateRoleFeature;
