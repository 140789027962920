import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Zoom,
  Stack,
  styled,
  Divider,
  IconButton,
} from "@mui/material";
import { api } from "../../../../../contexts/JWTContext";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../Styles/style";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));

Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  paddingY: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const defaultCrop = {
  aspect: 21 / 9,
  x: 0,
  y: 0,

  width: 500,
  height: 500,
};
const CropImgModalStep2 = ({
  open,
  setUploadImgData,
  onClose,
  uploadImgData,
  setOpenCropModal,
  cropModalImage,
  setSurveyImageDisplayStepThree,
  stepperData,
  setStepperData,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState(defaultCrop);
  const [cropperLoading, setCropperLoading] = useState(false);

  const companyData = JSON.parse(localStorage.getItem("company"));
  useEffect(() => {
    if (image) {
      const defaultCrop = {
        aspect: 21 / 9,
        x: 0,
        y: 0,

        width: image?.width,
      };
      setCrop(defaultCrop);
    }
  }, [image]);
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleImgSubmit = async () => {
    setCropperLoading(true);
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = 6;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    canvas.style.width = crop.width * window.devicePixelRatio + "px";
    canvas.style.height = crop.height * window.devicePixelRatio + "px";
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    if (!uploadImgData?.image_url) {
      //  setPictureRequired(true);
    } else {
      //setPictureRequired(false);
    }
    const croppedImageDataURL = canvas.toDataURL();

    try {
      const croppedImageFileData = dataURLtoFile(
        croppedImageDataURL,
        `cropped-image-file${new Date()}`
      );
      let formData = new FormData();

      formData.append("photos", croppedImageFileData);

      const res = await api.post(
        `/google/post/reviewRequestEmail/${
          companyData !== null && companyData?.id
        }`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            // const progress = (progressEvent.loaded / progressEvent.total) * 50;
          },
          onDownloadProgress: (progressEvent) => {
            /* const progress =
              50 + (progressEvent.loaded / progressEvent.total) * 50; */
          },
        }
      );

      if (res.status === 200) {
        if (res?.data?.locationArray?.length > 0) {
          setStepperData({
            ...stepperData,
            emailTemplateData: {
              image_url: res?.data?.filesArray[0]?.location,
              heading: stepperData?.emailTemplateData?.heading,
              description: stepperData?.emailTemplateData?.description,
            },
          });
          // setOpenPictureModal(false);
          setSurveyImageDisplayStepThree(croppedImageDataURL);
        }
      }
    } catch (error) {}

    setSurveyImageDisplayStepThree(croppedImageDataURL);

    setUploadImgData({
      ...uploadImgData,
      image_url: croppedImageDataURL,
    });
    await setTimeout(() => {
      setCropperLoading(false);
      setOpenCropModal(false);
    }, 1000);
  };

  const onImageLoaded = (img) => {
    setImage(img);
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: "100vh",
              maxWidth: "800px",

              borderRadius: "8px",
            },
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DialogTitle
            sx={{
              color: "#1B2430",
              fontSize: "20px",
              marginBottom: "20px",
              fontWeight: "700",
            }}
            id="alert-dialog-slide-title"
          >
            {t("Crop_campaign_image")}
          </DialogTitle>
          <IconButton
            onClick={() => onClose()}
            aria-label="save"
            hover="none"
            sx={{
              hover: "none",
              marginBottom: "15px",
              marginRight: "22px",
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Stack>

        <DialogContent>
          <Stack direction="column">
            <Item>
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ReactCrop
                    src={cropModalImage}
                    onImageLoaded={onImageLoaded}
                    onChange={(event) => onCropChange(event)}
                    aspectRatio={21 / 9}
                    keepSelection={true}
                    crop={crop}
                    minWidth={500}
                    minHeight={214}
                    style={{
                      borderRadius: "8px",
                    }}
                  ></ReactCrop>
                </Box>
              </>
            </Item>
          </Stack>
        </DialogContent>

        <Divider />
        <DialogActions>
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            paddingTop="8px"
          >
            <CommonButton
              displayWhite="true"
              onSubmit={() => onClose()}
              label={t("Cancel")}
            />
            <LoadingButton
              name="Save "
              className={classes.cropperButton}
              variant="contained"
              size="large"
              onClick={handleImgSubmit}
              loading={cropperLoading}
            >
              {t("Ok")}
            </LoadingButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CropImgModalStep2;
