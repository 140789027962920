import React, { useState, useEffect } from "react";
import EditModal from "../../Modal";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Dialog,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import Apple from "../../../../../../assets/images/Apple.svg";
import Buisness from "../../../../../../assets/images/Buisness.svg";
import Maestro from "../../../../../../assets/images/Maestro.svg";
import Ec from "../../../../../../assets/images/Ec.svg";
import Visa from "../../../../../../assets/images/Visa.svg";
import Debit from "../../../../../../assets/images/Debit.svg";
import { useStyles } from "../../../Styles/style";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Paymentoptions() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  return (
    <>
      {openDefaultModel && (
        <EditModal
          open={openDefaultModel}
          modalIndex={11}
          onCancel={handleCloseTemplateMenu}
        />
      )}
      <Card variant="outlined" className={classes.listingCard}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container sx={{ padding: "16px" }}>
              <Grid item xs={8} sm={8} md={9} lg={10}>
                <Typography className={classes.titleName}>
                  Payment Options
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={3} lg={2}>
                <Button fullWidth>
                  <Typography
                    onClick={handleOpenTemplateMenu}
                    sx={{ fontWeight: 600 }}
                  >
                    {t("Edit")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem />

            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  textAlign: "center",
                  padding: "8px",
                  borderRight: "1px solid #E5E5E5",
                  borderBottom: "1px solid #E5E5E5",
                }}
              >
                <img alt="" src={Apple} />
              </Grid>

              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  textAlign: "center",
                  padding: "8px",
                  borderLeft: "1px solid #E5E5E5",
                  borderBottom: "1px solid #E5E5E5",
                }}
              >
                <img alt="" src={Buisness} />
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  textAlign: "center",
                  padding: "8px",
                  borderRight: "1px solid #E5E5E5",
                  borderBottom: "1px solid #E5E5E5",
                }}
              >
                <img alt="" src={Ec} />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  textAlign: "center",
                  padding: "8px",
                  borderLeft: "1px solid #E5E5E5",
                  borderBottom: "1px solid #E5E5E5",
                }}
              >
                <img alt="" src={Debit} />
              </Grid>
            </Grid>
            <Divider variant="middle" />
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  textAlign: "center",
                  padding: "8px",
                  borderRight: "1px solid #E5E5E5",
                  borderBottom: "1px solid #E5E5E5",
                }}
              >
                <img alt="" src={Maestro} />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  textAlign: "center",
                  padding: "8px",
                  borderLeft: "1px solid #E5E5E5",
                  borderBottom: "1px solid #E5E5E5",
                }}
              >
                <img alt="" src={Visa} />
              </Grid>
            </Grid>
            <Divider variant="middle" />
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button fullWidth>
                <Typography sx={{ fontWeight: 600, textAlign: "Left" }}>
                  {t("View_More")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
export default Paymentoptions;
