import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../Styles/style";
import CloseIcon from "@mui/icons-material/Close";

const KeywordField = ({ data, setKeywordsMain, setTouchedValue }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [keywords, setKeywords] = useState(data.keywords);
  const [duplicateKeywords, setDuplicateKeywords] = useState(false);

  useEffect(() => {
    setKeywords(data.keywords);
  }, [data]);

  useEffect(() => {
    updateMain();
  }, [keywords]);

  const updateMain = () => {
    setKeywordsMain((prevKeywordsMain) =>
      prevKeywordsMain.map((item) =>
        item.id === data.id ? { ...item, keywords } : item
      )
    );
  };

  const handleAddKeywords = (e) => {
    if (e.target.value.includes(",") && e.target.value.includes("\n")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(/[\n,]+/);
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(keywords);
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(",")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(",");
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(keywords);
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(".")) {
      const lastDotRemoved = e?.target?.value.replace(/\.+$/, "");
      const arr = lastDotRemoved.split(".");
      arr.concat(keywords);
      const mergeResult = [...keywords, ...arr];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    }
    const lowerCaseBrandTags = keywords.map((tag) => tag.toLowerCase());
    if (lowerCaseBrandTags.includes(e.target.value.toLowerCase())) {
      setDuplicateKeywords(true);
    } else {
      setDuplicateKeywords(false);
    }
  };

  const removeKeyword = (index) => {
    setKeywords(keywords.filter((el, i) => i !== index));
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter" || e.target.value.trim() === "") return;

    let value = e.target.value.toLowerCase().trim();
    if (value.includes("\n") && value !== "\n") {
      const lastLineBreakRemoved = value.replace(/\n+$/, "");
      const arr = lastLineBreakRemoved.split("\n");
      const filtered = arr.filter((item) => item?.trim() !== "");
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);

      e.target.value = "";
    } else {
      if (!value.trim()) return;
      const mergeResult = [...keywords, value];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
    }
    const lowerCaseBrandTags = keywords.map((tag) => tag.toLowerCase());
    const lastLineBreakRemoved = value.replace(/\n+$/, "");
    const arr = lastLineBreakRemoved.split("\n");
    const filtered = arr.filter((item) => item?.trim() !== "");
    if (lowerCaseBrandTags.includes(value.toLowerCase())) {
      setDuplicateKeywords(true);
      setTimeout(() => {
        setDuplicateKeywords(false);
      }, 2000);
    } else {
      for (let i = 0; i < keywords.length; i++) {
        if (filtered.includes(keywords[i])) {
          setDuplicateKeywords(true);
          setTimeout(() => {
            setDuplicateKeywords(false);
          }, 2000);
          break;
        }
      }
    }

    e.target.value = "";
  };
  return (
    <Grid item md={12} sm={12} xs={12} lg={12} sx={{ marginTop: "12px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "4px" }}
      >
        <Typography className={classes.fieldTextDescription}>
          {data.name}
        </Typography>
        <Typography className={classes.limitFieldTextDescription}>
          {keywords === "" || keywords?.length === 0 ? 0 : keywords?.length}
          /20
        </Typography>
      </Box>
      <Grid className={classes.tagsInputContainer}>
        <TextField
          variant="standard"
          type="text"
          className={classes.tagsInputBulk}
          InputProps={{
            disableUnderline: true,
          }}
          placeholder={t("Keyword_Input")}
          autoComplete="none"
          fullWidth
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setTouchedValue(true);
            if (
              e.target.value === "," ||
              e.target.value === "." ||
              e.target.value.trim() === ""
            ) {
              e.target.value = "";
            } else if (
              (e.target.value !== "" &&
                e.target.value !== "," &&
                e.target.value.includes(".")) ||
              (e.target.value !== "." &&
                e.target.value !== "" &&
                e.target.value.includes(","))
            ) {
              handleAddKeywords(e);
            }
          }}
          disabled={keywords.length >= 20}
        />
        {keywords?.length > 0 &&
          keywords.map((keywords, index) => (
            <Grid className={classes.tagItem} key={index}>
              <Box className={classes.keywordBox}>
                <Typography className={classes.keywordTypo}>
                  {keywords}
                </Typography>
                <CloseIcon
                  className={classes.keywordIcon}
                  onClick={() => removeKeyword(index)}
                />
              </Box>
            </Grid>
          ))}
      </Grid>
      <Typography
        sx={{
          padding: "4px",
          color: "gray",
          fontSize: "10px",
        }}
      >
        {t("Enter_Keyword_text")}
      </Typography>
      {duplicateKeywords && (
        <Typography sx={{ color: "red", fontSize: "12px" }}>
          {t("keywords_duplicated_error")}
        </Typography>
      )}
    </Grid>
  );
};

export default KeywordField;
