import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  TextField,
  Box,
  DialogContent,
  Dialog,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";

import { useTranslation } from "react-i18next";

import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { countriesCurrencyData } from "../../../../../../../../assets/countryCurrency";

import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
  fieldTextService: {
    fontSize: "14px",
    fontWeight: "700",
  },
  textLimitTypo: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#FF0000",
    lineHeight: "15px",
    marginBottom: "8px",
  },
}));

const FetchModel = ({
  serviceItem,
  title,
  open,
  handleCloseNewRequestModel,
  serviceSuggestions,
  location,
  setServiceItems,
  editableServiceIndex,
  serviceItems,
  suggestions,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [serviceDescription, setServiceDescription] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [addServiceValue, setAddServiceValue] = useState(null);
  const [servicePriceValue, setServicePriceValue] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [serviceUpdateLoading, setServiceUpdateLoading] = useState(false);
  const [priceValRequired, setPriceValRequired] = useState(false);
  const [currencyValRequired, setCurrencyValRequired] = useState(false);
  const [duplicateService, setDuplicateService] = useState(false);
  const [priceObjRequired, setPriceObjRequired] = useState(false);
  const [uniqueCurrencyCode, setUniqueCurrencyCode] = useState([]);

  const [displayName, setDisplayName] = useState("");
  const handleChangeServiceDescription = (event) => {
    setServiceDescription(event.target.value);
  };

  useEffect(() => {
    const serviceType =
      serviceItem.freeFormServiceItem?.label.displayName ||
      getServiceDisplayName(serviceItem.structuredServiceItem.serviceTypeId);

    function getServiceDisplayName(serviceTypeId) {
      const suggestion = serviceSuggestions.find(
        (suggestion) => suggestion.serviceTypeId === serviceTypeId
      );
      return suggestion ? suggestion.displayName : "Unknown Service";
    }

    setAddServiceValue(serviceType);
    setServiceDescription(
      serviceItem?.structuredServiceItem?.description ||
        serviceItem?.freeFormServiceItem?.label?.description
    );
    if (serviceItem?.price?.units) {
      setServicePriceValue(serviceItem?.price?.units);
    }
    if (serviceItem?.price?.currencyCode) {
      setCurrency(serviceItem?.price?.currencyCode);
    }
    setDisplayName(serviceItem?.freeFormServiceItem?.label?.displayName);

    if (serviceItem?.price?.units && serviceItem?.price?.currencyCode) {
      setServicePrice("fixed");
    } else {
      setServicePrice("no_price");
    }

    const uniqueCurrencyMap = {};
    countriesCurrencyData.forEach((item) => {
      if (!uniqueCurrencyMap[item.currency_code]) {
        uniqueCurrencyMap[item.currency_code] = item;
      }
    });

    const uniqueCurrencyData = Object.values(uniqueCurrencyMap).sort((a, b) =>
      (a.currency_code || "").localeCompare(b.currency_code || "")
    );

    setUniqueCurrencyCode(uniqueCurrencyData);
  }, []);

  const handleUpdateService = async () => {
    setServiceUpdateLoading(true);

    const updatedServiceItems = [...serviceItems];
    let newService = {};
    if (serviceItem.hasOwnProperty("structuredServiceItem")) {
      newService = {
        structuredServiceItem: {
          serviceTypeId: serviceItem?.structuredServiceItem?.serviceTypeId,
          description: serviceDescription?.trim(),
        },
      };
    } else {
      newService = {
        freeFormServiceItem: {
          category: serviceItem?.freeFormServiceItem?.category,
          label: {
            displayName: addServiceValue.trim(),
            description: serviceDescription?.trim(),
          },
        },
      };
    }

    if (currency !== null && servicePriceValue !== null) {
      newService = {
        ...newService,
        price: {
          currencyCode: currency,
          units: servicePriceValue,
        },
      };
    }
    updatedServiceItems[editableServiceIndex] = newService;

    if (servicePrice === "no_price" || servicePrice === "free") {
      setPriceObjRequired(false);
      toast.success("Service updated successfully");
      setServiceItems(updatedServiceItems);
      setAddServiceValue(null);
      setServicePriceValue(null);
      setCurrency("");
      // setServiceUpdateLoading(false);
    } else {
      if (currency === null || servicePriceValue === null) {
        setPriceObjRequired(true);
        toast.error("Unable to save Service");
      } else {
        setPriceObjRequired(false);
        toast.success("Service updated successfully");
        setServiceItems(updatedServiceItems);
        setAddServiceValue(null);
        setServicePriceValue(null);
        setCurrency("");
      }
    }

    handleCloseNewRequestModel();

    setServiceUpdateLoading(false);
    // setServicePrice("");
    // setAddServiceValue(null);
    // setServicePriceValue(null);
    // setCurrency("");
    // handleCloseNewRequestModel();
  };
  const getDisplayName = (suggestions, id) => {
    const displayName = suggestions?.filter(
      (suggestion) => suggestion?.serviceTypeId === id
    );
    return displayName[0]?.displayName || null;
  };

  const handleChangeService = (event) => {
    setAddServiceValue(event.target.value);

    let textWithoutSpaces = event?.target?.value?.replace(/\s+/g, "");
    const isDuplicate = serviceItems.some((item) => {
      const serviceTypes =
        item?.freeFormServiceItem?.label?.displayName
          ?.toLowerCase()
          ?.replace(/\s+/g, "") ||
        getDisplayName(suggestions, item?.structuredServiceItem?.serviceTypeId)
          ?.replace(/\s+/g, "")
          ?.toLowerCase();

      return serviceTypes === textWithoutSpaces?.toLowerCase();
    });

    setDuplicateService(isDuplicate);
  };
  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "100%",
              maxWidth: "850px",
              height: "480px",
            },
          }}
          open={open}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 16px",
            }}
          >
            <Typography
              sx={{
                lineHeight: "28px",
                fontWeight: 700,
                fontSize: "20px",
                color: "#1b2430",
                marginTop: "3px",
              }}
            >
              {title}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            {" "}
            <Typography className={classes.fieldTextService}>
              {t("service")}
            </Typography>
            <Grid container>
              <Grid item xs={12} md={12} lg={12} mt={1}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder={t("add_service")}
                  disabled={serviceItem.hasOwnProperty("structuredServiceItem")}
                  value={addServiceValue}
                  fullWidth
                  onChange={handleChangeService}
                />
                {duplicateService ? (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {t("Service_already_exists")}
                  </Typography>
                ) : !addServiceValue?.trim() ? (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {t("Field_req")}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={12} mt={5}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <FormControl fullWidth>
                      {/* <InputLabel
                        id="demo-simple-select-helper-label"
                        sx={{
                          color: "#A2A2A2",
                        }}
                      >
                        {t("price")}
                      </InputLabel> */}
                      <Select
                        labelId="demo-simple-select-helper-label"
                        // label="price"
                        id="demo-simple-select-helper"
                        value={servicePrice}
                        onChange={(e) => {
                          if (
                            e.target.value === "no_price" ||
                            e.target.value === "free"
                          ) {
                            setServicePriceValue("");
                            setCurrency(null);
                            setPriceObjRequired(false);
                          } else {
                            setPriceObjRequired(true);
                          }
                          setServicePrice(e.target.value);
                        }}
                        sx={{ width: "100%" }}
                      >
                        <MenuItem value="no_price">{t("no_price")}</MenuItem>
                        <MenuItem value="free">{t("free")}</MenuItem>
                        <MenuItem value="fixed">{t("fixed")}</MenuItem>
                        <MenuItem value="from">{t("from")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      // error={priceObjRequired}
                      placeholder={t("service_price")}
                      value={servicePriceValue}
                      disabled={
                        servicePrice === "no_price" || servicePrice === "free"
                      }
                      inputProps={{
                        inputMode: "numeric",
                        type: "number",
                      }}
                      fullWidth
                      // helperText={
                      //   priceObjRequired ? "This field is required" : ""
                      // }
                      onChange={(event) => {
                        const newValue = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        setServicePriceValue(newValue);

                        if (newValue?.length > 0 && currency?.length > 0) {
                          setPriceObjRequired(false);
                          // setServiceUpdateLoading(false);
                        } else {
                          setPriceObjRequired(true);
                          // setServiceUpdateLoading(false);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        sx={{
                          color: "#A2A2A2",
                        }}
                      >
                        {"e.g USD"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        label="e.g USD"
                        id="demo-simple-select-helper"
                        value={currency}
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          setCurrency(selectedValue);
                          if (
                            selectedValue.length > 0 &&
                            servicePriceValue?.length > 0
                          ) {
                            setPriceObjRequired(false);
                            // setServiceUpdateLoading(false);
                          } else {
                            setPriceObjRequired(true);
                            // setServiceUpdateLoading(false);
                          }
                        }}
                        disabled={
                          servicePrice === "no_price" || servicePrice === "free"
                        }
                        sx={{ width: "100%" }}
                      >
                        {uniqueCurrencyCode?.map((item) => (
                          <MenuItem
                            key={item.currency_code}
                            value={item.currency_code}
                          >
                            {item?.currency_code}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12} mt={3} mb={-2}>
                <Grid container>
                  <Grid item xs={11} md={11} lg={11}>
                    {serviceDescription?.length >= 300 && (
                      <Typography className={classes.textLimitTypo}>
                        {t("Text_Limit_ServiceDescription")}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={1} md={1} lg={1}>
                    <Typography className={classes.limitFieldTextAskQ}>
                      {!serviceDescription ? 0 : serviceDescription?.length}
                      /300{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} lg={12} mt={3}>
                <TextField
                  id="standard-basic"
                  rows={5}
                  maxRows={20}
                  multiline
                  type="text"
                  name="services_desc"
                  sx={{ width: "100%" }}
                  value={serviceDescription}
                  onChange={handleChangeServiceDescription}
                  placeholder={t("service_description")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "10px",
              marginRight: "7px",
            }}
          >
            <CommonButton
              displayWhite="true"
              onSubmit={handleCloseNewRequestModel}
              label={t("Cancel")}
            />

            <CommonButton
              type="submit"
              label={t("Save")}
              isLoading={serviceUpdateLoading}
              onSubmit={handleUpdateService}
              disabled={
                priceObjRequired
                  ? true
                  : duplicateService
                  ? true
                  : !addServiceValue?.trim()
                  ? true
                  : false
              }
            />
          </Box>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
