import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import Loader from "../../../components/Loaders/Loader";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import { TablePagination } from "@mui/material";
import ProgressCard from "../../../components/CustomComponents/Cards/ProgressCard";
import DomainCard from "../../../components/CustomComponents/Cards/DomainCard";
import CommonSearch from "../../../components/CustomComponents/Cards/CommonSearch";
import { useTranslation } from "react-i18next";
import TitleHeader from "../../../components/CustomComponents/title";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Index = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [backLinksData, setBackLinksData] = useState(location.state);
  const [backLinksDetails, setBackLinksDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(async () => {
    await getBackLinksDetails();
  }, [rowsPerPage, page]);
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const getBackLinksDetails = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `seo/backlink-reports/backlinks/85142?limit=${rowsPerPage}&page=${page}`
      );
      if (res.status === 200) {
        setBackLinksDetails(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TitleHeader showButton={true} />
          <CommonSearch />
          <Grid container spacing={2}>
            <Grid item md={5}>
              <ProgressCard
                labels={["to only homepage", "to only inside page", "Other"]}
                dataSet={[
                  backLinksData?.domains?.domains_saved -
                    backLinksData?.domains?.domains_to_otherpage,
                  backLinksData?.domains?.domains_saved -
                    backLinksData?.domains?.domains_to_homepage,
                  backLinksData?.domains?.domains_to_otherpage -
                    (backLinksData?.domains?.domains_saved -
                      backLinksData?.domains?.domains_to_homepage),
                ]}
                title={t("Refering_Domains")}
                title1={t("To_only_homepage")}
                title2={t("To_only_inside_pages")}
                title3={t("Other")}
                totalDomains={backLinksData?.domains?.total_domains}
                domainToHomepage={
                  backLinksData?.domains?.domains_saved -
                  backLinksData?.domains?.domains_to_otherpage
                }
                domainToInsidePage={
                  backLinksData?.domains?.domains_saved -
                  backLinksData?.domains?.domains_to_homepage
                }
                domainToOtherPage={backLinksData?.domains?.domains_to_otherpage}
              />
            </Grid>

            <Grid item md={2}>
              <DomainCard
                title={t("Anchor_Texts")}
                text={backLinksData?.anchors?.total_anchors}
              />
              <DomainCard
                title={t("REFERRING_IPS")}
                text={backLinksData?.anchors?.total_anchors}
              />
            </Grid>

            <Grid item md={5}>
              <DomainCard
                title={t("Refering_Domains")}
                progress={backLinksData?.inlink_rank}
                text={backLinksData?.backlinks?.edu_backlinks}
              />
            </Grid>
          </Grid>
          <Typography
            variant="h4"
            sx={{ marginBottom: "1rem", marginTop: "2rem" }}
          >
            {t("BackLink")}
          </Typography>{" "}
          <TableContainer component={Paper} marginTop={2}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("BackLink")}</TableCell>
                  <TableCell align="right"> DT</TableCell>
                  <TableCell align="right">PT</TableCell>
                  <TableCell align="center">{t("Anchor")}</TableCell>
                  <TableCell align="center">{t("Target")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {backLinksDetails?.backlinks?.map((row) => (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Link
                        href={row?.url_to}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {row?.url_to.slice(0, 19)}
                      </Link>
                    </TableCell>

                    <TableCell align="right">
                      {row?.domain_inlink_rank}
                    </TableCell>
                    <TableCell align="right">{row?.inlink_rank}</TableCell>
                    <TableCell align="right">{row?.anchor}</TableCell>
                    <TableCell align="right">
                      <Link
                        href={row?.url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        {row?.url.slice(0, 19)}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={backLinksData?.backlinks?.total_backlinks}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default Index;
