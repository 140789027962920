import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material";
import TableHead from "@material-ui/core/TableHead";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AnchorCard = ({ backLinks }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const viewFullReport = () => {
    navigate("/user/backlink/anchors", { state: backLinks });
  };

  return (
    <Grid container spacing={4} xs={12} lg={12} sx={{ marginTop: "2rem" }}>
      <Grid item md={6}>
        <Typography sx={{ marginBottom: "12px" }} variant="h6">
          {t("AnchorTop")}
        </Typography>
        <Paper>
          {/* <TableWrapper> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Anchor_Text")}</TableCell>
                <TableCell align="right">{t("Domain")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {backLinks?.anchors?.top_anchors?.domains?.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {/* <Link href={`//${row.anchor}`} target="_blank"  > */}

                      {row.anchor}
                      {/* </Link> */}
                    </TableCell>
                    <TableCell align="right">{row.count}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {location.pathname !== "/user/backlink/anchors" && (
              <TableFooter>
                <Button onClick={viewFullReport} sx={{ padding: "1rem" }}>
                  {t("ReportBtn")}
                </Button>
              </TableFooter>
            )}
          </Table>
          {/* </TableWrapper> */}
        </Paper>
      </Grid>
      <Grid item md={6}>
        <Typography sx={{ marginBottom: "12px" }} variant="h6">
          {t("To_backlink")}
        </Typography>
        <Paper>
          {/* <TableWrapper> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Anchor_Text")}</TableCell>
                <TableCell align="right">{t("Domain")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {backLinks?.anchors?.top_anchors?.links?.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.anchor}
                    </TableCell>
                    <TableCell align="right">{row.count}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {location.pathname !== "/user/backlink/anchors" && (
            <TableFooter>
              <Button onClick={viewFullReport} sx={{ padding: "1rem" }}>
                {t("ReportBtn")}
              </Button>
            </TableFooter>
          )}
          {/* </TableWrapper> */}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AnchorCard;
