import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  MenuItem,
  Tooltip,
  tooltipClasses,
  TextField,
  Autocomplete,
  Dialog,
  Stack,
  Paper,
  Button,
} from "@mui/material";
import ReconnectCard from "../../../../components/CustomComponents/Cards/ReconnectCard";
import PermanentClosedCard from "../../../../components/CustomComponents/Cards/PermanentClosedCard";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import CompanyDetails from "./Components/CompanyDetails/index";
import CompanyContactDetails from "./Components/CompanyContactDetails";
import LocationHours from "./Components//LocationOpeningHours/index";
import CompanyAttributes from "./Components//CompanyAttributes/index";
import CompanyDescription from "./Components/CompanyDescription/index";
import Categories from "./Components/Categories/index";
import LocationMap from "./Components/LocationMap/index";
import AdditionalCategories from "./Components/AdditionalCategories/index";
import styled from "styled-components/macro";
import LocationSpecialHours from "./Components/LocationSpeacialHours/index";
import PaymentOptions from "./Components/PaymentOptions/index";
import SocialAccounts from "./Components/SocialAccounts/index";
import LocationServices from "./Components/LocationServices/index";
import LocationImages from "./Components/CoverImages/index";
import LocationLogoImages from "./Components/Images/index";
import { toast } from "react-toastify";
import { api } from "../../../../contexts/JWTContext";
import DeleteModel from "../../../../components/Models/DeleteModal/Delete";
import SyncModel from "../../../../components/Models/DeleteModal/syncModel";
import CircularProgress from "@mui/material/CircularProgress";
import Socialaccounts from "./Components/SocialAccounts/index";
import Socialaccountdetails from "./Components/SocialAccountDetails";
import LocalPages from "./Components/LocalPages/index";
import "react-circular-progressbar/dist/styles.css";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import Switch from "@mui/material/Switch";
import Loader from "../../../../components/Loaders/Loader";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import Products from "./Components/Products/index";

import { useStyles } from "../Styles/style";
import MoreOpeningHours from "./Components/MoreOpeningHours/MoreOpeningHours";
import Keywords from "./Components/CompanyKeywords";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function LocationDetails({
  location,
  changeLoc,
  selectedTab,
  locationDetailsData,
  getLocationDetails,
  googleConnect,
  facebookConnect,
  getLocationById,
  singleLocation,
  setSingleLocationLoading,
  getLocationDetailsListing,
  setLocationDetailsData,
  lodgingCategories,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  locationEditPermission,
  locationDeletePermission,
  locationViewPermission,
  locationAddPermission,
  centerData,
  displayMapError,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [locationCategory, setLocationCategory] = useState(null);
  const [locationFacebookCategory, setLocationFacebookCategory] =
    useState(null);
  const [allAttributes, setAllAttributes] = useState([]);
  const [autoSync, setAutoSync] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [openAcceptModel, setOpenAcceptModel] = useState(false);
  const [timesArr, setTimesArr] = useState([]);
  const [percentageArr, setPercentageArr] = useState([]);
  const [profilePercentage, setProfilePercentage] = useState("0");
  const companyData = JSON.parse(localStorage.getItem("company"));
  const userLanguage = localStorage.getItem("i18nextLng");
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [openingHoursModel, setOpeningHoursModel] = useState(false);
  const [calculateBool, setCalculateBool] = useState(false);

  useEffect(() => {
    if (location !== null && location?.socialLink?.length > 0) {
      //  getLocationDetails(location);
      getAllAttributes(location);
      getLocationCategories(location);
      //  getLocationFacebookCategories(location);
    }
  }, []);
  const handleLanguageChange = () => {
    const selectedLanguage = localStorage.getItem("i18nextLng");
    getLocationCategories(location);
    setCalculateBool(true);
  };

  useEffect(() => {
    window.addEventListener("languageChange", handleLanguageChange);

    return () => {
      window.removeEventListener("languageChange", handleLanguageChange);
    };
  }, []);
  useEffect(() => {
    if (calculateBool) {
      handleCalculatePercentage();
    }
  }, [calculateBool]);

  useEffect(() => {
    if (locationDetailsData !== null && location !== null) {
      handleCalculatePercentage();
    }
  }, [locationDetailsData]);

  useEffect(() => {
    setProfilePercentage("0");
  }, [location]);

  const handleCalculatePercentage = async () => {
    let arrWithoutLodging = [
      {
        name: t("Phone_Numbers"),
        value: "primaryPhone",
        percentageValue: 10,
      },
      {
        name: t("Website_URL"),
        value: "websiteUri",
        percentageValue: 10,
      },
      {
        name: t("Description"),
        value: "description",
        percentageValue: 10,
      },
      {
        name: t("Service_Areas"),
        value: "serviceArea",
        percentageValue: 10,
      },
      {
        name: t("Attributes"),
        value: "attributes",
        percentageValue: 25,
      },
      {
        name: t("Additional_Desc"),
        value: "additionalCategories",
        percentageValue: 5,
      },
      {
        name: t("cover_images_uberAll"),
        value: "media",
        percentageValue: 10,
      },
      {
        name: t("Keywords"),
        value: "keywords",
        percentageValue: 10,
      },
      {
        name: t("Social_Accounts"),
        value: "socialProfiles",
        percentageValue: 5,
      },
      {
        name: t("Services_&_Brands"),
        value: "brands",
        percentageValue: 5,
      },
    ];
    let arrWithLodging = [
      {
        name: t("Phone_Numbers"),
        value: "primaryPhone",
        percentageValue: 10,
      },
      {
        name: t("Website_URL"),
        value: "websiteUri",
        percentageValue: 10,
      },
      {
        name: t("Service_Areas"),
        value: "serviceArea",
        percentageValue: 15,
      },
      {
        name: t("Attributes"),
        value: "attributes",
        percentageValue: 25,
      },
      {
        name: t("Additional_Desc"),
        value: "additionalCategories",
        percentageValue: 5,
      },
      {
        name: t("cover_images_uberAll"),
        value: "media",
        percentageValue: 10,
      },
      {
        name: t("Keywords"),
        value: "keywords",
        percentageValue: 15,
      },
      {
        name: t("Social_Accounts"),
        value: "socialProfiles",
        percentageValue: 5,
      },
      {
        name: t("Services_&_Brands"),
        value: "brands",
        percentageValue: 5,
      },
    ];

    let arrFinal = [];
    let finalArr = [];
    if (locationDetailsData !== null && lodgingCategories?.length > 0) {
      if (locationDetailsData?.result?.uberall?.socialProfiles?.length > 0) {
        let obj = {
          name: t("Social_Accounts"),
          value: "socialProfiles",
          percentageValue: 5,
        };
        finalArr.push(obj);
      } else {
        finalArr = finalArr?.filter((item) => item?.value !== "socialProfiles");
      }

      if (
        locationDetailsData?.result?.uberall?.brands?.length > 0 ||
        locationDetailsData?.result?.uberall?.services?.length > 0
      ) {
        let obj = {
          name: t("Services_&_Brands"),
          value: "brands",
          percentageValue: 5,
        };
        finalArr.push(obj);
      }
      if (
        location?.isLodgingEnabled === true &&
        lodgingCategories.includes(
          locationDetailsData?.result?.google?.categories?.primaryCategory?.name
        )
      ) {
        {
        }
        arrWithLodging.forEach((itemTwo) => {
          Object.keys(locationDetailsData?.result?.google).map((item) => {
            if (item === "categories") {
              Object.keys(locationDetailsData?.result?.google?.categories).map(
                (itemThree) => {
                  if (
                    itemThree === "additionalCategories" &&
                    itemTwo?.value === "additionalCategories"
                  ) {
                    if (
                      locationDetailsData?.result?.google?.categories[
                        `${itemThree}`
                      ]?.length > 0
                    ) {
                      let obj = {
                        name: itemTwo?.name,
                        value: itemThree,
                        percentageValue: itemTwo?.percentageValue,
                      };
                      finalArr.push(obj);
                    }
                  }
                }
              );
            } else if (item === "media") {
              if (item === "media" && itemTwo?.value === "media") {
                if (locationDetailsData?.result?.google?.media?.length > 0) {
                  const imageFound =
                    locationDetailsData?.result?.google?.media?.filter(
                      (item) => item?.isDeleted === false
                    );

                  if (imageFound?.length > 0) {
                    if (
                      imageFound?.length === 1 &&
                      imageFound[0]?.category === "AT_WORK"
                    ) {
                    } else {
                      if (imageFound?.length > 0) {
                        let obj = {
                          name: itemTwo?.name,
                          value: item,
                          percentageValue: itemTwo?.percentageValue,
                        };
                        finalArr.push(obj);
                      }
                    }
                  }
                }
              }
            } else if (item === "phoneNumbers") {
              locationDetailsData?.result?.google?.phoneNumbers !== null &&
                Object.keys(locationDetailsData?.result?.google?.phoneNumbers)
                  ?.length > 0 &&
                Object.keys(
                  locationDetailsData?.result?.google?.phoneNumbers
                ).map((itemThree) => {
                  if (
                    itemThree === "primaryPhone" &&
                    itemTwo?.value === "primaryPhone"
                  ) {
                    if (
                      locationDetailsData?.result?.google?.phoneNumbers[
                        `${itemThree}`
                      ] !== null &&
                      locationDetailsData?.result?.google?.phoneNumbers[
                        `${itemThree}`
                      ] !== "" &&
                      locationDetailsData?.result?.google?.phoneNumbers[
                        `${itemThree}`
                      ] !== undefined
                    ) {
                      let obj = {
                        name: itemTwo?.name,
                        value: itemThree,
                        percentageValue: itemTwo?.percentageValue,
                      };

                      finalArr.push(obj);
                    }
                  }
                });
            } else if (
              item === "serviceArea" &&
              itemTwo?.value === "serviceArea"
            ) {
              if (
                locationDetailsData?.result?.google[`${item}`] !== null &&
                locationDetailsData?.result?.google[`${item}`]?.places
                  ?.placeInfos.length > 0
              ) {
                let obj = {
                  name: itemTwo?.name,
                  value: item,
                  percentageValue: itemTwo?.percentageValue,
                };
                finalArr.push(obj);
              }
            } else if (item === itemTwo?.value) {
              if (location?.isLodgingEnabled === false) {
                if (
                  Array.isArray(locationDetailsData?.result?.google[`${item}`])
                ) {
                  if (
                    locationDetailsData?.result?.google[`${item}`]?.length > 0
                  ) {
                    let obj = {
                      name: itemTwo?.name,
                      value: item,
                      percentageValue: itemTwo?.percentageValue,
                    };
                    finalArr.push(obj);
                  }
                } else if (
                  locationDetailsData?.result?.google[`${item}`] !== "" &&
                  locationDetailsData?.result?.google[`${item}`] !== null &&
                  locationDetailsData?.result?.google[`${item}`] !== undefined
                ) {
                  let obj = {
                    name: itemTwo?.name,
                    value: item,
                    percentageValue: itemTwo?.percentageValue,
                  };
                  finalArr.push(obj);
                }
              } else {
                if (itemTwo?.value === "attributes") {
                  let lodgingArr = [];

                  if (
                    locationDetailsData?.result?.google?.lodging !==
                      undefined &&
                    locationDetailsData?.result?.google?.lodging !== null
                  ) {
                    Object.entries(
                      locationDetailsData?.result?.google?.lodging
                    )?.map(([keyOne, valueOne]) => {
                      if (
                        keyOne !== "updateMask" &&
                        keyOne !== "name" &&
                        keyOne !== "metadata"
                      ) {
                        Object.entries(valueOne)?.map(([keyTwo, valueTwo]) => {
                          if (valueTwo?.hasOwnProperty("type")) {
                            if (valueTwo?.value !== null) {
                              let objOne = {
                                name: keyTwo,
                                type: valueTwo?.type,
                                value: valueTwo?.value,
                              };
                              lodgingArr.push(objOne);
                            }
                          } else if (valueTwo?.hasOwnProperty("hours")) {
                            if (
                              valueTwo?.hours?.value !== null &&
                              valueTwo?.minutes?.value !== null
                            ) {
                              let timeObj = {
                                name: keyTwo,
                                type: "time",
                                value: {
                                  hours: valueTwo?.hours?.value,
                                  minutes: valueTwo?.minutes?.value,
                                },
                              };
                              lodgingArr.push(timeObj);
                            }
                          } else if (Array.isArray(valueTwo)) {
                            if (keyTwo === "codes") {
                              let index = 0;
                              if (valueTwo[0]?.value !== null) {
                                let obj = {
                                  name: keyTwo,
                                  type: valueTwo[0]?.type,
                                  value: valueTwo[0]?.value,
                                };
                                lodgingArr.push(obj);
                              }
                            } else if (keyTwo === "languagesSpoken") {
                              let obj = {
                                name: keyTwo,
                                type: "string",
                                value: valueTwo,
                              };
                              lodgingArr.push(obj);
                            } else {
                              valueTwo?.map((dataItem) => {
                                Object.entries(dataItem)?.map(
                                  ([keyThree, valueThree]) => {
                                    if (valueThree?.hasOwnProperty("type")) {
                                      if (valueThree?.value !== null) {
                                        let obj = {
                                          name: keyThree,
                                          type: valueThree?.type,
                                          value: valueThree?.value,
                                        };
                                        lodgingArr.push(obj);
                                      }
                                    }
                                  }
                                );
                              });
                            }
                          } else {
                            valueTwo &&
                              Object.keys(valueTwo)?.length > 0 &&
                              Object.keys(valueTwo) !== null &&
                              Object.entries(valueTwo)?.map(
                                ([keyThree, valueThree]) => {
                                  if (
                                    valueThree?.hasOwnProperty("type") ||
                                    valueThree?.hasOwnProperty("hours")
                                  ) {
                                    if (valueThree?.value !== null) {
                                      let objThree = {
                                        name: keyThree,
                                        type: valueThree?.type,
                                        value: valueThree?.value,
                                      };
                                      lodgingArr.push(objThree);
                                    }
                                  } else {
                                    if (Array.isArray(valueThree)) {
                                      if (keyThree === "ecoCertifications") {
                                        valueThree?.map((dataItem) => {
                                          Object.entries(dataItem)?.map(
                                            ([keyFour, valueFour]) => {
                                              if (
                                                valueFour?.hasOwnProperty(
                                                  "type"
                                                )
                                              ) {
                                                if (valueFour?.value !== null) {
                                                  let obj = {
                                                    name: keyFour,
                                                    type: valueFour?.type,
                                                    value: valueFour?.value,
                                                  };
                                                  lodgingArr.push(obj);
                                                }
                                              }
                                            }
                                          );
                                        });
                                      }
                                    } else {
                                      valueThree &&
                                        Object.entries(valueThree)?.map(
                                          ([keyFour, valueFour]) => {
                                            if (
                                              valueFour?.hasOwnProperty("type")
                                            ) {
                                              // let index = 0;
                                              if (valueFour?.value !== null) {
                                                let obj = {
                                                  name: keyFour,
                                                  type: valueFour?.type,
                                                  value: valueFour?.value,
                                                };
                                                lodgingArr.push(obj);
                                              }
                                            } else {
                                              if (Array.isArray(valueFour)) {
                                                let index = 0;
                                                valueFour?.map((itemFour) => {
                                                  Object.entries(itemFour).map(
                                                    ([keyFive, valueFive]) => {
                                                      if (
                                                        valueFive?.value !==
                                                        null
                                                      ) {
                                                        let obj = {
                                                          name: keyFive,
                                                          type: valueFive?.type,
                                                          value:
                                                            valueFive?.value,
                                                        };
                                                        lodgingArr.push(obj);
                                                      }
                                                    }
                                                  );
                                                });
                                              } else {
                                                valueFour &&
                                                  Object.entries(
                                                    valueFour
                                                  )?.map(
                                                    ([keyFive, valueFive]) => {
                                                      if (
                                                        valueFive?.hasOwnProperty(
                                                          "type"
                                                        )
                                                      ) {
                                                        if (
                                                          valueFive?.value !==
                                                          null
                                                        ) {
                                                          let obj = {
                                                            name: keyFive,
                                                            type: valueFive?.type,
                                                            value:
                                                              valueFive?.value,
                                                          };
                                                          lodgingArr.push(obj);
                                                        }
                                                      } else {
                                                        valueFive &&
                                                          Object.entries(
                                                            valueFive
                                                          )?.map(
                                                            ([
                                                              keySix,
                                                              valueSix,
                                                            ]) => {
                                                              if (
                                                                valueSix?.value !==
                                                                null
                                                              ) {
                                                                let obj = {
                                                                  name: keySix,
                                                                  type: valueSix?.type,
                                                                  value:
                                                                    valueSix?.value,
                                                                };
                                                                lodgingArr.push(
                                                                  obj
                                                                );
                                                              }
                                                            }
                                                          );
                                                      }
                                                    }
                                                  );
                                              }
                                            }
                                          }
                                        );
                                    }
                                  }
                                }
                              );
                          }
                        });
                      }
                    });

                    if (lodgingArr?.length > 0) {
                      if (lodgingArr?.length === 1) {
                        const languageFound = lodgingArr[0]?.value?.filter(
                          (item) => item?.spoken?.value !== null
                        );
                        if (languageFound?.length > 0) {
                          let obj = {
                            name: itemTwo?.name,
                            value: item,
                            percentageValue: itemTwo?.percentageValue,
                          };
                          finalArr.push(obj);
                        }
                      } else {
                        let obj = {
                          name: itemTwo?.name,
                          value: item,
                          percentageValue: itemTwo?.percentageValue,
                        };
                        finalArr.push(obj);
                      }
                    }
                  }
                } else if (
                  locationDetailsData?.result?.google[`${item}`] !== "" &&
                  locationDetailsData?.result?.google[`${item}`] !== null &&
                  locationDetailsData?.result?.google[`${item}`] !== undefined
                ) {
                  let obj = {
                    name: itemTwo?.name,
                    value: item,
                    percentageValue: itemTwo?.percentageValue,
                  };
                  finalArr.push(obj);
                }
              }

              // finalArr.push(item);
            }
          });
          arrFinal = finalArr;
        });
      } else {
        {
        }
        arrWithoutLodging.forEach((itemTwo) => {
          locationDetailsData?.result?.google &&
            Object.keys(locationDetailsData?.result?.google).map((item) => {
              if (item === "categories") {
                Object.keys(
                  locationDetailsData?.result?.google?.categories
                ).map((itemThree) => {
                  if (
                    itemThree === "additionalCategories" &&
                    itemTwo?.value === "additionalCategories"
                  ) {
                    if (
                      locationDetailsData?.result?.google?.categories[
                        `${itemThree}`
                      ]?.length > 0
                    ) {
                      let obj = {
                        name: itemTwo?.name,
                        value: itemThree,
                        percentageValue: itemTwo?.percentageValue,
                      };
                      finalArr.push(obj);
                    }
                  }
                });
              } else if (item === "media") {
                if (item === "media" && itemTwo?.value === "media") {
                  if (locationDetailsData?.result?.google?.media?.length > 0) {
                    const imageFound =
                      locationDetailsData?.result?.google?.media?.filter(
                        (item) => item?.isDeleted === false
                      );

                    if (imageFound?.length > 0) {
                      if (
                        imageFound?.length === 1 &&
                        imageFound[0]?.category === "AT_WORK"
                      ) {
                      } else {
                        if (imageFound?.length > 0) {
                          let obj = {
                            name: itemTwo?.name,
                            value: item,
                            percentageValue: itemTwo?.percentageValue,
                          };
                          finalArr.push(obj);
                        }
                      }
                    }
                  }
                }
              } else if (item === "profile") {
                locationDetailsData?.result?.google?.profile !== null &&
                  Object.keys(locationDetailsData?.result?.google?.profile).map(
                    (itemThree) => {
                      if (
                        itemThree === "description" &&
                        itemTwo?.value === "description"
                      ) {
                        if (
                          locationDetailsData?.result?.google?.profile[
                            `${itemThree}`
                          ] !== null &&
                          locationDetailsData?.result?.google?.profile[
                            `${itemThree}`
                          ] !== "" &&
                          locationDetailsData?.result?.google?.profile[
                            `${itemThree}`
                          ] !== undefined
                        ) {
                          let obj = {
                            name: itemTwo?.name,
                            value: itemThree,
                            percentageValue: itemTwo?.percentageValue,
                          };
                          finalArr.push(obj);
                        }
                      }
                    }
                  );
              } else if (item === "phoneNumbers") {
                locationDetailsData?.result?.google?.phoneNumbers !== null &&
                  Object.keys(locationDetailsData?.result?.google?.phoneNumbers)
                    ?.length > 0 &&
                  Object.keys(
                    locationDetailsData?.result?.google?.phoneNumbers
                  ).map((itemThree) => {
                    if (
                      itemThree === "primaryPhone" &&
                      itemTwo?.value === "primaryPhone"
                    ) {
                      if (
                        locationDetailsData?.result?.google?.phoneNumbers[
                          `${itemThree}`
                        ] !== null &&
                        locationDetailsData?.result?.google?.phoneNumbers[
                          `${itemThree}`
                        ] !== "" &&
                        locationDetailsData?.result?.google?.phoneNumbers[
                          `${itemThree}`
                        ] !== undefined
                      ) {
                        let obj = {
                          name: itemTwo?.name,
                          value: itemThree,
                          percentageValue: itemTwo?.percentageValue,
                        };

                        finalArr.push(obj);
                      }
                    }
                  });
              } else if (
                item === "serviceArea" &&
                itemTwo?.value === "serviceArea"
              ) {
                if (
                  locationDetailsData?.result?.google[`${item}`] !== null &&
                  locationDetailsData?.result?.google[`${item}`]?.places
                    ?.placeInfos.length > 0
                ) {
                  let obj = {
                    name: itemTwo?.name,
                    value: item,
                    percentageValue: itemTwo?.percentageValue,
                  };
                  finalArr.push(obj);
                }
              } else if (item === itemTwo?.value) {
                if (
                  itemTwo?.value === "attributes" &&
                  Array.isArray(locationDetailsData?.result?.google[`${item}`])
                ) {
                  if (
                    locationDetailsData?.result?.google[`${item}`]?.length > 0
                  ) {
                    let obj = {
                      name: itemTwo?.name,
                      value: item,
                      percentageValue: itemTwo?.percentageValue,
                    };
                    finalArr.push(obj);
                  }
                } else if (
                  locationDetailsData?.result?.google[`${item}`] !== "" &&
                  locationDetailsData?.result?.google[`${item}`] !== null &&
                  locationDetailsData?.result?.google[`${item}`] !== undefined
                ) {
                  let obj = {
                    name: itemTwo?.name,
                    value: item,
                    percentageValue: itemTwo?.percentageValue,
                  };
                  finalArr.push(obj);
                }

                // finalArr.push(item);
              }
            });
          arrFinal = finalArr;
        });
      }

      let arr2 = [];
      arrFinal.forEach((item) => {
        let isFound = false;
        arr2.forEach((item2) => {
          if (item.value === item2.value) {
            isFound = true;
          }
        });
        if (!isFound) {
          //  timesArr.push(item);
          arr2.push(item);
        }
      });
      if (locationDetailsData?.result?.keywords) {
        if (
          locationDetailsData?.result?.keywords &&
          locationDetailsData?.result?.keywords?.length > 0
        ) {
          if (
            location?.isLodgingEnabled === true &&
            lodgingCategories.includes(
              locationDetailsData?.result?.google?.categories?.primaryCategory
                ?.name
            )
          ) {
            let obj = {
              name: t("Keywords"),
              value: "keywords",
              percentageValue: 15,
            };

            arr2.push(obj);
          } else {
            let obj = {
              name: t("Keywords"),
              value: "keywords",
              percentageValue: 10,
            };

            arr2.push(obj);
          }
        }
      }
      if (
        location?.isLodgingEnabled === true &&
        lodgingCategories.includes(
          locationDetailsData?.result?.google?.categories?.primaryCategory?.name
        )
      ) {
        const results = arrWithLodging.filter(
          ({ name: id1 }) => !arr2.some(({ name: id2 }) => id2 === id1)
        );
        setPercentageArr(results);
      } else {
        const results = arrWithoutLodging.filter(
          ({ name: id1 }) => !arr2.some(({ name: id2 }) => id2 === id1)
        );

        setPercentageArr(results);
      }

      if (arr2?.length > 0) {
        let totalSum = 0;

        let finalArray = [];

        finalArray = [...arr2];

        finalArray?.forEach((item) => {
          totalSum = totalSum + item?.percentageValue;
        });

        const percentage = ((totalSum / 100) * 100).toFixed();

        try {
          const res = await api.patch(`/location/${location?.id}`, {
            profileCompletition: Number(percentage),
          });
        } catch (error) {
          console.log(error);
        }

        setProfilePercentage(percentage);
      }

      setCalculateBool(false);
    }
  };
  const getLocationCategories = async (locationData) => {
    setCategoryLoading(true);
    try {
      const response = await api.get(
        `/locationListing/getAll-categories/${locationData.id}`
      );

      if (response.status === 200) {
        setCategoryLoading(false);
        setLocationCategory(response?.data?.data);
        setLocationFacebookCategory(response?.data?.data);
      }
    } catch (error) {
      setCategoryLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  /*   const getLocationFacebookCategories = async (locationData) => {
    try {
      const response = await api.get(
        `/locationListing/get-all-facebook-categories/${locationData.id}`
      );
      if (response.status === 200) {
     
        setLocationFacebookCategory(response?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }; */

  const getAllAttributes = async (locationData) => {
    try {
      const res = await api.get(
        `/locationListing/getAll-attributes/${locationData?.id}`
      );
      if (res.status === 200) {
        setAllAttributes(res?.data?.data?.attributes);
      }
    } catch (error) {}
  };
  const handleChangeSwitch = async (e) => {
    if (e.target.checked) {
      setAutoSync(true);
    } else {
      setAutoSync(false);
    }

    try {
      setLoadingLocation(true);
      const res = await api.patch(
        `/locationListing/update-autoSync/${location?.id}`,
        {
          autoSync: e.target.checked,
        }
      );

      if (res.status === 200) {
        setIsLoading(false);
        //  toast.success("Updated Successfully");
        getLocationDetails(location);
        getLocationDetailsListing(location);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoadingLocation(false);
    }
  };

  const handleOpenAcceptModel = () => {
    setOpenAcceptModel(true);
  };
  const handleCloseAcceptModel = () => {
    setOpenAcceptModel(false);
  };

  return (
    <>
      {/* {openAcceptModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={handleOpenAcceptModel}
          onClose={handleCloseAcceptModel}
          aria-labelledby="responsive-dialog-title"
        >
          <SyncModel
            title={t("Sync Latest Listing")}
            description={t(
              "Are you sure you want to sync latest listing data from google ?"
            )}
            onConfirm={() => handleAcceptAccount()}
            onCancel={handleCloseAcceptModel}
            loading={isLoading}
          />
        </Dialog>
      )} */}
      {location !== null &&
        googleConnect !== null &&
        googleConnect?.validToken === false &&
        locationDetailsData !== null && (
          <Box sx={{ marginTop: "8px" }}>
            <ReconnectCard
              location={location}
              displayReconnectButton={true}
              addPermission={locationAddPermission}
              editPermission={locationEditPermission}
              deletePermission={locationDeletePermission}
              viewPermission={locationViewPermission}
            />
          </Box>
        )}

      <>
        {location !== null &&
          googleConnect !== null &&
          googleConnect?.validToken === true &&
          locationDetailsData !== null && (
            <Grid container spacing={2} sx={{ marginTop: "2px" }}>
              {locationDetailsData !== null &&
              locationDetailsData?.result?.google?.openInfo?.status ===
                "CLOSED_PERMANENTLY" ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ marginTop: "8px" }}
                >
                  <PermanentClosedCard
                    location={location}
                    addPermission={addPermission}
                    editPermission={editPermission}
                    deletePermission={deletePermission}
                    viewPermission={viewPermission}
                    setOpeningHoursModel={setOpeningHoursModel}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card
                    // variant="outlined"
                    sx={{
                      bgcolor: "#06BDFF",
                      borderRadius: "8px",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Grid
                      container
                      sx={{ padding: "10px", alignItems: "center" }}
                    >
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box sx={{ height: "100px", width: "100px" }}>
                          <CircularProgressbar
                            justifyContent="center"
                            styles={buildStyles({
                              textColor: "#ffffff",
                              pathColor: "#ffffff",
                              trailColor: "#82deff",
                            })}
                            // value={difficulty}
                            // text={`${difficulty}%`}
                            value={profilePercentage}
                            text={`${profilePercentage}%`}
                          />

                          {/*    <CircularProgress
                        variant="determinate"
                        value={profilePercentage}
                      /> */}
                        </Box>
                      </Grid>
                      {parseInt(profilePercentage) === 100 ||
                      profilePercentage === "100" ? (
                        <>
                          <Grid item xs={8} sm={8} md={10} lg={10}>
                            <Typography
                              className={classes.progressCardCompleteTitle}
                            >
                              {t("Complete_Profile_Text")}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={8} sm={8} md={10} lg={10}>
                            <Typography className={classes.progressCardTitle}>
                              {t("Profile_Complete")}
                            </Typography>

                            <Typography className={classes.progressCardPara}>
                              {t("Profile_Complete_subhead")}

                              {percentageArr?.map((item) => (
                                <>
                                  {" "}
                                  {(item?.name ? ", " : "") + item?.name}
                                  {/* {item?.name} */}
                                  {/* {","}{" "} */}
                                </>
                              ))}
                              {"."}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Card>
                </Grid>
              )}
            </Grid>
          )}
        <Grid container spacing={2} sx={{ marginTop: "8px" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CompanyDetails
              locationDetailsData={locationDetailsData}
              location={location}
              locationCategory={locationCategory}
              getLocationDetails={getLocationDetails}
              getLocationDetailsListing={getLocationDetailsListing}
              lodgingCategories={lodgingCategories}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
            <br />
            <Categories
              locationDetailsData={locationDetailsData}
              location={location}
              locationFacebookCategory={locationFacebookCategory}
              locationCategory={locationCategory}
              getLocationDetails={getLocationDetails}
              facebookConnect={facebookConnect}
              getLocationDetailsListing={getLocationDetailsListing}
              getLocationById={getLocationById}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
            <br />
            <CompanyContactDetails
              locationDetailsData={locationDetailsData}
              location={location}
              locationCategory={locationCategory}
              getLocationDetails={getLocationDetails}
              getLocationDetailsListing={getLocationDetailsListing}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
            <br />
            <CompanyDescription
              locationDetailsData={locationDetailsData}
              getLocationDetails={getLocationDetails}
              location={location}
              setLocationDetailsData={setLocationDetailsData}
              getLocationDetailsListing={getLocationDetailsListing}
              locationCategory={locationCategory}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
            <br />
            <Keywords
              locationDetailsData={locationDetailsData}
              getLocationDetails={getLocationDetails}
              location={location}
              setLocationDetailsData={setLocationDetailsData}
              getLocationDetailsListing={getLocationDetailsListing}
              locationCategory={locationCategory}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
          </Grid>

          <Grid item xs={12} sm={7} md={7} lg={7}>
            <br />
            <CompanyAttributes
              locationDetailsData={locationDetailsData}
              getLocationDetails={getLocationDetails}
              location={location}
              allAttributes={allAttributes}
              setLocationDetailsData={setLocationDetailsData}
              getLocationDetailsListing={getLocationDetailsListing}
              locationCategory={locationCategory}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={5}>
            <br />
            {displayMapError === false && (
              <>
                <LocationMap
                  locationDetailsData={locationDetailsData}
                  location={location}
                  allAttributes={allAttributes}
                  getLocationDetails={getLocationDetails}
                  setLocationDetailsData={setLocationDetailsData}
                  getLocationDetailsListing={getLocationDetailsListing}
                  locationCategory={locationCategory}
                  lodgingCategories={lodgingCategories}
                  categoryLoading={categoryLoading}
                  addPermission={addPermission}
                  editPermission={editPermission}
                  deletePermission={deletePermission}
                  viewPermission={viewPermission}
                  centerData={centerData}
                  displayMapError={displayMapError}
                />
                <br />
              </>
            )}

            <LocationHours
              locationDetailsData={locationDetailsData}
              location={location}
              locationCategory={locationCategory}
              getLocationDetails={getLocationDetails}
              setTimesArr={setTimesArr}
              timesArr={timesArr}
              setLocationDetailsData={setLocationDetailsData}
              getLocationDetailsListing={getLocationDetailsListing}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
              setOpeningHoursModel={setOpeningHoursModel}
              openingHoursModel={openingHoursModel}
            />
            <br />
            <LocationSpecialHours
              location={location}
              locationCategory={locationCategory}
              locationDetailsData={locationDetailsData}
              onCancel={handleCloseAcceptModel}
              getLocationDetails={getLocationDetails}
              setLocationDetailsData={setLocationDetailsData}
              getLocationDetailsListing={getLocationDetailsListing}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
            <br />
            <MoreOpeningHours
              location={location}
              locationCategory={locationCategory}
              locationDetailsData={locationDetailsData}
              getLocationDetails={getLocationDetails}
              setTimesArr={setTimesArr}
              timesArr={timesArr}
              setLocationDetailsData={setLocationDetailsData}
              getLocationDetailsListing={getLocationDetailsListing}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
            {/* <br />
            <AdditionalCategories
              location={location}
              locationDetailsData={locationDetailsData}
              locationCategory={locationCategory}
              setLocationDetailsData={setLocationDetailsData}
              getLocationDetails={getLocationDetails}
              getLocationDetailsListing={getLocationDetailsListing}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            /> */}
            {/*  <br />
              <MoreHours
                location={location}
                locationDetailsData={locationDetailsData}
                getLocationDetails={getLocationDetails}
              /> */}
            {/* <Paymentoptions />
          <br />
          <LocationImages />
          <br />
          <Locationservices />
          <br />

          <br />
          <Socialaccounts /> */}
            <br />
            <Socialaccounts
              locationDetailsData={locationDetailsData}
              getLocationDetails={getLocationDetails}
              location={location}
              locationCategory={locationCategory}
              googleConnect={googleConnect}
              facebookConnect={facebookConnect}
              setSingleLocationLoading={setSingleLocationLoading}
              getLocationById={getLocationById}
              singleLocation={singleLocation}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
            <br />
            <Socialaccountdetails
              locationDetailsData={locationDetailsData}
              getLocationDetails={getLocationDetails}
              location={location}
              locationCategory={locationCategory}
              googleConnect={googleConnect}
              facebookConnect={facebookConnect}
              setSingleLocationLoading={setSingleLocationLoading}
              getLocationDetailsListing={getLocationDetailsListing}
              getLocationById={getLocationById}
              singleLocation={singleLocation}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
            <br />

            {locationDetailsData !== null &&
              locationDetailsData?.result?.google?.metadata?.hasOwnProperty(
                "canHaveFoodMenus"
              ) &&
              locationDetailsData?.result?.google?.metadata
                ?.canHaveFoodMenus === true && (
                <>
                  <Products
                    locationDetailsData={locationDetailsData}
                    getLocationDetails={getLocationDetails}
                    location={location}
                    locationCategory={locationCategory}
                    googleConnect={googleConnect}
                    facebookConnect={facebookConnect}
                    setSingleLocationLoading={setSingleLocationLoading}
                    getLocationById={getLocationById}
                    singleLocation={singleLocation}
                    getLocationDetailsListing={getLocationDetailsListing}
                    lodgingCategories={lodgingCategories}
                    categoryLoading={categoryLoading}
                    addPermission={addPermission}
                    editPermission={editPermission}
                    deletePermission={deletePermission}
                    viewPermission={viewPermission}
                    centerData={centerData}
                    displayMapError={displayMapError}
                  />
                  <br />
                </>
              )}
            <LocationLogoImages
              locationDetailsData={locationDetailsData}
              getLocationDetails={getLocationDetails}
              location={location}
              locationCategory={locationCategory}
              googleConnect={googleConnect}
              facebookConnect={facebookConnect}
              setSingleLocationLoading={setSingleLocationLoading}
              getLocationById={getLocationById}
              singleLocation={singleLocation}
              getLocationDetailsListing={getLocationDetailsListing}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              addPermission={addPermission}
              editPermission={editPermission}
              deletePermission={deletePermission}
              viewPermission={viewPermission}
              centerData={centerData}
              displayMapError={displayMapError}
            />
            {/* 
            {companyData !== null &&
              companyData !== undefined &&
              companyData?.thirdPartyCoverImages===true && (
                <>
                  <br />
                  <LocationImages
                    locationDetailsData={locationDetailsData}
                    getLocationDetails={getLocationDetails}
                    location={location}
                    locationCategory={locationCategory}
                    googleConnect={googleConnect}
                    facebookConnect={facebookConnect}
                    setSingleLocationLoading={setSingleLocationLoading}
                    getLocationById={getLocationById}
                    singleLocation={singleLocation}
                    getLocationDetailsListing={getLocationDetailsListing}
                  />
                </>
              )} */}
            {companyData !== null &&
              companyData !== undefined &&
              companyData?.thirdPartyCoverImages === true && (
                <>
                  <br />
                  <LocalPages
                    locationDetailsData={locationDetailsData}
                    getLocationDetails={getLocationDetails}
                    location={location}
                    locationCategory={locationCategory}
                    googleConnect={googleConnect}
                    facebookConnect={facebookConnect}
                    setSingleLocationLoading={setSingleLocationLoading}
                    getLocationById={getLocationById}
                    singleLocation={singleLocation}
                    getLocationDetailsListing={getLocationDetailsListing}
                    lodgingCategories={lodgingCategories}
                    categoryLoading={categoryLoading}
                    addPermission={addPermission}
                    editPermission={editPermission}
                    deletePermission={deletePermission}
                    viewPermission={viewPermission}
                    centerData={centerData}
                    displayMapError={displayMapError}
                  />
                </>
              )}
          </Grid>
        </Grid>
      </>
    </>
  );
}
export default LocationDetails;
