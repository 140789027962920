import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  tooltipClasses,
  Typography,
  Paper,
  TextField,
} from "@mui/material";
import { useStyles } from "./style.js";
import CommonButton from "../../CustomComponents/Buttons/CommonButton.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components/macro";
import Collapsible from "../../CustomComponents/Collapsible.js";
import { countriesData } from "../../../assets/countriesList/index.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Loader from "../../Loaders/Loader.js";

const SmallCheckbox = styled(Checkbox)({
  width: "12px",
  height: "12px",
  "& .MuiSvgIcon-root": {
    fontSize: "16px",
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "30px",
  padding: "6px",
  paddingRight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "800px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
}));

const ListingPercentage = [
  { name: "0-10", profileCompletitionFrom: 0, profileCompletitionTo: 10 },
  { name: "11-20", profileCompletitionFrom: 11, profileCompletitionTo: 20 },
  { name: "21-30", profileCompletitionFrom: 21, profileCompletitionTo: 30 },
  { name: "31-40", profileCompletitionFrom: 31, profileCompletitionTo: 40 },
  { name: "41-50", profileCompletitionFrom: 41, profileCompletitionTo: 50 },
  { name: "51-60", profileCompletitionFrom: 51, profileCompletitionTo: 60 },
  { name: "61-70", profileCompletitionFrom: 61, profileCompletitionTo: 70 },
  { name: "71-80", profileCompletitionFrom: 71, profileCompletitionTo: 80 },
  { name: "81-90", profileCompletitionFrom: 81, profileCompletitionTo: 90 },
  { name: "91-100", profileCompletitionFrom: 91, profileCompletitionTo: 100 },
];

const bool = [
  {
    id: 0,
    name: "No",
    value: false,
  },
  {
    id: 1,
    name: "Yes",
    value: true,
  },
];

const ViewMoreFiltersModal = (props) => {
  const {
    open,
    close,
    locations,
    industries,
    handleSubmit,
    owners,
    modalFilters,
    setModalFilters,
    initialState,
    sections,
    panels,
    setFiltersSelectAll,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [section, setSection] = useState(sections);
  const [expandedPanels, setExpandedPanels] = useState(panels);
  const [expandAllPanels, setExpandAllPanels] = useState(true);
  const [isSorted, setIsSorted] = useState("");
  const [anchorBulk, setAnchorBulk] = useState(null);
  const [filters, setFilters] = useState(modalFilters);

  useEffect(() => {
    if (modalFilters.countries?.length > 0) {
      const array = ["countries", "company", ...modalFilters.countries];
      setExpandedPanels((prev) => [...prev, ...array]);
    }
    if (modalFilters.owners?.length > 0) {
      const array = ["owners"];
      setExpandedPanels((prev) => [...prev, ...array]);
    }
  }, []);

  useEffect(() => {
    if (filters?.companies?.length === 0) {
      setExpandedPanels((prevPanels) => {
        return prevPanels.filter((i) => i !== "company");
      });
    }
  }, [filters]);

  useEffect(() => {
    if (filters?.countries?.length === 0) {
      setExpandedPanels((prevPanels) => {
        return prevPanels.filter((i) => i !== "countries");
      });
    }
  }, [filters]);

  useEffect(() => {
    if (
      filters.ownersObjects.length === splitAccountOwnerNames(owners)?.length
    ) {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        owners: true,
      }));
    } else {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        owners: false,
      }));
    }
  }, [filters]);

  useEffect(() => {
    if (
      filters.countriesObjects.length ===
      getCountryWiseLocations(locations?.results)?.length
    ) {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        countries: true,
      }));
    } else {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        countries: false,
      }));
    }
  }, [filters]);

  // useEffect(() => {
  //   if (filters?.locations?.length > 0 && locations?.results) {
  //     const location = locations.results.filter((item) =>
  //       filters.locations.includes(item?.id)
  //     );
  //     const country = getCountryWiseLocations(location).map(
  //       (item) => item.countryName
  //     );
  //     country.forEach((item) => {
  //       setExpandedPanels((prevPanels) => {
  //         if (!prevPanels.includes(item)) {
  //           return [...prevPanels, item];
  //         }
  //         return prevPanels;
  //       });
  //     });
  //   } else {
  //     setExpandedPanels([]);
  //   }
  // }, [filters]);

  const handleClose = () => {
    setAnchorBulk(null);
  };

  const handleClickMenu = (event) => {
    setAnchorBulk(event.currentTarget);
  };

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  const getCountryWiseLocations = (locationsArray) => {
    if (locationsArray !== undefined) {
      return countriesData
        ?.map((country) => {
          const locations = locationsArray
            ?.filter(
              (location) =>
                location.countryCode === country.code ||
                location.country === country.name
            )
            ?.map((location) => location);
          const companies = locations?.map((location) => location.company);
          return {
            countryName: country.name,
            countryCode: country.code,
            locationsArray: locations,
            companiesArray: companies,
          };
        })
        ?.filter((country) => country.locationsArray.length > 0);
    } else return [];
  };

  const getCompanyWiseLocations = (countriesArray) => {
    if (countriesArray !== undefined) {
      const mergedCompanies = countriesArray.reduce((acc, item) => {
        return acc.concat(item?.companiesArray);
      }, []);

      return Array.from(
        mergedCompanies
          .reduce((map, obj) => map.set(obj.id, obj), new Map())
          .values()
      );
    } else return [];
  };

  const handleExpandAllPanels = () => {
    const arr = [...expandedPanels];
    getCountryWiseLocations(locations?.results)?.map((item) =>
      arr.push(item?.countryName)
    );
    const uniqueArray = [...new Set(arr)];
    setExpandedPanels(uniqueArray);
    setExpandAllPanels(false);
  };

  const handleCollapseAllPanelsPanels = () => {
    const filtered = expandedPanels?.filter((item) => item === "groups");
    setExpandedPanels(filtered);
    setExpandAllPanels(true);
  };

  const handleChangeAllCountryLocations = (e, locationsArr, countryName) => {
    const location = locations?.results?.filter((item) =>
      filters.locations.includes(item?.id)
    );
    const country = countriesData.find((item) => item?.code === countryName);
    if (e.target.checked) {
      let arr = [...location];
      let mergedArr = arr.concat(locationsArr);
      setFilters((prevFilters) => ({
        ...prevFilters,
        locations: mergedArr?.map((item) => item?.id),
      }));
    } else {
      const filtered = location?.filter((item) => {
        const countryMatch =
          item?.country === null ||
          country.name === null ||
          item?.country === "" ||
          item?.country === country.name;
        const countryCodeMatch =
          item?.countryCode === null ||
          country.code === null ||
          item?.countryCode === "" ||
          item?.countryCode === country.code;
        return !countryMatch || !countryCodeMatch;
      });

      setFilters((prevFilters) => ({
        ...prevFilters,
        locations: filtered
          ?.filter(
            (item) =>
              item?.countryCode !== countryName ||
              item?.country !== country.name
          )
          ?.map((item) => item?.id),
      }));
    }
  };

  const handleCheckForAllLocations = (countryLocations, selectedLocations) => {
    const array2IdSet = new Set(selectedLocations?.map((obj) => obj.id));
    return countryLocations.every((obj) => array2IdSet.has(obj.id));
  };

  const handleCheckedCountries = (arr) => {
    let locationIds = arr?.map((item) => item?.id);
    let newSet = new Set(filters.locations);
    return locationIds.some((element) => newSet.has(element));
  };

  const handleChangeSelectAllLocations = (e) => {
    if (e.target.checked) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        locations: getALlLocations(locations?.results),
      }));
      handleExpandAllPanels();
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        locations: [],
      }));
      handleCollapseAllPanelsPanels();
    }
  };

  const handleSelectAllCountries = (e) => {
    let countries = getCountryWiseLocations(locations?.results)?.map(
      (item) => item
    );
    const companies = countries
      ?.map((item) => item?.companiesArray?.map((item) => item?.id))
      .flat();
    const locationIds = countries
      ?.map((item) => item?.locationsArray?.map((item) => item?.id))
      .flat();
    if (e.target.checked) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        countriesObjects: countries,
        countries: countries?.map((item) => item?.countryName),
        companies: Array.from(new Set(companies)),
        locations: Array.from(new Set(locationIds)),
      }));
      setExpandedPanels((prevPanels) => [
        ...prevPanels,
        "countries",
        "company",
        ...countries?.map((item) => item?.countryName),
      ]);
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        countriesObjects: [],
        countries: [],
        companies: [],
        locations: [],
      }));
      setExpandedPanels([]);
    }
  };

  const handleSelectAllCompanies = (e) => {
    let countries = getCountryWiseLocations(locations?.results)?.map(
      (item) => item
    );
    const companies = countries
      ?.map((item) => item?.companiesArray?.map((item) => item?.id))
      .flat();
    const locationIds = countries
      ?.map((item) => item?.locationsArray?.map((item) => item?.id))
      .flat();
    if (e.target.checked) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        companies: Array.from(new Set(companies)),
        locations: Array.from(new Set(locationIds)),
      }));
      setExpandedPanels((prevPanels) => [
        ...prevPanels,
        "company",
        ...countries?.map((item) => item?.countryName),
      ]);
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        companies: [],
        locations: [],
      }));
      setExpandedPanels([]);
    }
  };

  const handleSelectAllIndustries = (e) => {
    if (e.target.checked) {
      let industry = industries?.map((item) => item?.industry);
      setFilters((prevFilters) => ({
        ...prevFilters,
        industries: industry,
      }));
      setExpandedPanels((prev) => [...prev, "industries"]);
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        industries: [],
      }));
      setExpandedPanels((prev) =>
        prev.filter((panel) => panel !== "industries")
      );
    }
  };

  const handleSelectAllOwners = (e) => {
    if (e.target.checked) {
      let accountOwners = splitAccountOwnerNames(owners)?.map(
        (item) => item?.accountOwnerName
      );
      setFilters((prevFilters) => ({
        ...prevFilters,
        owners: accountOwners,
        ownersObjects: splitAccountOwnerNames(owners),
      }));
      setExpandedPanels((prev) => [...prev, "owners"]);
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        owners: [],
        ownersObjects: [],
      }));
      setExpandedPanels((prev) => prev.filter((panel) => panel !== "owners"));
    }
  };

  const handleSelectAllPercentage = (e) => {
    if (e.target.checked) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        percentages: ListingPercentage,
      }));
      setExpandedPanels((prev) => [...prev, "percentage"]);
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        percentages: [],
      }));
      setExpandedPanels((prev) =>
        prev.filter((panel) => panel !== "percentage")
      );
    }
  };

  const getALlLocations = (location) => {
    let countries = getCountryWiseLocations(location)?.map((item) => item);
    const locationIds = countries
      ?.map((item) => item?.locationsArray?.map((item) => item?.id))
      .flat();
    return locationIds;
  };

  const handleSorted = (arr, ele) => {
    if (isSorted === "asc") {
      const sortedArr = arr.sort((a, b) => a[ele].localeCompare(b[ele]));
      return sortedArr;
    } else if (isSorted === "desc") {
      const sortedArr = arr.sort((a, b) => b[ele].localeCompare(a[ele]));
      return sortedArr;
    } else {
      return arr;
    }
  };

  const clearFilters = () => {
    setFilters(initialState);
    setExpandedPanels([]);
    setIsSorted("");
    setFiltersSelectAll({});
  };

  const splitAccountOwnerNames = (name) => {
    const names = name?.map((item) => item.accountOwnerName.toLowerCase());
    const nameList = Array.from(new Set(names?.join(",").split(",")));
    return nameList.map((item) => {
      return { accountOwnerName: item };
    });
  };

  return (
    <Modal open={open}>
      <Grid container>
        <Grid
          item
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid className={classes.styleMainModelContainer}>
            <Grid
              sx={{
                pb: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.mainContainerHeading}>
                {t("Filters")}
              </Typography>

              <IconButton
                autoFocus
                onClick={() => {
                  close(false);
                  setSection("locations");
                }}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider />
            <Grid className={classes.contentContainer}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Grid sx={{ display: "flex", gap: "10px" }}>
                  <CommonButton
                    type="submit"
                    isLoading={false}
                    disabled={false}
                    onSubmit={() => setSection("locations")}
                    label={t("TaskLocation")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={section === "locations" ? "false" : "true"}
                  />
                  <CommonButton
                    type="submit"
                    isLoading={false}
                    disabled={false}
                    onSubmit={() => setSection("others")}
                    label={t("others")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={section === "others" ? "false" : "true"}
                  />
                </Grid>
                <Grid>
                  <>
                    <CommonButton
                      type="submit"
                      isLoading={false}
                      disabled={false}
                      onSubmit={handleClickMenu}
                      label={
                        isSorted === "asc"
                          ? t("sortA-Z")
                          : isSorted === "desc"
                          ? t("sortZ-A")
                          : t("Sort_By")
                      }
                      icon={<KeyboardArrowDownIcon />}
                      leftMargin={true}
                      customHeight={true}
                      displayWhite={"true"}
                      iconLocation={true}
                      aria-owns={anchorBulk ? "simple-menu" : undefined}
                      aria-haspopup="true"
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorBulk}
                      open={Boolean(anchorBulk)}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          background: "#EBEBEC",
                        },
                      }}
                    >
                      <MenuItem
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          lineHeight: "20px",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#DBDBDC",
                          },
                        }}
                        onClick={(e) => {
                          setIsSorted("asc");
                          handleClose();
                        }}
                      >
                        {t("sortA-Z")}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          lineHeight: "20px",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#DBDBDC",
                          },
                        }}
                        onClick={(e) => {
                          setIsSorted("desc");
                          handleClose();
                        }}
                      >
                        {t("sortZ-A")}
                      </MenuItem>
                    </Menu>
                  </>
                </Grid>
              </Grid>
              {section === "locations" &&
                (locations !== undefined && locations?.results?.length > 0 ? (
                  <>
                    <Grid>
                      <Collapsible
                        title={t("countries")}
                        handleChange={handleExpandPanel}
                        expanded={expandedPanels.includes("countries")}
                        id={"countries"}
                        countryNameStyling={
                          filters.countries.length > 0 ? true : false
                        }
                        defaultPreventions={true}
                        handleSelectAllGroups={handleSelectAllCountries}
                        selectAllGroups={
                          filters.countriesObjects.length ===
                          getCountryWiseLocations(locations?.results)?.length
                        }
                        displayGroupSelectAll={true}
                        ComponentToRender={
                          <Grid container sx={{ gap: "10px" }}>
                            {locations !== null &&
                              locations?.results?.length > 0 &&
                              handleSorted(
                                getCountryWiseLocations(locations?.results),
                                "countryName"
                              )?.map((item, i) => (
                                <Item
                                  key={i}
                                  sx={{
                                    border: filters.countries.includes(
                                      item?.countryName
                                    )
                                      ? "1px solid #06BDFF"
                                      : "1px solid #EBEBEC",
                                  }}
                                  onClick={() => {
                                    const locationsIds =
                                      item?.locationsArray?.map(
                                        (loc) => loc.id
                                      );
                                    const companiesIds =
                                      item?.companiesArray?.map(
                                        (comp) => comp.id
                                      );
                                    // collapseCompanies(companiesIds);
                                    if (
                                      filters.countries.includes(
                                        item?.countryName
                                      )
                                    ) {
                                      setFilters((prevFilters) => ({
                                        ...prevFilters,
                                        countries:
                                          prevFilters.countries?.filter(
                                            (i) => i !== item?.countryName
                                          ),
                                        countriesObjects:
                                          prevFilters.countriesObjects?.filter(
                                            (i) =>
                                              i.countryName !==
                                              item?.countryName
                                          ),
                                        locations:
                                          prevFilters.locations?.filter(
                                            (i) => !locationsIds.includes(i)
                                          ),
                                        companies:
                                          prevFilters.companies?.filter(
                                            (i) => !companiesIds.includes(i)
                                          ),
                                      }));
                                      setExpandedPanels((prevPanels) => {
                                        return prevPanels.filter(
                                          (i) => i !== item?.countryName
                                        );
                                      });
                                    } else {
                                      setFilters((prevFilters) => ({
                                        ...prevFilters,
                                        countries: Array.from(
                                          new Set([
                                            ...prevFilters.countries,
                                            item?.countryName,
                                          ])
                                        ),
                                        countriesObjects: Array.from(
                                          new Set([
                                            ...prevFilters.countriesObjects,
                                            item,
                                          ])
                                        ),
                                        locations: Array.from(
                                          new Set([
                                            ...prevFilters.locations,
                                            ...locationsIds,
                                          ])
                                        ),
                                        companies: Array.from(
                                          new Set([
                                            ...prevFilters.companies,
                                            ...companiesIds,
                                          ])
                                        ),
                                      }));
                                      if (expandedPanels.includes("company")) {
                                        setExpandedPanels((prevPanels) => [
                                          ...prevPanels,
                                          item?.countryName,
                                        ]);
                                      } else {
                                        setExpandedPanels((prevPanels) => [
                                          ...prevPanels,
                                          "company",
                                          item?.countryName,
                                        ]);
                                      }
                                    }
                                  }}
                                >
                                  <Checkbox
                                    checked={filters.countries.includes(
                                      item?.countryName
                                    )}
                                    size="small"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#1B2430",
                                    }}
                                  >
                                    {item?.countryName}
                                  </Typography>
                                </Item>
                              ))}
                          </Grid>
                        }
                      />
                    </Grid>
                    <Grid>
                      <Collapsible
                        title={t("TaskCompany")}
                        handleChange={handleExpandPanel}
                        expanded={expandedPanels.includes("company")}
                        id={"company"}
                        defaultPreventions={true}
                        countryNameStyling={
                          filters.companies.length > 0 ? true : false
                        }
                        handleSelectAllGroups={handleSelectAllCompanies}
                        selectAllGroups={
                          filters.companies.length ===
                          getCompanyWiseLocations(
                            getCountryWiseLocations(locations?.results)
                          )?.length
                        }
                        displayGroupSelectAll={true}
                        ComponentToRender={
                          <Grid container sx={{ gap: "10px" }}>
                            {locations !== null &&
                              locations?.results?.length > 0 &&
                              handleSorted(
                                getCompanyWiseLocations(
                                  getCountryWiseLocations(locations?.results)
                                ),
                                "name"
                              )?.map((item, i) => (
                                <Item
                                  key={i}
                                  sx={{
                                    border: filters.companies.includes(item?.id)
                                      ? "1px solid #06BDFF"
                                      : "1px solid #EBEBEC",
                                  }}
                                  onClick={() => {
                                    const location = locations?.results?.filter(
                                      (loc) => loc.company.id === item?.id
                                    );
                                    const locationsIds = location?.map(
                                      (loc) => loc.id
                                    );
                                    filters.companies.includes(item?.id)
                                      ? setFilters((prevFilters) => ({
                                          ...prevFilters,
                                          companies:
                                            prevFilters.companies?.filter(
                                              (i) => i !== item?.id
                                            ),
                                          locations:
                                            prevFilters.locations?.filter(
                                              (i) => !locationsIds.includes(i)
                                            ),
                                        }))
                                      : setFilters((prevFilters) => ({
                                          ...prevFilters,
                                          companies: Array.from(
                                            new Set([
                                              ...prevFilters.companies,
                                              item?.id,
                                            ])
                                          ),
                                          locations: Array.from(
                                            new Set([
                                              ...prevFilters.locations,
                                              ...locationsIds,
                                            ])
                                          ),
                                        }));
                                  }}
                                >
                                  <Checkbox
                                    checked={filters.companies.includes(
                                      item?.id
                                    )}
                                    size="small"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#1B2430",
                                    }}
                                  >
                                    {item?.name}
                                  </Typography>
                                </Item>
                              ))}
                          </Grid>
                        }
                      />
                    </Grid>
                    <Grid>
                      <Grid
                        container
                        sx={{
                          padding: "8px",
                          border: "1px solid #EBEBEC",
                          borderRadius: "8px",
                        }}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                          <Grid
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 600, fontSize: "1.125rem" }}
                            >
                              {t("Locations")}
                            </Typography>

                            <Grid>
                              <SmallCheckbox
                                name="selectAll"
                                color="primary"
                                checked={
                                  locations !== null &&
                                  getALlLocations(locations?.results)
                                    ?.length === filters?.locations?.length
                                }
                                onChange={(e) =>
                                  handleChangeSelectAllLocations(e)
                                }
                                sx={{ marginRight: "4px" }}
                              />
                              {t("All_locationss")}
                              {expandAllPanels ? (
                                <IconButton onClick={handleExpandAllPanels}>
                                  <ExpandMoreIcon />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={handleCollapseAllPanelsPanels}
                                >
                                  <ExpandLessIcon />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ gap: "10px" }}>
                          {locations !== null &&
                            locations?.results?.length > 0 &&
                            handleSorted(
                              getCountryWiseLocations(locations?.results),
                              "countryName"
                            )?.map((e, i) => (
                              <Grid item key={i} sx={{ width: "100%" }}>
                                <Collapsible
                                  title={e.countryName}
                                  handleChange={handleExpandPanel}
                                  expanded={expandedPanels.includes(
                                    e.countryName
                                  )}
                                  expandedPanels={expandedPanels}
                                  setExpandedPanels={setExpandedPanels}
                                  id={e.countryName}
                                  countryNameStyling={handleCheckedCountries(
                                    e.locationsArray
                                  )}
                                  displaySelectAll={true}
                                  itemData={e}
                                  handleCheckForAllLocations={
                                    handleCheckForAllLocations
                                  }
                                  handleChangeAllCountryLocations={
                                    handleChangeAllCountryLocations
                                  }
                                  modellocations={locations?.results?.filter(
                                    (company) =>
                                      filters.locations.includes(company.id)
                                  )}
                                  countriesArr={e}
                                  ComponentToRender={
                                    <Grid container sx={{ gap: "10px" }}>
                                      {handleSorted(
                                        e.locationsArray,
                                        "internalName"
                                      )?.map((item, i) => (
                                        <Item
                                          key={i}
                                          sx={{
                                            border: filters.locations.includes(
                                              item?.id
                                            )
                                              ? "1px solid #06BDFF"
                                              : "1px solid #EBEBEC",
                                          }}
                                          onClick={() => {
                                            filters.locations.includes(item?.id)
                                              ? setFilters((prevFilters) => ({
                                                  ...prevFilters,
                                                  locations:
                                                    prevFilters.locations?.filter(
                                                      (i) => i !== item?.id
                                                    ),
                                                }))
                                              : setFilters((prevFilters) => ({
                                                  ...prevFilters,
                                                  locations: [
                                                    ...prevFilters.locations,
                                                    item?.id,
                                                  ],
                                                }));
                                          }}
                                        >
                                          <Checkbox
                                            checked={filters.locations.includes(
                                              item?.id
                                            )}
                                            size="small"
                                          />
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              color: "#1B2430",
                                            }}
                                          >
                                            {item?.internalName}
                                          </Typography>
                                        </Item>
                                      ))}
                                    </Grid>
                                  }
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Loader />
                ))}
              {section === "others" && (
                <>
                  <Grid>
                    <Collapsible
                      title={t("industries")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("industries")}
                      id={"industries"}
                      countryNameStyling={filters.industries.length > 0}
                      handleSelectAllGroups={handleSelectAllIndustries}
                      selectAllGroups={
                        filters.industries.length === industries?.length
                      }
                      defaultPreventions={true}
                      displayGroupSelectAll={industries?.length > 0}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {industries !== null && industries?.length > 0 ? (
                            handleSorted(industries, "industry")?.map(
                              (item, i) => (
                                <Item
                                  key={i}
                                  sx={{
                                    border: filters.industries.includes(
                                      item?.industry
                                    )
                                      ? "1px solid #06BDFF"
                                      : "1px solid #EBEBEC",
                                  }}
                                  onClick={() => {
                                    filters.industries.includes(item?.industry)
                                      ? setFilters((prevFilters) => ({
                                          ...prevFilters,
                                          industries:
                                            prevFilters.industries?.filter(
                                              (i) => i !== item?.industry
                                            ),
                                        }))
                                      : setFilters((prevFilters) => ({
                                          ...prevFilters,
                                          industries: [
                                            ...prevFilters.industries,
                                            item?.industry,
                                          ],
                                        }));
                                  }}
                                >
                                  <Checkbox
                                    checked={filters.industries.includes(
                                      item?.industry
                                    )}
                                    size="small"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#1B2430",
                                    }}
                                  >
                                    {item?.industry}
                                  </Typography>
                                </Item>
                              )
                            )
                          ) : (
                            <Grid
                              sx={{
                                width: "100%",
                                height: "100px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="h3">
                                {t("data_found")}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      }
                    />
                  </Grid>
                  <Grid>
                    <Collapsible
                      title={t("Account_Owner")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("owners")}
                      id={"owners"}
                      defaultPreventions={true}
                      countryNameStyling={filters.owners.length > 0}
                      handleSelectAllGroups={handleSelectAllOwners}
                      selectAllGroups={
                        filters.owners.length ===
                        splitAccountOwnerNames(owners)?.length
                      }
                      displayGroupSelectAll={
                        splitAccountOwnerNames(owners)?.length > 0
                      }
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {owners !== null && owners?.length > 0 ? (
                            handleSorted(
                              splitAccountOwnerNames(owners),
                              "accountOwnerName"
                            )?.map((item, i) => (
                              <Item
                                key={i}
                                sx={{
                                  textTransform: "capitalize",
                                  border: filters.owners.includes(
                                    item?.accountOwnerName
                                  )
                                    ? "1px solid #06BDFF"
                                    : "1px solid #EBEBEC",
                                }}
                                onClick={() => {
                                  filters.owners.includes(
                                    item?.accountOwnerName
                                  )
                                    ? setFilters((prevFilters) => ({
                                        ...prevFilters,
                                        owners: prevFilters.owners?.filter(
                                          (i) => i !== item?.accountOwnerName
                                        ),
                                        ownersObjects:
                                          prevFilters.ownersObjects?.filter(
                                            (i) =>
                                              i.accountOwnerName !==
                                              item.accountOwnerName
                                          ),
                                      }))
                                    : setFilters((prevFilters) => ({
                                        ...prevFilters,
                                        owners: [
                                          ...prevFilters.owners,
                                          item?.accountOwnerName,
                                        ],
                                        ownersObjects: [
                                          ...prevFilters.ownersObjects,
                                          item,
                                        ],
                                      }));
                                }}
                              >
                                <Checkbox
                                  checked={filters.owners.includes(
                                    item?.accountOwnerName
                                  )}
                                  size="small"
                                />
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#1B2430",
                                  }}
                                >
                                  {item?.accountOwnerName}
                                </Typography>
                              </Item>
                            ))
                          ) : (
                            <Grid
                              sx={{
                                width: "100%",
                                height: "100px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="h3">
                                {t("data_found")}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      }
                    />
                  </Grid>
                  <Grid>
                    <Collapsible
                      multiple
                      title={t("ListingPercentage")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("percentage")}
                      id={"percentage"}
                      defaultPreventions={true}
                      countryNameStyling={filters.percentages.length > 0}
                      handleSelectAllGroups={handleSelectAllPercentage}
                      selectAllGroups={
                        filters.percentages.length === ListingPercentage.length
                      }
                      displayGroupSelectAll={true}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {ListingPercentage?.map((item, i) => (
                            <Item
                              key={i}
                              sx={{
                                border: filters.percentages.includes(item)
                                  ? "1px solid #06BDFF"
                                  : "1px solid #EBEBEC",
                              }}
                              onClick={() =>
                                filters.percentages.includes(item)
                                  ? setFilters((prevFilters) => ({
                                      ...prevFilters,
                                      percentages:
                                        prevFilters.percentages?.filter(
                                          (i) => i !== item
                                        ),
                                    }))
                                  : setFilters((prevFilters) => ({
                                      ...prevFilters,
                                      percentages: [
                                        ...prevFilters.percentages,
                                        item,
                                      ],
                                    }))
                              }
                            >
                              <Checkbox
                                checked={filters.percentages.includes(item)}
                                size="small"
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#1B2430",
                                }}
                              >
                                {item?.name}
                              </Typography>
                            </Item>
                          ))}
                        </Grid>
                      }
                    />
                  </Grid>
                  <Grid>
                    <Collapsible
                      title={t("GooglePost")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("posts")}
                      id={"posts"}
                      countryNameStyling={filters.post !== null}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {bool?.map((item, i) => (
                            <Item
                              key={i}
                              sx={{
                                border:
                                  filters.post !== null &&
                                  filters.post === item?.value
                                    ? "1px solid #06BDFF"
                                    : "1px solid #EBEBEC",
                              }}
                              onClick={() => {
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  post:
                                    prevFilters.post === item?.value
                                      ? null
                                      : item?.value,
                                }));
                              }}
                            >
                              <Checkbox
                                checked={filters.post === item?.value}
                                size="small"
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#1B2430",
                                }}
                              >
                                {item?.name}
                              </Typography>
                            </Item>
                          ))}
                        </Grid>
                      }
                    />
                  </Grid>
                  <Grid>
                    <Collapsible
                      title={t("Review_Response")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("reviews")}
                      id={"reviews"}
                      countryNameStyling={filters.reviews !== null}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {bool?.map((item, i) => (
                            <Item
                              key={i}
                              sx={{
                                border:
                                  filters.reviews !== null &&
                                  filters.reviews === item?.value
                                    ? "1px solid #06BDFF"
                                    : "1px solid #EBEBEC",
                              }}
                              onClick={() => {
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  reviews:
                                    prevFilters.reviews === item?.value
                                      ? null
                                      : item?.value,
                                }));
                              }}
                            >
                              <Checkbox
                                checked={filters.reviews === item?.value}
                                size="small"
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#1B2430",
                                }}
                              >
                                {item?.name}
                              </Typography>
                            </Item>
                          ))}
                        </Grid>
                      }
                    />
                  </Grid>
                  <Grid>
                    <Collapsible
                      title={t("Deal_Value")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("deal")}
                      countryNameStyling={
                        (filters.deal.min !== 0 || filters.deal.max !== 0) &&
                        (filters.deal.min !== "" || filters.deal.max !== "")
                      }
                      id={"deal"}
                      ComponentToRender={
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              label={t("From")}
                              title={t("From")}
                              type="number"
                              placeholder={t("From")}
                              id="outlined-name"
                              value={filters.deal.min}
                              sx={{
                                background: "#FFF",
                                padding: "0px",
                                height: "52px",
                                width: "100%",
                              }}
                              onChange={(e) => {
                                const value = e.target.value;
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  deal: {
                                    ...prevFilters.deal,
                                    min: value === "" ? "" : Number(value),
                                  },
                                }));
                              }}
                              onFocus={(e) => {
                                if (e.target.value === "0") {
                                  setFilters((prevFilters) => ({
                                    ...prevFilters,
                                    deal: {
                                      ...prevFilters.deal,
                                      min: "",
                                    },
                                  }));
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              label={t("To")}
                              title={t("To")}
                              type="number"
                              placeholder={t("To")}
                              id="outlined-name"
                              value={filters.deal.max}
                              sx={{
                                background: "#FFF",
                                padding: "0px",
                                height: "52px",
                                width: "100%",
                              }}
                              onChange={(e) => {
                                const value = e.target.value;
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  deal: {
                                    ...prevFilters.deal,
                                    max: value === "" ? "" : Number(value),
                                  },
                                }));
                              }}
                              onFocus={(e) => {
                                if (e.target.value === "0") {
                                  setFilters((prevFilters) => ({
                                    ...prevFilters,
                                    deal: {
                                      ...prevFilters.deal,
                                      max: "",
                                    },
                                  }));
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                py: "10px",
              }}
            >
              <CommonButton
                type="submit"
                isLoading={false}
                disabled={locations === null}
                onSubmit={() => {
                  clearFilters();
                  setSection("locations");
                }}
                label={t("Clear_Filters")}
                leftMargin={true}
                customHeight={true}
                displayWhite={"true"}
              />
              <CommonButton
                type="submit"
                isLoading={false}
                disabled={locations === null}
                onSubmit={() => {
                  const data = { ...filters };
                  data.deal.max = data.deal.max === "" ? 0 : data.deal.max;
                  data.deal.min = data.deal.min === "" ? 0 : data.deal.min;
                  setModalFilters(data);
                  handleSubmit(data);
                  setSection("locations");
                }}
                label={t("Apply_filters")}
                leftMargin={true}
                customHeight={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ViewMoreFiltersModal;
