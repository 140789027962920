import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
  createFilterOptions,
  Box,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useStyles } from "../Style/style";
import useAuth from "../../../../hooks/useAuth";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import RefreshIcon from "@mui/icons-material/Refresh";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import EmotionAiFilterModal from "./EmotionAiFilterModel";
import CompareDatesFilterModel from "./CompareDatesModel";
import moment from "moment";
import FilterModal from "../../../../components/Models/FilterModelEmotionAI";
import Stack from "@mui/material/Stack";
import { ListItem, ListItemText, ListItemIcon } from "@mui/material";
import Chip from "@mui/material/Chip";

import DatePickerComponent from "../../../../components/CustomComponents/DateRangePicker/RangePicker";
import useDateFormat from "../../../../hooks/useDateFormat";

const FilterHeader = ({
  allLocations,
  locations,
  setLocations,
  range,
  handleChangeRange,
  startDate,
  handleChangeStartDate,
  handleChangeEndDate,
  endDate,
  resetHandler,
  resetCompareModel,
  openModalFilter,
  handleOpenEditUser,
  handleChangeLocation,
  handleCloseModalFilter,
  handleOpenCompareDatesModel,
  handleCloseCompareDatesModel,
  openCompareModel,
  handleChangeSentiment,
  sentimentValue,
  handleChangeLanguage,
  language,
  errorMessage,
  allGroups,
  setSelectedGroups,
  selectedGroups,
  handleChangeGroups,
  selectAll,
  setSelectAll,
  handleChangeNextStartDate,
  handleChangeNextEndDate,
  compareRange,
  handleChangeCompareRange,
  nextEndDate,
  nextStartDate,
  handleApplyFilter,
  setCallApi,
  countries,
  handleChangeGroupsForLocations,
  resetStart,
  resetEnd,
  resetNextStart,
  resetNextEnd,
  locationAddPermission,
  locationViewPermission,
  defaultLocation,
  setAutoSelectHeader,
  autoSelectHeader,
  isSentimentalAnalysisDone,
  setSelectAllLocations,
  selectAllLocations,
  handleOpenNewRequestModel,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { format } = useDateFormat();
  const filter = createFilterOptions();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [dateRange, setDateRange] = React.useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  });

  const dateValues = [
    {
      label: `${t("In_Range")}`,
      value: "between",
    },
    {
      label: `${t("before")}`,
      value: "lt",
    },
    {
      label: `${t("after")}`,
      value: "gt",
    },
  ];
  const compareDateValues = [
    {
      label: `${t("In_Range")}`,
      value: "between",
    },
    {
      label: `${t("before")}`,
      value: "lt",
    },
    {
      label: `${t("after")}`,
      value: "gt",
    },
  ];
  function mergeGroups(mainArray) {
    return mainArray.reduce((accumulator, currentObject) => {
      return accumulator.concat(currentObject.groups);
    }, []);
  }
  function removeMatchingObjects(array, groups) {
    const locationIdsSet = new Set(groups.map((group) => group.locationId));
    return array.filter((obj) => !locationIdsSet.has(obj.id));
  }

  const handleChangeAutoSelect = (e) => {
    setAutoSelectHeader(e.target.checked);

    if (e.target.checked) {
      if (selectedGroups?.length > 0) {
        handleChangeGroups(selectedGroups, true);
      }
    } else {
      if (selectedGroups?.length > 0) {
        const mergedGroups = mergeGroups(selectedGroups);

        const filteredArray = removeMatchingObjects(locations, mergedGroups);
        handleChangeLocation(filteredArray);
      }
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3.5} sx={{ paddingTop: "8px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {allLocations?.length > 0 && (
              <>
                <Autocomplete
                  multiple
                  disablePortal
                  disableCloseOnSelect
                  id="combo-box-demo"
                  options={allLocations?.length > 0 ? allLocations : []}
                  getOptionDisabled={(option) => {
                    if (option?.id === 0) {
                      return false;
                    }
                    //  return option?.statusOfSentimentalAnalysis !== "done";
                  }}
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        ((option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null) ||
                        (option?.city !== "" && option?.city !== null)
                          ? "(" +
                            (option?.addressLine1 !== "" &&
                            option?.addressLine1 !== null &&
                            option?.addressLine1 !== undefined
                              ? option?.addressLine1 + "," + " "
                              : "") +
                            (option?.city ? option?.city : "") +
                            ")"
                          : "")
                      : ""
                  }
                  className="Autocomplete-field"
                  value={locations ? locations : []}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => {
                    setCallApi(false);
                    handleChangeLocation(value);
                    const filterLocations = allLocations?.filter(
                      (item) => item?.statusOfSentimentalAnalysis === "done"
                    );
                    if (value.find((option) => option.id === 0)) {
                      if (filterLocations?.length === locations?.length) {
                        setLocations([]);
                        setSelectAllLocations(false);
                      } else {
                        setSelectAllLocations(true);
                        setLocations(filterLocations);
                      }
                      handleChangeGroupsForLocations(filterLocations);
                    } else {
                      const filterLocationsFromValue = value?.filter(
                        (item) => item?.statusOfSentimentalAnalysis === "done"
                      );
                      const filterLocationswithOutanalysis = value?.filter(
                        (item) =>
                          item?.statusOfSentimentalAnalysis === "pending" &&
                          item?.reviewFoundStatus === true
                      );
                      if (filterLocationswithOutanalysis?.length > 0) {
                        handleOpenNewRequestModel(
                          filterLocationswithOutanalysis[0]
                        );
                      }
                      setLocations(filterLocationsFromValue);
                      if (autoSelectHeader) {
                        handleChangeGroupsForLocations(
                          filterLocationsFromValue
                        );
                      }
                      if (
                        filterLocationsFromValue?.length ===
                        filterLocations?.length
                      ) {
                        setSelectAllLocations(true);
                      } else {
                        setSelectAllLocations(false);
                      }
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [{ id: 0, internalName: "Select All" }, ...filtered];
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        disabled={
                          option?.id !== 0 &&
                          option?.statusOfSentimentalAnalysis !== "done"
                        }
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                          (option?.id === 0 && selectAllLocations) ||
                          (selected &&
                            option?.statusOfSentimentalAnalysis === "done")
                        }
                      />

                      <Typography>
                        <span
                          style={{
                            textAlign: "start",
                            color:
                              ((option?.statusOfSentimentalAnalysis ===
                                "done" ||
                                option?.statusOfSentimentalAnalysis ===
                                  "pending") &&
                                option?.reviewFoundStatus === true) ||
                              option?.id == 0
                                ? "#1B2430"
                                : "#BBBDC1",
                          }}
                        >
                          {`${option?.internalName} ${
                            option?.addressLine1 !== "" &&
                            option?.addressLine1 !== null &&
                            option?.addressLine1 !== undefined
                              ? "(" + option?.addressLine1 + "," + " "
                              : ""
                          } ${option?.city ? option?.city + ")" : ""}`}
                        </span>
                        {option?.statusOfSentimentalAnalysis && (
                          <span
                            onClick={() => {
                              if (
                                option?.statusOfSentimentalAnalysis ===
                                  "pending" &&
                                option?.reviewFoundStatus === true
                              ) {
                                handleOpenNewRequestModel(option);
                              }
                            }}
                            style={{
                              marginLeft: "10px",
                              color:
                                option?.statusOfSentimentalAnalysis === "done"
                                  ? "#13CF8F"
                                  : option?.reviewFoundStatus === false
                                  ? "#FC3652"
                                  : option?.statusOfSentimentalAnalysis ===
                                    "requested"
                                  ? "#F86F35"
                                  : option?.statusOfSentimentalAnalysis ===
                                    "inProgress"
                                  ? "#FC3652"
                                  : option?.reviewFoundStatus === true &&
                                    option?.statusOfSentimentalAnalysis ===
                                      "pending"
                                  ? "#F8A92B"
                                  : "",
                            }}
                          >
                            {option?.statusOfSentimentalAnalysis === "done"
                              ? "• " + `${t("Done")}`
                              : ""}
                            {option?.reviewFoundStatus === false
                              ? "• " + `${t("No_reviews")}`
                              : ""}
                            {option?.statusOfSentimentalAnalysis === "requested"
                              ? "• " + `${t("requested_state")}`
                              : ""}
                            {option?.statusOfSentimentalAnalysis ===
                            "inProgress"
                              ? "• " + `${t("inProgress")}`
                              : ""}
                            {option?.reviewFoundStatus === true &&
                            option?.statusOfSentimentalAnalysis === "pending"
                              ? "• " + `${t("please_request")}`
                              : ""}
                          </span>
                        )}
                      </Typography>
                    </li>
                  )}
                  renderTags={(value, getTagProps) => {
                    const MAX_DISPLAY_ITEMS = 2;
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                        }}
                      >
                        <Stack
                          direction="column"
                          spacing={1}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            flexWrap: "nowrap",
                          }}
                        >
                          {value
                            .slice(0, MAX_DISPLAY_ITEMS)
                            .map((option, index) => (
                              <Chip
                                style={{
                                  background: "#FFF",
                                  border: "1px solid #06BDFF",
                                  borderRadius: "800px",
                                }}
                                key={index}
                                label={`${option?.internalName} ${
                                  option?.addressLine1 !== "" &&
                                  option?.addressLine1 !== null &&
                                  option?.addressLine1 !== undefined
                                    ? "(" + option?.addressLine1 + "," + " "
                                    : ""
                                } ${option?.city ? option?.city + ")" : ""}`}
                                {...getTagProps({ index })}
                              />
                            ))}
                          {value.length > MAX_DISPLAY_ITEMS && (
                            <Chip
                              style={{
                                background: "#FFF",
                                border: "1px solid #BBBDC1",
                                borderRadius: "800px",
                                cursor: "pointer",
                                fontWeight: 700,
                                width: "100px",
                              }}
                              onClick={() => handleOpenEditUser()}
                              label={`+${
                                value.length - MAX_DISPLAY_ITEMS
                              } more`}
                            />
                          )}
                        </Stack>
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                />
              </>
            )}
            {errorMessage && (
              <div className={classes.errorMessageStyle}>{errorMessage}</div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Autocomplete
              multiple
              disablePortal
              disableCloseOnSelect
              id="combo-box-demo"
              options={
                allGroups !== null && allGroups?.rows?.length > 0
                  ? allGroups?.rows
                  : []
              }
              getOptionDisabled={(option) => {
                if (option?.id === 0) {
                  return false;
                }
                if (isSentimentalAnalysisDone(option?.groups)) {
                  return false;
                } else {
                  return true;
                }
                return false;
              }}
              getOptionLabel={(option) => (option?.name ? option?.name : "")}
              className="Autocomplete-field"
              value={selectedGroups ? selectedGroups : []}
              onChange={(e, value) => {
                setCallApi(false);
                let arr = [];
                allGroups?.rows?.map((item) => {
                  if (isSentimentalAnalysisDone(item?.groups)) {
                    arr.push(item);
                  }
                });

                if (value.find((option) => option.id === 0)) {
                  if (selectedGroups?.length === arr?.length) {
                    setSelectedGroups([]);
                    setSelectAll(false);
                  } else {
                    setSelectAll(true);

                    handleChangeGroups(arr, autoSelectHeader);
                  }
                } else {
                  handleChangeGroups(value, autoSelectHeader);
                  if (value?.length === arr?.length) {
                    setSelectAll(true);
                  } else {
                    setSelectAll(false);
                  }
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (allGroups?.rows?.length > 0) {
                  return [{ id: 0, name: "Select All" }, ...filtered];
                } else {
                  return [];
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={
                      (option?.id === 0 && selectAll) ||
                      (selected && isSentimentalAnalysisDone(option?.groups))
                    }
                    id="select-all-checkbox"
                  />

                  <Typography>{option?.name}</Typography>
                  {isSentimentalAnalysisDone(option?.groups) === false &&
                    option?.id !== 0 && (
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "#F8A92B",
                        }}
                      >
                        {" "}
                        {"•" + " " + t("Pending")}
                      </span>
                    )}
                </li>
              )}
              renderTags={(value, getTagProps) => {
                const MAX_DISPLAY_ITEMS = 2;
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  >
                    <Stack
                      // direction="row"
                      spacing={1}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        flexWrap: "nowrap",
                        display: "inline",
                      }}
                    >
                      {value
                        .slice(0, MAX_DISPLAY_ITEMS)
                        .map((option, index) => (
                          <Chip
                            style={{
                              background: "#FFF",
                              border: "1px solid #06BDFF",
                              borderRadius: "800px",
                            }}
                            key={index}
                            label={option?.name}
                            {...getTagProps({ index })}
                          />
                        ))}
                      {value.length > MAX_DISPLAY_ITEMS && (
                        <Chip
                          style={{
                            background: "#FFF",
                            border: "1px solid #BBBDC1",
                            borderRadius: "800px",
                            cursor: "pointer",
                            fontWeight: 700,
                            width: "100px",
                            marginTop: "4px",
                          }}
                          onClick={() => handleOpenEditUser()}
                          label={`+${value.length - MAX_DISPLAY_ITEMS} more`}
                        />
                      )}
                    </Stack>
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={t("Select_group")}
                  {...params}
                  required
                  name="language"
                />
              )}
            ></Autocomplete>
            <Grid></Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: "flex" }}>
            <Checkbox
              checked={autoSelectHeader}
              onChange={(e) => {
                handleChangeAutoSelect(e);
                //  handleChangeLocationModel(arr);
                //  handleChangeGroupsModel(arr, e.target.checked);
              }}
              size="small"
              sx={{
                paddingRight: "3px",
                paddingLeft: "0px",
                paddingTop: "3px",
              }}
            />
            <Typography sx={{ fontSize: "12px", marginTop: "4px" }}>
              {t("auto-select-checkbox-group")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/*    <MobileDateRangePicker
        startText="From"
        endText="To"
        value={value}
        onChange={(newValue) => {
          if (value?.length > 0) {
          }
          setValue(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <>
            <TextField
              {...startProps}
              variant="outlined"
              margin="dense"
              helperText=""
              style={{ marginRight: "8px", backgroundColor: "white" }}
            />
            <TextField
              {...endProps}
              variant="outlined"
              margin="dense"
              helperText=""
              style={{ backgroundColor: "white" }}
            />
          </>
        )}
      /> */}

      <Grid item xs={12} sm={12} md={0.5} lg={0.5}>
        <Typography className={classes.fieldLabell}>{t("Datee")}:</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={2.8} lg={3.5}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.rangeGrid}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={dateValues}
              getOptionLabel={(option) => option?.label}
              sx={{ width: "100%" }}
              //  value={locationId}
              value={range}
              onChange={(e, value) => {
                setCallApi(false);
                handleChangeRange(value);
              }}
              className="Autocomplete-field"
              renderInput={(params) => (
                <TextField
                  placeholder={t("Select Range")}
                  {...params}
                  required
                  name="language"
                />
              )}
            ></Autocomplete>
          </Grid>
          {range !== null && range?.value === "gt" && (
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: "8px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  //  className={{ root: classes.whiteBackground }}
                  disableFuture
                  placeholder={t("Fromm")}
                  label={t("Fromm")}
                  fullWidth
                  value={
                    startDate !== "" && startDate !== null
                      ? dayjs(startDate)
                      : null
                  }
                  className={classes.whiteBackground}
                  format={format}
                  minDate={dayjs("2005-01-01")}
                  maxDate={dayjs().endOf("day")}
                  onChange={(e) => {
                    setCallApi(false);
                    if (range?.value === "between") {
                      handleChangeStartDate(e);
                    } else {
                      handleChangeStartDate(e);
                      const today = new Date();
                      handleChangeEndDate(today);
                    }
                  }}
                  //  disabled={range !== null && range?.value === "gt"}

                  renderInput={(params) => (
                    <TextField
                      autoComplete="off"
                      fullWidth
                      {...params}
                      sx={{ background: "#FFFF" }}
                      //   label={t("From")}
                      // sx={{ padding: "8px" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}
          {range !== null && range?.value === "lt" && (
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: "8px" }}>
              {" "}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  /*  sx={{
                    // width: "320px",
                    border: "1px solid #E0E0E0",
                    width: "320px",
                    background: "white",
                  }} */
                  disableFuture
                  format={format}
                  fullWidth
                  // maxDate={endDate}
                  minDate={dayjs("2005-01-01")}
                  maxDate={dayjs().endOf("day")}
                  className={classes.whiteBackground}
                  value={
                    endDate !== "" && endDate !== null ? dayjs(endDate) : null
                  }
                  onChange={(e) => {
                    setCallApi(false);
                    const today = new Date();
                    if (range?.value === "between") {
                      let selectedDate = dayjs(e).format("YYYY-MMM-DD");
                      let todayDate = moment(today).format("YYYY-MMM-DD");

                      handleChangeEndDate(e.endOf("day"));
                    } else {
                      let selectedDate = dayjs(e).format("YYYY-MMM-DD");
                      let todayDate = moment(today).format("YYYY-MMM-DD");

                      handleChangeEndDate(e.endOf("day"));

                      const newEndDate = dayjs(e);
                      const previousYear = new Date(
                        newEndDate.year() - 1,
                        newEndDate.month(),
                        newEndDate.date()
                      );

                      handleChangeStartDate(previousYear);
                    }
                  }}
                  /*   disabled={
                    startDate===null ||
                    (range !== null && range?.value === "lt")
                  } */
                  placeholder={t("Too")}
                  label={t("Too")}
                  renderInput={(params) => (
                    <TextField
                      autoComplete="off"
                      fullWidth
                      {...params}
                      sx={{ background: "#FFFF" }}
                      //  label={t("To")}
                      //   error={dateRequired}
                      // sx={{ padding: "8px" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}
          {range !== null && range?.value === "between" && (
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: "0px" }}>
              <DatePickerComponent
                handleChangeStartDate={handleChangeStartDate}
                handleChangeEndDate={handleChangeEndDate}
                startDate={startDate}
                endDate={endDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                setCallApi={setCallApi}
                removePadding={false}
                // rangeValue={"between"}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={6} sx={{ padding: "0px" }}>
            <Button
              onClick={() => {
                handleOpenCompareDatesModel();
              }}
              className={classes.compareFilterButton}
            >
              <Typography className={classes.viewMoreFilterTypo}>
                {t("Compare_to")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4.5}
        className={classes.filterHeaderButtons}
      >
        <Grid container className={classes.filterHeaderButtonsTwo}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            className={classes.filterHeaderButtonsFour}
          >
            <CommonButton
              //  displayBlack="true"
              //  icon={<RefreshIcon />}
              label={t("Apply_filters")}
              onSubmit={handleApplyFilter}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            className={classes.filterHeaderButtonsFour}
          >
            <CommonButton
              displayBlack="true"
              icon={<RefreshIcon />}
              label={t("Reset")}
              onSubmit={resetHandler}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            className={classes.filterHeaderButtonsFour}
          >
            <Button
              onClick={() => {
                handleOpenEditUser();
              }}
              className={classes.viewMoreFilterButton}
            >
              <Typography className={classes.viewMoreFilterTypo}>
                {t("View_More_Filters")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CompareDatesFilterModel
        handleCloseModalFilter={handleCloseCompareDatesModel}
        openCompareModel={openCompareModel}
        resetHandler={resetCompareModel}
        handleChangeStartDate={handleChangeStartDate}
        handleChangeEndDate={handleChangeEndDate}
        startDate={startDate}
        endDate={endDate}
        handleChangeRange={handleChangeRange}
        range={range}
        dateValues={dateValues}
        compareDateValues={compareDateValues}
        handleChangeNextStartDate={handleChangeNextStartDate}
        handleChangeNextEndDate={handleChangeNextEndDate}
        compareRange={compareRange}
        handleChangeCompareRange={handleChangeCompareRange}
        nextEndDate={nextEndDate}
        nextStartDate={nextStartDate}
        setCallApi={setCallApi}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      {openModalFilter && (
        <FilterModal
          locations={locations}
          allLocations={allLocations}
          handleChangeLocation={handleChangeLocation}
          handleChangeRange={handleChangeRange}
          range={range}
          dateValues={dateValues}
          startDate={startDate}
          endDate={endDate}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
          resetHandler={resetHandler}
          sentimentValue={sentimentValue}
          openModalFilter={openModalFilter}
          handleChangeSentiment={handleChangeSentiment}
          handleCloseModalFilter={handleCloseModalFilter}
          handleChangeLanguage={handleChangeLanguage}
          language={language}
          allGroups={allGroups}
          setSelectedGroups={setSelectedGroups}
          selectedGroups={selectedGroups}
          handleChangeGroups={handleChangeGroups}
          selectAllLocations={selectAllLocations}
          setSelectAllLocations={setSelectAllLocations}
          setLocations={setLocations}
          setSelectAll={setSelectAll}
          selectAll={selectAll}
          setCallApi={setCallApi}
          countries={countries}
          handleChangeGroupsForLocations={handleChangeGroupsForLocations}
          nextEndDate={nextEndDate}
          nextStartDate={nextStartDate}
          compareRange={compareRange}
          resetStart={resetStart}
          resetEnd={resetEnd}
          resetNextStart={resetNextStart}
          resetNextEnd={resetNextEnd}
          locationAddPermission={locationAddPermission}
          locationViewPermission={locationViewPermission}
          defaultLocation={defaultLocation}
          autoSelectHeader={autoSelectHeader}
          isSentimentalAnalysisDone={isSentimentalAnalysisDone}
          setAutoSelectHeader={setAutoSelectHeader}
          handleOpenNewRequestModel={handleOpenNewRequestModel}
        />
      )}
      {/*   <EmotionAiFilterModal
        locations={locations}
        allLocations={allLocations}
        handleChangeLocation={handleChangeLocation}
        handleChangeRange={handleChangeRange}
        range={range}
        dateValues={dateValues}
        startDate={startDate}
        endDate={endDate}
        handleChangeStartDate={handleChangeStartDate}
        handleChangeEndDate={handleChangeEndDate}
        resetHandler={resetHandler}
        sentimentValue={sentimentValue}
        openModalFilter={openModalFilter}
        handleChangeSentiment={handleChangeSentiment}
        handleCloseModalFilter={handleCloseModalFilter}
        handleChangeLanguage={handleChangeLanguage}
        language={language}
        allGroups={allGroups}
        setSelectedGroups={setSelectedGroups}
        selectedGroups={selectedGroups}
        handleChangeGroups={handleChangeGroups}
        selectAllLocations={selectAllLocations}
        setSelectAllLocations={setSelectAllLocations}
        setLocations={setLocations}
        setSelectAll={setSelectAll}
        selectAll={selectAll}
        setCallApi={setCallApi}
        // setDateRange={setDateRange}
      /> */}
    </>
  );
};

export default FilterHeader;
