import React, { useState, useEffect } from "react";
import { Grid, TablePagination, Typography, Box } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useNavigate } from "react-router-dom";

import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";

import CollapsibleTable from "./CollapseTable";

import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  Menu: {
    "&:hover": {
      backgroundColor: "gray !important",
    },
  },
  searchIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  mainBox: {
    marginTop: "5rem",
    marginInline: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  secondaryBox: {
    width: "80px",
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

// const headCells = [
//   { id: "Name", alignment: "left", label: "Name" },
//   { id: "Products", alignment: "left", label: "Products" },
//   { id: "Actions", alignment: "left", label: "Actions" },
// ];
// const bodyCells = [
//   { id: "Name", alignment: "left", label: "products" },
//   { id: "Products", alignment: "left", label: "Start date" },
//   { id: "Actions", alignment: "left", label: "Renewal date" },
// ];

const ActiveProducts = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = props.params;
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [loading, setLoading] = React.useState(false);
  const [filterWithName, setFilterWithName] = useState("");
  const [products, setProducts] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    getActiveProducts();
  }, []);
  useEffect(() => {
    getActiveProducts();
  }, [page, rowsPerPage]);
  // let allUsers = [];
  // users.forEach((user) => {
  //   allUsers.push(user);
  // });
  // const PartnerHandleChange = (selectedCustomer) => {
  //   setPartnerFilter(selectedCustomer.id);
  // };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getActiveProducts();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName, page, rowsPerPage]);

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };

  //   const FilterData = async () => {
  //

  //     try {
  //       setLoading(true);
  //       const res = await axios.get(
  //         `http://ec2-35-165-226-126.us-west-2.compute.amazonaws.com/api/v1/company?partnerId=${partnerFilter}&searchTerm=${filterWithName}`,
  //         config
  //       );
  //       if (res.status >= 200) {
  //         setAllCompanies(res.data.data);
  //
  //         setLoading(false);
  //       }
  //     } catch (error) {}
  //   };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
    getActiveProducts();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, products?.count - page * rowsPerPage);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => ({
      el,
      index,
    }));
    stabilizedThis.sort((a, b) => {
      const order = comparator(a.el, b.el);
      if (order !== 0) return order;
      return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
  }
  const getActiveProducts = async () => {
    try {
      setIsLoading(true);
      // setLoading(true);
      const res = await api.get(
        `/admin/location?companyId=${id}&page=${
          page + 1
        }&limit=${rowsPerPage}&searchTerm=${filterWithName}`
      );
      if (res.status === 200) {
        // setAllCompanies(usersArr);

        setProducts(res.data.data);
        // setLoading(false);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <Typography marginTop={"25px"} variant="h5">
        {t("ActiveProduct")}
      </Typography>
      <br />

      {/* <Paper> */}
      <Grid container spacing={1}>
        <Grid item xs={8} sm={8} md={8} lg={9}>
          <SearchField
            filterByName={filterByName}
            onSubmit={getActiveProducts}
            placeholder={t("FilterLocations")}
            filterWithName={filterWithName}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={3}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CommonButton
            onSubmit={getActiveProducts}
            label={t("Search")}
            alignment={true}
          />
          <CommonButton
            // isLoading={isLoading}
            onSubmit={() => navigate("/admin/add-location")}
            label={t("Add_Location")}
            alignment={true}
          />
        </Grid>
      </Grid>
      {/* </Paper> */}

      {products?.count > 0 ? (
        <>
          <CollapsibleTable
            t={t}
            stableSort={stableSort}
            getComparator={getComparator}
            page={page}
            rowsPerPage={rowsPerPage}
            product={products}
            emptyRows={emptyRows}
            createSortHandler={createSortHandler}
          />

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={products?.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <>
          <Box
            // className="Main_Box"
            className={classes.mainBox}
          >
            <Box>
              <SearchOutlinedIcon
                className={classes.secondaryBox}
                // className="Search_icons"
              />
            </Box>

            <Typography variant="h2">{t("Products_Not_Found")}</Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default ActiveProducts;
