import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";

const color = {
  darkGrey: "#1B2430",
  blue: "#06BDFF",
  grey: "#495059",
  white: "#FFFFFF",
  lightGrey: "#8D9298",
  darkPink: "#FC3652",
  lightGreen: "#13CF8F",
  darkBlue: "#0638C1",
  lightBlue: "#06BDFF",
  lightGray: "#E0E0E0",
  skyBlue: "#066DE8",
  mustard: "#F8A92B",
  gray: "#F6F6F7",
  graphite: "#545353",
  magenta: "#b02639",
  lightPink: "#FFD0D0",
  babypink: "#ea99a2",
  maroon: "#330404",
  red: "#FF1616",
  darkGray: "#cbd5e1",
  darkGraphite: "#f8fafc",
  darkRed: "#FF0000",
};

export const useStyles = makeStyles((theme) => ({
  signupHead: {
    fontSize: "24px",
    fontWeight: "700",
    textAlign: "center",
  },
  signupsubHead: {
    textAlign: "center",
    // fontSize="14px"
    mb: "1rem",
    color: color?.grey,
  },
  signInHead: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "700",
  },
  signInSubhead: {
    textAlign: "center",
    mb: "1rem",
    fontSize: "14px",
    color: color?.grey,
  },
  resetPasswordLabel: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "700",
  },
  resetPasswordsubtext: {
    textAlign: "center",
    fontSize: "14px",
    color: color?.grey,
  },
  signupLabel: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "700",
    fontSize: "14px",
    textDecoration: "none",
    lineHeight: "20px",
    marginTop: "1rem",
  },
  signInLabel: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "700",
    fontSize: "14px",
    textDecoration: "none",
    lineHeight: "20px",
    marginTop: "16px",
    marginBottom: "8px",
  },
  nameLabel: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "700",
    color: "rgba(0,0,0,0.87)",
  },
  signupButton: {
    marginBottom: "10px",
    marginTop: "16px",
    fontWeight: "700",
    fontSize: "14px",
  },
  signInButton: {
    fontWeight: "700",
    fontSize: "14px",
  },
  mainContainer: {
    background: "#E3F9F2",
    padding: "6px 16px",
    borderRadius: "7px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  errorText: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.43",
    color: "#330404",
    padding: "6px 0px",
  },
  errorIcon: {
    color: "#13CF8F",
    marginTop: "5px",
  },
  mainContainerUserRegister: {
    background: "#FDEDED",
    padding: "6px 16px",
    borderRadius: "7px",
    marginBottom: "10px",
    marginTop: "13px",
  },
  errorTextUserRegister: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.43",
    color: "#ef5350",
    padding: "6px 0px",
    marginLeft: "15px",
  },
  errorIconUserRegister: {
    color: "#ef5350",
    marginTop: "5px",
  },
}));
