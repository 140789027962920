import React from "react";
import { useStyles } from "../../Style/style";
import { useTranslation } from "react-i18next";
import {
  InfoOutlined,
  Refresh,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

function StatusCard(props) {
  const { handleRetryReply, status, text } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item lg={1} md={1}></Grid>
      <Grid item lg={11} md={11}>
        <Box className={classes.toast}>
          <Box
            className={classes.toastBox}
            sx={{
              backgroundColor:
                status === "info"
                  ? "#F1F7FF"
                  : status === "warning"
                  ? "#FFF1D7"
                  : "",
            }}
          >
            <Box
              className={classes.toastBoxBorder}
              sx={{
                backgroundColor:
                  status === "info"
                    ? "#06BDFF"
                    : status === "warning"
                    ? "#FAB431"
                    : "",
              }}
            ></Box>
            <Box className={classes.toastBoxContent}>
              <Box className={classes.toastBoxText}>
                {status === "warning" && (
                  <WarningAmberOutlined sx={{ color: "#FAB431" }} />
                )}
                {status === "info" && (
                  <InfoOutlined sx={{ color: "#06BDFF" }} />
                )}
                <Box>
                  <Typography>{t(text)}</Typography>
                </Box>
              </Box>
              {status === "warning" && (
                <BootstrapTooltip title={t("Retry")}>
                  <span>
                    <IconButton onClick={handleRetryReply}>
                      <Refresh />
                    </IconButton>
                  </span>
                </BootstrapTooltip>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
export default StatusCard;
