import { makeStyles } from "@mui/styles";
import { height, maxHeight, maxWidth } from "@mui/system";

export const useStyles = makeStyles((theme) => ({
  whiteBackground: {
    background: "white",
    width: "100%",
  },
  styleMainModelContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "550px",
    width: "800px",
    background: "#FFFF",
    border: "2px solid gray",
    borderRadius: "8px",
    boxShadow: 24,
    padding: "16px",
  },
  styleCommentContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "300px",
    width: "800px",
    background: "#FFFF",
    border: "2px solid gray",
    borderRadius: "8px",
    boxShadow: 24,
    padding: "16px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingTop: "10px",
    height: "430px",
    overflowY: "auto",
  },
  commentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingTop: "10px",
    height: "225px",
    overflowY: "auto",
  },
  mainContainerHeading: {
    fontWeight: 700,
    fontSize: "20px",
  },
  fieldLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    marginLeft: "10px",
    marginTop: "6px",
  },
  nameLabel: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "700",
    color: "rgba(0,0,0,0.87)",
  },
  commentBox: {
    background: "#FFFF",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    boxShadow: 24,
    padding: "16px",
    height: "100%",
    overflowY: "auto",
  },
  tagItem: {
    margin: "4px",
    marginBottom: "0px",
    backgroundColor: "#ebebec",
    borderRadius: "800px",
    display: "inline-block",
    padding: "4px 8px",
    fontSize: "12px",
    color: "#1B2430",
    fontWeight: "400",
  },
  keywordBox: {
    alignItems: "center",
    display: "flex",
    paddingX: "8px",
    paddingY: "6px",
  },
  keywordTypo: {
    fontSize: "12px",
    paddingX: "4px",
    fontWeight: "400",
    marginX: "4px",
    textTransform: "capitalize",
  },
  keywordIcon: {
    marginLeft: "6px",
    fontSize: "16px",
    color: "#1B2430",
    cursor: "pointer",
    "&:hover ": {
      color: "#06BDFF",
    },
  },
  tagsInput: {
    flexGrow: 1,
    padding: "4.5px 0",
    border: "none",
    outline: "none",
    overflow: "hidden",
  },
  tagsInputContainer: {
    border: "1px solid #c7c7c7",
    borderRadius: "4px",
    padding: "0.5em",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "4px",
  },
}));
