import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  TextField,
  Card,
  Checkbox,
  Autocomplete,
  Button,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Dialog } from "@material-ui/core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../.././../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImportIcon } from "../../../../../../../assets/images/importIcon.svg";
import { InputAdornment } from "@material-ui/core";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { languagesData } from "../../../../../../../assets/defaultLanguage";

import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../.././../components/Loaders/Loader";
// import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import ConfirmModal from "../../../../../.././../components/Models/ConfirmationModal";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const LocalPages = ({
  setTouchedValue,
  isLoading,
  descriptionSetArray,
  setDescriptionSetArray,
  handleAdd,
  handleDelete,
  handleChangeLanguage,
  handleChangeDescription,
  handleImportDescription,
  handleOpenDeleteModel,
  languagesDataArray,
  disableAddDescription,
  deleteLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridMianContainerDesc}
      >
        {descriptionSetArray?.length > 0 &&
          descriptionSetArray?.map((item, index) => (
            <Grid container spacing={2} className={classes.gridContainerDesc}>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography className={classes.reviewName}>
                  {t("Language_review")}
                </Typography>

                {languagesDataArray?.length > 0 && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={
                      languagesDataArray?.length > 0 ? languagesDataArray : []
                    }
                    value={languagesData?.find(
                      (data) => data?.value === item?.language
                    )}
                    onChange={(e, value) => {
                      handleChangeLanguage(value?.value, index);
                      setTouchedValue(true);
                    }}
                    getOptionLabel={(option) =>
                      option?.language ? option?.language : ""
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("Select_languages")}
                        {...params}
                        error={item?.languageError}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5}>
                <Box className={classes.boxContainerDesc}>
                  <Typography className={classes.reviewName}>
                    {t("Description")}
                  </Typography>
                  <BootstrapTooltip title={t("import_desc")}>
                    <IconButton onClick={() => handleImportDescription(index)}>
                      <ImportIcon />
                    </IconButton>
                  </BootstrapTooltip>
                </Box>

                <TextField
                  id="standard-basic"
                  rows={6}
                  maxRows={10}
                  multiline
                  type="text"
                  placeholder={t("Your_description_here")}
                  name="locationdescription"
                  fullWidth
                  error={item?.descriptionError}
                  value={item?.description}
                  onChange={(e) => {
                    handleChangeDescription(e.target.value, index);
                    setTouchedValue(true);
                  }}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                <Box className={classes.boxIconBtn}>
                  <IconButton
                    autoFocus
                    onClick={() => {
                      if (item?.hasOwnProperty("newAdded")) {
                        handleDelete(index);
                      } else {
                        handleOpenDeleteModel(item?._id);
                      }

                      //  setTouchedValue(true);
                    }}
                    /*   disabled={
                        descriptionSetArray?.length===1 &&
                        item?.hasOwnProperty("newAdded")
                      } */
                    className={classes.iconBtn}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          ))}
        <Grid
          container
          sx={{
            marginBottom: "20px",
            marginTop: descriptionSetArray?.length === 0 ? "12px" : "0px",
          }}
        >
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            sx={{
              marginLeft: descriptionSetArray?.length === 0 ? "20px" : "0px",
            }}
          >
            {descriptionSetArray?.length === 0 && (
              <Button
                autoFocus
                onClick={() => {
                  handleAdd();
                  setTouchedValue(true);
                }}
              >
                <AddIcon fontSize="medium" className={classes.addIcon} />{" "}
                <Typography className={classes.addButton}>
                  {t("Add_Description")}{" "}
                </Typography>
              </Button>
            )}
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} className={classes.btnDescs}>
            {descriptionSetArray?.length > 0 && (
              <Button
                autoFocus
                disabled={
                  languagesDataArray?.length === 0 || disableAddDescription
                }
                onClick={() => {
                  handleAdd();
                  setTouchedValue(true);
                }}
              >
                <AddIcon
                  fontSize="medium"
                  className={
                    languagesDataArray?.length === 0 || disableAddDescription
                      ? classes.addIconDisabled
                      : classes.addIcon
                  }
                />{" "}
                <Typography
                  className={
                    languagesDataArray?.length === 0 || disableAddDescription
                      ? classes.addButtonDisabled
                      : classes.addButton
                  }
                >
                  {t("Add_More_Description")}{" "}
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LocalPages;
