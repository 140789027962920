import React, { useState, useEffect } from "react";
import EditLocation from "./Components/EditLocationForm";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { alpha } from "@material-ui/core/styles";
import Loader from "../../../../components/Loaders/Loader";
import SocialConnections from "./Components/SocialConnections";
import { api } from "../../../../contexts/JWTContext";
import { useLocation } from "react-router-dom";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import TitleHeader from "../../../../components/CustomComponents/title";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Tabs,
  Tab,
} from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  Mainbox: {
    padding: "24px",
  },
  searchIcon: {
    bgcolor: "background.paper",

    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
  },
}));
function Index() {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const params = new URLSearchParams(search);
  const stateLocationIdArray = params.get("state").split("/");
  const googleScope = params.get("scope");
  const [isLoading, setIsLoading] = useState(false);
  const [singleLocation, setSingleLocation] = React.useState(null);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [locationSelectedSubscription, setLocationSelectedSubscription] =
    React.useState([]);
  const [subscriptionArr, setSubscriptionArr] = useState([]);

  useEffect(() => {
    if (location?.state !== null) {
      if (location?.state?.showProduct === true) {
        setSelectedTab(1);
      }
    } else {
      setSelectedTab(0);
    }
  }, []);

  useEffect(() => {
    if (location?.state !== null) {
      if (location?.state?.showProduct === true) {
        setSelectedTab(1);
      }
    } else {
      setSelectedTab(0);
    }
  }, []);
  /*   useEffect(() => {
    handleSubscriptions();
  }, [singleLocation]); */

  useEffect(async () => {
    getLocationById(stateLocationIdArray[0]);
    //  getAllSubscriptions();
  }, []);

  /*   useEffect(() => {
    if (
      (location?.state?.showProduct===true &&
        location?.state?.displayOn===true) ||
      (location?.search.length > 0 &&
        singleLocation?.locationSubscription?.length===0)
    ) {
      allSubscriptions?.results?.map((item) => subscriptionArr.push(item?.id));
      setSubscriptionArr([...subscriptionArr]);
    } else {
      if (singleLocation?.locationSubscription?.length > 0) {
        const activeSubscriptions = singleLocation?.locationSubscription.map(
        (item) =>
          item?.status === "active" &&
          subscriptionArr.push(item?.subscription?.id)
      ); 
        for (var i = 0; i < singleLocation?.locationSubscription?.length; i++) {
          singleLocation?.locationSubscription[i]?.status === "active" &&
            subscriptionArr.push(
              singleLocation?.locationSubscription[i]?.subscription?.id
            );
        }
        const uniquePosts = new Set(subscriptionArr);

        setSubscriptionArr([...uniquePosts]);
      }
    }
    return () => {
      setSubscriptionArr([]);
    };
  }, [allSubscriptions, singleLocation]); */

  useEffect(async () => {
    if (search?.length > 0 && googleScope?.length > 0) {
      setSelectedTab(1);
      setIsLoading(true);

      try {
        const res = await api.get(`google/auth-code` + search);
        if (res.data?.status === true) {
          await createSocialLink(res.data?.data);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);

        await getLocationById(stateLocationIdArray[0]);
      }

      // if (res.status > true) {
      //   getAllLocation()

      // }
    }
  }, [search]);
  const createSocialLink = async (data) => {
    try {
      const res = await api.post(`/socialLink`, {
        type: "google",
        refreshToken: data?.refreshToken,
        accountId: data?.accountId,
        locationId: data?.locationId,
        referenceId: data?.referenceId,
      });

      if (res?.data?.status === true) {
        await getLocationById(stateLocationIdArray[0]);
        toast.success("Google account attached successfully");
      }
    } catch (error) {}
  };
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleSubscriptions = () => {
    let locationActiveSub = [];
    singleLocation?.locationSubscription?.forEach((element, index) => {
      if (element?.subscription?.status === "active") {
        locationActiveSub?.push(element);
      }
    });
    setLocationSelectedSubscription(locationActiveSub);
  };

  const getLocationById = async (locationId) => {
    try {
      setIsLoading(true);
      const res = await api.get(`/admin/location/${locationId}`);
      if (res.status === 200) {
        setSingleLocation(res.data.data);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  /*  const getAllSubscriptions = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/admin/subscription`);
      if (res.status === 200) {
        setAllSubscriptions(res.data.data);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }; */

  return (
    <React.Fragment>
      <Helmet title="Profile" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TitleHeader
            showButton={true}
            title={singleLocation?.name}
            showDivider={true}
          />

          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ width: "100%" }}>
            <Tabs
              sx={{ width: "100%", marginTop: "2px" }}
              className={classes.Tabs}
              value={selectedTab}
              onChange={handleChange}
              variant="scrollable"
              centered
            >
              <Tab sx={{ width: "10%" }} label={t("Details")} />
              <Tab sx={{ width: "auto" }} label={t("Social_connections")} />
            </Tabs>
            <Divider mb={4} variant="middle" />
            {selectedTab === 0 && (
              <Grid container spacing={6}>
                <EditLocation
                  singleLocation={singleLocation}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  allSubscriptions={allSubscriptions}
                  getLocationById={stateLocationIdArray[0]}
                  getSingleLocation={getLocationById}
                />
              </Grid>
            )}
            {selectedTab === 1 && (
              <>
                <SocialConnections
                  singleLocation={singleLocation}
                  // allSubscriptions={allSubscriptions}
                  getLocationById={getLocationById}
                  getLocationId={stateLocationIdArray[0]}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  /*  locationSelectedSubscription={locationSelectedSubscription}
                  setSubscriptionArr={setSubscriptionArr}
                  subscriptionArr={subscriptionArr} */
                />
                {/*    <Box className={classes.Mainbox} component={Paper}>
                  <>
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={12} lg={12}>
                        <Box
                          sx={{
                            backgroundColor: "#F6F6F7",
                            padding: "24px",
                            marginBottom: "8px",
                          }}
                        >
                          <Grid container>
                            <Grid item sm={10} md={10} lg={10}>
                              <Typography
                                sx={{
                                  color: "#1B2430",
                                  fontSize: "18px",
                                  fontWeight: 700,
                                }}
                              >
                                Connect with Google
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#495059",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                Get access to our products after connecting this
                                location to Google
                              </Typography>
                            </Grid>
                            <Grid item sm={2} md={2} lg={2}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <CommonButton label="Connect" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box sx={{ fontSize: "20px", fontWeight: "700" }}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography variant="h3">
                              {t("Products")} <br />
                            </Typography>
                          </Stack>

                          <Typography sx={{ fontSize: "14px" }}>
                            {t("sol_desc")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box direction="column" spacing={2}>
                          {allSubscriptions?.results?.map(
                            (subscription, index) => {
                              const result = locationSelectedSubscription.find(
                                (data) =>
                                  data?.subscription?.id===subscription.id &&
                                  data?.status === "active"
                              );

                              return (
                                <SubscriptionCard
                                  subscription={subscription}
                                  result={result}
                                  getLocationById={getLocationById}
                                  getLocationId={getLocationId}
                                  setIsLoading={setIsLoading}
                                />
                              );
                            }
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                </Box> */}
              </>
            )}
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default Index;
