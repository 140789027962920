import React, { useState, useEffect } from "react";
import data from "../data.json";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import Truncate from "react-truncate";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useStyles } from "../../../../Styles/style";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const AttributesItem = ({ attributeItem, locationDetailsData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [displayLanguageSpoken, setDisplayLanguageSpoken] = useState(null);

  useEffect(() => {
    if (attributeItem?.name === "languagesSpoken") {
      const filteredItem = attributeItem?.value?.find(
        (item) => item?.spoken?.value !== null
      );
      setDisplayLanguageSpoken(filteredItem);
    }
  }, [locationDetailsData]);

  return (
    <>
      {attributeItem?.name === "languagesSpoken" ? (
        displayLanguageSpoken !== null &&
        displayLanguageSpoken !== undefined && (
          <>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography className={classes.headName}>
                {attributeItem?.name
                  ?.replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())
                  .trim()}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              {attributeItem?.value?.map(
                (item) =>
                  (item?.spoken?.value === true ||
                    item?.spoken?.value === false) && (
                    <Typography className={classes.headNameValue1}>
                      {`${item?.languageCode?.name} ${
                        item?.spoken?.value === true
                          ? "(" + "Yes" + ")"
                          : item?.spoken?.value === false
                          ? "(" + "No" + ")"
                          : ""
                      }`}
                    </Typography>
                  )
              )}
            </Grid>
          </>
        )
      ) : (
        <>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Typography className={classes.headName}>
              {attributeItem?.name
                ?.replace(/([A-Z])/g, " $1")
                .replace(/^./, (str) => str.toUpperCase())
                .trim()}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {attributeItem?.type === "boolean" &&
            attributeItem?.value === false ? (
              <Typography className={classes.headNameValue1}>
                {" "}
                {t("No")}
              </Typography>
            ) : attributeItem?.type === "boolean" &&
              attributeItem?.value === true ? (
              <Typography className={classes.headNameValue1}>
                {" "}
                {t("Yes")}
              </Typography>
            ) : attributeItem?.type === "integer" ||
              attributeItem?.type === "number" ? (
              <Typography className={classes.headNameValue1}>
                {attributeItem?.value}
              </Typography>
            ) : attributeItem?.type === "enum" ? (
              <Typography className={classes.headNameValue1}>
                <Truncate
                  lines={1}
                  style={{ fontWeight: "400", fontSize: "16px" }}
                  ellipsis={<span>...</span>}
                >
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      // color: "#06BDFF",
                    }}
                    // className={classes.headNameDescAttr}
                  >
                    {attributeItem?.value?.replaceAll("_", " ")}
                  </Typography>
                </Truncate>
              </Typography>
            ) : (attributeItem?.type === "string" ||
                attributeItem?.type === "String" ||
                attributeItem?.type === "integer") &&
              attributeItem?.name !== "languageCode" ? (
              <Typography className={classes.headNameValue1}>
                <Truncate
                  lines={1}
                  style={{ fontWeight: "400", fontSize: "16px" }}
                  ellipsis={<span>...</span>}
                >
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      // color: "#06BDFF",
                    }}
                    // className={classes.headNameDescAttr}
                  >
                    {attributeItem?.value}
                  </Typography>
                </Truncate>
              </Typography>
            ) : (attributeItem?.type === "string" ||
                attributeItem?.type === "String") &&
              attributeItem?.name === "languageCode" ? (
              <Typography className={classes.headNameValue1}>
                <Truncate
                  lines={1}
                  style={{ fontWeight: "400", fontSize: "16px" }}
                  ellipsis={<span>...</span>}
                >
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      // color: "#06BDFF",
                    }}
                    // className={classes.headNameDescAttr}
                  >
                    {attributeItem?.value?.toString()}
                  </Typography>
                </Truncate>
              </Typography>
            ) : attributeItem?.type === "time" ? (
              <Typography className={classes.headNameValue1}>
                {attributeItem?.value && attributeItem?.value?.hours !== null
                  ? parseInt(attributeItem?.value?.hours) >= 10
                    ? attributeItem?.value?.hours
                    : attributeItem?.value?.hours
                  : "00"}
                :
                {attributeItem?.value && attributeItem?.value?.minutes !== null
                  ? parseInt(attributeItem?.value?.minutes) >= 10
                    ? attributeItem?.value?.minutes
                    : attributeItem?.value?.minutes
                  : "00"}{" "}
              </Typography>
            ) : null}
          </Grid>
        </>
      )}
    </>
  );
};
export default AttributesItem;
