import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import NotFound from "../../../../../../components/NotFound/NotFound";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Paymenttags from "./Components/paymentTags";
import data from "./data.json";
import EditModal from "../../Modal";
import AttributesItem from "./Components/attributesItem";
import LodgingAttributesItem from "./Components/attributesItemLodging";
import CardHeader from "../CardHeader";

import { api } from "../../../../../../contexts/JWTContext";
import { useStyles } from "../../../Styles/style";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
function Companyattributes({
  location,
  getLocationDetails,
  locationDetailsData,
  allAttributes,
  locationCategory,
  setLocationDetailsData,
  getLocationDetailsListing,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const initialItemsToShow = 15;
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [attributeItemArr, setAttributeItemArr] = useState([]);
  const [lodgingAttributes, setLodgingAttributes] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);

  const handleViewMore = () => {
    setItemsToShow((prevItemsToShow) =>
      prevItemsToShow === lodgingAttributes.length
        ? initialItemsToShow
        : lodgingAttributes.length
    );
  };

  const visibleItems = lodgingAttributes.slice(0, itemsToShow);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
    getLocationDetails(location);
  };
  const hotelCategoryArr = [
    "categories/gcid:hotel",
    "categories/gcid:extended_stay_hotel",
    "categories/gcid:resort_hotel",
    "categories/gcid:capsule_hotel",
    "categories/gcid:love_hotel",
    "categories/gcid:hostel",
    "categories/gcid:motel",
    "categories/gcid:resort_hotel",
    "categories/gcid:lodge",
    "categories/gcid:camping_cabin",
    "categories/gcid:inn",
    "categories/gcid:guest_house",
    "categories/gcid:farm",
    "categories/gcid:religious_lodging",
    "categories/gcid:apartment_building",
    "categories/gcid:apartment_complex",
    "categories/gcid:apartment_rental_agency",
    "categories/gcid:chalet",
    "categories/gcid:cottage",
    "categories/gcid:villa",
    "categories/gcid:campground",
  ];
  useEffect(() => {
    if (locationDetailsData?.result?.google?.attributes?.length > 0) {
      const filteredAttributes =
        locationDetailsData?.result?.google?.attributes?.filter((item) =>
          allAttributes?.map(
            (data) => data?.parent === item?.name /* &&
                          data?.status === "active" */
          )
        );
    }
  }, [locationDetailsData]);

  useEffect(() => {
    if (locationDetailsData !== null && locationDetailsData !== undefined) {
      if (
        lodgingCategories?.length > 0 &&
        lodgingCategories?.includes(
          locationDetailsData?.result?.google?.categories?.primaryCategory?.name
        ) &&
        location?.isLodgingEnabled === true
      ) {
        let lodgingArr = [];
        if (
          locationDetailsData?.result?.google?.lodging !== undefined &&
          locationDetailsData?.result?.google?.lodging !== null
        ) {
          Object.entries(locationDetailsData?.result?.google?.lodging)?.map(
            ([keyOne, valueOne]) => {
              if (
                keyOne !== "updateMask" &&
                keyOne !== "name" &&
                keyOne !== "metadata"
              ) {
                Object.entries(valueOne)?.map(([keyTwo, valueTwo]) => {
                  if (valueTwo?.hasOwnProperty("type")) {
                    if (valueTwo?.value !== null) {
                      let objOne = {
                        name: keyTwo,
                        type: valueTwo?.type,
                        value: valueTwo?.value,
                      };
                      lodgingArr.push(objOne);
                    }
                  } else if (valueTwo?.hasOwnProperty("hours")) {
                    if (
                      valueTwo?.hours?.value !== null &&
                      valueTwo?.minutes?.value !== null
                    ) {
                      let timeObj = {
                        name: keyTwo,
                        type: "time",
                        value: {
                          hours: valueTwo?.hours?.value,
                          minutes: valueTwo?.minutes?.value,
                        },
                      };
                      lodgingArr.push(timeObj);
                    }
                  } else if (Array.isArray(valueTwo)) {
                    if (keyTwo === "codes") {
                      let index = 0;
                      if (valueTwo[0]?.value !== null) {
                        let obj = {
                          name: keyTwo,
                          type: valueTwo[0]?.type,
                          value: valueTwo[0]?.value,
                        };
                        lodgingArr.push(obj);
                      }
                    } else if (keyTwo === "languagesSpoken") {
                      let obj = {
                        name: keyTwo,
                        type: "string",
                        value: valueTwo,
                      };
                      lodgingArr.push(obj);
                    } else {
                      valueTwo?.map((dataItem) => {
                        Object.entries(dataItem)?.map(
                          ([keyThree, valueThree]) => {
                            if (valueThree?.hasOwnProperty("type")) {
                              if (valueThree?.value !== null) {
                                let obj = {
                                  name: keyThree,
                                  type: valueThree?.type,
                                  value: valueThree?.value,
                                };
                                lodgingArr.push(obj);
                              }
                            }
                          }
                        );
                      });
                    }
                  } else {
                    valueTwo &&
                      Object.keys(valueTwo)?.length > 0 &&
                      Object.keys(valueTwo) !== null &&
                      Object.entries(valueTwo)?.map(
                        ([keyThree, valueThree]) => {
                          if (
                            valueThree?.hasOwnProperty("type") ||
                            valueThree?.hasOwnProperty("hours")
                          ) {
                            if (valueThree?.value !== null) {
                              let objThree = {
                                name: keyThree,
                                type: valueThree?.type,
                                value: valueThree?.value,
                              };
                              lodgingArr.push(objThree);
                            }
                          } else {
                            if (Array.isArray(valueThree)) {
                              if (keyThree === "ecoCertifications") {
                                valueThree?.map((dataItem) => {
                                  Object.entries(dataItem)?.map(
                                    ([keyFour, valueFour]) => {
                                      if (valueFour?.hasOwnProperty("type")) {
                                        if (valueFour?.value !== null) {
                                          let obj = {
                                            name: keyFour,
                                            type: valueFour?.type,
                                            value: valueFour?.value,
                                          };
                                          lodgingArr.push(obj);
                                        }
                                      }
                                    }
                                  );
                                });
                              }
                            } else {
                              valueThree &&
                                Object.entries(valueThree)?.map(
                                  ([keyFour, valueFour]) => {
                                    if (valueFour?.hasOwnProperty("type")) {
                                      // let index = 0;
                                      if (valueFour?.value !== null) {
                                        let obj = {
                                          name: keyFour,
                                          type: valueFour?.type,
                                          value: valueFour?.value,
                                        };
                                        lodgingArr.push(obj);
                                      }
                                    } else {
                                      if (Array.isArray(valueFour)) {
                                        let index = 0;
                                        valueFour?.map((itemFour) => {
                                          Object.entries(itemFour).map(
                                            ([keyFive, valueFive]) => {
                                              if (valueFive?.value !== null) {
                                                let obj = {
                                                  name: keyFive,
                                                  type: valueFive?.type,
                                                  value: valueFive?.value,
                                                };
                                                lodgingArr.push(obj);
                                              }
                                            }
                                          );
                                        });
                                      } else {
                                        valueFour &&
                                          Object.entries(valueFour)?.map(
                                            ([keyFive, valueFive]) => {
                                              if (
                                                valueFive?.hasOwnProperty(
                                                  "type"
                                                )
                                              ) {
                                                if (valueFive?.value !== null) {
                                                  let obj = {
                                                    name: keyFive,
                                                    type: valueFive?.type,
                                                    value: valueFive?.value,
                                                  };
                                                  lodgingArr.push(obj);
                                                }
                                              } else {
                                                valueFive &&
                                                  Object.entries(
                                                    valueFive
                                                  )?.map(
                                                    ([keySix, valueSix]) => {
                                                      if (
                                                        valueSix?.value !== null
                                                      ) {
                                                        let obj = {
                                                          name: keySix,
                                                          type: valueSix?.type,
                                                          value:
                                                            valueSix?.value,
                                                        };
                                                        lodgingArr.push(obj);
                                                      }
                                                    }
                                                  );
                                              }
                                            }
                                          );
                                      }
                                    }
                                  }
                                );
                            }
                          }
                        }
                      );
                  }
                });
              }
            }
          );
        }

        setLodgingAttributes(lodgingArr);
      }
    }
  }, [locationDetailsData]);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {openDefaultModel && (
        <EditModal
          open={openDefaultModel}
          modalIndex={9}
          locationCategory={locationCategory}
          onCancel={handleCloseTemplateMenu}
          getLocationDetails={getLocationDetails}
          location={location}
          locationDetailsData={locationDetailsData}
          allAttributes={allAttributes}
          setLocationDetailsData={setLocationDetailsData}
          getLocationDetailsListing={getLocationDetailsListing}
          lodgingCategories={lodgingCategories}
          categoryLoading={categoryLoading}
          centerData={centerData}
          displayMapError={displayMapError}
        />
      )}
      <Card variant="outlined" className={classes.listingCard}>
        <>
          {locationDetailsData !== null ? (
            <>
              <Grid container spacing={1}>
                <CardHeader
                  title={t("Attributes")}
                  editPermission={editPermission}
                  viewPermission={viewPermission}
                  handleOpenTemplateMenu={handleOpenTemplateMenu}
                />
              </Grid>
              <Box
                sx={{
                  height: lodgingAttributes.length >= 15 ? "883px" : "100%",
                  overflowY:
                    lodgingAttributes.length > initialItemsToShow
                      ? "auto"
                      : "auto",
                }}
              >
                {lodgingCategories?.length > 0 &&
                lodgingCategories?.includes(
                  locationDetailsData?.result?.google?.categories
                    ?.primaryCategory?.name
                ) &&
                location?.isLodgingEnabled === true
                  ? lodgingAttributes?.length > 0 &&
                    visibleItems?.map((item) => {
                      return (
                        <>
                          <Grid
                            container
                            sx={{
                              padding: "16px",
                            }}
                          >
                            <LodgingAttributesItem
                              attributeItem={item}
                              locationDetailsData={locationDetailsData}
                            />
                          </Grid>
                          <Divider />
                        </>
                      );
                    })
                  : locationDetailsData?.result?.google?.attributes?.length >
                      0 &&
                    locationDetailsData?.result?.google?.attributes?.map(
                      (item, index) => {
                        const result = allAttributes?.find(
                          (data) => data?.parent === item?.name /* &&
                          data?.status === "active" */
                        );

                        return (
                          <>
                            {result !== undefined && (
                              <>
                                <Grid
                                  container
                                  sx={{
                                    padding: "16px",
                                  }}
                                >
                                  <AttributesItem
                                    result={result}
                                    index={index}
                                    attributeItem={item}
                                    allAttributes={allAttributes}
                                    locationDetailsData={locationDetailsData}
                                  />
                                </Grid>
                                <Divider />
                              </>
                            )}
                          </>
                        );
                      }
                    )}
              </Box>
            </>
          ) : (
            <NotFound text={t("No_Data_Found")} />
          )}
        </>

        {lodgingAttributes.length > initialItemsToShow && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ alignItems: "start", padding: "12px" }}
          >
            <Button onClick={handleViewMore}>
              <Typography className={classes.viewMoreButton}>
                {itemsToShow === initialItemsToShow
                  ? t("View_More")
                  : t("View_Less")}
              </Typography>
            </Button>
          </Grid>
        )}
      </Card>
    </Grid>
  );
}
export default Companyattributes;
