import React, { useState, useEffect } from "react";
import { api } from "../../contexts/JWTContext";

import moment from "moment";
import Grid from "@mui/material/Grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Typography,
  TextField,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
  IconButton,
  tooltipClasses,
  MenuItem,
  Select,
  Autocomplete,
  Menu,
  Alert,
  Modal,
  Divider,
} from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { Cancel } from "@mui/icons-material";
import GoogleLogo from "../../assets/icon-google.svg";
import { toast } from "react-toastify";
import StarRatings from "react-star-ratings";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import { Edit } from "@mui/icons-material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

import CommonButton from "../CustomComponents/Buttons/CommonButton";
import CancelButton from "../CustomComponents/Buttons/CancelButton";
import Editors from "../CustomComponents/Editor/Editors";
import useDateFormat from "../../hooks/useDateFormat";

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const personalizedKeywords = ["reviewer", "starRating", "location"];
const personalizedKeywordsHandler = (text, reviewer, location, starRating) => {
  for (let index = 0; index < personalizedKeywords.length; index++) {
    if (personalizedKeywords[index] === "reviewer") {
      text = text.replace(/{{reviewer}}/g, reviewer);
    } else if (personalizedKeywords[index] === "starRating") {
      text = text.replace(/{{starRating}}/g, starRating);
    } else if (personalizedKeywords[index] === "location") {
      text = text.replace(/{{location}}/g, location);
    }
  }
  return text;
  // if (textData.includes("{{reviewer}}") && textData.includes("{{location}}")) {
  //   (textData = textData.replace("{{reviewer}}", reviewer)),
  //     (textData = textData.replace("{{location}}", location));
  // } else
  // if (textData.includes("{{reviewer}}")) {
  //   textData = textData.replace("{{reviewer}}", reviewer);
  //   return textData;
  // }

  // if (textData.includes("{{location}}")) {
  //   textData = textData.replace("{{location}}", location);
  //   return textData;
  // }
  // return textData;

  // for (let index = 0; index < personalizedKeywords.length; index++) {
  //   if (personalizedKeywords[index] === "reviewer") {
  //
  //     text = textData.replace(/{{reviewer}}/g, reviewer);
  //   }
  //   // else if (personalizedKeywords[index] === "starRating") {
  //   //
  //   //   text = textData.replace(/{{starRating}}/g, starRating);
  //   // } else if (personalizedKeywords[index] === "location") {

  //   //   text = textData.replace(/{{location}}/g, location?.internalName);
  //   // }
  // }

  // return text;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // maxHeight: "87vh",
  maxWidth: "500px",
  height: "650px",
  // width: "500px",
  // height: "auto",
  // width: "auto",
  bgcolor: "background.paper",
  border: "2px solid gray",
  // overflow:"scroll",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

function GoogleReviews({ item, location, fromEditTask }) {
  const [displayGooglePic, setDisplayGooglePic] = useState(false);
  const { formatDate } = useDateFormat();

  // Post Comment API Call============================================== //
  return (
    <>
      <Box
        sx={{
          padding: fromEditTask === "true" ? "16px" : "24px",
          background: "#F6F6F7",
          borderRadius: "8px",
          minHeight: "150px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2.5} sm={1.5} md={1.5} lg={1.5}>
            {item?.profilePicture && !displayGooglePic ? (
              <img
                alt=""
                style={{
                  maxWidth: 60,
                  maxHeight: 60,
                  minWidth: 40,
                  maxWidth: 40,
                }}
                src={item?.profilePicture}
                alt="photo"
                onError={() => {
                  setDisplayGooglePic(true);
                }}
              />
            ) : (
              <img
                alt=""
                style={{ maxWidth: 60, maxHeight: 60 }}
                src={GoogleLogo}
              />
            )}
          </Grid>

          <Grid item xs={5.5} sm={4.5} md={6.5} lg={6.5}>
            <StarRatings
              rating={item?.ratingValue}
              starDimension={fromEditTask === "true" ? "16px" : "20px"}
              starRatedColor="gold"
              numberOfStars={5}
              starSpacing="2px"
              name="rating"
            />
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                color: "#1B2430",
                lineHeight: "24px",
              }}
            >
              {item?.reviewerTitle}
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#495059",
              }}
            >
              {formatDate(item?.createdAt)}
            </Typography>
          </Grid>

          <Grid
            item
            xs={4}
            md={6}
            sm={4}
            lg={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <FmdGoodIcon sx={{ color: "#a8a8a8", fontSize: "14px" }} />{" "}
            <CustomWidthTooltip
              title={` ${location?.internalName},${location?.addressLine1},${location?.city}`}
            >
              <Typography sx={{ color: "#a8a8a8", ml: 0.5, mr: 1.5 }}>
                {location?.internalName},{location?.addressLine1} ,
                {location?.city}
              </Typography>
            </CustomWidthTooltip>
          </Grid>
          {/* </Box> */}
        </Grid>

        {/* {item?.comment ? ( */}
        <Grid container spacing={2}>
          <Grid md={1.5}></Grid>
          {item?.ratingText && (
            <>
              <Grid item md={9.5}>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#495059",
                    lineHeight: "24px",
                  }}
                >
                  {item?.ratingText}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        {/* ) : ( */}
        {/* <Typography variant="h7" sx={{ color: "#a8a8a8" }}>
          The user didn't write a review and just left with the rating
        </Typography> */}
        {/* )} */}

        {/* {item?.reviewReply?.comment || toggleInput ? ( */}

        {/* ) : (
          <></>
        )} */}
      </Box>
    </>
  );
}

export default GoogleReviews;
