import React from "react";
import Box from "@mui/material/Box";
import { LinearProgress } from "@mui/material";
import { Typography } from "@mui/material";

const LinearProgressBar = ({
  value,
  startPercentage,
  endPercentage,
  color,
}) => {
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress variant="determinate" value={value} color={color} />
        {startPercentage ||
          (endPercentage && (
            <Box>
              <Typography variant="body2">{startPercentage}</Typography>
              <Typography variant="body2">{endPercentage}</Typography>
            </Box>
          ))}
      </Box>
    </div>
  );
};

export default LinearProgressBar;
