import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";

const color = {
  pink: "#f68897",
  lightyellow: "#fdf2df",
  orange: "orange",
  gray: "#757575",
};
export const useStyles = makeStyles((theme) => ({
  whiteBackground: {
    background: "white",
    width: "100%",
  },
  styleMainModelContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "550px",
    width: "800px",
    background: "#FFFF",
    border: "2px solid gray",
    borderRadius: "8px",
    // overflow: "auto",
    boxShadow: 24,
    padding: "16px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingTop: "10px",
    height: "430px",
    overflowY: "auto",
  },
  mainContainerHeading: {
    fontWeight: 700,
    fontSize: "20px",
  },
  fieldLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    marginLeft: "10px",
    marginTop: "6px",
  },
}));
