import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
} from "@mui/material";
import Deletes from "./Deletes.css";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const Delete = ({
  title,
  description,
  onConfirm,
  onClose,
  loading,
  onCancel,
  indexValue,
  setSelectedTab,
  cancelState,
  touchedValue,
  setTouchedValue,
  getLocationDetails,
  location,
}) => {
  const [open, setOpen] = useState(false);
  const [deletePost, setDeletePost] = useState(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Box className="box_delete">
          <DialogContentText
            id="responsive-dialog-title"
            sx={{}}
            className="delete_dialog_content1"
          >
            {t("Save_Changes")}
          </DialogContentText>
          <DialogContentText
            id="responsive-dialog-title"
            className="delete_dialog_content2"
          >
            {t("save_changes_message")}
          </DialogContentText>

          <DialogActions>
            <Button
              autoFocus
              onClick={() => onCancel()}
              className="delete_button"
            >
              {t("Cancel")}
            </Button>

            <Button onClick={onConfirm} className="delete_button" autoFocus>
              {loading ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                t("Confirm")
              )}
            </Button>
          </DialogActions>
        </Box>
      </div>
    </>
  );
};

export default Delete;
