import React from "react";
import { Chip as MuiChip } from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

const Chip = styled(MuiChip)(spacing);

const Status = ({ task }) => {
  const color =
    task?.status === "open"
      ? "error"
      : task?.status === "completed"
      ? "success"
      : task?.status === "inprogress"
      ? "warning"
      : "";
  return (
    <>
      {task && color && (
        <Chip
          label={task?.status}
          color={color}
          // onClick={handleClick}
          m={1}
        />
      )}
    </>
  );
};

export default Status;
