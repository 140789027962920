import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  TextField as MuiTextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  createFilterOptions,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components/macro";
import { useStyles } from "../styles/style";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import { api } from "../../../../contexts/JWTContext";
import Editors from "../../../../components/CustomComponents/Editor/Editors";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Loader from "../../../../components/Loaders/Loader";
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);

const AddTask = ({ alignment, onCancel, task }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [isLoading, setIsLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [locationId, setLocationId] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("open");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [statusRequired, setStatusRequired] = useState(false);
  const [titleRequired, setTitleRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  const [userRequired, setUserRequired] = useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState("7");
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [selectAll, setSelectAll] = useState(false);
  const [taskLoading, setTaskLoading] = useState(false);
  const filter = createFilterOptions();

  useEffect(async () => {
    getAllCompanies();
    getAllUsers();
  }, []);

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    setStatusRequired(false);
  };
  const handleLocationChange = (option) => {
    setLocationId(option);
  };
  const handleUserChange = (option) => {
    setSelectedUser(option);
  };

  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/company/` + companyData?.id);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
      }
    } catch (error) {}
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `/companyUserRoles?pageNumber=1&limit=5000&companyId=${companyData?.id}`
      );
      if (res.status === 200) {
        const activeUsers = res?.data?.data?.rows?.filter(
          (user) =>
            user?.User?.inActive === false &&
            (user?.User?.status === "active" ||
              user?.User?.status === "pending")
        );

        setUsers(activeUsers);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  console.log(users, "resss");

  const createTask = async () => {
    if (title === undefined) {
      setTitleRequired(true);
    }

    if (description === "") {
      setDescriptionRequired(true);
    }

    if (locationId === undefined) {
      setLocationRequired(true);
    }
    if (selectedUser?.length < 1) {
      setUserRequired(true);
    }
    let userIds = [];
    selectedUser?.map((item) => {
      userIds.push(item?.User?.id);
    });

    if (
      description !== "" &&
      title !== undefined &&
      locationId !== undefined &&
      selectedUser.length > 0
    ) {
      try {
        setTaskLoading(true);
        const res = await api.post(`/tasks`, {
          title: title,
          description: description,
          companyId: companyData?.id,
          locationId: locationId?.id,
          status: status,
          dueDate: selectedDueDate,
          userIds: userIds?.length > 0 ? userIds.toString() : "",
          // getStatus: task.status,
        });
        if (res.status === 201) {
          setTaskLoading(false);
          toast.success("Task Added Successfully", {
            onClose: () => {
              onCancel(true);
            },
          });
        }
      } catch (error) {
        setTaskLoading(false);

        toast.error(t("Error"));
      }
    }
  };

  return (
    <>
      <Box className={classes.addTaskMainBox}>
        <Typography className={classes.title}>{t("AddTask")}</Typography>
      </Box>
      <Divider my={4} />

      <Box className={classes.addTaskField}>
        <Box sx={{ overflowY: "auto", height: "75vh" }}>
          {taskLoading ? (
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "55vh",
              }}
            >
              <Loader />
            </Grid>
          ) : (
            <>
              <Box>
                <Typography className={classes.addTaskTypo}>
                  {t("Task_Tile")}
                </Typography>
                <TextField
                  error={titleRequired}
                  value={title}
                  placeholder={t("Enter_Title")}
                  onChange={(e) => {
                    setTitleRequired(false);
                    setTitle(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  InputProps={{
                    inputProps: {
                      style: {
                        marginLeft: "10px",
                        marginRight: "10px",
                        width: "100%",
                        padding: "16px 0px",
                      },
                    },
                  }}
                  helperText={titleRequired && t("Field_req_Title")}
                  // margin="normal"
                  // label={t("TitleForm")}
                />
              </Box>

              <Box>
                <Typography className={classes.addTaskTypo}>
                  {t("Status")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    error={statusRequired}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    defaultValue={status}
                    // label={"Title"}

                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"open"}>{t("Open")}</MenuItem>
                    <MenuItem value={"inprogress"}>{t("InProgress")}</MenuItem>
                    <MenuItem value={"completed"}>{t("Completed")}</MenuItem>
                  </Select>
                  {statusRequired && (
                    <Typography sx={{ color: "#d32f2f" }}>
                      {t("Field_req_status")}
                    </Typography>
                  )}
                </FormControl>
              </Box>

              <Typography className={classes.addTaskTypo}>
                {t("Select_Locations")}
              </Typography>

              <Autocomplete
                error={locationRequired}
                disablePortal
                id="combo-box-demo"
                options={
                  allCompanies?.locations?.length > 0
                    ? allCompanies?.locations
                    : []
                }
                getOptionLabel={(option) =>
                  option?.internalName
                    ? option?.internalName +
                      " " +
                      "(" +
                      (option?.addressLine1 !== "" &&
                      option?.addressLine1 !== null &&
                      option?.addressLine1 !== undefined
                        ? option?.addressLine1 + "," + " "
                        : "") +
                      (option?.city ? option?.city : "") +
                      ")"
                    : ""
                }
                sx={{ width: "100%" }}
                value={locationId}
                onChange={(e, value) => {
                  handleLocationChange(value);
                  setLocationRequired(false);
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("Select_Location")}
                    {...params}
                    required
                    error={locationRequired}
                    name="language"
                    helperText={locationRequired && t("Field_req_loc")}
                  />
                )}
              ></Autocomplete>

              <Typography className={classes.addTaskTypo}>
                {t("Select_User")}
              </Typography>
              {users?.length > 0 && (
                <Autocomplete
                  error={userRequired}
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={users?.length > 0 ? users : []}
                  getOptionLabel={(option) =>
                    option?.User?.firstName !== null &&
                    option?.User?.lastName !== null
                      ? option?.User?.firstName.charAt(0).toUpperCase() +
                        option?.User?.firstName.slice(1) +
                        " " +
                        (option?.User?.lastName !== null &&
                        option?.User?.lastName !== ""
                          ? option?.User?.lastName.charAt(0).toUpperCase() +
                            option?.User?.lastName.slice(1) +
                            " " +
                            "(" +
                            option?.User?.email +
                            ")"
                          : "")
                      : option?.User?.email
                  }
                  sx={{ width: "100%" }}
                  value={selectedUser}
                  onChange={(event, value) => {
                    if (value.find((option) => option.id === 0)) {
                      if (users?.length === selectedUser?.length) {
                        handleUserChange([]);
                        setSelectAll(false);
                      } else {
                        setSelectAll(true);
                        handleUserChange(users);
                        setUserRequired(false);
                      }
                    } else {
                      handleUserChange(value);
                      if (value?.length === users?.length) {
                        setSelectAll(true);
                      } else {
                        setSelectAll(false);
                      }
                    }
                  }}
                  renderOption={(props, option, { selected }) => {
                    if (option.id === 0) {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selectAll}
                          />
                          {option?.firstName}
                        </li>
                      );
                    } else {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected || selectAll}
                          />
                          {option?.User?.firstName !== null &&
                          option?.User?.lastName !== null
                            ? option?.User?.firstName.charAt(0).toUpperCase() +
                              option?.User?.firstName.slice(1) +
                              " " +
                              (option?.User?.lastName !== null &&
                              option?.User?.lastName !== ""
                                ? option?.User?.lastName
                                    .charAt(0)
                                    .toUpperCase() +
                                  option?.User?.lastName.slice(1) +
                                  " " +
                                  "(" +
                                  option?.User?.email +
                                  ")"
                                : "")
                            : option?.User?.email}
                        </li>
                      );
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (users?.length > 0) {
                      return [
                        { id: 0, firstName: t("Select_All") },
                        ...filtered,
                      ];
                    } else {
                      return [];
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_User")}
                      {...params}
                      required
                      error={userRequired}
                      name="language"
                      helperText={userRequired && t("Field_req_user")}
                    />
                  )}
                ></Autocomplete>
              )}

              <Grid container>
                <Typography className={classes.addTaskTypo}>
                  {t("Duee_Date")}
                </Typography>
                <Select
                  required
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDueDate}
                  onChange={(e, value) => {
                    setSelectedDueDate(e.target.value);
                  }}
                >
                  <MenuItem value={"3"}>{t("3-Days")}</MenuItem>
                  <MenuItem value={"7"}>{t("7-Days")}</MenuItem>
                </Select>

                <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
              </Grid>
              <Grid container>
                <Typography className={classes.descText}>
                  {t("Description")}
                </Typography>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Editors
                    error={descriptionRequired}
                    value={description}
                    onChange={(e) => {
                      setDescription(e);
                      setDescriptionRequired(false);
                    }}
                  />
                  {descriptionRequired && (
                    <Typography sx={{ color: "#d32f2f", marginLeft: "8px" }}>
                      {t("Field_req_dec")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Box>

        <Divider />
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-end"
        >
          <Box className={classes.editTaskBox}>
            <CommonButton
              displayWhite="true"
              onSubmit={onCancel}
              label={t("Cancel")}
            />

            <CommonButton
              onSubmit={() => {
                createTask();
              }}
              variant="contained"
              // loading={isLoading || taskLoading}
              label={t("Add_Task")}
              disabled={isLoading || taskLoading}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default AddTask;
