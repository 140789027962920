import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../../../components/Loaders/Loader";
import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TitleHeader from "../../../../components/CustomComponents/title";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
function Index() {
  let { id } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const [locationURL, setLocationURL] = useState("");
  const [isLaoding, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.up("sm"));
  const { signOut } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    getSoConnectLocationURL();
  });
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const getSoConnectLocationURL = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/location/jwt?locationId=${id}`);
      if (res.status === 200) {
        setLoading(false);

        setLocationURL(res.data.data.url);
      }
    } catch (error) {
      setLoading(true);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  return (
    <React.Fragment>
      <Helmet title="View Location" />
      <TitleHeader title={t("View_Location")} showButton={true} />
      <Grid container justifyContent="center">
        {isLaoding ? (
          <Loader />
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <iframe
              title="iframe"
              loading="lazy"
              src={locationURL}
              width={"100%"}
              height={isMobile ? "800" : isMedium ? "850" : "850"}
              onLoad={() => {}}
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default Index;
