import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  Checkbox,
  DialogContent,
  Dialog,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import { alpha } from "@material-ui/core/styles";
import FetchModels from "./FetchModels.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Loader from "../../Loaders/Loader";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  modalCompanyTitle: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "700",
    color: "rgba(0,0,0,0.87)",
  },
  modalCircularProgress: {
    width: "25px",
    height: "25px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const FetchModel = ({
  title,
  open,
  handleCloseNewRequestModel,

  requestLoading,
  allCompanies,
  handleChangeCompany,
  companyId,
  setLocationId,
  handleLocationChange,
  locationId,
  allLocation,
  handleRequestForEmotionAI,
  locationLoading,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [deletePost, setDeletePost] = useState(null);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectAll, setSelectAll] = useState(false);
  const filter = createFilterOptions();
  return (
    <>
      <div>
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", height: "400px" } }}
          open={open}
          onClose={handleCloseNewRequestModel}
        >
          <DialogTitle
            sx={{
              lineHeight: "28px",
              fontWeight: 700,
              fontSize: "20px",
              color: "#1b2430",
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent dividers sx={{ height: "230px", overflowY: "scroll" }}>
            <Grid container>
              <Grid item sm={12} md={12} lg={12} xs={12}>
                <Typography className={classes.modalCompanyTitle}>
                  {t("Select_Company")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={locationLoading === true ? 11 : 12}
                md={locationLoading === true ? 11 : 12}
                sm={locationLoading === true ? 11 : 12}
                lg={locationLoading === true ? 11 : 12}
              >
                <Autocomplete
                  // multiple
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allCompanies?.results?.length > 0
                      ? allCompanies?.results
                      : []
                  }
                  getOptionLabel={(option) =>
                    option?.name ? option.name + "(" + option?.city + ")" : ""
                  }
                  className="Autocomplete-field"
                  value={companyId ? companyId : []}
                  onChange={(e, value) => {
                    handleChangeCompany(value);
                    setSelectAll(false);
                  }}
                  /*  renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {`${option.name} (${option.city})`}
              </li>
            )} */
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_Company")}
                      {...params}
                      required
                      name="company"
                    />
                  )}
                ></Autocomplete>
              </Grid>
              {locationLoading && (
                <Grid item xs={1} md={1} sm={1} lg={1}>
                  <CircularProgress
                    style={{
                      width: "25px",
                      height: "25px",
                      color: "#06BDFF",
                      marginTop: "8px",
                      marginLeft: "8px",
                    }}
                  />
                </Grid>
              )}
              <Grid item sm={12} md={12} lg={12}>
                <Typography className={classes.modalCompanyTitle}>
                  {t("Select_Location")}
                </Typography>
                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  getOptionDisabled={(option) => {
                    if (
                      option?.reviewFoundStatus === false &&
                      option?.statusOfSentimentalAnalysis === "pending"
                    ) {
                      return true;
                    }
                    if (option?.statusOfSentimentalAnalysis === "requested") {
                      return true;
                    }
                    if (option?.statusOfSentimentalAnalysis === "done") {
                      return true;
                    }
                    if (option?.statusOfSentimentalAnalysis === "inProgress") {
                      return true;
                    }
                    return false;
                  }}
                  disableCloseOnSelect
                  options={allLocation?.length > 0 ? allLocation : []}
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        "(" +
                        (option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? option?.addressLine1 + "," + " "
                          : "") +
                        (option?.city ? option?.city : "") +
                        ")"
                      : ""
                  }
                  className="Autocomplete-field"
                  value={locationId}
                  onChange={(event, value) => {
                    // Check if 'All' option is clicked
                    const filterLocations = allLocation?.filter(
                      (item) =>
                        item?.reviewFoundStatus !== false &&
                        item?.statusOfSentimentalAnalysis !== "done" &&
                        item?.statusOfSentimentalAnalysis !== "requested" &&
                        item?.statusOfSentimentalAnalysis !== "inProgress"
                    );
                    if (value.find((option) => option.id === 0)) {
                      // Check if all options are selected

                      if (filterLocations?.length === locationId?.length) {
                        setLocationId([]);
                        setSelectAll(false);
                      } else {
                        setSelectAll(true);
                        setLocationId(filterLocations);
                      }
                    } else {
                      setLocationId(value);
                      if (value?.length === filterLocations?.length) {
                        setSelectAll(true);
                      } else {
                        setSelectAll(false);
                      }
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (allLocation?.length > 0) {
                      return [
                        { id: 0, internalName: "Select All" },
                        ...filtered,
                      ];
                    } else {
                      return [];
                    }
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        disabled={
                          (option?.reviewFoundStatus === false &&
                            option?.statusOfSentimentalAnalysis ===
                              "pending") ||
                          option?.statusOfSentimentalAnalysis === "requested" ||
                          option?.statusOfSentimentalAnalysis === "done" ||
                          option?.statusOfSentimentalAnalysis === "inProgress"
                        }
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        // checked={selected}
                        id="select-all-checkbox"
                        checked={
                          option?.reviewFoundStatus !== false &&
                          option?.statusOfSentimentalAnalysis !== "requested" &&
                          option?.statusOfSentimentalAnalysis !== "done" &&
                          option?.statusOfSentimentalAnalysis !==
                            "inProgress" &&
                          (selectAll || selected)
                        }
                      />
                      <Grid
                        container
                        style={{ justifyContent: "space-between" }}
                      >
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <span style={{ textAlign: "start" }}>
                            {`${option.internalName} ${
                              option?.addressLine1 !== "" &&
                              option?.addressLine1 !== null &&
                              option?.addressLine1 !== undefined
                                ? "(" + option?.addressLine1 + "," + " "
                                : ""
                            } ${option?.city ? option?.city + ")" : ""}`}
                          </span>
                        </Grid>

                        {option?.statusOfSentimentalAnalysis && (
                          <Grid item={3} sm={3} md={3} lg={3}>
                            <span
                              style={{
                                textAlign: "center",
                                // marginLeft: "8px",
                                // marginRight: "18px",
                                color:
                                  option?.statusOfSentimentalAnalysis === "done"
                                    ? "#13CF8F"
                                    : option?.reviewFoundStatus === false
                                    ? "#FC3652"
                                    : option?.statusOfSentimentalAnalysis ===
                                      "requested"
                                    ? "#F86F35"
                                    : option?.statusOfSentimentalAnalysis ===
                                      "inProgress"
                                    ? "#FC3652"
                                    : "",
                              }}
                            >
                              {option?.statusOfSentimentalAnalysis === "done"
                                ? "• " + `${t("Done")}`
                                : ""}
                              {option?.reviewFoundStatus === false
                                ? "• " + `${t("No_reviews")}`
                                : ""}
                              {option?.statusOfSentimentalAnalysis ==
                              "requested"
                                ? "• " + `${t("Pending")}`
                                : ""}
                              {option?.statusOfSentimentalAnalysis ==
                              "inProgress"
                                ? "• " + `${t("inProgress")}`
                                : ""}
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      //  error={locationRequired}
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleCloseNewRequestModel();
                //setSelectAll(false);
              }}
              className="delete_button"
              sx={{ color: "#1B2430" }}
            >
              {t("Cancel")}
            </Button>

            <Button
              autoFocus
              disabled={locationId?.length === 0 ? true : false}
              onClick={() => handleRequestForEmotionAI()}
              className="delete_button"
            >
              {requestLoading ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: "25px", height: "25px", color: "#06BDFF" }}
                />
              ) : (
                t("Submit")
              )}
            </Button>
          </DialogActions>
        </Dialog>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default FetchModel;
