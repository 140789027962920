import {
  Grid,
  Card,
  Divider,
  Typography,
  Checkbox,
  Box,
  TableBody,
  Table,
  TableContainer,
  TablePagination,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TitleHeader from "../../../../components/CustomComponents/title";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import { api } from "../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import Loader from "../../../../components/Loaders/Loader";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../styles/style";
import GoogleError from "../../../../components/CustomComponents/Errors/Reconnect";
import NotConnected from "../../../../components/CustomComponents/Errors/NotConnected";

function Accounts(props) {
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [accountId, setAccountId] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [accounts, setAccounts] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [refreshToken, setRefreshToken] = useState("");
  const [email, setEmail] = useState("");
  const [displayTryAgainError, setDisplayTryAgainError] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [count, setCount] = useState("");
  const state = location?.search?.includes("state=");

  const getCode = location?.search !== "" && location?.search.split("=");

  const code =
    getCode &&
    (state === false
      ? getCode[1]?.replace("&scope", "")
      : getCode[2]?.replace("&scope", ""));

  const locationId =
    getCode && state === true && getCode[1]?.replace("&code", "");

  const getAllAccounts = async () => {
    if (state === false) {
      try {
        setLoading(true);
        const res = await api.get(
          `googleConnect/auth-code?code=${
            code === false ? "" : code
          }&refreshToken=${
            location?.state !== null
              ? location?.state?.refreshToken
              : count !== ""
              ? refreshToken
              : ""
          }&pageNumber=${page + 1}&limit=${rowsPerPage}${
            count !== "" ? `&count=${count}` : ""
          }`
        );
        if (res?.data?.status === true) {
          setLoading(false);
          let arr = res?.data?.data?.accounts;
          arr.map((item) => {
            accounts.push(item);
          });
          setAccounts([...accounts]);
          setRefreshToken(res?.data?.data?.refreshToken);
          setEmail(res?.data?.data?.email);
          setCount(res?.data?.data?.count);
          setPage(page + 1);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    } else if (state === true && getCode[1]?.includes("reconnect")) {
      try {
        setLoading(true);
        const res = await api.get(
          `googleConnect/auth-code?code=${
            code === false ? "" : code
          }&refreshToken=${
            location?.state !== null
              ? location?.state?.refreshToken
              : count !== ""
              ? refreshToken
              : ""
          }&state=reconnect&pageNumber=${page + 1}&limit=${rowsPerPage}${
            count !== "" ? `&count=${count}` : ""
          }`
        );
        if (res?.data?.status === true) {
          setLoading(false);
          let arr = res?.data?.data?.accounts;
          arr.map((item) => {
            accounts.push(item);
          });
          setAccounts([...accounts]);
          setPage(page + 1);
          setRefreshToken(res?.data?.data?.refreshToken);
          setEmail(res?.data?.data?.email);
          setCount(res?.data?.data?.count);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    } else {
      try {
        setLoading(true);
        const res = await api.get(
          `googleConnect/update-all-locations-refreshtoken?locationId=${locationId}&refreshToken=${
            code === false ? "" : code
          }`
        );
        if (res?.data?.status === true) {
          setLoading(false);
          toast.success(res?.data?.message);
          //  setAccounts(res?.data?.data?.accounts);
          //  setRefreshToken(res?.data?.data?.refreshToken);
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.status === 404) {
          setDisplayTryAgainError(true);
        }
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    getAllAccounts();
  }, []);

  const handleChangeCheckButton = (e, data) => {
    if (e.target.checked) {
      setSelectedData(data);
      const str = data?.name?.split("/");
      setAccountId(str[1]);
    } else {
      setSelectedData(null);
      setAccountId("");
    }
  };
  const handleSubmit = () => {
    if (state === true && getCode[1]?.includes("reconnect")) {
      navigate("/user/google_locations", {
        state: {
          accountId: accountId,
          refreshToken: refreshToken,
          accountName: selectedData?.accountName,
          email: email,
          state: "reconnect",
        },
      });
    } else {
      navigate("/user/google_locations", {
        state: {
          accountId: accountId,
          refreshToken: refreshToken,
          accountName: selectedData?.accountName,
          email: email,
          state: null,
        },
      });
    }
  };
  const handleNavigationToDashboard = () => {
    navigate("/");
  };

  const handleConnectWithGoogle = () => {
    fetchURL();
  };

  async function fetchURL() {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        window.location.href = res?.data.data + "&state=" + locationId;
      }
    } catch (error) {}

    // if (response?.data?.url) {
    //   window.location.href = response.data.url;
    // }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {" "}
      <TitleHeader
        title={
          state === true
            ? t("connection_restored")
            : t("Connect_Google_buisness")
        }
      />
      <Grid container sx={{ justifyContent: "center" }}>
        {state === false ||
        (state === true && getCode[1]?.includes("reconnect")) ? (
          <Card variant="outlined" className={classes.cardContentAccount}>
            <Grid container>
              <Grid xs={12} sm={12} md={12} lg={12} sx={{ padding: "10px" }}>
                <Typography className={classes.headingAccount}>
                  {t("Text_Accounts")}
                </Typography>
              </Grid>
              <Grid container className={classes.accountsContainer}>
                {loading ? (
                  <Loader />
                ) : (
                  <Grid item lg={12} sm={12} md={12} xs={12}>
                    <TableContainer
                      sx={{
                        width: "100%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Table
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                      >
                        {" "}
                        {accounts?.length > 0 ? (
                          accounts.map((item, index) => (
                            <CustomizedTableRow
                              tabIndex={-1}
                              key={`${item.id}-${index}`}
                            >
                              {" "}
                              <CustomizedTableCell sx={{ width: "50px" }}>
                                <Checkbox
                                  checked={
                                    selectedData?.accountName ===
                                    item?.accountName
                                  }
                                  onChange={(e) =>
                                    handleChangeCheckButton(e, item)
                                  }
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                  sx={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                  }}
                                />
                              </CustomizedTableCell>
                              <CustomizedTableCell>
                                {" "}
                                <Typography className={classes.accountName}>
                                  {item?.accountName}
                                </Typography>
                              </CustomizedTableCell>
                            </CustomizedTableRow>
                          ))
                        ) : (
                          <Grid
                            container
                            sx={{
                              padding: "20px",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography className={classes.headingAccount}>
                              {t("No_Account_Found_Text")}
                            </Typography>
                          </Grid>
                        )}
                      </Table>
                    </TableContainer>
                    {accounts?.length > 0 && count !== "" && (
                      /*  <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rowsPerPage + 1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      /> */
                      <Button onClick={getAllAccounts}>
                        <Typography className={classes.viewMoreButton}>
                          {t("View_More")}
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                my={3}
                className={classes.buttonContainerAccount}
              >
                <CommonButton
                  onSubmit={() => navigate("/user/locations")}
                  // type="submit"
                  displayWhite="true"
                  variant="contained"
                  // disabled={isSubmitting}

                  label={t("Cancel")}
                />

                <CommonButton
                  type="submit"
                  disabled={selectedData === null}
                  onSubmit={handleSubmit}
                  label={t("Confirm")}
                />
              </Grid>
            </Grid>
          </Card>
        ) : state === true && displayTryAgainError ? (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Box className={classes.locationbox}>
                <NotConnected
                  text={t("google_reconnect_error_message")}
                  subtext={t("google_reconnect_error_message_subhead")}
                />

                <CommonButton
                  onSubmit={handleConnectWithGoogle}
                  /* icon={
                    <img 
alt=""
                      src={Googleicon}
                      style={{ paddingRight: "8px" }}
                      alt="Google Icon"
                    />
                  } */
                  label={t("try_again_text")}
                />
              </Box>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Box className={classes.locationbox}>
                <GoogleError
                  text={t("success_message_reconnect")}
                  subtext={t("success_message_reconnect_subText")}
                  displayError={false}
                />

                <CommonButton
                  onSubmit={handleNavigationToDashboard}
                  /* icon={
                  <img 
alt=""
                    // src={Googleicon}
                    className={classes.commonButton}
                    alt={"/google Icon"}
                  />
                } */
                  label={t("back_to_dashboard")}
                />
              </Box>
            )}
          </>
        )}
      </Grid>
    </>
  );
}

export default Accounts;
