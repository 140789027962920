import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../Styles/style";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CompanyServices = ({ data, onCancel }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [tagsRequired, setTagsRequired] = useState(false);

  useEffect(() => {
    setTags(data.locations[0].categories.primaryCategory.serviceTypes);
  }, []);
  const handleKeyDown = (e) => {
    let tempObj = {
      displayName: "",
    };
    if (e.key !== "Enter") return;
    tempObj = {
      displayName: e.target.value,
    };
    // const value = e.target.value;
    // setTagsRequired(true);
    if (!tempObj?.displayName.trim()) return;
    setTags([...tags, tempObj]);
    e.target.value = "";

    // setTagsRequired(false);
  };

  const removeTag = (index) => {
    setTags(tags.filter((el, i) => i !== index));
  };
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: "18px" }}>
      <Grid container>
        <Grid item xs={10} sm={11} md={11.5} lg={11.5}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.modalHeader}>
              {t("Services_head")}
            </Typography>
            <BootstrapTooltip title={t("Services_desc")}>
              <Typography
                sx={{
                  marginLeft: "5px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>
            </BootstrapTooltip>
          </Box>
        </Grid>
        <Grid item xs={2} sm={1} md={0.5} lg={0.5}>
          <IconButton autoFocus onClick={onCancel} className="delete_button">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Divider variant="middle" sx={{ paddingY: "8px" }} />
      {data !== null && (
        <Formik
          initialValues={{
            locationKeywords: data.locations[0].categories.primaryCategory
              .serviceTypes.displayName
              ? data.locations[0].categories.primaryCategory.serviceTypes
                  .displayName
              : "",

            submit: false,
          }}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              // setIsLoading(true);
              // const res = await api.patch(
              //     `/location/${getLocationById}`,
              //     {
              //     name: values.locationName,
              //     // city: values.addressOne,
              //     // country: values.addressTwo,
              //     // street: values.postalCode,
              //     // streetnumber: values.city,
              //     // postalcode: values.countryName,
              //     // adrdessline: values.telephone,
              //     // servicearea: values.website,
              //     // primarycategory: values.r,

              //     }
              // );
              if (data.status === 200) {
                // setIsLoading(false);
                // setMessage(res.data.message);
                // getSingleLocation(getLocationById);
                // toast.success("Updated Successfully", {
                // onClose: () => {
                //     navigate(-1);
                // },
                // });
                // setTimeout(() => {
                //   setMessage(navigate("/user/locations"));
                // }, );
              }
            } catch (error) {
              // const message =
              //     error?.response?.data?.message ||
              //     "Something went wrong";
              // setStatus({ success: false });
              // setErrors({ submit: message });
              // setSubmitting(false);
              // toast.error(error?.response?.data?.message);
              // setIsLoading(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form>
              <Grid container sx={{ paddingY: "6px" }}>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  sx={{ marginTop: "12px" }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography className={classes.fieldTextService}>
                      {t("Services")}
                    </Typography>
                    <BootstrapTooltip title={t("services_subText")}>
                      <Typography
                        sx={{
                          marginLeft: "5px",
                          marginTop: "4px",
                          cursor: "pointer",
                        }}
                      >
                        <InfoOutlinedIcon />
                      </Typography>
                    </BootstrapTooltip>
                  </Box>

                  <Grid
                    className={
                      tagsRequired === false
                        ? "tags-input-container"
                        : "tags-input-container-error"
                    }
                  >
                    {tags.map((serviceTypes, index) => (
                      <div className="tag-item" key={index}>
                        <span className="text">
                          {serviceTypes.displayName}
                          <span
                            className="close"
                            onClick={() => removeTag(index)}
                          >
                            {" "}
                            X
                          </span>
                        </span>
                      </div>
                    ))}

                    <input
                      type="text"
                      className="tags-input"
                      placeholder={t("E.g.Vegan,_Restaurant")}
                      //   onKeyPress={this.keyPress}
                      onKeyDown={handleKeyDown}

                      //  defaultValue={productData.tags}
                    />
                  </Grid>
                  {tagsRequired ? (
                    <Typography className={classes.errorText}>
                      {t("Enter_Keywords_Msg")}
                    </Typography>
                  ) : (
                    <Typography className={classes.helpingText}>
                      {t("Enter_Keyword")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container sx={{ paddingY: "6px" }}>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  sx={{ marginTop: "12px" }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography className={classes.fieldTextService}>
                      {t("Brands")}
                    </Typography>
                    <BootstrapTooltip title={t("brands_subText")}>
                      <Typography
                        sx={{
                          marginLeft: "5px",
                          marginTop: "4px",
                          cursor: "pointer",
                        }}
                      >
                        <InfoOutlinedIcon />
                      </Typography>
                    </BootstrapTooltip>
                  </Box>

                  <Grid
                    className={
                      tagsRequired === false
                        ? "tags-input-container"
                        : "tags-input-container-error"
                    }
                  >
                    {tags.map((serviceTypes, index) => (
                      <div className="tag-item" key={index}>
                        <span className="text">
                          {serviceTypes.displayName}
                          <span
                            className="close"
                            onClick={() => removeTag(index)}
                          >
                            {" "}
                            X
                          </span>
                        </span>
                      </div>
                    ))}

                    <input
                      type="text"
                      className="tags-input"
                      placeholder={t("E.g.Vegan,_Restaurant")}
                      //   onKeyPress={this.keyPress}
                      onKeyDown={handleKeyDown}

                      //  defaultValue={productData.tags}
                    />
                  </Grid>
                  {tagsRequired ? (
                    <Typography className={classes.errorText}>
                      {t("Enter_Keywords_Msg")}
                    </Typography>
                  ) : (
                    <Typography className={classes.helpingText}>
                      {t("Enter_Keyword")}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Divider variant="middle" sx={{ marginY: "12px" }} />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "end",
                }}
              >
                <CommonButton
                  displayWhite="true"
                  label={t("Cancel")}
                  onSubmit={onCancel}
                />
                <CommonButton type="submit" label={"Save"} />
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Grid>
  );
};

export default CompanyServices;
