import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Button,
} from "@mui/material";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../../../components/Loaders/Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../styles/style";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  subTitle,
  open,
  handleCloseNewRequestModel,
  totalModalEmailsArr,
  selectedItems,
  setSelectedItems,
  selectAllChecked,
  setSelectAllChecked,
  handleSubmitOk,
  handleCancelEmailsModal,
  isModalEmailsSent,
  setIsModalEmailsSent,
  isLoading,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedItems([...totalModalEmailsArr]);
    // setSelectAllChecked(true);
  }, [open]);

  useEffect(() => {
    if (selectedItems.length === totalModalEmailsArr.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedItems, totalModalEmailsArr]);

  const handleCheckboxChange = (item) => {
    const updatedSelectedItems = [...selectedItems];

    if (updatedSelectedItems.includes(item)) {
      updatedSelectedItems.splice(updatedSelectedItems.indexOf(item), 1);
    } else {
      updatedSelectedItems.push(item);
    }
    setSelectedItems(updatedSelectedItems);
  };

  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      setSelectAllChecked(false);
      setSelectedItems([]);
    } else {
      setSelectAllChecked(true);
      setSelectedItems([...totalModalEmailsArr]);
    }
  };

  return (
    <>
      <div>
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", height: "400px" } }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogTitle>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.fetchModelTitle}>
                {title}
              </Typography>
              <BootstrapTooltip
                title={
                  <h1 style={{ fontSize: "12px" }}>{t("duplicated_text")}</h1>
                }
              >
                <Typography className={classes.fetchModelIcon}>
                  <InfoOutlinedIcon />
                </Typography>
              </BootstrapTooltip>
            </Box>
            <Typography className={classes.fetchModelTypography}>
              {t("fetch_email_info")}
            </Typography>
          </DialogTitle>

          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid container className={classes.modelContainer}>
                <Box className={classes.boxStyle}>
                  <Checkbox
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                  />
                  <Typography>{t("Select_All")}</Typography>
                </Box>
                {totalModalEmailsArr?.map((item, index) => (
                  <Grid item sm={12} md={12} lg={12} key={index}>
                    <Box className={classes.boxStyle}>
                      <Checkbox
                        checked={selectedItems.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                      <Typography>{item}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </DialogContent>

          <Box className={classes.fetchModelButtonContainer}>
            {/*   <Box sx={{ color: "#495059", marginLeft: "4px" }}>
              <Checkbox
                checked={isModalEmailsSent}
                onChange={handleChangeChecked}
              />
              {t("modal_email_sent_msg")}
            </Box> */}
            <Box sx={{ marginRight: "10px" }}>
              <Button
                variant="outlined"
                className={classes.cancelButton}
                onClick={handleCancelEmailsModal}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitOk}
                className={classes.okButton}
              >
                {t("Ok")}
              </Button>
            </Box>
          </Box>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
