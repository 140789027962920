import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import AnchorCard from "../../../components/CustomComponents/Cards/AnchorCard";
import CommonSearch from "../../../components/CustomComponents/Cards/CommonSearch";
import Loader from "../../../components/Loaders/Loader";
import useAuth from "../../../hooks/useAuth";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import { useTranslation } from "react-i18next";
import { TablePagination } from "@mui/material";

const Index = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [fullReport, setFullReport] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorData, setAnchorData] = useState(location.state);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };
  const getAnchorsDetails = async () => {
    setIsLoading(true);

    try {
      const res = await api.get(`/seo/backlink-reports/anchors/85142`);
      if (res.status === 200) {
        setFullReport(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  useEffect(async () => {
    getAnchorsDetails();
  }, []);

  const paginationData = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `/seo/backlink-reports/anchors/85142?limit=${rowsPerPage}&page=${page}`
      );
      if (res.status === 200) {
        setFullReport(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    paginationData();
  }, [rowsPerPage, page]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CommonSearch />
          <AnchorCard backLinks={anchorData} />
          <Typography
            variant="h4"
            sx={{ marginBottom: "1rem", marginTop: "2rem" }}
          >
            {t("Anchor_Texts")}
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Anchor")}</TableCell>
                  <TableCell align="right">{t("Refering_Domains")}</TableCell>
                  <TableCell align="right">{t("BackLink")}</TableCell>
                  <TableCell align="right">{t("DOFOLLOW")}</TableCell>
                  <TableCell align="right">{t("FIRST_SEEN")}</TableCell>
                  <TableCell align="right">{t("LAST_SEEN")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fullReport?.anchors.map((row) => (
                  <TableRow
                  // onClick={() => navigate(`/admin/backlink/${row?.reportId}`)}
                  >
                    <TableCell component="th" scope="row">
                      {row.anchor}
                    </TableCell>
                    <TableCell align="right">{row?.domains}</TableCell>
                    <TableCell align="right">{row?.backlinks}</TableCell>
                    <TableCell align="right">{row?.dofollow}</TableCell>
                    <TableCell align="right">
                      {" "}
                      {moment(row?.first_seen).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {moment(row?.last_visited).format("MM/DD/YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={anchorData?.anchors?.total_anchors}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default Index;
