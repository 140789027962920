import React, { useEffect, useState } from "react";
import { Box, Grid, Paper as MuiPaper, Typography, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useJsApiLoader } from "@react-google-maps/api";
import NotFound from "../../../../../../components/NotFound/NotFound";
import styled from "styled-components/macro";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import { useStyles } from "../../../Styles/style";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";

function Locationmap({
  centerData,
  containerStyle,
  displayMapError,
  locationDetailsData,
  setAddressInfo,
  addressInfo,
  requestSent,
  setTouchedValue,
  setDisplayErrorMessage,
  handleScroll,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [markerPosition, setMarkerPosition] = useState(centerData);
  const apiKey = "AIzaSyCpwInSnlQBKGFUOa_XkGwSQEp6VQDKdVU";

  useEffect(() => {
    if (centerData?.lat !== "" && centerData?.lng !== "") {
      setMarkerPosition(centerData);
    }
  }, [centerData]);
  const handleMarkerDragEnd = (event) => {
    if (requestSent === false) {
      setDisplayErrorMessage(false);
      setTouchedValue(true);
      const newLat = event.latLng.lat();
      const newLng = event.latLng.lng();
      setMarkerPosition({ lat: newLat, lng: newLng });

      setKey(apiKey);
      setLanguage("en");

      //   setRegion("be");
      geocode(RequestType.LATLNG, `${newLat},${newLng}`)
        .then(({ results }) => {
          const address = results[0].formatted_address;
          const { city, state, country, regionCode, postalCode } =
            results[0].address_components.reduce((acc, component) => {
              if (component.types.includes("locality"))
                acc.city = component.long_name;
              else if (component.types.includes("administrative_area_level_1"))
                acc.state = component.long_name;
              else if (component.types.includes("country")) {
                acc.country = component.long_name;
                acc.regionCode = component.short_name;
              } else if (component.types.includes("postal_code"))
                acc.postalCode = component.long_name;
              return acc;
            }, {});
          setAddressInfo({
            ...addressInfo,
            locality: city,
            administrativeArea: state,
            addressLine: address,
            regionCode: regionCode,
            postalCode:
              postalCode !== undefined ? postalCode : addressInfo?.postalCode,
          });
        })
        .catch(console.error);
    } else {
      if (setDisplayErrorMessage !== undefined) {
        setDisplayErrorMessage(true);
        handleScroll();
      }
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDFJLhlAmlDBZS06eElolQSAL0BZA_X-qY",
    libraries: ["geometry", "drawing"],
  });

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {displayMapError && (
          <div style={{ width: "97%", margin: "auto" }}>
            {" "}
            <Grid container className={classes.MapErrorContainer}>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <ErrorOutlineIcon className={classes.errorIcon} />
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.mapErrorText}>
                    {t("map_error_text")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <>
          {locationDetailsData !== null ? (
            <Grid container>
              {centerData?.lat !== "" && centerData?.lng !== "" && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <LoadScript googleMapsApiKey="AIzaSyDFJLhlAmlDBZS06eElolQSAL0BZA_X-qY"> */}
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={markerPosition}
                      zoom={10}
                    >
                      <Marker
                        position={centerData}
                        draggable={true}
                        onDragEnd={handleMarkerDragEnd}
                      ></Marker>

                      <></>
                    </GoogleMap>
                  )}
                  {/* </LoadScript> */}
                </Grid>
              )}
            </Grid>
          ) : (
            <NotFound text={t("No_Data_Found")} />
          )}
        </>
      </Grid>
    </>
  );
}
export default Locationmap;
