export const languagesData = [
  {
    id: "1",
    value: "bg",
    language: "Bulgarian",
  },
  {
    id: "2",
    value: "zh",
    language: "Chinese (simplified)",
  },
  {
    id: "3",
    value: "cs",
    language: "Czech",
  },
  {
    id: "4",
    value: "da",
    language: "Danish",
  },
  {
    id: "5",
    value: "nl",
    language: "Dutch",
  },
  {
    id: "6",
    value: "en",
    language: "English",
  },

  {
    id: "7",
    value: "et",
    language: "Estonian",
  },
  {
    id: "8",
    value: "fi",
    language: "Finnish",
  },
  {
    id: "9",
    value: "FR",
    language: "French",
  },

  {
    id: "10",
    value: "de",
    language: "German",
  },
  {
    id: "11",
    value: "el",
    language: "Greek",
  },
  {
    id: "12",
    value: "hu",
    language: "Hungarian",
  },
  {
    id: "13",
    value: "id",
    language: "Indonesian",
  },
  {
    id: "14",
    value: "it",
    language: "Italian",
  },
  {
    id: "15",
    value: "ja",
    language: "Japanese",
  },
  {
    id: "16",
    value: "lv",
    language: "Latvian",
  },
  {
    id: "17",
    value: "lt",
    language: "Lithuanian",
  },
  {
    id: "18",
    value: "pl",
    language: "Polish",
  },
  {
    id: "19",
    value: "pt-PT",
    language: "Portuguese",
  },
  {
    id: "20",
    value: "pt-BR",
    language: "Portuguese (Brazilian)",
  },
  {
    id: "21",
    value: "ro",
    language: "Romanian",
  },
  {
    id: "22",
    value: "ru",
    language: "Russian",
  },
  {
    id: "23",
    value: "sk",
    language: "Slovak",
  },
  {
    id: "24",
    value: "sl",
    language: "Slovenian",
  },
  {
    id: "25",
    value: "es",
    language: "Spanish",
  },
  {
    id: "26",
    value: "sv",
    language: "Swedish",
  },
  {
    id: "27",
    value: "tr",
    language: "Turkish",
  },
  {
    id: "28",
    value: "uk",
    language: "Ukranian",
  },
  {
    id: "29",
    value: "ko",
    language: "Korean",
  },
  {
    id: "30",
    value: "nb",
    language: "Norwegian",
  },
];
export const languagesDataTwo = [
  {
    id: "1",
    value: "bg",
    language: "Bulgarian",
  },
  {
    id: "2",
    value: "zh",
    language: "Chinese (simplified)",
  },
  {
    id: "3",
    value: "cs",
    language: "Czech",
  },
  {
    id: "4",
    value: "da",
    language: "Danish",
  },
  {
    id: "5",
    value: "nl",
    language: "Dutch",
  },
  {
    id: "6",
    value: "en",
    language: "English",
  },

  {
    id: "7",
    value: "et",
    language: "Estonian",
  },
  {
    id: "8",
    value: "fi",
    language: "Finnish",
  },
  {
    id: "9",
    value: "FR",
    language: "French",
  },

  {
    id: "10",
    value: "de",
    language: "German",
  },
  {
    id: "11",
    value: "el",
    language: "Greek",
  },
  {
    id: "12",
    value: "hu",
    language: "Hungarian",
  },
  {
    id: "13",
    value: "id",
    language: "Indonesian",
  },
  {
    id: "14",
    value: "it",
    language: "Italian",
  },
  {
    id: "15",
    value: "ja",
    language: "Japanese",
  },
  {
    id: "16",
    value: "ko",
    language: "Korean",
  },
  {
    id: "17",
    value: "lv",
    language: "Latvian",
  },
  {
    id: "18",
    value: "lt",
    language: "Lithuanian",
  },
  {
    id: "19",
    value: "nb",
    language: "Norwegian",
  },
  {
    id: "20",
    value: "pl",
    language: "Polish",
  },
  {
    id: "21",
    value: "pt-PT",
    language: "Portuguese",
  },
  {
    id: "22",
    value: "pt-BR",
    language: "Portuguese (Brazilian)",
  },
  {
    id: "23",
    value: "ro",
    language: "Romanian",
  },
  {
    id: "24",
    value: "ru",
    language: "Russian",
  },
  {
    id: "25",
    value: "sk",
    language: "Slovak",
  },
  {
    id: "26",
    value: "sl",
    language: "Slovenian",
  },
  {
    id: "27",
    value: "es",
    language: "Spanish",
  },
  {
    id: "28",
    value: "sv",
    language: "Swedish",
  },
  {
    id: "29",
    value: "th",
    language: "Thai",
  },
  {
    id: "30",
    value: "tr",
    language: "Turkish",
  },
  {
    id: "31",
    value: "uk",
    language: "Ukranian",
  },

  {
    id: "32",
    value: "vi_VN",
    language: "Vietnamese",
  },
];

export const languageOptions = {
  bg: {
    icon: "/static/img/flags/bg.png",
    name: "Bulgarian",
  },
  zh: {
    icon: "/static/img/flags/cn.png",
    name: "Chinese (simplified)",
  },
  cs: {
    icon: "/static/img/flags/cz.png",
    name: "Czech",
  },
  da: {
    icon: "/static/img/flags/dk.png",
    name: "Danish",
  },
  en: {
    icon: "/static/img/flags/us.png",
    name: "English",
  },
  et: {
    icon: "/static/img/flags/et.png",
    name: "Estonian",
  },
  FR: {
    icon: "/static/img/flags/fr.png",
    name: "French",
  },
  fi: {
    icon: "/static/img/flags/fi.png",
    name: "Finnish",
  },
  de: {
    icon: "/static/img/flags/de.png",
    name: "German",
  },
  nl: {
    icon: "/static/img/flags/nl.png",
    name: "Dutch",
  },
  es: {
    icon: "/static/img/flags/es.png",
    name: "Spanish",
  },
  sv: {
    icon: "/static/img/flags/sv.png",
    name: "Swedish",
  },
  el: {
    icon: "/static/img/flags/gr.png",
    name: "Greek",
  },
  it: {
    icon: "/static/img/flags/it.png",
    name: "Italian",
  },
  id: {
    icon: "/static/img/flags/it.png",
    name: "Indonesian",
  },
  ja: {
    icon: "/static/img/flags/jp.png",
    name: "Japanese",
  },
  lv: {
    icon: "/static/img/flags/lv.png",
    name: "Latvian",
  },
  lt: {
    icon: "/static/img/flags/lt.png",
    name: "Lithuanian",
  },
  pl: {
    icon: "/static/img/flags/pl.png",
    name: "Polish",
  },
  "pt-PT": {
    icon: "/static/img/flags/pt.png",
    name: "Portuguese",
  },
  "pt-BR": {
    icon: "/static/img/flags/ptbr.png",
    name: "Portuguese (Brazilian)",
  },
  ro: {
    icon: "/static/img/flags/ro.png",
    name: "Romanian",
  },
  ru: {
    icon: "/static/img/flags/ru.png",
    name: "Russian",
  },
  sk: {
    icon: "/static/img/flags/sk.png",
    name: "Slovak",
  },
  sl: {
    icon: "/static/img/flags/sl.png",
    name: "Slovenian",
  },
  tr: {
    icon: "/static/img/flags/tr.png",
    name: "Turkish",
  },
  hu: {
    icon: "/static/img/flags/hu.png",
    name: "Hungarian",
  },

  uk: {
    icon: "/static/img/flags/uk.png",
    name: "Ukrainian",
  },
  nb: {
    icon: "/static/img/flags/nb.png",
    name: "Norwegian",
  },
  ko: {
    icon: "/static/img/flags/ko.png",
    name: "Korean",
  },
};
