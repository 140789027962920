import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  Box,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import PropTypes from "prop-types";
import MultipleImagesCard from "./MultipleImagesCommonCard";
import { Dialog } from "@material-ui/core";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../../Styles/style";
import Loader from "../../../../../../../../components/Loaders/Loader";
import ConfirmModal from "../../../../../../../../components/Models/ConfirmationModal";
import ConfirmModalTwo from "../../../../../../../../components/Models/ConfirmModalLodging";
import PreviewImageModal from "../../../Images/Modals/PreviewImageModal/PreviewImageModal";
import { api } from "../../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
// import noImageGray from "../../../../../../assets/images/noImageGray.png";
import { styled } from "@mui/system";
import FoodImageModal from "./Food&DrinkImages/FoodImageModal";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CoverImages = ({
  onCancel,
  data,
  locationDetailsData,
  location,
  locationCategory,
  locationFacebookCategory,
  getLocationDetails,
  getLocationDetailsListing,
  facebookConnect,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  otherValue,
  setOtherValue,
  handleDeleteCoverImages,
  handleUpdateCoverImages,
  page,
  setPage,
  finalUploadImages,
  setFinalUploadImages,
  finalDeleteImages,
  setFinalDeleteImages,
  getLocationMedia,
  fetchMoreFoodrData,
  previewImages,
  setPreviewImages,
  mediaCount,
  setMediaCount,
  setFoodPhotoImages,
  foodPhotoImages,
  previewLoading,
  setPreviewLoading,
  setIsLoading,
  isLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [foodImageModal, setFoodImageModal] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [selectedFoodImages, setSelectedFoodImages] = useState([]);
  const [selectedPdfFiles, setSelectedPdfFiles] = useState([]);
  const [category, setCategory] = useState("");
  const [cancelState, setCancelState] = useState(false);
  const [openPreviewImageModal, setOpenPreviewImageModal] = useState(false);
  const [value, setValue] = useState(0);
  const [displayButtonGrid, setDisplayButtonGrid] = useState(false);
  const [indexValueArr, setIndexValueArr] = useState(0);
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);

  useEffect(() => {
    getLocationMedia();
  }, []);
  useEffect(() => {
    if (page === 0) {
      setFoodPhotoImages([]);
    }
  }, [page]);

  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      getLocationDetails(location);
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };

  const handleOpenFoodModal = (images, pdfs) => {
    setSelectedFoodImages(images);
    setSelectedPdfFiles(pdfs);
    setFoodImageModal(true);
    setCategory("FOOD_AND_MENU");
  };

  const handleCloseFoodPictureModal = () => {
    document.getElementById("file-container7").value = "";

    setFoodImageModal(false);

    setSelectedFoodImages([]);
  };
  const handleImagesShow = (images) => {
    if (category === "FOOD_AND_MENU") {
      let arr8 = [...foodPhotoImages];
      images?.map((item) => {
        let teamObj1 = {
          sourceUrl: item,
          category: "FOOD_AND_MENU",
          alreadyUploadedPhoto: false,
        };
        finalUploadImages.push(teamObj1);
        arr8.push(teamObj1);
        setMediaCount(arr8?.length);
        setFoodPhotoImages(arr8);
      });
    }
    setFinalUploadImages([...finalUploadImages]);
  };

  const handleDeleteImages = (item, index) => {
    let deleteItemsArr = [...finalDeleteImages];
    const ind = finalUploadImages.findIndex(
      (data) => data?.sourceUrl === item?.sourceUrl
    );
    const filteredItems = finalUploadImages?.filter((data, Ind) => {
      return Ind !== ind;
    });

    console.log(filteredItems, "filteredItems");

    setFinalUploadImages(filteredItems);
    if (item?.alreadyUploadedPhoto === true) {
      deleteItemsArr?.push(item?._id);
    }
    setFinalDeleteImages(deleteItemsArr);

    if (item?.category === "FOOD_AND_MENU" || item?.category === "MENU") {
      const deleteImage = foodPhotoImages?.filter((item, indx) => {
        return indx !== index;
      });
      setMediaCount(deleteImage?.length);
      setFoodPhotoImages(deleteImage);
    }
    setTouchedValue(true);
  };
  const handleDeleteImagePreview = (item, index) => {
    const ind = previewImages.findIndex(
      (data) => data?.sourceUrl === item?.sourceUrl
    );
    const filteredItems = previewImages?.filter((data, Ind) => {
      return Ind !== ind;
    });
    setPreviewImages(filteredItems);
  };

  const teamValidations = {
    ratio: null,
    minSize: 1000,
    maxSiz: 50000,
    square: false,
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleOpenPreviewImage = (images, displayGrid, value) => {
    setPreviewImages(images);
    setOpenPreviewImageModal(true);
    setDisplayButtonGrid(displayGrid);
    if (value !== undefined) {
      setIndexValueArr(value);
    } else {
      setIndexValueArr(null);
    }
  };

  const handleClosePreviewImage = () => {
    setOpenPreviewImageModal(false);
  };
  const handleCloseBox = () => {
    setOpenConfirmBox(false);
    if (otherValue !== null) {
      setValue(otherValue);
      setTouchedValue(false);
    }
  };

  return (
    <>
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateCoverImages}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            getLocationDetails={getLocationDetails}
            location={location}

            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      {openConfirmBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={openConfirmBox}
          onClose={handleCloseBox}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModalTwo
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateCoverImages}
            onCancel={handleCloseBox}
            location={location}
            loading={isLoading}
          />
        </Dialog>
      )}
      <Grid
        container
        direction="column"
        //   className={classes.gridContainerTwoBulk}
      >
        <Box sx={{ width: "100%", overflow: "auto", height: "400px" }}>
          {isLoading ? (
            <Grid
              container
              sx={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                height: "430px",
                // marginTop: "150px",
              }}
            >
              <Loader />
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MultipleImagesCard
                  title={t("Food_&_Drinks")}
                  tooltipText={t("interior_tooltipText")}
                  imagesArray={foodPhotoImages}
                  handleOpenModal={handleOpenFoodModal}
                  handleDeleteImage={handleDeleteImages}
                  alignment={true}
                  locationId={location?.id}
                  handleClose={handleCloseFoodPictureModal}
                  open={foodImageModal}
                  loading={isLoading}
                  handleImagesShow={handleImagesShow}
                  setTouchedValue={setTouchedValue}
                  category={category}
                  identifier="container7"
                  fetchMoreData={fetchMoreFoodrData}
                  hasMore={hasMore}
                  setHasMore={setHasMore}
                  handleOpenPreviewImage={handleOpenPreviewImage}
                  setTitle={setTitle}
                  mediaCount={mediaCount}
                  finalDeleteImages={finalDeleteImages}
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <Divider />

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Close")}
            onSubmit={handleCancelButton}
          />
          <CommonButton
            disabled={isLoading || companyData?.menuEnabled == false}
            onSubmit={handleUpdateCoverImages}
            label={t("Save")}
          />
        </Grid>
      </Grid>

      {foodImageModal && (
        <FoodImageModal
          title={t("Food_&_Drinks")}
          locationId={location.id}
          tooltipText={t("interior_tooltipText")}
          handleClose={handleCloseFoodPictureModal}
          open={foodImageModal}
          loading={isLoading}
          handleImagesShow={handleImagesShow}
          setTouchedValue={setTouchedValue}
          validation={teamValidations}
          category={category}
          selectedImages={selectedFoodImages}
          setSelectedImages={setSelectedFoodImages}
          selectedPdfFiles={selectedPdfFiles}
          setSelectedPdfFiles={setSelectedPdfFiles}
        />
      )}

      {openPreviewImageModal && (
        <PreviewImageModal
          handleClickOpen={handleOpenPreviewImage}
          open={openPreviewImageModal}
          handleClose={handleClosePreviewImage}
          previewImages={previewImages}
          handleDeleteImage={handleDeleteImages}
          title={t("photos_of_product")}
          displayButtonGrid={displayButtonGrid}
          indexOfItem={indexValueArr}
          handleDeleteImagePreview={handleDeleteImagePreview}
          mediaCount={mediaCount}
          previewLoading={previewLoading}
          fetchMoreData={fetchMoreFoodrData}
        />
      )}
    </>
  );
};

export default CoverImages;
