import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Dialog,
  DialogActions,
  createFilterOptions,
  Stack,
  DialogTitle,
  DialogContent,
  Divider,
  Zoom,
  Button,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { api } from "../../../../contexts/JWTContext";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import noImageGray from "../../../../assets/images/noImageGray.png";
import Loader from "../../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ReactComponent as AiLogoGrey } from "../../../../assets/images/AIlogogrey.svg";
import CamBtnIcon from "../../../../assets/Icons/camBtnIcon.svg";
import PostAIModal from "../../../../components/Models/AIModal/PostAIModal";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useStyles } from "../styles/style";
import Compressor from "compressorjs";
import PostCustomModel from "./CustomModel";
import CalendarMonthIcon from "../../../../assets/Icons/Calender.svg";
// import PictureModalCard from "./PictureModalCard";
import PictureModalCard from "../AddPosts/Components/PictureModalCard";
import EditIcon from "@mui/icons-material/Edit";
import useDateFormat from "../../../../hooks/useDateFormat";

const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));
Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const EditPosts = ({ onCancel, item, getAllposts, setPageNumber }) => {
  const classes = useStyles();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const initialValues = {
    Description: "",
  };
  const validate = yup.object({
    Description: yup.string().required(),
  });
  const { t } = useTranslation();
  const location = useLocation();
  const inputFileRef = React.useRef();
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [startDate, setStartDate] = useState("");
  const [isUploadPicture, setIsUploadPicture] = useState("");
  const [repeat, setRepeat] = useState("");
  const [endDate, setEndDate] = useState("");
  const [postType, setPostType] = useState("");
  const [loading, setLoading] = useState(false);
  const [allLocation, setAllLocation] = useState([]);
  const [displayError, setDisplayError] = useState("");
  // const [locationId, setLocationId] = useState("");
  const locationId = location.state?.location?.id;
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationRequired, setLocationRequired] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [tags, setTags] = useState([]);
  const [AiContentDescription, setAiContentDescription] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [contentRequired, setContentRequired] = useState(false);
  const [tagsRequired, setTagsRequired] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [endDateGreater, setEndDateGreater] = useState(false);
  const [endDateRequired, setEndDateRequired] = useState(false);
  const [ValidDate, setValidDate] = useState(true);
  const [EndValidDate, setEndValidDate] = useState(true);
  const [selectedImages, setSelectedImages] = useState([]);

  const [isUploading, setIsUploading] = useState(false);
  const [openPictureModal, setOpenPictureModal] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editImage, setEditImage] = useState([]);
  const [displayImage, setDisplayImage] = useState(null);
  const [loctionLoadings, setLoctionLoadings] = useState(false);
  const [editUploadedImage, setEditUploadedImage] = useState([]);
  const [isUploadPictureUberAll, setIsUploadPictureUberAll] = useState(false);
  const [displayUberAllError, setDisplayUberAllError] = useState(false);
  const [disableLocations, setDisableLocations] = useState(false);
  const [limitNumber, setLimitNumber] = useState("160");
  const [openCustomModel, setOpenCustomModel] = useState(false);
  const [frequencyValue, setFrequencyValue] = useState("daily");
  const [occuranceValue, setOccuranceValue] = useState(0);
  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [selectedMonthDays, setSelectedMonthDays] = useState([]);
  const [customError, setCustomError] = useState(false);
  const [postLocationsArr, setPostLocationArr] = useState([]);
  const [translatePrompLoading, setTranslatePrompLoading] = useState(false);
  const [error, setError] = useState(false);
  const [translatePromptAgainLoading, setTranslatePromptAgainLoading] =
    useState(false);

  const filter = createFilterOptions();
  function isValidDateFormat(dateString, format) {
    return dayjs(dateString, format, true).isValid();
  }

  const { format } = useDateFormat();

  useEffect(() => {
    let arr = [];
    if (allLocation?.results?.length > 0 && selectedLocation?.length > 0) {
      allLocation?.results?.forEach((itemOne) => {
        selectedLocation?.forEach((itemTwo) => {
          if (itemOne?.id === itemTwo?.id) {
            arr?.push(itemTwo);
          }
        });
      });
      if (arr?.length > 0 && arr.length === selectedLocation?.length) {
        setDisableLocations(false);
      } else {
        setDisableLocations(true);
      }
    }
  }, [allLocation, selectedLocation]);

  useEffect(() => {
    if (selectedImages?.length > 0) {
      setOpenPictureModal(true);
    }
  }, [selectedImages]);

  /*   useEffect(async () => {
    if (isUploading) {
      await uploadImages();
    }
  }, [isUploading]); */

  useEffect(() => {
    if (item) {
      setDescription(item.content);
      setStartDate(item?.startDate);
      setRepeat(item?.repeat);
      setEndDate(item?.endDate);
      setPostType(item?.postType);
      setEditImage(item?.postPicture);
      if (item?.postPicture?.length > 0) {
        item?.postPicture?.forEach((item) => {
          setDisplayImage(item?.url);
        });
      }
      setFrequencyValue(
        item?.customSchedule !== null ? item?.customSchedule?.frequency : ""
      );
      setSelectedMonthDays(
        item?.customSchedule !== null ? item?.customSchedule?.dates : []
      );
      setSelectedWeekDays(
        item?.customSchedule !== null ? item?.customSchedule?.days : []
      );
      setOccuranceValue(
        item?.customSchedule !== null ? item?.customSchedule?.occurance : 1
      );
      setUrl(item?.url);
      if (item?.postLocations.length > 0) {
        const uniqueLocations = [];
        const uniqueIds = new Set();

        for (const postLocation of item?.postLocations) {
          const locationId = postLocation?.location?.id;

          if (!uniqueIds.has(locationId)) {
            uniqueIds.add(locationId);
            uniqueLocations.push(postLocation);
          }
        }
        setPostLocationArr(uniqueLocations);
        let tempLocationArray = [];

        uniqueLocations?.forEach((locationItem) => {
          tempLocationArray.push(locationItem?.location);
        });
        if (tempLocationArray?.length > 0) {
          const defaultLocationObj = tempLocationArray?.find(
            (item) => item?.defaultLocation !== null
          );
          if (defaultLocationObj !== undefined && defaultLocationObj !== null) {
            setDefaultLocation(defaultLocationObj);
            // setDefaultLocationByDefault(defaultLocationObj);
          } else {
            setDefaultLocation(tempLocationArray[0]);
            //  setDefaultLocationByDefault(tempLocationArray[0]);
          }
        }
        setSelectedLocation(tempLocationArray);
      }

      //  setSelectedLocation(item?.postLocations);
      setIsUploadPicture(item?.isUploadPicture);
      setIsUploadPictureUberAll(item?.uploadUberAll);
      // setStatus(getPost?.status);
    }
    getAllLocations();
  }, []);

  const currentDate = new Date();

  const handleChangeCheckButton = (e) => {
    setIsUploadPicture(e.target.checked);
  };
  const handleChangeCheckButtonUberAll = (e) => {
    setIsUploadPictureUberAll(e.target.checked);
  };

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
    setEditUploadedImage([]);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
    setLimitNumber("160");
  };

  const getAllLocations = async () => {
    try {
      setLoctionLoadings(true);
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/googleLinkedlocations`);
      if (res.status === 200) {
        setAllLocation(res.data.data);
        setLoctionLoadings(false);
      }
    } catch (error) {
      setLoctionLoadings(false);
    }
  };

  const updateRule = async () => {
    if (selectedLocation.length === 0) {
      setLocationRequired(true);
    } else {
      setPageNumber(0);
      setLocationRequired(false);
      let locationIds = [];
      selectedLocation?.forEach((location) => {
        locationIds.push(location?.id);
      });
      const postLoc = [];
      if (postLocationsArr.length > 0) {
        locationIds.forEach((itemOne) => {
          postLocationsArr.forEach((itemTwo) => {
            if (itemOne === itemTwo?.location?.id) {
              postLoc.push(itemTwo);
            }
          });
        });
      }
      const data = {
        content: description,
        url: postType === "CALL" ? "" : url,

        locationIds: locationIds,
        postLocations: postLoc.length > 0 ? postLoc : [],
        startDate: startDate,
        endDate: endDate,
        repeat: repeat,
        postType: postType,
        isUploadPicture: isUploadPicture,
        uploadUberAll: isUploadPictureUberAll,
        customSchedule: {
          frequency: frequencyValue,
          occurance: occuranceValue,
          days: selectedWeekDays, // if weekly is selected than days will be, weekdays.
          dates: selectedMonthDays,
        },
        postPictures: [
          {
            url: editImage[0]?.url,
            key: editImage[0]?.key,
          },
        ],
      };

      try {
        setLoading(true);
        const res = await api.patch(`/post/${item?.id}`, data);

        if (res.status === 200) {
          toast.success(res.data.message);

          setLoading(false);
          getAllposts(true);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const generateContent = async (data) => {
    /*   if (tags?.length===0) {
      setTagsRequired(true);
    } else {
      setTagsRequired(false);
    } */
    if (AiContentDescription?.length === 0) {
      setContentRequired(true);
    } else {
      setContentRequired(false);
    }
    if (AiContentDescription?.length > 0) {
      try {
        setLoading(true);

        const res = await api.post(`/openAi/generateContent`, {
          prompt: data,
        });
        if (res.status === 200) {
          setLoading(false);
          setDisplayError(false);
          setGeneratedContent(res?.data?.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        setDisplayError(true);
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const generateAIContent = () => {
    const str = "\n\nproduct:";
    const str2 = "\n\n";
    const joinedStr = AiContentDescription.concat(str);
    /* const finalStr = joinedStr tags.toString(); */
    let finalStr = "";
    if (tags?.length > 0) {
      finalStr = joinedStr + tags.toString();
    } else {
      finalStr = AiContentDescription.concat(str2);
    }

    generateContent(finalStr);
  };
  const handleSubmit = () => {
    handleCloseTemplateMenu();
    setDescription(generatedContent);

    // setDescription(generatedContent?.length);
  };

  const onFileChangeCapture = (e) => {
    let tempImages = [];

    for (const [key, value] of Object.entries(e?.target?.files)) {
      new Compressor(value, {
        quality: 0.8,
        success: (compressedResult) => {
          // setCompressedFile(compressedResult);

          tempImages.push({
            picture: compressedResult,
            picturePreview: URL.createObjectURL(compressedResult),
          });
          setSelectedImages([...tempImages]);
        },
      });
    }

    setIsUploading(false);
  };

  const handleClose = () => {
    document.getElementById("file-id1").value = "";
    setOpenPictureModal(false);
    setEditUploadedImage([]);
    setSelectedImages([]);
    setIsUploading(false);
  };
  const handleSubmitPicture = () => {
    setSubmitLoading(true);

    document.getElementById("file-id1").value = "";
    if (editUploadedImage?.length > 0) {
      setEditImage(editUploadedImage[0]?.postPictures);
      setDisplayUberAllError(editUploadedImage[0]?.errorUberAll);
    }
    if (selectedImages?.length > 0) {
      setDisplayImage(selectedImages[0]?.picturePreview);
    }
    setSubmitLoading(false);
    setOpenPictureModal(false);
    setEditUploadedImage([]);
    setSelectedImages([]);
    setIsUploading(false);
  };
  const handleClickCloseWarningCard = () => {
    setDisplayUberAllError(false);
  };

  const handleClickCloseWarning = (index) => {
    let temmpArray = [...selectedImages];
    let temmpObj = temmpArray[index];
    temmpObj["errorUberAll"] = false;
    temmpArray[index] = temmpObj;
    setSelectedImages(temmpArray);
  };

  /*  const uploadImages = async () => {
    try {
      //let tempImages = [];
      let formData = new FormData();

      // selectedImages.forEach((item) => {
      formData.append("photos", selectedImages[0].picture);
      // });

      setIsLoading(true);
      const res = await api.post(`/google/post/${locationId}`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;

        //  setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50;
         // setProgress(progress);
        },
      });
      if (res.status === 200) {
        setIsUploaded(true);

        let tempArray = [];
        res.data.locationArray?.map((item) => {
          tempArray.push({
            locationIds: [],
            content: "",
            startDate: null,
            endDate: null,
            repeat: "no",
            postType: "CALL",
            isUploadPicture: true,
            url: "",
            title: "",
            postPictures: [
              {
                url: item?.url,
                key: item?.key,
              },
            ],
          });
          //
          // img["key"] = item?.key;
        });

        // let tempPostData;
        // setPostData((state) => {
        //   tempPostData = state;
        //   return state;
        // });
        setEditUploadedImage(tempArray[0].postPictures);

        setIsLoading(false);

        //   handleClose();
      }
    } catch (error) {
      setIsUploaded(false);

      setIsLoading(false);
    }
  }; */

  const handleDelete = (index) => {
    const deleteImage = selectedImages?.filter((item, indx) => indx !== index);

    const findDeleted = selectedImages?.find((item, indx) => indx === index);
    if (findDeleted) {
      const filterDelete = editUploadedImage?.filter(
        (item) => item?.key !== findDeleted.key
      );
      setEditUploadedImage(filterDelete);
    }

    setSelectedImages(deleteImage);

    // Check if there are no more selected images after deletion and update isUploading state accordingly
    if (deleteImage.length >= 0) {
      setIsUploading(false);
    }
    setOpenPictureModal(false);
    // navigate(-1);
  };
  const handleImageError = (index, key) => {
    let temmpArray = [...selectedImages];
    let temmpObj = temmpArray[index];

    if (key !== undefined && key === "uberAll") {
      temmpObj["errorUberAll"] = true;
      temmpArray[index] = temmpObj;
    } else {
      temmpObj["error"] = true;
      temmpArray[index] = temmpObj;
    }
    setSelectedImages(temmpArray);
  };
  const afterUploadSelectedChange = (indexNum, keyNum) => {
    const tempArray = [...selectedImages];
    const tempObj = selectedImages[indexNum];
    tempObj["key"] = keyNum;
    tempArray.splice(indexNum, 1, tempObj);
    setSelectedImages(tempArray);
  };

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };

  const handleCloseCustomModel = () => {
    setOpenCustomModel(false);
    if (
      (selectedMonthDays?.length === 0 && frequencyValue === "monthly") ||
      (selectedWeekDays?.length === 0 && frequencyValue === "weekly") ||
      occuranceValue === 0
    ) {
      setCustomError(true);
    } else {
      setCustomError(false);
    }

    if (frequencyValue === "" && repeat === "custom") {
      setRepeat("no");
    }
    // setSelectedPostData(null);
  };

  const validateUrl = (value) => {
    const urlPattern = new RegExp(
      "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})"
    );
    return !!value.match(urlPattern);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setError(!validateUrl(value));
    setUrl(value);
    if (value === "") {
      setError(false);
    }
  };
  return (
    <>
      {openCustomModel && (
        <PostCustomModel
          title={t("Custom_Repeat_Date")}
          onCancel={handleCloseCustomModel}
          setOpenCustomModel={setOpenCustomModel}
          open={openCustomModel}
          frequencyValue={frequencyValue}
          setFrequencyValue={setFrequencyValue}
          occuranceValue={occuranceValue}
          setOccuranceValue={setOccuranceValue}
          selectedWeekDays={selectedWeekDays}
          setSelectedWeekDays={setSelectedWeekDays}
          selectedMonthDays={selectedMonthDays}
          setSelectedMonthDays={setSelectedMonthDays}
          repeat={repeat}
          data={item}
        />
      )}
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          onClose={handleCloseTemplateMenu}
        >
          <PostAIModal
            title={t("AI Writer")}
            onCancel={handleCloseTemplateMenu}
            tags={tags}
            setTags={setTags}
            displayError={displayError}
            AiContentDescription={AiContentDescription}
            setAiContentDescription={setAiContentDescription}
            generatedContent={generatedContent}
            setGeneratedContent={setGeneratedContent}
            setTagsRequired={setTagsRequired}
            tagsRequired={tagsRequired}
            setContentRequired={setContentRequired}
            contentRequired={contentRequired}
            defaultLocation={defaultLocation}
            limitNumber={limitNumber}
            setLimitNumber={setLimitNumber}
            translatePromptAgainLoading={translatePromptAgainLoading}
            setTranslatePromptAgainLoading={setTranslatePromptAgainLoading}
            translatePrompLoading={translatePrompLoading}
            setTranslatePrompLoading={setTranslatePrompLoading}
          />
          <DialogActions className={classes.postDialog}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {generatedContent?.length > 0 && (
                  /*   <CommonButton
                displayWhite="true"
                onSubmit={generateAIContent}
                label={<RefreshIcon />}
                disabled={loading}
                // label={t("Cancel")}
              /> */
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={
                      loading ||
                      tagsRequired ||
                      translatePrompLoading ||
                      translatePromptAgainLoading
                    }
                    onClick={() => generateAIContent()}
                    className={classes.postLoadingButton}
                  >
                    <RefreshIcon />
                  </LoadingButton>
                )}

                {generatedContent?.length === 0 && (
                  /*  <CommonButton
                type="submit"
                label={t("Write For Me")}
                disabled={loading}
                onSubmit={generateAIContent}
              /> */
                  <LoadingButton
                    loading={loading}
                    onClick={() => generateAIContent()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={
                      loading ||
                      tagsRequired ||
                      translatePrompLoading ||
                      translatePromptAgainLoading
                    }
                    className={classes.PostAiButton}
                  >
                    {t("Write_For_Me")}
                  </LoadingButton>
                )}
                {generatedContent?.length > 0 && (
                  <LoadingButton
                    loading={loading}
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={
                      loading ||
                      tagsRequired ||
                      translatePrompLoading ||
                      translatePromptAgainLoading
                    }
                    className={classes.postSubmitButton}
                  >
                    {t("Submit")}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
            <style>
              {`
           .MuiDialogActions-root {
            display: block;
          }
         
        `}
            </style>
          </DialogActions>
        </Dialog>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={() => {
          updateRule();
        }}
      >
        {() => (
          <Form>
            <Box className={classes.editPostBox}>
              <Typography className={classes.locationTypo}>
                {t("Edit_Post")}
              </Typography>
            </Box>
            <hr />

            <Grid
              container
              spacing={4}
              md={12}
              sx={{
                display: "flex",

                justifyContent: "space-between",
              }}
            >
              <Grid item xs={11} sm={5} md={5}>
                {loading ? (
                  <Loader />
                ) : (
                  <Box
                    // className={classes.lazyLoadBox}
                    sx={{
                      position: "relative",
                      marginTop: "10px",
                    }}
                  >
                    <LazyLoadImage
                      loading="lazy"
                      key={item.id}
                      src={
                        loadImg
                          ? noImageGray
                          : displayImage !== null
                          ? displayImage
                          : ""
                      }
                      beforeLoad={handleBeforeLOad}
                      afterLoad={handleAfterLOad}
                      style={{
                        height: "200px",
                        width: "250px",
                        borderRadius: "8px",
                        imagerendering: "pixelated;",
                      }}
                      PlaceholderSrc={noImageGray}
                      alt="Image Alt"
                      effect="blur"
                    />
                    {/*  <LazyLoad
                      src={editImage?.map((item) => item.url)}
                      key={item.id}
                      placeholderSrc="http://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                      // className={classes.lazyLoadContainer}
                      sx={{
                        height: "200px",
                        width: "250px",
                        borderRadius: "8px",
                        imagerendering: "pixelated;",
                      }}
                      alt="image"
                    /> */}

                    <form
                      className={classes.formUpload}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <label htmlFor="file-id1">
                        <img
                          alt=""
                          src={CamBtnIcon}
                          style={{
                            position: "absolute",
                            width: "80px",
                            bottom: "100%",
                            right: "3%",
                            cursor: "pointer",
                          }}
                        />
                        <input
                          id="file-id1"
                          type="file"
                          accept=".jpg, .png, .jpeg"
                          multiple={true}
                          ref={inputFileRef}
                          onChange={onFileChangeCapture}
                          style={{ display: "none" }}
                        />
                      </label>
                    </form>
                  </Box>
                )}
              </Grid>

              <Grid item xs={11} sm={7} md={7}>
                <Grid item md={12}>
                  <Typography className={classes.locationTypo}>
                    {t("Select_Location")}
                  </Typography>

                  <Autocomplete
                    multiple
                    error={locationRequired}
                    disablePortal
                    id="combo-box-demo"
                    disableCloseOnSelect
                    disabled={disableLocations}
                    options={
                      allLocation?.results?.length > 0
                        ? allLocation?.results
                        : []
                    }
                    value={selectedLocation ? selectedLocation : []}
                    onChange={(event, value) => {
                      // Check if 'All' option is clicked
                      if (value?.length > 0) {
                        setLocationRequired(false);
                      } else {
                        setLocationRequired(true);
                      }

                      if (value.find((option) => option.id === 0)) {
                        const defaultLocationObj = allLocation?.results?.find(
                          (item) => item?.defaultLocation !== null
                        );
                        if (
                          defaultLocationObj !== undefined &&
                          defaultLocationObj !== null
                        ) {
                          setDefaultLocation(defaultLocationObj);
                        } else {
                          setDefaultLocation(allLocation?.results[0]);
                        }
                        if (
                          allLocation?.results.length ===
                          selectedLocation?.length
                        ) {
                          // Check if all options are selected
                          setSelectedLocation([]);
                          setSelectAll(false);
                        } else {
                          setSelectAll(true);
                          setSelectedLocation(allLocation?.results);
                          //  setLocationRequired(false);
                        }
                      } else {
                        const defaultLocationObj = value?.find(
                          (item) => item?.defaultLocation !== null
                        );
                        if (
                          defaultLocationObj !== undefined &&
                          defaultLocationObj !== null
                        ) {
                          setDefaultLocation(defaultLocationObj);
                        } else {
                          setDefaultLocation(value[0]);
                        }
                        setSelectedLocation(value);
                        if (value?.length === allLocation?.results?.length) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (allLocation?.results?.length > 0) {
                        return [
                          { id: 0, internalName: "Select All" },
                          ...filtered,
                        ];
                      } else {
                        return [];
                      }
                    }}
                    // defaultValue={selectedLocation}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    sx={{ minWidth: 300 }}
                    // value={locationId ? locationId : []}
                    // onChange={(e, value) => {
                    //   setLocationRequired(false);

                    //   setSelectedLocation(value);
                    // }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          id="select-all-checkbox"
                          checked={selectAll || selected}
                          // checked={selected}
                        />

                        {`${option?.internalName} ${
                          option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? "(" + option?.addressLine1 + "," + " "
                            : ""
                        } ${option?.city ? option?.city + ")" : ""}`}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("SelectLocation")}
                        {...params}
                        required
                        error={locationRequired}
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                </Grid>

                <Grid
                  item
                  xs={5}
                  sm={6}
                  md={12}
                  // md={2.75}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography className={classes.dateTimeTypo}>
                    {t("Select_Date_Time")}
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      /*  sx={{
                        // width: "320px",
                        border: "1px solid #E0E0E0",
                        width: "320px",
                      }} */
                      value={startDate !== "" ? dayjs(startDate) : null}
                      format={`${format} hh:mm A`}
                      // value={moment(startDate).format("D/MM/YYYY")}
                      classes={{
                        root: classes.root,
                      }}
                      disablePast
                      minDateTime={dayjs(currentDate)}
                      onChange={(e) => {
                        const currentDate = new Date();
                        const selectedDateTime = new Date(e);
                        const format = "dd/MM/yyyy hh:mm aa";
                        const isValid =
                          e !== "" &&
                          selectedDateTime >= currentDate &&
                          isValidDateFormat(e, format);

                        setValidDate(isValid);
                        setStartDate(e);
                        // setSelectedDateTime(selectedDateTime);
                        // setStartDateRequired(false);
                        if (repeat !== "no") {
                          if (endDate !== "") {
                            if (new Date(dayjs(endDate)) > new Date(e)) {
                              setEndDateGreater(false);
                            } else {
                              setEndDateGreater(true);
                            }
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ background: "#FFFF" }}
                          helperText={!ValidDate && "Invalid date"}
                          {...params}
                          error={
                            !ValidDate ||
                            (startDate === null && (
                              <ErrorMessage name={endDate} />
                            ))
                          }
                        />
                      )}
                    />
                    <style>
                      {`
           .MuiDialogActions-root {
            display: none;
          }
         
        `}
                    </style>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                ></Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Grid
                    item
                    md={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "8px",
                    }}
                  >
                    <Typography className={classes.dateTimeTypo}>
                      {t("Repeat_This_Post")}
                    </Typography>
                    <FormControl>
                      <Select
                        value={repeat}
                        onChange={(e) => {
                          setRepeat(e.target.value);
                          setEndDate("");
                          if (e.target.value !== "custom") {
                            setCustomError(false);
                          }
                          if (e.target.value === "no") {
                            setEndDateGreater(false);

                            setEndDateRequired(false);
                          } else if (e.target.value === "custom") {
                            //  setSelectedPostData(data);
                            setOpenCustomModel(true);
                          } else {
                            setOpenCustomModel(false);
                            setFrequencyValue("");
                            setSelectedMonthDays([]);
                            setSelectedWeekDays([]);
                            setOccuranceValue(1);
                          }
                        }}
                        error={customError}
                        //  helperText={customError && t("Invalid_values")}
                      >
                        <MenuItem value="no">{t("No")}</MenuItem>
                        <MenuItem value="daily">{t("Daily")}</MenuItem>
                        <MenuItem value="weekly">{t("Weekly")}</MenuItem>
                        <MenuItem value="monthly">{t("Monthly")}</MenuItem>
                        <MenuItem value="yearly">{t("Yearly")}</MenuItem>
                        <MenuItem value="custom">{t("custom_text")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={6}
                    // md={2.75}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "8px",
                    }}
                  >
                    {repeat === "no" ? (
                      <> </>
                    ) : (
                      <>
                        <Grid>
                          <Typography className={classes.dateTimeTypo}>
                            {t("End_Date")}
                          </Typography>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              /*  sx={{
                                border: "1px solid #E0E0E0",
                                width: "320px",
                                background: "blue",
                              }} */
                              value={
                                endDate !== "" && endDate !== null
                                  ? dayjs(endDate)
                                  : null
                              }
                              format={`${format} hh:mm A`}
                              classes={{
                                root: classes.root,
                              }}
                              disablePast
                              minDateTime={dayjs(currentDate)}
                              onChange={(e) => {
                                if (e !== null && e !== "") {
                                  const currentDate = new Date();
                                  const selectedDateTime = new Date(e);
                                  const format = "dd/MM/yyyy hh:mm aa";
                                  const isValid =
                                    e !== "" &&
                                    selectedDateTime >= currentDate &&
                                    isValidDateFormat(e, format);

                                  setEndValidDate(isValid);

                                  // setSelectedDateTime(selectedDateTime);
                                  if (new Date(e) > new Date(startDate)) {
                                    setEndDateGreater(false);
                                  } else {
                                    setEndDateGreater(true);
                                  }
                                } else {
                                  setEndDateGreater(false);
                                  setEndValidDate(true);
                                  setEndDateRequired(false);
                                }

                                setEndDate(e);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ background: "#FFFF" }}
                                  {...params}
                                  error={
                                    !EndValidDate ||
                                    endDateGreater ||
                                    endDateRequired
                                  }
                                  helperText={
                                    (!EndValidDate || endDateGreater) &&
                                    "Invalid date"
                                  }
                                />
                              )}
                            />
                            <style>
                              {`
       .MuiDialogActions-root {
        display: none;
      }
    
    `}
                            </style>
                          </LocalizationProvider>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {/*   {customError && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ padding: "0px" }}
                    >
                      <Grid container className={classes.mainErrorCustom}>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <ErrorOutlineIcon className={classes.errorIcon} />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                          <Typography className={classes.fieldErrorCustom}>
                            {t("Invalid_values")}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <EditIcon
                            sx={{
                              color: "#FF1616",
                              // marginRight: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => setOpenCustomModel(true)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )} */}
                  {customError === false && repeat === "custom" && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid
                        container
                        // spacing={2}
                        sx={{
                          backgroundColor: "#E0F7FF",

                          borderRadius: "8px",
                          marginTop: "12px",
                          padding: "12px 8px 5px 8px",
                        }}
                      >
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <img
                            alt=""
                            src={CalendarMonthIcon}
                            // style={{ marginTop: "5px" }}
                          />
                        </Grid>
                        {frequencyValue === "monthly" && (
                          <Grid
                            item
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            // sx={{ margin: "15px" }}
                          >
                            <Typography>
                              {`${t("every")} ${
                                occuranceValue !== 0 && occuranceValue > 1
                                  ? occuranceValue
                                  : ""
                              } ${
                                occuranceValue === 1
                                  ? t("month_text")
                                  : t("months_text")
                              } ${selectedMonthDays
                                ?.map((value) => {
                                  const lastDigit = value % 10;
                                  const suffix =
                                    value === 11 || value === 12 || value === 13
                                      ? "th"
                                      : lastDigit === 1
                                      ? "st"
                                      : lastDigit === 2
                                      ? "nd"
                                      : lastDigit === 3
                                      ? "rd"
                                      : "th";

                                  return `${value}${suffix}`;
                                })
                                .join(", ")}`}
                            </Typography>
                          </Grid>
                        )}
                        {frequencyValue === "daily" && (
                          <Grid
                            item
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            // sx={{ margin: "15px" }}
                          >
                            <Typography>
                              {occuranceValue !== 0 &&
                                (occuranceValue === 1
                                  ? t("Daily")
                                  : occuranceValue > 1
                                  ? `${t("every")} ${
                                      occuranceValue ? occuranceValue : ""
                                    } ${
                                      occuranceValue > 1
                                        ? t("days_text")
                                        : t("days")
                                    } `
                                  : "")}
                            </Typography>
                          </Grid>
                        )}
                        {frequencyValue === "weekly" && (
                          <Grid
                            item
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            //  sx={{ margin: "15px" }}
                          >
                            <Typography>
                              {`${t("every")} ${
                                occuranceValue !== 0 && occuranceValue > 1
                                  ? occuranceValue
                                  : ""
                              }  ${
                                occuranceValue === 1
                                  ? t("week_text")
                                  : t("weeks_text")
                              } ${selectedWeekDays
                                ?.map((value) => {
                                  let str = `${
                                    value === 0
                                      ? t("sunday")
                                      : value === 1
                                      ? t("monday")
                                      : value === 2
                                      ? t("tuesday")
                                      : value === 3
                                      ? t("wednesday")
                                      : value === 4
                                      ? t("thursday")
                                      : value === 5
                                      ? t("friday")
                                      : t("saturday")
                                  }`;

                                  return str;
                                })
                                .join(", ")}`}
                            </Typography>
                          </Grid>
                        )}

                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <EditIcon
                            sx={{
                              color: "#06BDFF",
                              // marginRight: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => setOpenCustomModel(true)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* )} */}
                  <Grid item md={12} xs={12} sm={12}>
                    <Typography className={classes.dateTimeTypo}>
                      {t("Post_Summary")}
                    </Typography>
                    <Grid container>
                      <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5}>
                        {" "}
                        {description?.length}/1500{" "}
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5}>
                        <CustomWidthTooltip
                          // color="red"
                          title={t("Post_Ai_Tooltip")}
                        >
                          <IconButton
                            sx={{
                              width: "40px",
                              height: "10px",
                              marginBottom: "5px",
                            }}
                            onClick={handleOpenTemplateMenu}
                            disabled={
                              loctionLoadings === true
                                ? true
                                : allLocation?.results?.length > 0
                                ? false
                                : true
                            }
                          >
                            <AiLogoGrey />
                          </IconButton>
                        </CustomWidthTooltip>
                      </Grid>
                    </Grid>

                    {description?.length >= 1499 && (
                      <Typography className={classes.lengthErrorTypo}>
                        {t("text_limit")}
                      </Typography>
                    )}
                    {/*     <TextareaAutosize
                      multiline
                      style={{
                        border: "1px solid #E0E0E0",
                        fontFamily: "Segoe UI",
                        fontStyle: "Normal",
                        padding: "12px 12px 12px 12px",
                        width: "100%",
                        borderRadius: "8px",
                        color: "#1B2430",
                        height: "100px",
                        fontWeight: "400",
                        fontSize: "1rem",
                        mb: 2,
                      }}
                      // onChange={(e) => {
                      //   setDescription(e.target.value);
                      // }}
                      onChange={(e) => {
                        if (e.target?.value?.length <= 1500) {
                          setDescription(e.target.value);
                          // handleChangeDescription(e, index);
                        }
                      }}
                      // label="Post Description"
                      placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed libero odio, dignissim ut neque et, finibus sollicitudin dolor. Nunc mollis eu magna vitae faucibus. Proin ac pellentesque tortor. Etiam semper, lacus ornare posuere egestas, turpis est suscipit sapien, nec dictum velit tortor at enim."
                      value={description}
                      name="Description"
                    /> */}
                    <TextField
                      multiline
                      fullWidth
                      placeholder={t("Enter_Description")}
                      value={description}
                      name="Description"
                      onChange={(e) => {
                        if (e.target?.value?.length <= 1500) {
                          setDescription(e.target.value);
                          // handleChangeDescription(e, index);
                        }
                      }}
                    />
                    {description === "" && (
                      <Typography sx={{ color: "red" }}>
                        <ErrorMessage name="Summary" />
                      </Typography>
                    )}
                  </Grid>
                  <Box className={classes.postEditBox}>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        md={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mb: 2,
                          marginX: "8px",
                        }}
                      >
                        <Typography className={classes.googleCTATypo}>
                          {t("Google_My_CTA")}
                        </Typography>
                        <Grid container sx={{ display: "flex", width: "100%" }}>
                          <Grid item md={6}>
                            <FormControl>
                              <Select
                                sx={{ minWidth: "170px" }}
                                onChange={(e) => setPostType(e.target.value)}
                                value={postType}
                              >
                                <MenuItem value="ORDER">
                                  {t("OrderOnline")}
                                </MenuItem>
                                <MenuItem value="BOOK">{t("Book")}</MenuItem>
                                <MenuItem value="SHOP">{t("Shop")}</MenuItem>
                                <MenuItem value="LEARN_MORE">
                                  {t("Learn_More")}
                                </MenuItem>
                                <MenuItem value="SIGN_UP">
                                  {t("Sign_Up")}
                                </MenuItem>
                                <MenuItem value="CALL">{t("CallNow")}</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          {postType === "CALL" ? (
                            <> </>
                          ) : (
                            <>
                              <Grid item md={6}>
                                <TextField
                                  placeholder={t("Please_enter_Url")}
                                  // label="URL"
                                  value={url}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  error={error}
                                  helperText={error ? t("Invalid_Url") : ""}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <FormGroup sx={{ ml: 2 }}>
                      <FormControlLabel
                        onChange={(e) => handleChangeCheckButton(e)}
                        control={<Checkbox checked={isUploadPicture} />}
                        label={t("img_desc")}
                      />
                    </FormGroup>
                    <FormGroup sx={{ ml: 2 }}>
                      <FormControlLabel
                        onChange={(e) => handleChangeCheckButtonUberAll(e)}
                        control={<Checkbox checked={isUploadPictureUberAll} />}
                        label={t("img_desc_uberAll")}
                      />
                    </FormGroup>
                  </Box>
                  {displayUberAllError && isUploadPictureUberAll && (
                    <Grid container sx={{ marginBottom: "5px" }}>
                      <Grid
                        container
                        className={classes.mainContainerUberAllForCard}
                      >
                        <Grid
                          item
                          xs={0.7}
                          sm={0.7}
                          md={0.7}
                          lg={0.7}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <BootstrapTooltip
                            title={
                              <h1 style={{ fontSize: "12px" }}>
                                {t("Additional_photos_tooltipText")}
                              </h1>
                            }
                          >
                            <ErrorOutlineIcon
                              className={classes.errorIconUberAll}
                            />
                          </BootstrapTooltip>
                        </Grid>
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ display: "flex" }}
                          >
                            <Typography className={classes.errorTextUberAll}>
                              {t("uberAll_error")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={0.3}
                          sm={0.3}
                          md={0.3}
                          lg={0.3}
                          sx={{
                            textAlign: "end",
                          }}
                        >
                          <CloseIcon
                            fontSize="small"
                            onClick={() => handleClickCloseWarningCard()}
                            className={classes.closeIconUberAll}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Box className={classes.postButtonBox}>
              <CommonButton
                displayWhite="true"
                isLoading={loading}
                onSubmit={onCancel}
                label={t("Cancel_Button")}
              />
              <CommonButton
                isLoading={loading}
                onSubmit={() => {
                  {
                    startDate && updateRule();
                  }
                }}
                disabled={
                  customError
                    ? true
                    : description?.length >= 1500 ||
                      ValidDate === false ||
                      (endDate !== null &&
                        endDate !== "" &&
                        EndValidDate === false) ||
                      (endDate !== null && endDate !== "" && endDateGreater) ||
                      (endDate !== null && endDate !== "" && endDateRequired) ||
                      error
                }
                label={t("Update_Post")}
              />
            </Box>
          </Form>
        )}
      </Formik>

      {openPictureModal && (
        <Dialog
          open={openPictureModal}
          disableBackdropClick
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px", // Set your width here
                borderRadius: "8px",
              },
            },
          }}
          TransitionComponent={Transition}
          keepMounted
          //  onClose={handleCloseNavigate}
          fullWidth
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {selectedImages?.length > 0 && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <DialogTitle
                  sx={{
                    color: "#1B2430",
                    fontSize: "20px",
                    marginBottom: "20px",
                    fontWeight: "700",
                  }}
                  id="alert-dialog-slide-title"
                >
                  {t("Uploading_Images")}
                </DialogTitle>
                <IconButton
                  onClick={() => handleClose()}
                  aria-label="save"
                  hover="none"
                  sx={{
                    hover: "none",
                    marginBottom: "15px",
                    marginRight: "22px",
                  }}
                >
                  X
                </IconButton>
              </Stack>

              <DialogContent>
                <Stack direction="column">
                  <Item>
                    {selectedImages && (
                      <>
                        {selectedImages?.slice(0, 1)?.map((img, index) => {
                          return (
                            <PictureModalCard
                              img={img}
                              handleDelete={handleDelete}
                              locationId={locationId}
                              setPostData={setEditUploadedImage}
                              postData={editUploadedImage}
                              handleClose={handleClose}
                              index={index}
                              isUploading={isUploading}
                              setIsUploading={setIsUploading}
                              afterUploadSelectedChange={(key) =>
                                afterUploadSelectedChange(index, key)
                              }
                              handleImageError={handleImageError}
                              handleClickCloseWarning={handleClickCloseWarning}
                            />
                          );
                        })}
                      </>
                    )}
                  </Item>

                  <Divider />
                  <Item
                    sx={{
                      paddingTop: "24px",
                      display: "flex",
                      justifyContent: "flex-end",
                      flex: 1,
                    }}
                  >
                    {selectedImages?.length > 0 && (
                      <>
                        <Button
                          sx={{
                            fontWeight: "700",
                            size: "large",
                            color: "#1B2430",
                            borderRadius: "8px",
                            padding: " 14px 20px",
                            border: "1px solid #E0E0E0",
                            hover: "none",
                          }}
                          variant="outlined"
                          // color="#1B2430"

                          onClick={() => handleClose()}
                        >
                          {t("Cancel")}
                        </Button>
                        &nbsp; &nbsp;
                        {/* <CommonButton
                          disabled={
                            selectedImages.find((item) => item?.error===true)
                              ? true
                              : editImage?.length===selectedImages?.length ||
                                !isUploading
                              ? false
                              : true
                          }
                          onSubmit={
                            editImage.length === selectedImages.length
                              ? uploadImages
                              : setIsUploading(true)
                          }
                          label={
                            editImage?.length === selectedImages?.length
                              ? t("Submit")
                              : t("Upload")
                          }
                        /> */}
                        <CommonButton
                          disabled={
                            selectedImages.find((item) => item?.error === true)
                              ? true
                              : editUploadedImage?.length ===
                                  selectedImages?.length || !isUploading
                              ? false
                              : true
                          }
                          onSubmit={() =>
                            editUploadedImage?.length === selectedImages?.length
                              ? handleSubmitPicture()
                              : setIsUploading(true)
                          }
                          loading={submitLoading}
                          label={
                            editUploadedImage?.length === selectedImages?.length
                              ? t("Submit")
                              : t("Upload")
                          }
                        />
                      </>
                    )}
                  </Item>
                </Stack>
              </DialogContent>
            </>
          )}

          <DialogActions>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
            ></Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default EditPosts;
