import React, { useEffect, useState } from "react";
import { Paper, Typography, Grid, CircularProgress } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import {
  arrWithLodging,
  arrWithoutLodging,
} from "../../../../assets/ProfilePercentageArray";
import Loader from "../../../../components/Loaders/Loader";

const AdminOverview = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [hour, setHour] = useState(null);
  const [totalLocations, setTotalLocations] = useState(0);
  const [currentLocation, setCurrentLocation] = useState(null);

  const [remainingLocations, setRemainingLocations] = useState(0);
  const [allLocations, setAllLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lodgingCategories, setLodgingCategories] = useState([]);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [profilePercentage, setProfilePercentage] = useState("0");
  const [loading] = useState(false);

  // useEffect(() => {
  //   getLodgingCategories();
  //   getAllLocation();
  // }, []);

  // useEffect(() => {
  //   if (allLocations?.length > 0) {
  //     processLocations();
  //   }
  // }, [allLocations]);

  async function processLocations() {
    setIsLoading(true);
    for (let index = 0; index < allLocations.length; index++) {
      let item = allLocations[index];
      let currentIndex = index + 1;
      setCurrentLocation(item);
      setRemainingLocations(totalLocations - currentIndex);
      if (index + 1 === totalLocations) {
        setIsLoading(false);
        setDisplayMessage(true);
      }
      try {
        const response = await api.get(`locationListing/${item.id}`);

        if (response.status === 200) {
          let locationDetailsData = response?.data?.data;

          let arrFinal = [];
          let finalArr = [];
          if (locationDetailsData !== null && lodgingCategories?.length > 0) {
            if (
              locationDetailsData?.result?.uberall?.socialProfiles?.length > 0
            ) {
              let obj = {
                name: t("Social_Accounts"),
                value: "socialProfiles",
                percentageValue: 5,
              };
              finalArr.push(obj);
            } else {
              finalArr = finalArr?.filter(
                (item) => item?.value !== "socialProfiles"
              );
            }

            if (
              locationDetailsData?.result?.uberall?.brands?.length > 0 ||
              locationDetailsData?.result?.uberall?.services?.length > 0
            ) {
              let obj = {
                name: t("Services_&_Brands"),
                value: "brands",
                percentageValue: 5,
              };
              finalArr.push(obj);
            }
            if (
              item?.isLodgingEnabled === true &&
              lodgingCategories.includes(
                locationDetailsData?.result?.google?.categories?.primaryCategory
                  ?.name
              )
            ) {
              {
              }
              arrWithLodging.forEach((itemTwo) => {
                Object.keys(locationDetailsData?.result?.google).map((item) => {
                  if (item === "categories") {
                    Object.keys(
                      locationDetailsData?.result?.google?.categories
                    ).map((itemThree) => {
                      if (
                        itemThree === "additionalCategories" &&
                        itemTwo?.value === "additionalCategories"
                      ) {
                        if (
                          locationDetailsData?.result?.google?.categories[
                            `${itemThree}`
                          ]?.length > 0
                        ) {
                          let obj = {
                            name: itemTwo?.name,
                            value: itemThree,
                            percentageValue: itemTwo?.percentageValue,
                          };
                          finalArr.push(obj);
                        }
                      }
                    });
                  } else if (item === "media") {
                    if (item === "media" && itemTwo?.value === "media") {
                      if (
                        locationDetailsData?.result?.google?.media?.length > 0
                      ) {
                        const imageFound =
                          locationDetailsData?.result?.google?.media?.filter(
                            (item) => item?.isDeleted === false
                          );

                        if (imageFound?.length > 0) {
                          if (
                            imageFound?.length === 1 &&
                            imageFound[0]?.category === "AT_WORK"
                          ) {
                          } else {
                            if (imageFound?.length > 0) {
                              let obj = {
                                name: itemTwo?.name,
                                value: item,
                                percentageValue: itemTwo?.percentageValue,
                              };
                              finalArr.push(obj);
                            }
                          }
                        }
                      }
                    }
                  } else if (item === "phoneNumbers") {
                    locationDetailsData?.result?.google?.phoneNumbers !==
                      null &&
                      Object.keys(
                        locationDetailsData?.result?.google?.phoneNumbers
                      )?.length > 0 &&
                      Object.keys(
                        locationDetailsData?.result?.google?.phoneNumbers
                      ).map((itemThree) => {
                        if (
                          itemThree === "primaryPhone" &&
                          itemTwo?.value === "primaryPhone"
                        ) {
                          if (
                            locationDetailsData?.result?.google?.phoneNumbers[
                              `${itemThree}`
                            ] !== null &&
                            locationDetailsData?.result?.google?.phoneNumbers[
                              `${itemThree}`
                            ] !== "" &&
                            locationDetailsData?.result?.google?.phoneNumbers[
                              `${itemThree}`
                            ] !== undefined
                          ) {
                            let obj = {
                              name: itemTwo?.name,
                              value: itemThree,
                              percentageValue: itemTwo?.percentageValue,
                            };

                            finalArr.push(obj);
                          }
                        }
                      });
                  } else if (
                    item === "serviceArea" &&
                    itemTwo?.value === "serviceArea"
                  ) {
                    if (
                      locationDetailsData?.result?.google[`${item}`] !== null &&
                      locationDetailsData?.result?.google[`${item}`]?.places
                        ?.placeInfos.length > 0
                    ) {
                      let obj = {
                        name: itemTwo?.name,
                        value: item,
                        percentageValue: itemTwo?.percentageValue,
                      };
                      finalArr.push(obj);
                    }
                  } else if (item === itemTwo?.value) {
                    if (item?.isLodgingEnabled === false) {
                      if (
                        Array.isArray(
                          locationDetailsData?.result?.google[`${item}`]
                        )
                      ) {
                        if (
                          locationDetailsData?.result?.google[`${item}`]
                            ?.length > 0
                        ) {
                          let obj = {
                            name: itemTwo?.name,
                            value: item,
                            percentageValue: itemTwo?.percentageValue,
                          };
                          finalArr.push(obj);
                        }
                      } else if (
                        locationDetailsData?.result?.google[`${item}`] !== "" &&
                        locationDetailsData?.result?.google[`${item}`] !==
                          null &&
                        locationDetailsData?.result?.google[`${item}`] !==
                          undefined
                      ) {
                        let obj = {
                          name: itemTwo?.name,
                          value: item,
                          percentageValue: itemTwo?.percentageValue,
                        };
                        finalArr.push(obj);
                      }
                    } else {
                      if (itemTwo?.value === "attributes") {
                        let lodgingArr = [];

                        if (
                          locationDetailsData?.result?.google?.lodging !==
                            undefined &&
                          locationDetailsData?.result?.google?.lodging !== null
                        ) {
                          Object.entries(
                            locationDetailsData?.result?.google?.lodging
                          )?.map(([keyOne, valueOne]) => {
                            if (
                              keyOne !== "updateMask" &&
                              keyOne !== "name" &&
                              keyOne !== "metadata"
                            ) {
                              Object.entries(valueOne)?.map(
                                ([keyTwo, valueTwo]) => {
                                  if (valueTwo?.hasOwnProperty("type")) {
                                    if (valueTwo?.value !== null) {
                                      let objOne = {
                                        name: keyTwo,
                                        type: valueTwo?.type,
                                        value: valueTwo?.value,
                                      };
                                      lodgingArr.push(objOne);
                                    }
                                  } else if (
                                    valueTwo?.hasOwnProperty("hours")
                                  ) {
                                    if (
                                      valueTwo?.hours?.value !== null &&
                                      valueTwo?.minutes?.value !== null
                                    ) {
                                      let timeObj = {
                                        name: keyTwo,
                                        type: "time",
                                        value: {
                                          hours: valueTwo?.hours?.value,
                                          minutes: valueTwo?.minutes?.value,
                                        },
                                      };
                                      lodgingArr.push(timeObj);
                                    }
                                  } else if (Array.isArray(valueTwo)) {
                                    if (keyTwo === "codes") {
                                      let index = 0;
                                      if (valueTwo[0]?.value !== null) {
                                        let obj = {
                                          name: keyTwo,
                                          type: valueTwo[0]?.type,
                                          value: valueTwo[0]?.value,
                                        };
                                        lodgingArr.push(obj);
                                      }
                                    } else if (keyTwo === "languagesSpoken") {
                                      let obj = {
                                        name: keyTwo,
                                        type: "string",
                                        value: valueTwo,
                                      };
                                      lodgingArr.push(obj);
                                    } else {
                                      valueTwo?.map((dataItem) => {
                                        Object.entries(dataItem)?.map(
                                          ([keyThree, valueThree]) => {
                                            if (
                                              valueThree?.hasOwnProperty("type")
                                            ) {
                                              if (valueThree?.value !== null) {
                                                let obj = {
                                                  name: keyThree,
                                                  type: valueThree?.type,
                                                  value: valueThree?.value,
                                                };
                                                lodgingArr.push(obj);
                                              }
                                            }
                                          }
                                        );
                                      });
                                    }
                                  } else {
                                    valueTwo &&
                                      Object.keys(valueTwo)?.length > 0 &&
                                      Object.keys(valueTwo) !== null &&
                                      Object.entries(valueTwo)?.map(
                                        ([keyThree, valueThree]) => {
                                          if (
                                            valueThree?.hasOwnProperty(
                                              "type"
                                            ) ||
                                            valueThree?.hasOwnProperty("hours")
                                          ) {
                                            if (valueThree?.value !== null) {
                                              let objThree = {
                                                name: keyThree,
                                                type: valueThree?.type,
                                                value: valueThree?.value,
                                              };
                                              lodgingArr.push(objThree);
                                            }
                                          } else {
                                            if (Array.isArray(valueThree)) {
                                              if (
                                                keyThree === "ecoCertifications"
                                              ) {
                                                valueThree?.map((dataItem) => {
                                                  Object.entries(dataItem)?.map(
                                                    ([keyFour, valueFour]) => {
                                                      if (
                                                        valueFour?.hasOwnProperty(
                                                          "type"
                                                        )
                                                      ) {
                                                        if (
                                                          valueFour?.value !==
                                                          null
                                                        ) {
                                                          let obj = {
                                                            name: keyFour,
                                                            type: valueFour?.type,
                                                            value:
                                                              valueFour?.value,
                                                          };
                                                          lodgingArr.push(obj);
                                                        }
                                                      }
                                                    }
                                                  );
                                                });
                                              }
                                            } else {
                                              valueThree &&
                                                Object.entries(valueThree)?.map(
                                                  ([keyFour, valueFour]) => {
                                                    if (
                                                      valueFour?.hasOwnProperty(
                                                        "type"
                                                      )
                                                    ) {
                                                      // let index = 0;
                                                      if (
                                                        valueFour?.value !==
                                                        null
                                                      ) {
                                                        let obj = {
                                                          name: keyFour,
                                                          type: valueFour?.type,
                                                          value:
                                                            valueFour?.value,
                                                        };
                                                        lodgingArr.push(obj);
                                                      }
                                                    } else {
                                                      if (
                                                        Array.isArray(valueFour)
                                                      ) {
                                                        let index = 0;
                                                        valueFour?.map(
                                                          (itemFour) => {
                                                            Object.entries(
                                                              itemFour
                                                            ).map(
                                                              ([
                                                                keyFive,
                                                                valueFive,
                                                              ]) => {
                                                                if (
                                                                  valueFive?.value !==
                                                                  null
                                                                ) {
                                                                  let obj = {
                                                                    name: keyFive,
                                                                    type: valueFive?.type,
                                                                    value:
                                                                      valueFive?.value,
                                                                  };
                                                                  lodgingArr.push(
                                                                    obj
                                                                  );
                                                                }
                                                              }
                                                            );
                                                          }
                                                        );
                                                      } else {
                                                        valueFour &&
                                                          Object.entries(
                                                            valueFour
                                                          )?.map(
                                                            ([
                                                              keyFive,
                                                              valueFive,
                                                            ]) => {
                                                              if (
                                                                valueFive?.hasOwnProperty(
                                                                  "type"
                                                                )
                                                              ) {
                                                                if (
                                                                  valueFive?.value !==
                                                                  null
                                                                ) {
                                                                  let obj = {
                                                                    name: keyFive,
                                                                    type: valueFive?.type,
                                                                    value:
                                                                      valueFive?.value,
                                                                  };
                                                                  lodgingArr.push(
                                                                    obj
                                                                  );
                                                                }
                                                              } else {
                                                                valueFive &&
                                                                  Object.entries(
                                                                    valueFive
                                                                  )?.map(
                                                                    ([
                                                                      keySix,
                                                                      valueSix,
                                                                    ]) => {
                                                                      if (
                                                                        valueSix?.value !==
                                                                        null
                                                                      ) {
                                                                        let obj =
                                                                          {
                                                                            name: keySix,
                                                                            type: valueSix?.type,
                                                                            value:
                                                                              valueSix?.value,
                                                                          };
                                                                        lodgingArr.push(
                                                                          obj
                                                                        );
                                                                      }
                                                                    }
                                                                  );
                                                              }
                                                            }
                                                          );
                                                      }
                                                    }
                                                  }
                                                );
                                            }
                                          }
                                        }
                                      );
                                  }
                                }
                              );
                            }
                          });

                          if (lodgingArr?.length > 0) {
                            if (lodgingArr?.length === 1) {
                              const languageFound =
                                lodgingArr[0]?.value?.filter(
                                  (item) => item?.spoken?.value !== null
                                );
                              if (languageFound?.length > 0) {
                                let obj = {
                                  name: itemTwo?.name,
                                  value: item,
                                  percentageValue: itemTwo?.percentageValue,
                                };
                                finalArr.push(obj);
                              }
                            } else {
                              let obj = {
                                name: itemTwo?.name,
                                value: item,
                                percentageValue: itemTwo?.percentageValue,
                              };
                              finalArr.push(obj);
                            }
                          }
                        }
                      } else if (
                        locationDetailsData?.result?.google[`${item}`] !== "" &&
                        locationDetailsData?.result?.google[`${item}`] !==
                          null &&
                        locationDetailsData?.result?.google[`${item}`] !==
                          undefined
                      ) {
                        let obj = {
                          name: itemTwo?.name,
                          value: item,
                          percentageValue: itemTwo?.percentageValue,
                        };
                        finalArr.push(obj);
                      }
                    }

                    // finalArr.push(item);
                  }
                });
                arrFinal = finalArr;
              });
            } else {
              {
              }
              arrWithoutLodging.forEach((itemTwo) => {
                locationDetailsData?.result?.google &&
                  Object.keys(locationDetailsData?.result?.google).map(
                    (item) => {
                      if (item === "categories") {
                        Object.keys(
                          locationDetailsData?.result?.google?.categories
                        ).map((itemThree) => {
                          if (
                            itemThree === "additionalCategories" &&
                            itemTwo?.value === "additionalCategories"
                          ) {
                            if (
                              locationDetailsData?.result?.google?.categories[
                                `${itemThree}`
                              ]?.length > 0
                            ) {
                              let obj = {
                                name: itemTwo?.name,
                                value: itemThree,
                                percentageValue: itemTwo?.percentageValue,
                              };
                              finalArr.push(obj);
                            }
                          }
                        });
                      } else if (item === "media") {
                        if (item === "media" && itemTwo?.value === "media") {
                          if (
                            locationDetailsData?.result?.google?.media?.length >
                            0
                          ) {
                            const imageFound =
                              locationDetailsData?.result?.google?.media?.filter(
                                (item) => item?.isDeleted === false
                              );

                            if (imageFound?.length > 0) {
                              if (
                                imageFound?.length === 1 &&
                                imageFound[0]?.category === "AT_WORK"
                              ) {
                              } else {
                                if (imageFound?.length > 0) {
                                  let obj = {
                                    name: itemTwo?.name,
                                    value: item,
                                    percentageValue: itemTwo?.percentageValue,
                                  };
                                  finalArr.push(obj);
                                }
                              }
                            }
                          }
                        }
                      } else if (item === "profile") {
                        locationDetailsData?.result?.google?.profile !== null &&
                          Object.keys(
                            locationDetailsData?.result?.google?.profile
                          ).map((itemThree) => {
                            if (
                              itemThree === "description" &&
                              itemTwo?.value === "description"
                            ) {
                              if (
                                locationDetailsData?.result?.google?.profile[
                                  `${itemThree}`
                                ] !== null &&
                                locationDetailsData?.result?.google?.profile[
                                  `${itemThree}`
                                ] !== "" &&
                                locationDetailsData?.result?.google?.profile[
                                  `${itemThree}`
                                ] !== undefined
                              ) {
                                let obj = {
                                  name: itemTwo?.name,
                                  value: itemThree,
                                  percentageValue: itemTwo?.percentageValue,
                                };
                                finalArr.push(obj);
                              }
                            }
                          });
                      } else if (item === "phoneNumbers") {
                        locationDetailsData?.result?.google?.phoneNumbers !==
                          null &&
                          Object.keys(
                            locationDetailsData?.result?.google?.phoneNumbers
                          )?.length > 0 &&
                          Object.keys(
                            locationDetailsData?.result?.google?.phoneNumbers
                          ).map((itemThree) => {
                            if (
                              itemThree === "primaryPhone" &&
                              itemTwo?.value === "primaryPhone"
                            ) {
                              if (
                                locationDetailsData?.result?.google
                                  ?.phoneNumbers[`${itemThree}`] !== null &&
                                locationDetailsData?.result?.google
                                  ?.phoneNumbers[`${itemThree}`] !== "" &&
                                locationDetailsData?.result?.google
                                  ?.phoneNumbers[`${itemThree}`] !== undefined
                              ) {
                                let obj = {
                                  name: itemTwo?.name,
                                  value: itemThree,
                                  percentageValue: itemTwo?.percentageValue,
                                };

                                finalArr.push(obj);
                              }
                            }
                          });
                      } else if (
                        item === "serviceArea" &&
                        itemTwo?.value === "serviceArea"
                      ) {
                        if (
                          locationDetailsData?.result?.google[`${item}`] !==
                            null &&
                          locationDetailsData?.result?.google[`${item}`]?.places
                            ?.placeInfos.length > 0
                        ) {
                          let obj = {
                            name: itemTwo?.name,
                            value: item,
                            percentageValue: itemTwo?.percentageValue,
                          };
                          finalArr.push(obj);
                        }
                      } else if (item === itemTwo?.value) {
                        if (
                          itemTwo?.value === "attributes" &&
                          Array.isArray(
                            locationDetailsData?.result?.google[`${item}`]
                          )
                        ) {
                          if (
                            locationDetailsData?.result?.google[`${item}`]
                              ?.length > 0
                          ) {
                            let obj = {
                              name: itemTwo?.name,
                              value: item,
                              percentageValue: itemTwo?.percentageValue,
                            };
                            finalArr.push(obj);
                          }
                        } else if (
                          locationDetailsData?.result?.google[`${item}`] !==
                            "" &&
                          locationDetailsData?.result?.google[`${item}`] !==
                            null &&
                          locationDetailsData?.result?.google[`${item}`] !==
                            undefined
                        ) {
                          let obj = {
                            name: itemTwo?.name,
                            value: item,
                            percentageValue: itemTwo?.percentageValue,
                          };
                          finalArr.push(obj);
                        }

                        // finalArr.push(item);
                      }
                    }
                  );
                arrFinal = finalArr;
              });
            }

            let arr2 = [];
            arrFinal.forEach((item) => {
              let isFound = false;
              arr2.forEach((item2) => {
                if (item.value === item2.value) {
                  isFound = true;
                }
              });
              if (!isFound) {
                //  timesArr.push(item);
                arr2.push(item);
              }
            });
            if (locationDetailsData?.result?.keywords) {
              if (
                locationDetailsData?.result?.keywords &&
                locationDetailsData?.result?.keywords?.length > 0
              ) {
                if (
                  item?.isLodgingEnabled === true &&
                  lodgingCategories.includes(
                    locationDetailsData?.result?.google?.categories
                      ?.primaryCategory?.name
                  )
                ) {
                  let obj = {
                    name: t("Keywords"),
                    value: "keywords",
                    percentageValue: 15,
                  };

                  arr2.push(obj);
                } else {
                  let obj = {
                    name: t("Keywords"),
                    value: "keywords",
                    percentageValue: 10,
                  };

                  arr2.push(obj);
                }
              }
            }
            /*  if (
              item?.isLodgingEnabled===true &&
              lodgingCategories.includes(
                locationDetailsData?.result?.google?.categories?.primaryCategory
                  ?.name
              )
            ) {
              const results = arrWithLodging.filter(
                ({ name: id1 }) => !arr2.some(({ name: id2 }) => id2 === id1)
              );
              //  setPercentageArr(results);
            } else {
              const results = arrWithoutLodging.filter(
                ({ name: id1 }) => !arr2.some(({ name: id2 }) => id2 === id1)
              );

              // setPercentageArr(results);
            } */

            if (arr2?.length > 0) {
              let totalSum = 0;

              let finalArray = [];

              finalArray = [...arr2];

              finalArray?.forEach((item) => {
                totalSum = totalSum + item?.percentageValue;
              });

              const percentage = ((totalSum / 100) * 100).toFixed();

              try {
                const res = await api.patch(`/location/${item?.id}`, {
                  profileCompletition: Number(percentage),
                });
              } catch (error) {
                console.log(error);
              }

              setProfilePercentage(percentage);
            }
          }
        }
      } catch (error) {
        setProfilePercentage("0");
        /*     const res = await api.patch(`/location/${item?.id}`, {
          profileCompletition: "0",
        });
        setProfilePercentage("0"); */
      }
    }
  }

  const getLodgingCategories = async () => {
    try {
      const response = await api.get(`lodging/categories`);
      if (response.status === 200) {
        setLodgingCategories(response?.data?.data);
      }
    } catch (error) {}
  };

  const getAllLocation = async () => {
    setLocationLoading(true);
    try {
      const res = await api.get(`/location/search?status=approved`);
      if (res.status === 200) {
        setTotalLocations(res?.data?.data?.count);
        setAllLocations(res?.data?.data?.results);
        setLocationLoading(false);
      }
    } catch (error) {
      setLocationLoading(false);
      // setIsLoading(false);
    }
  };

  const getHour = () => {
    const date = new Date();
    const hours = date.getHours();
    setHour(hours);
  };

  return (
    <>
      <Typography
        fontWeight={700}
        fontSize="24px"
        lineHeight="32px"
        sx={{ textTransform: "capitalize" }}
      >
        {hour < 12 ? `${t("Good_Morning")}` : `${t("Good_Evening")}`}{" "}
        {user?.firstName !== null &&
        user?.firstName !== "" &&
        user?.lastName !== null &&
        user?.lastName !== ""
          ? `, ${user?.firstName} ${user?.lastName}`
          : ""}
        👋
      </Typography>
      <Typography
        fontWeight={400}
        fontSize="14px"
        lineHeight="20px"
        // variant="subtitle1"
        color="#495059"
        sx={{ marginBottom: "0.5rem" }}
      >
        {t("sub_title")}
      </Typography>

      {/* <Paper sx={{ padding: "24px", marginTop: "24px" }}>
        {locationLoading ? (
          <Grid sx={{ height: "300px" }}>
            <Loader />
          </Grid>
        ) : (
          <Grid container sx={{ height: "300px" }}>
            {displayMessage===false && (
              <Grid xs={12} sm={12} md={12} lg={12} sx={{ display: "flex" }}>
                <Typography
                  fontWeight={700}
                  fontSize="24px"
                  lineHeight="32px"
                  sx={{ textTransform: "capitalize" }}
                >
                  Updating locations profile percentage
                </Typography>
                {isLoading && (
                  <CircularProgress
                    color="secondary"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "8px",
                      marginTop: "5px",
                    }}
                  />
                )}
              </Grid>
            )}
            {displayMessage===false ? (
              <>
                <Grid xs={12} sm={12} md={12} lg={12} sx={{ display: "flex" }}>
                  <Typography
                    fontWeight={700}
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ textTransform: "capitalize" }}
                  >
                    Total Locations:
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ textTransform: "capitalize", marginLeft: "8px" }}
                  >
                    {totalLocations}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} sx={{ display: "flex" }}>
                  <Typography
                    fontWeight={700}
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ textTransform: "capitalize" }}
                  >
                    Current Location:
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ textTransform: "capitalize", marginLeft: "8px" }}
                  >
                    {currentLocation !== null
                      ? " " + currentLocation?.internalName
                      : ""}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} sx={{ display: "flex" }}>
                  <Typography
                    fontWeight={700}
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ textTransform: "capitalize" }}
                  >
                    Company Name:
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ textTransform: "capitalize", marginLeft: "8px" }}
                  >
                    {currentLocation !== null &&
                    currentLocation?.company !== null
                      ? currentLocation?.company?.name
                      : ""}
                  </Typography>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={12} sx={{ display: "flex" }}>
                  <Typography
                    fontWeight={700}
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ textTransform: "capitalize" }}
                  >
                    Profile Percentange:
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ textTransform: "capitalize", marginLeft: "8px" }}
                  >
                    {" " + profilePercentage + "%"}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} sx={{ display: "flex" }}>
                  <Typography
                    fontWeight={700}
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ textTransform: "capitalize" }}
                  >
                    Remaining Locations:
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ textTransform: "capitalize", marginLeft: "8px" }}
                  >
                    {" " + remainingLocations}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <Typography
                  fontWeight={700}
                  fontSize="24px"
                  lineHeight="32px"
                  sx={{ textTransform: "capitalize" }}
                >
                  Profile percentage updated successfully for all locations !!!
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Paper> */}

      {/* <Typography variant="h4">{`${user?.firstName} ${user?.lastName}  has role ${user.role}`}</Typography> */}
    </>
  );
};

export default AdminOverview;
