import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  tooltipClasses,
  Typography,
  Paper,
  TextField,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CommonButton from "../../CustomComponents/Buttons/CommonButton.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components/macro";
import Collapsible from "../../CustomComponents/Collapsible.js";
import DatePickerComponent from "../../CustomComponents/DateRangePicker/RangePicker.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "dayjs";
import { useStyles } from "./style";
import { languagesData } from "../../../assets/defaultLanguage/index.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate } from "react-router-dom";
import useDateFormat from "../../../hooks/useDateFormat.js";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "30px",
  padding: "6px",
  paddingRight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "800px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
}));
const SmallCheckbox = styled(Checkbox)({
  // padding: "0px 12px 0px 0px",
  width: "12px",
  height: "12px",
  "& .MuiSvgIcon-root": {
    fontSize: "16px", // Adjust the size of the icon inside the checkbox
  },
});

const FilterModal = ({
  handleCloseModalFilter,
  openModalFilter,
  resetHandler,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeSentiment,
  sentimentValue,
  startDate,
  endDate,
  dateRequired,
  allLocations,
  handleChangeLocation,
  handleChangeRange,
  range,
  dateValues,
  locations,
  handleChangeLanguage,
  language,
  allGroups,
  selectedGroups,
  handleChangeGroups,
  setSelectedGroups,
  setLocations,
  selectAllLocations,
  setSelectAllLocations,
  setSelectAll,
  selectAll,
  setCallApi,
  countries,
  handleChangeGroupsForLocations,
  nextEndDate,
  nextStartDate,
  compareRange,
  resetStart,
  resetEnd,
  resetNextStart,
  resetNextEnd,
  locationAddPermission,
  locationViewPermission,
  defaultLocation,
  autoSelectHeader,
  isSentimentalAnalysisDone,
  setAutoSelectHeader,
  handleOpenNewRequestModel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorBulk, setAnchorBulk] = useState(null);
  const [isSorted, setIsSorted] = useState("");
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [checkedLocation, setCheckedLocation] = useState(false);
  const [section, setSection] = useState("locations");
  const [autoSelect, setAutoSelect] = useState(autoSelectHeader);
  const [dateRange, setDateRange] = React.useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  });
  const [modellocations, setModelLocations] = useState(locations);
  const [startDateModel, setStartDateModel] = useState(startDate);
  const [endDateModel, setEndDateModel] = useState(endDate);
  const [nextStartDateModel, setNextStartDateModel] = useState(nextStartDate);
  const [nextEndDateModel, setnextEndDateModel] = useState(nextEndDate);
  const [selectedGroupsModel, setSelectedGroupsModel] =
    useState(selectedGroups);
  const [sentimentValueModel, setSentimentValueModel] =
    React.useState(sentimentValue);
  const [languageModel, setLanguageModel] = useState(language);
  const [oldGroupsModel, setOldGroupsModel] = useState([]);
  const [rangeModel, setRangeModel] = useState({
    label: `${t("In_Range")}`,
    value: "between",
  });
  const [loading, setLoading] = useState(false);
  const [expandAllPanels, setExpandAllPanels] = useState(true);
  const [selectAllFeatures, setSelectAllFeatures] =
    useState(selectAllLocations);
  const [selectAllGroups, setSelectAllGroups] = useState(selectAll);
  const { format } = useDateFormat();

  useEffect(() => {
    if (openModalFilter) {
      setModelLocations(locations);
    }
  }, [openModalFilter]);

  const sentimentsValues = [
    {
      label: `${t("positive")}`,
      value: "Positive",
    },
    {
      label: `${t("negative")}`,
      value: "Negative",
    },
    {
      label: `${t("neutral")}`,
      value: "Neutral",
    },
  ];

  useEffect(() => {
    if (startDateModel !== null && endDateModel !== null) {
      let startDateOnee = dayjs(startDateModel);
      const startDateOne = startDateOnee.subtract(1, "days");
      const endDateOne = dayjs(endDateModel);
      // const endDateOne = endDateOnee.subtract(1, "days");
      const today = dayjs();

      if (
        (rangeModel !== null && rangeModel?.value === "gt") ||
        (compareRange !== null && compareRange?.value === "gt")
      ) {
        const difference = startDateOnee.diff(today);

        const diffInMonths = monthDiff(startDateOnee, today);

        if (diffInMonths === 12 || diffInMonths > 12) {
          const newStartDate = startDateOne.subtract(1, "year");
          setNextStartDateModel(newStartDate.toDate());
        } else {
          const diffInDays = today.diff(startDateOnee, "days");

          if (diffInMonths < 12 || diffInDays < 30) {
            if (diffInMonths === 0 && diffInDays < 30) {
              const newStartDate = startDateOne.subtract(diffInDays, "days");
              setNextStartDateModel(newStartDate.toDate());
            } else {
              const newStartDate = startDateOne.subtract(
                diffInMonths,
                "months"
              );
              setNextStartDateModel(newStartDate.toDate());
            }
          }
        }

        setnextEndDateModel(startDateOnee.toDate());
      } else if (
        (rangeModel !== null && rangeModel?.value === "lt") ||
        (compareRange !== null && compareRange?.value === "lt")
      ) {
        const diff = monthDiff(endDateOne, today);

        if (diff === 12 || diff > 12) {
          const newEndDate = endDateOne.add(1, "year");
          setnextEndDateModel(newEndDate.toDate());
        } else {
          setnextEndDateModel(today.toDate());
        }

        setNextStartDateModel(endDateOne.toDate());
      } else {
        const diffInMonths = monthDiff(startDateOnee, endDateOne);
        const diffInDays = endDateOne.diff(startDateOnee, "days");
        if (diffInMonths < 12 || diffInDays > 0) {
          if (diffInDays > 0) {
            const newStartDate = startDateOne.subtract(diffInDays, "days");
            setNextStartDateModel(newStartDate.toDate());
            setnextEndDateModel(startDateOne.endOf("day").toDate());
          } else {
            const newStartDate = startDateOne.subtract(diffInMonths, "months");

            setNextStartDateModel(newStartDate.toDate());
            setnextEndDateModel(startDateOnee.toDate());
          }
        } else {
          const yearsToSubtract = Math.floor(diffInMonths / 12);
          const remainingMonths = diffInMonths % 12;

          const newStartDate = startDateOne
            .subtract(yearsToSubtract, "year")
            .subtract(remainingMonths, "months");
          setNextStartDateModel(newStartDate.toDate());
          setnextEndDateModel(startDateOnee.toDate());
        }
      }
    }
  }, [startDateModel, endDateModel]);

  useEffect(() => {
    if (selectAllGroups) {
      handleExpandPanel(undefined, true, "groups");
    }
  }, [selectAllGroups]);

  function monthDiff(dateFrom, dateTo) {
    // Convert dayjs objects to native Date objects
    const fromDate = dateFrom instanceof dayjs ? dateFrom.toDate() : dateFrom;
    const toDate = dateTo instanceof dayjs ? dateTo.toDate() : dateTo;

    return (
      toDate.getMonth() -
      fromDate.getMonth() +
      12 * (toDate.getFullYear() - fromDate.getFullYear())
    );
  }

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorBulk(null);
  };
  const handleSorted = (arr, ele) => {
    if (isSorted === "asc") {
      const sortedArr = arr.sort((a, b) => a[ele].localeCompare(b[ele]));
      return sortedArr;
    } else if (isSorted === "desc") {
      const sortedArr = arr.sort((a, b) => b[ele].localeCompare(a[ele]));
      return sortedArr;
    } else {
      return arr;
    }
  };

  const handleClickMenu = (event) => {
    setAnchorBulk(event.currentTarget);
  };

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }

    let uniqueArray = [...new Set(_expandedPanels)];
    setExpandedPanels(uniqueArray);
  };

  const resetStates = () => {
    setModelLocations([defaultLocation]);
    setSelectAllGroups(false);
    setSelectAllFeatures(false);
    setSelectedGroupsModel([]);
    setSentimentValueModel(null);
    setAutoSelect(false);
    setLanguageModel("");
    setRangeModel({
      label: `${t("In_Range")}`,
      value: "between",
    });
    setStartDateModel(resetStart);
    setEndDateModel(resetEnd);
    setNextStartDateModel(resetNextStart);
    setnextEndDateModel(resetNextEnd);
    // resetHandler();
  };

  const handleChangeRangeModel = (value) => {
    setCallApi(false);

    setRangeModel(value);
  };

  const handleSubmitFilters = () => {
    setLoading(true);

    handleChangeStartDate(startDateModel);
    handleChangeEndDate(endDateModel);
    handleChangeSentiment(sentimentValueModel);
    handleChangeLanguage(languageModel);
    const filteredLocations = modellocations?.filter(
      (item) => item?.statusOfSentimentalAnalysis === "done"
    );
    handleChangeLocation(filteredLocations);
    handleChangeGroups(selectedGroupsModel, false);
    setSelectAll(selectAllGroups);
    handleChangeRange(rangeModel, true);
    setSelectAllLocations(selectAllFeatures);
    setSelectAll(selectAllGroups);
    setAutoSelectHeader(autoSelect);
    setTimeout(() => {
      setLoading(true);
      handleCloseModalFilter();
      setCallApi(true);
    }, 2000);
  };

  function handleDisplayDot(countriesArr, locationsArr) {
    let name = countriesArr?.countryName;
    let countriesLocations = countriesArr?.locationsArray;
    const ids1 = new Set(countriesLocations.map((obj) => obj.id));

    for (let obj of locationsArr) {
      if (ids1.has(obj.id)) {
        return true;
      }
    }

    return false;
  }

  function filterArrayById(inputArray, targetId) {
    return inputArray.filter((item) =>
      item.groups.some((group) => group.locationId === targetId)
    );
  }
  const handleChangeGroupsModel = (value, autoSelectValue) => {
    setSelectedGroupsModel(value);

    if (autoSelectValue) {
      let newLocations = [...modellocations];
      const results = oldGroupsModel.filter(
        ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
      );
      if (value?.length > 0) {
        value?.map((group) => {
          group?.groups?.map((groupItem) => {
            allLocations?.map((location) => {
              if (groupItem?.locationId === location?.id) {
                newLocations?.push(location);
              } else {
                // setSelectAll(false);
                results?.map((group) => {
                  group?.groups?.map((groupItem) => {
                    modellocations?.map((location) => {
                      const filteredArray = filterArrayById(
                        value,
                        location?.id
                      );
                      if (
                        groupItem?.locationId === location?.id &&
                        filteredArray?.length === 0
                      ) {
                        newLocations = newLocations?.filter(
                          (item) => item?.id !== location?.id
                        );
                      }
                    });
                  });
                });
              }
            });
          });
        });
      } else {
        results?.map((group) => {
          group?.groups?.map((groupItem) => {
            modellocations?.map((location) => {
              if (groupItem?.locationId === location?.id) {
                newLocations = newLocations?.filter(
                  (item) => item?.id !== location?.id
                );
              }
            });
          });
        });
      }

      const uniqueData = removeDuplicates(newLocations);
      setModelLocations(uniqueData);
    }

    setOldGroupsModel(value);
  };
  function removeDuplicates(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }
  function checkGroupsExistence(groups, arr) {
    return groups.every((group) =>
      arr.some((elem) => elem.id === group.locationId)
    );
  }

  const handleChangeGroupsForLocationsModel = (locations) => {
    if (modellocations?.length > 0 || selectedGroupsModel?.length > 0) {
      const filteredArray2 = selectedGroupsModel?.filter((item) =>
        item.groups ? checkGroupsExistence(item.groups, locations) : true
      );

      setSelectedGroupsModel(filteredArray2 || []); // Make sure filteredArray2 has a value or assign an empty array if it's undefined/null
      // setLocationError(false);
    } else {
      //  setSelectAllGroups(false);
      // /  setLocationError(true);
      setSelectedGroupsModel([]);
    }
  };

  const handleChangeLocationModel = (loc) => {
    // setCallApi(false);
    setModelLocations(loc);
  };

  const handleChangeStartDateModel = (e) => {
    setStartDateModel(e);
  };

  const handleChangeEndDateModel = (e) => {
    setEndDateModel(e);
  };

  const handleChangeLanguageModel = (value) => {
    setLanguageModel(value);
  };
  const handleChangeSentimentModel = (value) => {
    setSentimentValueModel(value);
  };
  const handleExpandAllPanels = () => {
    const arr = [...expandedPanels];
    countries?.map((item) => arr.push(item?.countryName));
    const uniqueArray = [...new Set(arr)];
    setExpandedPanels(uniqueArray);

    setExpandAllPanels(false);
  };

  const handleCollapseAllPanelsPanels = () => {
    const filtered = expandedPanels?.filter((item) => item === "groups");
    setExpandedPanels(filtered);
    setExpandAllPanels(true);
  };
  const handleChangeSelectAllLocations = (e) => {
    setSelectAllFeatures(e.target.checked);
    if (e.target.checked) {
      const filteredLocations = allLocations?.filter(
        (item) => item?.statusOfSentimentalAnalysis === "done"
      );
      setModelLocations(filteredLocations);
      handleExpandAllPanels();
    } else {
      setModelLocations([]);
    }
  };
  const handleSelectAllGroups = (e) => {
    setSelectAllGroups(e.target.checked);
    if (e.target.checked) {
      let arr = [];
      allGroups?.rows?.map((item) => {
        if (isSentimentalAnalysisDone(item?.groups)) {
          arr.push(item);
        }
      });
      handleChangeGroupsModel(arr, autoSelect);
      expandedPanels?.push("groups");
    } else {
      handleChangeGroupsModel([], autoSelect);
    }
  };
  const handleChangeAllCountryLocations = (e, locationsArr, countryName) => {
    if (e.target.checked) {
      let arr = [...modellocations];
      let mergedArr = arr.concat(locationsArr);
      const filteredLocations = mergedArr?.filter(
        (item) => item?.statusOfSentimentalAnalysis === "done"
      );
      const uniqueData = removeDuplicates(filteredLocations);
      setModelLocations(uniqueData);
    } else {
      const filtered = modellocations?.filter(
        (item) => item?.countryCode !== countryName
      );
      setModelLocations(filtered);
    }
  };

  const handleCheckForAllLocations = (countryLocations, selectedLocations) => {
    const array2IdSet = new Set(selectedLocations.map((obj) => obj.id));
    return countryLocations.every((obj) => array2IdSet.has(obj.id));
  };

  const handleChangeAutoSelect = (e) => {
    setAutoSelect(e.target.checked);

    if (e.target.checked) {
      if (selectedGroupsModel?.length > 0) {
        handleChangeGroupsModel(selectedGroupsModel, true);
      }
    } else {
      if (selectedGroupsModel?.length > 0) {
        const mergedGroups = mergeGroups(selectedGroupsModel);
        const filteredArray = removeMatchingObjects(
          modellocations,
          mergedGroups
        );
        setModelLocations(filteredArray);
      }
    }
  };

  function mergeGroups(mainArray) {
    return mainArray.reduce((accumulator, currentObject) => {
      return accumulator.concat(currentObject.groups);
    }, []);
  }

  function removeMatchingObjects(array, groups) {
    const locationIdsSet = new Set(groups.map((group) => group.locationId));
    return array.filter((obj) => !locationIdsSet.has(obj.id));
  }
  const handleNavigationToGroups = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 3, page: 0 },
    });
  };
  return (
    <Modal
      open={openModalFilter}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container>
        <Grid
          item
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className={classes.styleMainModelContainer}>
            <Box
              sx={{
                pb: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.mainContainerHeading}>
                {t("Filters")}
              </Typography>

              <IconButton
                autoFocus
                onClick={handleCloseModalFilter}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box className={classes.contentContainer}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <CommonButton
                    type="submit"
                    isLoading={false}
                    disabled={false}
                    onSubmit={() => setSection("locations")}
                    label={t("Locations")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={section === "locations" ? "false" : "true"}
                  />
                  <CommonButton
                    type="submit"
                    isLoading={false}
                    disabled={false}
                    onSubmit={() => setSection("others")}
                    label={t("others")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={section === "others" ? "false" : "true"}
                  />
                </Box>
                <Box>
                  <>
                    <CommonButton
                      type="submit"
                      isLoading={false}
                      disabled={false}
                      onSubmit={handleClickMenu}
                      label={
                        isSorted == "asc"
                          ? t("sortA-Z")
                          : isSorted == "desc"
                          ? t("sortZ-A")
                          : t("Sort_By")
                      }
                      icon={<KeyboardArrowDownIcon />}
                      leftMargin={true}
                      customHeight={true}
                      displayWhite={"true"}
                      iconLocation={true}
                      aria-owns={anchorBulk ? "simple-menu" : undefined}
                      aria-haspopup="true"
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorBulk}
                      open={Boolean(anchorBulk)}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          background: "#EBEBEC",
                        },
                      }}
                    >
                      <MenuItem
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          lineHeight: "20px",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#DBDBDC",
                          },
                        }}
                        onClick={(e) => {
                          setIsSorted("asc");
                          handleClose();
                        }}
                      >
                        {t("sortA-Z")}
                      </MenuItem>
                      <MenuItem
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          lineHeight: "20px",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#DBDBDC",
                          },
                        }}
                        onClick={(e) => {
                          setIsSorted("desc");
                          handleClose();
                        }}
                      >
                        {t("sortZ-A")}
                      </MenuItem>
                    </Menu>
                  </>
                </Box>
              </Box>

              {section === "locations" && (
                <>
                  <Box>
                    <Collapsible
                      title={t("groups_label")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("groups")}
                      id={"groups"}
                      displayGroupSelectAll={
                        allGroups?.rows?.length > 0 ? true : false
                      }
                      handleSelectAllGroups={handleSelectAllGroups}
                      selectedGroupsModel={selectedGroupsModel}
                      selectAllGroups={selectAllGroups}
                      countryNameStyling={selectedGroupsModel?.length > 0}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {allGroups !== null && allGroups?.rows?.length > 0 ? (
                            handleSorted(allGroups?.rows, "name")?.map((item) =>
                              isSentimentalAnalysisDone(item?.groups) ? (
                                <Item
                                  onClick={() => ""}
                                  sx={{
                                    padding: "7px 8px 7px 8px",
                                    gap: "4px",
                                    border: selectedGroupsModel?.find(
                                      (group) => group?.id === item?.id
                                    )
                                      ? "1px solid #06BDFF"
                                      : "1px solid #EBEBEC",
                                    borderRadius: "800px",
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      selectedGroupsModel?.length > 0 &&
                                      selectedGroupsModel?.find(
                                        (group) => group?.id === item?.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      let arr = [...selectedGroupsModel];
                                      if (e.target.checked) {
                                        arr.push(item);
                                        handleChangeGroupsModel(
                                          arr,
                                          autoSelect
                                        );
                                      } else {
                                        const filtered = arr.filter(
                                          (group) => group?.id !== item?.id
                                        );
                                        handleChangeGroupsModel(
                                          filtered,
                                          autoSelect
                                        );
                                      }
                                    }}
                                    size="small"
                                    sx={{
                                      paddingLeft: "2px",
                                      paddingRight: "2px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "18px",
                                      color: "#1B2430",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                </Item>
                              ) : (
                                <BootstrapTooltip
                                  title={t("groups_disabled_tooltipText")}
                                >
                                  <Item
                                    onClick={() => ""}
                                    sx={{
                                      padding: "7px 8px 7px 8px",
                                      gap: "4px",
                                      border: "none",
                                      borderRadius: "800px",
                                      background: "#F0F0F0 !important",
                                    }}
                                  >
                                    <Checkbox
                                      checked={
                                        selectedGroupsModel?.length > 0 &&
                                        selectedGroupsModel?.find(
                                          (group) => group?.id === item?.id
                                        )
                                      }
                                      disabled={true}
                                      onChange={(e) => {
                                        let arr = [...selectedGroupsModel];
                                        if (e.target.checked) {
                                          arr.push(item);
                                          handleChangeGroupsModel(
                                            arr,
                                            autoSelect
                                          );
                                        } else {
                                          const filtered = arr.filter(
                                            (group) => group?.id !== item?.id
                                          );
                                          handleChangeGroupsModel(
                                            filtered,
                                            autoSelect
                                          );
                                        }
                                      }}
                                      size="small"
                                      sx={{
                                        paddingLeft: "2px",
                                        paddingRight: "2px",
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "18px",
                                        color: "gray !important",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                  </Item>
                                </BootstrapTooltip>
                              )
                            )
                          ) : (
                            <Grid
                              container
                              sx={{
                                height: "200px",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid item sm={8} md={8} lg={8}>
                                <Typography
                                  sx={{
                                    lineHeight: "28px",
                                    fontWeight: 700,
                                    fontSize: "20px",
                                    color: "#1b2430",
                                    marginTop: "30px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {t("No_Groups_Found")}
                                </Typography>
                                <Typography
                                  sx={{
                                    lineHeight: "24px",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    color: "#1B2430",

                                    marginBottom: "15px",
                                  }}
                                >
                                  {t("create_groups_text")}
                                </Typography>
                                {locationAddPermission &&
                                locationViewPermission ? (
                                  <CommonButton
                                    onSubmit={handleNavigationToGroups}
                                    customHeight={true}
                                    label={t("create_groups")}
                                    icon={<AddIcon sx={{ color: "#FFFF" }} />}
                                  />
                                ) : (
                                  <BootstrapTooltip
                                    title={t("authorized_access")}
                                  >
                                    <span>
                                      <CommonButton
                                        disabled={true}
                                        onSubmit={handleNavigationToGroups}
                                        customHeight={true}
                                        label={t("create_groups")}
                                        icon={
                                          <AddIcon sx={{ color: "#FFFF" }} />
                                        }
                                      />
                                    </span>
                                  </BootstrapTooltip>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      }
                    />
                  </Box>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ display: "flex" }}
                    >
                      <Checkbox
                        checked={autoSelect}
                        onChange={(e) => {
                          handleChangeAutoSelect(e);

                          //  handleChangeLocationModel(arr);
                          //  handleChangeGroupsModel(arr, e.target.checked);
                        }}
                        size="small"
                        sx={{ paddingRight: "3px", paddingLeft: "0px" }}
                      />
                      <Typography sx={{ fontSize: "12px", marginTop: "10px" }}>
                        {t("auto-select-checkbox-group")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      padding: "8px",
                      border: "1px solid #EBEBEC",
                      borderRadius: "8px",
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: 600, fontSize: "1.125rem" }}
                        >
                          {t("Locations")}
                        </Typography>

                        <Box>
                          <SmallCheckbox
                            name="selectAll"
                            color="primary"
                            checked={selectAllFeatures}
                            // disabled={defaultRole}
                            onChange={(e) => handleChangeSelectAllLocations(e)}
                            sx={{ marginRight: "4px" }}
                          />
                          {t("All_locationss")}
                          {expandAllPanels ? (
                            <IconButton onClick={handleExpandAllPanels}>
                              <ExpandMoreIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={handleCollapseAllPanelsPanels}>
                              <ExpandLessIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid container sx={{ gap: "10px" }}>
                      {countries?.length > 0 &&
                        handleSorted(countries, "countryName")?.map((item) => (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            {" "}
                            <Collapsible
                              title={item?.countryName}
                              handleChange={handleExpandPanel}
                              expanded={expandedPanels?.includes(
                                item?.countryName
                              )}
                              expandedPanels={expandedPanels}
                              setExpandedPanels={setExpandedPanels}
                              id={item?.countryName}
                              countryNameStyling={handleDisplayDot(
                                item,
                                modellocations
                              )}
                              displaySelectAll={true}
                              itemData={item}
                              handleCheckForAllLocations={
                                handleCheckForAllLocations
                              }
                              handleChangeAllCountryLocations={
                                handleChangeAllCountryLocations
                              }
                              modellocations={modellocations}
                              countriesArr={item}
                              ComponentToRender={
                                <Grid container sx={{ gap: "10px" }}>
                                  {item?.locationsArray?.length > 0 &&
                                    handleSorted(
                                      item?.locationsArray,
                                      "internalName"
                                    )?.map((itemTwo) => (
                                      <Item
                                        onClick={() => ""}
                                        style={{
                                          padding: "12px 8px 12px 8px",
                                          gap: "4px",
                                          border:
                                            itemTwo?.statusOfSentimentalAnalysis !==
                                            "done"
                                              ? "none"
                                              : modellocations?.find(
                                                  (item) =>
                                                    item?.id === itemTwo?.id
                                                )
                                              ? "1px solid #06BDFF"
                                              : "1px solid #BBBDC1",
                                          borderRadius: "800px",
                                          background:
                                            itemTwo?.statusOfSentimentalAnalysis !==
                                            "done"
                                              ? "#F0F0F0"
                                              : modellocations?.find(
                                                  (item) =>
                                                    item?.id === itemTwo?.id
                                                )
                                              ? "#FFF"
                                              : "#FFF",

                                          //  height: "45px",
                                        }}
                                      >
                                        <Checkbox
                                          checked={
                                            modellocations?.find(
                                              (loc) => loc?.id === itemTwo?.id
                                            ) !== undefined
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            let arr = [...modellocations];
                                            if (e.target.checked) {
                                              arr.push(itemTwo);
                                              handleChangeLocationModel(arr);
                                              if (autoSelect) {
                                                handleChangeGroupsForLocationsModel(
                                                  arr
                                                );
                                              }
                                            } else {
                                              const filtered = arr.filter(
                                                (loc) => loc?.id !== itemTwo?.id
                                              );
                                              handleChangeLocationModel(
                                                filtered
                                              );
                                              if (autoSelect) {
                                                handleChangeGroupsForLocationsModel(
                                                  filtered
                                                );
                                              }
                                            }
                                          }}
                                          disabled={
                                            itemTwo?.statusOfSentimentalAnalysis !==
                                            "done"
                                          }
                                          size="small"
                                          sx={{
                                            paddingLeft: "2px",
                                            paddingRight: "2px",
                                          }}
                                        />

                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "18px",
                                            color:
                                              itemTwo?.statusOfSentimentalAnalysis !==
                                              "done"
                                                ? "gray !important"
                                                : modellocations?.find(
                                                    (item) =>
                                                      item?.id === itemTwo?.id
                                                  )
                                                ? "#1B2430"
                                                : "#1B2430",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {itemTwo.internalName +
                                            (itemTwo?.city !== null &&
                                              itemTwo?.city !== "" &&
                                              " " + "(" + itemTwo?.city + ")")}
                                        </Typography>
                                        <span
                                          onClick={() => {
                                            if (
                                              itemTwo?.statusOfSentimentalAnalysis ===
                                                "pending" &&
                                              itemTwo?.reviewFoundStatus ===
                                                true
                                            ) {
                                              handleOpenNewRequestModel(
                                                itemTwo
                                              );
                                            }
                                          }}
                                          style={{
                                            marginLeft: "10px",
                                            color:
                                              itemTwo?.statusOfSentimentalAnalysis ===
                                              "done"
                                                ? "#13CF8F"
                                                : itemTwo?.reviewFoundStatus ==
                                                  false
                                                ? "#FC3652"
                                                : itemTwo?.statusOfSentimentalAnalysis ===
                                                  "requested"
                                                ? "#F86F35"
                                                : itemTwo?.statusOfSentimentalAnalysis ===
                                                  "inProgress"
                                                ? "#FC3652"
                                                : itemTwo?.reviewFoundStatus ===
                                                    true &&
                                                  itemTwo?.statusOfSentimentalAnalysis ===
                                                    "pending"
                                                ? "#F8A92B"
                                                : "",
                                          }}
                                        >
                                          {itemTwo?.statusOfSentimentalAnalysis ==
                                          "done"
                                            ? "• " + `${t("Done")}`
                                            : ""}
                                          {itemTwo?.reviewFoundStatus === false
                                            ? "• " + `${t("No_reviews")}`
                                            : ""}
                                          {itemTwo?.statusOfSentimentalAnalysis ==
                                          "requested"
                                            ? "• " + `${t("requested_state")}`
                                            : ""}
                                          {itemTwo?.statusOfSentimentalAnalysis ==
                                          "inProgress"
                                            ? "• " + `${t("inProgress")}`
                                            : ""}
                                          {itemTwo?.reviewFoundStatus ===
                                            true &&
                                          itemTwo?.statusOfSentimentalAnalysis ==
                                            "pending"
                                            ? "• " + `${t("please_request")}`
                                            : ""}
                                        </span>
                                      </Item>
                                    ))}
                                </Grid>
                              }
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </>
              )}
              {section === "others" && (
                <>
                  <Collapsible
                    title={t("Datee")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("Date")}
                    id={"Date"}
                    ComponentToRender={
                      <Grid container sx={{ gap: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={dateValues}
                            getOptionLabel={(option) => option?.label}
                            sx={{ width: "100%" }}
                            //  value={locationId}
                            value={rangeModel}
                            onChange={(e, value) => {
                              setCallApi(false);
                              handleChangeRangeModel(value);
                            }}
                            className="Autocomplete-field"
                            renderInput={(params) => (
                              <TextField
                                placeholder={t("Select Range")}
                                {...params}
                                required
                                name="language"
                              />
                            )}
                          ></Autocomplete>
                        </Grid>
                        {rangeModel !== null && rangeModel?.value === "gt" && (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                /*  sx={{
                            // width: "320px",
                            // width: "320px",
                            border: "1px solid #E0E0E0",
                            width: "320px",
                            background: "blue",
                          }} */
                                disableFuture
                                className={classes.whiteBackground}
                                format={format}
                                minDate={dayjs("2005-01-01")}
                                maxDate={dayjs().endOf("day")}
                                //  disabled={range !== null && range?.value === "gt"}
                                placeholder={t("From")}
                                value={
                                  startDateModel !== "" &&
                                  startDateModel !== null
                                    ? dayjs(startDateModel)
                                    : null
                                }
                                onChange={(e) => {
                                  setCallApi(false);
                                  if (range?.value === "between") {
                                    handleChangeStartDateModel(e);
                                    setDateRange({
                                      startDate: new Date(e),
                                      endDate: new Date(endDate),
                                    });
                                  } else {
                                    handleChangeStartDateModel(e);
                                    const today = new Date();
                                    handleChangeEndDateModel(today);
                                    setDateRange({
                                      startDate: new Date(e),
                                      endDate: new Date(today),
                                    });
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    fullWidth
                                    {...params}
                                    sx={{
                                      padding: "8px",
                                      background: "#FFFF",
                                      wdith: "100%",
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        )}
                        {rangeModel !== null && rangeModel?.value === "lt" && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ padding: "8px" }}
                          >
                            {" "}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                /*  sx={{
                    // width: "320px",
                    border: "1px solid #E0E0E0",
                    width: "320px",
                    background: "white",
                  }} */
                                disableFuture
                                format={format}
                                fullWidth
                                // maxDate={endDate}
                                minDate={dayjs("2005-01-01")}
                                maxDate={dayjs().endOf("day")}
                                className={classes.whiteBackground}
                                value={
                                  endDateModel !== "" && endDateModel !== null
                                    ? dayjs(endDateModel)
                                    : null
                                }
                                onChange={(e) => {
                                  setCallApi(false);
                                  const today = new Date();
                                  if (range?.value === "between") {
                                    let selectedDate =
                                      dayjs(e).format("YYYY-MMM-DD");
                                    let todayDate =
                                      moment(today).format("YYYY-MMM-DD");

                                    handleChangeEndDateModel(e.endOf("day"));
                                  } else {
                                    let selectedDate =
                                      dayjs(e).format("YYYY-MMM-DD");
                                    let todayDate =
                                      moment(today).format("YYYY-MMM-DD");

                                    handleChangeEndDateModel(e.endOf("day"));

                                    const newEndDate = dayjs(e);
                                    const previousYear = new Date(
                                      newEndDate.year() - 1,
                                      newEndDate.month(),
                                      newEndDate.date()
                                    );

                                    handleChangeStartDateModel(previousYear);
                                  }
                                }}
                                /*   disabled={
                    startDate===null ||
                    (range !== null && range?.value === "lt")
                  } */
                                placeholder={t("Too")}
                                label={t("Too")}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    fullWidth
                                    {...params}
                                    sx={{ background: "#FFFF" }}
                                    //  label={t("To")}
                                    //   error={dateRequired}
                                    // sx={{ padding: "8px" }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        )}
                        {rangeModel !== null &&
                          rangeModel?.value === "between" && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ padding: "0px" }}
                            >
                              <DatePickerComponent
                                handleChangeStartDate={
                                  handleChangeStartDateModel
                                }
                                handleChangeEndDate={handleChangeEndDateModel}
                                startDate={startDateModel}
                                endDate={endDateModel}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                setCallApi={setCallApi}
                                removePadding={true}
                                rangeValue={"between"}
                              />
                            </Grid>
                          )}
                      </Grid>
                    }
                  />

                  <Collapsible
                    title={t("Language_review")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("Language")}
                    id={"Language"}
                    countryNameStyling={languageModel !== ""}
                    ComponentToRender={
                      <Grid container sx={{ gap: "10px" }}>
                        {handleSorted(languagesData, "language").map((item) => (
                          <Item
                            sx={{
                              border:
                                languageModel?.language === item.language
                                  ? "1px solid #06BDFF"
                                  : "1px solid #EBEBEC",
                            }}
                          >
                            <Checkbox
                              checked={
                                languageModel?.language === item.language
                              }
                              onClick={(e) => {
                                setCallApi(false);
                                if (e.target.checked) {
                                  handleChangeLanguageModel(item);
                                } else {
                                  handleChangeLanguageModel("");
                                }
                              }}
                              size="small"
                            />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#1B2430",
                              }}
                            >
                              {t(item.language)}
                            </Typography>
                          </Item>
                        ))}
                      </Grid>
                    }
                  />
                  <Collapsible
                    title={t("Sentiment")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("Sentiment")}
                    id={"Sentiment"}
                    countryNameStyling={
                      sentimentValueModel !== null && sentimentValueModel !== ""
                    }
                    ComponentToRender={
                      <Grid container sx={{ gap: "10px" }}>
                        {handleSorted(sentimentsValues, "value").map((item) => (
                          <Item
                            sx={{
                              border:
                                sentimentValueModel?.label === item?.label
                                  ? "1px solid #06BDFF"
                                  : "1px solid #EBEBEC",
                            }}
                          >
                            <Checkbox
                              checked={
                                sentimentValueModel?.label === item?.label
                              }
                              onClick={(e) => {
                                setCallApi(false);
                                if (e.target.checked) {
                                  handleChangeSentimentModel(item);
                                } else {
                                  handleChangeSentimentModel("");
                                }
                              }}
                              size="small"
                            />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#1B2430",
                              }}
                            >
                              {t(item.label)}
                            </Typography>
                          </Item>
                        ))}
                      </Grid>
                    }
                  />
                </>
              )}
            </Box>
            <Divider />

            <Grid container sx={{ paddingTop: "10px", paddingBottom: "16px" }}>
              <Grid item md={6}></Grid>
              <Grid item md={6}>
                <Box
                  sx={{
                    justifyContent: "flex",
                    float: "right",
                  }}
                >
                  <CommonButton
                    onSubmit={resetStates}
                    label={t("Clear_Filters")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={"true"}
                  />
                  &nbsp;&nbsp;
                  <CommonButton
                    type="submit"
                    isLoading={loading}
                    disabled={loading}
                    onSubmit={handleSubmitFilters}
                    label={t("Apply_filters")}
                    leftMargin={true}
                    customHeight={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default FilterModal;

/* openModalFilter && (
  <FilterModal
    open={openModalFilter}
    close={setOpenModalFilter}
    allGroups={allGroups}
  />
); */
