import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import TimePickers from "./timePickers.js";
import { useStyles } from "../../../../../Styles/style.js";
import TriStateToggleButton from "./ThreeStateSwitch";

const Hours = ({
  periods,
  index,
  openTime,
  closeTime,
  handleChangeCloseTime,
  handleChangeOpenTime,
  handleChangeOpen,
  open,
  filteredPeriodsArr,
  setSelectedData,
  selectedData,
  getLocationDetails,
  handleAddHours,
  handleRemoveHours,
  openTimesError,
  setOpenTimesError,
  setCloseTimesError,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={6} sm={1.5} md={1.5} lg={1.5} sx={{ paddingTop: "7px" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            color: "#1B2430",
            marginTop: "5px",
            // marginRight: "10px",
          }}
        >
          {periods?.openDay === "MONDAY"
            ? t("monday")
            : periods?.openDay === "TUESDAY"
            ? t("tuesday")
            : periods?.openDay === "WEDNESDAY"
            ? t("wednesday")
            : periods?.openDay === "THURSDAY"
            ? t("thursday")
            : periods?.openDay === "FRIDAY"
            ? t("friday")
            : periods?.openDay === "SATURDAY"
            ? t("saturday")
            : periods?.openDay === "SUNDAY"
            ? t("sunday")
            : periods?.openDay}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={2}
        md={2.5}
        lg={2.5}
        sx={{ paddingTop: "7px", marginTop: "8px" }}
      >
        <Typography className={classes.switchTypo}>
          {" "}
          <TriStateToggleButton
            periods={periods}
            indexValue={index}
            handleChangeOpen={handleChangeOpen}
          />
          {/*  <Switch
            className={
              periods?.closed === true
                ? classes.toggleChecked
                : classes.toggleUnChecked
            }
            checked={periods?.closed === true ? false : true}
            onChange={(e) => handleChangeOpen(e, periods, index)}
          />
          {periods?.closed === false ? t("Open") : t("Closed")} */}
        </Typography>
      </Grid>
      <Grid item xs={8.5} sm={8} md={8} lg={8}>
        {periods?.closed === false &&
          periods?.mergedTimes?.map((item, ind) => (
            <Grid container key={index} sx={{ marginBottom: "10px" }}>
              <TimePickers
                periodsIndex={index}
                periods={periods}
                itemIndex={ind}
                item={item}
                handleChangeCloseTime={handleChangeCloseTime}
                handleChangeOpenTime={handleChangeOpenTime}
                handleChangeOpen={handleChangeOpen}
                open={open}
                filteredPeriodsArr={filteredPeriodsArr}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                getLocationDetails={getLocationDetails}
                handleAddHours={handleAddHours}
                handleRemoveHours={handleRemoveHours}
                openTimesError={openTimesError}
                setOpenTimesError={setOpenTimesError}
                setCloseTimesError={setCloseTimesError}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};
export default Hours;
