import React from "react";
import KeywordSearch from "../AdminSeo/Components/KeywordSearch";
import { KeywordSearchProvider } from "./navbar/context/KeywordSearchContext";

function Default() {
  return (
    <KeywordSearchProvider>
      <KeywordSearch></KeywordSearch>
    </KeywordSearchProvider>
  );
}

export default Default;
