import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TextField,
  Button,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { Paper } from "@mui/material";
import ShortcutSharpIcon from "@mui/icons-material/ShortcutSharp";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InputAdornment from "@mui/material/InputAdornment";
import Loader from "../../../../../components/Loaders/Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../Styles/style";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  subTitle,
  open,
  handleCloseNewRequestModel,
  allLocation,
  totalModalEmailsArr,
  selectedItems,
  setSelectedItems,
  selectAllChecked,
  setSelectAllChecked,
  handleSubmitOk,
  handleCancelEmailsModal,
  isModalEmailsSent,
  setIsModalEmailsSent,
  isLoading,
  setLocationRequired,
  locationRequired,
  allGroups,
  // selectedGroup,
  locationViewPermission,
  setLocationViewPermission,
  viewPermission,
  addPermission,
  editPermission,
  deletePermission,
  locationAddPermission,
  locationEditPermission,
  locationDeletePermission,
  BootstrapTooltip,
  handleNavigationToGroups,
  resetHandler,
  handleCreateQuestions,
  askQuestionValue,
  setAskQuestionValue,
  locationId,
  setLocationId,
  createQuestionLoading,
  selectAllLocations,
  setSelectAllLocations,
  selectedGroups,
  setSelectedGroups,
  selectAllGroups,
  setSelectAllGroups,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const filter = createFilterOptions();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [oldGroups, setOldGroups] = useState([]);
  const [locationError, setLocationError] = useState(false);

  const handleLocationChange = (value) => {
    setLocationId(value);
  };

  const handleChangeAskQuestion = (e) => {
    const inputValue = e.target.value;
    setAskQuestionValue(inputValue);
  };

  function filterArrayById(inputArray, targetId) {
    return inputArray.filter((item) =>
      item.groups.some((group) => group.locationId === targetId)
    );
  }

  const handleChangeGroups = (value) => {
    setSelectedGroups(value);

    let newLocations = [...locationId];
    const results = oldGroups.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );
    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocation?.results?.map((location) => {
            if (groupItem?.locationId === location?.id) {
              newLocations?.push(location);
            } else {
              setSelectAllLocations(false);
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  locationId?.map((location) => {
                    const filteredArray = filterArrayById(value, location?.id);
                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          locationId?.map((location) => {
            if (groupItem?.locationId === location?.id) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== location?.id
              );
            }
          });
        });
      });
    }
    const uniqueData = removeDuplicates(newLocations);
    setLocationId(uniqueData);

    if (newLocations?.length > 0) {
      setLocationError(false);
    } else {
      setLocationError(true);
    }

    setOldGroups(value);
  };

  function removeDuplicates(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }

  function checkGroupsExistence(groups, arr) {
    return groups.every((group) =>
      arr.some((elem) => elem.id === group.locationId)
    );
  }

  const handleChangeGroupsForLocations = (locations) => {
    if (locations?.length > 0 || selectedGroups?.length > 0) {
      const filteredArray2 = selectedGroups?.filter((item) =>
        item.groups ? checkGroupsExistence(item.groups, locations) : true
      );

      setSelectAllGroups(false);
      setSelectedGroups(filteredArray2 || []); // Make sure filteredArray2 has a value or assign an empty array if it's undefined/null
      // setLocationError(false);
    } else {
      //  setSelectAllGroups(false);
      setLocationError(true);
      setSelectedGroups([]);
    }
  };
  return (
    <>
      <div>
        <Dialog
          open={open}
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "700px",
              height: "420px",
            },
          }}
        >
          <DialogTitle className={classes.faqModelTitle}>{title}</DialogTitle>

          <DialogContent dividers>
            {/* {isLoading ? (
              <Loader />
            ) : ( */}
            <Grid container className={classes.modelContainer}>
              <Typography className={classes.faqAskModelTitle}>
                {t("group_access")}
              </Typography>

              <Autocomplete
                multiple
                disablePortal
                disableCloseOnSelect
                id="combo-box-demo"
                // fullWidth
                options={
                  allGroups !== null && allGroups?.rows?.length > 0
                    ? allGroups?.rows
                    : []
                }
                getOptionLabel={(option) => (option?.name ? option?.name : "")}
                sx={{ bgcolor: "white", width: "98% !important" }}
                className="Autocomplete-field"
                value={selectedGroups ? selectedGroups : []}
                onChange={(e, value) => {
                  if (value.find((option) => option.id === 0)) {
                    if (selectedGroups?.length === allGroups?.rows?.length) {
                      setSelectedGroups([]);
                      setSelectAllGroups(false);
                    } else {
                      setSelectAllGroups(true);
                      handleChangeGroups(allGroups?.rows);
                    }
                  } else {
                    handleChangeGroups(value);
                    if (value?.length === allGroups?.rows?.length) {
                      setSelectAllGroups(true);
                    } else {
                      setSelectAllGroups(false);
                    }
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (allGroups?.rows?.length > 0) {
                    return [{ id: 0, name: "Select All" }, ...filtered];
                  } else {
                    return [];
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selectAllGroups || selected}
                      id="select-all-checkbox"
                    />

                    <Typography>{option?.name}</Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("Select_group")}
                    {...params}
                    required
                    name="language"
                  />
                )}
              ></Autocomplete>

              <Typography className={classes.faqAskModelTitle}>
                {t("Select_Locations")}
              </Typography>

              <Autocomplete
                multiple
                disablePortal
                disableCloseOnSelect
                error={locationError}
                fullWidth
                id="combo-box-demo"
                options={
                  allLocation?.results?.length > 0 ? allLocation?.results : []
                }
                getOptionLabel={(option) =>
                  option?.internalName
                    ? option?.internalName +
                      " " +
                      ((option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null) ||
                      (option?.city !== "" && option?.city !== null)
                        ? "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : "")
                    : ""
                }
                sx={{ bgcolor: "white", width: "98%" }}
                value={locationId?.length ? locationId : []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  handleLocationChange(value);

                  if (value.find((option) => option.id === 0)) {
                    if (allLocation?.results?.length === locationId?.length) {
                      setLocationId([]);
                      setSelectAllLocations(false);
                    } else {
                      setSelectAllLocations(true);
                      handleChangeGroupsForLocations(allLocation?.results);
                      setLocationId(allLocation?.results);
                    }
                  } else {
                    setLocationId(value);
                    handleChangeGroupsForLocations(value);
                    if (value?.length === allLocation?.results?.length) {
                      setSelectAllLocations(true);
                    } else {
                      setSelectAllLocations(false);
                    }
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return [{ id: 0, internalName: "Select All" }, ...filtered];
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        (option?.id === 0 && selectAllLocations) || selected
                      }
                    />

                    {`${option?.internalName} ${
                      option?.addressLine1 !== "" &&
                      option?.addressLine1 !== null &&
                      option?.addressLine1 !== undefined
                        ? "(" + option?.addressLine1 + "," + " "
                        : ""
                    } ${option?.city ? option?.city + ")" : ""}`}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("SelectLocation")}
                    {...params}
                    required
                    // error={locationRequired}
                    name="language"
                  />
                )}
              ></Autocomplete>

              <Grid item xs={12} md={12} lg={12} mt={3}>
                <Grid container>
                  <Grid item xs={10.5} md={10.5} lg={10.5}>
                    <Typography className={classes.faqAskModelTitle}>
                      {t("askAquestion")}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5} md={1.5} lg={1.5}>
                    <Typography className={classes.limitFieldTextAskQ}>
                      {askQuestionValue === "" ? 0 : askQuestionValue?.length}
                      /250{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                {askQuestionValue?.length >= 250 && (
                  <Typography className={classes.textLimitTypo}>
                    {t("Text_Limit_Ask_Question")}
                  </Typography>
                )}
              </Grid>
              <TextField
                id="standard-basic"
                rows={5}
                maxRows={20}
                multiline
                type="text"
                name="askQuestions"
                sx={{ width: "98%" }}
                value={askQuestionValue}
                onChange={handleChangeAskQuestion}
                placeholder={t("askAquestion")}
              />
            </Grid>
            {/* )} */}
          </DialogContent>

          <Box className={classes.actionsClass}>
            <CommonButton
              displayWhite="true"
              label={t("Cancel")}
              onSubmit={handleCloseNewRequestModel}
            />
            <CommonButton
              disabled={
                askQuestionValue?.length >= 250
                  ? true
                  : createQuestionLoading
                  ? true
                  : askQuestionValue === ""
                  ? true
                  : locationId?.length === 0
                  ? true
                  : false
              }
              isLoading={createQuestionLoading}
              variant="contained"
              label={t("Post")}
              onSubmit={handleCreateQuestions}
            />
          </Box>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
