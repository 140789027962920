import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  TextField,
  Alert as MuiAlert,
} from "@mui/material";
import "./postAiModel.css";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { TextareaAutosize } from "@material-ui/core";
import { fontWeight, spacing } from "@mui/system";
import styled from "styled-components/macro";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  mainHeadingContainer: {
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
}));

const Alert = styled(MuiAlert)(spacing);

const PostAIModal = ({
  title,
  onCancel,
  aiTags,
  setAiTags,
  AiContentDescription,
  setAiContentDescription,
  generatedContent,
  setGeneratedContent,
  setTagsAIRequired,
  tagsAIRequired,
  contentRequired,
  setContentRequired,
  displayError,
  locationDetailsData,
  openFrom,
}) => {
  const classes = useStyles();
  const textArea = useRef();

  useEffect(() => {
    setAiContentDescription(
      `Write a concise, SEO-friendly description about ${
        locationDetailsData?.result?.google?.title
          ? locationDetailsData?.result?.google?.title
          : "--"
      }` +
        ` targeting people searching for ${
          locationDetailsData?.result?.google?.categories?.primaryCategory
            ?.displayName
            ? locationDetailsData?.result?.google?.categories?.primaryCategory
                ?.displayName
            : "--"
        }` +
        `${
          locationDetailsData?.result?.google?.storefrontAddress?.locality !==
          null
            ? " in"
            : "."
        } ` +
        `${
          locationDetailsData?.result?.google?.storefrontAddress?.locality
            ? locationDetailsData?.result?.google?.storefrontAddress?.locality
            : "--"
        }.` +
        ` Please strictly keep the content under ${
          openFrom !== null && openFrom === "long" ? "750" : "200"
        } characters including spaces`
    );
    setGeneratedContent("");
    setAiTags([]);
  }, []);

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    //  setTagsRequired(true);
    if (!value.trim()) return;
    setAiTags([...aiTags, value]);
    e.target.value = "";
    // setTagsRequired(false);
  };
  const { t } = useTranslation();
  const removeTag = (index) => {
    setAiTags(aiTags.filter((el, i) => i !== index));
  };

  return (
    <>
      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Box sx={{ display: "flex" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>{title}</Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={onCancel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Divider />

      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Grid className={classes.mainKeyWordContainer}>
            <Typography className={classes.labelText}>
              {t("What_Would_Like")}
            </Typography>

            <TextField
              rows={4}
              maxRows={4}
              fullWidth
              multiline
              style={{
                minHeight: "84px",
              }}
              onChange={(e) => {
                setAiContentDescription(e.target.value);
                if (e.target.value?.length > 0) {
                  setContentRequired(false);
                } else {
                  setContentRequired(true);
                }
              }}
              error={contentRequired}
              helperText={contentRequired && "Content is required"}
              placeholder={t("What_Would_Like_Placeholder")}
              value={AiContentDescription}
              name="Description"
            />
            {displayError && (
              <Alert mt={2} mb={3} severity="error">
                {t("Ai_Error_Text")}
              </Alert>
            )}
          </Grid>
          {generatedContent?.length > 0 && (
            <Grid className={classes.mainKeyWordContainer}>
              <Typography className={classes.labelText}>
                {t("Generated_Content")}
              </Typography>

              <TextField
                rows={8}
                maxRows={10}
                multiline
                style={{
                  minHeight: "224px",
                }}
                fullWidth
                placeholder={t("What_Would_Like_Placeholder")}
                value={generatedContent}
                name="Description"
                onChange={(e) => setGeneratedContent(e.target.value)}
              />
            </Grid>
          )}
        </Box>

        {/* </DialogActions> */}
      </Grid>
    </>
  );
};

export default PostAIModal;
