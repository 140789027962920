import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Modal,
  TextField,
  Autocomplete,
  Checkbox,
  createFilterOptions,
  IconButton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Loader from "../../../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { toast } from "react-toastify";
import { api } from "../../../../../contexts/JWTContext";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  // maxHeight: "550px",
  // width: "500px",
  bgcolor: "background.paper",
  // maxHeight: "87vh",
  width: "850px",
  boxShadow: 24,
  p: 4,
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const BulkUpdateModel = ({
  handleCloseAddEmailModel,
  open,
  handleOpenAddBulkEmailModel,
  setAllLocations,
  setBusinessEmailLocations,
  setDisplayEmailWarning,
  setSelectAllLocations,
  handleCloseModel,
  setLocationsRequired,
}) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { t } = useTranslation();
  const filter = createFilterOptions();
  const [selectAll, setSelectAll] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [isLoading, setIsLoading] = useState([]);
  const [allModelLocations, setAllModelLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationRequired, setLocationRequired] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [emails, setEmails] = useState([userData?.email]);
  const [value, setValue] = useState(null);
  let timer = 0;

  useEffect(() => {
    if (open === true) {
      getAllLocations();
    }
  }, [open]);

  const submitBulkUpdate = async () => {
    if (selectedLocation?.length === 0) {
      setLocationRequired(true);
    } else {
      setLocationRequired(false);
    }
    let locationIds = [];
    if (selectedLocation?.length > 0) {
      selectedLocation?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    if (selectedLocation?.length > 0) {
      setBulkLoading(true);
      try {
        const res = await api.patch(`/location/bulkedit-locations`, {
          locations: locationIds,
          //  defaultLanguage: selectLanguage,
          businessEmail: value,
        });
        if (res.status === 200) {
          getAllLocationsForMainPage();
          setBulkLoading(false);

          setSelectedLocation([]);
          toast.success(res?.data?.message);
        }
      } catch (error) {
        setBulkLoading(false);
        handleCloseAddEmailModel();
        handleCloseModel();

        toast.error(error?.response?.data?.message);
      }
    }
  };
  const getAllLocationsForMainPage = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
          // searchTerm: filterWithName,
          // subscriptionIds: temp?.length > 0 ? temp.toString() : "",
        },
      });
      if (res.status === 200) {
        setAllLocations(res.data.data);
        if (res.data.data?.results?.length > 0) {
          const filteredData = res.data.data?.results?.filter(
            (item) => item?.businessEmail === null || item?.businessEmail === ""
          );
          setSelectAllLocations(false);
          setBusinessEmailLocations(filteredData);
          if (filteredData?.length > 0) {
            setDisplayEmailWarning(true);
          } else {
            setDisplayEmailWarning(false);
          }
        }
        handleCloseAddEmailModel();
        handleCloseModel();
      }
    } catch (error) {}
  };

  const getAllLocations = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/location/search?businessEmail=null`, {
        params: {
          isLocationPage: true,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          setAllModelLocations(res?.data?.data?.results);
        }

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleInputChange = (newInputValue) => {
    if (newInputValue && isValidEmail(newInputValue)) {
      if (!emails.includes(newInputValue)) {
        setEmails((prevEmails) => [...prevEmails, newInputValue]);
      }

      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
    //  setInputValue(newInputValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setValidEmail(false);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  /*   const handleChangeCheckButton = (e) => {
    setAllLocationsSelected(e.target.checked);

    if (e.target.checked) {
      setSelectedLocation(allModelLocations);
    } else {
      setSelectedLocation([]);
    }
  }; */

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={1}>
            <Grid item md={11.5}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "28px",
                  marginBottom: "8px",
                  // marginTop: "8px",
                }}
              >
                {t("Bulk_Add")}
              </Typography>
            </Grid>
            <Grid item md={0.5} sx={{ textAlign: "end" }}>
              <IconButton
                autoFocus
                onClick={handleCloseAddEmailModel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          {isLoading ? (
            <Grid
              container
              sx={{
                height: "400px",
                paddingTop: "15px",
                paddingBottom: "15px",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <Loader />
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                paddingTop: "10px",
                paddingBottom: "15px",
              }}
            >
              <Grid container spacing={1}>
                <Grid item sm={12} md={12}>
                  <Typography
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#1B2430",
                      lineHeight: "20px",
                      marginTop: "15px",
                      marginBottom: "8px",
                    }}
                  >
                    {t("Email_Address_Buisness")}
                  </Typography>
                  <Box sx={{ width: "100%" }}>
                    <Autocomplete
                      freeSolo
                      value={value}
                      onChange={handleChange}
                      // onInputChange={(e, value) => handleInputChange(value)}
                      options={emails}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          type="email"
                          fullWidth
                          placeholder="e.g. john@obenan.com"
                          variant="outlined"
                          error={validEmail}
                          onChange={(e, newValue) => {
                            clearTimeout(timer);
                            timer = setTimeout(
                              () => handleInputChange(e.target.value),
                              500
                            );
                          }}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item sm={12} md={12}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        color: "#1B2430",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#1B2430",
                        lineHeight: "20px",
                        marginTop: "15px",
                      }}
                    >
                      {t("Select_Locations")}
                    </Typography>
                    <BootstrapTooltip title={t("tooltipText_businessEmail")}>
                      <Typography
                        sx={{
                          marginLeft: "5px",
                          marginTop: "14px",
                          cursor: "pointer",
                        }}
                      >
                        <InfoOutlinedIcon />
                      </Typography>
                    </BootstrapTooltip>
                  </Box>

                  <Autocomplete
                    multiple
                    error={locationRequired}
                    disablePortal
                    id="combo-box-demo"
                    disableCloseOnSelect
                    options={
                      allModelLocations?.length > 0 ? allModelLocations : []
                    }
                    value={selectedLocation ? selectedLocation : []}
                    onChange={(e, value) => {
                      const selectAllObj = value?.find(
                        (item) => item?.internalName === "Select All"
                      );
                      if (selectAllObj !== null && selectAllObj !== undefined) {
                        setSelectedLocation(allModelLocations);
                      } else {
                        setSelectedLocation(value);
                      }

                      if (value?.length > 0) {
                        setLocationRequired(false);
                      } else {
                        setLocationRequired(true);
                      }
                      // handleLocationChange(value);

                      if (value.find((option) => option.id === 0)) {
                        // Check if all options are selected
                        if (
                          allModelLocations?.length === selectedLocation?.length
                        ) {
                          setSelectedLocation([]);
                          setSelectAll(false);
                        } else {
                          setSelectAll(true);
                          setSelectedLocation(allModelLocations);
                          //  setLocationRequired(false);
                        }
                      } else {
                        setSelectedLocation(value);
                        if (value?.length === allModelLocations?.length) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (allModelLocations?.length > 0) {
                        return [
                          { id: 0, internalName: "Select All" },
                          ...filtered,
                        ];
                      } else {
                        return [];
                      }
                    }}
                    // defaultValue={selectedLocation}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    sx={{ minWidth: 300 }}
                    // value={locationId ? locationId : []}
                    // onChange={(e, value) => {
                    //   setLocationRequired(false);

                    //   setSelectedLocation(value);
                    // }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          id="select-all-checkbox"
                          checked={selectAll || selected}
                          // checked={selected}
                        />
                        {`${option?.internalName} ${
                          option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? "(" + option?.addressLine1 + "," + " "
                            : ""
                        } ${option?.city ? option?.city + ")" : ""}`}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        name="language"
                        {...params}
                        error={locationRequired === true}
                        helperText={locationRequired && t("Field_req_loc")}
                        placeholder={t("Select_Locations")}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </Grid>

              {/*  <Grid container spacing={1}>
                <Grid item sm={12} md={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Checkbox
                      sx={{ paddingLeft: "0px", paddingRight: "3px" }}
                      checked={allLocationsSelected}
                      onChange={(e) => handleChangeCheckButton(e)}
                    />
                    <Typography sx={{ paddingTop: "10px" }}>
                      {t("All_locationss")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid> */}
              <Grid container sx={{ height: "100px" }}></Grid>
            </Grid>
          )}

          <Divider />
          <Grid container spacing={1} sx={{ paddingTop: "10px" }}>
            <Grid item spacing={2} xs={12} md={12}>
              <Box sx={{ float: "right" }}>
                <CommonButton
                  label={t("Cancel_Button")}
                  displayWhite="true"
                  onSubmit={handleCloseAddEmailModel}
                />
                &nbsp;&nbsp;
                <CommonButton
                  isLoading={bulkLoading}
                  label={t("submit_edit")}
                  disabled={
                    selectedLocation?.length === 0 ||
                    value === null ||
                    bulkLoading ||
                    validEmail
                  }
                  onSubmit={submitBulkUpdate}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default BulkUpdateModel;
