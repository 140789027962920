import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  Button,
  Box,
  TextField,
  Alert as MuiAlert,
} from "@mui/material";
import "./postAiModel.css";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

import { spacing } from "@mui/system";
import styled from "styled-components/macro";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  mainHeadingContainer: {
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    marginBottom: "5px",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
}));

const Alert = styled(MuiAlert)(spacing);

const PostAIModal = ({
  title,
  onCancel,
  setAiTags,
  AiContentDescription,
  setAiContentDescription,
  generatedContent,
  setGeneratedContent,
  contentRequired,
  setContentRequired,
  displayError,
  openFrom,
  setTouchedValue,
}) => {
  const classes = useStyles();
  const [dataCount, setDataCount] = useState(1);

  useEffect(() => {
    setAiContentDescription(
      `Write a concise, SEO-friendly description about {business-name} targeting people searching for {primary-category} in {city-name}. Keep the content under ${
        openFrom !== null && openFrom === "long" ? "750" : "200"
      } characters`
    );
    setGeneratedContent("");
    setAiTags([]);
  }, []);

  const { t } = useTranslation();

  const loadMoreData = () => {
    setDataCount(generatedContent?.length);
  };
  const loadLessData = () => {
    setDataCount(1);
  };

  return (
    <>
      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Box sx={{ display: "flex" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>{title}</Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={onCancel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Divider />

      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Grid className={classes.mainKeyWordContainer}>
            <Typography className={classes.labelText}>
              {t("What_Would_Like")}
            </Typography>

            <TextField
              rows={3}
              maxRows={6}
              fullWidth
              multiline
              style={{
                minHeight: "84px",
              }}
              onChange={(e) => {
                setTouchedValue(true);
                setAiContentDescription(e.target.value);
                if (e.target.value?.length > 0) {
                  setContentRequired(false);
                } else {
                  setContentRequired(true);
                }
              }}
              error={contentRequired}
              helperText={contentRequired && "Content is required"}
              placeholder={t("What_Would_Like_Placeholder")}
              value={AiContentDescription}
              name="Description"
            />
            {displayError && (
              <Alert mt={2} mb={3} severity="error">
                {t("Ai_Error_Text")}
              </Alert>
            )}
          </Grid>
          {generatedContent?.length > 0 && (
            <Grid className={classes.mainKeyWordContainer}>
              <Typography className={classes.labelText}>
                {t("Generated_Content")}
              </Typography>

              {generatedContent?.slice(0, dataCount).map((item) => (
                <Grid item xs={12} sm={12} md={12} lg={12} mt={3} mb={3}>
                  <Typography className={classes.labelText}>
                    {item?.name}
                  </Typography>
                  <TextField
                    rows={openFrom === "long" ? 6 : 3}
                    maxRows={openFrom === "long" ? 8 : 3}
                    multiline
                    fullWidth
                    placeholder={t("What_Would_Like_Placeholder")}
                    value={item?.content}
                    name="Description"
                  />
                </Grid>
              ))}

              {generatedContent?.length > 1 && (
                <Grid container sx={{ background: "#FFF" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.viewMoreButtonGrid}
                  >
                    {dataCount === 1 ? (
                      <Button onClick={loadMoreData}>
                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                          {t("View_More")}
                        </Typography>
                      </Button>
                    ) : (
                      <Button onClick={loadLessData}>
                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                          {t("View_Less")}
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default PostAIModal;
