import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
} from "@mui/material";
import Deletes from "./Deletes.css";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "20px",
    paddingTop: "15px",
  },
  mainErrorContainer: {
    padding: "20px",
    width: "95%",
    //  marginTop: "15px",
    margin: "auto",
    marginTop: "10px",
    background: "#FFD0D0",
    borderRadius: "8px",
  },
  sourceImage: {
    width: "80px",
    height: "80px",
  },
  sourceName: {
    //   fontFamily: "Work Sans",
    padding: "30px 0px 20px 10px",
    fontWeight: 800,
    height: "100%",
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "capitalize",
  },
  UnsubscribeButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "134px",
    height: "48px",
    background: "#FC3652",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    "&:hover ": {
      background: "#b02639",
      borderRadius: "8px",
      color: "#FFFFFF",
    },
  },
  cancelButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "100px",
    height: "48px",
    background: "##FFFFFF",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    border: "1px solid #E0E0E0",
    "&:hover ": {
      background: "#c2cbcf",
      borderRadius: "8px",
      color: "white",
    },
  },
  errorText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
  },
  retryText: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#0638C1",
  },
  actionButtons: {
    padding: "20px 16px",
    gap: "16px",
    width: "100%",
    height: "100%",
    background: "#FFFFFF",
    boxShadow: "0px -1px 0px #E0E0E0",
  },
  errorGrid: {
    justifyContent: "center",
    textAlign: "center",
    padding: "10px 5px 0px 5px",
    cursor: "pointer",
  },
  subscribedText: {
    textAlign: "center",
    background: "#06BDFF",
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14px",
    padding: "3px 6px",
    gap: "4px",
    width: "67px",
    height: "20px",
    borderRadius: "800px",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
}));

const Delete = ({
  title,
  description,
  onConfirm,
  onCancel,
  failedIcon,
  reviewSourceData,
  handleUpdateReviewSource,
  name,
}) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [deletePost, setDeletePost] = useState(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Box className="box_delete">
          {/*   <DialogContentText
            id="responsive-dialog-title"
            sx={{}}
            className="delete_dialog_content1"
          >
            {title}
          </DialogContentText> */}

          {failedIcon && (
            <Grid container className={classes.mainErrorContainer}>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <ErrorOutlineIcon sx={{ color: "#FF1616" }} />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Typography className={classes.errorText}>
                  {`${t("error_text_1")} ${name} ${t("error_text_2")}`}
                  {/* {`It seems there is some problem getting reviews from the
                 ${name}. You can either Retry or unsubscribe for now`} */}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                className={classes.errorGrid}
                onClick={handleUpdateReviewSource}
              >
                <Typography className={classes.retryText}>
                  {t("Retry")}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container className={classes.mainContainer}>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img
                alt=""
                className={classes.sourceImage}
                // style={{ width: 40, height: 40 }}
                src={`${baseURL}/${reviewSourceData?.image}`}
              />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Typography className={classes.sourceName}>{name}</Typography>
            </Grid>
          </Grid>

          <DialogActions className={classes.actionButtons}>
            <Button
              autoFocus
              onClick={onCancel}
              className={classes.cancelButton}
            >
              {t("Cancel")}
            </Button>

            <Button onClick={onConfirm} className={classes.UnsubscribeButton}>
              {/*   {t("Confirm")} */}
              {t("Unsubscribe")}
            </Button>
          </DialogActions>
        </Box>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default Delete;
