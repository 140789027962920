import React from "react";
import styled from "styled-components/macro";
import { countries } from "../../../../../countries";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;



function NavbarLanguagesDropdown({
  onChange,
  closeMenu,
  toggleMenu,
  anchorMenu,
  country,
  selectedCountry,
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Tooltip title={t("countries")}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Flag src={selectedCountry?.flag} alt={selectedCountry?.name} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
      // onClose={closeMenu}
      // value={"us"}
      // defaultValue={"us"}
      >
        {countries?.map((country) => (
          <MenuItem key={country?.id} onClick={() => onChange(country)}>
            {country?.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default NavbarLanguagesDropdown;
