import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Autocomplete, TextField } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleIcon from "@mui/icons-material/Google";
import { useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Grid,
  IconButton,
  Paper as MuiPaper,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Chip,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { Edit } from "@mui/icons-material";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { spacing } from "@mui/system";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Table = ({
  row,
  index,
  handleSubClickOpen,
  handleConnectWithGoogle,
  //   handleNavigation,
  setOpen,
  setDeleteId,
  closeMenu,
  handleOpenAcceptModel,
  handleOpenRejectModel,
  handleClick,
  handleClose,
  selectedRow,
  anchorEl,
  handleTaskChecked,
  selectedLocationData,
  handleOpenPlanModal,
  setLocationId,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [googleConnect, setGoogleConnect] = useState(null);
  useEffect(() => {
    row?.socialLink?.length > 0 &&
      row?.socialLink?.forEach((element) => {
        if (element?.type === "google") {
          setGoogleConnect(element);
        }
      });
  }, []);

  let textColor;

  let dotColor;

  if (row?.enableUberAll === "approved") {
    textColor = "#13CF8F";

    dotColor = "#13CF8F";
  } else if (row?.enableUberAll === "rejected") {
    textColor = "#FC3652";
    dotColor = "#FC3652";
  } else if (row?.enableUberAll === "requested") {
    textColor = "#F86F35";
    dotColor = "#F86F35";
  } else if (row?.enableUberAll === "pending") {
    textColor = "#F8A92B";
    dotColor = "#F8A92B";
  } else if (
    row?.enableUberAll === "error" ||
    row?.enableUberAll === "approved"
  ) {
    textColor = "#8D9298";
    dotColor = "#8D9298";
  } else {
    textColor = "black"; // Default color
  }
  return (
    <>
      <CustomizedTableRow
        // hover
        tabIndex={-1}
        key={`${row.id}-${index}`}
      >
        <CustomizedTableCell hover>
          <Checkbox
            checked={selectedLocationData.includes(row.id)}
            onChange={(e) => {
              handleTaskChecked(e, index, row.id, row);
            }}
            disabled={
              row?.enableUberAll === "error" ||
              row?.enableUberAll === "approved" ||
              googleConnect === null
              // row?.enableUberAll === "rejected"
            }
            //   inputProps={{ "aria-label": "controlled" }}
            label=""
          />
        </CustomizedTableCell>
        <CustomizedTableCell
          sx={{
            color:
              row?.enableUberAll === "error" || googleConnect === null
                ? "#8D9298"
                : "#1B2430",
          }}
        >
          {row.id}
        </CustomizedTableCell>
        <CustomizedTableCell
          sx={{
            color:
              row?.enableUberAll === "error" || googleConnect === null
                ? "#8D9298"
                : "#1B2430",
          }}
          align="left"
        >
          {row?.company !== null ? row?.company?.name : "--"}
        </CustomizedTableCell>
        <CustomizedTableCell
          sx={{
            color:
              row?.enableUberAll === "error" || googleConnect === null
                ? "#8D9298"
                : "#1B2430",
          }}
          align="left"
        >
          {row.name}
        </CustomizedTableCell>
        <CustomizedTableCell
          sx={{
            color:
              row?.enableUberAll === "error" || googleConnect === null
                ? "#8D9298"
                : "#1B2430",
          }}
          align="left"
        >
          {row.addressLine1 + (row?.city !== null ? ` ,${row?.city}` : "")}
        </CustomizedTableCell>
        <CustomizedTableCell
          sx={{ color: textColor, width: "150px" }}
          align="left"
        >
          <FiberManualRecordIcon
            sx={{
              fontSize: 10,
              color: dotColor,
              paddingRight: "3px",
            }}
          />

          {row.enableUberAll !== null
            ? row.enableUberAll.charAt(0).toUpperCase() +
              row.enableUberAll.slice(1)
            : "--"}
        </CustomizedTableCell>

        <CustomizedTableCell
          sx={{
            color:
              row?.enableUberAll === "error" || googleConnect === null
                ? "#8D9298"
                : "#1B2430",
          }}
          align="left"
        >
          {row?.productPlan?.name !== null ? row?.productPlan?.name : "--"}
        </CustomizedTableCell>
        <CustomizedTableCell padding="none" align="center">
          <Box mr={2} sx={{ display: "flex" }}>
            <Box sx={{ width: "35px" }}>
              {row?.enableUberAll === "error" && (
                <BootstrapTooltip
                  title={
                    row?.errorLog !== null && row?.errorLog !== undefined
                      ? row?.errorLog
                      : ""
                  }
                >
                  <ErrorOutlineIcon
                    sx={{ color: "#FF1616", marginTop: "8px" }}
                  />
                </BootstrapTooltip>
              )}
            </Box>
            <Box sx={{ width: "35px" }}>
              {googleConnect === null && (
                <BootstrapTooltip title={t("Connect_With_Google_Text")}>
                  <GoogleIcon
                    sx={{
                      color: "#8D9298",
                      marginTop: "8px",
                    }}
                  />
                </BootstrapTooltip>
              )}
            </Box>
            <Box sx={{ width: "35px" }}>
              <IconButton
                disabled={
                  row?.enableUberAll === "error" || googleConnect === null
                }
                onClick={(e) => handleClick(e, row)}
                variant="outlined"
              >
                <MoreVertIcon
                  disabled={
                    row?.enableUberAll === "error" || googleConnect === null
                  }
                />
              </IconButton>
            </Box>

            {/*  {row?.enableUberAll !== "error" && googleConnect !== null && (
              <div style={{ width: "22px" }}></div>
            )} */}

            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {selectedRow?.enableUberAll === "rejected" ? (
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    //  handleClose();
                    handleOpenAcceptModel(selectedRow?.id);
                  }}
                >
                  {t("accept")}
                </MenuItem>
              ) : selectedRow?.enableUberAll === "approved" ? (
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    //  handleClose();
                    handleOpenPlanModal(selectedRow);
                  }}
                >
                  {t("change_plan")}
                </MenuItem>
              ) : selectedRow?.enableUberAll === "pending" ? (
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    //  handleClose();
                    handleOpenAcceptModel(selectedRow?.id);
                  }}
                >
                  {t("Enable_directory")}
                </MenuItem>
              ) : (
                <>
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      //  handleClose();
                      handleOpenAcceptModel(selectedRow?.id);
                    }}
                  >
                    {t("accept")}
                  </MenuItem>

                  <Divider variant="middle" />
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      //  handleClose();
                      handleOpenRejectModel(selectedRow?.id);
                    }}
                  >
                    {t("Reject")}
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>
        </CustomizedTableCell>
      </CustomizedTableRow>
    </>
  );
};

export default Table;
