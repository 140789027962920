import { api, handleSessionOut } from "../contexts/JWTContext";
import { languageOptions } from "../assets/defaultLanguage";

export const getSelectedCustomer = async (handleSignOut) => {
  try {
    const res = await api.get(`/company/me`);
    if (res.data?.status === true) {
      localStorage.setItem("company", JSON.stringify(res?.data.data));
    }
  } catch (error) {
    handleSessionOut(
      error?.response?.status,
      error?.response?.data?.message,
      handleSignOut
    );
  }
};

export const LanguageChange = async (setCallApi, closeMenu) => {
  let userLng = "";
  const languageData = localStorage.getItem("i18nextLng");
  const stateData = JSON.parse(localStorage.getItem("user"));
  const defaultLanguage = Object.keys(languageOptions).find((item) => {
    userLng = languageOptions[`${languageData}`]?.name;
  });

  try {
    const res = await api.patch(`/user/${stateData?.id}`, {
      firstName: stateData?.firstName,
      lastName: stateData?.lastName,
      email: stateData?.email,
      language: userLng,
      role: stateData?.role,
    });

    if (res.status === 200) {
      if (setCallApi !== undefined) {
        setCallApi(true);
      }
      if (closeMenu !== undefined) {
        closeMenu();
      }
      localStorage.setItem("user", JSON.stringify(res.data.data));

      const UpdatedLanguage = Object.keys(languageOptions).find(
        (item) => languageOptions[`${item}`]?.name === res?.data?.data?.language
      );
      //     toast.success(t("Language_updated_successfully"));

      localStorage.setItem("i18nextLng", UpdatedLanguage);
    }
  } catch (error) {}
};

export const checkLocalStorageData = () => {
  const stateData = JSON.parse(localStorage.getItem("user"));
  const companyData = JSON.parse(localStorage.getItem("company"));
  const languageData = localStorage.getItem("i18nextLng");
  const accessToken = localStorage.getItem("accessToken");
  const loginUserData = JSON.parse(localStorage.getItem("stateData"));
  const rolePermissions = JSON.parse(localStorage.getItem("rolePermissions"));

  if (
    rolePermissions !== undefined &&
    rolePermissions !== null &&
    loginUserData !== undefined &&
    loginUserData !== null &&
    accessToken !== undefined &&
    accessToken !== null &&
    stateData !== undefined &&
    stateData !== null &&
    companyData !== undefined &&
    companyData !== null &&
    languageData !== undefined &&
    languageData !== null
  ) {
    return true;
  }
  return false;
};
