import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Autocomplete, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleIcon from "@mui/icons-material/Google";
import { useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  Paper as MuiPaper,
  Typography,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import CustomizedTableRow from "../../../../../components/CustomComponents/table/tableRow";
import CustomizedTableCell from "../../../../../components/CustomComponents/table/tableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const Table = ({
  row,
  index,
  handleConnectWithGoogle,
  setOpen,
  setDeleteId,
  closeMenu,
  handleOpenDeleteModel,
  handleClick,
  handleClose,
  selectedRow,
  anchorEl,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [googleConnect, setGoogleConnect] = useState(null);
  useEffect(() => {
    row?.socialLink?.length > 0 &&
      row?.socialLink?.forEach((element) => {
        if (element?.type === "google") {
          setGoogleConnect(element);
        }
      });
  }, []);

  let textColor;

  let dotColor;
  if (row?.logs?.status === "pending") {
    textColor = "#F8A92B";
    dotColor = "#F8A92B";
  } else if (row?.logs?.status === "accepted") {
    textColor = "#13CF8F";
    dotColor = "#13CF8F";
  } else if (row?.logs?.status === "rejected") {
    textColor = "#FC3652";
    dotColor = "#FC3652";
  } else {
    textColor = "black"; // Default color
  }
  return (
    <>
      <CustomizedTableRow
        sx={{ width: "80px" }}
        tabIndex={-1}
        key={`${row.id}-${index}`}
      >
        <CustomizedTableCell>{row.id}</CustomizedTableCell>
        <CustomizedTableCell align="left" sx={{ width: "150px" }}>
          {row?.company !== null
            ? row?.company?.name?.charAt(0).toUpperCase() +
              row?.company?.name?.slice(1).toLowerCase()
            : "--"}
        </CustomizedTableCell>
        <CustomizedTableCell align="left" sx={{ width: "200px" }}>
          {row.name}
        </CustomizedTableCell>

        <CustomizedTableCell>
          {row?.logs?.currentAddress?.addressLine?.length > 0 ? (
            <Typography>
              {row?.logs?.currentAddress?.addressLine?.join(" ")}
            </Typography>
          ) : null}

          {row?.logs?.currentAddress?.locality ? (
            <Typography>{row?.logs?.currentAddress?.locality}</Typography>
          ) : (
            ""
          )}
          {row?.logs?.currentAddress?.administrativeArea ? (
            <Typography>
              {row?.logs?.currentAddress?.administrativeArea}
            </Typography>
          ) : (
            ""
          )}
          {row?.logs?.currentAddress?.regionCode ? (
            <Typography>{row?.logs?.currentAddress?.regionCode}</Typography>
          ) : (
            ""
          )}
          {row?.logs?.currentAddress?.postalCode ? (
            <Typography>{row?.logs?.currentAddress?.postalCode}</Typography>
          ) : (
            ""
          )}
        </CustomizedTableCell>
        <CustomizedTableCell align="left">
          {row?.logs?.newAddress?.addressLine?.length > 0 ? (
            <Typography>
              {row?.logs?.newAddress?.addressLine?.join(" ")}
            </Typography>
          ) : null}

          {row?.logs?.newAddress?.locality ? (
            <Typography>{row?.logs?.newAddress?.locality}</Typography>
          ) : (
            ""
          )}
          {row?.logs?.newAddress?.administrativeArea ? (
            <Typography>{row?.logs?.newAddress?.administrativeArea}</Typography>
          ) : (
            ""
          )}
          {row?.logs?.newAddress?.regionCode ? (
            <Typography>{row?.logs?.newAddress?.regionCode}</Typography>
          ) : (
            ""
          )}
          {row?.logs?.newAddress?.postalCode ? (
            <Typography>{row?.logs?.newAddress?.postalCode}</Typography>
          ) : (
            ""
          )}
        </CustomizedTableCell>
        <CustomizedTableCell
          align="left"
          sx={{ color: textColor, width: "100px" }}
        >
          <FiberManualRecordIcon
            sx={{ fontSize: 10, color: dotColor, paddingRight: "3px" }}
          />
          {row?.logs?.status?.charAt(0).toUpperCase() +
            row?.logs?.status?.slice(1).toLowerCase()}
        </CustomizedTableCell>
        <CustomizedTableCell padding="none" align="center">
          <Box mr={2}>
            <IconButton
              disabled={row?.logs?.status === "accepted"}
              onClick={(e) => handleClick(e, row)}
              variant="outlined"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {selectedRow !== null &&
                selectedRow?.logs?.status !== "rejected" && (
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      // handleClose();
                      handleOpenDeleteModel(selectedRow?.id, "reject");
                      // handleOpenModal(row?.id);
                    }}
                  >
                    <Typography
                      aria-label="update_status"
                      size="large"
                      onClick={() => (
                        setOpen(true), setDeleteId(selectedRow?.id)
                      )}
                    >
                      {t("Reject")}
                    </Typography>
                  </MenuItem>
                )}

              {selectedRow !== null &&
                selectedRow?.logs?.status !== "accepted" && (
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      // handleClose();
                      handleOpenDeleteModel(selectedRow?.id, "done");
                      // handleOpenModal(row?.id);
                    }}
                  >
                    <Typography
                      aria-label="update_status"
                      size="large"
                      onClick={() => (
                        setOpen(true), setDeleteId(selectedRow?.id)
                      )}
                    >
                      {t("mark_done")}
                    </Typography>
                  </MenuItem>
                )}
            </Menu>
          </Box>
        </CustomizedTableCell>
      </CustomizedTableRow>
    </>
  );
};

export default Table;
