import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
} from "@mui/material";
import { languagesData } from "../../../../assets/defaultLanguage";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../components/Loaders/Loader";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import tick from "../../../../assets/images/tick.jpg";
import moment from "moment";
import { api } from "../../../../contexts/JWTContext";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const FetchModel = ({
  title,
  open,
  handleCloseNewRequestModel,
  reportsId,
  setOpenRequest,
  openRequest,
  setSelectLanguageFilter,
  openSubmit,
  setOpenSubmit,
  selectedReport,
  handleOpenSelectPlan,
  loading,
  setProductPlanId,
  selectedPrductPlanId,
  productPlanId,
  locationRegionCode,
}) => {
  const { t } = useTranslation();

  const [productPlans, setProductPlans] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [companyRequired, setCompanyRequired] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState("en");

  useEffect(() => {
    getAllProductPlans();
  }, [openRequest]);

  useEffect(() => {
    setProductPlanId(selectedPrductPlanId);
  }, [openRequest]);

  const getAllProductPlans = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`thirdParty/productPlans`);

      if (res.status === 200) {
        if (
          locationRegionCode === "US" ||
          locationRegionCode === "USA" ||
          locationRegionCode === "CA"
        ) {
          setProductPlans(
            res?.data?.data?.filter((item) =>
              item?.name.toLowerCase().includes("canada")
            )
          );
        } else {
          setProductPlans(
            res?.data?.data?.filter(
              (item) => !item?.name.toLowerCase().includes("canada")
            )
          );
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePlanIdChange = (item) => {
    setProductPlanId(item?.planId);
  };

  return (
    <>
      <div>
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", height: "400px" } }}
          open={open}
          onClose={handleCloseNewRequestModel}
        >
          <DialogTitle
            sx={{
              lineHeight: "28px",
              fontWeight: 700,
              fontSize: "20px",
              color: "#1b2430",
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid
                container
                sx={{
                  overflowY: "scroll",
                }}
              >
                {productPlans !== null &&
                  productPlans?.map((item) => {
                    return (
                      item?.status === "ACTIVE" && (
                        <Grid
                          item
                          sm={12}
                          md={12}
                          lg={12}
                          mb={12}
                          sx={{ margin: "0px", padding: "0px" }}
                          key={item.id}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                              /*   defaultChecked={
                                item?.planId===productPlanId ||
                                item?.planId === selectedPrductPlanId
                              } */
                              checked={
                                item?.planId === productPlanId
                                // item?.planId === selectedPrductPlanId
                              }
                              onChange={() => handlePlanIdChange(item)}
                            />
                            <Typography>{item?.name}</Typography>
                          </Box>
                        </Grid>
                      )
                    );
                  })}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <CommonButton
              displayWhite="true"
              onSubmit={() => {
                handleCloseNewRequestModel();
              }}
              label={t("Cancel")}
            />

            <CommonButton
              //  isLoading={loading}
              type="submit"
              label={t("submit")}
              // onSubmit={() => getExportReports()}
              onSubmit={handleOpenSelectPlan}
              disabled={selectedReport === null}
            />
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
