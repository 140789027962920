import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  Tooltip,
  tooltipClasses,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../../../../../components/Loaders/Loader";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../Styles/style";
import AddIcon from "@mui/icons-material/Add";
import SingleHour from "./components/signleHour";
import Collapsible from "./components/collapsible";
import NotFound from "../../../../../../../components/NotFound/NotFound";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  border: "1px solid #EBEBEC",
  // minWidth: "101px",
  height: "30px",
  padding: "6px",
  margin: "0.3rem",
  paddingLeft: "15px",
  paddingRight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "800px",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const MoreOpeningHours = ({
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  indexValue,
  setSelectedTab,
  selectedTab,
  setIndexValue,
  setSelectedLocationsBulkUpdate,
  selectedLocationsBulkUpdate,
  onCancel,
  handleCloseFilterModel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allHours, setAllHours] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [hoursForFilters, setHoursForFilters] = useState([]);
  const [allLocationSelectedHours, setAllLocationSelectedHours] = useState([]);
  const [periodsArr, setPeriodsArr] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [expandAllPanels, setExpandAllPanels] = useState(true);
  const [newTimeArr, setNewTimeArr] = useState([]);
  const [cancelState, setCancelState] = useState(false);

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  const handleExpandAllPanels = () => {
    const arr = [];
    allLocationSelectedHours?.map((item) => arr.push(item?.hoursTypeId));
    setExpandedPanels(arr);
    /*   const _expandedPanels = arr;
    setExpandedPanels([..._expandedPanels]); */
    setExpandAllPanels(false);
  };
  const handleCollapseAllPanelsPanels = () => {
    setExpandedPanels([]);
    setExpandAllPanels(true);
  };

  useEffect(() => {
    getAllHours();
  }, []);

  /*  const handleLocationSelectedMoreHours = () => {
    if (locationDetailsData?.result?.google?.moreHours) {
      if (locationDetailsData?.result?.google?.moreHours?.length > 0) {
        setAllLocationSelectedHours(
          locationDetailsData?.result?.google?.moreHours
        );
      }
    
    }
  }; */

  const getAllHours = async () => {
    setIsLoading(true);
    let locationIds = [];
    if (selectedLocationsBulkUpdate?.length > 0) {
      selectedLocationsBulkUpdate.map((item) => {
        locationIds.push(item.id);
      });
    }
    try {
      const res = await api.get(
        `/locationListing/getMoreHoursCommonAttributes?ids=${locationIds?.toString()}`
      );
      if (res.status === 200) {
        setHoursForFilters(res?.data?.data);

        setAllHours(res?.data?.data);
      }
      setIsLoading(false);
      handleScroll();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleAddHour = (data, ind) => {
    setTouchedValue(true);
    let tempObj = {
      hoursTypeId: "",
      periods: [],
    };
    tempObj = {
      hoursTypeId: data?.hoursTypeId,
      periods: [],
    };
    setAllHours(allHours.filter((el, i) => i !== ind));
    allLocationSelectedHours?.push(tempObj);
    setAllLocationSelectedHours([...allLocationSelectedHours]);
    /*  const arr = [];
    allLocationSelectedHours?.map((item) => arr.push(item?.hoursTypeId));
    setExpandedPanels(arr); */
  };
  const handleRemoveHour = (data, ind) => {
    setTouchedValue(true);

    const filteredData = hoursForFilters?.find(
      (item) => item?.hoursTypeId === data?.hoursTypeId
    );
    allHours?.push(filteredData);
    setAllHours([...allHours]);
    setAllLocationSelectedHours(
      allLocationSelectedHours.filter((el, i) => i !== ind)
    );
    /*  const arr = [];
    allLocationSelectedHours?.map((item) => arr.push(item?.hoursTypeId));
    setExpandedPanels(arr); */
  };

  const handleUpdateRegularHour = async () => {
    setIsLoading(true);
    let locationIds = [];
    if (selectedLocationsBulkUpdate?.length > 0) {
      selectedLocationsBulkUpdate.map((item) => {
        locationIds.push(item.id);
      });
    }
    let filteredOpenArr = [...allLocationSelectedHours];

    /*  filteredOpenArr.map((itemOne, ind) => {
         const openedArr = itemOne?.periods.filter(
        (item, index) => item?.closed === false
      ); 
      itemOne.periods = openedArr;
    }); */

    filteredOpenArr?.forEach((itemOne, ind) => {
      let finalArr = [];
      itemOne?.periods?.forEach((item) => {
        if (item?.mergedTimes?.length === 0) {
          let tempObjTwo = {
            openDay: item?.openDay,
            openTime: {
              hours: 0,
              minutes: 0,
            },
            status:
              item?.closed === false
                ? "open"
                : item?.closed === true
                ? "close"
                : "neutral",
            closeDay: item?.closeDay,
            closeTime: {
              hours: 0,
              minutes: 0,
            },
          };
          finalArr.push(tempObjTwo);
        } else if (
          item?.mergedTimes?.length > 0 &&
          item?.mergedTimes?.length === 1
        ) {
          let tempObj = {
            openDay: item?.openDay,
            openTime: {
              hours: item?.mergedTimes[0]?.openHours?.hours,
              minutes: item?.mergedTimes[0]?.openHours?.minutes
                ? item?.mergedTimes[0]?.openHours?.minutes
                : 0,
            },
            status:
              item?.closed === false
                ? "open"
                : item?.closed === true
                ? "close"
                : "neutral",
            closeDay: item?.closeDay,
            closeTime: {
              hours: item?.mergedTimes[0]?.closedHours?.hours,
              minutes: item?.mergedTimes[0]?.closedHours?.minutes
                ? item?.mergedTimes[0]?.closedHours?.minutes
                : 0,
            },
          };

          finalArr.push(tempObj);
        } else {
          item?.mergedTimes?.map((itemTwo) => {
            let tempObjTwo = {
              openDay: item?.openDay,
              openTime: {
                hours: itemTwo?.openHours?.hours,
                minutes: itemTwo?.openHours?.minutes
                  ? itemTwo?.openHours?.minutes
                  : 0,
              },
              status:
                item?.closed === false
                  ? "open"
                  : item?.closed === true
                  ? "close"
                  : "neutral",
              closeDay: item?.closeDay,
              closeTime: {
                hours: itemTwo?.closedHours?.hours,
                minutes: itemTwo?.closedHours?.minutes
                  ? itemTwo?.closedHours?.minutes
                  : 0,
              },
            };
            finalArr.push(tempObjTwo);
          });
        }
        itemOne.periods = finalArr;
      });
    });

    try {
      setDisplayConfirmationBox(false);
      const res = await api.patch(`/locationListing/bulkMoreHours`, {
        locationIds: locationIds,
        moreHours: filteredOpenArr,
      });

      if (res.status === 200) {
        setIsLoading(false);
        toast.success("Updated Successfully");
        // setAllLocationSelectedHours([]);

        // onCancel();

        if (indexValue !== null) {
          let selectedValue = indexValue;

          setSelectedTab(selectedValue);
        }
        if (cancelState === true) {
          onCancel();
          handleCloseFilterModel();
        }
        setTouchedValue(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      handleCloseFilterModel();
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      //  getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };

  const handleScroll = () => {
    if (containerRef.current !== null) {
      containerRef.current.scrollTop = 0;
    }
  };
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateRegularHour}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            /*    getLocationDetails={getLocationDetails}
            location={location} */

            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      <Grid container sx={{ padding: "18px" }}>
        <Grid
          item
          xs={10}
          sm={11}
          md={11.5}
          lg={11.5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography className={classes.modalHeader}>
            {t("More_Opening_Hours")}
          </Typography>
          <BootstrapTooltip title={t("more_hour_des")}>
            <Typography
              sx={{
                marginLeft: "5px",
                marginTop: "4px",
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon />
            </Typography>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={2} sm={1} md={0.5} lg={0.5}>
          <IconButton
            autoFocus
            onClick={() => {
              handleOpen();
              setCancelState(true);
            }}
            className="delete_button"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <Grid ref={containerRef} className={classes.gridContainertwoBulk}>
        {isLoading ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.loaderBox}
          >
            <Loader />
          </Grid>
        ) : (
          <Grid conatiner>
            <Grid container sx={{ padding: "18px" }}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <Typography className={classes.modalHeader}>
                  {t("add_more_hours")}
                </Typography>
              </Grid>
              {allHours?.length > 0 ? (
                <Grid container sx={{ marginTop: "6px" }}>
                  {allHours?.map((item, index) => (
                    <Item
                      onClick={() => handleAddHour(item, index)}
                      key={index}
                    >
                      <AddIcon sx={{ color: "#06BDFF", fontWeight: "700" }} />
                      <Typography className={classes.moreHoursText}>
                        {item?.displayName}
                      </Typography>
                    </Item>
                  ))}
                </Grid>
              ) : (
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <NotFound text={t("No_Data_Found")} noMargin={false} />
                </Grid>
              )}
            </Grid>

            {allLocationSelectedHours?.length > 0 && (
              <Grid container sx={{ padding: "18px" }}>
                <Grid item md={11.5} sm={11.5} xs={11.5} lg={11.5}>
                  <Typography className={classes.modalHeader}>
                    {t("more_hours")}
                  </Typography>
                </Grid>
                <Grid item md={0.5} sm={0.5} xs={0.5} lg={0.5}>
                  {/*  <ExpandMoreIcon /> */}
                  {expandAllPanels ? (
                    <IconButton onClick={handleExpandAllPanels}>
                      <ExpandMoreIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleCollapseAllPanelsPanels}>
                      <ExpandLessIcon />
                    </IconButton>
                  )}
                </Grid>

                {allLocationSelectedHours?.length > 0 &&
                  allLocationSelectedHours?.map((item, index) => {
                    const result =
                      hoursForFilters?.length > 0 &&
                      hoursForFilters?.find(
                        (data) => data?.hoursTypeId === item?.hoursTypeId
                      );
                    //  setPeriodsArr(item?.periods);
                    return (
                      <Grid container sx={{ marginTop: "12px" }}>
                        <Grid item md={11.5} sm={11.5} xs={11.5} lg={11.5}>
                          <Collapsible
                            title={result?.displayName}
                            handleChange={handleExpandPanel}
                            expanded={expandedPanels.includes(
                              item?.hoursTypeId
                            )}
                            id={item?.hoursTypeId}
                            ComponentToRender={
                              <SingleHour
                                allLocationSelectedHours={
                                  allLocationSelectedHours
                                }
                                index={index}
                                item={item}
                                // location={location}
                                // locationDetailsData={locationDetailsData}
                                setTouchedValue={setTouchedValue}
                              />
                            }
                          />
                        </Grid>
                        <Grid item md={0.5} sm={0.5} xs={0.5} lg={0.5}>
                          <IconButton
                            autoFocus
                            onClick={() => handleRemoveHour(item, index)}
                            className="delete_button"
                            sx={{ marginTop: "5px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      <Divider />

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridButtons}
      >
        <CommonButton
          displayWhite="true"
          label={t("Back")}
          onSubmit={handleCancelButton}
        />
        <CommonButton
          onSubmit={handleUpdateRegularHour}
          label={t("Save")}
          disabled={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default MoreOpeningHours;
