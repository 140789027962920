import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import FacebookModel from "../../../../components/Models/FacebookConnectModal";
import { useLocation } from "react-router-dom";
import TableComponent from "./Components/table";
import { useStyles } from "../styles/style";
import { checkLocalStorageData } from "../../../../utils/LocalStorageHelpers";
import {
  Box,
  Grid,
  Paper as MuiPaper,
  Table,
  Divider,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  Checkbox,
  Menu,
  MenuItem,
  Button,
  tooltipClasses,
  Tooltip,
  Autocomplete,
  createFilterOptions,
  TextField,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Loader from "../../../../components/Loaders/Loader";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TitleHeader from "../../../../components/CustomComponents/title";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import LocationNotFound from "../../../../components/CustomComponents/Errors/NoLocationFound";
import { countriesData } from "../../../../assets/countriesList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useAuth from "../../../../hooks/useAuth";
import BulkUpdateModel from "./Components/bulkUpdateModel";
import { languageOptions } from "../../../../assets/defaultLanguage";
import FilterModal from "./Components/FilterModelBulk";
import { LanguageChange } from "../../../../utils/LocalStorageHelpers";
const Paper = styled(MuiPaper)(spacing);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const EnhancedTableHead = (props) => {
  const { order, orderBy, t, handleChangeCheckButton, selectAll } = props;

  const headCells = [
    {
      alignment: "left",
      label: (
        <Checkbox
          checked={selectAll}
          //  disabled={socialLinkState===false ? true : false}
          onChange={(e) => handleChangeCheckButton(e)} //   inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "name", alignment: "left", label: t("LocationTableName") },
    { id: "city", alignment: "left", label: t("Address") },
    //  { id: "customer", alignment: "left", label: t("TablePartner") },
    // { id: "products", alignment: "center", label: t("TableProducts") },
    { id: "createdAt", alignment: "left", label: t("CreatedAt") },
    { id: "status", alignment: "left", label: t("TableStatus") },
    { id: "locationStatus", alignment: "left", label: t("LocationStatus") },
    { id: "actions", alignment: "center", label: t("TableAction") },
  ];
  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {headCells.map((headCell) => (
          <CustomizedTableCell
            key={headCell?.id}
            align={headCell?.alignment}
            padding={headCell?.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell?.id ? order : false}
          >
            {headCell.label}
          </CustomizedTableCell>
        ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

function MyLocations() {
  const { search } = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDataValid = checkLocalStorageData();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [allCompanies, setAllCompanies] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [anchorBulk, setAnchorBulk] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [countries, setCountries] = useState([]);
  const [allCompanyLocations, setAllCompayLocations] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [defaultValueLocation, setDefaultvalueLocation] = useState(null);
  const [selectAllCountries, setSelectAllCountries] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [socialLinkState, setSocialLinkState] = useState(false);
  const [location, setLocation] = useState(null);
  const { signOut, permissionsAcess, user } = useAuth();
  const [openRequestModel, setOpenRequestModel] = useState(false);
  const [openDeleteRejectRequestModel, setOpenDeleteRejectRequestModel] =
    useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [openEditBulkModel, setOpenBulkEditModel] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState("");
  const [openFacebookModel, setOpenFacebookModel] = useState(false);
  const filter = createFilterOptions();
  const languageData = localStorage.getItem("i18nextLng");
  const stateData = JSON.parse(localStorage.getItem("user"));
  let selectedLanguage = "";
  let userLang = "";
  selectedLanguage = languageOptions[userLang];
  const [email, setEmail] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [allGroups, setAllGroups] = useState(null);
  const [selectedLocationsBulkUpdate, setSelectedLocationsBulkUpdate] =
    useState([]);
  const [openFilterBulkModel, setOpenFilterBulkModel] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [website, setWebsite] = useState(null);
  const [locationName, setLocationName] = useState(null);
  const [disconnectLocations, setDisconnectLocations] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [googleState, setGoogleState] = useState(false);
  const [facebookState, setFacebookState] = useState(false);

  useEffect(() => {
    if (isDataValid) {
      if (languageData !== "en" || stateData?.language === null) {
        LanguageChange();
      }
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, []);

  useEffect(() => {
    if (openFilterBulkModel === false && openEditBulkModel === false) {
      resetHandler();
    }
  }, [openFilterBulkModel, openEditBulkModel]);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );

      filteredPermissions?.forEach((item) => {
        if (
          item?.permissionName === "create" ||
          item?.permissionName === "Account Management"
        ) {
          setAddPermission(true);
        }
        if (
          item?.permissionName === "edit" ||
          item?.permissionName === "Account Management"
        ) {
          setEditPermission(true);
        }
        if (
          item?.permissionName === "delete" ||
          item?.permissionName === "Account Management"
        ) {
          setDeletePermission(true);
        }
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setViewPermission(true);
        }
      });
    }
  }, []);

  useEffect(async () => {
    if (!(search?.length > 0) && isDataValid) {
      await getAllLocation();
    }
    // await getAllSubscriptions();
  }, [rowsPerPage, page, selectedCountries, selectedGroup]);

  useEffect(() => {
    if (isDataValid) {
      getSelectedCustomer();
      getAllGroups();
    }
  }, []);

  useEffect(() => {
    if (disconnectLocations?.length === 0) {
      setSelectAll(false);
    } else if (disconnectLocations?.length === 1) {
      if (location !== null) {
        const selectedLoc = allCompanies?.results?.find(
          (item) => item?.id === disconnectLocations[0]
        );

        const googleExist = selectedLoc?.socialLink?.find(
          (item) => item?.type === "google"
        );
        if (googleExist !== undefined && googleExist !== null) {
          setGoogleState(true);
        } else {
          setGoogleState(false);
        }

        const facebookExist = selectedLoc?.socialLink?.find(
          (item) => item?.type === "facebook"
        );

        if (facebookExist !== undefined && facebookExist !== null) {
          setFacebookState(true);
        } else {
          setFacebookState(false);
        }
      }
    } else if (disconnectLocations?.length > 1) {
      setGoogleState(false);
      setFacebookState(false);
    }
  }, [disconnectLocations]);
  const getAllGroups = async () => {
    // setLocationLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data);

        // setLocationLoading(false);
      }
    } catch (error) {
      //  setLocationLoading(false);
      setAllGroups(null);
    }
  };

  useEffect(() => {
    if (isDataValid) {
      getAllCountryLocations();
    }
  }, []);
  const getSelectedCustomer = async () => {
    try {
      const res = await api.get(`/company/me`);
      if (res.data?.status === true) {
        localStorage.setItem("company", JSON.stringify(res?.data.data));
      }
    } catch (error) {}
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const handleOpenTemplateMenu = (e) => {
    handleClose();
    setOpenDefaultModel(true);

    setSelectedValue(e);
  };

  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };

  const handleOpenRequestModel = () => {
    setOpenRequestModel(true);
  };
  const handleCloseRequestModel = () => {
    setOpenRequestModel(false);
  };

  const handleOpenRejectRequestModel = () => {
    setOpenDeleteRejectRequestModel(true);
    setOpenRequestModel(false);
    setAnchorEl(null);
  };
  const handleCloseRejectRequestModel = () => {
    setOpenDeleteRejectRequestModel(false);
  };

  useEffect(async () => {
    if (search?.length > 0) {
      setIsLoading(true);

      const params = new URLSearchParams(search);
      const myArray = params.get("state").split("/");
      try {
        const res = await api.get(`google/auth-code` + search);
        if (res.data?.status === true) {
          await createSocialLink(res.data?.data);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);

        await getAllLocation();
      }
    }
  }, [search]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setAnchorBulk(null);
    setSelectedRow(null);
  };
  const handleOpenDeleteModel = (id) => {
    setOpen(id);
    setAnchorEl(null);
    setAnchorBulk(null);
  };
  const handleClick = (event, selectedData) => {
    setSelectedRow(selectedData);

    setAnchorEl(event.currentTarget);
  };
  const handleClickMenu = (event) => {
    setAnchorBulk(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleDeleteLocation = async () => {
    try {
      const res = await api.delete(
        `/location/${selectedRow?.id}?finalDelete=false`
      );
      if (res.status === 200) {
        setOpen(false);
        toast.success("Location delete request sent to Admin");
        setDisconnectLocations([]);
        getAllLocation();
      }
    } catch (error) {
      setOpen(false);
      setDisconnectLocations([]);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSocialLink = async (data) => {
    try {
      const res = await api.post(`/socialLink`, {
        type: "google",
        refreshToken: data?.refreshToken,
        accountId: data?.accountId,
        locationId: data?.locationId,
        referenceId: data?.referenceId,
      });

      if (res?.data?.status === true) {
        await getAllLocation();
        toast.success("successfully attached Google account");
      }
    } catch (error) {}
  };

  /*   const getAllSubscriptions = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/subscriptions`);
      if (res.status === 200) {
        setAllSubscriptions(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
 */

  const removeDuplicates = (arr, prop) => {
    return arr.filter(
      (obj, index, self) =>
        index === self.findIndex((el) => el[prop] === obj[prop])
    );
  };

  const getAllLocation = async () => {
    setIsLoading(true);
    try {
      const countryCodeString = selectedCountries
        ?.map((item) => item?.countryCode)
        .join(",");

      const res = await api.get(
        `/location/search?limit=${rowsPerPage}&page=${page + 1}`,
        {
          params: {
            searchTerm: filterWithName,
            countryCode: countryCodeString.toString(),
            isLocationPage: true,
            groupId: selectedGroup !== null ? selectedGroup?.id : "",
          },
        }
      );
      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          setAllCompanies(res.data.data);
        } else {
          setAllCompanies(null);
        }
        setIsLoading(false);
        //  setFilterWithName("");
        if (res.data.data.results.length > 0) {
          let defaultlocation = null;
          defaultlocation = res.data.data.results?.find(
            (item, index) => item.defaultLocation !== null
          );
          setDefaultvalueLocation(defaultlocation);
          const socialLinkObj = res.data.data.results?.some(
            (item) => item?.socialLink?.length > 0
          );
          setSocialLinkState(socialLinkObj);
        } else {
          setDefaultvalueLocation(null);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setAllCompanies(null);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const getCountryWiseLocations = (countriesData, locationsArray) => {
    return countriesData
      .map((country) => {
        const locations = locationsArray
          .filter(
            (location) =>
              location.countryCode === country.code ||
              location.country === country.name
          )
          .map((location) => location);
        return {
          countryName: country.name,
          countryCode: country.code,
          locationsArray: locations,
        };
      })
      .filter((country) => country.locationsArray.length > 0);
  };

  const getAllCountryLocations = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: true,
        },
      });
      if (res.status === 200) {
        setIsLoading(false);
        setFilterWithName("");
        let countriesArr = [];

        if (res?.data?.data?.results?.length > 0) {
          const countryWiseLocations = getCountryWiseLocations(
            countriesData,
            res?.data?.data?.results
          );
          setCountries(countryWiseLocations);

          let data = res?.data?.data?.results;
          const index = data.findIndex((object) => {
            return object.defaultLocation !== null;
          });
          if (index > 0) {
            data.unshift(data.splice(index, 1)[0]);
          }
          setAllCompayLocations(data);
        } else {
          setAllCompayLocations(res?.data?.data?.results);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };

  async function fetchURL(id, locationId, fromReconnectWithValidToken) {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        if (fromReconnectWithValidToken === true) {
          window.location.href = res?.data.data + "&state=reconnect";
        } else {
          window.location.href = res?.data.data /* + "&state=" + id */;
        }
      }
    } catch (error) {}

    // if (response?.data?.url) {
    //   window.location.href = response.data.url;
    // }
  }

  async function fetchURLReconnect(id) {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        window.location.href = res?.data.data + "&state=" + id;
      }
    } catch (error) {}

    // if (response?.data?.url) {
    //   window.location.href = response.data.url;
    // }
  }

  const handleConnectWithGoogle = (fromReconnect) => {
    fetchURL(
      `${defaultValueLocation?.id}/${defaultValueLocation?.placeId}`,
      defaultValueLocation?.id,
      fromReconnect
    );
  };
  const handleConnectWithFacebook = async () => {
    try {
      const res = await api.get(`facebook/auth-url`);
      if (res.data?.status === true) {
        // window.location.href = res?.data.data /* + "&state=" + id */;
        // login();
      }
    } catch (error) {}
  };

  const handleNavigation = (data) => {
    navigate(`/user/token-expire/location/${data?.id}`);
  };
  const handleFacebookConnect = async (data) => {
    try {
      const res = await api.get(
        `facebookConnect/auth-url?locationId=${data?.id}`
      );

      if (res.data?.status === true) {
        window.location.href = res?.data.data;
      }
    } catch (error) {}
  };
  const handleRequestedToConnect = async () => {
    setRequestLoading(true);
    try {
      // Make your API call here to update the status from "rejected" to "requested"
      const res = await api.patch(`/location/${selectedRow?.id}`, {
        status: "requested",
      });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setRequestLoading(false);
        setOpenRequestModel(false);

        getAllLocation();
        handleClose();
      }

      // setShowModal(true); // Set the showModal state to true after the API call is successful
    } catch (error) {
      setRequestLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };
  const handleOpenBulkEditModel = () => {
    setOpenBulkEditModel(true);
  };
  const handleCloseBulkEditModel = () => {
    setOpenBulkEditModel(false);
  };

  const handleOpenFilterModel = () => {
    setOpenFilterBulkModel(true);
  };
  const handleCloseFilterModel = () => {
    setOpenFilterBulkModel(false);
  };

  const handleOpenFacebookModel = (rowData) => {
    setSelectedRow(rowData);
    setOpenFacebookModel(true);
  };
  const handleCloseFacebookModel = () => {
    setOpenFacebookModel(false);
    setSelectedRow(null);
  };

  const getFilteredLocationsByCountries = (value) => {
    let filteredLocations = [];
    if (value?.length > 0) {
      value?.forEach((itemOne) => {
        allCompanyLocations?.forEach((location) => {
          if (
            location?.countryCode !== null &&
            location?.countryCode !== "" &&
            location?.countryCode === itemOne?.code
          ) {
            filteredLocations?.push(location);
          }
        });
      });
      if (filteredLocations?.length > 0) {
        setAllLocations(filteredLocations);
        setLocation(filteredLocations[0]);
      } else {
        setAllLocations(allCompanyLocations);
        setLocation(allCompanyLocations[0]);
      }
    } else {
      setAllLocations(allCompanyLocations);
      setLocation(allCompanyLocations[0]);
    }
  };

  const handleDeleteRejectedLocation = async () => {
    // setRequestLoading(true);
    try {
      const res = await api.delete(
        `/location/${selectedRow?.id}?finalDelete=true`
      );
      if (res.status === 200) {
        // setRequestLoading(false);
        closeMenu();
        toast.success(t("Location_deleted_successfully"));

        handleCloseRejectRequestModel();
        getAllLocation();
      }
    } catch (error) {
      // setRequestLoading(false);
      closeMenu();
      toast.error(error?.response?.data?.message);
      handleCloseRejectRequestModel();
    }
  };

  const handleChangeCountries = (value) => {
    if (value.find((option) => option.id === 0)) {
      // Check if all options are selected
      if (countries?.length === selectedCountries?.length) {
        setSelectedCountries([]);
        setSelectAllCountries(false);
      } else {
        setSelectAllCountries(true);
        setSelectedCountries(countries);
        //  setLocationRequired(false);
        setAllLocations(allCompanyLocations);
        setLocation(allCompanyLocations[0]);
      }
    } else {
      setSelectedCountries(value);
      getFilteredLocationsByCountries(value);

      if (value?.length === countries?.length) {
        setSelectAllCountries(true);
      } else {
        setSelectAllCountries(false);
      }
      setPage(0);
    }
  };

  const handleNavigationToGroups = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 3, page: 0 },
    });
  };
  const handleChangeGroups = (value) => {
    setPage(0);
    setSelectedGroup(value);
  };
  const resetHandler = () => {
    setLocationName(null);
    setEmail(null);
    setSelectLanguage("");
    setPhoneNumber(null);
    setKeywords([]);
    setWebsite(null);
  };

  const submitBulkUpdate = async () => {
    let locationIds = [];
    if (selectedLocationsBulkUpdate?.length > 0) {
      selectedLocationsBulkUpdate.map((item) => {
        locationIds.push(item.id);
      });
    }
    setBulkLoading(true);

    const requestData = {};

    // Add properties to the requestData object only if they are not empty or null
    if (locationIds.length > 0) requestData.locations = locationIds;
    if (selectLanguage) requestData.defaultLanguage = selectLanguage?.value;
    if (email) requestData.businessEmail = email;
    if (locationName) requestData.name = locationName;
    if (phoneNumber) requestData.telephone = phoneNumber;
    if (website) requestData.website = website;
    if (keywords?.length > 0) requestData.brandNames = keywords.join(",");

    try {
      const res = await api.patch(`/location/bulkedit-locations`, requestData);
      if (res.status === 200) {
        handleCloseBulkEditModel();
        handleCloseFilterModel();
        setBulkLoading(false);
        resetHandler();
        getAllLocation();
        toast.success(res.data.message);
      }
    } catch (error) {
      handleCloseBulkEditModel();
      handleCloseFilterModel();
      setBulkLoading(false);
      resetHandler();
      toast.error(error?.response?.data?.message);
    }
  };

  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    {
    }
    if (e.target.checked) {
      for (var i = 0; i < allCompanies?.results?.length; i++) {
        disconnectLocations.push(allCompanies?.results[i]?.id);
      }

      const uniqueLocation = new Set(disconnectLocations);
      {
      }
      setDisconnectLocations([...uniqueLocation]);
    } else {
      setDisconnectLocations([]);
    }
  };

  const handleTaskChecked = (e, index, locationId, data) => {
    setLocation(data);
    if (e.target.checked) {
      disconnectLocations.push(locationId);
      {
      }
    } else {
      var locationIndex = disconnectLocations.indexOf(locationId);
      disconnectLocations.splice(locationIndex, 1);
    }
    if (allCompanies?.results?.length > 0) {
      const filtered = allCompanies?.results?.filter(
        (item) => item?.socialLink?.length > 0
      );
      {
      }

      if (filtered?.length === disconnectLocations?.length) {
        setDisconnectLocations([]);
        setSelectAll(true);
      } else {
        setSelectAll(false);
        setDisconnectLocations(filtered);
      }
    }
    setDisconnectLocations([...disconnectLocations]);
  };
  const handleDisconnectSelectedLocations = async () => {
    setBulkLoading(true);
    try {
      const res = await api.patch(`socialLink/removeSocialLinks`, {
        type: selectedValue,
        locationIds: disconnectLocations,
      });

      if (res.status === 200) {
        getAllLocation();
        toast.success("Location Disconnect Successfully");

        setOpenDefaultModel(false);
        setSelectAll(false);
        closeMenu(false);
        setDisconnectLocations([]);
      }
      setBulkLoading(false);
    } catch (error) {
      setBulkLoading(false);
      setDisconnectLocations([]);
      toast.error("Location Disconnect Failed");
    }
  };
  return (
    <>
      {openFacebookModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openFacebookModel}
          onClose={handleCloseFacebookModel}
        >
          <FacebookModel
            location={selectedRow}
            onConfirm={handleFacebookConnect}
            onCancel={handleCloseFacebookModel}
          />
        </Dialog>
      )}

      {openEditBulkModel && (
        <BulkUpdateModel
          handleCloseBlukUpdateModel={handleCloseBulkEditModel}
          openEditBulkModel={openEditBulkModel}
          email={email}
          setEmail={setEmail}
          selectLanguage={selectLanguage}
          setSelectLanguage={setSelectLanguage}
          keywords={keywords}
          setKeywords={setKeywords}
          setLocationName={setLocationName}
          locationName={locationName}
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
          website={website}
          setWebsite={setWebsite}
          handleOpenFilterModel={handleOpenFilterModel}
          addPermission={addPermission}
          editPermission={editPermission}
          deletePermission={deletePermission}
          viewPermission={viewPermission}
          submitBulkUpdate={submitBulkUpdate}
          bulkLoading={bulkLoading}
          selectedLocationsBulkUpdate={selectedLocationsBulkUpdate}
          handleConnectWithGoogle={handleConnectWithGoogle}
        />
      )}
      {openRequestModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openRequestModel}
          onClose={handleCloseRequestModel}
        >
          <Delete
            title={t("location_Requests")}
            description={t("send_request_again")}
            onConfirm={handleRequestedToConnect}
            onCancel={handleCloseRequestModel}
            loading={requestLoading}
          />
        </Dialog>
      )}

      {openDeleteRejectRequestModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDeleteRejectRequestModel}
          onClose={handleCloseRejectRequestModel}
        >
          <Delete
            title={t("location_Requests")}
            description={t("delete_confirm")}
            onConfirm={handleDeleteRejectedLocation}
            onCancel={handleCloseRejectRequestModel}
            loading={requestLoading}
          />
        </Dialog>
      )}
      <Helmet title="Locations" />
      <TitleHeader
        title={t("LocationTitle")}
        subHeading={t("LocationList")}
        googleButton={allCompanies?.results?.length > 0 ? true : false}
        // facebookButton={true}
        handleConnectWithGoogle={handleConnectWithGoogle}
        googleButtonName={t("Connect_With_Google")}
        handleConnectWithFacebook={handleConnectWithFacebook}
        facebookButtonName={t("facebook_connect")}
        addPermission={addPermission}
        editPermission={editPermission}
        viewPermission={viewPermission}
        groupButtonFromEmotionAi={
          allCompanies && allCompanies?.count > 0 ? true : false
        }
        locationViewPermission={viewPermission}
        groupButtonName={t("manage_groups")}
        handleNavigationToGroups={handleNavigationToGroups}
        extraButton={true}
        locationPage={true}
        extraButtonName={t("Bulk_update")}
        handleDefaultTemplate={handleOpenFilterModel}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={2}>
            {openDefaultModel && (
              <Dialog
                maxWidth
                maxHeight
                open={openDefaultModel}
                onClose={handleCloseTemplateMenu}
              >
                <Delete
                  title={t("Disconnect_title")}
                  description={t(
                    `${t("disconnect_des1")} ${
                      selectedValue === ""
                        ? t("disconnect_des2")
                        : selectedValue
                    } ?`
                  )}
                  onConfirm={handleDisconnectSelectedLocations}
                  onCancel={handleCloseTemplateMenu}
                  loading={bulkLoading}
                />
              </Dialog>
            )}
            <Grid item xs={2.5} sm={2.5} md={2.5}>
              <Autocomplete
                multiple
                disablePortal
                id="combo-box-demo"
                options={countries?.length > 0 ? countries : []}
                getOptionLabel={(option) => option?.countryName}
                className="Autocomplete-field"
                value={selectedCountries}
                onChange={(event, value) => {
                  handleChangeCountries(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.countryName === value.countryName
                }
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (countries?.length > 0) {
                    return [{ id: 0, countryName: "Select All" }, ...filtered];
                  } else {
                    return [];
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected || selectAllCountries}
                    />

                    <Typography sx={{ textTransform: "capitalize" }}>
                      {" "}
                      {option?.countryName}{" "}
                    </Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("Filter_locations_By_country")}
                    {...params}
                    required
                    name="language"
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item xs={2.5} sm={2.5} md={2.5}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={
                  allGroups !== null && allGroups?.rows?.length > 0
                    ? allGroups?.rows
                    : []
                }
                getOptionLabel={(option) => (option?.name ? option?.name : "")}
                className="Autocomplete-field"
                value={selectedGroup}
                onChange={(e, value) => {
                  handleChangeGroups(value);
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("filter_locations_groups")}
                    {...params}
                    required
                    name="language"
                  />
                )}
              ></Autocomplete>
            </Grid>

            <Grid item xs={12} sm={5} md={5}>
              <SearchField
                filterByName={filterByName}
                onSubmit={getAllLocation}
                placeholder={t("filter_location_by_name")}
                filterWithName={filterWithName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CommonButton
                isLoading={isLoading}
                onSubmit={getAllLocation}
                label={t("Search")}
                alignment={true}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: "flex-end" }}>
            {/*  {allCompanies && allCompanies?.count > 0 && (
              <Grid
                item
                xs={12}
                sm={1}
                md={1.5}
                lg={1.5}
                sx={{
                  display: "flex",

                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              >
                <CommonButton
                  isLoading={isLoading}
                  onSubmit={handleOpenBulkEditModel}
                  label={t("bulk_edit_location")}
                  alignment={true}
                />
              </Grid>
            )} */}
            {disconnectLocations.length > 0 && (
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  aria-owns={anchorBulk ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickMenu}
                  endIcon={<KeyboardArrowDownIcon />}
                  style={{
                    width: "150px",
                    height: "52px",
                    // radius: "8px",
                    border: "1px solid #E0E0E0",
                    color: "#1B2430",
                    padding: "14px, 20px, 14px, 20px",
                    background: "#FFFF",
                    borderRadius: "8px",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginLeft: "8px",
                    cursor: "pointer",
                  }}
                >
                  {t("Actions")}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorBulk}
                  open={Boolean(anchorBulk)}
                  onClose={handleClose}
                >
                  {editPermission && viewPermission ? (
                    <MenuItem
                      sx={{
                        color: "#1B2430",
                        fontSize: "14px",
                        lineHeight: "20px",
                        cursor: "pointer",
                        "&:hover": {
                          background: "#EBEBEC",
                        },
                      }}
                      onClick={(e) => handleOpenTemplateMenu("google")}
                    >
                      {t("Disconnect_with_google")}
                    </MenuItem>
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <MenuItem
                          disabled
                          sx={{
                            color: "#1B2430",
                            fontSize: "14px",
                            lineHeight: "20px",
                            cursor: "pointer",
                            "&:hover": {
                              background: "#EBEBEC",
                            },
                          }}
                          onClick={(e) => handleOpenTemplateMenu("google")}
                        >
                          {t("Disconnect_with_google")}
                        </MenuItem>
                      </span>
                    </BootstrapTooltip>
                  )}
                  <Divider />

                  {/*     {editPermission && viewPermission ? (
                    <MenuItem
                      sx={{
                        color: "#1B2430",
                        fontSize: "14px",
                        lineHeight: "20px",
                        cursor: "pointer",
                        "&:hover": {
                          background: "#EBEBEC",
                        },
                      }}
                      onClick={(e) => {
                        handleOpenBulkEditModel();
                        handleClose();
                      }}
                    >
                      {t("bulk_edit_location")}
                    </MenuItem>
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <MenuItem
                          disabled
                          sx={{
                            color: "#1B2430",
                            fontSize: "14px",
                            lineHeight: "20px",
                            cursor: "pointer",
                            "&:hover": {
                              background: "#EBEBEC",
                            },
                          }}
                          onClick={(e) => {
                            handleOpenBulkEditModel();
                            handleClose();
                          }}
                        >
                          {t("bulk_edit_location")}
                        </MenuItem>
                      </span>
                    </BootstrapTooltip>
                  )} */}

                  {/*  {multiLocation===true ? (
                    <MenuItem onClick={(e) => handleOpenTemplateMenu("google")}>
                      Disconnect with google
                    </MenuItem>
                  ) : singleLocationTrue && googleState===true ? (
                    <MenuItem onClick={(e) => handleOpenTemplateMenu("google")}>
                      Disconnect with google
                    </MenuItem>
                  ) : null} */}
                </Menu>
              </Grid>
            )}
          </Grid>

          {allCompanies && allCompanies?.count > 0 ? (
            <Grid
              container
              marginTop={disconnectLocations.length > 0 ? "0rem" : "1rem"}
              spacing={disconnectLocations.length > 0 ? 0 : 6}
            >
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      t={t}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={allCompanies?.count}
                      getAllLocation={getAllLocation}
                      selectAll={selectAll}
                      handleChangeCheckButton={handleChangeCheckButton}
                      socialLinkState={socialLinkState}
                    />
                    <TableBody>
                      {allCompanies?.results?.length > 0 ? (
                        allCompanies?.results?.map((row, index) => {
                          return (
                            <TableComponent
                              row={row}
                              index={index}
                              handleConnectWithGoogle={handleConnectWithGoogle}
                              handleClose={handleClose}
                              handleTaskChecked={handleTaskChecked}
                              disconnectLocations={disconnectLocations}
                              handleNavigation={handleNavigation}
                              setOpen={setOpen}
                              setDeleteId={setDeleteId}
                              closeMenu={closeMenu}
                              selectedRow={selectedRow}
                              handleOpenDeleteModel={handleOpenDeleteModel}
                              handleClick={handleClick}
                              anchorEl={anchorEl}
                              handleFacebookConnect={handleFacebookConnect}
                              handleRequestedToConnect={
                                handleRequestedToConnect
                              }
                              handleOpenRequestModel={handleOpenRequestModel}
                              handleOpenRejectRequestModel={
                                handleOpenRejectRequestModel
                              }
                              handleOpenFacebookModel={handleOpenFacebookModel}
                              addPermission={addPermission}
                              editPermission={editPermission}
                              deletePermission={deletePermission}
                              viewPermission={viewPermission}
                            />
                          );
                        })
                      ) : (
                        <Typography variant="h3">{t("data_found")}</Typography>
                      )}
                    </TableBody>
                    <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <Delete
                        title={t("Request_Delete_Location")}
                        description={t("location_del_desc_new")}
                        onConfirm={() => handleDeleteLocation()}
                        onCancel={handleClose}
                      />
                    </Dialog>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={allCompanies?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          ) : (
            allCompanies === null && (
              <Box className={classes.locationbox}>
                <LocationNotFound
                  text={t("No_Location_Added")}
                  subtext={t("add_location_text")}
                />
              </Box>
            )
          )}
        </>
      )}

      {openFilterBulkModel && (
        <FilterModal
          title={t("Bulk_Edit")}
          subTitle={t("bulk_update_subheading")}
          allLocations={allCompanyLocations}
          openModalFilter={openFilterBulkModel}
          handleCloseModalFilter={handleCloseFilterModel}
          allGroups={allGroups}
          countries={countries}
          getCountryWiseLocations={getCountryWiseLocations}
          setSelectedLocationsBulkUpdate={setSelectedLocationsBulkUpdate}
          selectedLocationsBulkUpdate={selectedLocationsBulkUpdate}
          locationAddPermission={addPermission}
          locationViewPermission={viewPermission}
          handleOpenBulkEditModel={handleOpenBulkEditModel}
        />
      )}
    </>
  );
}

export default MyLocations;
