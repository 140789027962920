import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Paper as MuiPaper,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  TextField as MuiTextField,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components/macro";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Editors from "../../../../components/CustomComponents/Editor/Editors";
import ReviewsCard from "../../../../components/ReviewSource/ReviewsCard";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { api } from "../../../../contexts/JWTContext";
import { useStyles } from "../styles/style";

const TextField = styled(MuiTextField)(spacing);

const EditTask = ({ onCancel, selectedTask, alignment }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [users, setUsers] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [companyId, setCompanyId] = useState(null);
  const [locationId, setLocationId] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [status, setStatus] = useState("");
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [userRequired, setUserRequired] = useState(false);
  const [companyIdRequired, setCompanyIdRequired] = useState(false);
  const [titleRequired, setTitleRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };
  useEffect(async () => {
    getAllCompanies();
    //  getTasks()
  }, [companyId]);
  useEffect(() => {
    if (selectedTask) {
      let tempUsersArray = [];
      selectedTask?.taskUsers?.length > 0 &&
        selectedTask?.taskUsers?.map((item) => {
          tempUsersArray.push(item?.user);
        });
      setCompanyId(selectedTask?.company);
      setSelectedUser(tempUsersArray);
      setTitle(selectedTask?.title);
      setDescription(selectedTask?.description);

      setUsers(selectedTask?.company?.users);
      setLocationId(selectedTask?.location);
      setStatus(selectedTask?.status);
      setSelectedReview(selectedTask?.review);
    }
  }, [selectedTask]);

  const handleCompanyChange = (option) => {
    getAllCompanies();
    setCompanyId(option);
    setLocationId(null);
    if (allCompanies?.locations?.length !== 0) {
      setSelectedCompany(option?.locations);
      setUsers(option?.users);
    } else {
      setSelectedCompany("no location found with this id");
      setUsers("no user found with this id");
    }
  };

  const handleLocationChange = (option) => {
    setLocationId(option);
  };

  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/admin/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);

        if (companyId) {
          let temp = [...res.data.data];
          let findMe = temp.find((e) => e?.id === companyId?.id);
          if (findMe) {
            setSelectedCompany(findMe?.locations);
          }
        }
      }
    } catch (error) {
      // toast.error("error");
      // setSubmitting(false);
    }
  };

  const createTask = async () => {
    const newStr = description.replace(/(<([^>]+)>)/gi, "");

    if (title === "") {
      setTitleRequired(true);
    }

    if (newStr === "") {
      setDescriptionRequired(true);
    }
    if (locationId === null) {
      setLocationRequired(true);
    }
    if (companyId === null) {
      setCompanyIdRequired(true);
    }
    if (selectedUser?.length === 0) {
      setUserRequired(true);
    }

    if (
      newStr !== "" &&
      title !== "" &&
      locationId !== null &&
      companyId !== null
    ) {
      let userIds = [];
      selectedUser?.map((item) => {
        userIds.push(item?.id);
      });
      try {
        setIsLoading(true);

        if (selectedTask) {
          const res = await api.patch("/tasks/" + selectedTask?.id, {
            title: title,
            description: description,
            companyId: companyId?.id,
            locationId: locationId?.id,
            status: status,
            userIds: userIds?.length > 0 ? userIds.toString() : "",
          });
          if (res.status === 200) {
            toast.success("Task Updated Successfully", {
              onClose: () => {
                setIsLoading(false);

                onCancel(true);
              },
            });
            // setAllCompanies(res.data.data);
          }
        }
      } catch (error) {
        setIsLoading(false);

        toast.error("error");
      }
    }

    ////
  };
  const handleUserChange = (option) => {
    setSelectedUser(option);
  };

  return (
    <>
      <Box className={classes.allfieldss}>
        <Box
          sx={{
            overflowY: "scroll",
            maxHeight: "55vh",
          }}
        >
          <Grid container>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <Box sx={{ marginBottom: "8px" }}>
                {selectedReview && (
                  <>
                    <Typography className={classes.locations}>
                      {t("Linked_Review")}
                    </Typography>
                    <ReviewsCard
                      item={selectedReview}
                      location={selectedReview?.location}
                      fromEditTask="true"
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <Typography className={classes.taskTitle}>
              {t("Task_Tile")}
            </Typography>
            <TextField
              error={titleRequired}
              style={{ width: "100%", borderRadius: "8px" }}
              value={title}
              onChange={(e) => {
                setTitleRequired(false);
                setTitle(e.target.value);
              }}
              InputProps={{
                inputProps: {
                  style: {
                    marginLeft: "10px",
                    marginRight: "10px",
                    //  marginBottom: "8px",
                    width: "100%",
                  },
                },
              }}
              margin="normal"
              // label={t("TitleForm")}
            />
          </Grid>

          <Box sx={{ minWidth: alignment === true ? 120 : "auto" }}>
            <Typography className={classes.locations}>{t("Status")}</Typography>
            <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                // label={t("StatusTask")}
                onChange={handleChangeStatus}
              >
                <MenuItem value={"open"}>{t("Open")}</MenuItem>
                <MenuItem value={"inprogress"}>{t("InProgress")}</MenuItem>
                <MenuItem value={"completed"}>{t("Completed")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {selectedTask?.company !== null && (
            <>
              <Typography className={classes.locations}>
                {t("Company")}
              </Typography>

              <Autocomplete
                // error={userRequired}
                disablePortal
                id="combo-box-demo"
                options={allCompanies?.results ? allCompanies?.results : []}
                getOptionLabel={(option) => (option?.name ? option?.name : "")}
                sx={{ width: "100%" }}
                value={companyId}
                onChange={(e, value) => {
                  handleCompanyChange(value);
                  setCompanyIdRequired(false);
                }}
                renderInput={(params) => (
                  <TextField
                    // placeholder={t("Select_User")}
                    {...params}
                    required
                    // label={t("TaskLocation")}
                    error={companyIdRequired}
                    name="language"
                  />
                )}
              ></Autocomplete>
            </>
          )}
          {companyId && (
            <>
              <Typography className={classes.locations}>
                {t("Select_User")}
              </Typography>

              <Autocomplete
                multiple
                disablePortal
                id="combo-box-demo"
                options={users ? users : []}
                getOptionLabel={(option) =>
                  option?.firstName ? option.firstName : ""
                }
                sx={{ width: "100%" }}
                value={selectedUser}
                onChange={(e, value) => {
                  handleUserChange(value);
                  setUserRequired(false);
                }}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.firstName + " " + option?.lastName}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("Select_User")}
                    {...params}
                    required
                    // label={t("TaskLocation")}
                    error={userRequired}
                    name="language"
                  />
                )}
              ></Autocomplete>
            </>
          )}
          {companyId && (
            <>
              <Typography className={classes.locations}>
                {t("Select_Locations")}
              </Typography>

              <Autocomplete
                // error={locationRequired}
                disablePortal
                id="combo-box-demo"
                options={selectedCompany?.length > 0 ? selectedCompany : []}
                getOptionLabel={(option) =>
                  option?.internalName
                    ? option?.internalName +
                      " " +
                      "(" +
                      (option?.addressLine1 !== "" &&
                      option?.addressLine1 !== null &&
                      option?.addressLine1 !== undefined
                        ? option?.addressLine1 + "," + " "
                        : "") +
                      (option?.city ? option?.city : "") +
                      ")"
                    : ""
                }
                sx={{ width: "100%" }}
                value={locationId}
                onChange={(e, value) => {
                  handleLocationChange(value);
                  setLocationRequired(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={locationRequired}
                    // name="language"
                    // label={t("TaskCompany")}
                  />
                )}
              ></Autocomplete>
            </>
          )}

          <Grid container>
            <Typography className={classes.locations}>
              {t("Description")}
            </Typography>

            <Grid item xs={12} sm={12} md={12}>
              <Editors
                value={description}
                onChange={(e) => {
                  setDescriptionRequired(false);
                  setDescription(e);
                }}
              />
              {descriptionRequired && (
                <Typography sx={{ color: "#d32f2f" }}>
                  {t("Field_req")}
                </Typography>
              )}
            </Grid>
            {/* <Grid item>
          <TextField
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            InputProps={{
              inputProps: {
                style: {
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginBottom: "8px",
                },
              },
            }}
            margin="normal"
            label={"Description"}
          />
        </Grid> */}
            <Divider my={2} />
          </Grid>
        </Box>
        <Box sx={{ marginTop: "8px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <CommonButton
                displayWhite="true"
                onSubmit={onCancel}
                label={t("Cancel")}
              />
              <CommonButton
                onSubmit={() => {
                  createTask();
                  // setSubmitting(true);
                }}
                // disabled={Submitting}
                loading={isLoading}
                label={t("Update_Task")}
              />
            </Box>
            {/*   <LoadingButton
              onClick={() => {
                createTask();
              }}
              sx={{ ml: 4 }}
              // type="submit"
              variant="contained"
              loading={isLoading}
              // disabled={isSubmitting}
              style={{
                width: "100%",

                color: "primary",
                fontWeight: "600",
                borderRadius: "8px",
                fontSize: "1.25rem",
                lineHeight: 1.5,
                padding: "0.5rem 1rem",
              }}
            >
              {t("Update_Task")}
            </LoadingButton> */}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default EditTask;
