import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";

const color = {
  pink: "#f68897",
  lightyellow: "#fdf2df",
  orange: "orange",
  gray: "#757575",
};

export const useStyles = makeStyles((theme) => ({
  boxContainer: {
    paddingLeft: "20px",
  },
  negativeText: {
    bgcolor: color?.lightyellow,
    color: color?.pink,
  },
  positiveText: {
    bgcolor: color?.lightyellow,
    color: color?.orange,
  },

  reviewText: {
    color: color?.gray,
    fontSize: "10px",
  },

  titleHeadingSentiments: {
    fontWeight: 500,
    textAlign: "center",
    fontSize: "12px",
    padding: "10px",
    lineheight: "18px",
  },
  gridContainer: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: "10px",
  },
  titleTextSentiments: {
    fontWeight: 700,
    textAlign: "Left",
    fontSize: "32px",
    paddingLeft: "52px",
    lineheight: "40px",
  },

  gridContainerSentiment: {
    display: "flex",
    height: "40px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  sentimentHeaderImage: {
    height: "40px",
    width: "40px",
  },
  cardContainerMainSnippets: {
    borderRadius: "0px",
    padding: "8px 8px 8px 8px",
  },
  titleHeading: {
    fontWeight: 600,
    fontSize: "14px",
  },
  titleTextActionPlans: {
    font: "Work Sans",
    fontWeight: 400,
    lineHeight: "20px",
    fontSize: "14px",
    color: "#495059",
    paddingTop: "5px",
  },
  cardContainerReviewsSnippets: {
    borderRadius: "0px",
    padding: "8px 8px 8px 8px",
    maxWidth: "100%",
    height: "100%",
    paddingY: "8px",
  },
  titleTextActionPlans: {
    font: "Work Sans",
    fontWeight: 400,
    lineHeight: "20px",
    fontSize: "14px",
    color: "#495059",
    paddingTop: "5px",
  },
  titleHeading: {
    fontWeight: 600,
    fontSize: "14px",
  },
  titleHeadingFilters: {
    fontWeight: 600,
    fontSize: "14px",
    paddingTop: "17px",
  },
  titleHeadingFiltersDash: {
    fontWeight: 600,
    fontSize: "14px",
    paddingTop: "18px",
    textAlign: "center",
  },
  reviewModalStyle: {
    padding: "15px",
  },
}));
