import React, { useState, useEffect } from "react";

import styled from "styled-components/macro";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Box,
  Paper,
  Stack,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { api } from "../../../../../contexts/JWTContext";
import { spacing } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { alpha } from "@material-ui/core/styles";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../../components/Loaders/Loader";
import SubscriptionCard from "../../AdminMyLocation/Components/SubscriptionCard";
import AccountsCard from "./AccountsCard";
import { useNavigate } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  Mainbox: {
    padding: "24px",
  },
  connectBox: {
    borderRadius: "8px",
    backgroundColor: "#F6F6F7",
    padding: "24px",
    marginBottom: "8px",
  },
  searchIcon: {
    bgcolor: "background.paper",

    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
  },
  subscriptionContainer: {
    marginTop: "20px",
  },
}));
function Products({
  // allSubscriptions,
  getLocationById,
  getLocationId,
  setIsLoading,
  isLoading,
  singleLocation,
  /*  locationSelectedSubscription,
  setSubscriptionArr,
  subscriptionArr, */
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [confirmSubscription, setConfirmSubscription] = useState(false);

  const handleSubmit = () => {
    setConfirmSubscription(true);
  };
  const handleSubscriptionClose = () => {
    setConfirmSubscription(false);
    //  setSubBoxOpen(false);
  };
  const onCancel = () => {
    navigate("/admin/locations");
  };

  /*   const updateSubscriptionHandler = async () => {
    setIsLoading(true);

    try {
      const res = await api.post(`/locationSubscription/bulk`, {
        locationId: getLocationId,
        subscriptionId: subscriptionArr,
      });
      if (res.data.status===true) {
        toast.success(res?.data?.message);
        getLocationById(getLocationId);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);

      setIsLoading(false);
    }
  }; */
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ width: "100%" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className={classes.Mainbox} component={Paper}>
            <>
              <Grid container spacing={2}>
                <AccountsCard
                  socialLink={singleLocation?.socialLink}
                  id={singleLocation?.id}
                  placeId={singleLocation?.placeId}
                  setIsLoading={setIsLoading}
                  getLocationById={getLocationById}
                  location={location}
                />
              </Grid>
              {/*     <Grid
                container
                spacing={2}
                className={classes.subscriptionContainer}
              >
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <Box sx={{ fontSize: "20px", fontWeight: "700" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h3">
                        {t("Products")} <br />
                      </Typography>
                    </Stack>

                    <Typography sx={{ fontSize: "14px" }}>
                      {t("sol_desc")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={7} lg={7}>
                  <Box direction="column" spacing={2}>
                    {allSubscriptions?.results?.map((subscription, index) => {
                      const result = locationSelectedSubscription.find(
                        (data) =>
                          data?.subscription?.id===subscription.id 
                      );

                      return (
                        <SubscriptionCard
                          item={subscription}
                          // allSubscription={false}
                          result={result}
                          getLocationById={getLocationById}
                          getLocationId={getLocationId}
                          setIsLoading={setIsLoading}
                          confirmSubscription={confirmSubscription}
                          handleSubscriptionClose={handleSubscriptionClose}
                          subscriptionArr={subscriptionArr}
                          setSubscriptionArr={setSubscriptionArr}
                          updateSubscriptionHandler={updateSubscriptionHandler}
                        />
                      );
                    })}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <CommonButton
                        displayWhite="true"
                        onSubmit={onCancel}
                        //  disabled={isSubmitting}
                        label={t("Cancel")}
                      />
                      <CommonButton
                        //   loading={isLoading}
                        type="submit"
                        //   disabled={isSubmitting}
                        label={t("Update_Subscription")}
                        onSubmit={handleSubmit}
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid> */}
            </>
          </Box>
        </>
      )}
    </Grid>
  );
}

export default Products;
