import {
  Grid,
  Box,
  Typography,
  Modal,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Delete from "../../components/Models/DeleteModal/DeleteModel";
import { api } from "../../contexts/JWTContext";
import { toast } from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddReview from "../../pages/UserPages/UserReviewSource/Components/AddReview";
import { Chip } from "@mui/material";
import styled from "styled-components/macro";
import useAuth from "../../hooks/useAuth";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "87vh",
  // height: "250px",
  // height: "470px",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: "8px",
  boxShadow: 24,

  p: 4,
};
const useStyles = makeStyles((theme) => ({
  sourceImage: {
    width: "40px",
    height: "40px",
  },
  sourceName: {
    //   fontFamily: "Work Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  subscribedText: {
    background: "#06BDFF",
    marginRight: "12px",
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "3px",
    gap: "4px",
    width: "67px",
    height: "20px",
    borderRadius: "800px",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
}));
const Container = ({
  index,
  thirdPartReviewData,
  id,
  name,
  image,
  onSubmit,
  color,
  reviewData,
  setIsLoading,
  onClickOpenModel,
  getReviews,
  allReviewSource,
  selectedLocation,
  locationId,
  viewPermission,
  addPermission,
}) => {
  const theme = useTheme();
  const { user } = useAuth();

  const [reviewId, setReviewId] = useState(null);
  const [openModalAddReview, setOpenModalAddReview] = React.useState(false);
  const [modelDataReviewSource, setDataReviewSource] = React.useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const baseURL = process.env.REACT_APP_BASE_URL;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selected, setSelected] = useState(false);
  const [failed, setFailed] = useState(false);
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState(null);
  const [googleSelected, setGoogleSelected] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    reviewData?.results?.map((item) => {
      const data = item?.thirdPartyReviewSource?.id;
      if (data === thirdPartReviewData?.id) {
        if (item?.status === "failed") {
          setFailed(true);
        } else {
          setFailed(false);
        }
        setSelected(true);
        setSelectedId(item?.id);
      }
    });

    // const data = reviewData?.results?.[0]?.thirdPartyReviewSource?.id;
  }, [reviewData]);
  const handleClose = () => {
    setOpen(false);
    // setColor(0);
  };
  useEffect(() => {
    selectedLocation?.socialLink?.map((item) => {
      if (item?.type === "google" && thirdPartReviewData?.name === "google") {
        setGoogleSelected(item);
        setSelected(true);
      }
    });
  }, [selectedLocation]);
  const handleId = () => {};
  const handleOpen = (selected, deleteId, item) => {
    setDeleteId(deleteId);
    setReviewId(item);
    selected ? setOpen(true) : setOpenModalAddReview(true);
    // setDataReviewSource(null);
    //  setOpenModalAddReview(true);
    // setColor(1);
  };

  const handleCloseModal = (apicall) => {
    setOpenModalAddReview(false);
    if (apicall) {
      getReviews();
    }
  };

  const handleDeleteReviewSource = async () => {
    try {
      const res = await api.delete(`/reviewSource/${deleteId}`);
      if (res.status === 200) {
        setOpen(false);

        toast.success(
          res.data.message
            ? res.data.message
            : "Review Source removed successfully"
        );
        setIsLoading(false);
        getReviews();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleUpdateReviewSource = async () => {
    try {
      const body = {
        status: "open",
      };
      const res = await api.patch(`/reviewSource/${deleteId}`, body);
      if (res.status === 200) {
        setOpen(false);
        toast.success("Review Source updated successfully");
        setIsLoading(false);
        getReviews();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {(viewPermission && addPermission) || user?.role === "super-admin" ? (
        <Box
          sx={{
            bgcolor: "white",
            display: "flex",
            padding: "14px",
            // minWidth: "300px",
            alignItems: "center",
            justifyContent: "space-between",
            // border: selected ? "1px solid #06BDFF " : "1px solid #E0E0E0",
            borderRadius: "8px",
            // marginRight: "14px",
            // marginTop: "24px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (thirdPartReviewData?.name === "google") {
              navigate(`/user/edit_location?state=${selectedLocation?.id}/`, {
                state: { row: selectedLocation },
              });
            } else {
              handleOpen(selected, selectedId, thirdPartReviewData);
            }
          }}
        >
          <Grid sx={{ pr: 2 }}>
            <img
              alt=""
              className={classes.sourceImage}
              // style={{ width: 40, height: 40 }}
              src={`${baseURL}/${thirdPartReviewData?.image}`}
            />
          </Grid>
          <Grid container justifyContent="space-between">
            {/*  <Typo name={thirdPartReviewData?.displayName} variant="h4" /> */}
            <Typography className={classes.sourceName}>
              {thirdPartReviewData?.displayName}
            </Typography>

            {selected && !failed ? (
              /*  <Typography className={classes.subscribedText}>
              Subscribed
            </Typography> */
              <Chip
                sx={{
                  height: "22px",
                  borderRadius: "800px",
                  marginRight: "12px",
                }}
                //  className={classes.subscribedText}
                label={t("Subscribed")}
                color={"primary"}
                m={0}
              />
            ) : failed ? (
              <Tooltip title={t("Failed")}>
                <ErrorOutlineIcon
                  sx={{ color: "#FF1616", marginRight: "12px" }}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </Grid>
          <Grid>
            <KeyboardArrowRightIcon
              sx={{ color: "#8D9298", marginTop: "2px" }}
            />
          </Grid>
        </Box>
      ) : (
        <BootstrapTooltip title={t("authorized_access")}>
          <Box
            sx={{
              bgcolor: "white",
              display: "flex",
              padding: "14px",
              // minWidth: "300px",
              alignItems: "center",
              justifyContent: "space-between",
              // border: selected ? "1px solid #06BDFF " : "1px solid #E0E0E0",
              borderRadius: "8px",
              // marginRight: "14px",
              // marginTop: "24px",
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.26)",
            }}
            // onClick={() => {
            //   if (thirdPartReviewData?.name==="google") {
            //     navigate(`/user/edit_location?state=${selectedLocation?.id}/`, {
            //       state: { row: selectedLocation },
            //     });
            //   } else {
            //     handleOpen(selected, selectedId, thirdPartReviewData);
            //   }
            // }}
          >
            <Grid sx={{ pr: 2 }}>
              <img
                alt=""
                className={classes.sourceImage}
                // style={{ width: 40, height: 40 }}
                src={`${baseURL}/${thirdPartReviewData?.image}`}
                style={{ color: "rgba(0, 0, 0, 0.26)", opacity: 0.6 }}
              />
            </Grid>
            <Grid container justifyContent="space-between">
              {/*  <Typo name={thirdPartReviewData?.displayName} variant="h4" /> */}
              <Typography className={classes.sourceName}>
                {thirdPartReviewData?.displayName}
              </Typography>

              {selected && !failed ? (
                /*  <Typography className={classes.subscribedText}>
              Subscribed
            </Typography> */
                <Chip
                  sx={{
                    height: "22px",
                    borderRadius: "800px",
                    marginRight: "12px",
                  }}
                  //  className={classes.subscribedText}
                  label={t("Subscribed")}
                  color={"primary"}
                  m={0}
                />
              ) : failed ? (
                <Tooltip title={t("Failed")}>
                  <ErrorOutlineIcon
                    sx={{ color: "#FF1616", marginRight: "12px" }}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </Grid>
            <Grid>
              <KeyboardArrowRightIcon
                sx={{ color: "rgba(0, 0, 0, 0.26)", marginTop: "2px" }}
                disabled
              />
            </Grid>
          </Box>
        </BootstrapTooltip>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Review_Source")}
          description={t(
            `${t("desc_del_1")} ${reviewId?.name} ${t("desc_del_2")}`
          )}
          name={thirdPartReviewData?.displayName}
          reviewSourceData={reviewId}
          handleUpdateReviewSource={handleUpdateReviewSource}
          onConfirm={() => handleDeleteReviewSource()}
          onCancel={handleClose}
          failedIcon={failed}

          // failed={failed}
        />
      </Dialog>

      {openModalAddReview && (
        /*   <Modal
          open={openModalAddReview}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ padding: "0px" }}
        > */
        <Dialog
          //  fullScreen={fullScreen}
          open={openModalAddReview}
          onClose={handleCloseModal}
          //  aria-labelledby="responsive-dialog-title"
        >
          <AddReview
            name={thirdPartReviewData?.displayName}
            modelDataReviewSource={modelDataReviewSource}
            onCancel={handleCloseModal}
            allReviewSource={allReviewSource}
            locationId={locationId?.id}
            reviewSourceData={reviewId}
            failed={failed}
          />
        </Dialog>
      )}
    </>
  );
};

export default Container;
