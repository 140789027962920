import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";

const color = {
  pink: "#f68897",
  lightyellow: "#fdf2df",
  orange: "orange",
  gray: "#757575",
};

export const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    // textAlign: "center",
    // marginBottom: "6px",
    marginLeft: "10px",
    marginTop: "12px",
  },
  fieldLabell: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    // textAlign: "center",
    // marginBottom: "6px",
    // marginLeft: "10px",
    marginTop: "12px",
  },
  dashLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    // marginBottom: "6px",
    //  marginLeft: "10px",
    marginTop: "6px",
    marginTop: "20px",
  },
  viewMoreFilterButton: {
    height: "58px",
  },
  compareFilterButton: {
    height: "40px",
  },
  viewMoreFilterTypo: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
  },
  cardbox: {
    backgroundColor: "#F6F6F7",
    borderRadius: "none",
    height: "auto",
  },
  cardboxViewMore: {
    backgroundColor: "#FFFFF",
    borderRadius: "none",

    height: "auto",
  },

  headRatingName: {
    fontWeight: 700,
    fontSize: "32px",
    color: "#1B2430",
  },
  subHeadRatingName: {
    fontWeight: 400,
    // marginTop: "8px",
    lineHeight: "14px",
    fontSize: "14px",
    color: "#495059",
  },
  descriptionReviews: {
    fontWeight: 400,
    // marginTop: "8px",
    lineHeight: "24px",
    fontSize: "16px",
    color: "#495059",
  },
  subHeadRatingNameLocation: {
    fontWeight: 400,
    // marginTop: "8px",
    lineHeight: "14px",
    fontSize: "14px",
    color: "#8D9298",
    marginTop: "10px",
    marginBottom: "10px",
  },

  subHeadRatingNameBox: {
    fontWeight: 700,
    // marginTop: "8px",
    lineHeight: "24px",
    fontSize: "18px",
    color: "#1B2430",
    marginTop: "10px",
    marginBottom: "4px",
  },

  subHeadRatingNameBoxRep: {
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: "18px",
    color: "#1B2430",
    marginBottom: "4px",
  },
  subHeadRatingReviewDetail: {
    fontWeight: 700,
    // marginTop: "8px",
    lineHeight: "24px",
    fontSize: "18px",
    color: "#1B2430",
    marginTop: "10px",
    marginBottom: "4px",
    padding: "8px 0px 8px 14px",
    // paddingBottom: "10px",
  },
  ratingHead: {
    lineHeight: "18px",
    fontSize: "12px",
    fontWeight: 400,
  },
  ratingHeadings: {
    lineHeight: "18px",
    fontSize: "20px",
    fontWeight: 900,
  },
  ratingHeadingsLoc: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  ratingHeadingsLoc2: {
    lineHeight: "20px",
  },
  ratingHeadingsLocBold: {
    fontWeight: 700,
    // marginTop: "8px",
    lineHeight: "24px",
    fontSize: "18px",
    color: "#1B2430",
    marginTop: "10px",
    marginBottom: "4px",
  },
  ratingHeadingsLocAddress: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#757575",
  },
  // subHeadLocationName: {
  // fontWeight: 400,
  // fontSize: "14px",
  // color: "#495059",
  // alignItems: "center",
  // },
  // subHeadLocationName: {
  // fontWeight: 400,
  // fontSize: "14px",
  // lineHeight: "20px",
  // color: "#8D9298",
  // },
  checkBox: {
    color: "#06BDFF",
    fontSize: "14px",
    fontWeight: 700,
  },

  editButton: {
    padding: "14px 20px",
    width: "100%",
    height: "48px",
    background: "#06BDFF",
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
  },
  containerSpacing: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  mainGridCont: {
    padding: "16px",
    paddingBottom: "10px",
  },
  mainGridCont2: {
    marginTop: "10px",
    padding: "16px",
    paddingBottom: "10px",
  },
  actionPlansGroupContainer: {
    // marginTop: "10px",
    padding: "16px",
    //  paddingBottom: "10px",
  },
  imgAlign: {
    marginBottom: "-6px",
  },
  textAligns: {
    marginLeft: "10px",
  },
  redIconClass: {
    color: "#FF1616",
    width: "15px",
    marginBottom: "-7px",
    marginLeft: "5px",
  },
  greenIconClass: {
    color: "#13CF8F",
    width: "15px",
    marginBottom: "-7px",
    marginLeft: "5px",
  },
  orangeIconClass: {
    color: "#F8A92B",
    width: "15px",
    marginBottom: "-7px",
    marginLeft: "5px",
  },

  redIconClassGraph: {
    color: "#FF1616",
    width: "15px",
    marginBottom: "-7px",
    // marginLeft: "5px",
  },
  greenIconClassGraph: {
    color: "#13CF8F",
    width: "15px",
    marginBottom: "-7px",
    // marginLeft: "5px",
  },
  orangeIconClassGraph: {
    color: "#F8A92B",
    width: "15px",
    marginBottom: "-7px",
    //  marginLeft: "5px",
  },
  tableHeading: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
  },
  mainContainer: {
    padding: "24px 14px 24px 14px",
    //textAlign: "center",
  },
  graphContainer: {
    padding: "0px 14px 0px 14px",
  },
  tableContainer: {
    padding: "0px 14px 0px 14px",
  },
  viewMoreButtonGrid: {
    textAlign: "start",
    paddingTop: "14px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },
  whiteBackground: {
    background: "white",
    width: "100%",
  },
  customTooltip: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
    color: "#fff",
    padding: "10px",
    border: "none",
  },
  customTooltipReview: {
    backgroundColor: " #1B2430",
    borderRadius: "8px",
    color: "#fff",
    padding: "10px",
    border: "none",
    padding: "20px",
  },
  toolTipStyleReview: {
    // fontSize: "10px",
    // fontWeight: 700,
    textAlign: "center",
    //padding: "2px 0px",
    fontSize: "12px",
    color: "#EBEBEC",
  },
  toolTipStyleValueReview: {
    // fontSize: "10px",
    textAlign: "center",
    fontWeight: 700,
    padding: "2px 0px",
    fontSize: "13px",
    color: "#F6F6F7",
  },
  toolTipStyle: {
    fontSize: "10px",
    fontWeight: 700,
    padding: "5px 0px",
  },
  errorMessageStyle: {
    color: "#FF1616",
    marginLeft: "3px",
  },
  mainGridContCursor: {
    padding: "16px",
    paddingBottom: "10px",
    cursor: "pointer",
  },
  mainGridContCursorProgressBar: {
    padding: "5px 16px 10px 16px",
    // paddingBottom: "10px",
    cursor: "pointer",
  },
  emailReportTitle: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "20px",
    color: "#1b2430",
  },
  emailReportLabel: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "17px",
    color: "#1b2430",
  },
  emailReportSubLabel: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "14px",
    color: "#1b2430",
  },
  reviewsubHeadBox: {
    paddingY: "16px",
    backgroundColor: "#E0F7FF",
    borderRadius: "8px",
  },
  reviewsubHeadTextError: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "4px",
    marginLeft: "6px",
    color: "#FF0000",
  },
  reviewsubHeadText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "4px",
    marginLeft: "6px",
  },
  removeFileButton: {
    cursor: "pointer",
    width: "9px",
    height: "9px",
    marginLeft: "8px",
    marginTop: "2px",
  },
  deleteIcon: {
    color: "#FF1616",
  },
  fileBoxStyle: {
    display: "flex",
    paddingTop: "8px",
  },

  selectedFileTypo: {
    cursor: "pointer",
    "&:hover": {
      color: "#06BDFF",
    },
  },
  scheduleReviewTypo: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#1B2430",
  },
  scheduleReviewTypoEmail: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#1B2430",
  },
  scheduleReviewsIcon: {
    marginLeft: "5px",
    marginTop: "5px",
    color: "#06BDFF",
  },
  scheduleReviewInfoGrid: {
    paddingTop: "5px",
    paddingBottom: "0px",
  },
  scheduleReviewInfoTypo: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#330404",
  },
  scheduleReviewInfoTypoEmail: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#330404",
    paddingLeft: "3px",
    paddingTop: "2px",
  },
  graphButtonTypo: {
    textAlign: "center",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700,
    padding: "8px",
  },
  graphButtonDiv: {
    background: "white",
    //  height: "48px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",
    width: "100%",
    cursor: "pointer",
    color: "#06BDFF",
  },
  graphButtonDivSelected: {
    background: "#06BDFF",
    //  height: "48px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",

    cursor: "pointer",
    color: "white",
  },
  latestImpressingHeadingBox: {
    display: "flex",
    alignItems: "center",
  },
  summarizedTextStyle: {
    marginLeft: "5px",
    marginTop: "10px",
    cursor: "pointer",
  },
  summaryGrid: {
    backgroundColor: "#E0F7FF",
  },
  summaryGridTwo: {
    padding: "16px",
  },
  summaryTextStyle: {
    font: "Work Sans",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#1B2430",
  },
  summarizedDateStyle: {
    Font: "Work Sans",
    Weight: "400",
    Size: "14px",
    LineHeight: "20px",
  },
  noReviewGrid: {
    backgroundColor: "#E0F7FF",
    textAlign: "center",
  },
  mainGridContGroups: {
    padding: "16px",
    paddingBottom: "10px",
    display: "flex",
  },
  summaryLoadingStyle: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  summaryLoadingGridStyle: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  viewMoreGridStyle: {
    background: "#FFF",
  },
  viewMoreText: {
    fontSize: "14px",
    fontWeight: 700,
  },
  reputationLoadingStyle: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "300px",
    alignItems: "center",
    borderLeft: "1px solid #9d9d9d",
    borderBottom: "1px solid #9d9d9d",
    paddingX: "8px",
  },
  topicsLoadingStyle: {
    width: "30px",
    height: "30px",
    color: "#06BDFF",
    padding: "5px",
  },
  topicsLoadingGrid: {
    textAlign: "center",
    padding: "18px 5px 5px 5px",
  },
  toolTipIconStyle: {
    marginLeft: "5px",
    marginTop: "10px",
    cursor: "pointer",
  },
  headingBox: {
    display: "flex",
    alignItems: "center",
  },
  reviewCardBox: {
    display: "flex",
  },
  reviewCardBoxTwo: {
    marginLeft: "5px",
    width: "100%",
  },
  reviewCardBoxThree: {
    display: "flex",
    justifyContent: "space-between",
  },
  reviewerTitleTypo: {
    fontWeight: 700,
    fontSize: "14px",
    color: "black",
  },
  locationIcon: {
    color: "#8D9298",
  },
  locationTypo: {
    color: "#8D9298",
  },
  latestImpressionMainGrid: {
    marginTop: "5px",
  },
  latestImpressionCard: {
    padding: "8px",
    borderRadius: "0px",
  },
  rangeGrid: {
    padding: "0px 8px 8px 8px",
  },
  filterHeaderButtons: {
    textAlign: "end",
  },
  filterHeaderButtonsTwo: {
    justifyContent: "space-between",
    display: "flex",
  },
  filterHeaderButtonsFour: {
    textAlign: "end",
  },
}));
