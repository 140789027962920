import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Stack,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";

const useStyles = makeStyles((theme) => ({
  titleName: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#1B2430",
  },
  headName: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
    textTransform: "capitalize",
  },
  headNameDesc: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#06BDFF",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  tagsText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  tagsItem: {
    background: "#EBEBEC",
    minWidth: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "700px",
    flexWrap: "nowrap",
  },
  viewMoreButton: {
    fontWeight: 700,
    fontSize: "14px",
    textAlign: "start",
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
function CardHeader({
  locationDetailsData,
  handleOpenTemplateMenu,
  editPermission,
  title,
  viewPermission,
  displayViewButton,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Grid container sx={{ padding: "16px" }}>
        <Grid item xs={8} sm={8} md={10} lg={10}>
          <Typography className={classes.titleName}>{title}</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={2} lg={2}>
          <Box sx={{ textAlign: "end" }}>
            {" "}
            {editPermission && viewPermission ? (
              <Button onClick={handleOpenTemplateMenu}>
                <Typography sx={{ fontWeight: 600 }}>
                  {displayViewButton !== undefined && displayViewButton == true
                    ? t("View_Label")
                    : t("Edit")}
                </Typography>
              </Button>
            ) : (
              <BootstrapTooltip title={t("authorized_access")}>
                <span>
                  <Button
                    disabled
                    sx={{ cursor: "pointer" }}
                    onClick={handleOpenTemplateMenu}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      {displayViewButton !== undefined &&
                      displayViewButton == true
                        ? t("View_Label")
                        : t("Edit")}
                    </Typography>
                  </Button>
                </span>
              </BootstrapTooltip>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default CardHeader;
