import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Select,
  Checkbox,
  MenuItem,
  Button,
  Card,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useStyles } from "../../../../../Styles/style";
import AddIcon from "@mui/icons-material/Add";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { languagesData } from "../../../../../../../../assets/defaultLanguage";

const AttributesModal = ({
  item,
  ind,
  handleAddAttribute,
  handleRemoveAttribute,
  handleChangeChecked,
  allAttributes,
  handleChangeAttributeValue,
  hasEmpty,
}) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { t } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = useState(true);

  return (
    <Grid container spacing={2} className={classes.customFieldContain}>
      <Grid
        item
        xs={7}
        sm={7}
        md={item?.valueType === "BOOL" ? 10 : 6}
        lg={item?.valueType === "BOOL" ? 10 : 6}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={allAttributes?.length > 0 ? allAttributes : []}
          value={item}
          onChange={(e, value) => {
            handleChangeAttributeValue(value, ind, item);
          }}
          getOptionLabel={(option) =>
            option?.displayNames ? option?.displayNames : ""
          }
          fullWidth
          renderInput={(params) => (
            <TextField placeholder={t("select_attr")} {...params} />
          )}
        />
      </Grid>

      <Grid xs={1.5} sm={1.5} md={1} lg={1} className={classes.attributeStyle}>
        {item?.valueType !== "" && (
          <Box className={classes.attributBox}>
            <Switch
              // className={classes.toggleChecked}
              className={
                item?.value === "true"
                  ? classes.toggleUnChecked
                  : classes.toggleChecked
              }
              checked={item?.value === "true" ? true : false}
              //  disabled={isEditable || dataValues?.isEditable === false}
              onChange={(e) => {
                handleChangeChecked(e.target.checked, ind);
              }}
            />
            <Typography className={classes.attributsCustoms}>
              {item?.value !== null
                ? item?.value === "true"
                  ? t("Yes")
                  : t("No")
                : t("No")}
            </Typography>
          </Box>
        )}
      </Grid>
      {item?.valueType === "" && (
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className={classes.attributAlign}
        ></Grid>
      )}
      <Grid item xs={1} sm={1} md={1} lg={1} className={classes.attributAlign}>
        <IconButton
          onClick={() => {
            handleRemoveAttribute(ind, item?.parent);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default AttributesModal;
