import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

const AddLocationButton = ({ onSubmit, disabled, icon }) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        onClick={() => onSubmit()}
        disabled={disabled}
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: "14px 20px",
          width: "auto",
          height: "52px",
          background: "##FFFFFF",
          borderRadius: "8px",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#06BDFF",
          border: "1px solid #06BDFF",
          "&:hover ": {
            background: "#c2cbcf",
            borderRadius: "8px",
            color: "white",
          },
        }}
      >
        {icon}
        {t("Add_Location_manually")}
      </Button>
    </>
  );
};

export default AddLocationButton;
