import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TextField,
} from "@mui/material";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { api } from "../../../../contexts/JWTContext";
import UserRegister from "../../../../pages/AuthPages/UserRegister/registerForm";
import Loader from "../../../../components/Loaders/Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useStyles } from "../../../AuthPages/styles/styles";

const FetchModel = ({
  title,
  subTitle,
  open,
  handleCloseNewRequestModel,
  isFullWidth,
  isDisabledEmail,
  isLoading,
  token,
  getAllUsers,
  approveUserEmail,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [firstNameRequired, setFirstNameRequired] = useState(false);
  // const [lastNameRequired, setLastNameRequired] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [confirmPasswordRequired, setConfirmPasswordRequired] = useState(false);
  const [samePassword, setSamePassword] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
    token: token,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      email: approveUserEmail,
    });
  }, []);

  const handleApproveUser = async () => {
    if (userInfo?.firstName === "") {
      setFirstNameRequired(true);
    }
    // if (userInfo?.lastName==="") {
    //   setLastNameRequired(true);
    // }
    if (userInfo?.email === "") {
      setEmailRequired(true);
    }
    if (userInfo?.password === "") {
      setPasswordRequired(true);
    }
    if (userInfo?.confirmPassword === "") {
      setConfirmPasswordRequired(true);
    }

    if (userInfo?.confirmPassword !== userInfo?.password) {
      setSamePassword(true);
    }
    if (
      userInfo?.firstName !== "" &&
      userInfo?.email !== "" &&
      userInfo?.password !== "" &&
      // userInfo?.lastName !== "" &&
      userInfo?.confirmPassword !== "" &&
      userInfo?.password === userInfo?.confirmPassword
    ) {
      try {
        setLoading(true);
        const res = await api.patch(`user/approveUser`, {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          password: userInfo.password,
          token: userInfo?.token,
        });
        if (res.status === 201) {
          toast.success(res?.data?.message);
          handleCloseNewRequestModel();
          setLoading(false);
          getAllUsers();
        }
      } catch (error) {
        setLoading(false);
        handleCloseNewRequestModel();
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <div>
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", height: "600px" } }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogTitle>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  lineHeight: "28px",
                  fontWeight: 700,
                  fontSize: "20px",
                  color: "#1b2430",
                }}
              >
                {title}
              </Typography>
            </Box>
          </DialogTitle>

          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid container>
                <Grid item sm={12} md={12} lg={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Grid
                      container
                      sx={{ width: !isFullWidth ? "450px" : "100%" }}
                    >
                      <Typography className={classes.nameLabel}>
                        {t("First_Name")}
                      </Typography>
                      <TextField
                        // disabled={loading}

                        type="text"
                        name="firstName"
                        placeholder={t("First_Name")}
                        value={userInfo?.firstName}
                        error={firstNameRequired}
                        fullWidth
                        helperText={firstNameRequired && t("First_name_req")}
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            firstName: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            setFirstNameRequired(false);
                          } else {
                            setFirstNameRequired(true);
                          }
                        }}
                      />
                      <Typography className={classes.nameLabel}>
                        {t("Last_Name")}
                      </Typography>
                      <TextField
                        // disabled={loading}

                        type="text"
                        name="lastName"
                        autoComplete="off"
                        placeholder={t("Last_Name")}
                        inputProps={{ autoComplete: "off" }}
                        value={userInfo?.lastName}
                        fullWidth
                        // error={lastNameRequired}
                        // helperText={lastNameRequired && t("Last_name_req")}
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            lastName: e.target.value,
                          });
                          // if (e.target.value?.length > 0) {
                          //   setLastNameRequired(false);
                          // } else {
                          //   setLastNameRequired(true);
                          // }
                        }}
                      />

                      <Typography className={classes.nameLabel}>
                        {t("Email_Address")}
                      </Typography>
                      <TextField
                        disabled={isDisabledEmail ? true : false}
                        type="email"
                        name="email"
                        placeholder="Email address"
                        defaultValue=""
                        autoComplete="none"
                        fullWidth
                        value={userInfo?.email}
                        error={emailRequired || validEmail}
                        helperText={
                          emailRequired
                            ? t("Email_required_text")
                            : validEmail
                            ? t("valid_emaill")
                            : ""
                        }
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            email: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            setEmailRequired(false);
                            if (e.target.value.length > 0) {
                              if (
                                /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(
                                  e.target.value
                                )
                              ) {
                                setValidEmail(false);
                              } else {
                                setValidEmail(true);
                              }
                            } else {
                              setValidEmail(false);
                            }
                          } else {
                            setEmailRequired(true);
                            setValidEmail(false);
                          }
                        }}
                      />

                      <Typography className={classes.nameLabel}>
                        {t("Password")}
                      </Typography>
                      <TextField
                        // disabled={loading}

                        type="password"
                        name="password"
                        placeholder={t("Password")}
                        defaultValue=""
                        autoComplete="off"
                        // inputProps={{
                        //   autoComplete: "none",
                        // }}
                        value={userInfo?.password}
                        error={passwordRequired || passwordLength}
                        fullWidth
                        helperText={
                          passwordRequired
                            ? t("confirm_password_validation")
                            : passwordLength
                            ? t("valid_pass")
                            : ""
                        }
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            password: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            if (e.target.value?.length < 5) {
                              setPasswordLength(true);
                            } else {
                              setPasswordLength(false);
                            }
                            if (e.target.value?.length > 0) {
                              if (
                                userInfo?.confirmPassword?.length > 0 &&
                                userInfo?.confirmPassword !== e.target.value
                              ) {
                                setSamePassword(true);
                              } else {
                                setSamePassword(false);
                              }
                            } else {
                              setPasswordLength(false);
                              setSamePassword(false);
                            }
                            setPasswordRequired(false);
                          } else {
                            setPasswordRequired(true);
                          }
                        }}
                      />
                      <Typography className={classes.nameLabel}>
                        {t("Confirm_Password")}
                      </Typography>
                      <TextField
                        // disabled={loading}

                        type="password"
                        name="confirmPassword"
                        placeholder={t("Confirm_Password")}
                        value={userInfo?.confirmPassword}
                        fullWidth
                        error={confirmPasswordRequired || samePassword}
                        helperText={
                          confirmPasswordRequired
                            ? t("confirm_password_validation2")
                            : samePassword
                            ? t("confirm_password_match_validation")
                            : ""
                        }
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            confirmPassword: e.target.value,
                          });
                          if (
                            e.target.value?.length > 0 &&
                            e.target.value !== userInfo?.password
                          ) {
                            setSamePassword(true);
                            setConfirmPasswordRequired(false);
                          } else {
                            setSamePassword(false);
                          }
                          if (e.target.value?.length > 0) {
                            setConfirmPasswordRequired(false);
                          } else {
                            setConfirmPasswordRequired(true);
                          }
                        }}
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <CommonButton
              displayWhite="true"
              onSubmit={handleCloseNewRequestModel}
              label={t("Cancel")}
            />

            <CommonButton
              isLoading={loading}
              type="submit"
              label={t("approve")}
              onSubmit={handleApproveUser}
            />
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
