import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import blurImage from "../../../../../../assets/placeholder.png";
import noImageGray from "../../../../../../assets/images/noImageGray.png";
import { useStyles } from "../../../Styles/style";
import { Formik, Form, ErrorMessage } from "formik";
import Checkbox from "@mui/material/Checkbox";
import Rating from "@mui/material/Rating";
import { useTranslation } from "react-i18next";
import Google from "../../../../../../assets/images/Google.svg";
import ProfilePictureModal from "../../Modal/PictureModal";
import "react-toastify/dist/ReactToastify.css";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import { api } from "../../../../../../contexts/JWTContext";
import EditIcon from "@mui/icons-material/Edit";
import CropImgModal from "../../Modal/CropImgModal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const Step1 = ({
  stepperData,
  setStepperData,
  setLocationId,
  getAllLocations,
  setCustomUrl,
  allLocations,
  locationId,
  email,
  customUrl,
  setTripAdvisorChecked,
  setFacebookChecked,
  setGoogleChecked,
  tripAdvisorChecked,
  facebookChecked,
  googleChecked,
  privateSurveyChecked,
  setPrivateSurveyChecked,
  reviewPlatFormChecked,
  setReviewPlatFormChecked,
  customUrlChecked,
  setCustomUrlChecked,
  setNegativeUrl,
  negativeUrl,
  NegUrlIsValid,
  negativeEmail,
  setNegativeEmail,
  negativeEmailError,
  setNegativeEmailError,
  setImageUpload,
  surveyData,
  setNegUrlIsValid,
  id,
  surveyImageDisplay,
  setSurveyImageDisplay,
  setFormitableDisabled,
  surveyEmails,
  setSurveyEmails,
  oldLocation,
  setOldLocation,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const textArea = useRef();
  const inputFileRef = React.useRef();
  const [openPictureModal, setOpenPictureModal] = useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const [error, setError] = useState(false);
  // const [customUrlError, setCustomUrlError] = useState(false);
  const [negativeUrlError, setNegativeUrlError] = useState(false);
  const [progress, setProgress] = useState(0);

  const [loading, setLoading] = useState(false);
  const [loadImg, setLoadImg] = useState(false);

  const [cropModalImage, setCropModalImage] = useState();
  const [uploadImgData, setUploadImgData] = useState({
    image_url: setStepperData?.campaignSurveyData?.image_url,
  });

  const [openCropModal, setOpenCropModal] = useState(false);

  const companyData = JSON.parse(localStorage.getItem("company"));
  const stateData = JSON.parse(localStorage.getItem("stateData"));

  const CommonButtons = ({ onClick, children }) => {
    return (
      <button
        style={{
          height: "52px",
          padding: "14px 20px",
          fontSize: "14px",
          marginLeft: "10px",
          borderRadius: "8px",
          color: "#FFF",
          background: "#06BDFF",
          width: "auto",
          border: "none",
          cursor: "pointer",
        }}
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    );
  };
  useEffect(() => {
    if (id !== undefined) {
      setNegUrlIsValid(true);
      setNegativeUrlError(false);
    }
  }, []);
  const closeCropModal = () => {
    setOpenCropModal(false);
  };

  /*   useEffect(() => {
    if (id !== undefined && surveyData !== null) {
      setStepperData({
        survey_email: stepperData?.survey_email,
        custom_positive_url: stepperData?.custom_positive_url,
        platform: stepperData?.platform,
        negative_feedback_type: stepperData?.negative_feedback_type,
        language: stepperData?.language,
        campaign_type: stepperData?.campaign_type,
        locationIds: stepperData?.locationIds,
        emailTemplateData: stepperData?.emailTemplateData,
        custom_negative_url: stepperData?.custom_negative_url,
        status: stepperData?.status,
        campaignSurveyData: {
          image_url: surveyData?.image_url,
          heading: surveyData?.heading,
          description: surveyData?.description,
        },
      });
    }
  }, [surveyData]); */

  const handleNegativeFeedBackCheck = (event) => {
    switch (event.target.name) {
      case "private-survey":
        setPrivateSurveyChecked(event.target.checked);
        setReviewPlatFormChecked(false);
        setCustomUrlChecked(false);
        setNegativeEmail(stateData?.user?.email);
        let splittedStrEmails = [];
        // let emailArr = [stateData?.user?.email];
        splittedStrEmails = stateData?.user?.email + ",\n";
        const finalStrEmails = [splittedStrEmails];
        setSurveyEmails(finalStrEmails);
        setNegativeEmailError(false);

        break;
      case "review-platform":
        setReviewPlatFormChecked(event.target.checked);
        setPrivateSurveyChecked(false);
        setCustomUrlChecked(false);
        break;
      case "custom-url":
        setCustomUrlChecked(event.target.checked);
        setPrivateSurveyChecked(false);
        setReviewPlatFormChecked(false);
        break;
      default:
        break;
    }
    if (event.target.name === "private-survey") {
      // setSurveyImageDisplay("/static/media/logowhitetext.29f98c05.svg");
      setStepperData({
        ...stepperData,
        survey_email: [stateData?.user?.email],
        validSurveyEmails: 1,
        negative_feedback_type: event.target.name,
        campaignSurveyData: {
          heading: "Our apologies for any inconvenience.",
          description:
            "Please let us know what we can improve for your next visit. Your ratings and comments are sent directly to our management team. If your wish to be contacted, please add your contact information. Many thanks for your feedback and collaboration.",
        },
      });
    } else {
      setStepperData({
        ...stepperData,
        negative_feedback_type: event.target.name,
        survey_email: [],
        validSurveyEmails: 0,
      });
    }
  };

  const getAllCampaigns = async (locationIdValues) => {
    try {
      const res = await api.get(
        `/campaign?locationIds=${locationIdValues}&sortBy=createdAt`
      );

      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          const formitableCampaign = res?.data?.data?.results?.find(
            (item) => item?.campaign_type === "formitable"
          );
          if (formitableCampaign !== undefined) {
            setFormitableDisabled(true);
          } else {
            setFormitableDisabled(false);
          }
        }
      }
    } catch (error) {}
  };

  const handleOpenPictureModal = () => {
    setOpenPictureModal(true);
  };
  const handleCloseNavigate = () => {
    setOpenPictureModal(false);
  };

  const handleClosePictureModal = () => {
    setOpenPictureModal(false);
  };

  const handleCheckboxChange = (event) => {
    switch (event.target.name) {
      case "Tripadvisor":
        setTripAdvisorChecked(event.target.checked);
        setFacebookChecked(false);
        setGoogleChecked(false);
        break;
      case "facebook":
        setFacebookChecked(event.target.checked);
        setTripAdvisorChecked(false);
        setGoogleChecked(false);
        break;
      case "google":
        setGoogleChecked(event.target.checked);
        setTripAdvisorChecked(false);
        setFacebookChecked(false);
        break;
      default:
        break;
    }

    setStepperData({
      ...stepperData,
      platform: event.target.name,
    });
  };

  useEffect(() => {
    setGoogleChecked(true);
    setStepperData((prevData) => ({
      ...prevData,
      platform: "google",
    }));
  }, []);

  const handleLocationChange = (value) => {
    if (value !== null) {
      setLocationId(value);
      if (id === undefined && value?.isFormitableEnabled === true) {
        getAllCampaigns(value?.id);
      }

      let stepperDataHeading = stepperData?.emailTemplateData?.heading;

      if (
        stepperDataHeading?.includes(oldLocation?.name) &&
        oldLocation !== undefined
      ) {
        stepperDataHeading = stepperDataHeading?.replaceAll(
          oldLocation?.name,
          value?.name
        );
      } else {
        stepperDataHeading =
          stepperData?.emailTemplateData?.heading !== ""
            ? stepperData?.emailTemplateData?.heading
            : `How was your experience at ${value?.name}?`;
      }

      setError(false);
      setStepperData({
        ...stepperData,
        locationIds: [value],
        custom_positive_url: `https://search.google.com/local/writereview?placeid=${value?.placeId}`,
        emailTemplateData: {
          image_url: stepperData?.emailTemplateData?.image_url,
          heading:
            stepperDataHeading === undefined
              ? `How was your experience at ${value?.name}?`
              : stepperDataHeading,
          description: stepperData?.emailTemplateData?.description,
        },
      });
      setCustomUrl(
        `https://search.google.com/local/writereview?placeid=${value?.placeId}`
      );
      //  setCustomUrlError(false);
    }
    setOldLocation(value);
  };
  useEffect(() => {
    getAllLocations();
  }, []);

  /*   const handleChangeCustomUrl = (event) => {
    setCustomUrl(event.target.value);
    //  setCustomUrlError(false);
    setStepperData({
      ...stepperData,
      custom_positive_url: event.target.value,
    });
  }; */

  const handleChangeNegativeUrl = (event) => {
    const newNegativeUrl = event.target.value;
    setNegativeUrl(newNegativeUrl);
    setNegativeUrlError(false);

    if (newNegativeUrl.trim() !== "") {
      setStepperData((prevState) => ({
        ...prevState,
        custom_negative_url: newNegativeUrl,
      }));
    } else {
      setStepperData((prevState) => ({
        ...prevState,
      }));
    }
  };

  const handleBlurNegativeUrl = () => {
    if (negativeUrl.length === 0) {
      setNegativeUrlError(true);
    }
  };

  /*   const handleChangeNegativeEmail = (event) => {
    const emailValue = event.target.value;
    setNegativeEmail(emailValue);

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(emailValue);

    setNegativeEmailError(!isValidEmail);

    setStepperData({
      ...stepperData,
      survey_email: emailValue,
    });
  }; */

  /*   const handleBlurNegativeEmail = () => {
    setIsFocused(false);

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(negativeEmail);

    setNegativeEmailError(!isValidEmail || negativeEmail.length === 0);
  };
 */

  const handleImageData = (data) => {
    // setImageUploadData(data);
  };

  const handleBlurLocation = () => {
    if (!locationId || locationId.length === 0) {
      setError(true);
    }
  };

  // const onFileChangeCapture = async (e) => {
  //   setImageUpload(e.target.files.length);
  //   let tempImages = [];
  //   for (const [key, value] of Object.entries(e.target.files)) {
  //     if (key !=="length") {
  //       tempImages.push({
  //         picture: value,
  //         picturePreview: URL.createObjectURL(value),
  //       });
  //     }
  //   }

  //   let formData = new FormData();
  //   if (tempImages?.length > 0) {
  //     formData.append("photos", tempImages[0].picture);
  //   }

  //   try {
  //     setLoading(true);
  //     const res = await api.post(
  //       `/google/post/reviewRequestSurvey/${
  //         companyData !== null && companyData?.id
  //       }`,
  //       formData,
  //       {
  //         onUploadProgress: (progressEvent) => {
  //           const progress = (progressEvent.loaded / progressEvent.total) * 50;

  //           setProgress(progress);
  //         },
  //         onDownloadProgress: (progressEvent) => {
  //           const progress =
  //             50 + (progressEvent.loaded / progressEvent.total) * 50;

  //           setProgress(progress);
  //         },
  //       }
  //     );

  //     if (res.status === 200) {
  //       let tempArray = [];

  //       setLoading(false);
  //       if (res?.data?.locationArray?.length > 0) {
  //         setStepperData({
  //           ...stepperData,
  //           campaignSurveyData: {
  //             image_url: res?.data?.filesArray[0]?.location,
  //           },
  //         });
  //         setDisplayImage(true);
  //       }
  //     }
  //   } catch (error) {
  //     setDisplayImage(false);
  //     setLoading(true);
  //   }
  // };

  const onFileChangeCapture = (e) => {
    let tempImages = [];
    for (const [key, value] of Object.entries(e.target.files)) {
      if (key !== "length") {
        tempImages.push({
          picture: value,
          picturePreview: URL.createObjectURL(value),
        });
      }
    }

    setSelectedImages(tempImages);

    if (e.target.files[0]) {
      const objectURL = URL.createObjectURL(e.target.files[0]);
      setUploadImgData(e.target.files[0]);
      // setImgUploadded();
      setCropModalImage(objectURL);
    }
    setOpenCropModal(true);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files) {
      let tempImages = [];
      for (const [key, value] of Object.entries(e.dataTransfer.files)) {
        if (
          key !== "length" &&
          value &&
          !value?.type?.toLowerCase()?.includes("video")
        ) {
          tempImages.push({
            picture: value,
            picturePreview: URL.createObjectURL(value),
          });
        }
      }
      setSelectedImages(tempImages);
    }
  };

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };
  const handleCloseEdit = () => {
    setStepperData({
      ...stepperData,
      campaignSurveyData: {
        image_url: "",
        heading: stepperData?.campaignSurveyData?.heading,
        description: stepperData?.campaignSurveyData?.description,
      },
    });
  };

  const handleSurveyEmailsChange = (value) => {
    // const { value } = event.target;
    const formattedValue = value.replace(/,\s*/g, ",\n");
    setSurveyEmails(formattedValue);

    const emailsArray = formattedValue.split(",");
    const validEmailsArray = [];

    // Validate each email address and push the valid ones into the array
    for (let i = 0; i < emailsArray.length; i++) {
      const email = emailsArray[i].trim();
      if (isValidEmail(email)) {
        validEmailsArray.push(email);
      }
    }

    setStepperData({
      ...stepperData,
      survey_email: validEmailsArray.map((email) => email.trim()),
      validSurveyEmails: validEmailsArray?.length,
    });
  };

  const isValidEmail = (email) => {
    // Use a regular expression to validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <Grid container component={Paper} sx={{ padding: "16px" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {openPictureModal && (
            <ProfilePictureModal
              handleClose={handleClosePictureModal}
              open={openPictureModal}
              handleCloseNavigate={handleCloseNavigate}
              stepperData={stepperData}
              setStepperData={setStepperData}
              handleImageData={handleImageData}
              setOpenPictureModal={setOpenPictureModal}
              onFileChangeCapture={onFileChangeCapture}
              setSelectedImages={setSelectedImages}
              selectedImages={selectedImages}
              surveyImageDisplay={surveyImageDisplay}
              setSurveyImageDisplay={setSurveyImageDisplay}
            />
          )}
          <Box sx={{ paddingBottom: "16px" }}>
            <Typography className={classes.stepLocation}>
              {t("Select_Your_Location")}
              <span style={{ color: "#FC3652" }}>*</span>
            </Typography>

            <Typography className={classes.stepLocationSubhead}>
              {t("Choose_location_subhead")}
            </Typography>
            <Autocomplete
              disablePortal
              //   multiple
              id="combo-box-demo"
              options={
                allLocations?.results?.length > 0 ? allLocations?.results : []
              }
              value={locationId}
              getOptionLabel={(option) =>
                option?.internalName
                  ? option?.internalName +
                    (option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null &&
                    option?.addressLine1 !== undefined
                      ? " (" + option.addressLine1
                      : "") +
                    (option?.city !== "" &&
                    option?.city !== null &&
                    option?.city !== undefined
                      ? (option?.addressLine1 ? ", " : " (") + option.city
                      : "") +
                    ")"
                  : ""
              }
              className="Autocomplete-field"
              onChange={(e, value) => {
                handleLocationChange(value);
              }}
              onBlur={() => handleBlurLocation()}
              renderInput={(params) => (
                <TextField
                  placeholder={t("SelectLocation")}
                  {...params}
                  error={error}
                  helperText={error && t("location_req")}
                  // name="language"
                />
              )}
            ></Autocomplete>
          </Box>
          <Box sx={{ paddingY: "16px" }}>
            <Typography className={classes.stepLocation}>
              {t("Select_Review_Platform")}
              <span style={{ color: "#FC3652" }}>*</span>
            </Typography>
            <Typography className={classes.stepLocationSubhead}>
              {t("Select_Review_Campaign")}
            </Typography>
            <Grid spacing={2} container>
              <Grid item xs={12} md={2.5} sm={3} lg={2.5}>
                <Box className={classes.socialBox}>
                  <Checkbox
                    sx={{ marginTop: "4px" }}
                    checked={googleChecked}
                    onChange={handleCheckboxChange}
                    name="google"
                    color="primary"
                  />
                  {/* <img 
alt="" src={Google} /> */}
                  <LazyLoadImage
                    loading="lazy"
                    src={Google}
                    PlaceholderSrc={blurImage}
                    alt="Google Image"
                    effect="blur"
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={3.5}>
                <Box className={classes.socialBox}>
                  <Checkbox
                    sx={{ marginTop: "4px" }}
                    checked={googleChecked}
                    onChange={handleCheckboxChange}
                    name="google"
                    color="primary"
                  />
                  <img 
alt="" src={Google} />
                </Box>
              </Grid>
              <Grid item xs={12} md={3.5}>
                <Box className={classes.socialBox}>
                  <Checkbox
                    sx={{ marginTop: "4px" }}
                    checked={googleChecked}
                    onChange={handleCheckboxChange}
                    name="google"
                    color="primary"
                  />
                  <img 
alt="" src={Google} />
                </Box>
              </Grid>
              <Grid item xs={12} md={1.5}></Grid> */}
            </Grid>
          </Box>
          {/* <Box sx={{ paddingY: "16px" }}>
            <Typography
              className={classes.stepLocation}
              sx={{ marginBottom: "12px" }}
            >
              {t("Custom_Url")}
              <span style={{ color: "#FC3652" }}>*</span>
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter custom url"
              fullWidth
              // value={customUrl}
              value={customUrl}
              onChange={handleChangeCustomUrl}
              onBlur={handleBlurCustomUrl}
              error={customUrlError}
              helperText={
                (customUrlIsValid === false && customUrl?.length > 0 && (
                  <span style={{ color: "red" }}>
                    {" "}
                    {t("Please_enter_valid_url")}
                  </span>
                )) ||
                (customUrlError && t("This_field_is_requied"))
              }
            />
          </Box> */}
          <Box sx={{ paddingY: "16px" }}>
            <Typography className={classes.stepLocation} mb={1}>
              {t("Feedback_Question")}
              <span style={{ color: "#FC3652" }}>*</span>
            </Typography>
            <Typography className={classes.stepLocationSubhead}>
              {t("Feedback_Question_subhead")}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box className={classes.socialBoxFeedback}>
                  <Checkbox
                    sx={{ marginTop: "4px" }}
                    checked={privateSurveyChecked}
                    onChange={handleNegativeFeedBackCheck}
                    name="private-survey"
                    color="primary"
                  />
                  <Typography className={classes.selectionText}>
                    {t("Private_Survey")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box className={classes.socialBoxFeedback}>
                  <Checkbox
                    checked={reviewPlatFormChecked}
                    sx={{ marginTop: "4px" }}
                    onChange={handleNegativeFeedBackCheck}
                    name="review-platform"
                    color="primary"
                  />
                  <Typography className={classes.selectionText}>
                    {t("Send_Review")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box className={classes.socialBoxFeedback}>
                  <Checkbox
                    checked={customUrlChecked}
                    sx={{ marginTop: "4px" }}
                    onChange={handleNegativeFeedBackCheck}
                    name="custom-url"
                    color="primary"
                  />
                  <Typography className={classes.selectionText}>
                    {t("Custom_url")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {privateSurveyChecked === true && (
            <>
              <Box className={classes.mainStep2Box}>
                <>
                  {stepperData?.campaignSurveyData?.image_url === "" ||
                  stepperData?.campaignSurveyData?.image_url === undefined ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "24px", position: "relative" }}
                      >
                        <Box
                          sx={{
                            p: 2,
                            // /    border: "2px dashed #E0E0E0", /
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "450px",
                            borderWidth: "2px",
                            borderRadius: "1rem",
                            borderStyle: "dashed",
                            borderColor: "#cbd5e1",

                            // height: "70vh",
                          }}
                        >
                          <form
                            className={classes.formUpload}
                            onDragEnter={handleDrag}
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <label
                              className={classes.lableFileUpload}
                              for="file"
                            >
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AddAPhotoOutlinedIcon
                                      sx={{
                                        height: "79.38px",
                                        width: "91.67px",
                                        cursor: "pointer",
                                        color: "#8D9298",
                                      }}
                                    />

                                    <Typography
                                      sx={{
                                        fontSize: "1rem",
                                        marginTop: "8px",
                                        fontWeight: "400",
                                        color: "#495059",
                                      }}
                                    >
                                      {t("Uploading_Images_Text")}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        marginTop: "15px",
                                      }}
                                    >
                                      <CommonButtons
                                        sx={{
                                          height: "15px",
                                        }}
                                        onClick={() =>
                                          inputFileRef.current.click()
                                        }
                                      >
                                        {t("Browse_Image")}
                                      </CommonButtons>
                                      {/*   <CommonButton
                                          label={t("Browse_Image")}
                                        /> */}
                                    </Typography>
                                  </Box>
                                </>
                              )}
                              <input
                                id="file"
                                type="file"
                                accept="image/*"
                                multiple={true}
                                ref={inputFileRef}
                                onChange={onFileChangeCapture}
                                style={{ display: "none" }}
                              />
                            </label>
                            {dragActive && (
                              <div
                                className={classes.dragFile}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                          </form>
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "24px", position: "relative" }}
                      >
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <LazyLoadImage
                            effect="blur"
                            src={loadImg ? noImageGray : surveyImageDisplay}
                            beforeLoad={handleBeforeLOad}
                            afterLoad={handleAfterLOad}
                            style={{
                              borderRadius: "8px",
                              maxHeight: 660,
                              height: "412px",
                              width: "100%",
                            }}
                            placeholderSrc={noImageGray}
                            alt="Image Alt"
                          />
                          {loadImg === false ? (
                            <IconButton
                              className={classes.imageCrossIcon2}
                              onClick={() => handleCloseEdit()}
                            >
                              <CloseOutlinedIcon
                                fontSize="small"
                                sx={{ color: "#FFFF" }}
                              />
                            </IconButton>
                          ) : null}
                        </div>
                      </Grid>
                    </>
                  )}
                </>

                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "28px", md: "32px" },
                      fontWeight: "700",
                      color: "#1B2430",
                      marginTop: "30px",
                      marginBottom: "8px",
                      lineHeight: "40px",
                    }}
                  >
                    {stepperData?.campaignSurveyData?.heading}
                    <EditIcon
                      style={{
                        color: "#06BDFF",
                        fontSize: "25px",
                        cursor: "pointer",
                        margin: "0px 15px",
                      }}
                      onClick={handleOpenPictureModal}
                    />
                  </Typography>
                  <Typography className={classes.stepLocationSubhead1}>
                    {stepperData?.campaignSurveyData?.description}
                  </Typography>
                </Box>

                <Box sx={{ padding: "24px" }}>
                  <Typography
                    // className={classes.stepLocation1}
                    // sx={{ paddingBottom: "20px" }}
                    sx={{
                      fontSize: { xs: "28px", md: "32px" },
                      fontWeight: "700",
                      color: "#1B2430",
                      marginTop: "30px",
                      marginBottom: "20px",
                      lineHeight: "40px",
                      // paddingLeft: "10px",
                      // paddingRight: "10px",
                    }}
                  >
                    {t("Rate_your_visit")}
                  </Typography>
                  {/* <img 
alt="" src={StarRatingImg} width={"100%"} /> */}
                  <Rating
                    name="hover-feedback"
                    defaultValue={3}
                    value={3}
                    readOnly
                    precision={1}
                    size="large"
                    sx={{
                      fontSize: { xs: "2.5rem", md: "6.5rem" },
                    }}
                  />
                </Box>
                <Box sx={{ padding: "24px", textAlign: "left" }}>
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      phoneNumber: "",
                      review: "",
                    }}
                    // validationSchema={SubmitSchema}
                    // onSubmit={handleSubmitSurvey}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <Form>
                        <Box>
                          <Typography className={classes.stepLocationFormText}>
                            {t("Name")}
                            <span style={{ color: "#FC3652" }}>*</span>
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("Name_subhead")}
                            sx={{ marginBottom: "12px" }}
                            fullWidth
                            value={values?.name}
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            //  error={surveyNameError}
                            //  helperText={surveyNameError && "Name is required"}
                            disabled
                          />
                          <Box
                            sx={{
                              color: "red",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 400,
                            }}
                            mb={2}
                          >
                            <ErrorMessage component="span" name="name" />
                          </Box>
                        </Box>

                        <Box>
                          <Typography className={classes.stepLocationFormText}>
                            {t("Email")}
                            <span style={{ color: "#FC3652" }}>*</span>
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("Email_here")}
                            fullWidth
                            sx={{ marginBottom: "12px" }}
                            name="email"
                            value={values?.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                          />
                          <Box
                            sx={{
                              color: "red",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 400,
                            }}
                            mb={2}
                          >
                            <ErrorMessage component="span" name="email" />
                          </Box>
                        </Box>
                        <Box>
                          <Typography className={classes.stepLocationFormText}>
                            {t("Phone")}
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("Phone_here")}
                            sx={{ marginBottom: "12px" }}
                            name="phoneNumber"
                            fullWidth
                            value={values?.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                          />
                          <Box
                            sx={{
                              color: "red",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 400,
                            }}
                            mb={2}
                          >
                            <ErrorMessage component="span" name="phoneNumber" />
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            className={classes.stepLocationFormTextReview}
                          >
                            {t("Write_Review")}
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            multiline
                            rows={5.8}
                            maxRows={10}
                            ref={textArea}
                            variant="outlined"
                            placeholder={t("review_here_subhead")}
                            sx={{ marginBottom: "12px" }}
                            name="review"
                            fullWidth
                            value={values?.review}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                          />
                          <Box
                            sx={{
                              color: "red",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 400,
                            }}
                            mb={2}
                          >
                            <ErrorMessage component="span" name="review" />
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            paddingY: "12px",
                          }}
                        >
                          {/* <CommonButton
                            type="submit"
                            label={t("Submit review")}
                            disabled={
                              Object.keys(errors).length > 0
                                ? true
                                : !values?.email.length
                                ? true
                                : false
                            }
                            disabled
                          /> */}
                          <button
                            style={{
                              border: "none",
                              textDecoration: "none",
                              backgroundColor: "#06BDFF",
                              color: "white",
                              fontSize: "14px",
                              borderRadius: "8px",
                              height: "56px",
                              padding: "6px 16px",
                              fontWeight: 500,
                            }}
                          >
                            {t("Submit_Review")}
                          </button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
              {/*    <Box sx={{ paddingY: "26px" }}>
                <Typography className={classes.stepLocation}>
                  {t("Enter_Email_Address")}
                  <span style={{ color: "#FC3652" }}>*</span>
                </Typography>
                <Typography className={classes.stepLocationSubhead}>
                  {t("Email_subhead")}
                </Typography>

                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder={t("Email_here")}
                  fullWidth
                  sx={{ marginBottom: "12px" }}
                  name="email"
                  type="email"
                  error={negativeEmailError}
                  // value={negativeEmail}
                  value={stepperData?.survey_email}
                  onChange={handleChangeNegativeEmail}
                  onBlur={handleBlurNegativeEmail}
                  onFocus={handleFocusNegativeEmail}
                  helperText={
                    negativeEmailError
                      ? negativeEmail.length === 0
                        ? t("email_req")
                        : t("Enter_a_valid_email")
                      : ""
                  }
                  focused={isFocused}
                />
              </Box> */}

              <Box sx={{ paddingTop: "26px" }}>
                <Typography className={classes.stepLocation}>
                  {t("Enter_Email_Address")}
                  <span style={{ color: "#FC3652" }}>*</span>
                </Typography>
                <Typography className={classes.stepLocationSubhead}>
                  {t("Email_subhead")}
                </Typography>

                <TextField
                  id="outlined-basic"
                  multiline
                  rows={5.8}
                  maxRows={10}
                  ref={textArea}
                  variant="outlined"
                  placeholder={t("Enter_email_here")}
                  sx={{ marginBottom: "12px" }}
                  value={surveyEmails}
                  fullWidth
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSurveyEmailsChange(event.target.value + ",");
                    }
                  }}
                  onChange={(e) => handleSurveyEmailsChange(e.target.value)}
                />
              </Box>
              <Box mb={3} ml={1}>{`${
                stepperData?.validSurveyEmails
                  ? stepperData?.validSurveyEmails
                  : 0
              } ${t("validEmailText")}`}</Box>
            </>
          )}

          {customUrlChecked && (
            <>
              <Box sx={{ paddingY: "26px" }}>
                <Typography className={classes.stepLocation}>
                  {t("Enter_URL")}
                  <span style={{ color: "#FC3652" }}>*</span>
                </Typography>
                <Typography className={classes.stepLocationSubhead}>
                  {t("Enter_url_subhead")}
                </Typography>

                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder={t("email_placeholder")}
                  fullWidth
                  value={negativeUrl}
                  onChange={handleChangeNegativeUrl}
                  onBlur={handleBlurNegativeUrl}
                  error={negativeUrlError}
                  helperText={negativeUrlError && t("URL_required")}
                  // helperText={
                  //   (NegUrlIsValid === false && negativeUrl?.length > 0 && (
                  //     <span style={{ color: "red" }}>
                  //       {" "}
                  //       {"Please enter valid url"}
                  //     </span>
                  //   )) ||
                  //   (negativeUrlError && t("This_field_is_requied"))
                  // }
                />
              </Box>
            </>
          )}
        </Grid>
        <CropImgModal
          open={openCropModal}
          onClose={closeCropModal}
          uploadImgData={uploadImgData}
          setUploadImgData={setUploadImgData}
          setOpenCropModal={setOpenCropModal}
          cropModalImage={cropModalImage}
          setLoading={setLoading}
          companyData={companyData}
          setProgress={setProgress}
          setStepperData={setStepperData}
          stepperData={stepperData}
          progress={progress}
          setSurveyImageDisplay={setSurveyImageDisplay}
        />
      </Grid>
    </>
  );
};

export default Step1;
