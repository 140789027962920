import React, { useEffect, useState } from "react";
import { api } from "../../../../contexts/JWTContext";
import { useParams } from "react-router-dom";
import { ShowComments } from "./ShowComments";
import Editors from "../../../../components/CustomComponents/Editor/Editors";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";

const Comments = ({ selectedTask, onCancel }) => {
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState(null);
  const [commentsDetails, setCommentsDetails] = useState([]);

  useEffect(() => {
    getAllComments();
  }, []);

  const getAllComments = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/comments/task/${selectedTask?.id}`);

      if (res.status === 200) {
        setCommentsDetails(res?.data?.data?.results);
        // setGetComment(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const postComment = async () => {
    try {
      setIsLoading(true);
      const res = await api.post("/comments", {
        text: comment,
        taskId: selectedTask?.id,
      });
      if (res.status === 201) {
        setComment("");

        getAllComments();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const commentChangeHandler = (e) => {
    setComment(e);
  };

  return (
    <>
      <Box sx={{ overflow: "scroll", maxHeight: "20vh" }}>
        <Box>
          <ShowComments
            getAllComments={getAllComments}
            commentsDetails={commentsDetails}
          />
        </Box>
      </Box>
      <Box sx={{ overflowY: "scroll", maxHeight: "55vh", marginTop: "8px" }}>
        <Editors
          onChange={commentChangeHandler}
          value={comment}
          placeholder={t("comment_text")}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              marginTop: "8px",
            }}
          >
            <CommonButton
              displayWhite="true"
              onSubmit={onCancel}
              label={t("Cancel")}
            />
            <CommonButton
              onSubmit={postComment}
              disabled={isLoading}
              variant="contained"
              loading={isLoading}
              label={t("Add_comment")}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default Comments;
