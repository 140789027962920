import React from "react";
import styled from "styled-components/macro";
import Chart from "react-chartjs-2";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { orange, red, green } from "@mui/material/colors";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 120px;
`;

const Progress = ({ title, total, labels, dataSet }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: dataSet,
        backgroundColor: [
          // theme.palette.secondary.main,
          orange[500],
          red[500],
          green[500],
          // grey[300],
          // theme.palette.grey[300],
        ],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "65%",
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {total}
        </Typography>

        <Spacer mb={6} />
        <ChartWrapper>
          <Chart type="doughnut" data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default Progress;
