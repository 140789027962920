import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Autocomplete, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleIcon from "@mui/icons-material/Google";
import { useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Grid,
  IconButton,
  Paper as MuiPaper,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Chip,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { Edit } from "@mui/icons-material";
import CustomizedTableRow from "../../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../../components/CustomComponents/table/tableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { spacing } from "@mui/system";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const Table = ({
  row,
  index,
  handleSubClickOpen,
  handleConnectWithGoogle,
  //   handleNavigation,
  setOpen,
  setDeleteId,
  closeMenu,
  handleOpenDeleteModel,
  handleClick,
  handleClose,
  selectedRow,
  anchorEl,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [googleConnect, setGoogleConnect] = useState(null);
  const [facebookConnect, setFacebookConnect] = useState(null);
  const [displayFacebookIcon, setFacebookIcon] = useState(false);
  useEffect(() => {
    if (row.enableFacebook === true) {
      setFacebookIcon(true);
    }
    row?.socialLink?.length > 0 &&
      row?.socialLink?.forEach((element) => {
        if (element?.type === "google") {
          setGoogleConnect(element);
        }
        if (element?.type === "facebook") {
          setFacebookConnect(element);
        }
      });
  }, []);
  return (
    <>
      <CustomizedTableRow
        // hover
        tabIndex={-1}
        key={`${row.id}-${index}`}
      >
        <CustomizedTableCell>{row.id}</CustomizedTableCell>
        <CustomizedTableCell align="left">{row.name}</CustomizedTableCell>
        <CustomizedTableCell align="left">
          {row?.company !== null ? row?.company?.name : "--"}
        </CustomizedTableCell>
        {/* <TableCell align="left">{getSubscription}</TableCell> */}
        {/*  <CustomizedTableCell padding="none" align="center">
          {row?.locationSubscription?.length === 0 ? (
            ""
          ) : (
            <Box mr={2}>
              {row?.locationSubscription.map(function (item, i) {
                return (
                  <>
                    {item.status === "active" ? (
                      <Chip
                        sx={{
                          padding: "5px",
                          margin: "5px",
                        }}
                        label={item.subscription.name}
                        m={1}
                      />
                    ) : null}
                  </>
                );
              })}
            </Box>
          )}
        </CustomizedTableCell> */}
        <CustomizedTableCell sx={{ cursor: "pointer" }}>
          <Typography>
            {row?.company?.state === "churn"
              ? t("Churned_text")
              : row?.company?.state === "active"
              ? t("Active")
              : ""}
          </Typography>
        </CustomizedTableCell>
        {/*  <CustomizedTableCell align="left">
                          {row.status}
                        </CustomizedTableCell> */}
        <CustomizedTableCell padding="none" align="center">
          <Box mr={2}>
            <CustomWidthTooltip title={t("Edit_location")}>
              <IconButton
                aria-label="edit"
                size="large"
                onClick={() =>
                  navigate(`/admin/edit_location?state=${row?.id}/`)
                }
              >
                <Edit />
              </IconButton>
            </CustomWidthTooltip>

            {/* <CustomWidthTooltip title="View Google Reviews">
                                <IconButton
                                  aria-label="view-reviews"
                                  size="large"
                                  onClick={() => {
                                    navigate(`/user/tasks/reviews/${row.id}`);
                                  }}
                                >
                                  <GoogleIcon />
                                </IconButton>
                              </CustomWidthTooltip> */}
            {/* <CustomWidthTooltip title="View Reviews">
                                <IconButton
                                  aria-label="view-reviews"
                                  size="large"
                                  onClick={() => {
                                    if (googleData?.isConnected) {
                                      navigate(`/user/tasks/reviews/${row.id}`);
                                    } else {
                                      toast.info(
                                        "please connect google account with location"
                                      );
                                    }

                                  }}
                                >
                                  <ReviewsIcon />
                                </IconButton>
                              </CustomWidthTooltip> */}
            {/* <CustomWidthTooltip title="View location">
                                <IconButton
                                  aria-label="view"
                                  size="large"
                                  onClick={() => {
                                    let isLocation = false;
                                    row?.locationSubscription.forEach(
                                      (location, index) => {
                                        if (
                                          location.subscription.name ===
                                          "listing"
                                        ) {
                                          isLocation = true;
                                        }
                                      }
                                    );
                                    if (isLocation) {
                                      navigate(
                                        "/user/view_location/" + row.id,
                                        {
                                          state: { row },
                                        }
                                      );
                                    } else {
                                      alert("you do not have Location");
                                    }
                                  }}
                                >
                                  <LocationOnIcon />
                                </IconButton>
                              </CustomWidthTooltip> */}

            {/* <CustomWidthTooltip title={t("Edit_Subscription")}>
                                <IconButton
                                  aria-label="view"
                                  size="large"
                                  onClick={() => {
                                    handleSubClickOpen(
                                      row.locationSubscription,
                                      row.id
                                    );
                                  }}
                                >
                                  <SubscriptionsIcon />
                                </IconButton>
                              </CustomWidthTooltip> */}

            {/* <CustomWidthTooltipOfDelete title="Delete location">
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  onClick={() => (
                                    setOpen(true), setDeleteId(row.id)
                                  )}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </CustomWidthTooltipOfDelete> */}

            {/* <CustomWidthTooltip title="Active/Inactive Status">
                                <IconButton aria-label="details" size="large">
                                  <MoreVertIcon />
                                </IconButton>
                              </CustomWidthTooltip> */}
            <IconButton onClick={(e) => handleClick(e, row)} variant="outlined">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  closeMenu();
                  //  handleClose();
                  handleOpenDeleteModel(selectedRow?.id);
                  // handleOpenModal(row?.id);
                }}
              >
                <DeleteIcon
                  sx={{
                    color: "#545353",
                    paddingRight: "5px",
                  }}
                />
                <Typography
                  aria-label="delete"
                  size="large"
                  onClick={() => (setOpen(true), setDeleteId(selectedRow?.id))}
                >
                  {t("Delete")}
                </Typography>
              </MenuItem>
              {/*  <Divider variant="middle" />
                              <MenuItem
                                onClick={() => {
                                  closeMenu();
                                  if (googleData?.isConnected) {
                                    navigate(`/user/tasks/reviews/${row.id}`);
                                  } else {
                                    toast.info(
                                      "please connect google account with location"
                                    );
                                  }
                                }}
                              >
                                <ReviewsIcon
                                  sx={{ color: "#545353", paddingRight: "5px" }}
                                />
                                <Typography
                                  aria-label="view-reviews"
                                  size="large"
                                >
                                  View Reviews
                                </Typography>
                              </MenuItem> */}
              <Divider variant="middle" />
              <MenuItem
                onClick={() => {
                  handleSubClickOpen(
                    selectedRow?.locationSubscription,
                    selectedRow?.id
                  );
                }}
              >
                <SubscriptionsIcon
                  sx={{
                    color: "#545353",
                    paddingRight: "5px",
                  }}
                />
                <Typography aria-label="delete" size="large">
                  {t("Edit_Subscription")}
                </Typography>
              </MenuItem>
              {/*   <Divider variant="middle" />
                              <MenuItem
                                onClick={() => {
                                  let isLocation = false;
                                  selectedRow?.locationSubscription.forEach(
                                    (location, index) => {
                                      if (
                                        location.subscription.name === "listing"
                                      ) {
                                        isLocation = true;
                                      }
                                    }
                                  );
                                  if (isLocation) {
                                    navigate(
                                      "/user/view_location/" + selectedRow.id,
                                      {
                                        state: { row },
                                      }
                                    );
                                  } else {
                                    toast.warn("Please Subscribe listing");
                                  }
                                }}
                              >
                                <LocationOnIcon
                                  sx={{ color: "#545353", paddingRight: "5px" }}
                                />
                                <Typography aria-label="delete" size="large">
                                  {t("View_Location")}
                                </Typography>
                              </MenuItem> */}
            </Menu>
            {displayFacebookIcon === true &&
              (facebookConnect !== null ? (
                // <CustomWidthTooltip title={t("Connected_with_facebook")}>
                <IconButton aria-label="edit" size="large">
                  <FacebookOutlinedIcon
                    sx={{
                      color: "#0639c2",
                    }}
                  />
                </IconButton>
              ) : (
                // </CustomWidthTooltip>
                // <CustomWidthTooltip title={t("facebook_connect")}>
                <IconButton aria-label="edit" size="large">
                  <FacebookOutlinedIcon
                    sx={{
                      color: "gray",
                    }}
                  />
                </IconButton>
                // </CustomWidthTooltip>
              ))}
            <CustomWidthTooltip
              title={
                googleConnect !== null && googleConnect.validToken === true
                  ? t("Connected_with_google")
                  : googleConnect !== null && googleConnect.validToken === false
                  ? t("token_expired")
                  : t("Connect_With_Google_Text")
              }
            >
              <IconButton
                aria-label="edit"
                size="large"
                onClick={() =>
                  navigate(`/admin/edit_location?state=${row?.id}/`, {
                    state: {
                      showProduct: true,
                      displayOn: false,
                    },
                  })
                }
              >
                <GoogleIcon
                  sx={{
                    color:
                      googleConnect !== null &&
                      googleConnect?.validToken === true
                        ? "#34A853"
                        : googleConnect !== null &&
                          googleConnect?.validToken === false
                        ? "#FF1616"
                        : "#8D9298",
                  }}
                />
              </IconButton>
            </CustomWidthTooltip>
          </Box>
        </CustomizedTableCell>
      </CustomizedTableRow>
    </>
  );
};

export default Table;
