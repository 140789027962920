import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import {
  Grid,
  Typography,
  Card,
  Paper,
  Button,
  Box,
  Tooltip,
  tooltipClasses,
  Autocomplete,
  TextField,
} from "@mui/material";
import BouncingDotsLoader from "../../../../components/Loaders/smallBounceLoader";
import { useTranslation } from "react-i18next";
import moment from "moment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useStyles } from "../Style/style";
import styled from "styled-components/macro";
import useDateFormat from "../../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Review = ({
  actionPlans,
  actionPlansLoading,
  downloadFormat,
  setDownloadFormat,
  viewPermission,
  locations,
  pdfChecked,
  pdfDownloadChecked,
  openDownloadPdfModal,
  openReportEmailModal,
  handleDisplayNames,
  groupsActionPlansFromatedData,
  handleDisplayExportLocationName,
  handleDisplayGroupNameSimple,
  handleDisplayGroupNames,
  setFeaturesObj,
  featuresObj,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [downloadValue, setDownloadValue] = useState(null);
  const [dataCountGroups, setDataCountGroups] = useState(5);
  const [dataCount, setDataCount] = useState(0);
  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);

  useEffect(() => {
    if (
      (pdfChecked === true && openReportEmailModal === true) ||
      (pdfDownloadChecked === true && openDownloadPdfModal === true)
    ) {
      if (
        groupsActionPlansFromatedData?.length > 0 ||
        actionPlans?.length > 0
      ) {
        setDataCount(actionPlans?.length);
        setDataCountGroups(groupsActionPlansFromatedData?.length);
      } else {
        loadLessData();
      }
    } else {
      if (actionPlans?.length >= 5) {
        setDataCount(5);
        setDataCountGroups(0);
      } else {
        setDataCount(actionPlans?.length);
        setDataCountGroups(5 - actionPlans?.length);
      }
    }
  }, [
    pdfChecked,
    pdfDownloadChecked,
    openDownloadPdfModal,
    openReportEmailModal,
  ]);

  useEffect(() => {
    if (groupsActionPlansFromatedData?.length > 0 || actionPlans?.length > 0) {
      if (actionPlans?.length >= 5) {
        setDataCount(5);
        setDataCountGroups(0);
      } else {
        setDataCount(actionPlans?.length);
        setDataCountGroups(5 - actionPlans?.length);
      }
    }
  }, [actionPlans, groupsActionPlansFromatedData]);

  const loadMoreData = () => {
    if (actionPlans?.length > 0) {
      setDataCount(actionPlans?.length);
      setDataCountGroups(groupsActionPlansFromatedData?.length);
    }
  };
  const loadLessData = () => {
    if (actionPlans?.length >= 5) {
      setDataCount(5);
      setDataCountGroups(0);
    } else {
      setDataCount(actionPlans?.length);
      setDataCountGroups(5 - actionPlans?.length);
    }
  };

  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 2, value: "email", title: t("email_report") },
  ];

  const handleActionPlanDownload = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    setDownloadValue(newValue);
    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "actionPlan") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };
  return (
    <>
      {(actionPlans.length > 0 ||
        groupsActionPlansFromatedData?.length > 0) && (
        <Grid container>
          <>
            <Card className={classes.cardbox}>
              <Paper variant="outlined">
                <Grid container>
                  <Grid
                    item
                    xs={9.3}
                    sm={9.3}
                    md={9.3}
                    lg={9.3}
                    className={classes.mainGridCont}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography className={classes.subHeadRatingNameBox}>
                        {t("ai_text")}
                      </Typography>
                      <BootstrapTooltip title={t("ai_driver_tooltip_text")}>
                        <Typography
                          sx={{
                            marginLeft: "5px",
                            marginTop: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <InfoOutlinedIcon />
                        </Typography>
                      </BootstrapTooltip>
                    </Box>
                  </Grid>
                  {viewPermission && (
                    <Grid
                      item
                      xs={2.7}
                      sm={2.7}
                      md={2.7}
                      lg={2.7}
                      className={classes.mainGridCont}
                    >
                      <Autocomplete
                        id="tags-outlined"
                        options={downloadOptions}
                        value={downloadValue}
                        getOptionLabel={(option) => option.title}
                        filterSelectedOptions
                        onChange={handleActionPlanDownload}
                        renderInput={(params) => (
                          <TextField {...params} placeholder={t("actionss")} />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider />

                {actionPlans?.slice(0, dataCount).map((item, index) => {
                  return (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={classes.mainGridCont2}
                      >
                        <Typography className={classes.subHeadRatingNameBox}>
                          {handleDisplayNames(item?.locationId, true)}
                        </Typography>
                      </Grid>
                      {Array?.isArray(
                        item?.response?.lastActionPlan?.actionPlan
                      )
                        ? item?.response?.lastActionPlan?.actionPlan?.map(
                            (items, indexx) => {
                              return (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                      paddingLeft: "16px",
                                      marginTop: "3px",
                                      marginBottom: "3px",
                                    }}
                                  >
                                    <Typography
                                      className={classes.titleTextActionPlans}
                                    >
                                      {`${indexx + 1}:`}
                                    </Typography>
                                    <Typography
                                      className={classes.titleTextActionPlans}
                                      sx={{ marginLeft: "5px" }}
                                    >
                                      {`${items}`}
                                    </Typography>
                                  </Box>
                                </Grid>
                              );
                            }
                          )
                        : JSON.parse(
                            item?.response?.lastActionPlan?.actionPlan
                          ).map((items, indexx) => {
                            return (
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    paddingLeft: "16px",
                                    marginTop: "3px",
                                    marginBottom: "3px",
                                  }}
                                >
                                  <Typography
                                    className={classes.titleTextActionPlans}
                                  >
                                    {`${indexx + 1}:`}
                                  </Typography>
                                  <Typography
                                    className={classes.titleTextActionPlans}
                                    sx={{ marginLeft: "5px" }}
                                  >
                                    {`${items}`}
                                  </Typography>
                                </Box>
                              </Grid>
                            );
                          })}
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography
                          sx={{
                            Font: "Work Sans",
                            Weight: "400",
                            Size: "14px",
                            color: "#495059",
                            paddingLeft: "16px",
                            marginTop: "10px",
                            marginBottom: "8px",
                          }}
                        >
                          {t("last_updated")} &nbsp;
                          {formatDate(
                            item?.response?.lastActionPlan?.createdAt
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}

                {groupsActionPlansFromatedData
                  ?.slice(0, dataCountGroups)
                  .map((item, index) => {
                    return (
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className={classes.mainGridCont2}
                        >
                          <Divider />
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.actionPlansGroupContainer}
                            sx={{ display: "flex" }}
                          >
                            <Typography
                              className={classes.subHeadRatingNameBox}
                            >
                              {`${t("group_name")}` + " "}
                            </Typography>
                            <Typography
                              className={classes.subHeadRatingNameBox}
                            >
                              {handleDisplayGroupNames(item?.groupId, true)}
                            </Typography>
                          </Grid>
                          <Divider />

                          {item?.groups?.map((itemss, groupIndex) => (
                            <Grid container key={groupIndex}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                className={classes.mainGridCont2}
                              >
                                <Typography
                                  className={classes.subHeadRatingNameBox}
                                >
                                  {handleDisplayNames(itemss?.locationId, true)}
                                </Typography>
                              </Grid>
                              {Array.isArray(
                                itemss?.response?.lastActionPlan?.actionPlan
                              )
                                ? itemss?.response?.lastActionPlan?.actionPlan?.map(
                                    (actionItem, actionIndex) => (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                      >
                                        <Box
                                          key={actionIndex}
                                          sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            paddingLeft: "16px",
                                            marginTop: "3px",
                                            marginBottom: "3px",
                                          }}
                                        >
                                          <Typography
                                            className={
                                              classes.titleTextActionPlans
                                            }
                                          >
                                            {`${actionIndex + 1}:`}
                                          </Typography>
                                          <Typography
                                            className={
                                              classes.titleTextActionPlans
                                            }
                                            sx={{ marginLeft: "5px" }}
                                          >
                                            {`${actionItem}`}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    )
                                  )
                                : JSON.parse(
                                    itemss?.response?.lastActionPlan?.actionPlan
                                  )?.map((actionItem, actionIndex) => (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Box
                                        key={actionIndex}
                                        sx={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                          paddingLeft: "16px",
                                          marginTop: "3px",
                                          marginBottom: "3px",
                                        }}
                                      >
                                        <Typography
                                          className={
                                            classes.titleTextActionPlans
                                          }
                                        >
                                          {`${actionIndex + 1}:`}
                                        </Typography>
                                        <Typography
                                          className={
                                            classes.titleTextActionPlans
                                          }
                                          sx={{ marginLeft: "5px" }}
                                        >
                                          {`${actionItem}`}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  ))}
                            </Grid>
                          ))}
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography
                              sx={{
                                Font: "Work Sans",
                                Weight: "400",
                                Size: "14px",
                                color: "#495059",
                                paddingLeft: "16px",
                                marginTop: "10px",
                                marginBottom: "8px",
                              }}
                            >
                              {t("last_updated")} &nbsp;
                              {formatDate(
                                item?.response?.lastActionPlan?.createdAt
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}

                {actionPlansLoading && (
                  <Grid
                    container
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      paddingBottom: "16px",
                    }}
                  >
                    <Grid item xs={4} sm={4} md={5} lg={5.5}></Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={1}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <BouncingDotsLoader />
                    </Grid>
                    <Grid item xs={4} sm={4} md={5} lg={5.5}></Grid>
                  </Grid>
                )}
              </Paper>
              {actionPlans.length + groupsActionPlansFromatedData?.length >
                5 && (
                <Grid container sx={{ background: "#FFF" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.viewMoreButtonGrid}
                  >
                    {dataCount + dataCountGroups === 5 ? (
                      <Button onClick={loadMoreData}>
                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                          {t("View_More")}
                        </Typography>
                      </Button>
                    ) : (
                      <Button onClick={loadLessData}>
                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                          {t("View_Less")}
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </Card>
          </>
        </Grid>
      )}
    </>
  );
};

export default Review;
