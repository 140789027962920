// ** React Imports ================================================================
import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// ** File Imports ================================================================
import { api } from "../../../../contexts/JWTContext";
import { languagesData } from "../../../../assets/defaultLanguage";
import Delete from "../../../../components/Models/DeleteModal/Delete";
// ** Material ui Imports ================================================================
import Loader from "../../../../components/Loaders/Loader";

import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components/macro";

import { Dialog, MenuItem } from "@mui/material";
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  tooltipClasses,
  IconButton,
  FormControl,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStyles } from "../styles/style";

// ** Other Imports ================================================================
const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});

//const Divider = styled(MuiDivider)(spacing);

const ShowTemplates = ({
  item,
  index,
  deleteTemplate,
  templateId,
  changeHandler,
  defaultTemplate,
  handleAdd,
  checkRequired,
  dataArray,
  languagesDataArray,
}) => {
  const { t } = useTranslation();
  // ** States ================================================================
  const [textRequired, setTextRequired] = useState(false);

  const [reviewerName, setReviewerName] = useState(false, "");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [textCount, setTextCount] = useState(0);
  const textArea = useRef();

  // ** Handler Functions ================================================================
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (checkRequired === true) {
      if (item.text?.length < 1) {
        setTextRequired(true);
      } else {
        setTextRequired(false);
      }
    }
  }, [checkRequired]);
  useEffect(() => {
    setTextCount(item?.text?.length);
  });

  const handleCount = (e) => {
    setTextCount(e.target.value.length);
  };

  const deleteTemplateApi = async () => {
    try {
      const res = await api.delete(`/templateDescription/${item?.id}`);

      if (res.status === 200) {
        deleteTemplate(index);
        // navigate("/user/template");
        toast.success(`Template description deleted successfully ${item?.id}`);
        setIsLoading(false);
        setOpen(false);
      }
    } catch (error) {
      // toast.error(res.status.messaage);
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Templatee")}
          description={t("template_desc")}
          onConfirm={() =>
            item?.id ? deleteTemplateApi() : deleteTemplate(index)
          }
          onCancel={handleClose}
        />
      </Dialog>
      <Grid container spacing={2}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Grid item xs={12} sm={12} lg={4} md={4}>
              <FormControl fullWidth>
                <Typography className={classes.reviewName}>
                  {t("Language_review")}
                </Typography>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    languagesDataArray?.length > 0 ? languagesDataArray : []
                  }
                  value={languagesData?.find(
                    (data) => data?.value === item?.language
                  )}
                  onChange={(e, value) => {
                    if (value !== null) {
                      changeHandler(value, index, true, false);
                    } else {
                      changeHandler(undefined, index, true, false);
                    }
                  }}
                  getOptionLabel={(option) =>
                    option?.language ? option?.language : ""
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_languages")}
                      {...params}
                      error={item?.languageError}
                    />
                  )}
                />

                {/* <Select
                  error={languageRequired}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={item?.language}
                  onChange={(e, value) => {
                    setLanguageRequired(false);
                    changeHandler(e, index, true, false);
                  }}
                >
                  {languagesData.map((item) => (
                    <MenuItem value={item.value}>{item.language}</MenuItem>
                  ))}
                </Select> */}
              </FormControl>

              <FormControl
                // className={classes.formControl}
                fullWidth

                // className="form-style"
              >
                <Typography className={classes.personalizeText}>
                  {t("Personalize")}
                </Typography>

                <Select
                  //  error={languageRequired}
                  blurOnSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={reviewerName}
                  // label="Personalize"
                  onChange={(e, value) => {
                    //  setReviewerName(e.target.value);
                    changeHandler(e, index, false, true, textArea);
                  }}
                  // className="select-box"
                >
                  <MenuItem value="{{first_name}}">
                    <div>
                      {t("First_name")} <br />{" "}
                      <span className={classes.menu_item}>ex: john</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="{{last_name}}">
                    <div>
                      {t("Last_name")} <br />{" "}
                      <span className={classes.menu_item}>ex: alpha</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="{{business_email}}">
                    <div>
                      {t("Business_email")} <br />{" "}
                      <span className={classes.menu_item}>
                        ex: john@obenan.com
                      </span>
                    </div>
                  </MenuItem>
                  <MenuItem value="{{business_phone_number}}">
                    <div>
                      {t("Phone_no")} <br />{" "}
                      <span className={classes.menu_item}>
                        ex: +3197010281297
                      </span>
                    </div>
                  </MenuItem>

                  <MenuItem value="{{location}}">
                    <div>
                      {t("Location_Name")} <br />{" "}
                      <span className={classes.menu_item}>
                        ex: Factory Girl
                      </span>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={10.5} sm={11} lg={7.5} md={7}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography className={classes.descriptionText}>
                  {t("Description")} {index + 1}
                </Typography>

                <Typography className={classes.descLengthText}>
                  {" "}
                  {textCount}/4000{" "}
                </Typography>
                {textCount >= 4000 && (
                  <Typography className={classes.limitText}>
                    {t("text_limit_text")}
                  </Typography>
                )}
              </Grid>

              <Box>
                <textarea
                  id="standard-basic"
                  // label="Standard"
                  rows={5.8}
                  maxRows={10}
                  value={item?.text}
                  style={{
                    border: "1px solid #c4c4c4",
                    fontFamily: "Segoe UI",
                    fontStyle: "Normal",
                    padding: "12px 12px 12px 12px",
                    width: "100%",
                    gap: "8px",
                    borderRadius: "4px",
                    minHeight: "144px",
                    color: "#1B2430",
                    fontWeight: "400",
                    fontSize: "16px",
                    "&:hover ": {
                      border: "1px solid #54d2ff",
                    },
                  }}
                  label={null}
                  onChange={(e) => {
                    if (
                      textCount > 4000 ||
                      e.target.value < 1 ||
                      e.target.value === ""
                    ) {
                      setTextRequired(true);
                    } else {
                      setTextRequired(false);
                    }
                    // setTextRequired(false);
                    // setText(e.target.value);
                    changeHandler(e, index);
                    if (
                      e.target.value?.includes(" {{first_name}} ") === false &&
                      reviewerName === "{{first_name}}"
                    ) {
                      setReviewerName("");
                    } else if (
                      e.target.value?.includes(" {{last_name}} ") === false &&
                      reviewerName === "{{last_name}}"
                    ) {
                      setReviewerName("");
                    } else if (
                      e.target.value?.includes(" {{business_email}} ") ===
                        false &&
                      reviewerName === "{{business_email}}"
                    ) {
                      setReviewerName("");
                    } else if (
                      e.target.value?.includes(
                        " {{business_phone_number}} "
                      ) === false &&
                      reviewerName === "{{business_phone_number}}"
                    ) {
                      setReviewerName("");
                    } else if (
                      e.target.value?.includes(" {{location}} ") === false &&
                      reviewerName === "{{location}}"
                    ) {
                      setReviewerName("");
                    }
                    handleCount(e);
                  }}
                  multiline
                  fullWidth
                  ref={textArea}
                  error={textRequired}
                  /*   InputProps={{
                    disableUnderline: true,
                  }} */
                  inputProps={{ maxLength: 4000 }}
                  /*   InputProps={{
                inputComponent: TextareaAutosize,
              }} */
                />
                {textRequired && (
                  <Typography className={classes.descRequired}>
                    {t("desc_req")}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={1.5}
              sm={1}
              md={1}
              lg={0.5}
              className={classes.grid_item_2}
            >
              {dataArray.length > 1 && (
                <CustomWidthTooltipOfDelete
                  title={t("Delete_Template")}
                  sx={{ marginTop: ".5rem", color: "#FC3652" }}
                >
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(e) => {
                      changeHandler(e, index, false, false, textArea, true);
                      // setOpen(true);
                      // item?.id ? deleteTemplateApi() : deleteTemplate(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomWidthTooltipOfDelete>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default ShowTemplates;
