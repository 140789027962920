import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  Button,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import YoutubeIcon from "../../../../../../../assets/images/Youtube.svg";
import FacebookIcon from "../../../../../../../assets/images/socialAccountImgs/Facebook.svg";
import InstagramIcon from "../../../../../../../assets/images/socialAccountImgs/Instagram.svg";
import TwitterIcon from "../../../../../../../assets/images/twitterX.svg";
import PinterestIcon from "../../../../../../../assets/images/socialAccountImgs/Pinterest.svg";
import LinkedInIcon from "../../../../../../../assets/images/LinkedIn.svg";
import Foursquare from "../../../../../../../assets/images/socialAccountImgs/Foursquare.svg";
import Xing from "../../../../../../../assets/images/xingLogo.svg";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const SocialAccountDetails = ({
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  indexValue,
  setSelectedTab,
  selectedTab,
  setIndexValue,
  setSelectedLocationsBulkUpdate,
  selectedLocationsBulkUpdate,
  onCancel,
  handleCloseFilterModel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedData, setSelectedData] = useState({});
  const [filteredPeriodsArr, setFilteredPeriodsArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newDate, setNewDate] = useState(null);
  const [todayDate, setTodayDate] = useState(null);
  const [newTimeArr, setNewTimeArr] = useState([]);
  const [cancelState, setCancelState] = useState(false);
  const [youTubeUrlValid, setYouTubeUrlValid] = useState(false);
  const [facebookUrlValid, setFacebookUrlValid] = useState(false);
  const [linkedInUrlValid, setLinkedInUrlValid] = useState(false);
  const [instagramUrlValid, setInstagramUrlValid] = useState(false);
  const [twitterUrlValid, setTwitterUrlValid] = useState(false);
  const [pinterestUrlValid, setPinterestUrlValid] = useState(false);
  const [FoursquareUrlValid, setFoursquareUrlValid] = useState(false);
  const [xingUrlValid, setXingUrlValid] = useState(false);

  const [youTubeUrl, setyouTubeUrl] = useState({ type: "YOUTUBE", url: "" });
  const [facebookUrl, setFacebookUrl] = useState({ type: "FACEBOOK", url: "" });
  const [instagramUrl, setInstagramUrl] = useState({
    type: "INSTAGRAM",
    url: "",
  });
  const [twitterUrl, setTwitterUrl] = useState({ type: "TWITTER", url: "" });
  const [pinterestUrl, setPinterestUrl] = useState({
    type: "PINTEREST",
    url: "",
  });
  const [vimeoUrl, setVimeoUrl] = useState({ type: "", url: "" });
  const [FoursquareUrl, setFoursquareUrl] = useState({
    type: "FOURSQUARE",
    url: "",
  });
  const [linkedInUrl, setLinkedInUrl] = useState({ type: "LINKEDIN", url: "" });
  const [xingUrl, setXingUrl] = useState({ type: "XING", url: "" });

  /* +\ */

  var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
  var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
  var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

  function validURLOther(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  function validURL(string) {
    if (typeof string !== "string") {
      return false;
    }

    var match = string.match(protocolAndDomainRE);
    if (!match) {
      return false;
    }

    var everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
      return false;
    }

    if (
      localhostDomainRE.test(everythingAfterProtocol) ||
      nonLocalhostDomainRE.test(everythingAfterProtocol)
    ) {
      return true;
    }

    return false;
  }
  const handleSocailAccounts = async () => {
    if (
      facebookUrlValid === false &&
      pinterestUrlValid === false &&
      youTubeUrlValid === false &&
      instagramUrlValid === false &&
      FoursquareUrlValid === false &&
      xingUrlValid === false &&
      twitterUrlValid === false &&
      linkedInUrlValid === false
    ) {
      setIsLoading(true);
      let socialAccountArr = [];

      const addToSocialArray = (socialUrl, regex) => {
        const { url } = socialUrl;
        if (url.trim().length > 0 && regex.test(url)) {
          socialAccountArr.push({ ...socialUrl });
        }
      };

      if (facebookUrl?.url?.length > 0) {
        socialAccountArr.push(facebookUrl);
      }
      if (linkedInUrl?.url?.length > 0) {
        socialAccountArr.push(linkedInUrl);
      }
      if (youTubeUrl?.url?.length > 0) {
        socialAccountArr.push(youTubeUrl);
      }
      if (instagramUrl?.url?.length > 0) {
        socialAccountArr.push(instagramUrl);
      }
      if (twitterUrl?.url?.length > 0) {
        socialAccountArr.push(twitterUrl);
      }
      if (pinterestUrl?.url?.length > 0) {
        socialAccountArr.push(pinterestUrl);
      }
      if (FoursquareUrl?.url?.length > 0) {
        socialAccountArr.push(FoursquareUrl);
      }
      if (xingUrl?.url?.length > 0) {
        socialAccountArr.push(xingUrl);
      }
      let locationIds = [];
      if (selectedLocationsBulkUpdate?.length > 0) {
        selectedLocationsBulkUpdate?.map((item) => {
          locationIds?.push(item?.id);
        });
      }

      try {
        setDisplayConfirmationBox(false);
        const res = await api.patch(`locationListing/bulkSocialProfiles`, {
          locationIds: locationIds,

          socialProfiles: socialAccountArr,
        });

        if (indexValue !== null) {
          let selectedValue = indexValue;
          setSelectedTab(selectedValue);
        }

        if (cancelState) {
          onCancel();
          handleCloseFilterModel();
        }

        if (res.status === 200) {
          setIsLoading(false);
          toast.success("Updated Successfully");
          setTouchedValue(false);
          //  getLocationDetailsListing(location);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error(error?.response?.data?.message);
      }
    } else {
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    }
  };

  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      handleCloseFilterModel();
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      //getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };

  const isFacebookUrlValid = (inputUrl) => {
    try {
      const url = new URL(inputUrl);
      if (
        url.hostname === "www.facebook.com" ||
        url.hostname === "facebook.com"
      ) {
        // Additional checks if needed
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const isLinkedInUrlValid = (inputUrl) => {
    try {
      const url = new URL(inputUrl);
      if (
        url.hostname === "www.linkedin.com" ||
        url.hostname === "linkedin.com"
      ) {
        // Additional checks if needed
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const isYoutubeUrlValid = (inputUrl) => {
    try {
      const url = new URL(inputUrl);
      if (
        url.hostname === "www.youtube.com" ||
        url.hostname === "youtube.com"
      ) {
        // Additional checks if needed
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const isInstagramUrlValid = (inputUrl) => {
    try {
      const url = new URL(inputUrl);
      if (
        url.hostname === "www.instagram.com" ||
        url.hostname === "instagram.com"
      ) {
        // Additional checks if needed
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const isTwitterUrlValid = (inputUrl) => {
    try {
      const url = new URL(inputUrl);
      if (
        url.hostname === "www.twitter.com" ||
        url.hostname === "twitter.com"
      ) {
        // Additional checks if needed
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const isPinterestUrlValid = (inputUrl) => {
    try {
      const url = new URL(inputUrl);
      if (
        url.hostname === "www.pinterest.com" ||
        url.hostname === "pinterest.com"
      ) {
        // Additional checks if needed
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const isFourSquaretUrlValid = (inputUrl) => {
    try {
      const url = new URL(inputUrl);
      if (
        url.hostname === "www.foursquare.com" ||
        url.hostname === "foursquare.com"
      ) {
        // Additional checks if needed
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const isXingUrlValid = (inputUrl) => {
    try {
      const url = new URL(inputUrl);
      if (url.hostname === "www.xing.com" || url.hostname === "xing.com") {
        // Additional checks if needed
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleSocailAccounts}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            /*   getLocationDetails={getLocationDetails}
            location={location} */
          />
        </Dialog>
      )}
      <Grid container sx={{ padding: "18px" }}>
        <Grid
          item
          xs={10}
          sm={10}
          md={11.5}
          lg={11.5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography className={classes.modalHeader}>
            {t("Social_Accounts")}
          </Typography>
          <BootstrapTooltip title={t("Social_desc")}>
            <Typography
              sx={{
                marginLeft: "5px",
                marginTop: "4px",
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon />
            </Typography>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
          <IconButton
            autoFocus
            onClick={() => {
              handleOpen();
              setCancelState(true);
            }}
            className="delete_button"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />
      <Grid container className={classes.gridContainertwoBulk}>
        {isLoading ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.loaderBox}
          >
            <Loader />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container sx={{ padding: "18px" }}>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className={classes.socialAccountsFieldGrid}
              >
                <Box className={classes.socialImageBox}>
                  <Typography>
                    <img alt="" width={15} height={15} src={YoutubeIcon} />
                  </Typography>
                  <Typography className={classes.socialImageLabel}>
                    {"Youtube"}
                  </Typography>
                  <BootstrapTooltip
                    title={t("Social_Accounts_tooltip_youtube")}
                  >
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Typography>
                  </BootstrapTooltip>
                </Box>
                <TextField
                  fullWidth
                  value={youTubeUrl.url}
                  onChange={(event) => {
                    setTouchedValue(true);
                    setyouTubeUrl({
                      ...youTubeUrl,
                      url: event.target.value,
                    });

                    const inputUrl = event.target.value.trim();

                    if (inputUrl.length === 0) {
                      setYouTubeUrlValid(false);
                    } else {
                      /*  const youtubeRegex =
                        /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:company\/|school\/|in\/)?[^/&?]+)([^"&?/\s]+)?$/;
 */
                      const isValid = isYoutubeUrlValid(inputUrl);
                      if (isValid) {
                        setYouTubeUrlValid(false);
                      } else {
                        setYouTubeUrlValid(true);
                      }
                    }
                  }}
                  variant="outlined"
                  error={youTubeUrlValid}
                  helperText={youTubeUrlValid && t("Invalid_Url")}
                />
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className={classes.socialAccountsFieldGrid}
              >
                <Box className={classes.socialImageBox}>
                  <Typography>
                    <img alt="" width={15} height={15} src={FacebookIcon} />
                  </Typography>
                  <Typography className={classes.socialImageLabel}>
                    {"Facebook"}
                  </Typography>
                  <BootstrapTooltip
                    title={t("Social_Accounts_tooltip_facebook")}
                  >
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Typography>
                  </BootstrapTooltip>
                </Box>
                <TextField
                  fullWidth
                  value={facebookUrl.url}
                  onChange={(event) => {
                    setTouchedValue(true);
                    setFacebookUrl({
                      ...facebookUrl,
                      url: event.target.value,
                    });

                    const inputUrl = event.target.value.trim();

                    if (inputUrl.length === 0) {
                      setFacebookUrlValid(false);
                    } else {
                      /* const facebookRegex =
                        /^(https?:\/\/)?(www\.)?(facebook\.com\/(?:company\/|school\/|in\/)?[^/&?]+)([^"&?/\s]+)?$/;
                      const facebookRegexTwo =
                        /^(https?:\/\/)?(www\.)?(facebook\.com\/(?:company\/|school\/|in\/)?[^/&?]+)?([^"&?/\s]+)?$/;
                     
                      if (
                        !facebookRegex.test(inputUrl) &&
                        facebookRegexTwo.test(inputUrl)
                      ) {
                        setFacebookUrlValid(true);
                      } else {
                        setFacebookUrlValid(false);
                      } */
                      const isValid = isFacebookUrlValid(inputUrl);
                      if (isValid) {
                        setFacebookUrlValid(false);
                      } else {
                        setFacebookUrlValid(true);
                      }
                    }
                  }}
                  variant="outlined"
                  error={facebookUrlValid}
                  helperText={facebookUrlValid && t("Invalid_Url")}
                />
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className={classes.socialAccountsFieldGrid}
              >
                <Box className={classes.socialImageBox}>
                  <Typography>
                    <img alt="" width={15} height={15} src={LinkedInIcon} />
                  </Typography>
                  <Typography className={classes.socialImageLabel}>
                    {"LinkedIn"}
                  </Typography>
                  <BootstrapTooltip title={t("Social_Accounts_tooltip_link")}>
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Typography>
                  </BootstrapTooltip>
                </Box>
                <TextField
                  fullWidth
                  value={linkedInUrl.url}
                  onChange={(event) => {
                    setTouchedValue(true);
                    setLinkedInUrl({
                      ...linkedInUrl,
                      url: event.target.value,
                    });

                    const inputUrl = event.target.value.trim();

                    if (inputUrl.length === 0) {
                      setLinkedInUrlValid(false);
                    } else {
                      const isValid = isLinkedInUrlValid(inputUrl);
                      if (isValid) {
                        setLinkedInUrlValid(false);
                      } else {
                        setLinkedInUrlValid(true);
                      }
                      /*  const linkedInRegex =
                        /^(https?:\/\/)?(www\.)?(linkedin\.com\/(?:company\/|school\/|in\/)?[^/&?]+)([^"&?/\s]+)?$/;

                      if (!linkedInRegex.test(inputUrl)) {
                        setLinkedInUrlValid(true);
                      } else {
                        setLinkedInUrlValid(false);
                      } */
                    }
                  }}
                  variant="outlined"
                  error={linkedInUrlValid}
                  helperText={linkedInUrlValid && t("Invalid_Url")}
                />
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className={classes.socialAccountsFieldGrid}
              >
                <Box className={classes.socialImageBox}>
                  <Typography>
                    <img alt="" width={15} height={15} src={InstagramIcon} />
                  </Typography>
                  <Typography className={classes.socialImageLabel}>
                    {"Instagram"}
                  </Typography>
                  <BootstrapTooltip title={t("Social_Accounts_tooltip_insta")}>
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Typography>
                  </BootstrapTooltip>
                </Box>
                <TextField
                  fullWidth
                  value={instagramUrl.url}
                  onChange={(event) => {
                    setTouchedValue(true);
                    setInstagramUrl({
                      ...instagramUrl,
                      url: event.target.value,
                    });

                    const inputUrl = event.target.value.trim();

                    if (inputUrl.length === 0) {
                      setInstagramUrlValid(false);
                    } else {
                      /*   const instagramRegex =
                        /^(https?:\/\/)?(www\.)?(instagram\.com\/(?:company\/|school\/|in\/)?[^/&?]+)([^"&?/\s]+)?$/;
 */
                      const isValid = isInstagramUrlValid(inputUrl);
                      if (isValid) {
                        setInstagramUrlValid(false);
                      } else {
                        setInstagramUrlValid(true);
                      }
                    }
                  }}
                  variant="outlined"
                  error={instagramUrlValid}
                  helperText={instagramUrlValid && t("Invalid_Url")}
                />
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className={classes.socialAccountsFieldGrid}
              >
                <Box className={classes.socialImageBox}>
                  <Typography>
                    <img alt="" width={15} height={15} src={TwitterIcon} />
                  </Typography>
                  <Typography className={classes.socialImageLabel}>
                    {`X (${t("formerly_known_as")} Twitter)`}
                  </Typography>
                  <BootstrapTooltip title={t("Social_Accounts_tooltip_x")}>
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Typography>
                  </BootstrapTooltip>
                </Box>
                <TextField
                  fullWidth
                  value={twitterUrl.url}
                  onChange={(event) => {
                    setTouchedValue(true);
                    setTwitterUrl({
                      ...twitterUrl,
                      url: event.target.value,
                    });

                    const inputUrl = event.target.value.trim();

                    if (inputUrl.length === 0) {
                      setTwitterUrlValid(false);
                    } else {
                      /*  const twitterRegex =
                        /^(https?:\/\/)?(www\.)?(twitter\.com\/(?:company\/|school\/|in\/)?[^/&?]+)([^"&?/\s]+)?$/; */
                      const isValid = isTwitterUrlValid(inputUrl);
                      if (isValid) {
                        setTwitterUrlValid(false);
                      } else {
                        setTwitterUrlValid(true);
                      }
                    }
                  }}
                  variant="outlined"
                  error={twitterUrlValid}
                  helperText={twitterUrlValid && t("Invalid_Url")}
                />
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className={classes.socialAccountsFieldGrid}
              >
                <Box className={classes.socialImageBox}>
                  <Typography>
                    <img alt="" width={15} height={15} src={PinterestIcon} />
                  </Typography>
                  <Typography className={classes.socialImageLabel}>
                    {"Pinterest"}
                  </Typography>
                  <BootstrapTooltip title={t("Social_Accounts_tooltip_pin")}>
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Typography>
                  </BootstrapTooltip>
                </Box>
                <TextField
                  fullWidth
                  value={pinterestUrl.url}
                  onChange={(event) => {
                    setTouchedValue(true);
                    setPinterestUrl({
                      ...pinterestUrl,
                      url: event.target.value,
                    });

                    const inputUrl = event.target.value.trim();

                    if (inputUrl.length === 0) {
                      setPinterestUrlValid(false);
                    } else {
                      /* const pinterestRegex =
                        /^(https?:\/\/)?(www\.)?(pinterest\.com\/(?:company\/|school\/|in\/)?[^/&?]+)([^"&?/\s]+)?$/; */
                      const isValid = isPinterestUrlValid(inputUrl);
                      if (isValid) {
                        setPinterestUrlValid(false);
                      } else {
                        setPinterestUrlValid(true);
                      }
                    }
                  }}
                  variant="outlined"
                  error={pinterestUrlValid}
                  helperText={pinterestUrlValid && t("Invalid_Url")}
                />
              </Grid>

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className={classes.socialAccountsFieldGrid}
              >
                <Box className={classes.socialImageBox}>
                  <Typography>
                    <img alt="" width={15} height={15} src={Foursquare} />
                  </Typography>
                  <Typography className={classes.socialImageLabel}>
                    {"Foursquare"}
                  </Typography>
                  <BootstrapTooltip title={t("Social_Accounts_tooltip_four")}>
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Typography>
                  </BootstrapTooltip>
                </Box>
                <TextField
                  fullWidth
                  value={FoursquareUrl.url}
                  onChange={(event) => {
                    setTouchedValue(true);
                    setFoursquareUrl({
                      ...FoursquareUrl,
                      url: event.target.value,
                    });

                    const inputUrl = event.target.value.trim();

                    if (inputUrl.length === 0) {
                      setFoursquareUrlValid(false);
                    } else {
                      /*  const foursquareRegex =
                        /^(https?:\/\/)?(www\.)?(foursquare\.com\/(?:company\/|school\/|in\/)?[^/&?]+)([^"&?/\s]+)?$/; */
                      const isValid = isFourSquaretUrlValid(inputUrl);
                      if (isValid) {
                        setFoursquareUrlValid(false);
                      } else {
                        setFoursquareUrlValid(true);
                      }
                    }
                  }}
                  variant="outlined"
                  error={FoursquareUrlValid}
                  helperText={FoursquareUrlValid && t("Invalid_Url")}
                />
              </Grid>

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className={classes.socialAccountsFieldGrid}
              >
                <Box className={classes.socialImageBox}>
                  <Typography>
                    <img alt="" width={15} height={15} src={Xing} />
                  </Typography>
                  <Typography className={classes.socialImageLabel}>
                    {"Xing"}
                  </Typography>
                  <BootstrapTooltip title={t("Social_Accounts_tooltip_xing")}>
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Typography>
                  </BootstrapTooltip>
                </Box>
                <TextField
                  fullWidth
                  value={xingUrl.url}
                  onChange={(event) => {
                    setTouchedValue(true);
                    setXingUrl({
                      ...xingUrl,
                      url: event.target.value,
                    });

                    const inputUrl = event.target.value.trim();

                    if (inputUrl.length === 0) {
                      setXingUrlValid(false);
                    } else {
                      /*  const xingRegex =
                        /^(https?:\/\/)?(www\.)?(xing\.com\/(?:company\/|school\/|in\/)?[^/&?]+)([^"&?/\s]+)?$/; */
                      const isValid = isXingUrlValid(inputUrl);
                      if (isValid) {
                        setXingUrlValid(false);
                      } else {
                        setXingUrlValid(true);
                      }
                    }
                  }}
                  variant="outlined"
                  error={xingUrlValid}
                  helperText={xingUrlValid && t("Invalid_Url")}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Divider variant="middle" />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridButtons}
      >
        <CommonButton
          displayWhite="true"
          label={t("Back")}
          onSubmit={handleCancelButton}
        />
        <CommonButton
          disabled={
            youTubeUrlValid ||
            facebookUrlValid ||
            instagramUrlValid ||
            linkedInUrlValid ||
            twitterUrlValid ||
            xingUrlValid ||
            FoursquareUrlValid ||
            pinterestUrlValid
          }
          onSubmit={handleSocailAccounts}
          label={t("Save")}
        />
      </Grid>
    </Grid>
  );
};

export default SocialAccountDetails;
