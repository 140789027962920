// ** React Imports ================================================================
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// ** File Imports ================================================================
import TitleHeader from "../../../components/CustomComponents/title";
import useAuth from "../../../hooks/useAuth";

import { api, handleSessionOut } from "../../../contexts/JWTContext";
import Delete from "../../../components/Models/DeleteModal/Delete";
import CustomizedTableRow from "../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../components/CustomComponents/table/tableCell";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import LocationNotFound from "../../../components/CustomComponents/Errors/LocationNotFound";
import NoLocationFound from "../../../components/CustomComponents/Errors/NoLocationFound";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { checkLocalStorageData } from "../../../utils/LocalStorageHelpers";

// ** Material ui Imports ================================================================

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  IconButton,
  Dialog,
  Tooltip,
  tooltipClasses,
  Menu,
  MenuItem,
  Typography,
  DialogContent,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loader from "../../../components/Loaders/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, styled } from "@mui/system";
import { TablePagination } from "@mui/material";
import { useStyles } from "./styles/style";
// ** Other Imports ================================================================
import moment from "moment";
import useDateFormat from "../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

// const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "red",
//   },
// });

const Index = () => {
  // ** Others ================================================================
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signOut, permissionsAcess } = useAuth();
  const { formatDate } = useDateFormat();
  const isDataValid = checkLocalStorageData();

  // ** States ================================================================
  const [rule, setRules] = useState("");
  const [loading, setLoading] = useState(false);
  const [ruleAllLocation, setRuleAllLocation] = useState([]);
  const [count, setCount] = useState("");
  const [allTemplate, setAllTemplate] = useState([]);
  const [templateState, setTemplateState] = useState(false);

  const [open, setOpen] = useState(false);
  const [deleteRule, setDeleteRule] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [allLocation, setAllLocation] = useState([]);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // ** Use Effects ================================================================
  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/review"
      );
      // setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (isDataValid) {
      getAllLocations();
      getAllTemplate();
      getSelectedCustomer();
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, []);
  useEffect(() => {
    if (isDataValid) {
      getAllRules();
    }
  }, [page, rowsPerPage]);

  const getAllLocations = async () => {
    setLoading(true);
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });

      if (res.status === 200) {
        if (res.data.data?.results?.length > 0) {
          setAllLocation(res.data.data);
        } else {
          setAllLocation(null);
        }

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAllLocation(null);
      /*   handleSessionOut(
         error?.response?.status,
         error?.response?.data?.message,
         handleSignOut
       ); */
    }
  };

  // ** Handler Functions ================================================================
  const getSelectedCustomer = async () => {
    try {
      const res = await api.get(`/company/me`);
      if (res.data?.status === true) {
        localStorage.setItem("company", JSON.stringify(res?.data.data));
      }
    } catch (error) {}
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleClick = (event, id, rowData) => {
    setAnchorEl(event.currentTarget);
    setDeleteRule(id);
    setSelectedData(rowData);
  };
  const handleClickDelete = async () => {
    handleClose();
    setIsLoading(true);
    try {
      const res = await api.get(`/rule/${selectedData?.id}`);
      if (res.status === 200) {
        if (res?.data?.data) {
          let tempLocationArray = [];
          res?.data?.data?.ruleLocation?.map((locationItem) => {
            tempLocationArray.push(locationItem?.location);
          });
          const results = tempLocationArray.filter(
            ({ id: id1 }) =>
              !allLocation?.results.some(({ id: id2 }) => id2 === id1)
          );

          if (results?.length > 0) {
            handleOpenConfirmationModel();
          } else {
            handleOpenDeleteModel();
          }
        }
        setIsLoading(false);
      }
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleOpenDeleteModel();
    }
  };
  const handleOpenDeleteModel = () => {
    setOpen(deleteRule);
    setAnchorEl(null);
  };
  const handleOpenConfirmationModel = () => {
    setOpen(false);
    setOpenDeleteModel(true);
    setAnchorEl(null);
  };
  const handleCloseConfirmationModel = () => {
    setOpenDeleteModel(false);
    setAnchorEl(null);
  };

  const closeMenu = () => {
    //  setAnchorMenu(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDisplayDate = (item) => {
    let displayDate = "";
    if (companyData.dateFormat === "May 19,2022 3:05PM") {
      displayDate = moment(item).format("MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "Monday,May 19,2022 3:05PM") {
      displayDate = moment(item).format("dddd,MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "May 19,2022") {
      displayDate = moment(item).format("MMMM DD,YYYY");
    }
    if (companyData.dateFormat === "5/19/2022") {
      displayDate = moment(item).format("M/D/YYYY");
    }
    if (companyData.dateFormat === "05/19/2022") {
      displayDate = moment(item).format("MM/D/YYYY");
    }
    if (companyData.dateFormat === "28/12/2022") {
      displayDate = moment(item).format("D/MM/YYYY");
    } else {
      displayDate = moment(item).format("D/MM/YYYY");
    }
    if (displayDate.length > 0) {
      return displayDate;
    } else return null;
  };

  const getAllRules = async () => {
    setLoading(true);
    try {
      const res = await api.get(`/rule?limit=${rowsPerPage}&page=${page + 1}`);
      if (res.status === 200) {
        if (res?.data?.data?.count > 0) {
          setRules(res?.data?.data);
          setCount(res?.data?.data?.count);
          let temparray = [];

          res?.data?.data?.results?.map((item) => {
            item?.ruleLocation?.map((item) => {
              temparray.push(item);
            });
            // temparray.push(item?.ruleLocation);
          });

          setRuleAllLocation(temparray);
        } else {
          setRules(null);
          setCount(0);
        }

        setLoading(false);
      }
    } catch (error) {
      setRules(null);
      setLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const getAllTemplate = async () => {
    // setLoading(true);
    try {
      const res = await api.get(`/template?limit=10&page=1`);
      if (res.status === 200) {
        //  setLoading(true);
        setAllTemplate(res?.data?.data);
        if (res?.data?.data?.results?.length > 0) {
          setTemplateState(false);
        } else {
          setTemplateState(true);
        }
        //   setLoading(false);
      }
    } catch (error) {
      //  setLoading(false);
    }
  };

  const DeleteRule = async (id) => {
    setDeleteLoading(true);
    try {
      const res = await api.delete(
        `/rule/${id !== undefined ? id : selectedData?.id}`
      );
      if (res.status === 200) {
        setOpen(false);
        handleCloseConfirmationModel();
        setSelectedData(null);
        getAllRules();
        setDeleteLoading(false);
        toast.success(res?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      handleCloseConfirmationModel();
      setDeleteLoading(false);
      setOpen(false);
    }
  };

  const AddRuleNavigateButton = () => {
    // if (allTemplate?.results?.length > 0) {
    navigate(`/user/addrule`);
    // } else {
    // handleOpenTemplateModal(true);
  };
  const handleAddTemplate = () => {
    navigate("/user/addrule");
  };
  function convertRatingStars(ratingStar) {
    const starMap = {
      one: "1",
      two: "2",
      three: "3",
      four: "4",
      five: "5",
    };

    const starsArray = JSON.parse(ratingStar);

    if (starsArray.length === 5) {
      return t("all_stars");
    }

    if (starsArray.length === 1) {
      const starsStr = starsArray.map((star) => starMap[star]).join(", ");
      return `${starsStr} ${t("star")}`;
    } else {
      const starsString = starsArray.map((star) => starMap[star]).join(", ");
      const translatedAnd = ` ${t("promptText_custom_and")} `;

      return starsString.replace(
        /,([^,]*)$/,
        translatedAnd + "$1 "
      ) /*  + t("stars") */;
    }
  }

  return (
    <>
      {openDeleteModel && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
          open={openDeleteModel}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <WarningAmberIcon sx={{ fontSize: "50px", color: "#F8A92B" }} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "25px",
                    lineHeight: "40px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("Rule_title")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("deleteRule_description")}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "14px",
              }}
            >
              <CommonButton
                displayWhite="true"
                onSubmit={handleCloseConfirmationModel}
                label={t("No_text")}
              />
              <CommonButton
                label={t("Yes")}
                isLoading={deleteLoading}
                disabled={deleteLoading}
                onSubmit={() => DeleteRule(deleteRule)}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {open && (
        <Dialog
          // fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Rule_title")}
            description={t("Delete_rule_subhead")}
            onConfirm={() => DeleteRule(deleteRule)}
            onCancel={handleClose}
            loading={deleteLoading}
          />
        </Dialog>
      )}
      <TitleHeader
        title={t("Rule_Title")}
        subHeading={t("Rule_subTitle")}
        createButton={allLocation?.results?.length > 0 ? true : false}
        name={t("Add_Rule")}
        showExtraCreateButton={true}
        extraCreateButtonText={t("all_star_rule")}
        extraCreateButtonOnSubmit={() =>
          navigate("/user/addrule?prefilled=true")
        }
        onClick={AddRuleNavigateButton}
        count={rule?.count}
        addPermission={addPermission}
        viewPermission={viewPermission}
      />
      {loading ? (
        <Loader />
      ) : allLocation?.results?.length > 0 ? (
        <>
          {/* <Paper> */}
          {rule !== "" && rule?.count > 0 ? (
            <>
              <TableContainer borderRadius="12" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <CustomizedTableHead>
                    <CustomizedTableRow>
                      <CustomizedTableCell align="left">
                        {t("AccountTableID")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="left">
                        {t("Rule_Name")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="left">
                        {t("Locations")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="left">
                        {t("stars")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="left">
                        {t("promptType")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="left">
                        {t("rule_tpye")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="left">
                        {t("Review_text")}
                      </CustomizedTableCell>

                      <CustomizedTableCell align="left">
                        {t("Last_updated")}
                      </CustomizedTableCell>
                      <CustomizedTableCell align="center">
                        {t("Action")}
                      </CustomizedTableCell>
                    </CustomizedTableRow>
                  </CustomizedTableHead>
                  <TableBody>
                    {rule?.results?.map((row) => (
                      <CustomizedTableRow>
                        <CustomizedTableCell sx={{ cursor: "pointer" }}>
                          {row.id}
                        </CustomizedTableCell>
                        <CustomizedTableCell
                          align="left"
                          hover
                          sx={{ cursor: "pointer", width: "200px" }}
                        >
                          {row?.title}
                        </CustomizedTableCell>
                        <CustomizedTableCell
                          align="left"
                          sx={{ width: "200px" }}
                        >
                          {row?.ruleLocation.length > 0 &&
                          row?.ruleLocation.length === 1
                            ? row?.ruleLocation?.map(
                                (item) =>
                                  item?.location !== null && (
                                    <>
                                      {item?.location?.internalName}
                                      {item?.location?.city !== null &&
                                        item?.location?.city !== undefined &&
                                        ` (${item?.location?.city})`}
                                    </>
                                  )
                              )
                            : t("Multilocation")}
                        </CustomizedTableCell>
                        <CustomizedTableCell
                          align="left"
                          //sx={{ textTransform: "lowercase" }}
                        >
                          {convertRatingStars(row?.ratingStar)}
                        </CustomizedTableCell>

                        <CustomizedTableCell
                          align="left"
                          hover
                          sx={{ cursor: "pointer" }}
                        >
                          {row?.isSupervised
                            ? "-"
                            : row?.defaultPrompt
                            ? t("default")
                            : t("custom_text")}
                        </CustomizedTableCell>
                        <CustomizedTableCell
                          align="left"
                          hover
                          sx={{ cursor: "pointer" }}
                        >
                          {row?.isSupervised
                            ? t("Supervised")
                            : t("Unsupervised")}
                        </CustomizedTableCell>
                        <CustomizedTableCell
                          align="left"
                          hover
                          sx={{ cursor: "pointer" }}
                        >
                          {row?.reviewTextPresent && row?.reviewTextNotPresent
                            ? t("both")
                            : row?.reviewTextPresent &&
                              row?.reviewTextNotPresent == false
                            ? t("present")
                            : t("Not_Present")}
                        </CustomizedTableCell>

                        <CustomizedTableCell align="left">
                          {formatDate(row?.updatedAt)}
                        </CustomizedTableCell>

                        <CustomizedTableCell align="center">
                          {editPermission && viewPermission ? (
                            <BootstrapTooltip title={t("Edit_Rule")}>
                              <IconButton
                                style={{ height: "16px" }}
                                aria-label="edit"
                                // size="large"
                                onClick={() =>
                                  navigate(`/user/addrule/${row?.id}`)
                                }
                              >
                                <Edit />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : (
                            <BootstrapTooltip title={t("authorized_access")}>
                              <span>
                                <IconButton
                                  style={{ height: "16px" }}
                                  aria-label="edit"
                                  disabled
                                  onClick={() =>
                                    navigate(`/user/addrule/${row?.id}`)
                                  }
                                >
                                  <Edit disabled />
                                </IconButton>
                              </span>
                            </BootstrapTooltip>
                          )}

                          {isLoading && row?.id === selectedData?.id ? (
                            <CircularProgress
                              style={{
                                width: "20px",
                                height: "20px",
                                marginTop: "5px",
                              }}
                            />
                          ) : (
                            <IconButton
                              onClick={(e) => handleClick(e, row?.id, row)}
                              variant="outlined"
                              disabled={isLoading}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          )}
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            {deletePermission && viewPermission ? (
                              <MenuItem
                                onClick={() => {
                                  closeMenu();
                                  handleClickDelete();

                                  // handleOpenModal(row?.id);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: "#545353",
                                    paddingRight: "5px",
                                  }}
                                />
                                <Typography aria-label="delete" size="large">
                                  {t("Delete")}
                                </Typography>
                              </MenuItem>
                            ) : (
                              <BootstrapTooltip title={t("authorized_access")}>
                                <span>
                                  <MenuItem
                                    disabled
                                    onClick={() => {
                                      closeMenu();
                                      //  handleOpenDeleteModel();
                                      // handleOpenModal(row?.id);
                                    }}
                                  >
                                    <DeleteIcon
                                      disabled
                                      sx={{
                                        // color: "#545353",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    <Typography
                                      aria-label="delete"
                                      size="large"
                                      onClick={(e) => {
                                        // setOpen(true);
                                      }}
                                    >
                                      {t("Delete")}
                                    </Typography>
                                  </MenuItem>
                                </span>
                              </BootstrapTooltip>
                            )}
                          </Menu>
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rule?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            rule === null && (
              <>
                {/* <NotFound text={t("No_Rule_Found")} /> */}
                <Box className={classes.locationbox}>
                  <LocationNotFound
                    text={t("No_Rules_Added")}
                    subtext={t("Please_add_a_rule")}
                  />

                  {templateState === false ? (
                    addPermission && viewPermission ? (
                      <CommonButton
                        onSubmit={() => navigate(`/user/addrule`)}
                        label={t("Add_Rule")}
                      />
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <CommonButton
                            onSubmit={() => navigate(`/user/addrule`)}
                            label={t("Add_Rule")}
                            disabled={true}
                          />
                        </span>
                      </BootstrapTooltip>
                    )
                  ) : addPermission && viewPermission ? (
                    <CommonButton
                      onSubmit={handleAddTemplate}
                      label={t("Add_Rule")}
                    />
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <CommonButton
                          onSubmit={handleAddTemplate}
                          label={t("Add_Rule")}
                          disabled={true}
                        />
                      </span>
                    </BootstrapTooltip>
                  )}
                </Box>
              </>
            )
          )}
          {/* </Paper> */}
        </>
      ) : (
        allLocation === null && (
          <Box className={classes.locationbox}>
            <NoLocationFound
              text={t("No_Location_Added")}
              subtext={t("add_location_text")}
            />
          </Box>
        )
      )}
    </>
  );
};

export default Index;
