import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  styled,
  TextField,
  Tooltip,
  Dialog,
  tooltipClasses,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  DialogActions,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useStyles } from "../styles/style";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { api } from "../../../../contexts/JWTContext";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from "formik";

import * as yup from "yup";
import { ReactComponent as AiWriter } from "../../../../assets/Icons/AIWriter.svg";
import { ReactComponent as AiLogoGrey } from "../../../../assets/images/AIlogogrey.svg";

import LazyLoad from "../../../../components/Loaders/lazyLoadImage";
import Loader from "../../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import PostAIModal from "../../../../components/Models/AIModal/PostAIModal";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import TextEntry from "../../components/TextEntry";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});

const EditPosts = ({ onCancel, item, getAllposts }) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const initialValues = {
    Description: "",
  };
  const validate = yup.object({
    Description: yup.string().required(),
  });
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const classes = useStyles();
  const [startDate, setStartDate] = useState("");
  const [isUploadPicture, setIsUploadPicture] = useState("");
  const [repeat, setRepeat] = useState("");
  const [endDate, setEndDate] = useState("");
  const [postType, setPostType] = useState("");
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyRequired, setCompanyRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  //const [allPosts, setAllPosts] = useState([]);
  const [allLocation, setAllLocation] = useState([]);
  const [tags, setTags] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [displayError, setDisplayError] = useState("");
  const [AiContentDescription, setAiContentDescription] = useState("");
  const [contentRequired, setContentRequired] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationRequired, setLocationRequired] = useState(false);
  const [tagsRequired, setTagsRequired] = useState(false);
  const [generatedContent, setGeneratedContent] = useState("");
  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    if (item) {
      setCompanyId(item?.company);
      setDescription(item.content);
      setStartDate(item?.startDate);
      setRepeat(item?.repeat);
      setEndDate(item?.endDate);
      setPostType(item?.postType);
      setUrl(item?.url);
      if (item?.postLocations.length > 0) {
        let tempLocationArray = [];

        item?.postLocations?.map((locationItem) => {
          tempLocationArray.push(locationItem?.location);
        });
        setSelectedLocation(tempLocationArray);
      }
      //  setSelectedLocation(item?.postLocations);
      setIsUploadPicture(item?.isUploadPicture);

      // setStatus(getPost?.status);
    }
    // getAllLocations();
  }, []);

  const handleChangeCheckButton = (e) => {
    setIsUploadPicture(e.target.checked);
  };
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  const navigate = useNavigate();
  // setPostId(item?.id);

  const getAllCompanies = async () => {
    try {
      //   setIsLoading(true);
      const res = await api.get(`/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        const selectedCompany = res.data.data?.results?.find(
          (company) => company?.id === item?.companyId
        );
        // setCompanyId(selectedCompany);

        if (selectedCompany?.locations?.length > 0) {
          const googleConnectedLocations = selectedCompany?.locations?.filter(
            (item) => item?.socialLink?.length > 0
          );

          setAllLocation(googleConnectedLocations);
        }

        //   setIsLoading(false);
      }
    } catch (error) {
      //   setIsLoading(false);
    }
  };

  const handleLocationChange = (option) => {
    setLocationId(option);
  };
  const handleChangeCompany = (option) => {
    setCompanyId(option);
    if (option?.locations?.length > 0) {
      const googleConnectedLocations = option?.locations?.filter(
        (item) => item?.socialLink?.length > 0
      );
      setAllLocation(googleConnectedLocations);
    }
  };

  /*  const getAllLocations = async () => {
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/googleLinkedlocations`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllLocation(res.data.data);
      }
    } catch (error) {}
  }; */

  const updateRule = async () => {
    if (selectedLocation.length === 0) {
      setLocationRequired(true);
    } else {
      setLocationRequired(false);
      let locationIds = [];
      selectedLocation?.map((location) => {
        locationIds.push(location?.id);
      });
      if (companyId) {
        setCompanyRequired(false);
      } else {
        setCompanyRequired(true);
      }
      const postLoc = [];
      if (item?.postLocations.length > 0) {
        locationIds.map((itemOne) => {
          item.postLocations.map((itemTwo) => {
            if (itemOne === itemTwo?.location?.id) {
              postLoc.push(itemTwo);
            }
          });
        });
      }

      const data = {
        companyId: companyId?.id,
        content: description,
        url: postType === "CALL" ? "" : url,
        locationIds: locationIds,
        postLocations: postLoc.length > 0 ? postLoc : [],
        startDate: startDate,
        endDate: endDate,
        repeat: repeat,
        postType: postType,
        isUploadPicture: isUploadPicture,
      };

      try {
        setLoading(true);
        const res = await api.patch(`/post/${item?.id}`, data);

        if (res.status === 200) {
          toast.success(res.data.message);

          setLoading(false);
          getAllposts();
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const generateContent = async (data) => {
    /*   if (tags?.length===0) {
      setTagsRequired(true);
    } else {
      setTagsRequired(false);
    } */
    if (AiContentDescription?.length === 0) {
      setContentRequired(true);
    } else {
      setContentRequired(false);
    }
    if (AiContentDescription?.length > 0) {
      try {
        setLoading(true);

        const res = await api.post(`/openAi/generateContent`, {
          prompt: data,
        });
        if (res.status === 200) {
          setLoading(false);
          setDisplayError(false);
          setGeneratedContent(res?.data?.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        setDisplayError(true);
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const generateAIContent = () => {
    const str = "\n\nproduct:";
    const str2 = "\n\n";
    const joinedStr = AiContentDescription.concat(str);
    /* const finalStr = joinedStr tags.toString(); */
    let finalStr = "";
    if (tags?.length > 0) {
      finalStr = joinedStr + tags.toString();
    } else {
      finalStr = AiContentDescription.concat(str2);
    }

    generateContent(finalStr);
  };
  const handleSubmit = () => {
    handleCloseTemplateMenu();
    setDescription(generatedContent);

    // setDescription(generatedContent?.length);
  };

  return (
    <>
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          onClose={handleCloseTemplateMenu}
        >
          <PostAIModal
            title={t("AI Writer")}
            onCancel={handleCloseTemplateMenu}
            tags={tags}
            setTags={setTags}
            displayError={displayError}
            AiContentDescription={AiContentDescription}
            setAiContentDescription={setAiContentDescription}
            generatedContent={generatedContent}
            setGeneratedContent={setGeneratedContent}
            setTagsRequired={setTagsRequired}
            tagsRequired={tagsRequired}
            setContentRequired={setContentRequired}
            contentRequired={contentRequired}
          />

          <DialogActions
            sx={{ padding: "20px", boxShadow: "0px -1px 0px #E0E0E0" }}
          >
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {generatedContent?.length > 0 && (
                  /*   <CommonButton
                displayWhite="true"
                onSubmit={generateAIContent}
                label={<RefreshIcon />}
                disabled={loading}
                // label={t("Cancel")}
              /> */
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={loading}
                    onClick={() => generateAIContent()}
                    className={classes.postLoadingButton}
                  >
                    <RefreshIcon />
                  </LoadingButton>
                )}

                {generatedContent?.length === 0 && (
                  /*  <CommonButton
                type="submit"
                label={t("Write For Me")}
                disabled={loading}
                onSubmit={generateAIContent}
              /> */
                  <LoadingButton
                    loading={loading}
                    onClick={() => generateAIContent()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.PostAiButton}
                  >
                    {t("Write_For_Me")}
                  </LoadingButton>
                )}
                {generatedContent?.length > 0 && (
                  /*  <CommonButton
                type="submit"
                label={t("Submit")}
                disabled={loading}
                onSubmit={handleSubmit}
              /> */
                  <LoadingButton
                    loading={loading}
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.postSubmitButton}
                  >
                    {t("Submit")}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={() => {
          updateRule();
        }}
      >
        {() => (
          <Form>
            <Box className={classes.editPostBox}>
              <Typography className="title">{t("Edit_Post")}</Typography>
            </Box>
            <hr />

            <Grid
              container
              spacing={4}
              md={12}
              sx={{
                display: "flex",

                justifyContent: "space-between",
              }}
            >
              <Grid item xs={11} sm={5} md={5}>
                {loading ? (
                  <Loader />
                ) : (
                  <Box
                    sx={{
                      display: "row",
                      justifyContent: "center",
                      gap: "8px",
                      paddingY: "12px",
                    }}
                  >
                    <LazyLoad
                      src={item?.postPicture?.map((item) => item.url)}
                      key={item.id}
                      placeholderSrc="http://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                      sx={{
                        height: "200px",
                        width: "250px",
                        cursor: "pointer",
                        borderRadius: "8px",
                        imagerendering: "pixelated;",
                      }}
                      alt="image"
                    />
                  </Box>
                )}
              </Grid>

              <Grid item xs={11} sm={7} md={7}>
                <Grid item md={12}>
                  <Typography className={classes.locationTypo}>
                    {t("Select_Company")}
                  </Typography>
                  <Autocomplete
                    // multiple
                    disablePortal
                    id="combo-box-demo"
                    options={
                      allCompanies?.results?.length > 0
                        ? allCompanies?.results
                        : []
                    }
                    getOptionLabel={(option) =>
                      option?.name
                        ? option?.name + "(" + option?.city + ")"
                        : ""
                    }
                    className="Autocomplete-field"
                    value={companyId ? companyId : []}
                    onChange={(e, value) => {
                      handleChangeCompany(value);
                      if (value) {
                        setCompanyRequired(false);
                      } else {
                        setCompanyRequired(true);
                      }
                    }}
                    /*  renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {`${option?.internalName} (${option.city})`}
              </li>
            )} */
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("Select_Company")}
                        {...params}
                        required
                        error={companyRequired}
                        name="company"
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid item md={12}>
                  <Typography className={classes.locationTypo}>
                    {t("Select_Location")}
                  </Typography>

                  <Autocomplete
                    multiple
                    disablePortal
                    id="combo-box-demo"
                    options={allLocation?.length > 0 ? allLocation : []}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    className="Autocomplete-field"
                    value={selectedLocation ? selectedLocation : []}
                    // value={selectedLocation}
                    onChange={(e, value) => {
                      setSelectedLocation(value);
                      handleLocationChange(value);
                      setLocationRequired(false);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {`${option?.internalName} (${
                          option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : ""
                        } ${option.city})`}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("SelectLocation")}
                        {...params}
                        required
                        error={locationRequired}
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                {/* <Typo
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#1B2430",
                    lineHeight: "20px",
                    marginTop:"8px",
                    marginBottom:"8px",
                  }}
                  name="Post Title"
                />
                <Field
                  style={{

                    width: "100%",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#1B2430",
                    lineHeight: "20px",
                    marginBottom:"8px",
                  }}
                  name="Title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  // label="Monday Event"
                  placeholder="Monday Event"
                  as={TextField}
                  type="text"
                />
                {title === "" && (
                  <Typo
                    sx={{ color: "red" }}
                    name={<ErrorMessage name="Title" />}
                  />
                )} */}

                <Grid
                  item
                  xs={5}
                  sm={6}
                  md={12}
                  // md={2.75}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography className={classes.dateTimeTypo}>
                    {t("Select_Date_Time")}
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      sx={{
                        // width: "320px",
                        border: "1px solid #E0E0E0",
                        width: "320px",
                      }}
                      value={startDate}
                      inputFormat="dd/MM/yyyy, hh:mm aa"
                      disablePast
                      onChange={(e) => {
                        // setStartDateRequired(false);

                        setStartDate(e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={{ width: "" }}
                          {...params}
                          error={
                            startDate === null && (
                              <ErrorMessage name={endDate} />
                            )
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                ></Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Grid
                    item
                    md={6}
                    // md={2.75}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "8px",
                    }}
                  >
                    <Typography className={classes.dateTimeTypo}>
                      {t("Repeat_This_Post")}
                    </Typography>

                    <FormControl>
                      <Select
                        // defaultValue={item?.repeatPost === "No"}
                        // labelId="demo-simple-select-helper-label"
                        // id="demo-simple-select-helper"
                        // defaultValue="No"
                        // label="Repeat"
                        value={repeat}
                        onChange={(e) => setRepeat(e.target.value)}
                      >
                        <MenuItem value="no">{t("No")}</MenuItem>
                        <MenuItem value="daily">{t("Daily")}</MenuItem>
                        <MenuItem value="weekly">{t("Weekly")}</MenuItem>
                        <MenuItem value="monthly">{t("Monthly")}</MenuItem>
                        <MenuItem value="yearly">{t("Yearly")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={6}
                    // md={2.75}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "8px",
                    }}
                  >
                    {repeat === "no" ? (
                      <> </>
                    ) : (
                      <>
                        <Grid>
                          <Typography className={classes.dateTimeTypo}>
                            {t("End_Date")}
                          </Typography>

                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              sx={{
                                // width: "320px",
                                border: "1px solid #E0E0E0",
                                width: "320px",
                                background: "blue",
                              }}
                              // label="Date&Time picker"
                              value={endDate}
                              inputFormat="dd/MM/yyyy, hh:mm aa"
                              disablePast
                              onChange={(e) => setEndDate(e)}
                              renderInput={(params) => (
                                <TextField
                                  style={{ width: "" }}
                                  {...params}
                                  // error={
                                  //   endDate === null && (
                                  //     <ErrorMessage name={endDate} />
                                  //   )
                                  // }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {/* )} */}
                  <Grid item md={12} xs={12} sm={12}>
                    <Typography className={classes.dateTimeTypo}>
                      {t("Post_Summary")}
                    </Typography>
                    <Grid container>
                      <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5}>
                        {" "}
                        {description?.length}/1500{" "}
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5}>
                        <CustomWidthTooltip
                          // color="red"
                          title={t("Post_Ai_Tooltip")}
                        >
                          <IconButton
                            sx={{
                              width: "40px",
                              height: "10px",
                              marginBottom: "5px",
                            }}
                            onClick={handleOpenTemplateMenu}
                          >
                            <AiLogoGrey />
                          </IconButton>
                        </CustomWidthTooltip>
                      </Grid>
                    </Grid>
                    {description?.length >= 1499 && (
                      <Typography className={classes.lengthErrorTypo}>
                        {t("text_limit")}
                      </Typography>
                    )}
                    <TextField
                      multiline
                      fullWidth
                      onChange={(e) => {
                        if (e.target?.value?.length <= 1500) {
                          setDescription(e.target.value);
                          // handleChangeDescription(e, index);
                        }
                      }}
                      // label="Post Description"
                      placeholder={t("Enter_Description")}
                      value={description}
                      name="Description"
                    />
                    {description === "" && (
                      <Typography sx={{ color: "red" }}>
                        <ErrorMessage name="Summary" />
                      </Typography>
                    )}
                  </Grid>

                  <Box
                    sx={{
                      bgcolor: "#F6F6F7",
                      width: "100%",
                      borderRadius: "8px",
                      marginY: "8px",
                      ml: 2,
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        md={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mb: 2,
                          marginX: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "700",
                            color: "#1B2430",
                            lineHeight: "24px",
                            marginBottom: "8px",
                            marginTop: "8px",

                            width: "100%",
                          }}
                        >
                          {t("Google_My_CTA")}
                        </Typography>
                        <Grid container sx={{ display: "flex", width: "100%" }}>
                          <Grid item md={6}>
                            <FormControl>
                              {/* <InputLabel id="demo-simple-select-helper-label">Book</InputLabel> */}
                              <Select
                                sx={{ minWidth: "170px" }}
                                // labelId="demo-simple-select-helper-label"
                                // id="demo-simple-select-helper"
                                // label="Age"
                                onChange={(e) => setPostType(e.target.value)}
                                value={postType}
                              >
                                <MenuItem value="ORDER">
                                  {t("OrderOnline")}
                                </MenuItem>
                                <MenuItem value="BOOK">{t("Book")}</MenuItem>
                                <MenuItem value="SHOP">{t("Shop")}</MenuItem>
                                <MenuItem value="LEARN_MORE">
                                  {t("Learn_More")}
                                </MenuItem>
                                <MenuItem value="SIGN_UP">
                                  {t("Sign_Up")}
                                </MenuItem>
                                <MenuItem value="CALL">{t("CallNow")}</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {/* <Grid md={6}>
                    <Field
                  style={{

                    width: "100%",
                    borderRadius: "8px",
                    color: "#1B2430",
                    fontWeight: "400",
                    mb:2,
                  }}
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                  // label="Post Description"

                  value={url}
                  name="url"
                  as={TextField}
                  type="text"
                />
                    </Grid> */}

                          {postType === "CALL" ? (
                            <> </>
                          ) : (
                            <>
                              <Grid item md={6}>
                                <TextField
                                  placeholder={t("Please_enter_Url")}
                                  // label="URL"
                                  value={url}
                                  onChange={(e) => {
                                    setUrl(e.target.value);
                                  }}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <FormGroup sx={{ ml: 2 }}>
                      {/* <FormControlLabel  control={<Checkbox onClick={(e) => handleChangeCheckbox(e, index)}  defaultChecked />}  label="Also add image to the Photos section of Google My Business?" /> */}
                      <FormControlLabel
                        //  onChange={(e) => {
                        //   setIsUploadPicture(e.target.value);
                        // }}
                        onChange={(e) => handleChangeCheckButton(e)}
                        control={<Checkbox checked={isUploadPicture} />}
                        label={t("img_desc")}
                      />
                    </FormGroup>
                  </Box>
                </Grid>

                {/* <Grid
                  item
                  md={0.5}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                ></Grid> */}
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
                mt: 2,
              }}
            >
              <CommonButton
                displayWhite="true"
                isLoading={loading}
                onSubmit={onCancel}
                label={t("Cancel_Button")}
              />
              {/* <CancelButton onClick={onCancel} label={t("Cancel_Button")} /> */}
              <CommonButton
                isLoading={loading}
                onSubmit={() => {
                  {
                    startDate && updateRule();
                  }
                }}
                disabled={description?.length >= 1500}
                label={t("Update_Post")}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditPosts;
