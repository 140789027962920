import React, { useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  DialogTitle,
  Box,
  DialogContent,
  Dialog,
  Checkbox,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { api } from "../../../../../../../../contexts/JWTContext";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";

import { useStyles } from "../../../../../Styles/style";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const OtherSectionModel = ({
  title,
  open,
  handleCloseProductSectionModel,
  locationProducts,
  selectedSectionData,
  selectedItemData,
  locationDetailsData,
  getAllMenus,
  location,
  getLocationDetailsListing,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedSectionToUpdate, setSelectedSectionToUpdate] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSelectSection = (e, section) => {
    if (e.target.checked) {
      setSelectedSectionToUpdate(section);
    } else {
      setSelectedSectionToUpdate(null);
    }
  };

  const handleDataToUpdate = (data) => {
    let dataToUpdate = data[0]?.sections;
    let previousSectionIndex = dataToUpdate?.findIndex(
      (item) =>
        item?.labels?.length > 0 &&
        item?.labels[0]?.displayName ===
          selectedSectionData?.labels[0]?.displayName
    );
    console.log(previousSectionIndex, "previousSection");
    dataToUpdate[previousSectionIndex].items = dataToUpdate[
      previousSectionIndex
    ].items?.filter(
      (item) =>
        item?.labels[0]?.displayName !==
        selectedItemData?.labels[0]?.displayName
    );
    console.log(dataToUpdate, previousSectionIndex, "previousSection");
    if (dataToUpdate[previousSectionIndex].items?.length === 0) {
      dataToUpdate = dataToUpdate?.filter(
        (item) =>
          item?.labels[0]?.displayName !==
          selectedSectionData?.labels[0]?.displayName
      );
    }

    let newSectionIndex = dataToUpdate?.findIndex(
      (item) =>
        item?.labels?.length > 0 &&
        item?.labels[0]?.displayName ===
          selectedSectionToUpdate?.labels[0]?.displayName
    );

    dataToUpdate[newSectionIndex]?.items?.push(selectedItemData);

    return dataToUpdate;
  };

  const handleSaveSectionData = async () => {
    setLoading(true);
    let dummyObj = {};
    let objjjj = JSON.stringify(locationDetailsData?.result?.menus);
    dummyObj = JSON.parse(objjjj);
    let menuData = handleDataToUpdate(dummyObj);

    let objTwo = [
      {
        labels: [
          {
            displayName: "Menu",
          },
        ],
        sections: menuData,
      },
    ];
    //  console.log(menuData, "sectionDataToUpdate");

    try {
      setLoading(true);

      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: objTwo,
          locationId: location?.id,
        }
      );

      if (res.status === 200) {
        setLoading(false);
        handleCloseProductSectionModel();
        getAllMenus();
        getLocationDetailsListing(location);
        toast.success(`${t("Updated_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "100%",
              maxWidth: "700px",
              height: "400px",
            },
          }}
          open={open}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "12px",
            }}
          >
            <Typography
              sx={{
                lineHeight: "28px",
                fontWeight: 700,
                fontSize: "20px",
                color: "#1b2430",
                marginTop: "3px",
              }}
            >
              {title}
            </Typography>
          </DialogTitle>
          {console.log(locationProducts, "locationProducts")}
          <DialogContent
            dividers
            sx={{ height: "250", padding: "8px", overflow: "auto" }}
          >
            {locationProducts?.length > 0 &&
              locationProducts[0]?.sections
                ?.filter(
                  (item) =>
                    item?.labels?.length > 0 &&
                    item?.labels[0]?.displayName !==
                      selectedSectionData?.labels[0]?.displayName
                )
                .map((section) => {
                  return (
                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ textTransform: "capitalize", fontSize: "13px" }}
                        >
                          {section?.labels?.length > 0 &&
                            section?.labels[0]?.displayName}
                        </Typography>

                        <Checkbox
                          checked={
                            selectedSectionToUpdate !== null &&
                            selectedSectionToUpdate?.labels?.length > 0 &&
                            selectedSectionToUpdate?.labels[0]?.displayName ===
                              section?.labels[0]?.displayName
                          }
                          disabled={loading}
                          onChange={(e) => handleSelectSection(e, section)}
                        />
                      </Box>
                      <Divider />
                    </Box>
                  );
                })}

            {/*  {isLoading ? <Loader /> : <></>}{" "} */}
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "10px",
              marginRight: "7px",
            }}
          >
            <CommonButton
              displayWhite="true"
              onSubmit={handleCloseProductSectionModel}
              label={t("Cancel")}
            />

            <CommonButton
              type="submit"
              label={t("move")}
              isLoading={loading}
              disabled={loading || selectedSectionToUpdate == null}
              onSubmit={handleSaveSectionData}
            />
          </Box>
        </Dialog>
      </div>
    </>
  );
};

export default OtherSectionModel;
