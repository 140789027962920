import React, { useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { api } from "../../../../contexts/JWTContext";
import * as Yup from "yup";
import { Formik } from "formik";
import { spacing } from "@mui/system";
import { Grid } from "@mui/material";
import { Alert as MuiAlert } from "@mui/material";
import { toast } from "react-toastify";
import CommonForm from "../../../../components/CustomComponents/forms/CommonForm";
import { useTranslation } from "react-i18next";
const Alert = styled(MuiAlert)(spacing);

const EditUser = ({
  selectedUserId,
  id,
  Language,
  Role,
  user,
  onCancel,
  theme,
  locations,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const stateData = JSON.parse(localStorage.getItem("user"));

  const AccountMangerRole = {
    name: "Account Manager",
    value: "account-manager",
  };
  const LocationManagerRole = {
    name: "Location Manager",
    value: "location-manager",
  };

  return (
    <>
      <Grid>
        <Grid sx={{ padding: "10px", bgcolor: "white" }}>
          <Formik
            // enableReinitialize={true}
            initialValues={{
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              language: user.language,
              role:
                user.role === "account-manager"
                  ? AccountMangerRole
                  : LocationManagerRole,
              locations: user.locations,
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .max(255)
                .required(`${t("First_name_req")}`),

              email: Yup.string()
                .email(`${t("valid_email")}`)
                .max(255)
                .required(`${t("email_req")}`),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              setIsLoading(true);

              try {
                let locationIds = [];
                selectedLocation?.map((location) => {
                  locationIds.push(location?.id);
                });
                const res = await api.patch(`/user/${user.id}`, {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  language: stateData?.language,
                  role: values?.role?.value,
                  locations: locationIds,
                });
                if (res.status === 200) {
                  setIsLoading(false);

                  localStorage.setItem("user", JSON.stringify(res.data.data));

                  toast.success("User updated successfully", {
                    onClose: () => {
                      onCancel();
                    },
                  });
                }
              } catch (error) {
                toast.error(error?.response?.data?.message);
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => (
              <CommonForm
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                onCancel={onCancel}
                touched={touched}
                values={values}
                Language={Language}
                Role={Role}
                isLoading={isLoading}
                label={"Update User"}
                locations={locations}
                userLocation={user?.userLocation}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default withTheme(EditUser);
