import React, { useState } from "react";
import moment from "moment";

import { Grid, Typography, Box } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import StarRatings from "react-star-ratings";
import { useStyles } from "../Style/style";
import { useEffect } from "react";
import useDateFormat from "../../../../hooks/useDateFormat";

const ReviewCard = ({
  item,
  handleDisplayNames,
  apiCallsFrom,
  allReviewSource,
}) => {
  const classes = useStyles();
  const [reviewSource, setReviewSource] = useState(null);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (apiCallsFrom === "old" || apiCallsFrom === "keyword") {
      const itemSource = allReviewSource?.results?.find(
        (itemOne) => itemOne?.id === item?.thirdPartyReviewSourcesId
      );
      setReviewSource(itemSource);
    }
  }, [item, apiCallsFrom, allReviewSource]);
  return (
    <>
      <Grid
        container
        sx={{
          "&:hover": {
            backgroundColor: "#E0F7FF",
            border: "1px solid #06BDFF",
          },
          backgroundColor: "white",
          border: "1px solid #E0E0E0",

          cursor: "pointer",
          margin: "auto",
          width: "95%",
          borderRadius: "8px",
        }}
      >
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ padding: "15px" }}
            // className={classes.reviewModalStyle}
          >
            <Box sx={{ display: "flex" }}>
              <Box>
                <img
                  alt=""
                  src={`${baseURL}/${
                    apiCallsFrom === "keyword"
                      ? reviewSource !== null
                        ? reviewSource?.image
                        : ""
                      : item?.thirdPartyReviewSource?.image
                  }`}
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </Box>
              <Box className={classes.reviewCardBoxTwo}>
                <Box
                  sx={{
                    marginLeft: "5px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <StarRatings
                        rating={item?.ratingValue}
                        starDimension="20px"
                        starRatedColor="gold"
                        numberOfStars={5}
                        starSpacing="2px"
                        name="rating"
                      />

                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "14px",
                          color: "black",
                        }}
                      >
                        {item?.reviewerTitle}
                      </Typography>

                      <Typography sx={{}}> {formatDate(item?.date)}</Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <LocationOnOutlinedIcon sx={{ color: "#8D9298" }} />
                      <Typography sx={{ color: "#8D9298" }}>
                        {item?.hasOwnProperty("locationId")
                          ? handleDisplayNames(item?.locationId)
                          : "Hovarda 36-40 Rupert Street, London"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box mt={3}>
              <Typography
                sx={{
                  color:
                    item?.sentimentAnalysis === null
                      ? "#1B2430"
                      : item?.sentimentAnalysis === "Negative"
                      ? "#f4596e"
                      : item?.sentimentAnalysis === "Positive"
                      ? "#3dba5c"
                      : "#f7ae0b",
                }}
              >
                {item?.ratingText}
              </Typography>

              <Typography className={classes.negativeText}></Typography>
            </Box>
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default ReviewCard;
