import React, { useRef, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import {
  Grid,
  Typography,
  Autocomplete,
  Box,
  Tooltip as MuiToolTip,
  tooltipClasses,
  TextField,
} from "@mui/material";
import { useStyles } from "../../Style/style";
import { useTranslation } from "react-i18next";
import NoDataFound from "./NoDataFound/NoDataFoundOnlineReputation";
import { WaveSpinner } from "../../../../../components/Loaders/wavaloader";
import styled from "styled-components/macro";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <MuiToolTip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const GroupedChart = ({
  keywordsData,
  handleDisplayName,
  keywordsLoading,
  setRatingTextKeywords,
  setSelectedLocationId,
  handleOpenReviewModel,
  setKeywordsSentiment,
  setDownloadFormat,
  viewPermission,
  downloadFormat,
  featuresObj,
  setFeaturesObj,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const gridItemRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(null);
  const [downloadValue, setDownloadValue] = useState(null);

  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);

  useEffect(() => {
    if (gridItemRef.current) {
      const gridItemWidth = gridItemRef.current.clientWidth;

      const newChartWidth = gridItemWidth - 20;

      setChartWidth(newChartWidth);
    }
  }, [keywordsData]);

  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 2, value: "email", title: t("email_report") },
  ];
  const handleKeywordsDownloadChange = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    setDownloadValue(newValue);
    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "keywordCloud") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };

  function truncateString(str, length) {
    if (str?.length <= length) {
      return str;
    }
    return str?.slice(0, length) + "....";
  }

  const updateData = (data) => {
    return data?.map((item) => {
      return {
        ...item,
        name: truncateString(item?.keywordText, 15),
      };
    });
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container mb={3}>
          <Grid item xs={9.7} sm={9.7} md={9.7} lg={9.7}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.subHeadRatingReviewDetail}>
                {t("Keywords_Cloud")}
              </Typography>
              <BootstrapTooltip title={t("Keywords_cloud_Tooltip")}>
                <Typography
                  sx={{
                    marginLeft: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <InfoOutlinedIcon />
                </Typography>
              </BootstrapTooltip>
            </Box>
          </Grid>
          {viewPermission && (
            <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3}>
              <Autocomplete
                id="tags-outlined"
                options={downloadOptions}
                value={downloadValue}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                onChange={handleKeywordsDownloadChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t("actionss")} />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {keywordsLoading ? (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "300px",
            alignItems: "center",
            borderLeft: "1px solid #9d9d9d",
            borderBottom: "1px solid #9d9d9d",
            paddingX: "8px",
          }}
        >
          <WaveSpinner />
        </Box>
      ) : keywordsData.length > 0 ? (
        <Grid container>
          <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          <Grid item xs={10} sm={10} md={10} lg={10} ref={gridItemRef}>
            {chartWidth && (
              <BarChart
                width={chartWidth}
                height={300}
                data={updateData(keywordsData)}
                layout="vertical"
                ref={chartRef}
              >
                <Legend
                  payload={[
                    {
                      value: `${t("positive")}`,
                      type: "rect",
                      id: "positiveCount",
                      color: "#13CF8F",
                    },
                    {
                      value: `${t("negative")}`,
                      type: "rect",
                      id: "negativeCount",
                      color: "#FC3652",
                    },
                    {
                      value: `${t("neutral")}`,
                      type: "rect",
                      id: "neutralCount",
                      color: "#F8A92B",
                    },
                  ]}
                />
                <XAxis type="number" />

                <YAxis
                  dataKey="name"
                  type="category"
                  interval={0}
                  width={150}
                  textAnchor="end"
                />
                <Tooltip
                  content={({ payload }) => {
                    if (payload && payload.length > 0) {
                      const data = payload[0].payload;

                      return (
                        <div className={classes.customTooltip}>
                          <Typography className={classes.toolTipStyle}>
                            {data.keywordText.charAt(0).toUpperCase() +
                              data?.keywordText.slice(1)}
                          </Typography>
                          <Typography className={classes.toolTipStyle}>
                            {` ${t("Total_reviews")}: ${
                              data.positiveCount +
                              data?.negativeCount +
                              data?.neutralCount
                            }`}
                          </Typography>
                          <Typography className={classes.toolTipStyle}>
                            {` ${t("Positive_Count")}: ${data.positiveCount}`}
                          </Typography>
                          <Typography className={classes.toolTipStyle}>
                            {` ${t("Negative_Count")}: ${data.negativeCount}`}
                          </Typography>
                          <Typography className={classes.toolTipStyle}>
                            {` ${t("Neutral_Count")}: ${data.neutralCount}`}
                          </Typography>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Legend />

                <Bar
                  isAnimationActive={false}
                  dataKey="positiveCount"
                  stackId="a"
                  key={Math.random()}
                  fill="#13CF8F"
                  barSize={20}
                  onClick={(data) => {
                    setSelectedLocationId(data.payload?.locationId);
                    setRatingTextKeywords(data.payload?.keywordText);
                    handleOpenReviewModel("keyword");
                    setKeywordsSentiment("Positive");
                  }}
                >
                  <LabelList
                    dataKey="positiveCount"
                    position="inside"
                    fill="#fff"
                    formatter={(value) => (value > 0 ? value : null)}
                  />
                </Bar>
                <Bar
                  isAnimationActive={false}
                  dataKey="negativeCount"
                  stackId="a"
                  fill="#FC3652"
                  barSize={20}
                  onClick={(data) => {
                    setSelectedLocationId(data.payload?.locationId);
                    setRatingTextKeywords(data.payload?.keywordText);
                    handleOpenReviewModel("keyword");
                    setKeywordsSentiment("Negative");
                  }}
                >
                  <LabelList
                    dataKey="negativeCount"
                    position="inside"
                    fill="#fff"
                    formatter={(value) => (value > 0 ? value : null)}
                  />
                </Bar>
                <Bar
                  isAnimationActive={false}
                  dataKey="neutralCount"
                  stackId="a"
                  fill="#F8A92B"
                  barSize={20}
                  onClick={(data) => {
                    setSelectedLocationId(data.payload?.locationId);
                    setRatingTextKeywords(data.payload?.keywordText);
                    handleOpenReviewModel("keyword");
                    setKeywordsSentiment("Neutral");
                  }}
                >
                  <LabelList
                    dataKey="neutralCount"
                    position="inside"
                    fill="#fff"
                    formatter={(value) => (value > 0 ? value : null)}
                  />
                </Bar>
              </BarChart>
            )}
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
        </Grid>
      ) : (
        <NoDataFound />
      )}
    </Grid>
  );
};

export default GroupedChart;
