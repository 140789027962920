import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Zoom,
  Stack,
  styled,
  Divider,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
// import PictureModalCard from "./PictureModalCard";
import Compressor from "compressorjs";
import CoverImagesCard from "./SqaureImageCard";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
  formUpload: {
    height: "16rem",
    width: "28rem",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
  },
  lableFileUpload: {
    height: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: "#cbd5e1",
    backgroundColor: "#f8fafc",
  },
  dragFile: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
}));
const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));
Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const CoverImageModal = ({
  locationId,
  open,
  handleClose,
  handleImagesShow,
  setTouchedValue,
  category,
  validation,
  setSelectedImages,
  selectedImages,
  title,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputFileRefSquare = useRef(null);

  const [picturePreview, setPicturePreview] = useState("");
  const [dragActive, setDragActive] = React.useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [compressedFile, setCompressedFile] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  // const [selectedImages, setSelectedImages] = useState([]);
  useEffect(() => {
    if (!open) {
      setPicturePreview("");
    }
  }, [open]);
  useEffect(() => {
    if (selectedImages?.length <= 0) {
      handleClose();
    }
  }, [selectedImages]);

  const handleDelete = (index) => {
    /*    let deleteCount = alreadyAddedImages;
    if (deleteCount > 0) {
      setAlreadyAddedImages(deleteCount - 1);
    } */
    const deleteImage = selectedImages.filter((item, indx) => {
      return indx !== index;
    });
    // const findDeleted = selectedImages.find((item, indx) => {
    //   return indx===index;
    // });
    /*  if (findDeleted) {
      const filterDelete = uploadedImages.filter((item) => {
        return item["postPictures"][0]["key"]===findDeleted.key;
      });
      setUploadedImages(filterDelete);
    } */
    setSelectedImages(deleteImage);
    setTouchedValue(true);
  };
  const handleImageError = (index) => {
    let temmpArray = [...selectedImages];
    let temmpObj = temmpArray[index];
    temmpObj["error"] = true;
    temmpArray[index] = temmpObj;
    setSelectedImages(temmpArray);
  };

  const onFileChangeCapture = (e) => {
    let tempImages = [];
    for (const [key, value] of Object.entries(e.target.files)) {
      if (key !== "length") {
        tempImages.push({
          picture: value,
          picturePreview: URL.createObjectURL(value),
        });
      }
    }
    setSelectedImages(tempImages);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    let tempImages = [];
    for (const [key, value] of Object.entries(e?.dataTransfer?.files)) {
      tempImages.push({
        picture: value,
        picturePreview: URL.createObjectURL(value),
      });
      setSelectedImages([...tempImages]);
    }
  };
  const handleSubmitImages = () => {
    handleClose();
    handleImagesShow(uploadedImages);
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
              borderRadius: "8px",
            },
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {selectedImages?.length > 0 ? (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <DialogTitle
                sx={{
                  color: "#1B2430",
                  fontSize: "20px",
                  marginBottom: "20px",
                  fontWeight: "700",
                }}
                id="alert-dialog-slide-title"
              >
                {title}
              </DialogTitle>
              <IconButton
                onClick={() => {
                  handleClose();
                  setSelectedImages([]);
                }}
                aria-label="save"
                hover="none"
                sx={{
                  hover: "none",
                  marginBottom: "15px",
                  marginRight: "22px",
                }}
              >
                X
              </IconButton>
            </Stack>

            <DialogContent>
              <Stack direction="column">
                <Item>
                  {selectedImages && (
                    <>
                      {selectedImages?.map((img, index) => {
                        return (
                          <CoverImagesCard
                            img={img}
                            handleDelete={handleDelete}
                            locationId={locationId}
                            handleClose={handleClose}
                            index={index}
                            isUploading={isUploading}
                            setIsUploading={setIsUploading}
                            uploadedImages={uploadedImages}
                            setUploadedImages={setUploadedImages}
                            handleImageError={handleImageError}
                            selectedImages={selectedImages}
                            setSelectedImages={setSelectedImages}
                            setTouchedValue={setTouchedValue}
                            category={category}
                            validation={validation}
                          />
                        );
                      })}
                    </>
                  )}
                </Item>

                <Divider />
                <Item
                  sx={{
                    paddingTop: "24px",
                    display: "flex",
                    justifyContent: "flex-end",
                    flex: 1,
                  }}
                >
                  {selectedImages?.length > 0 && (
                    <>
                      <Button
                        sx={{
                          fontWeight: "700",
                          size: "large",
                          color: "#1B2430",
                          borderRadius: "8px",
                          padding: " 14px 20px",
                          border: "1px solid #E0E0E0",
                          hover: "none",
                        }}
                        variant="outlined"
                        // color="#1B2430"

                        onClick={() => {
                          handleClose();
                          setSelectedImages([]);
                          setUploadedImages([]);
                        }}
                      >
                        {t("Cancel")}
                      </Button>
                      &nbsp; &nbsp;
                      <CommonButton
                        disabled={
                          selectedImages?.length === 0 ||
                          selectedImages.find((item) => item?.error === true)
                            ? true
                            : false
                        }
                        onSubmit={() =>
                          uploadedImages?.length === selectedImages?.length
                            ? handleSubmitImages()
                            : setIsUploading(true)
                        }
                        // onSubmit={selectedImages.length > 0 && handleClose}
                        label={
                          uploadedImages?.length === selectedImages?.length
                            ? t("Submit")
                            : t("Upload")
                        }
                      />
                    </>
                  )}
                </Item>
              </Stack>
            </DialogContent>
          </>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <DialogTitle
                id="alert-dialog-slide-title"
                sx={{
                  fontWeight: "700",
                  color: "#1B2430",
                  fontSize: "20px",
                  letterSpacing: "0.5px",
                  lineHeight: "28px",
                }}
              >
                {title}
              </DialogTitle>
              <IconButton
                onClick={() => handleClose()}
                aria-label="save"
                hover="none"
                sx={{ hover: "none", marginRight: "22px" }}
              >
                X{/* <Cancel /> */}
              </IconButton>
            </Stack>
            <DialogContent>
              <Stack direction="column">
                <Item>
                  <Box
                    sx={{
                      p: 2,
                      /*    border: "2px dashed #E0E0E0", */
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70vh",
                    }}
                  >
                    <form
                      className={classes.formUpload}
                      onDragEnter={handleDrag}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <label className={classes.lableFileUpload} for="file">
                        <AddAPhotoOutlinedIcon
                          sx={{
                            height: "79.38px",
                            width: "91.67px",
                            cursor: "pointer",
                            color: "#8D9298",
                          }}
                        />
                        <input
                          id="fileSquare"
                          type="file"
                          accept=".jpg, .png, .jpeg"
                          multiple={true}
                          ref={inputFileRefSquare}
                          onChange={onFileChangeCapture}
                          style={{ display: "none" }}
                        />
                      </label>
                      {dragActive && (
                        <div
                          className={classes.dragFile}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        marginTop: "26px",
                        fontWeight: "400",
                        color: "#495059",
                      }}
                    >
                      {t("Uploading_Images_Text")}
                    </Typography>
                  </Box>
                </Item>
              </Stack>
            </DialogContent>
          </>
        )}

        <DialogActions>
          <Grid container display="flex" justifyContent="space-between"></Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CoverImageModal;
