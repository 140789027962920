import * as React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import {
  TablePagination,
  TableHead,
  DialogActions,
  useMediaQuery,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  tableRow: {
    padding: "20px",
  },
}));

const CustomizedTableHead = styled((props) => <TableHead {...props} />)(() => ({
  padding: "10px 2px",
  height: "80px",
}));

export default CustomizedTableHead;
