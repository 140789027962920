import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TableSortLabel from "@mui/material/TableSortLabel";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableContainer, TablePagination } from "@mui/material";
import CustomizedTableCell from "../../../../../components/CustomComponents/table/tableCell";
import CustomizedTableRow from "../../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../../components/CustomComponents/table/tableHead";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import updateIcon from "../../../../../assets/Icons/updates.svg";
import ExpireIcon from "../../../../../assets/Icons/ExpireIcon.svg";
import DisconnectIcon from "../../../../../assets/Icons/DisconnectIcon.svg";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import data from "../data.json";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import moment from "moment";
import { Grid } from "@mui/material";
import { useStyles } from "../../Styles/style";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import LaunchIcon from "@mui/icons-material/Launch";
import Loader from "../../../../../components/Loaders/Loader";
import styled from "styled-components/macro";
import { Tooltip, tooltipClasses } from "@mui/material";
import Facebook from "../../../../../assets/images/facebookround.svg";
import Google from "../../../../../assets/images/googleround.svg";
import useDateFormat from "../../../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

function Row(props) {
  const { channelData } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [showImage, setShowImage] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { t } = useTranslation();

  const { formatDate } = useDateFormat();

  const hideImg = (event) => {
    setShowImage(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <CustomizedTableRow
        tabIndex={-1}
        sx={{ "& > *": { borderBottom: "unset" }, height: "60px" }}
      >
        <CustomizedTableCell align="left">
          <Grid container sx={{ aligItems: "center" }}>
            <Grid xs={4} sm={4} md={2.5} lg={2} sx={{ marginTop: "6px" }}>
              <img
                alt=""
                className={classes.sourceImage}
                // style={{ width: 40, height: 40 }}
                onError={hideImg}
                src={
                  showImage
                    ? `${baseURL}/assets/static/uberall_source/${channelData?.type?.toUpperCase()}.png`
                    : `${baseURL}/assets/static/uberall_source/default.png`
                }
              />
            </Grid>
            <Grid xs={8} sm={8} md={9.5} lg={10}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    paddingTop: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  {channelData?.typeName !== null
                    ? channelData?.typeName
                    : "--"}
                </Typography>
                {channelData?.listingUrl !== null &&
                  channelData?.listingUrl !== undefined && (
                    <BootstrapTooltip title={"Open Listing"}>
                      <a
                        style={{
                          color: "#1B2430",
                          textDecoration: "none",
                          display: "flex",
                          cursor: "pointer",
                          "&:hover ": {
                            color: "#06BDFF",
                            textDecoration: "none",
                          },
                        }}
                        href={
                          channelData?.listingUrl !== null &&
                          channelData?.listingUrl
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <OpenInNewOutlinedIcon
                          sx={{
                            width: "17px",
                            marginTop: "5px",
                            marginLeft: "5px",
                            color: "#06BDFF",
                          }}
                        />
                      </a>
                    </BootstrapTooltip>
                  )}
              </Box>
            </Grid>
          </Grid>
        </CustomizedTableCell>

        <CustomizedTableCell
          align="left"
          sx={{
            color: "#13CF8F",
            textDecoration: "none",
          }}
        >
          {(channelData?.claimStatus === "CLAIMED_BY_US" &&
            channelData?.syncStatus === "IN_SYNC") ||
          (channelData?.claimStatus === "NOT_CLAIMABLE" &&
            channelData?.syncStatus === "IN_SYNC") ||
          (channelData?.claimStatus === "CLAIMABLE" &&
            channelData?.syncStatus === "IN_SYNC") ||
          (channelData?.claimStatus === "UNKNOWN" &&
            channelData?.syncStatus === "IN_SYNC") ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckCircleOutlineOutlinedIcon sx={{ width: "17px" }} />
              <Typography sx={{ marginLeft: "6px" }}>
                {t("listing_sync")}
              </Typography>
            </Box>
          ) : (channelData?.claimStatus === "NOT_CLAIMABLE" &&
              channelData?.syncStatus === "NO_ONLINE_LISTING") ||
            (channelData?.claimStatus === "UNKNOWN" &&
              channelData?.syncStatus === "NO_ONLINE_LISTING") ||
            (channelData?.claimStatus === "CLAIMED_BY_OTHERS" &&
              channelData?.syncStatus === "NO_ONLINE_LISTING") ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon sx={{ width: "17px" }} />
              <Typography sx={{ marginLeft: "6px" }}>{t("submitt")}</Typography>
            </Box>
          ) : (channelData?.claimStatus === "UNKNOWN" &&
              channelData?.syncStatus === "NOT_IN_SYNC") ||
            (channelData?.claimStatus === "UNKNOWN" &&
              channelData?.syncStatus === "NOT_FOUND") ||
            (channelData?.claimStatus === "NOT_CLAIMABLE" &&
              channelData?.syncStatus === "NOT_IN_SYNC") ||
            (channelData?.claimStatus === "NOT_CLAIMABLE" &&
              channelData?.syncStatus === "NOT_FOUND") ||
            (channelData?.claimStatus === "CLAIMABLE" &&
              channelData?.syncStatus === "NOT_IN_SYNC") ||
            (channelData?.claimStatus === "CLAIMED_BY_US" &&
              channelData?.syncStatus === "NOT_IN_SYNC") ||
            (channelData?.claimStatus === "CLAIMED_BY_OTHERS" &&
              channelData?.syncStatus === "NOT_IN_SYNC") ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img alt="" src={updateIcon} style={{ width: "14px" }} />
              <Typography sx={{ marginLeft: "7px", color: "#13CF8F" }}>
                {t("listing_b_updated")}
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </CustomizedTableCell>

        <CustomizedTableCell align="left">
          <Typography
            sx={{
              marginLeft: channelData?.lastChecked !== null ? "0px" : "45px",
            }}
          >
            {channelData?.lastChecked !== null
              ? formatDate(channelData?.lastChecked)
              : "--"}
          </Typography>
        </CustomizedTableCell>
        {/* <CustomizedTableCell align="left">
          <RemoveRedEyeIcon />
        </CustomizedTableCell> */}
      </CustomizedTableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, backgroundColor: "#F6F6F7" }}>
              <Grid container>
                <Grid xs={6} sm={6} md={6} lg={6} sx={{ padding: "16px" }}>
                  <Typography className={classes.listingChannelTablehead}>
                    {t("Status")}
                  </Typography>

                  <Typography
                    sx={{
                      color:
                        channelData?.syncStatus === "IN_SYNC"
                          ? "#13CF8F"
                          : "#F8A92B",
                      marginBottom: "4px",
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor:
                          channelData?.syncStatus === "IN_SYNC"
                            ? "#13CF8F"
                            : "#F8A92B",
                        marginRight: "3px",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    ></div>
                    {channelData?.syncStatus[0]?.toUpperCase() +
                      channelData?.syncStatus
                        ?.substring(1)
                        ?.toLowerCase()
                        ?.split("_")
                        .join(" ")}
                  </Typography>
                  {channelData?.syncStatus === "IN_SYNC" ? (
                    <>
                      {" "}
                      <a
                        style={{
                          color: "#1B2430",
                          textDecoration: "none",
                          display: "flex",
                          cursor: "pointer",
                          "&:hover ": {
                            color: "#06BDFF",
                            textDecoration: "none",
                          },
                        }}
                        href={
                          channelData?.listingUrl !== null &&
                          channelData?.listingUrl
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#1B2430",

                            "&:hover ": {
                              color: "#06BDFF",
                              textDecoration: "none",
                            },
                          }}
                        >
                          {t("open_listing")}
                          <LaunchIcon
                            sx={{
                              paddingLeft: "5px",
                              paddingTop: "8px",
                            }}
                          />
                        </Typography>
                      </a>
                    </>
                  ) : (
                    <Typography className={classes.listingChannelTableTxt}>
                      {t("No_actions_required")}
                    </Typography>
                  )}
                </Grid>
                {(channelData?.claimStatus === "CLAIMED_BY_US" ||
                  channelData?.claimStatus === "CLAIMED_BY_OTHERS") && (
                  <Grid xs={6} sm={6} md={6} lg={6} sx={{ padding: "16px" }}>
                    <Typography className={classes.listingChannelTablehead}>
                      {t("Claim")}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography>{t("Claimed_by_others")}</Typography>

                      {channelData?.listingUrl !== null &&
                      channelData?.listingUrl !== undefined ? (
                        <a
                          style={{ color: "#06BDFF" }}
                          href={channelData?.listingUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LaunchIcon />
                        </a>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({
  channelData,
  location,
  channelsLoading,
  locationDetailsData,
  facebookConnect,
  googleConnect,
  handleConnectWithGoogle,
  handleNavigation,
  handleFacebookConnect,
  channelNames,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { formatDate } = useDateFormat();
  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: "1rem" }}>
        {channelsLoading ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                padding: "18px",
                marginTop: "100px",
                width: "100%",
              }}
            >
              <Loader />
            </Grid>
          </>
        ) : (
          <>
            <Table aria-label="simple table">
              <CustomizedTableHead>
                <CustomizedTableRow>
                  {/* <CustomizedTableCell align="left"></CustomizedTableCell> */}
                  <CustomizedTableCell align="left">
                    {t("Platform")}
                  </CustomizedTableCell>
                  {/* <CustomizedTableCell align="left">Additional Categories</CustomizedTableCell> */}
                  <CustomizedTableCell align="left">
                    {t("Status")}
                  </CustomizedTableCell>
                  <CustomizedTableCell align="left">
                    {t("Last_Checked_Date")}
                  </CustomizedTableCell>
                  {/* <CustomizedTableCell align="left">
                    {t("Actions")}
                  </CustomizedTableCell> */}
                </CustomizedTableRow>
              </CustomizedTableHead>

              {(location?.enableUberAll === "requested" ||
                location?.enableUberAll === "error" ||
                (location?.enableUberAll === "approved" &&
                  channelData?.length === 0)) && (
                <>
                  <CustomizedTableRow>
                    <CustomizedTableCell align="left">
                      <Grid container sx={{ aligItems: "center" }}>
                        <Grid
                          xs={4}
                          sm={4}
                          md={2.5}
                          lg={2}
                          sx={{ marginTop: "6px" }}
                        >
                          <img
                            alt=""
                            className={classes.sourceImage}
                            src={Google}
                          />
                        </Grid>
                        <Grid xs={8} sm={8} md={9.5} lg={10}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                paddingTop: "5px",
                                paddingLeft: "5px",
                              }}
                            >
                              {"Google"}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </CustomizedTableCell>

                    <CustomizedTableCell
                      align="left"
                      sx={{
                        color:
                          googleConnect !== null &&
                          googleConnect?.validToken === true
                            ? "#13CF8F"
                            : googleConnect !== null &&
                              googleConnect?.validToken === false
                            ? "yellow"
                            : "#FF0000",
                        textDecoration: "none",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {googleConnect !== null &&
                        googleConnect?.validToken === true ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              alt=""
                              src={updateIcon}
                              sx={{ width: "14px" }}
                            />
                            <Typography
                              sx={{ marginLeft: "7px", color: "#13CF8F" }}
                            >
                              {t("listing_b_updated")}
                            </Typography>
                          </Box>
                        ) : googleConnect !== null &&
                          googleConnect?.validToken === false ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              alt=""
                              src={ExpireIcon}
                              sx={{ width: "14px" }}
                            />
                            <Typography
                              onClick={() => {
                                handleNavigation(location);
                              }}
                              sx={{
                                marginLeft: "7px",
                                cursor: "pointer",
                                color: "#FF8C00",
                              }}
                            >
                              {t("token_expired")}
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              alt=""
                              src={DisconnectIcon}
                              sx={{ width: "14px" }}
                            />
                            <Typography
                              onClick={() => {
                                handleConnectWithGoogle(false);
                              }}
                              sx={{
                                marginLeft: "7px",
                                cursor: "pointer",
                                color: "#FF0000",
                              }}
                            >
                              {t("disconnected")}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </CustomizedTableCell>

                    <CustomizedTableCell align="left">
                      <Typography>
                        {locationDetailsData?.result?.lastSync !== null ? (
                          formatDate(locationDetailsData?.result?.lastSync)
                        ) : (
                          <>{"  " + "   --   "}</>
                        )}
                      </Typography>
                    </CustomizedTableCell>
                  </CustomizedTableRow>

                  {location?.enableFacebook === true && (
                    <CustomizedTableRow>
                      <CustomizedTableCell align="left">
                        <Grid container sx={{ aligItems: "center" }}>
                          <Grid
                            xs={4}
                            sm={4}
                            md={2.5}
                            lg={2}
                            sx={{ marginTop: "6px" }}
                          >
                            <img
                              alt=""
                              className={classes.sourceImage}
                              src={Facebook}
                            />
                          </Grid>
                          <Grid xs={8} sm={8} md={9.5} lg={10}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  paddingTop: "5px",
                                  paddingLeft: "5px",
                                }}
                              >
                                {"Facebook"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </CustomizedTableCell>

                      <CustomizedTableCell align="left">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {facebookConnect === true ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                alt=""
                                src={updateIcon}
                                sx={{ width: "14px" }}
                              />
                              <Typography
                                sx={{ marginLeft: "7px", color: "#13CF8F" }}
                              >
                                {t("listing_b_updated")}
                              </Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                alt=""
                                src={DisconnectIcon}
                                sx={{ width: "14px" }}
                              />
                              <Typography
                                sx={{
                                  marginLeft: "7px",
                                  color: "#FF0000",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleFacebookConnect(location);
                                }}
                              >
                                {t("disconnected")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </CustomizedTableCell>

                      <CustomizedTableCell align="left">
                        <Typography>
                          {locationDetailsData?.result?.lastSync !== null ? (
                            formatDate(locationDetailsData?.result?.lastSync)
                          ) : (
                            <>{"  " + "   --   "}</>
                          )}
                        </Typography>
                      </CustomizedTableCell>
                    </CustomizedTableRow>
                  )}

                  {channelNames?.map((item) => {
                    return (
                      <>
                        <CustomizedTableRow>
                          <CustomizedTableCell align="left">
                            <Grid container sx={{ aligItems: "center" }}>
                              <Grid
                                xs={4}
                                sm={4}
                                md={2.5}
                                lg={2}
                                sx={{ marginTop: "6px" }}
                              >
                                <img
                                  alt=""
                                  className={classes.sourceImage}
                                  src={`${baseURL}/assets/static/uberall_source/${item?.type}.png`}
                                />
                              </Grid>
                              <Grid xs={8} sm={8} md={9.5} lg={10}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      paddingTop: "5px",
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    {item?.typeName}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </CustomizedTableCell>

                          <CustomizedTableCell align="left">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <BootstrapTooltip
                                title={t("pending_request_tooltip")}
                              >
                                <Typography sx={{ color: "#FF8C00" }}>
                                  {"Pending"}
                                </Typography>
                              </BootstrapTooltip>
                            </Box>
                          </CustomizedTableCell>

                          <CustomizedTableCell align="left">
                            {" -- "}
                          </CustomizedTableCell>
                        </CustomizedTableRow>
                      </>
                    );
                  })}
                </>
              )}

              {location?.enableUberAll === "approved" &&
                channelData !== null &&
                channelData?.length > 0 && (
                  <>
                    <CustomizedTableRow>
                      <CustomizedTableCell align="left">
                        <Grid container sx={{ aligItems: "center" }}>
                          <Grid
                            xs={4}
                            sm={4}
                            md={2.5}
                            lg={2}
                            sx={{ marginTop: "6px" }}
                          >
                            <img
                              alt=""
                              className={classes.sourceImage}
                              src={Google}
                            />
                          </Grid>
                          <Grid xs={8} sm={8} md={9.5} lg={10}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  paddingTop: "5px",
                                  paddingLeft: "5px",
                                }}
                              >
                                {"Google"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </CustomizedTableCell>

                      <CustomizedTableCell
                        align="left"
                        sx={{
                          color:
                            googleConnect !== null &&
                            googleConnect?.validToken === true
                              ? "#13CF8F"
                              : googleConnect !== null &&
                                googleConnect?.validToken === false
                              ? "yellow"
                              : "#FF0000",
                          textDecoration: "none",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {/* <img 
alt="" src={updateIcon} sx={{ width: "14px" }} /> */}

                          {googleConnect !== null &&
                          googleConnect?.validToken === true ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                alt=""
                                src={updateIcon}
                                sx={{ width: "14px" }}
                              />
                              <Typography
                                sx={{ marginLeft: "7px", color: "#13CF8F" }}
                              >
                                {t("listing_b_updated")}
                              </Typography>
                            </Box>
                          ) : googleConnect !== null &&
                            googleConnect?.validToken === false ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                alt=""
                                src={ExpireIcon}
                                sx={{ width: "14px" }}
                              />
                              <Typography
                                onClick={() => {
                                  handleNavigation(location);
                                }}
                                sx={{
                                  cursor: "pointer",
                                  marginLeft: "7px",
                                  color: "#FF8C00",
                                }}
                              >
                                {t("token_expired")}
                              </Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                alt=""
                                src={DisconnectIcon}
                                sx={{ width: "14px" }}
                              />
                              <Typography
                                onClick={() => {
                                  handleConnectWithGoogle(false);
                                }}
                                sx={{
                                  cursor: "pointer",
                                  marginLeft: "7px",
                                  color: "#FF0000",
                                }}
                              >
                                {t("disconnected")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </CustomizedTableCell>

                      <CustomizedTableCell align="left">
                        <Typography>
                          {locationDetailsData?.result?.lastSync !== null ? (
                            formatDate(locationDetailsData?.result?.lastSync)
                          ) : (
                            <>{"  " + "   --   "}</>
                          )}
                        </Typography>
                      </CustomizedTableCell>
                    </CustomizedTableRow>

                    {location?.enableFacebook === true && (
                      <CustomizedTableRow>
                        <CustomizedTableCell align="left">
                          <Grid container sx={{ aligItems: "center" }}>
                            <Grid
                              xs={4}
                              sm={4}
                              md={2.5}
                              lg={2}
                              sx={{ marginTop: "6px" }}
                            >
                              <img
                                alt=""
                                className={classes.sourceImage}
                                src={Facebook}
                              />
                            </Grid>
                            <Grid xs={8} sm={8} md={9.5} lg={10}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    paddingTop: "5px",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  {"Facebook"}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </CustomizedTableCell>

                        <CustomizedTableCell align="left">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {facebookConnect === true ? (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <img
                                  alt=""
                                  src={updateIcon}
                                  sx={{ width: "14px" }}
                                />
                                <Typography
                                  sx={{ marginLeft: "7px", color: "#13CF8F" }}
                                >
                                  {t("listing_b_updated")}
                                </Typography>
                              </Box>
                            ) : (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <img
                                  alt=""
                                  src={DisconnectIcon}
                                  sx={{ width: "14px" }}
                                />
                                <Typography
                                  sx={{
                                    marginLeft: "7px",
                                    color: "#FF0000",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleFacebookConnect(location);
                                  }}
                                >
                                  {t("disconnected")}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </CustomizedTableCell>

                        <CustomizedTableCell align="left">
                          <Typography>
                            {locationDetailsData?.result?.lastSync !== null ? (
                              formatDate(locationDetailsData?.result?.lastSync)
                            ) : (
                              <>{"  " + "   --   "}</>
                            )}
                          </Typography>
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    )}

                    {channelData?.map((channelData, index) => (
                      <TableBody>
                        <Row
                          key={`${channelData?.id}-${index}`}
                          channelData={channelData}
                        />
                      </TableBody>
                    ))}
                  </>
                )}

              {(location?.enableUberAll === "pending" ||
                location?.enableUberAll === "rejected") && (
                <>
                  <CustomizedTableRow>
                    <CustomizedTableCell align="left">
                      <Grid container sx={{ aligItems: "center" }}>
                        <Grid
                          xs={4}
                          sm={4}
                          md={2.5}
                          lg={2}
                          sx={{ marginTop: "6px" }}
                        >
                          <img
                            alt=""
                            className={classes.sourceImage}
                            src={Google}
                          />
                        </Grid>
                        <Grid xs={8} sm={8} md={9.5} lg={10}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                paddingTop: "5px",
                                paddingLeft: "5px",
                              }}
                            >
                              {"Google"}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </CustomizedTableCell>

                    <CustomizedTableCell
                      align="left"
                      sx={{
                        color:
                          googleConnect !== null &&
                          googleConnect?.validToken === true
                            ? "#13CF8F"
                            : googleConnect !== null &&
                              googleConnect?.validToken === false
                            ? "yellow"
                            : "#FF0000",
                        textDecoration: "none",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {googleConnect !== null &&
                        googleConnect?.validToken === true ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              alt=""
                              src={updateIcon}
                              sx={{ width: "14px" }}
                            />
                            <Typography
                              sx={{ marginLeft: "7px", color: "#13CF8F" }}
                            >
                              {t("listing_b_updated")}
                            </Typography>
                          </Box>
                        ) : googleConnect !== null &&
                          googleConnect?.validToken === false ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              alt=""
                              src={ExpireIcon}
                              sx={{ width: "14px" }}
                            />
                            <Typography
                              onClick={() => {
                                handleNavigation(location);
                              }}
                              sx={{
                                marginLeft: "7px",
                                cursor: "pointer",
                                color: "#FF8C00",
                              }}
                            >
                              {t("token_expired")}
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              alt=""
                              src={DisconnectIcon}
                              sx={{ width: "14px" }}
                            />
                            <Typography
                              onClick={() => {
                                handleConnectWithGoogle(false);
                              }}
                              sx={{
                                marginLeft: "7px",
                                cursor: "pointer",
                                color: "#FF0000",
                              }}
                            >
                              {t("disconnected")}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </CustomizedTableCell>

                    <CustomizedTableCell align="left">
                      <Typography>
                        {locationDetailsData?.result?.lastSync !== null ? (
                          formatDate(locationDetailsData?.result?.lastSync)
                        ) : (
                          <>{"  " + "   --   "}</>
                        )}
                      </Typography>
                    </CustomizedTableCell>
                  </CustomizedTableRow>

                  {location?.enableFacebook === true && (
                    <CustomizedTableRow>
                      <CustomizedTableCell align="left">
                        <Grid container sx={{ aligItems: "center" }}>
                          <Grid
                            xs={4}
                            sm={4}
                            md={2.5}
                            lg={2}
                            sx={{ marginTop: "6px" }}
                          >
                            <img
                              alt=""
                              className={classes.sourceImage}
                              src={Facebook}
                            />
                          </Grid>
                          <Grid xs={8} sm={8} md={9.5} lg={10}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  paddingTop: "5px",
                                  paddingLeft: "5px",
                                }}
                              >
                                {"Facebook"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </CustomizedTableCell>

                      <CustomizedTableCell align="left">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {facebookConnect === true ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                alt=""
                                src={updateIcon}
                                sx={{ width: "14px" }}
                              />
                              <Typography
                                sx={{ marginLeft: "7px", color: "#13CF8F" }}
                              >
                                {t("listing_b_updated")}
                              </Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                alt=""
                                src={DisconnectIcon}
                                sx={{ width: "14px" }}
                              />
                              <Typography
                                sx={{
                                  marginLeft: "7px",
                                  color: "#FF0000",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleFacebookConnect(location);
                                }}
                              >
                                {t("disconnected")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </CustomizedTableCell>

                      <CustomizedTableCell align="left">
                        <Typography>
                          {locationDetailsData?.result?.lastSync !== null ? (
                            formatDate(locationDetailsData?.result?.lastSync)
                          ) : (
                            <>{"  " + "   --   "}</>
                          )}
                        </Typography>
                      </CustomizedTableCell>
                    </CustomizedTableRow>
                  )}
                </>
              )}
            </Table>
          </>
        )}
      </TableContainer>
    </>
  );
}
