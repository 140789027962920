import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Stack,
  Paper,
  Divider,
  styled,
  Button,
  Grid,
} from "@mui/material";
import Loader from "../../../../components/Loaders/Loader";
import { useStyles } from "../styles/style";
import moment from "moment";
import { api } from "../../../../contexts/JWTContext";
import useAuth from "../../../../hooks/useAuth";
import ProfilePictureModal from "./Components/pictureModal";
import TitleHeader from "../../../../components/CustomComponents/title";
import PostCard from "./addPostCard";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";

const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AccountsOverview = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const locationId = location.state?.location?.id;
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState([]);
  const [openPictureModal, setOpenPictureModal] = useState(false);
  const [showEditAll, setShowEditAll] = useState(false);
  const classes = useStyles();
  const [checkRequired, setCheckRequired] = useState(false);
  const [displayData, setDisplayData] = useState(false);
  const [bulkStartDate, setBulkStartDate] = useState(null);
  const [startDateRequired, setStartDateRequired] = useState(false);
  const [companyRequired, setCompanyRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);

  const [displayAllPosts, setDisplayAllPosts] = useState(false);

  let tempPostData = [...postData];

  // ** Change handler for all changes
  useEffect(() => {
    setOpenPictureModal(true);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (user) {
      setLoading(false);
    }
  }, [user]);

  const changeHandler = (index, itemName, value) => {
    if (index !== undefined) {
      tempPostData[index][`${itemName}`] = value;

      setPostData(tempPostData);
    } else {
      tempPostData?.map((item) => {
        item[`${itemName}`] = value;
      });
      setPostData(tempPostData);
    }
  };

  const changeHandlerBulkDateUpdate = (dateTime, timeInterval) => {
    let tempPostData = [...postData];
    let initialDate = dateTime;
    tempPostData?.map((item) => {
      item[`startDate`] = initialDate;

      if (dateTime !== null) {
        setStartDateRequired(false);
      }
      setBulkStartDate(dateTime);
      initialDate = moment(initialDate).add(timeInterval, "m").toDate();
    });

    setPostData(tempPostData);

    // if (index !== undefined) {
    //   tempPostData[index][`${itemName}`] = value;
    //

    //   setPostData(tempPostData);
    // } else {
    //   tempPostData?.map((item) => {
    //     item[`${itemName}`] = value;
    //   });
    //   setPostData(tempPostData);
  };
  /*   useEffect(() => {
    if (postData.length===0) {
      navigate("/user/allposts");
    }
  }, []); */

  const handleChangeDescription = (e, index) => {
    changeHandler(index, "content", e.target.value);
  };

  const handleChangeTitle = (e, index) => {
    changeHandler(index, "title", e.target.value);
  };

  const handleChangeStartDate = (e, index) => {
    setBulkStartDate(null);
    changeHandler(index, "startDate", e);
  };
  const handleChangeEndDate = (e, index) => {
    changeHandler(index, "endDate", e);
  };

  const handleChangeRepeatPost = (e, index) => {
    changeHandler(index, "repeat", e.target.value);
  };

  const handleChangeBusinessCta = (e, index) => {
    changeHandler(index, "postType", e.target.value);
    if (e.target.value === "CALL" && index) {
      let tempPostData = [...postData];
      let tempPostObj = tempPostData[index];
      tempPostObj["url"] = "";
      tempPostData[index] = tempPostObj;
      setPostData(tempPostData);
    }
  };

  const handleChangeButtonUrl = (e, index) => {
    changeHandler(index, "url", e.target.value);
  };
  const handleChangeCompany = (e, index) => {
    if (e !== null) {
      setCompanyRequired(false);
    }
    changeHandler(index, "companyId", e?.id);
    changeHandler(index, "companyIdData", e);
  };
  const handleChangeCompanyData = (e, index) => {
    // changeHandler(index, "locationId", e);
    changeHandler(index, "companyIdData", e);
  };
  const handleChangeAIDescriptionForBulk = (e, index) => {
    changeHandler(index, "content", e);
  };
  const handleChangeAIDescription = (e, index) => {
    changeHandler(index, "content", e);
  };
  const handleChangeLocation = (e, index) => {
    if (e?.length > 0) {
      setLocationRequired(false);
    }
    // changeHandler(index, "locationId", e);
    changeHandler(index, "locationIds", e);
  };
  const handleChangeLocationData = (e, index) => {
    // changeHandler(index, "locationId", e);
    changeHandler(index, "locationData", e);
  };
  const handleChangeCheckbox = (e, index) => {
    changeHandler(index, "isUploadPicture", e.target.checked);
  };

  const handleDeletePost = (e, index) => {
    const filteredPosts = postData?.filter((post, indx) => {
      return indx !== index;
    });

    setPostData(filteredPosts);
  };

  const handleShowEditAll = () => {
    setShowEditAll(!showEditAll);
  };

  const handleOpenPictureModal = () => {
    setOpenPictureModal(true);
  };
  const handleClosePictureModal = () => {
    // alert("here");
    setOpenPictureModal(false);
  };
  const handleCloseNavigate = () => {
    // alert("here");
    navigate(-1);
  };

  const handleProfilePictureUpload = async (formData) => {
    if (user) {
    }
  };

  const cancelHandler = () => {
    navigate("/admin/allposts", {
      state: { locationId: location.state?.location },
    });
    // setDisplayAllPosts(true);
  };

  const addpostbutton = () => {
    // navigate("/user/allposts", {
    //   state: { row }
    // });
    navigate("/admin/allposts");
  };
  const postsHandler = async () => {
    let tempRequiredCount = 0;
    postData.map((item) => {
      if (item?.startDate === null || item?.locationIds === "") {
        tempRequiredCount++;
      }
      if (item?.postType !== "CALL") {
        if (item?.url === "") {
          tempRequiredCount++;
        }
      }
    });

    if (tempRequiredCount === 0) {
      setIsLoading(true);
      // if (!"title") {
      //   setTitleRequired(true);
      // }
      try {
        setIsLoading(true);
        const res = await api.post(`/post`, { data: postData });

        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message);

          // navigate("/user/posts");
          // setDisplayAllPosts(true);

          setIsLoading(false);
          navigate("/admin/allposts", {
            state: { locationId: location.state?.location },
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    } else {
      setCheckRequired(!checkRequired);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* {displayAllPosts ? (
            <>
              <Typography>Thanks for submitting post </Typography>
            </>
          ) : ( */}

          <TitleHeader
            title={t("Add_Post")}
            subHeading={t("post_subHeading")}
          />
          <Paper className={classes.postBackground}>
            {postData.length === 0 && (
              <Grid container>
                <Grid sm={2} md={4}></Grid>
                <Grid xs={12} sm={8} md={4}>
                  <Box sx={{ justifyContent: "center", mt: 6 }}>
                    <Card className={classes.postCard} centered>
                      <CardContent>
                        <Typography variant="h3" sx={{ mt: 4 }}>
                          {t("No_more_posts")}
                        </Typography>

                        <Typography
                          className={classes.postTypoMain}
                          color="text.secondary"
                        >
                          {t("Post_subtext")}
                        </Typography>
                        <CardActions className={classes.postTypoMain}>
                          <CommonButton
                            label={t("Add_Posts_button")}
                            //  onSubmit={() => {
                            //   navigate("/user/posts", {
                            //     state: { row },
                            //   });
                            // }}
                            onSubmit={addpostbutton}
                          />
                        </CardActions>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid sm={2} md={4}></Grid>
              </Grid>
            )}

            {postData?.length < 0 && (
              <Typography className={classes.noPost}>
                {t("No_more_posts")}
              </Typography>
            )}

            {postData?.length > 0 && (
              <>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#FFFFFF",
                    width: "1,532px",
                    textAlign: "center",
                    backgroundColor: "#0638C1",
                    paddingY: "14px",
                    // paddingX: "20px",
                    borderRadius: "8px",
                    marginTop: "24px",
                    // marginX: "24px",
                    cursor: "pointer",
                  }}
                  onClick={handleShowEditAll}
                >
                  {t("Post_Desc")}
                </Typography>
              </>
            )}

            {showEditAll && postData.length > 0 && (
              <Box
                sx={{
                  border: "3px solid blue",
                  paddingX: "14px",
                  borderRadius: "8px",
                }}
              >
                <Grid container sx={{ marginTop: "8px", marginBottom: "14px" }}>
                  <Grid item md={12} xs={12}>
                    <PostCard
                      postData={postData}
                      handleChangeStartDate={handleChangeStartDate}
                      handleChangeEndDate={handleChangeEndDate}
                      handleChangeRepeatPost={handleChangeRepeatPost}
                      handleChangeBusinessCta={handleChangeBusinessCta}
                      handleChangeCheckbox={handleChangeCheckbox}
                      handleChangeButtonUrl={handleChangeButtonUrl}
                      handleChangeDescription={handleChangeDescription}
                      handleChangeAIDescription={handleChangeAIDescription}
                      handleChangeAIDescriptionForBulk={
                        handleChangeAIDescriptionForBulk
                      }
                      handleChangeCompany={handleChangeCompany}
                      handleChangeLocation={handleChangeLocation}
                      handleChangeTitle={handleChangeTitle}
                      handleDeletePost={handleDeletePost}
                      showEditAll={showEditAll}
                      bulkStartDate={bulkStartDate}
                      setShowEditAll={setShowEditAll}
                      startDateRequired={startDateRequired}
                      setDisplayData={setDisplayData}
                      changeHandlerBulkDateUpdate={changeHandlerBulkDateUpdate}
                      handleChangeLocationData={handleChangeLocationData}
                      handleChangeCompanyData={handleChangeCompanyData}
                      setCompanyRequired={setCompanyRequired}
                      companyRequired={companyRequired}
                      setLocationRequired={setLocationRequired}
                      locationRequired={locationRequired}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {!openPictureModal &&
              postData?.map((data, index) => (
                <Stack direction="column">
                  <PostCard
                    index={index}
                    data={data}
                    postData={postData}
                    handleChangeStartDate={handleChangeStartDate}
                    handleChangeEndDate={handleChangeEndDate}
                    handleChangeRepeatPost={handleChangeRepeatPost}
                    handleChangeCheckbox={handleChangeCheckbox}
                    handleChangeBusinessCta={handleChangeBusinessCta}
                    handleChangeButtonUrl={handleChangeButtonUrl}
                    handleChangeLocation={handleChangeLocation}
                    handleChangeDescription={handleChangeDescription}
                    handleChangeCompany={handleChangeCompany}
                    handleChangeAIDescription={handleChangeAIDescription}
                    handleChangeTitle={handleChangeTitle}
                    handleDeletePost={handleDeletePost}
                    checkRequired={checkRequired}
                    setDisplayData={setDisplayData}
                    setBulkStartDate={setBulkStartDate}
                    startDateRequired={startDateRequired}
                    setStartDateRequired={setStartDateRequired}
                    setCompanyRequired={setCompanyRequired}
                    companyRequired={companyRequired}
                    setLocationRequired={setLocationRequired}
                    locationRequired={locationRequired}
                  />
                  <Divider />
                </Stack>
              ))}
            <Stack direction="row" className={classes.postStack}>
              {postData?.length > 0 && (
                <Item>
                  <Button
                    className={classes.postcancelButton}
                    variant="outlined"
                    onClick={() => {
                      cancelHandler();
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  &nbsp; &nbsp;
                  <CommonButton
                    isLoading={isLoading}
                    label={t("Add_Posts")}
                    onSubmit={postsHandler}
                    disabled={postData[0]?.content?.length >= 1500}
                  />
                </Item>
              )}
            </Stack>
          </Paper>
          <ProfilePictureModal
            locationId={locationId}
            handleClose={handleClosePictureModal}
            open={openPictureModal}
            loading={loading}
            handlePictureUpload={handleProfilePictureUpload}
            setPostData={setPostData}
            handleCloseNavigate={handleCloseNavigate}
            postData={postData}
            // images={images}
            // setImages={setImages}
          />
        </>
      )}
    </>
  );
};

export default AccountsOverview;
