import React, { useState, useEffect } from "react";
import data from "../data.json";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useStyles } from "../../../../Styles/style";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Brandtags({ locationDetailsData }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [allData, setAllData] = useState([]);
  const [dataCount, setDataCount] = useState(3);

  const loadMoreData = () => {
    setDataCount(locationDetailsData?.result?.length);
  };
  const loadLessData = () => {
    setDataCount(3);
  };
  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {locationDetailsData?.result?.google?.categories
          ?.additionalCategories &&
          locationDetailsData?.result?.google?.categories?.additionalCategories
            ?.length > 0 &&
          locationDetailsData?.result?.google?.categories?.additionalCategories
            .slice(0, dataCount)
            .map((additionalCategories) => (
              <Item
                className={classes.tagsItemBrand}
                key={additionalCategories?.name}
              >
                <Typography className={classes.tagsTextBrands}>
                  {additionalCategories?.displayName}{" "}
                </Typography>
              </Item>
            ))}
      </Box>

      {locationDetailsData?.result?.google?.categories?.additionalCategories
        ?.length > 3 ? (
        <>
          <Divider />
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ alignItems: "start", padding: "12px" }}
          >
            {dataCount >= 3 ? (
              <Button onClick={loadMoreData}>
                <Typography className={classes.viewMoreButton}>
                  {t("View_More")}
                </Typography>
              </Button>
            ) : (
              <Button onClick={loadLessData}>
                <Typography className={classes.viewMoreButton}>
                  {t("View_Less")}
                </Typography>
              </Button>
            )}
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
export default Brandtags;
