import React, { useState, useEffect } from "react";
import { DateRangePicker } from "mui-daterange-picker";
import {
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Box,
  IconButton,
  Typography,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styled from "styled-components/macro";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import RemoveIcon from "@mui/icons-material/Remove";
import useDateFormat from "../../../../../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const DateRangeComponent = ({
  handleChangeEndDate,
  handleChangeStartDate,
  startDate,
  endDate,
  fromFilters,
  rangeValue,
  fromSchedule,
  repeatValue,
  setCallApi,
  removePadding,
  handleChangeDateRangesfromRangeModel,
}) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  const { format } = useDateFormat();
  const [modelRange, setModelRange] = useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  });

  useEffect(() => {
    if (open) {
      setModelRange({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
    }
  }, [open]);

  useEffect(() => {
    const yearSelect = document.querySelector(
      ".MuiPicker-yearSelection select"
    );
    if (yearSelect) {
      const yearOptions = yearSelect.options;
      for (let i = 0; i < yearOptions.length; i++) {
        const year = parseInt(yearOptions[i].value);
        if (year > 2024) {
          yearOptions[i].disabled = true;
        }
      }
    }
  }, [open]);

  const handleOpen = () => setOpen(true);

  const handleDateChange = (range) => {
    setModelRange(range);
  };

  const handleSaveDateRange = () => {
    if (setCallApi !== undefined) {
      setCallApi(false);
    }
    if (handleChangeDateRangesfromRangeModel !== undefined) {
      handleChangeDateRangesfromRangeModel(
        new Date(modelRange?.startDate.setHours(0, 0, 0, 0)),
        new Date(modelRange?.endDate.setHours(23, 59, 59, 999))
      );
    } else {
      handleChangeStartDate(
        new Date(modelRange?.startDate.setHours(0, 0, 0, 0))
      );
      handleChangeEndDate(
        new Date(modelRange?.endDate.setHours(23, 59, 59, 999))
      );
    }

    setOpen(false);
  };

  const startDateNew = new Date();
  startDateNew.setDate(startDateNew.getDate() - 7);

  // Calculate end date (today)
  const endDateNew = new Date();

  // Calculate today's start and end dates
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0); // Set to midnight
  const todayEnd = new Date();
  todayEnd.setHours(23, 59, 59, 999); // Set to end of the day

  // Calculate yesterday's start and end dates
  const yesterdayStart = new Date(todayStart);
  yesterdayStart.setDate(yesterdayStart.getDate() - 1);
  const yesterdayEnd = new Date(todayEnd);
  yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);

  // Calculate start and end dates for this week
  const today = new Date();
  const thisWeekStart = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay() + 1
  ); // Monday of the current week

  // If today is Sunday, adjust to get the previous Monday
  if (today.getDay() === 0) {
    thisWeekStart.setDate(thisWeekStart.getDate() - 7);
  }

  const thisWeekEnd = new Date(
    thisWeekStart.getFullYear(),
    thisWeekStart.getMonth(),
    thisWeekStart.getDate() + 6
  ); // Sunday of the current week

  // Calculate start and end dates for last week (Monday to Sunday)
  const lastWeekStart = new Date(thisWeekStart);
  lastWeekStart.setDate(lastWeekStart.getDate() - 7); // Monday of last week
  const lastWeekEnd = new Date(thisWeekEnd);
  lastWeekEnd.setDate(lastWeekEnd.getDate() - 7); // Sunday of last week
  // Calculate the first and last dates of the previous month
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

  // Calculate the first and last dates of the previous year
  const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
  const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);

  const ranges = [
    {
      label: t("today"),
      startDate: new Date().setHours(0, 0, 0, 0),
      endDate: new Date().setHours(59, 59, 59, 59),
    },

    {
      label: t("This_Week"),
      startDate: thisWeekStart,
      endDate: thisWeekEnd,
    },

    {
      label: t("This_Month"),
      startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      endDate: new Date(),
    },
  ];

  const handleClose = () => {
    setModelRange({
      startDate: startDate,
      endDate: endDate,
    });
    setOpen(false);
  };

  return (
    <Grid container sx={{ padding: "0px" }}>
      <Grid
        item
        xs={5}
        sm={5}
        md={5}
        lg={5.5}
        sx={{ padding: removePadding ? "8px 0px 8px 0px" : "8px 0px 8px 8px" }}
      >
        {fromFilters && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "28px",
              color: "#1B2430",
              // marginBottom: "6px",
              // marginLeft: "10px",
              marginTop: "6px",
            }}
          >
            {t("Fromm")}
          </Typography>
        )}
        <TextField
          value={startDate ? dayjs(startDate).format(format) : ""}
          // onChange={onChange}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton edge="end" onClick={handleOpen} size="large">
                <InsertInvitationIcon />
              </IconButton>
            ),
          }}
          fullWidth
          placeholder={!fromFilters ? t("Fromm") : format}
          label={!fromFilters ? t("Fromm") : ""}
          sx={{ background: "#FFFF" }}
        />
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1} sx={{ textAlign: "center" }}>
        <RemoveIcon sx={{ marginTop: "55px" }} />
      </Grid>{" "}
      {repeatValue !== "no" && (
        <Grid
          item
          xs={5}
          sm={5}
          md={5}
          lg={5.5}
          sx={{
            padding: "8px 0px 8px 8px",
          }}
        >
          {fromFilters && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "28px",
                color: "#1B2430",
                // marginBottom: "6px",
                // marginLeft: "10px",
                marginTop: "6px",
              }}
            >
              {t("Too")}
            </Typography>
          )}
          <TextField
            value={endDate ? dayjs(endDate).format(format) : ""}
            // onChange={onChange}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton edge="end" onClick={handleOpen} size="large">
                  <InsertInvitationIcon />
                </IconButton>
              ),
            }}
            fullWidth
            placeholder={!fromFilters ? t("Too") : format}
            label={!fromFilters ? t("Too") : ""}
            sx={{ background: "#FFFF" }}
          />
        </Grid>
      )}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "620px",
            height: "540px",
            borderRadius: "4px",
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <Grid>
          <Grid container sx={{ padding: "12px" }}>
            <Grid
              xs={12}
              sm={11.5}
              md={11.5}
              lg={11.5}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                {t("Select_Date_Range")}
              </Typography>
              <BootstrapTooltip
                title={
                  <h1 style={{ fontSize: "12px" }}>
                    {t("ErrorOutlineIconTooltip")}
                  </h1>
                }
              >
                <IconButton
                  sx={{
                    zIndex: "10",
                  }}
                >
                  <ErrorOutlineIcon
                    sx={{
                      color: "#1B2430",
                    }}
                  />
                </IconButton>
              </BootstrapTooltip>
            </Grid>
            <Grid xs={0.5} sm={0.5} md={0.5} lg={0.5}>
              <IconButton
                autoFocus
                onClick={handleClose}
                // className="delete_button"
                sx={{
                  paddingTop: "0px",
                  cursor: "pointer",
                  alignItems: "start",
                }}
              >
                <CloseIcon
                  sx={{
                    zIndex: "10",
                    paddingTop: "0px",
                    cursor: "pointer",
                    alignItems: "start",
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            sx={{
              //  padding: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DateRangePicker
              open={open}
              toggle={handleClose}
              onChange={handleDateChange}
              // value={modelRange}
              initialDateRange={modelRange}
              minDate={new Date(new Date().setHours(0, 0, 0, 0))}
              // maxDate={currentDateState}
              definedRanges={[]}
            />
          </Grid>
        </Grid>
        <Grid>
          <Divider />
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ justifyContent: "end", padding: "8px", textAlign: "end" }}
            >
              <CommonButton
                label={t("Save")}
                onSubmit={handleSaveDateRange}
                //  loading={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <style>
        {`
          .MuiDialogActions-root {
            display: block !important;
            text-align: right;
          }
        
            .MuiDivider-fullWidth + .MuiDivider-vertical{
            display: none !important;
          }
        .MuiDivider-vertical{
            display: none !important;
          }
             
        `}
      </style>
    </Grid>
  );
};

export default DateRangeComponent;
