// ** React Imports ================================================================
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";

// ** File Imports ================================================================
import { languagesData } from "../../../../assets/defaultLanguage";
import Delete from "../../../../components/Models/DeleteModal/Delete";
// ** Material ui Imports ================================================================
import styled from "styled-components/macro";
import { Typography, Dialog, MenuItem, TextareaAutosize } from "@mui/material";

import {
  Box,
  Grid,
  Tooltip,
  tooltipClasses,
  IconButton,
  FormControl,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStyles } from "../styles/style";

// ** Other Imports ================================================================
import "react-toastify/dist/ReactToastify.css";

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});
//const Divider = styled(MuiDivider)(spacing);

const ShowTemplates = ({
  item,
  index,
  changeHandler,
  deleteTemplate,
  checkRequired,
  defaultTemplate,
  dataArray,
  languagesDataArray,
}) => {
  const { t } = useTranslation();
  // ** States ================================================================
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [textRequired, setTextRequired] = useState(false);

  const [reviewerName, setReviewerName] = useState(false);
  const textArea = useRef();

  useEffect(() => {
    if (checkRequired === true) {
      if (item.text?.length < 1) {
        setTextRequired(true);
      } else {
        setTextRequired(false);
      }
    }
  }, [checkRequired]);
  // ** Others ================================================================

  // ** Handler Functions ================================================================
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Template_title_modal")}
          description={t("Template_delete_modal")}
          onConfirm={() => {
            deleteTemplate(index);
            handleClose();
          }}
          onCancel={handleClose}
        />
      </Dialog>

      <Grid container spacing={2}>
        <>
          <Grid item xs={12} sm={12} lg={4} md={4}>
            <FormControl
              // className={classes.formControl}
              fullWidth

              // className="form-style"
            >
              <Typography className={classes.reviewName}>
                {t("Language_review")}
              </Typography>
              {/* <InputLabel id="demo-simple-select-label">
                      <Box className="input-box">
                        <LanguageIcon /> {t("Language")}
                      </Box>
                    </InputLabel> */}

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={
                  languagesDataArray?.length > 0 ? languagesDataArray : []
                }
                value={languagesData?.find(
                  (data) => data?.value === item?.language
                )}
                onChange={(e, value) => {
                  if (value !== null) {
                    changeHandler(value, index, true, false);
                  } else {
                    changeHandler(undefined, index, true, false);
                  }
                }}
                getOptionLabel={(option) =>
                  option?.language ? option?.language : ""
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    placeholder={t("Select_languages")}
                    {...params}
                    error={item?.languageError}
                  />
                )}
              />
              {/*  <Select
                  error={languageRequired}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={item?.language}
                  onChange={(e, value) => {
                    setLanguageRequired(false);
                    changeHandler(e, index, true, false);
                    // languageChangeHandler(e);
                  }}
                  // className="select-box"
                >
                  {languagesData?.map((item) => (
                    <MenuItem value={item?.value} name={item?.language}>
                      {item.language}
                    </MenuItem>
                  ))}
                 
                </Select> */}
            </FormControl>
            <br />
            <FormControl
              // className={classes.formControl}
              // fullWidth
              fullWidth
            >
              <Typography className={classes.personalizeText}>
                {t("Personalize")}
              </Typography>

              <Select
                blurOnSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reviewerName}
                onChange={(e, value) => {
                  // setReviewerName(e.target.value);
                  changeHandler(e, index, false, true, textArea);
                  /*  setTimeout(() => {
                      setReviewerName("");
                    }, 500); */
                }}
                // className="select-box"
              >
                <MenuItem value="{{first_name}}">
                  <div>
                    {t("First_name")} <br />{" "}
                    <span className={classes.menu_item}>ex: john</span>
                  </div>
                </MenuItem>
                <MenuItem value="{{last_name}}">
                  <div>
                    {t("Last_name")} <br />{" "}
                    <span className={classes.menu_item}>ex: alpha</span>
                  </div>
                </MenuItem>
                <MenuItem value="{{business_email}}">
                  <div>
                    {t("Business_email")} <br />{" "}
                    <span className={classes.menu_item}>
                      ex: john@obenan.com
                    </span>
                  </div>
                </MenuItem>
                <MenuItem value="{{business_phone_number}}">
                  <div>
                    {t("Phone_no")}
                    <br />{" "}
                    <span className={classes.menu_item}>
                      ex: +3197010281297
                    </span>
                  </div>
                </MenuItem>
                {/*   <MenuItem value="{{reviewer}}">
                    <div>
                      Reviewer Name <br />{" "}
                      <span className="menu-item">ex: john</span>
                    </div>
                  </MenuItem> */}

                <MenuItem value="{{location}}">
                  <div>
                    {t("Location_Name")}
                    <br />{" "}
                    <span className={classes.menu_item}>ex: Factory Girl</span>
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10.5} sm={11} lg={7.5} md={7}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography className={classes.descriptionText}>
                {t("Description")} {index + 1}
              </Typography>

              <Typography className={classes.descLengthText}>
                {item?.text?.length}/4000{" "}
              </Typography>

              {item?.text?.length >= 4000 && (
                <Typography className={classes.limitText}>
                  {t("text_limit_text")}
                </Typography>
              )}
            </Grid>
            <Box>
              {/*   <TextareaAutosize
                  id="filled-multiline-static"
                  multiline
                  style={{
                    border: "1px solid #E0E0E0",
                    fontFamily: "Segoe UI",
                    fontStyle: "Normal",
                    padding: "12px 12px 12px 12px",
                    width: "100%",
                    gap: "8px",
                    borderRadius: "8px",
                    minHeight: "120px",
                    color: "#1B2430",
                    fontWeight: "400",
                    fontSize: "16px",
                    //   padding: "15px",
                  }}
                  ref={textArea}
                  error={textRequired}
                  aria-label="empty textarea"
                  placeholder="Write down template description ..."
                  value={item?.text}
                  onChange={(e) => {
                    if (e.target?.value?.length < 1000) {
                      setTextRequired(false);
                      changeHandler(e, index);
                    }
                  }}
                  InputProps={{
                    disableunderline: true,
                  }}
                  inputProps={{ maxLength: 1000 }}
                /> */}
              <TextareaAutosize
                id="standard-basic"
                // label="Standard"
                rows={5.8}
                maxRows={10}
                value={item?.text}
                style={{
                  border: "1px solid #c4c4c4",
                  fontFamily: "Segoe UI",
                  fontStyle: "Normal",
                  padding: "12px 12px 12px 12px",
                  width: "100%",
                  gap: "8px",
                  borderRadius: "4px",
                  minHeight: "120px",
                  color: "#1B2430",
                  fontWeight: "400",
                  fontSize: "16px",
                  "&:hover ": {
                    border: "1px solid #54d2ff",
                  },

                  //   padding: "15px",
                }}
                label={null}
                placeholder={t("desc_placeholder")}
                onChange={(e) => {
                  if (item?.text?.length > 4000) {
                    setTextRequired(true);
                  } else {
                    setTextRequired(false);
                  }
                  // setTextRequired(false);
                  // setText(e.target.value);
                  changeHandler(e, index);
                  if (
                    e.target.value?.includes(" {{first_name}} ") === false &&
                    reviewerName === "{{first_name}}"
                  ) {
                    setReviewerName("");
                  } else if (
                    e.target.value?.includes(" {{last_name}} ") === false &&
                    reviewerName === "{{last_name}}"
                  ) {
                    setReviewerName("");
                  } else if (
                    e.target.value?.includes(" {{business_email}} ") ===
                      false &&
                    reviewerName === "{{business_email}}"
                  ) {
                    setReviewerName("");
                  } else if (
                    e.target.value?.includes(" {{business_phone_number}} ") ===
                      false &&
                    reviewerName === "{{business_phone_number}}"
                  ) {
                    setReviewerName("");
                  } else if (
                    e.target.value?.includes(" {{location}} ") === false &&
                    reviewerName === "{{location}}"
                  ) {
                    setReviewerName("");
                  }
                }}
                multiline
                fullWidth
                ref={textArea}
                error={textRequired}
                /*   InputProps={{
                    disableUnderline: true,
                  }} */
                inputProps={{ maxLength: 4000 }}
                /*   InputProps={{
                inputComponent: TextareaAutosize,
              }} */
              />
              {textRequired && (
                <Typography className={classes.descRequired}>
                  {t("desc_req")}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sm={1}
            md={1}
            lg={0.5}
            className={classes.grid_item_2}
          >
            {dataArray.length > 1 && (
              <CustomWidthTooltipOfDelete
                title={t("Delete_Template")}
                sx={{ marginTop: ".5rem", color: "#FC3652" }}
              >
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    deleteTemplate(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </CustomWidthTooltipOfDelete>
            )}
          </Grid>

          {/* <FormControl
                    // className={classes.formControl}
                    // fullWidth

                    className="form-style"
                  >
                    <InputLabel id="demo-simple-select-label">
                      <Box className="input-box">
                        <LanguageIcon /> {t("Language")}
                      </Box>
                    </InputLabel>
                    <Select
                      error={languageRequired}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={item?.language}
                      label={"dummy11111111"}
                      onChange={(e, value) => {
                        setLanguageRequired(false);
                        changeHandler(e, index, true, false);
                        // languageChangeHandler(e);
                      }}
                      className="select-box"
                    >
                      {languagesData?.map((item) => (
                        <MenuItem value={item?.value} name={item?.language}>
                          {item.language}
                        </MenuItem>
                      ))}

                    </Select>
                  </FormControl> */}
          {/* <FormControl
                    // className={classes.formControl}
                    // fullWidth
                    className="form-style"
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Personalize
                    </InputLabel>
                    <Select
                      blurOnSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={reviewerName}
                      label="Personalize"
                      onChange={(e, value) => {
                        setReviewerName(null);
                        changeHandler(e, index, false, true, textArea);
                      }}
                      className="select-box"
                    >
                      <MenuItem value="{{reviewer}}">
                        <div>
                          Reviewer Name <br />{" "}
                          <span className="menu-item">ex: john</span>
                        </div>
                      </MenuItem>
                      <MenuItem value="{{location}}">
                        <div>
                          Location Name <br />{" "}
                          <span className="menu-item">ex: Factory Girl</span>
                        </div>
                      </MenuItem>

                    </Select>
                  </FormControl>

                  <Autocomplete
                    id="combo-box-demo"
                    className="select-box"
                    options={
                      defaultTemplate?.results?.length > 0
                        ? defaultTemplate?.results
                        : []
                    }
                    getOptionLabel={(option) =>
                      option?.title ? option.title : ""
                    }
                    sx={{
                      // width: "100%",
                      background: "#f5f5f5",
                    }}
                    // value={locationId}
                    onChange={(e, value) => {
                      if (value) {
                        const result = value?.templateDescription.find(
                          (item) => item.language==="en"
                        );
                        changeHandler(
                          { target: { value: result?.text } },
                          index
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField placeholder="Default templates" {...params} />
                    )}
                  ></Autocomplete> */}
        </>
      </Grid>
    </>
  );
};

export default ShowTemplates;
