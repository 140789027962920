import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  TextField,
  Card,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Dialog } from "@material-ui/core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../.././../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../.././../components/Loaders/Loader";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
// import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import ConfirmModal from "../../../../../.././../components/Models/ConfirmationModal";
import ConfirmModalTwo from "../../../../../../../components/Models/ConfirmModalLodging";
import Delete from "../../../../../../../components/Models/DeleteModal/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LocationProductsSection from "./ProductsSections";
import Images from "./Images/index";
import { languagesData } from "../../../../../../../assets/defaultLanguage";
import AddProductSection from "./ProductsSections/AddSectionModel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const LocationProductsModel = ({
  onCancel,
  data,
  locationDetailsData,
  location,
  locationCategory,
  locationFacebookCategory,
  getLocationDetails,
  getLocationDetailsListing,
  facebookConnect,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  setIndexValue,
  addressTouchedValue,
  setAddressTouchedValue,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const theme = useTheme();
  const containerRef = useRef(0);
  const [cancelState, setCancelState] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState(0);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [otherValue, setOtherValue] = useState(null);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [locationProducts, setLocationProducts] = useState([]);
  const [openAddSectionModel, setOpenAddSectionModel] = useState(false);
  const [selectedSectionData, setSelectedSectionData] = useState(null);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [callApi, setCallApi] = useState(false);
  const [openState, setOpenState] = useState(null);
  const [page, setPage] = useState(0);
  const [finalUploadImages, setFinalUploadImages] = useState([]);
  const [finalDeleteImages, setFinalDeleteImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [mediaCount, setMediaCount] = useState(0);
  const [foodPhotoImages, setFoodPhotoImages] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);

  useEffect(() => {
    if (value === 0) {
      getAllMenus();
    }
  }, [value]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (callApi) {
        getAllMenus();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const handleScroll = () => {
    if (containerRef.current !== null) {
      containerRef.current.scrollTop = 0;
    }
  };

  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      getLocationDetails(location);
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const getLocationMedia = async (pageValue) => {
    setIsLoading(true);
    try {
      const res = await api.post(
        `/location/${location?.id}/media?pageNumber=${
          pageValue !== undefined ? pageValue : page + 1
        }&limit=${"24"}`,
        {
          category: "FOOD_AND_MENU",
        }
      );
      if (res.status === 200) {
        if (res?.data?.data?.media?.length > 0) {
          let arr = [];
          res?.data?.data?.media?.map((item) => {
            setMediaCount(res?.data?.data?.mediaCount);

            item["alreadyUploadedPhoto"] = true;
            arr.push(item);
          });
          if (pageValue === undefined) {
            setPage(page + 1);
          } else {
            setPage(1);
          }

          setFoodPhotoImages([...arr]);
        }

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchMoreFoodrData = async () => {
    if (foodPhotoImages?.length < mediaCount) {
      setPreviewLoading(true);
      try {
        // setReviewsLoading(true);
        const res = await api.post(
          `/location/${location?.id}/media?pageNumber=${page + 1}&limit=24`,
          {
            category: "FOOD_AND_MENU",
          }
        );
        if (res.status === 200) {
          if (res.data.data?.media?.length > 0) {
            let data;

            let tempImages = foodPhotoImages;
            data = { ...res.data.data?.media };
            data = [...tempImages, ...res.data.data?.media];
            setPreviewImages(data);

            data?.map((item) => {
              if (item?.isDeleted === false) {
                item["alreadyUploadedPhoto"] = true;
                foodPhotoImages.push(item);
              }
            });
          }

          setPage(page + 1);
          setFoodPhotoImages([...foodPhotoImages]);
          setPreviewLoading(false);
          //  setReviewsLoading(false);
        } else {
          setFoodPhotoImages([]);
          setPreviewLoading(false);
        }
      } catch (error) {
        // setReviewsData([]);
        setPreviewLoading(false);
        //  setReviewsLoading(false);
      }
    } else {
      //  setHasMore(false);
    }
  };

  const handleChange = (event, newValue) => {
    if (touchedValue === true) {
      setOpenConfirmBox(true);
      setOtherValue(newValue);
    } else {
      setPage(0);
      setFoodPhotoImages([]);
      setFinalDeleteImages([]);
      setFinalUploadImages([]);
      setValue(newValue);
      setOtherValue(null);
    }
  };

  const handleOpenProductSectionModel = (key, sectionData) => {
    // setSelectedSectionData(sectionData);

    let tempData = locationProducts;
    const indexValueForanySection = tempData[0]?.sections?.findIndex(
      (item) => item?.hasOwnProperty("edit") && item?.edit === true
    );
    if (indexValueForanySection > -1) {
      delete tempData[0].sections[indexValueForanySection]["edit"];
    }

    setLocationProducts(tempData);
    setOpenState(key);
    setOpenAddSectionModel(true);
  };
  const handleCloseProductSectionModel = () => {
    setOpenAddSectionModel(false);
    setSelectedSectionData(null);
    setSelectedItemData(null);
    setOpenState(null);
  };

  const getAllMenus = async (menuId) => {
    setLoading(true);
    try {
      const res = await api.get(
        `/menus/${
          menuId !== undefined ? menuId : locationDetailsData?.result?.menusId
        }?search=${search}`
      );
      setLoading(false);
      if (res.status === 200) {
        setLocationProducts(res?.data?.data?.menus);
        handleScroll();
        setCallApi(false);
      }
    } catch (error) {
      setLocationProducts([]);
      setLoading(false);
      setCallApi(false);
    }
  };
  const handleCloseBox = () => {
    setOpenConfirmBox(false);
    if (otherValue !== null) {
      setValue(otherValue);
      setTouchedValue(false);
    }
  };
  const handleUpdateCoverImages = async () => {
    if (finalUploadImages?.length > 0) {
      setPage(0);
      finalUploadImages?.map((item) => {
        delete item.alreadyUploadedPhoto;
      });
      try {
        setIsLoading(true);
        setDisplayConfirmationBox(false);
        const res = await api.post(
          `/locationListing/add-media/${location?.id}`,
          {
            images: finalUploadImages,
          }
        );

        if (res.status === 200) {
          setIsLoading(false);
          getLocationDetailsListing(location);
          setTouchedValue(false);
          if (finalDeleteImages?.length === 0) {
            toast.success(`${t("Updated_Successfully")}`);
          }
          if (indexValue !== null) {
            let selectedValue = indexValue;
            setSelectedTab(selectedValue);
          } else {
            getLocationMedia(1);
            handleCloseBox();
          }
          if (cancelState === true) {
            onCancel();
          }
          setFinalUploadImages([]);
          setFinalDeleteImages([]);
          // onCancel();
          // getLocationDetails(location);

          // setLocationDetailsData(res?.data?.data);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    }
    if (finalDeleteImages?.length > 0) {
      setPage(0);
      handleDeleteCoverImages();
    }
  };
  const handleDeleteCoverImages = async () => {
    try {
      setIsLoading(true);
      setDisplayConfirmationBox(false);
      const res = await api.patch(
        `/locationListing/delete-images/${location?.id}`,
        {
          mediaIds: finalDeleteImages,
        }
      );
      if (indexValue !== null) {
        let selectedValue = indexValue;
        setSelectedTab(selectedValue);
      } else {
        getLocationMedia(1);
        handleCloseBox();
      }

      if (cancelState === true) {
        onCancel();
      }
      getLocationDetailsListing(location);
      if (res.status === 200) {
        setIsLoading(false);
        setTouchedValue(false);
        toast.success(`${t("Updated_Successfully")}`);
        setFinalUploadImages([]);
        setFinalDeleteImages([]);

        // onCancel();
        // getLocationDetails(location);

        // setLocationDetailsData(res?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      {openAddSectionModel && (
        <AddProductSection
          title={
            openState !== null &&
            (openState === "edit"
              ? t("edit_product")
              : openState === "add"
              ? t("add_product")
              : t("Add_menu_section"))
          }
          openFrom={openState}
          open={openAddSectionModel}
          handleCloseProductSectionModel={handleCloseProductSectionModel}
          selectedItemData={selectedItemData}
          selectedSectionData={selectedSectionData}
          setTouchedValue={setTouchedValue}
          getLocationDetails={getLocationDetails}
          location={location}
          locationDetailsData={locationDetailsData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          getLocationDetailsListing={getLocationDetailsListing}
          getAllMenus={getAllMenus}
          locationProducts={locationProducts}
        />
      )}

      {openConfirmBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={openConfirmBox}
          //  onClose={handleCloseBox}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModalTwo
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateCoverImages}
            onCancel={handleCloseBox}
            location={location}
            loading={isLoading}
          />
        </Dialog>
      )}
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          //  onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            /*  onConfirm={
              value === 0
                ? handleUpdateLocalPagesData
                : value === 1
                ? handleUpdateAttributes
                : handleUpdateDescription
            } */
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            getLocationDetails={getLocationDetails}
            location={location}

            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      <Grid container direction="column" className={classes.gridContainerone}>
        <Grid container sx={{ padding: "18px" }}>
          <Grid
            item
            xs={10}
            sm={10}
            md={11.5}
            lg={11.5}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography className={classes.modalHeader}>
              {t("product")}
            </Typography>
            <BootstrapTooltip title={t("menu_tooltip_text")}>
              <Typography
                sx={{
                  marginLeft: "5px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>
            </BootstrapTooltip>
          </Grid>
          <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
            <IconButton
              autoFocus
              onClick={() => {
                handleOpen();
                setCancelState(true);
              }}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />

        <Box ref={containerRef}>
          <Box className={classes.boxContainerMenus}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("product")} {...a11yProps(0)} />
              <Tab label={t("photos_of_product")} {...a11yProps(1)} />
            </Tabs>
          </Box>

          {value === 0 && (
            <LocationProductsSection
              locationProducts={locationProducts}
              handleOpenProductSectionModel={handleOpenProductSectionModel}
              setSelectedItemData={setSelectedItemData}
              setSelectedSectionData={setSelectedSectionData}
              selectedSectionData={selectedSectionData}
              selectedItemData={selectedItemData}
              setTouchedValue={setTouchedValue}
              getLocationDetails={getLocationDetails}
              location={location}
              setIsLoading={setIsLoading}
              setLoading={setLoading}
              isLoading={isLoading}
              locationDetailsData={locationDetailsData}
              getAllMenus={getAllMenus}
              search={search}
              setCallApi={setCallApi}
              setSearch={setSearch}
              getLocationDetailsListing={getLocationDetailsListing}
              containerRef={containerRef}
              openState={openState}
              setOpenState={setOpenState}
              setLocationProducts={setLocationProducts}
              loading={loading}
            />
          )}
          {value === 1 &&
            (loading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "460px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </Box>
            ) : (
              <Images
                locationDetailsData={locationDetailsData}
                location={location}
                onCancel={onCancel}
                locationCategory={locationCategory}
                getLocationDetails={getLocationDetails}
                getLocationDetailsListing={getLocationDetailsListing}
                touchedValue={touchedValue}
                setTouchedValue={setTouchedValue}
                setDisplayConfirmationBox={setDisplayConfirmationBox}
                displayConfirmationBox={displayConfirmationBox}
                setLocationDetailsData={setLocationDetailsData}
                indexValue={indexValue}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                addressTouchedValue={addressTouchedValue}
                setAddressTouchedValue={setAddressTouchedValue}
                otherValue={otherValue}
                setOtherValue={setOtherValue}
                handleDeleteCoverImages={handleDeleteCoverImages}
                handleUpdateCoverImages={handleUpdateCoverImages}
                page={page}
                setPage={setPage}
                finalUploadImages={finalUploadImages}
                setFinalUploadImages={setFinalUploadImages}
                finalDeleteImages={finalDeleteImages}
                setFinalDeleteImages={setFinalDeleteImages}
                getLocationMedia={getLocationMedia}
                fetchMoreFoodrData={fetchMoreFoodrData}
                previewImages={previewImages}
                setPreviewImages={setPreviewImages}
                mediaCount={mediaCount}
                setMediaCount={setMediaCount}
                setFoodPhotoImages={setFoodPhotoImages}
                foodPhotoImages={foodPhotoImages}
                previewLoading={previewLoading}
                setPreviewLoading={setPreviewLoading}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ))}
        </Box>

        {value === 0 && <Divider />}

        {/*  
        <Grid container className={classes.gridContainerthree}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridButtons}
          >
            <CommonButton
              displayWhite="true"
              label={t("Close")}
              onSubmit={handleCancelButton}
            />
            <CommonButton
                onSubmit={
                value === 0
                  ? handleUpdateLocalPagesData
                  : value === 1
                  ? handleUpdateAttributes
                  : value === 2
                  ? handleUpdateDescription
                  : handleUpdateCoverImages
              } 
              label={t("Save")}
            />
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
};

export default LocationProductsModel;
