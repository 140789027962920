import React, { useState, useEffect } from "react";
import { api } from "../../../../../contexts/JWTContext";
import {
  Box,
  Grid,
  Paper as MuiPaper,
  Typography,
  Divider as MuiDivider,
  Tab,
  Tabs,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import TitleHeader from "../../../../../components/CustomComponents/title";
import { useTranslation } from "react-i18next";
import SubscriptionCard from "../../Components/SubscriptionCard";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import { useStyles } from "../../styles/style";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);
const Subscribtion = (getAllLocation) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [getLocationId, setGetLocationId] = React.useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [locationSelectedSubscription, setLocationSelectedSubscription] =
    React.useState([]);
  useEffect(async () => {
    getAllSubscriptions();
  }, []);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleSubClose = () => {
    //  setSubBoxOpen(false);
    getAllLocation();
  };

  const getAllSubscriptions = async () => {
    try {
      //   setIsLoading(true);
      const res = await api.get(`/subscriptions`);
      if (res.status === 200) {
        setAllSubscriptions(res.data.data);
        //   setIsLoading(false);
      }
    } catch (error) {
      //  setIsLoading(false);
    }
  };

  return (
    <>
      <TitleHeader title={t("Location")} showButton={true} />
      <Box sx={{ width: "100%" }}>
        <Tabs
          sx={{ width: "100%", marginTop: "2px" }}
          className={classes.Tabs}
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          centered
        >
          <Tab sx={{ width: "20%" }} label={t("Details")} />
          <Tab sx={{ width: "20%" }} label={t("Products")} />
        </Tabs>
        {selectedTab === 0 && <Typography>Details</Typography>}
        {selectedTab === 1 && (
          <>
            <Divider mb={4} variant="middle" />

            <Box className={classes.MainBoxLocation} component={Paper}>
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      backgroundColor: "#F6F6F7",
                      padding: "24px",
                      marginBottom: "8px",
                    }}
                  >
                    <Grid container>
                      <Grid item sm={10} md={10} lg={10}>
                        <Typography
                          sx={{
                            color: "#1B2430",
                            fontSize: "18px",
                            fontWeight: 700,
                          }}
                        >
                          Connect with Google
                        </Typography>
                        <Typography
                          sx={{
                            color: "#495059",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          Get access to our products after connecting this
                          location to Google
                        </Typography>
                      </Grid>
                      <Grid item sm={2} md={2} lg={2}>
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <CommonButton label="Connect" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Box sx={{ fontSize: "20px", fontWeight: "700" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h3">
                        {t("Products")} <br />
                      </Typography>
                    </Stack>

                    <Typography sx={{ fontSize: "14px" }}>
                      {t("sol_desc")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Box direction="column" spacing={2}>
                    {allSubscriptions?.results?.map((subscription, index) => {
                      const result = locationSelectedSubscription.find(
                        (data) =>
                          data?.subscription?.id === subscription.id &&
                          data?.status === "active"
                      );
                      return (
                        <SubscriptionCard
                          subscription={subscription}
                          result={result}
                          getLocationId={getLocationId}
                          handleSubClose={handleSubClose}
                        />
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Subscribtion;
