import React, { useEffect, useState } from "react";
import data from "./data.json";
import {
  Grid,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  DialogContentText,
  Box,
  IconButton,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import CompanyDetailsModal from "../Components/CompanyDetails/Modal/index";
import CompanyContactmodal from "../Components/CompanyContactDetails/Modal/index";
import CompanyOpeninghours from "../Components/LocationOpeningHours/Modal/index";
import CompanySpeacialhour from "../Components/LocationSpeacialHours/Modal/index";
import CompanyDescription from "../Components/CompanyDescription/Modal/index";
import SocialAccounts from "../Components/SocialAccounts/Modal/index";
import SocialAccountDetails from "../Components/SocialAccountDetails/Modal/index";
import PaymentOptions from "../Components/PaymentOptions/Modal/index";
import CompanyServices from "../Components/LocationServices/Modal/index";
import Additionaldescription from "../Components/AdditionalCategories/Modal/index";
import LocationMap from "../Components/LocationMap/Modal/index";
import Companyattributes from "../Components/CompanyAttributes/Modal/index";
import Categories from "../Components/Categories/Modal/index";
import MoreHours from "../Components/MoreOpeningHours/Modal/index";
import { useStyles } from "../../Styles/style";
import { api } from "../../../../../contexts/JWTContext";
import CompanyLabel from "../Components/CompanyKeywords/Modals/index";
import LocalPages from "../Components/LocalPages/Modals/index";
import CoverImages from "../Components/CoverImages/Modals";
import Images from "../Components/Images/Modals";
import LocationProductsModel from "../Components/Products/Model";

const label = { inputProps: { "aria-label": "Switch demo" } };

const EditModal = ({
  open,
  onCancel,
  modalIndex,
  locationDetailsData,
  location,
  locationCategory,
  locationFacebookCategory,
  getLocationDetails,
  getLocationDetailsListing,
  allAttributes,
  getLocationById,
  singleLocation,
  setSingleLocationLoading,
  facebookConnect,
  setLocationDetailsData,
  lodgingCategories,
  categoryLoading,
  centerData,
  displayMapError,
  openDefaultModel,
}) => {
  {
    /*************************************** States ***************************************/
  }
  const [tags, setTags] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(modalIndex);
  const [allAttribute, setAllAttributes] = useState([]);
  const [touchedValue, setTouchedValue] = useState(false);
  const [addressTouchedValue, setAddressTouchedValue] = useState(false);
  const [displayConfirmationBox, setDisplayConfirmationBox] = useState(false);
  const [indexValue, setIndexValue] = useState(null);
  const companyData = JSON.parse(localStorage.getItem("company"));

  {
    /*************************************** Use Effects ***************************************/
  }
  useEffect(() => {
    getAllAttributes(location);
    setTags(data.locations[0].categories.primaryCategory.serviceTypes);
  }, []);

  const handleChangeTab = (event, newValue) => {
    //  setSelectedTab(newValue);
    if (touchedValue === false && addressTouchedValue === false) {
      setSelectedTab(newValue);
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      setIndexValue(newValue);
    }
  };

  const getAllAttributes = async (locationData) => {
    try {
      const res = await api.get(
        `/locationListing/getAll-attributes/${locationData?.id}`
      );
      if (res.status === 200) {
        setAllAttributes(res?.data?.data?.attributes);
      }
    } catch (error) {}
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="lg" // You can adjust the width if necessary
        sx={{
          "& .MuiDialog-paper": {
            // You can uncomment this if you need a fixed width
            height: "700px",
            borderRadius: "4px",
            overflow: "hidden", // Prevent scroll on entire dialog
          },
        }}
      >
        <DialogContentText
          id="responsive-dialog-title"
          sx={{ display: "flex", height: "100%", color: "#1b2430" }} // Flex to align grids
        >
          <Grid container sx={{ height: "100%", width: "1100px" }}>
            <Grid
              item
              xs={4}
              sm={3}
              md={3}
              lg={3}
              sx={{
                height: "100%", // Ensure it takes the full height
                overflow: "auto",
                borderRight: 1,
                borderColor: "divider",
                backgroundColor: "#F6F6F7",
                textAlign: "left", // Enable scrolling for the tabs section
              }}
            >
              {/*************************************** Tabs Section ***************************************/}
              <Box sx={{ maxHeight: "100%", overflowY: "auto" }}>
                <Tabs
                  orientation="vertical"
                  value={selectedTab}
                  onChange={handleChangeTab}
                  variant="scrollable"
                  aria-label="Vertical tabs example"
                  classes={{ indicator: classes.indicator }}
                  style={{ alignItems: "left" }}
                  modalIndex={selectedTab}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#8D9298",
                      fontWeight: "700",
                      padding: "10px",
                    }}
                  >
                    {t("Basic_Information")}
                  </Typography>
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("Company_Details")}
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("Categories")}
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("Contact_Details")}
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("Hours_head")}
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("Speacial_Opening_Hours")}
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("More_Opening_Hours")}
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("Company_Description")}
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("Social_Accounts")}
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("Attributes")}
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("services")}
                  />
                  {locationDetailsData !== null &&
                    locationDetailsData?.result?.google?.metadata
                      ?.canHaveFoodMenus === true && (
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.selectedTab,
                        }}
                        label={t("product")}
                      />
                    )}
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("cover_images_uberAll")}
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                    }}
                    label={t("connect_channel")}
                  />
                  {displayMapError === false && (
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      label={t("location_map")}
                    />
                  )}
                  {companyData !== null &&
                    companyData !== undefined &&
                    companyData?.thirdPartyCoverImages === true && (
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.selectedTab,
                        }}
                        label={t("local_pages")}
                      />
                    )}
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={8} sm={9} md={9} lg={9}>
              {/*************************************** Company Details Tab ***************************************/}
              {selectedTab === 1 && (
                <>
                  <CompanyDetailsModal
                    locationDetailsData={locationDetailsData}
                    location={location}
                    onCancel={onCancel}
                    locationCategory={locationCategory}
                    getLocationDetails={getLocationDetails}
                    getLocationDetailsListing={getLocationDetailsListing}
                    touchedValue={touchedValue}
                    setTouchedValue={setTouchedValue}
                    setDisplayConfirmationBox={setDisplayConfirmationBox}
                    displayConfirmationBox={displayConfirmationBox}
                    setLocationDetailsData={setLocationDetailsData}
                    indexValue={indexValue}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    setIndexValue={setIndexValue}
                    addressTouchedValue={addressTouchedValue}
                    setAddressTouchedValue={setAddressTouchedValue}
                  />
                </>
              )}
              {/*************************************** Company Contacts Tab ***************************************/}
              {selectedTab === 2 && (
                <Categories
                  locationDetailsData={locationDetailsData}
                  location={location}
                  onCancel={onCancel}
                  locationCategory={locationCategory}
                  locationFacebookCategory={locationFacebookCategory}
                  getLocationDetails={getLocationDetails}
                  facebookConnect={facebookConnect}
                  touchedValue={touchedValue}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  getLocationDetailsListing={getLocationDetailsListing}
                  getLocationById={getLocationById}
                  setIndexValue={setIndexValue}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                  categoryLoading={categoryLoading}
                />
              )}
              {/**************************************** Company Contacts Tab ****************************************/}
              {selectedTab === 3 && (
                <CompanyContactmodal
                  locationDetailsData={locationDetailsData}
                  location={location}
                  onCancel={onCancel}
                  locationCategory={locationCategory}
                  getLocationDetails={getLocationDetails}
                  getLocationDetailsListing={getLocationDetailsListing}
                  touchedValue={touchedValue}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  setIndexValue={setIndexValue}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                />
              )}
              {/****************************************  Open Hours Tab ****************************************/}
              {selectedTab === 4 && (
                <CompanyOpeninghours
                  locationDetailsData={locationDetailsData}
                  location={location}
                  onCancel={onCancel}
                  locationCategory={locationCategory}
                  getLocationDetails={getLocationDetails}
                  getLocationDetailsListing={getLocationDetailsListing}
                  touchedValue={touchedValue}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  setIndexValue={setIndexValue}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                />
              )}
              {/**************************************** Speacial Hour Tab ****************************************/}
              {selectedTab === 5 && (
                <CompanySpeacialhour
                  sx={{ width: "90vh" }}
                  location={location}
                  locationDetailsData={locationDetailsData}
                  onCancel={onCancel}
                  locationCategory={locationCategory}
                  getLocationDetails={getLocationDetails}
                  getLocationDetailsListing={getLocationDetailsListing}
                  touchedValue={touchedValue}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  setIndexValue={setIndexValue}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                />
              )}

              {selectedTab === 6 && (
                <MoreHours
                  getLocationDetails={getLocationDetails}
                  getLocationDetailsListing={getLocationDetailsListing}
                  location={location}
                  locationDetailsData={locationDetailsData}
                  onCancel={onCancel}
                  locationCategory={locationCategory}
                  touchedValue={touchedValue}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                />
              )}
              {selectedTab === 7 && (
                <CompanyDescription
                  locationDetailsData={locationDetailsData}
                  location={location}
                  onCancel={onCancel}
                  getLocationDetails={getLocationDetails}
                  getLocationDetailsListing={getLocationDetailsListing}
                  touchedValue={touchedValue}
                  locationCategory={locationCategory}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  lodgingCategories={lodgingCategories}
                  setIndexValue={setIndexValue}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                />
              )}
              {selectedTab === 8 && (
                <SocialAccountDetails
                  locationDetailsData={locationDetailsData}
                  location={location}
                  onCancel={onCancel}
                  locationCategory={locationCategory}
                  getLocationDetails={getLocationDetails}
                  getLocationDetailsListing={getLocationDetailsListing}
                  touchedValue={touchedValue}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  setIndexValue={setIndexValue}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                />
              )}

              {selectedTab === 9 && (
                <Companyattributes
                  getLocationDetails={getLocationDetails}
                  getLocationDetailsListing={getLocationDetailsListing}
                  location={location}
                  locationDetailsData={locationDetailsData}
                  onCancel={onCancel}
                  allAttributes={
                    allAttributes !== undefined ? allAttributes : allAttribute
                  }
                  touchedValue={touchedValue}
                  locationCategory={locationCategory}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  lodgingCategories={lodgingCategories}
                  setIndexValue={setIndexValue}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                  openDefaultModel={openDefaultModel}
                />
              )}

              {selectedTab === 10 && (
                <CompanyLabel
                  getLocationDetails={getLocationDetails}
                  getLocationDetailsListing={getLocationDetailsListing}
                  location={location}
                  locationDetailsData={locationDetailsData}
                  onCancel={onCancel}
                  locationCategory={locationCategory}
                  touchedValue={touchedValue}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  setIndexValue={setIndexValue}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                />
              )}
              {selectedTab === 11 &&
                locationDetailsData !== null &&
                locationDetailsData?.result?.google?.metadata?.hasOwnProperty(
                  "canHaveFoodMenus"
                ) &&
                locationDetailsData?.result?.google?.metadata
                  ?.canHaveFoodMenus === true && (
                  <LocationProductsModel
                    locationDetailsData={locationDetailsData}
                    location={location}
                    onCancel={onCancel}
                    locationCategory={locationCategory}
                    getLocationDetails={getLocationDetails}
                    getLocationDetailsListing={getLocationDetailsListing}
                    touchedValue={touchedValue}
                    setTouchedValue={setTouchedValue}
                    setDisplayConfirmationBox={setDisplayConfirmationBox}
                    displayConfirmationBox={displayConfirmationBox}
                    setLocationDetailsData={setLocationDetailsData}
                    indexValue={indexValue}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    addressTouchedValue={addressTouchedValue}
                    setAddressTouchedValue={setAddressTouchedValue}
                  />
                )}
              {((locationDetailsData !== null &&
                locationDetailsData?.result?.google?.metadata?.hasOwnProperty(
                  "canHaveFoodMenus"
                ) &&
                locationDetailsData?.result?.google?.metadata
                  ?.canHaveFoodMenus === true &&
                selectedTab === 12) ||
                (locationDetailsData !== null &&
                  (locationDetailsData?.result?.google?.metadata?.hasOwnProperty(
                    "canHaveFoodMenus"
                  ) == false ||
                    locationDetailsData?.result?.google?.metadata
                      ?.canHaveFoodMenus === false) &&
                  selectedTab === 11)) && (
                <Images
                  locationDetailsData={locationDetailsData}
                  location={location}
                  onCancel={onCancel}
                  locationCategory={locationCategory}
                  getLocationDetails={getLocationDetails}
                  getLocationDetailsListing={getLocationDetailsListing}
                  touchedValue={touchedValue}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                />
              )}
              {((locationDetailsData !== null &&
                locationDetailsData?.result?.google?.metadata?.hasOwnProperty(
                  "canHaveFoodMenus"
                ) &&
                locationDetailsData?.result?.google?.metadata
                  ?.canHaveFoodMenus === true &&
                selectedTab === 13) ||
                (locationDetailsData !== null &&
                  (locationDetailsData?.result?.google?.metadata?.hasOwnProperty(
                    "canHaveFoodMenus"
                  ) == false ||
                    locationDetailsData?.result?.google?.metadata
                      ?.canHaveFoodMenus === false) &&
                  selectedTab === 12)) && (
                <SocialAccounts
                  onCancel={onCancel}
                  data={data}
                  getLocationDetails={getLocationDetails}
                  location={location}
                  locationCategory={locationCategory}
                  setSingleLocationLoading={setSingleLocationLoading}
                  locationDetailsData={locationDetailsData}
                  getLocationById={getLocationById}
                  singleLocation={singleLocation}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                />
              )}

              {((locationDetailsData !== null &&
                locationDetailsData?.result?.google?.metadata?.hasOwnProperty(
                  "canHaveFoodMenus"
                ) &&
                locationDetailsData?.result?.google?.metadata
                  ?.canHaveFoodMenus === true &&
                selectedTab === 14 &&
                displayMapError === false) ||
                (locationDetailsData !== null &&
                  (locationDetailsData?.result?.google?.metadata?.hasOwnProperty(
                    "canHaveFoodMenus"
                  ) == false ||
                    locationDetailsData?.result?.google?.metadata
                      ?.canHaveFoodMenus === false) &&
                  selectedTab === 13 &&
                  displayMapError === false)) && (
                <LocationMap
                  locationDetailsData={locationDetailsData}
                  getLocationDetails={getLocationDetails}
                  location={location}
                  onCancel={onCancel}
                  centerData={centerData}
                  displayMapError={displayMapError}
                  addressTouchedValue={addressTouchedValue}
                  setAddressTouchedValue={setAddressTouchedValue}
                  touchedValue={touchedValue}
                  setTouchedValue={setTouchedValue}
                  setDisplayConfirmationBox={setDisplayConfirmationBox}
                  displayConfirmationBox={displayConfirmationBox}
                  setLocationDetailsData={setLocationDetailsData}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  setIndexValue={setIndexValue}
                />
              )}

              {/**************************************** Local Pages Tab ****************************************/}

              {companyData !== null &&
                companyData !== undefined &&
                companyData?.thirdPartyCoverImages === true &&
                ((locationDetailsData !== null &&
                  locationDetailsData?.result?.google?.metadata?.hasOwnProperty(
                    "canHaveFoodMenus"
                  ) &&
                  locationDetailsData?.result?.google?.metadata
                    ?.canHaveFoodMenus === true &&
                  ((displayMapError === false && selectedTab === 15) ||
                    (displayMapError === true && selectedTab === 14))) ||
                  (locationDetailsData !== null &&
                    (locationDetailsData?.result?.google?.metadata?.hasOwnProperty(
                      "canHaveFoodMenus"
                    ) == false ||
                      locationDetailsData?.result?.google?.metadata
                        ?.canHaveFoodMenus === false) &&
                    ((displayMapError === false && selectedTab === 14) ||
                      (displayMapError === true && selectedTab === 13)))) && (
                  <LocalPages
                    locationDetailsData={locationDetailsData}
                    location={location}
                    onCancel={onCancel}
                    locationCategory={locationCategory}
                    getLocationDetails={getLocationDetails}
                    getLocationDetailsListing={getLocationDetailsListing}
                    touchedValue={touchedValue}
                    setTouchedValue={setTouchedValue}
                    setDisplayConfirmationBox={setDisplayConfirmationBox}
                    displayConfirmationBox={displayConfirmationBox}
                    setLocationDetailsData={setLocationDetailsData}
                    indexValue={indexValue}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    setIndexValue={setIndexValue}
                    addressTouchedValue={addressTouchedValue}
                    setAddressTouchedValue={setAddressTouchedValue}
                  />
                )}
            </Grid>
          </Grid>
        </DialogContentText>
      </Dialog>

      {/*************************************** Dialog Content Box ***************************************/}
    </>
  );
};

export default EditModal;
