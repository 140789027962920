import React from "react";
import { Button as MuiButton } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";

const ThemeButton = ({
  name,
  variant,
  color,
  size,
  onClick,
  startIcon,
  isLoading,
  width,
}) => {
  const Button = styled(MuiButton)(spacing);
  return (
    <>
      <Button
        sx={{
          minWidth: "50px",
          width: width,
          height: "52px",
          fontSize: "initial",
          borderRadius: "6px",
        }}
        // mr={2}
        variant={variant}
        color="primary"
        size={size}
        onClick={onClick}
        startIcon={startIcon ? startIcon : ""}
        // loading={isLoading}
      >
        {name}
      </Button>
    </>
  );
};

export default ThemeButton;
