import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../../contexts/JWTContext";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Select } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Alert as MuiAlert,
  TextField,
  Box,
  Tooltip,
  tooltipClasses,
  IconButton,
} from "@mui/material";
import { languagesData } from "../../../../../assets/defaultLanguage";
import { makeStyles } from "@mui/styles";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../../components/Loaders/Loader";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
  tagItem: {
    margin: "4px",
    marginBottom: "0px",
    backgroundColor: "#ebebec",
    borderRadius: "800px",
    display: "inline-block",
    padding: "4px 8px",
    fontSize: "12px",
    color: "#1B2430",
    fontWeight: "400",
  },
  keywordBox: {
    alignItems: "center",
    display: "flex",
    paddingX: "8px",
    paddingY: "6px",
  },
  keywordTypo: {
    fontSize: "12px",
    paddingX: "4px",
    fontWeight: "400",
    marginX: "4px",
    textTransform: "capitalize",
  },
  keywordIcon: {
    marginLeft: "6px",
    fontSize: "16px",
    color: "#1B2430",
    cursor: "pointer",
    "&:hover ": {
      color: "#06BDFF",
    },
  },
  tagsInput: {
    flexGrow: 1,
    padding: "4.5px 0",
    border: "none",
    outline: "none",
    overflow: "hidden",
  },
  tagsInputContainer: {
    border: "1px solid #c7c7c7",
    borderRadius: "4px",
    padding: "0.5em",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "4px",
    "&:hover": {
      border: "1px solid #000000",
    },
  },
  limitFieldTextDescription: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1B2430",
    lineHeight: "20px",
    marginTop: "2px",
    paddingLeft: "3px",
  },
}));
//cons
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const Alert = styled(MuiAlert)(spacing);
const LocationForm = ({
  setIsLoading,
  isLoading,
  singleLocation,
  allSubscriptions,
  getLocationById,
  getSingleLocation,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectLanguage, setSelectLanguage] = useState("");
  const [SelectDefaultLocation, setDefaultLocation] = useState(false);
  const [keywords, setKeywords] = useState(
    singleLocation?.brandNames ? singleLocation?.brandNames.split(",") : []
  );
  const [duplicateAccountOwners, setDuplicateKeywords] = useState(false);

  useEffect(() => {
    if (singleLocation?.defaultLanguage) {
      const userDefaultLanguage = languagesData?.find(
        (item) => item?.value === singleLocation?.defaultLanguage
      );
      setSelectLanguage(userDefaultLanguage?.value);
    }
  }, [singleLocation]);
  {
  }

  const handleAddKeywords = (e) => {
    if (e.target.value.includes(",") && e.target.value.includes("\n")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(/[\n,]+/);
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(keywords);
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(",")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(",");
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(keywords);
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(".")) {
      const lastDotRemoved = e?.target?.value.replace(/\.+$/, "");
      const arr = lastDotRemoved.split(".");
      arr.concat(keywords);
      const mergeResult = [...keywords, ...arr];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    }
    const lowerCaseBrandTags = keywords.map((tag) => tag.toLowerCase());
    if (lowerCaseBrandTags.includes(e.target.value.toLowerCase())) {
      setDuplicateKeywords(true);
    } else {
      setDuplicateKeywords(false);
    }
  };

  const removeBrandsTag = (index) => {
    setKeywords(keywords.filter((el, i) => i !== index));
  };

  const handleBrandsKeyDown = (e) => {
    if (e.key !== "Enter" || e.target.value.trim() === "") return;

    let value = e.target.value.toLowerCase().trim();
    if (value.includes("\n") && value !== "\n") {
      const lastLineBreakRemoved = value.replace(/\n+$/, "");
      const arr = lastLineBreakRemoved.split("\n");
      const filtered = arr.filter((item) => item?.trim() !== "");
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);

      e.target.value = "";
    } else {
      if (!value.trim()) return;
      const mergeResult = [...keywords, value];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
    }
    const lowerCaseBrandTags = keywords.map((tag) => tag.toLowerCase());
    const lastLineBreakRemoved = value.replace(/\n+$/, "");
    const arr = lastLineBreakRemoved.split("\n");
    const filtered = arr.filter((item) => item?.trim() !== "");
    if (lowerCaseBrandTags.includes(value.toLowerCase())) {
      setDuplicateKeywords(true);
      setTimeout(() => {
        setDuplicateKeywords(false);
      }, 2000);
    } else {
      for (let i = 0; i < keywords.length; i++) {
        if (filtered.includes(keywords[i])) {
          setDuplicateKeywords(true);
          setTimeout(() => {
            setDuplicateKeywords(false);
          }, 2000);
          break;
        }
      }
    }

    e.target.value = "";
  };
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ width: "100%" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid>
            <Grid sx={{ padding: "10px", bgcolor: "white" }}>
              {singleLocation !== null && (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    accountName: singleLocation?.name
                      ? singleLocation?.name
                      : "",
                    addressOne: singleLocation?.addressLine1
                      ? singleLocation?.addressLine1
                      : "",
                    addressTwo: singleLocation?.addressLine2
                      ? singleLocation?.addressLine2
                      : "",
                    postalCode: singleLocation?.postalCode
                      ? singleLocation?.postalCode
                      : "",
                    city: singleLocation?.city ? singleLocation?.city : "",
                    countryName: singleLocation?.country
                      ? singleLocation?.country
                      : "",
                    telephone: singleLocation?.telephone
                      ? singleLocation?.telephone
                      : "",
                    website: singleLocation?.website
                      ? singleLocation?.website
                      : "",
                    defaultLanguage: singleLocation?.defaultLanguage,
                    businessEmail: singleLocation?.businessEmail
                      ? singleLocation?.businessEmail
                      : "",
                    internalName: singleLocation?.internalName
                      ? singleLocation?.internalName
                      : "",
                    /*   soConnectEmail: singleLocation?.soConnectEmail
                      ? singleLocation?.soConnectEmail
                      : "", */
                    submit: false,
                  }}
                  validationSchema={Yup.object().shape({
                    accountName: Yup.string()
                      .max(255)
                      .required(`${t("account_req")}`),
                    postalCode: Yup.string().required(`${t("postal_req")}`),
                    // accountExternalReference: Yup.string().max(255),
                    businessEmail: Yup.string()
                      .email(`${t("valid_emaill")}`)
                      .max(255)
                      .required(`${t("email_req")}`),
                    internalName: Yup.string().required(
                      `${t("internal_name_req")}`
                    ),
                    /*   soConnectEmail: Yup.string()
                      .email(`${t("valid_emaill")}`)
                      .max(255), */
                    // .required(`${t("So_connect_email")}`),
                    //  telephone: Yup.string().required(`${t("telephone_req")}`),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      setIsLoading(true);
                      const res = await api.patch(
                        `/location/${getLocationById}`,
                        {
                          name: values.accountName,
                          addressLine1: values.addressOne,
                          addressLine2: values.addressTwo,
                          postalCode: values.postalCode,
                          city: values.city,
                          country: values.countryName,
                          telephone: values.telephone,
                          website: values.website,
                          partnerId: singleLocation?.partnerId,
                          defaultLanguage: selectLanguage,
                          businessEmail: values.businessEmail
                            ? values.businessEmail
                            : "",
                          internalName: values.internalName,
                          /*   soConnectEmail: values.soConnectEmail
                            ? values.soConnectEmail
                            : "", */
                          isDefault: SelectDefaultLocation,
                          brandNames: keywords.join(","),
                        }
                      );
                      if (res.status === 200) {
                        setIsLoading(false);
                        // setMessage(res.data.message);
                        getSingleLocation(getLocationById);
                        toast.success("Updated Successfully", {
                          onClose: () => {
                            navigate(-1);
                          },
                        });
                        // setTimeout(() => {
                        //   setMessage(navigate("/user/locations"));
                        // }, );
                      }
                    } catch (error) {
                      const message =
                        error?.response?.data?.message ||
                        "Something went wrong";
                      setStatus({ success: false });
                      setErrors({ submit: message });
                      setSubmitting(false);
                      toast.error(error?.response?.data?.message);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form
                      noValidate
                      onSubmit={handleSubmit}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    >
                      {errors.submit && (
                        <Alert mt={2} mb={1} severity="error">
                          {errors.submit}
                        </Alert>
                      )}
                      <Grid>
                        <Grid
                          container
                          sx={{
                            marginTop: "20px",
                            marginLeft: "2px",
                            marginRight: "2px",
                            marginBottom: "20px",
                          }}
                        >
                          <Grid item xs={12} sm={3}>
                            <Typography variant="h3">{t("Name")}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            {" "}
                            <Typography
                              sx={{
                                marginTop: "10px",
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "rgba(0,0,0,0.87)",
                              }}
                            >
                              {t("Location_Name")}
                            </Typography>
                            <TextField
                              type="text"
                              name="accountName"
                              value={values?.accountName}
                              error={Boolean(
                                touched?.accountName && errors?.accountName
                              )}
                              fullWidth
                              helperText={
                                touched?.accountName && errors?.accountName
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          sx={{
                            marginTop: "20px",
                            marginLeft: "2px",
                            marginRight: "2px",
                            marginBottom: "20px",
                          }}
                        >
                          <Grid item xs={12} sm={3}>
                            <Typography variant="h3">
                              {" "}
                              {t("Internal_name")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            {" "}
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  marginTop: "10px",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  color: "rgba(0,0,0,0.87)",
                                }}
                              >
                                {t("Internal_name_label")}
                              </Typography>
                              <BootstrapTooltip
                                title={
                                  <h1 style={{ fontSize: "12px" }}>
                                    {t("internalName_tooltip_text")}
                                  </h1>
                                }
                              >
                                <IconButton>
                                  <ErrorOutlineIcon
                                    sx={{
                                      color: "#1B2430",
                                    }}
                                  />
                                </IconButton>
                              </BootstrapTooltip>
                            </Box>
                            <TextField
                              type="text"
                              name="internalName"
                              value={values?.internalName}
                              error={Boolean(
                                touched?.internalName && errors?.internalName
                              )}
                              fullWidth
                              helperText={
                                touched?.internalName && errors?.internalName
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid
                            container
                            sx={{
                              marginTop: "20px",
                              marginLeft: "2px",
                              marginRight: "2px",
                              marginBottom: "20px",
                            }}
                          >
                            <Grid item xs={12} sm={3}>
                              <Typography variant="h3">
                                {t("brand_name")}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                }}
                              >
                                <Box sx={{ display: "flex" }}>
                                  <Typography
                                    sx={{
                                      marginTop: "10px",
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      color: "rgba(0,0,0,0.87)",
                                    }}
                                  >
                                    {t("brand_name")}
                                  </Typography>
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {t("brand_name_tooltip")}
                                      </h1>
                                    }
                                  >
                                    <IconButton>
                                      <ErrorOutlineIcon
                                        sx={{
                                          color: "#1B2430",
                                        }}
                                      />
                                    </IconButton>
                                  </BootstrapTooltip>
                                </Box>
                                <Typography
                                  className={classes.limitFieldTextDescription}
                                >
                                  {keywords.length}/10
                                </Typography>
                              </Box>
                              <Grid className={classes.tagsInputContainer}>
                                <TextField
                                  variant="standard"
                                  type="text"
                                  className={classes.tagsInput}
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                  placeholder={t("brand_name_input")}
                                  autoComplete="none"
                                  fullWidth
                                  onKeyDown={handleBrandsKeyDown}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "," ||
                                      e.target.value === "." ||
                                      e.target.value.trim() === ""
                                    ) {
                                      e.target.value = "";
                                    } else if (
                                      (e.target.value !== "" &&
                                        e.target.value !== "," &&
                                        e.target.value.includes(".")) ||
                                      (e.target.value !== "." &&
                                        e.target.value !== "" &&
                                        e.target.value.includes(","))
                                    ) {
                                      handleAddKeywords(e);
                                    }
                                  }}
                                  disabled={keywords.length === 10}
                                />
                                {keywords?.length > 0 &&
                                  keywords.map((keywords, index) => (
                                    <Grid
                                      className={classes.tagItem}
                                      key={index}
                                    >
                                      <Box className={classes.keywordBox}>
                                        <Typography
                                          className={classes.keywordTypo}
                                        >
                                          {keywords}
                                        </Typography>{" "}
                                        <CloseIcon
                                          className={classes.keywordIcon}
                                          onClick={() => removeBrandsTag(index)}
                                        />
                                      </Box>
                                    </Grid>
                                  ))}
                              </Grid>
                              <Typography
                                sx={{
                                  padding: "4px",
                                  color: "gray",
                                  fontSize: "10px",
                                }}
                              >
                                {t("enter_keyword_limit_10")}
                              </Typography>
                              {duplicateAccountOwners && (
                                <Typography
                                  sx={{ color: "red", fontSize: "12px" }}
                                >
                                  {t("brand_duplicated_error")}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Divider variant="middle" />
                      <Grid
                        container
                        sx={{
                          marginTop: "20px",
                          marginLeft: "2px",
                          marginRight: "2px",
                          marginBottom: "20px",
                        }}
                      >
                        <Grid item xs={12} sm={3}>
                          <Typography variant="h3">{t("Address")}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("AddressLineOne")}
                          </Typography>
                          <TextField
                            type="text"
                            name="addressOne"
                            value={values.addressOne}
                            error={Boolean(
                              touched.addressOne && errors.addressOne
                            )}
                            fullWidth
                            helperText={touched.addressOne && errors.addressOne}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />

                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("AddressLineTwo")}
                          </Typography>
                          <TextField
                            type="text"
                            name="addressTwo"
                            value={values.addressTwo}
                            error={Boolean(
                              touched.addressTwo && errors.addressTwo
                            )}
                            fullWidth
                            helperText={touched.addressTwo && errors.addressTwo}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <Grid container>
                            <Grid item xs={5} sx={{ backgroundColor: "" }}>
                              <Typography
                                sx={{
                                  marginTop: "10px",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  color: "rgba(0,0,0,0.87)",
                                }}
                              >
                                {t("PostalCode")}
                              </Typography>
                              <TextField
                                name="postalCode"
                                value={values.postalCode}
                                error={Boolean(
                                  touched.postalCode && errors.postalCode
                                )}
                                inputProps={{
                                  maxLength: 12,
                                }}
                                fullWidth
                                helperText={
                                  touched.postalCode && errors.postalCode
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid xs={1}></Grid>
                            <Grid item xs={6} sx={{ backgroundColor: "" }}>
                              <Typography
                                sx={{
                                  marginTop: "10px",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  color: "rgba(0,0,0,0.87)",
                                }}
                              >
                                {t("City")}
                              </Typography>
                              <TextField
                                type="text"
                                name="city"
                                value={values.city}
                                error={Boolean(touched.city && errors.city)}
                                fullWidth
                                helperText={touched.city && errors.city}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Country")}
                          </Typography>
                          <TextField
                            type="text"
                            name="countryName"
                            value={values.countryName}
                            error={Boolean(
                              touched.countryName && errors.countryName
                            )}
                            fullWidth
                            helperText={
                              touched.countryName && errors.countryName
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Divider variant="middle" />
                      <Grid
                        container
                        sx={{
                          marginTop: "20px",
                          marginLeft: "2px",
                          marginRight: "2px",
                          marginBottom: "20px",
                        }}
                      >
                        <Grid item xs={12} sm={3}>
                          <Typography variant="h3">
                            {t("ContactDetails")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Telephone")}
                          </Typography>
                          <TextField
                            type="text"
                            name="telephone"
                            value={values.telephone}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />

                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Website")}
                          </Typography>
                          <TextField
                            type="text"
                            name="website"
                            value={values.website}
                            error={Boolean(touched.website && errors.website)}
                            fullWidth
                            helperText={touched.website && errors.website}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Email_Address_Buisness")}
                          </Typography>
                          <TextField
                            disabled={isLoading}
                            type="email"
                            name="businessEmail"
                            placeholder="e.g.  john@obenan.com"
                            value={values.businessEmail}
                            error={Boolean(
                              touched.businessEmail && errors.businessEmail
                            )}
                            fullWidth
                            helperText={
                              touched.businessEmail && errors.businessEmail
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      {/*   {singleLocation?.soconnect_connectivity_status ==
                        true && (
                        <>
                          <Divider variant="middle" />
                          <Grid
                            container
                            sx={{
                              marginTop: "20px",
                              marginLeft: "2px",
                              marginRight: "2px",
                              marginBottom: "20px",
                            }}
                          >
                            <Grid item xs={12} sm={3}>
                              <Typography variant="h3">
                                {t("So_Connect_heading")}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <Typography
                                sx={{
                                  marginTop: "10px",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  color: "rgba(0,0,0,0.87)",
                                }}
                              >
                                {t("So_Connect_email")}
                              </Typography>
                              <TextField
                                disabled={isLoading}
                                type="email"
                                name="soConnectEmail"
                                placeholder="e.g.  john@obenan.com"
                                value={values.soConnectEmail}
                                error={Boolean(
                                  touched.soConnectEmail &&
                                    errors.soConnectEmail
                                )}
                                fullWidth
                                helperText={
                                  touched.soConnectEmail &&
                                  errors.soConnectEmail
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )} */}
                      <Divider variant="middle" />
                      <Grid
                        container
                        sx={{
                          marginTop: "20px",
                          marginLeft: "2px",
                          marginRight: "2px",
                          marginBottom: "20px",
                        }}
                      >
                        <Grid item xs={12} sm={3}>
                          <Typography variant="h3">
                            {t("Auto_Replyy")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Default_Review")}
                          </Typography>
                          <Select
                            required
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectLanguage}
                            // label="Select"
                            onChange={(e, value) => {
                              setSelectLanguage(e.target.value);
                            }}
                          >
                            {languagesData?.map((item) => (
                              <MenuItem value={item?.value}>
                                {item?.language}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                      <Divider variant="middle" />
                      <Grid
                        container
                        sx={{
                          marginTop: "20px",
                          marginLeft: "2px",
                          marginRight: "2px",
                          marginBottom: "20px",
                        }}
                      >
                        <Grid item xs={12} sm={3}>
                          <Typography variant="h3">
                            {t("Default_Location_lable")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("Default_Location")}
                          </Typography>
                          <Select
                            required
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disabled={
                              singleLocation?.defaultLocation === null
                                ? false
                                : true
                            }
                            defaultValue={
                              singleLocation?.defaultLocation === null
                                ? false
                                : true
                            }
                            // label="Select"
                            onChange={(e, value) => {
                              setDefaultLocation(e.target.value);
                            }}
                          >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false} selected>
                              False
                            </MenuItem>
                          </Select>
                        </Grid>
                      </Grid>

                      <Divider variant="middle" />
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        my={3}
                      >
                        <Grid item>
                          <CommonButton
                            displayWhite="true"
                            onSubmit={() => navigate("/user/locations")}
                            label={t("Cancel")}
                          />
                        </Grid>
                        <Grid item>
                          <CommonButton
                            type="submit"
                            disabled={isSubmitting}
                            onSubmit={() => {}}
                            label={t("UpdateLocation")}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withTheme(LocationForm);
