import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Tooltip,
  tooltipClasses,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import NoDataReviewVelocity from "./NoDataFound/NoDataReviewVelocity";
import { useStyles } from "../../Style/style";
import GridSkeletonLoader from "./GridSkeletonLoader";
import useDateFormat from "../../../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const ReviewVelocity = ({
  handleDisplayName,
  ratingVarianceLoading,
  reviewVelocityData,
  handleDisplayReviewVelocity,
  kFormatter,
  locations,
  filteredLocations,
  handleOpenReviewModel,
  setVarianceType,
  setSelectedLocationId,
  getAllReviewsData,
  handleDisplayGroupName,
  setSelectedGroupId,
  setDownloadFormat,
  viewPermission,
  downloadFormat,
  pdfChecked,
  pdfDownloadChecked,
  openDownloadPdfModal,
  openReportEmailModal,
  tooltipContent,
  startDate,
  endDate,
  prevTooltipContent,
  setFeaturesObj,
  featuresObj,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dataCount, setDataCount] = useState(5);
  const [downloadValue, setDownloadValue] = useState(null);
  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);

  useEffect(() => {
    if (reviewVelocityData?.length > 0) {
      setDataCount(reviewVelocityData?.length);
    } else {
      setDataCount(5);
    }
  }, [
    pdfChecked,
    pdfDownloadChecked,
    openDownloadPdfModal,
    openReportEmailModal,
  ]);
  const loadMoreData = () => {
    if (reviewVelocityData?.length > 0) {
      setDataCount(reviewVelocityData?.length);
    }
  };
  const loadLessData = () => {
    setDataCount(5);
  };

  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 2, value: "email", title: t("email_report") },
  ];
  const handleReviewVelocityDownloadChange = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "reviewVelocity") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };

  return (
    <>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container mb={3}>
            <Grid item xs={9.7} sm={9.7} md={9.7} lg={9.7}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes.subHeadRatingReviewDetail}>
                  {t("review_velocity")}
                </Typography>
                <BootstrapTooltip title={t("Review_Velocity_Tooltip")}>
                  <Typography
                    sx={{
                      marginLeft: "5px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <InfoOutlinedIcon />
                  </Typography>
                </BootstrapTooltip>
              </Box>
            </Grid>
            {viewPermission && (
              <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3}>
                <Autocomplete
                  id="tags-outlined"
                  options={downloadOptions}
                  value={downloadValue}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  onChange={handleReviewVelocityDownloadChange}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t("actionss")} />
                  )}
                />
              </Grid>
            )}
          </Grid>
          {ratingVarianceLoading ? (
            <GridSkeletonLoader />
          ) : (
            <>
              {reviewVelocityData.length > 0 && (
                <Grid container className={classes.tableContainer}>
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    className={classes.mainGridCont}
                  >
                    <Typography className={classes.tableHeading}>
                      {t("Name")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2.25}
                    sm={2.25}
                    md={2.25}
                    lg={2.25}
                    className={classes.mainGridCont}
                  >
                    <Typography
                      className={classes.tableHeading}
                      sx={{ marginLeft: "9px" }}
                    >
                      {t("count")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2.25}
                    sm={2.25}
                    md={2.25}
                    lg={2.25}
                    className={classes.mainGridCont}
                  >
                    <Typography
                      className={classes.tableHeading}
                      sx={{ marginLeft: "9px" }}
                    >
                      {t("previous_count")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2.25}
                    sm={2.25}
                    md={2.25}
                    lg={2.25}
                    className={classes.mainGridCont}
                  >
                    <Typography
                      className={classes.tableHeading}
                      sx={{ marginLeft: "9px" }}
                    >
                      {t("%velocity")}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2.25}
                    sm={2.25}
                    md={2.25}
                    lg={2.25}
                    className={classes.mainGridCont}
                  >
                    <Typography
                      className={classes.tableHeading}
                      sx={{ marginLeft: "9px" }}
                    >
                      {t("total_count")}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {reviewVelocityData?.length > 0 ? (
                <Grid container className={classes.tableContainer}>
                  {reviewVelocityData?.slice(0, dataCount).map((item) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          sx={{
                            padding: "16px",
                            paddingBottom: "10px",
                          }}
                        >
                          <Typography className={classes.ratingHeadingsLoc}>
                            {item.hasOwnProperty("locationId") &&
                              handleDisplayName(
                                item?.locationId,
                                item?.lastMonthReview,
                                item?.thisMonthReview
                              )}

                            {item.hasOwnProperty("groupId") &&
                              handleDisplayGroupName(
                                item?.groupId,
                                item?.lastMonthReview,
                                item?.thisMonthReview
                              )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2.25}
                          sm={2.25}
                          md={2.25}
                          lg={2.25}
                          className={classes.mainGridContCursor}
                          onClick={() => {
                            if (
                              item?.thisMonthReview !== undefined &&
                              item?.thisMonthReview !== null &&
                              item?.thisMonthReview > 0
                            ) {
                              setSelectedLocationId(item?.locationId);
                              setSelectedGroupId(item?.groupId);

                              handleOpenReviewModel(
                                "new",
                                undefined,
                                item.hasOwnProperty("locationId")
                                  ? item?.locationId
                                  : item?.groupId,
                                item.hasOwnProperty("locationId")
                                  ? "locationId"
                                  : "groupId"
                              );
                            }
                          }}
                        >
                          <BootstrapTooltip
                            title={
                              <h1 style={{ fontSize: "12px" }}>
                                {`${t("From")} ${formatDate(startDate)} ${t(
                                  "To"
                                )} ${formatDate(endDate)}`}
                              </h1>
                            }
                          >
                            <span
                              className={classes.ratingHeadings}
                              style={{
                                color:
                                  item?.thisMonthReview >= 4.2
                                    ? "#13CF8F"
                                    : item?.thisMonthReview >= 4.0 &&
                                      item?.thisMonthReview < 4.2
                                    ? "#F8A92B"
                                    : "#FF1616",
                                marginLeft: "9px",
                              }}
                            >
                              {item?.thisMonthReview ? (
                                kFormatter(item?.thisMonthReview.toFixed(2))
                              ) : (
                                <span
                                  style={{
                                    color: "#FF1616",
                                  }}
                                >
                                  {"0"}
                                </span>
                              )}
                            </span>
                          </BootstrapTooltip>
                        </Grid>
                        <Grid
                          item
                          xs={2.25}
                          sm={2.25}
                          md={2.25}
                          lg={2.25}
                          className={classes.mainGridContCursor}
                          onClick={() => {
                            if (
                              item?.lastMonthReview !== undefined &&
                              item?.lastMonthReview !== null &&
                              item?.lastMonthReview > 0
                            ) {
                              setVarianceType("previous");

                              setSelectedLocationId(item?.locationId);
                              setSelectedGroupId(item?.groupId);

                              handleOpenReviewModel(
                                "new",
                                undefined,
                                item.hasOwnProperty("locationId")
                                  ? item?.locationId
                                  : item?.groupId,
                                item.hasOwnProperty("locationId")
                                  ? "locationId"
                                  : "groupId"
                              );
                            }
                          }}
                        >
                          <BootstrapTooltip
                            title={
                              <h1 style={{ fontSize: "12px" }}>
                                {`${prevTooltipContent}`}
                              </h1>
                            }
                          >
                            <span
                              className={classes.ratingHeadings}
                              style={{
                                color:
                                  item?.lastMonthReview >= 4.2
                                    ? "#13CF8F"
                                    : item?.lastMonthReview >= 4.0 &&
                                      item?.lastMonthReview < 4.2
                                    ? "#F8A92B"
                                    : "#FF1616",
                                marginLeft: "9px",
                              }}
                            >
                              {item?.lastMonthReview ? (
                                item?.lastMonthReview
                              ) : (
                                <span
                                  style={{
                                    color: "#FF1616",
                                  }}
                                >
                                  {"0"}
                                </span>
                              )}
                            </span>
                          </BootstrapTooltip>
                        </Grid>
                        <Grid
                          item
                          xs={2.25}
                          sm={2.25}
                          md={2.25}
                          lg={2.25}
                          className={classes.mainGridCont}
                        >
                          <BootstrapTooltip
                            title={
                              <h1 style={{ fontSize: "12px" }}>
                                {`${tooltipContent}`}
                              </h1>
                            }
                          >
                            <span
                              className={classes.ratingHeadings}
                              style={{
                                color:
                                  handleDisplayReviewVelocity(
                                    item?.lastMonthReview,
                                    item?.thisMonthReview
                                  ) > 0
                                    ? "#13CF8F"
                                    : handleDisplayReviewVelocity(
                                        item?.lastMonthReview,
                                        item?.thisMonthReview
                                      ) < 0
                                    ? "#FF1616"
                                    : "#F8A92B",
                                marginLeft: "9px",
                              }}
                            >
                              {handleDisplayReviewVelocity(
                                item?.lastMonthReview,
                                item?.thisMonthReview
                              ) !== "--"
                                ? kFormatter(
                                    handleDisplayReviewVelocity(
                                      item?.lastMonthReview,
                                      item?.thisMonthReview
                                    )
                                  ) + "%"
                                : "0.00%"}
                            </span>
                          </BootstrapTooltip>
                        </Grid>
                        <Grid
                          item
                          xs={2.25}
                          sm={2.25}
                          md={2.25}
                          lg={2.25}
                          className={classes.mainGridContCursor}
                          onClick={() => {
                            if (
                              item?.overAllReview !== undefined &&
                              item?.overAllReview !== null &&
                              item?.overAllReview > 0
                            ) {
                              // getAllReviewsData(item?.locationId);
                              setSelectedLocationId(item?.locationId);
                              setSelectedGroupId(item?.groupId);
                              if (item.hasOwnProperty("groupId")) {
                                setVarianceType("group");
                              }
                              handleOpenReviewModel(
                                "old",
                                undefined,
                                item.hasOwnProperty("locationId")
                                  ? item?.locationId
                                  : item?.groupId,
                                item.hasOwnProperty("locationId")
                                  ? "locationId"
                                  : "groupId"
                              );
                            }
                          }}
                        >
                          <span
                            className={classes.ratingHeadings}
                            style={{
                              color:
                                item?.overAllReview >= 4.2
                                  ? "#13CF8F"
                                  : item?.overAllReview >= 4.0 &&
                                    item?.overAllReview < 4.2
                                  ? "#F8A92B"
                                  : "#FF1616",
                              marginLeft: "9px",
                            }}
                          >
                            {item?.overAllReview ? (
                              item?.overAllReview
                            ) : (
                              <span
                                style={{
                                  color: "#FF1616",
                                }}
                              >
                                {"0"}
                              </span>
                            )}
                          </span>
                        </Grid>
                      </>
                    );
                  })}

                  <Grid container>
                    {filteredLocations.map((item) => {
                      return (
                        <>
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            sx={{
                              padding: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            <Typography className={classes.ratingHeadingsLoc}>
                              {handleDisplayName(item?.id)}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={2.25}
                            sm={2.25}
                            md={2.25}
                            lg={2.25}
                            className={classes.mainGridCont}
                          >
                            <Typography
                              className={classes.ratingHeadings}
                              sx={{
                                color: "#FF1616",
                                marginLeft: "9px",
                              }}
                            >
                              {"0.00"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2.25}
                            sm={2.25}
                            md={2.25}
                            lg={2.25}
                            className={classes.mainGridCont}
                          >
                            <Typography
                              className={classes.ratingHeadings}
                              sx={{
                                color: "#FF1616",
                                marginLeft: "9px",
                              }}
                            >
                              {"0.00"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2.25}
                            sm={2.25}
                            md={2.25}
                            lg={2.25}
                            className={classes.mainGridCont}
                          >
                            <Typography
                              className={classes.ratingHeadings}
                              sx={{
                                color: "#FF1616",
                                marginLeft: "9px",
                              }}
                            >
                              {"0.00"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2.25}
                            sm={2.25}
                            md={2.25}
                            lg={2.25}
                            className={classes.mainGridCont}
                          >
                            <Typography
                              className={classes.ratingHeadings}
                              sx={{
                                color: "#FF1616",
                                marginLeft: "9px",
                              }}
                            >
                              {"0.00"}
                            </Typography>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>

                  {reviewVelocityData.length > 5 && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.viewMoreButtonGrid}
                    >
                      {dataCount === 5 ? (
                        <Button onClick={loadMoreData}>
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: 700 }}
                          >
                            {t("View_More")}
                          </Typography>
                        </Button>
                      ) : (
                        <Button onClick={loadLessData}>
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: 700 }}
                          >
                            {t("View_Less")}
                          </Typography>
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container>
                  <NoDataReviewVelocity
                    handleDisplayName={handleDisplayName}
                    locations={locations}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewVelocity;
