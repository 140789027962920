import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  Box,
  TextField,
} from "@mui/material";
import "./postAiModel.css";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  mainHeadingContainer: {
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    marginBottom: "5px",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  limitFieldTextDescription: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1B2430",
    lineHeight: "20px",
    marginTop: "2px",
    paddingLeft: "3px",
  },
  tagsInputContainer: {
    border: "1px solid #c7c7c7",
    borderRadius: "4px",
    padding: "0.5em",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "4px",
    "&:hover": {
      border: "1px solid #000000",
    },
  },
  tagItem: {
    backgroundColor: "#ebebec",
    borderRadius: "800px",
    display: "inline-block",
    padding: "4px 8px",
    fontSize: "12px",
    color: "#1B2430",
    fontWeight: "400",
  },
  tagsInput: {
    flexGrow: 1,
    padding: "0.5em 0",
    border: "none",
    outline: "none",
    marginTop: "8px",
    overflow: "hidden",
  },
  tagsInputBulk: {
    flexGrow: 1,
    border: "none",
    outline: "none",
    overflow: "hidden",
  },
  keywordBox: {
    alignItems: "center",
    display: "flex",
    paddingX: "8px",
    paddingY: "6px",
  },
  keywordTypo: {
    fontSize: "12px",
    paddingX: "4px",
    fontWeight: "400",
    marginX: "4px",
  },
  keywordIcon: {
    marginLeft: "6px",
    fontSize: "16px",
    color: "#1B2430",
    cursor: "pointer",
    "&:hover ": {
      color: "#06BDFF",
    },
  },
}));

const ListingCustomBulkModal = ({
  title,
  length,
  subTitle,
  onCancel,
  placeHolder,
  keywords,
  setKeywords,
  setTouchedValue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [duplicateKeywords, setDuplicateKeywords] = useState(false);

  const handleAddKeywords = (e) => {
    if (e.target.value.includes(",") && e.target.value.includes("\n")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(/[\n,]+/);
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(keywords);
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(",")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(",");
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(keywords);
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(".")) {
      const lastDotRemoved = e?.target?.value.replace(/\.+$/, "");
      const arr = lastDotRemoved.split(".");
      arr.concat(keywords);
      const mergeResult = [...keywords, ...arr];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    }
    const lowerCaseBrandTags = keywords.map((tag) => tag.toLowerCase());
    if (lowerCaseBrandTags.includes(e.target.value.toLowerCase())) {
      setDuplicateKeywords(true);
    } else {
      setDuplicateKeywords(false);
    }
  };

  const removeKeyword = (index) => {
    setKeywords(keywords.filter((el, i) => i !== index));
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter" || e.target.value.trim() === "") return;

    let value = e.target.value.toLowerCase().trim();
    if (value.includes("\n") && value !== "\n") {
      const lastLineBreakRemoved = value.replace(/\n+$/, "");
      const arr = lastLineBreakRemoved.split("\n");
      const filtered = arr.filter((item) => item?.trim() !== "");
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);

      e.target.value = "";
    } else {
      if (!value.trim()) return;
      const mergeResult = [...keywords, value];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
    }
    const lowerCaseBrandTags = keywords.map((tag) => tag.toLowerCase());
    const lastLineBreakRemoved = value.replace(/\n+$/, "");
    const arr = lastLineBreakRemoved.split("\n");
    const filtered = arr.filter((item) => item?.trim() !== "");
    if (lowerCaseBrandTags.includes(value.toLowerCase())) {
      setDuplicateKeywords(true);
      setTimeout(() => {
        setDuplicateKeywords(false);
      }, 2000);
    } else {
      for (let i = 0; i < keywords.length; i++) {
        if (filtered.includes(keywords[i])) {
          setDuplicateKeywords(true);
          setTimeout(() => {
            setDuplicateKeywords(false);
          }, 2000);
          break;
        }
      }
    }

    e.target.value = "";
  };

  return (
    <>
      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>{title}</Typography>
              <Typography>{subTitle}</Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={onCancel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Divider />

      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Grid className={classes.mainKeyWordContainer}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "4px",
              }}
            >
              <Typography className={classes.labelText}>{title}</Typography>
              <Typography className={classes.limitFieldTextDescription}>
                {keywords === "" || keywords?.length === 0
                  ? 0
                  : keywords?.length}
                /20
              </Typography>
            </Box>
            <Grid className={classes.tagsInputContainer}>
              <TextField
                variant="standard"
                type="text"
                className={classes.tagsInputBulk}
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder={placeHolder}
                autoComplete="none"
                fullWidth
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  setTouchedValue(true);
                  if (
                    e.target.value === "," ||
                    e.target.value === "." ||
                    e.target.value.trim() === ""
                  ) {
                    e.target.value = "";
                  } else if (
                    (e.target.value !== "" &&
                      e.target.value !== "," &&
                      e.target.value.includes(".")) ||
                    (e.target.value !== "." &&
                      e.target.value !== "" &&
                      e.target.value.includes(","))
                  ) {
                    handleAddKeywords(e);
                  }
                }}
                disabled={keywords.length === 20}
              />
              {keywords?.length > 0 &&
                keywords.map((keywords, index) => (
                  <Grid className={classes.tagItem} key={index}>
                    <Box className={classes.keywordBox}>
                      <Typography className={classes.keywordTypo}>
                        {keywords}
                      </Typography>
                      <CloseIcon
                        className={classes.keywordIcon}
                        onClick={() => removeKeyword(index)}
                      />
                    </Box>
                  </Grid>
                ))}
            </Grid>
            <Typography
              sx={{
                padding: "4px",
                color: "gray",
                fontSize: "10px",
              }}
            >
              {t("Enter_Keyword_text")}
            </Typography>
            {duplicateKeywords && (
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {t("keywords_duplicated_error")}
              </Typography>
            )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ListingCustomBulkModal;
