// ** React Imports ================================================================
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// ** File Imports ================================================================
import { api } from "../../../../contexts/JWTContext";
import NotFound from "../../../../components/NotFound/NotFound";
import Loader from "../../../../components/Loaders/Loader";
import CommonSearch from "../../../../components/CustomComponents/Cards/CommonSearch";

// ** Material ui Imports ================================================================
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TablePagination } from "@mui/material";

// ** Other Imports ================================================================
import moment from "moment";

const AdminDomain = () => {
  // ** States ================================================================
  const [allDomain, setAllDomain] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [reportLoading, setReportLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //const [isLoading, setIsLoading] = useState(false);

  // ** Others ================================================================
  const { t } = useTranslation();
  const navigate = useNavigate();

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  // ** Use Effects ================================================================
  useEffect(() => {
    getAllDomain();
  }, []);

  useEffect(() => {
    paginationData();
  }, [rowsPerPage, page]);

  // ** Handler Functions ================================================================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeSearch = (e) => {
    setSearchData(e.target.value);
  };

  const getAllDomain = async () => {
    setReportLoading(true);
    try {
      const res = await api.get(`/seo/backlink-reports`);
      if (res.status === 200) {
        setAllDomain(res?.data?.data);
        setReportLoading(false);
      }
    } catch (error) {
      setReportLoading(false);
    }
  };

  const paginationData = async () => {
    try {
      setReportLoading(true);
      const res = await api.get(
        `/seo/backlink-reports?limit=${rowsPerPage}&page=${page + 1}`
      );
      if (res.status === 200) {
        setAllDomain(res.data.data);
        setReportLoading(false);
      }
    } catch (error) {
      setReportLoading(false);
    }
  };

  return (
    <div>
      <CommonSearch onChange={onChangeSearch} />

      {reportLoading ? (
        <Loader />
      ) : (
        <>
          {allDomain?.count > 0 ? (
            <>
              {" "}
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Report")}</TableCell>
                      <TableCell align="right">{t("Type")}</TableCell>
                      <TableCell align="right">{t("Report_Date")}</TableCell>
                      <TableCell align="right">{t("Backlinks")}</TableCell>
                      <TableCell align="right">
                        {t("Reffering_Domains")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allDomain?.results?.map((row) => (
                      <TableRow
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/admin/backlink/${row?.reportId}`)
                        }
                      >
                        <TableCell component="th" scope="row">
                          {row.target}
                        </TableCell>
                        {/* <CheckBox /> */}
                        <TableCell align="right">{row?.mode}</TableCell>
                        <TableCell align="right">
                          {" "}
                          {moment(row?.created).format("MM/DD/YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          {kFormatter(row?.totalBackLinks)}
                        </TableCell>
                        <TableCell align="right">
                          {kFormatter(row?.totalDomains)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allDomain?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              <NotFound />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AdminDomain;
