import React, { useState, useEffect } from "react";
import { DateRangePicker } from "mui-daterange-picker";
import {
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Box,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
import moment from "moment";
import CommonButton from "../Buttons/CommonButton";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DialogContent } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import useDateFormat from "../../../hooks/useDateFormat";

const DateRangeComponent = ({
  handleChangeEndDate,
  handleChangeStartDate,
  startDate,
  endDate,
  fromFilters,
  rangeValue,
  fromSchedule,
  repeatValue,
  setCallApi,
  removePadding,
  handleChangeDateRangesfromRangeModel,
}) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  const { formatDate } = useDateFormat();
  let currentDate = new Date();
  currentDate.setHours(23);
  currentDate.setMinutes(59);
  currentDate.setSeconds(59);
  const [currentDateState, setCurrentDateState] = useState(currentDate);

  const [modelRange, setModelRange] = useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  });

  useEffect(() => {
    if (open) {
      setModelRange({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
    }
  }, [open]);

  useEffect(() => {
    const yearSelect = document.querySelector(
      ".MuiPicker-yearSelection select"
    );
    if (yearSelect) {
      const yearOptions = yearSelect.options;
      for (let i = 0; i < yearOptions.length; i++) {
        const year = parseInt(yearOptions[i].value);
        if (year > 2024) {
          yearOptions[i].disabled = true;
        }
      }
    }
  }, [open]);

  const handleOpen = () => setOpen(true);

  const handleDateChange = (range) => {
    setModelRange(range);
  };

  const handleSaveDateRange = () => {
    if (setCallApi !== undefined) {
      setCallApi(false);
    }
    if (handleChangeDateRangesfromRangeModel !== undefined) {
      handleChangeDateRangesfromRangeModel(
        new Date(modelRange?.startDate.setHours(0, 0, 0, 0)),
        new Date(modelRange?.endDate.setHours(23, 59, 59, 999))
      );
    } else {
      handleChangeStartDate(
        new Date(modelRange?.startDate.setHours(0, 0, 0, 0))
      );
      handleChangeEndDate(
        new Date(modelRange?.endDate.setHours(23, 59, 59, 999))
      );
    }

    setOpen(false);
  };

  const startDateNew = new Date();
  startDateNew.setDate(startDateNew.getDate() - 7);

  // Calculate end date (today)
  const endDateNew = new Date();

  // Calculate today's start and end dates
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0); // Set to midnight
  const todayEnd = new Date();
  todayEnd.setHours(23, 59, 59, 999); // Set to end of the day

  // Calculate yesterday's start and end dates
  const yesterdayStart = new Date(todayStart);
  yesterdayStart.setDate(yesterdayStart.getDate() - 1);
  const yesterdayEnd = new Date(todayEnd);
  yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);

  // Calculate start and end dates for this week
  const today = new Date();
  const thisWeekStart = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay() + 1
  ); // Monday of the current week

  // If today is Sunday, adjust to get the previous Monday
  if (today.getDay() === 0) {
    thisWeekStart.setDate(thisWeekStart.getDate() - 7);
  }

  const thisWeekEnd = new Date(
    thisWeekStart.getFullYear(),
    thisWeekStart.getMonth(),
    thisWeekStart.getDate() + 6
  ); // Sunday of the current week

  // Calculate start and end dates for last week (Monday to Sunday)
  const lastWeekStart = new Date(thisWeekStart);
  lastWeekStart.setDate(lastWeekStart.getDate() - 7); // Monday of last week
  const lastWeekEnd = new Date(thisWeekEnd);
  lastWeekEnd.setDate(lastWeekEnd.getDate() - 7); // Sunday of last week
  // Calculate the first and last dates of the previous month
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

  // Calculate the first and last dates of the previous year
  const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
  const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);

  const ranges = [
    {
      label: t("today"),
      startDate: new Date().setHours(0, 0, 0, 0),
      endDate: new Date().setHours(59, 59, 59, 59),
    },
    {
      label: t("yesterday"),
      startDate: yesterdayStart,
      endDate: yesterdayEnd,
    },
    {
      label: t("This_Week"),
      startDate: thisWeekStart,
      endDate: thisWeekEnd,
    },
    {
      label: t("week"),
      startDate: lastWeekStart,
      endDate: lastWeekEnd,
    },
    {
      label: t("7days"),
      startDate: startDateNew,
      endDate: new Date(endDateNew.setDate(endDateNew.getDate() - 1)),
    },
    {
      label: t("30days"),
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(todayStart.setDate(todayStart.getDate() - 1)),
    },
    {
      label: t("This_Month"),
      startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      endDate: new Date(),
    },
    {
      label: t("monthValue"),
      startDate: lastMonthStart,
      endDate: lastMonthEnd,
    },
    {
      label: t("this_year"),
      startDate: new Date(today.getFullYear(), 0, 1),
      endDate: new Date(),
    },
    {
      label: t("Last_Year"),
      startDate: lastYearStart,
      endDate: lastYearEnd,
    },
    {
      label: t("last_365_year"),
      startDate: new Date(new Date().setDate(new Date().getDate() - 365)),
      endDate: new Date(todayStart.setDate(todayStart.getDate())),
    },
  ];

  const handleClose = () => {
    setModelRange({
      startDate: startDate,
      endDate: endDate,
    });
    setOpen(false);
  };

  return (
    <Grid container sx={{ padding: "0px" }}>
      <Grid
        item
        xs={rangeValue === "between" ? 6 : fromSchedule ? 4 : 12}
        sm={rangeValue === "between" ? 6 : fromSchedule ? 4 : 12}
        md={rangeValue === "between" ? 6 : fromSchedule ? 4 : 12}
        lg={rangeValue === "between" ? 6 : fromSchedule ? 4 : 12}
        sx={{ padding: removePadding ? "8px 0px 8px 0px" : "8px 0px 8px 8px" }}
        // mt={fromFilters ? 3 : 0}
      >
        {fromFilters && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "28px",
              color: "#1B2430",
              // marginBottom: "6px",
              // marginLeft: "10px",
              marginTop: "6px",
            }}
          >
            {t("Fromm")}
          </Typography>
        )}
        <TextField
          value={startDate ? formatDate(startDate) : ""}
          // onChange={onChange}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton edge="end" onClick={handleOpen} size="large">
                <InsertInvitationIcon />
              </IconButton>
            ),
          }}
          fullWidth
          placeholder={!fromFilters ? t("Fromm") : "DD/MM/YYYY"}
          label={!fromFilters ? t("Fromm") : ""}
          sx={{ background: "#FFFF" }}
        />
      </Grid>
      {repeatValue !== "no" && (
        <Grid
          item
          xs={rangeValue === "between" ? 6 : fromSchedule ? 4 : 12}
          sm={rangeValue === "between" ? 6 : fromSchedule ? 4 : 12}
          md={rangeValue === "between" ? 6 : fromSchedule ? 4 : 12}
          lg={rangeValue === "between" ? 6 : fromSchedule ? 4 : 12}
          sx={{
            padding: "8px 0px 8px 8px",
          }}
        >
          {fromFilters && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "28px",
                color: "#1B2430",
                // marginBottom: "6px",
                // marginLeft: "10px",
                marginTop: "6px",
              }}
            >
              {t("Too")}
            </Typography>
          )}
          <TextField
            value={endDate ? formatDate(endDate) : ""}
            // onChange={onChange}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton edge="end" onClick={handleOpen} size="large">
                  <InsertInvitationIcon />
                </IconButton>
              ),
            }}
            fullWidth
            placeholder={!fromFilters ? t("Too") : "DD/MM/YYYY"}
            label={!fromFilters ? t("Too") : ""}
            sx={{ background: "#FFFF" }}
          />
        </Grid>
      )}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "770px",
            height: "500px",
            borderRadius: "4px",
          },
        }}
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              width: "100%",
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
              {t("Select_Date_Range")}
            </Typography>
            <IconButton
              autoFocus
              onClick={handleClose}
              className="delete_button"
            >
              <CloseIcon sx={{ paddingTop: "0px", cursor: "pointer" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DateRangePicker
            open={open}
            toggle={handleClose}
            onChange={handleDateChange}
            // value={modelRange}
            initialDateRange={modelRange}
            minDate={new Date("2005-01-01")}
            maxDate={currentDateState}
            definedRanges={ranges}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <CommonButton
            label={t("Save")}
            onSubmit={handleSaveDateRange}
            //  loading={isLoading}
          ></CommonButton>
        </DialogActions>
        <style>
          {`
       .MuiDialogActions-root {
        display: block;
        text-align:right;
      }
     
    `}
        </style>
      </Dialog>
    </Grid>
  );
};

export default DateRangeComponent;
