import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  Checkbox,
  DialogContent,
  Dialog,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import FetchModel from "../../../components/Models/DeleteModal/FetchModels.css";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loaders/Loader";

const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const CreateEditGroup = ({
  title,
  open,
  handleCloseNewRequestModel,
  allLocations,
  selectedLocation,
  setSelectedLocation,
  getsentimentalLocation,
  requestLoading,
  groupName,
  setGroupName,
  locationGroupData,
  getLocationGroupById,
  groupId,
  groupsLoading,
  updateLocationGroup,
  createLocationGroup,
  duplicateGroupError,
  duplicateErrorMessage,
  setDuplicateGroupError,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [deletePost, setDeletePost] = useState(null);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectAll, setSelectAll] = useState(false);
  const filter = createFilterOptions();

  useEffect(() => {
    if (open === true && title === "edit") {
      getLocationGroupById();
    }
  }, [open]);

  useEffect(() => {
    if (locationGroupData !== null) {
      let loc = [];
      locationGroupData?.groups?.forEach((itemOne) => {
        allLocations?.forEach((item) => {
          if (item?.id === itemOne?.locationId) {
            loc.push(item);
          }
        });
      });

      setGroupName(locationGroupData?.name);
      setSelectedLocation([...loc]);
    }
  }, [locationGroupData]);

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "100%",
              maxWidth: "700px",
              height: "480px",
            },
          }}
          open={open}
          onClose={handleCloseNewRequestModel}
        >
          <DialogTitle
            sx={{
              lineHeight: "28px",
              fontWeight: 700,
              fontSize: "20px",
              color: "#1b2430",
            }}
          >
            {title === "edit" ? `${t("edit_group")}` : `${t("create_group")}`}
          </DialogTitle>
          <DialogContent dividers>
            {groupsLoading ? (
              <Loader />
            ) : (
              <Grid container spacing={4}>
                <Grid item sm={12} md={12} lg={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#1B2430",
                    }}
                  >
                    {t("group_names")}
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => {
                      setGroupName(e.target.value);
                      setDuplicateGroupError(false);
                    }}
                    error={duplicateGroupError}
                    helperText={
                      duplicateGroupError && t("duplicate_group_message")
                    }
                    placeholder={t("groups_placeholder_text")}
                    value={groupName}
                  />
                  {/*   {duplicateGroupError && (
                    <Typography sx={{ color: "red" }}>
                      {duplicateErrorMessage}
                    </Typography>
                  )} */}
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#1B2430",
                    }}
                  >
                    {t("Select_Location")}
                  </Typography>
                  <Autocomplete
                    multiple
                    disablePortal
                    disableCloseOnSelect
                    id="combo-box-demo"
                    options={allLocations?.length > 0 ? allLocations : []}
                    //  sx={{ height: "30vh" }}

                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    className="Autocomplete-field"
                    // value={locationId}
                    value={selectedLocation?.length ? selectedLocation : []}
                    //   onChange={(e, value) => {
                    //   setSelectedLocation(value);
                    //   //  setLocationRequired(false);
                    // }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) => {
                      // Check if 'All' option is clicked

                      if (value.find((option) => option.id === 0)) {
                        // Check if all options are selected

                        if (allLocations?.length === selectedLocation?.length) {
                          setSelectedLocation([]);
                          setSelectAll(false);
                        } else {
                          setSelectAll(true);
                          setSelectedLocation(allLocations);
                        }
                      } else {
                        setSelectedLocation(value);
                        if (value?.length === allLocations?.length) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                      }
                    }}
                    /*     isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    } */
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      return [
                        { id: 0, internalName: "Select All" },
                        ...filtered,
                      ];
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          //  checked={selected }
                          checked={selectAll || selected}
                        />
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Grid item xs={9} sm={9} md={9} lg={9}>
                            <span style={{ textAlign: "start" }}>
                              {`${option?.internalName} ${
                                option?.addressLine1 !== "" &&
                                option?.addressLine1 !== null &&
                                option?.addressLine1 !== undefined
                                  ? "(" + option?.addressLine1 + "," + " "
                                  : ""
                              } ${option?.city ? option?.city + ")" : ""}`}
                            </span>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("SelectLocation")}
                        {...params}
                        required
                        //  error={locationRequired}
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleCloseNewRequestModel();
                setSelectAll(false);
              }}
              className="delete_button"
              sx={{ color: "#1B2430" }}
            >
              {t("Cancel")}
            </Button>

            <Button
              autoFocus
              disabled={
                selectedLocation?.length === 0 ||
                groupName === "" ||
                groupsLoading ||
                duplicateGroupError
              }
              onClick={() => {
                if (title === "edit") {
                  updateLocationGroup();
                } else {
                  createLocationGroup();
                }
              }}
              className="delete_button"
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default CreateEditGroup;
