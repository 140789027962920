import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Zoom,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  IconButton,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import LinearProgress from "@mui/material/LinearProgress";
import { api } from "../../../../../../../../contexts/JWTContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { Construction } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});
Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const CoverImagesCard = ({
  selectedImages,
  img,
  handleDelete,
  locationId,
  handleClose,
  index,
  category,
  isUploading,
  setIsUploading,
  handleImageError,
  uploadedImages,
  setUploadedImages,
  setSelectedImages,
  setTouchedValue,
  validation,
}) => {
  const { t } = useTranslation();
  const [isUploaded, setIsUploaded] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageArea, setImageArea] = useState(null);
  const [imgRatio, setImgRatio] = useState(null);
  useEffect(async () => {
    if (isUploading) {
      await uploadImages();
    }
  }, [isUploading]);
  useEffect(() => {
    if (uploadedImages?.length === selectedImages?.length) {
      setIsUploading(false);
    }
  }, [uploadedImages]);

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const uploadImages = async () => {
    if (img.uploaded === false) {
      try {
        //let tempImages = [];
        let formData = new FormData();
        // selectedImages.forEach((item) => {
        formData.append("photos", img.picture);
        // });

        setIsLoading(true);
        const res = await api.post(
          `/locationListing/upload-cover-images/${locationId}?category=${category}`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 50;

              setProgress(progress);
            },
            onDownloadProgress: (progressEvent) => {
              const progress =
                50 + (progressEvent.loaded / progressEvent.total) * 50;
              setProgress(progress);
            },
          }
        );

        if (res.status === 200) {
          uploadedImages.push(res?.data?.data?.result?.Location);
          setIsUploaded(true);
          img["key"] = res?.data?.data?.result?.Location;
          img["uploaded"] = true;
          setUploadedImages([...uploadedImages]);
          setIsLoading(false);
        }
      } catch (error) {
        setIsUploaded(false);

        setIsLoading(false);
      }
      setTouchedValue(true);
    }
  };
  function getGCD(a, b) {
    return b === 0 ? a : getGCD(b, a % b);
  }
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Item
          sx={{
            gap: "1rem",
          }}
        >
          <Avatar
            variant="square"
            sx={{
              width: "56px",
              height: "56px",
              marginY: "10px",
              borderRadius: "8px",
            }}
            onLoad={(item) => {
              item?.target?.naturalHeight &&
                item?.target?.naturalWidth &&
                setImageArea({
                  height: item?.target?.naturalHeight,
                  width: item?.target?.naturalWidth,
                });

              if (
                item?.target?.naturalWidth < 480 ||
                item?.target?.naturalWidth > 3000
              ) {
                handleImageError(index);
              }
              if (
                item?.target?.naturalHeight < 480 ||
                item?.target?.naturalHeight > 3000
              ) {
                handleImageError(index);
              }

              if (img?.picture?.size < 10000 || img?.picture?.size > 5000000) {
                /*  if (
                img?.target?.naturalHeight < 250 ||
                img?.target?.naturalWidth < 250
              ) {
                handleImageError(index);
              } */
                handleImageError(index);
              }
            }}
            alt="Remy Sharp"
            src={img?.picturePreview}
          />
        </Item>
        <Item
          sx={{
            paddingLeft: "20px",
            flex: "auto",
            gap: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: "#1B2430",
              fontWeight: "400",
            }}
          >
            {img?.picture?.name.slice(0, 30)}
            {/*  {img?.picture?.name?.length > 30 ? "..." : ""} */}
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              color: "gray",
              fontWeight: "400",
            }}
          >
            {formatBytes(img?.picture?.size)}{" "}
            {imageArea && `(${imageArea?.width}px/${imageArea?.height}px)`}
          </Typography>
        </Item>
        <Item
          sx={{
            width: "180px",
            gap: "1rem",
          }}
        >
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : isUploaded === null ? (
            <Grid>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#FC3652",
                  fontWeight: "400",
                }}
              >
                {imageArea !== null &&
                (imageArea?.width < 480 || imageArea?.height < 480)
                  ? "Min: 480px/480px w/h"
                  : ""}{" "}
                {imageArea !== null &&
                (imageArea?.width > 3000 || imageArea?.height > 3000)
                  ? "Max: 3000px/3000px w/h"
                  : ""}{" "}
                {img?.picture?.size < 10000 || img?.picture?.size > 5000000
                  ? "  Min size: 10 KB, Max size: 5MB"
                  : ""}
              </Typography>
            </Grid>
          ) : (
            <Typography
              sx={{
                // alignItems: "start",

                fontSize: "14px",
                color: isUploaded ? "#13CF8F" : "#FC3652",
                fontWeight: "400",
              }}
            >
              {isUploaded ? t("Uploaded_successfully") : t("Uploaded_failed")}
            </Typography>
          )}
        </Item>

        <Item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {isUploaded === false ? (
            <CustomWidthTooltipOfDelete title="retry">
              <IconButton
                sx={{
                  color: "#8D9298",
                  width: "20px",
                  height: "18px",
                }}
                disabled={isLoading ? true : false}
                aria-label="retry"
                size="large"
                // color="#8D9298"
                onClick={() => uploadImages()}
              >
                <ReplayIcon />
              </IconButton>
            </CustomWidthTooltipOfDelete>
          ) : (
            <CustomWidthTooltipOfDelete
              // color="#8D9298"
              title="Delete"
            >
              <IconButton
                sx={{
                  color: "#8D9298",
                  width: "20px",
                  height: "18px",
                }}
                disabled={isLoading ? true : false}
                aria-label="delete"
                size="large"
                // color="red"
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            </CustomWidthTooltipOfDelete>
          )}
        </Item>
      </Stack>
    </>
  );
};

export default CoverImagesCard;
