import React, { useState, useEffect } from "react";

import styled, { withTheme } from "styled-components/macro";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import GoogleMapInpuField from "../../../../../components/GoogleMapField/index";

//Start for select time zone*******************************************
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
//End for select time zone*********************************************
import { spacing } from "@mui/system";
import { languagesData } from "../../../../../assets/defaultLanguage";

import { Grid, Box, IconButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Alert as MuiAlert,
  Button,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { handleSessionOut } from "../../../../../contexts/JWTContext";
import { useTranslation } from "react-i18next";

import useAuth from "../../../../../hooks/useAuth";
import { api } from "../../../../../contexts/JWTContext";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
const Alert = styled(MuiAlert)(spacing);
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//Country Select Field End*******************************************
//Select Partner  Field Start*******************************************
function getStylesofPartner(name, partnerAccount, theme) {
  return {
    fontWeight:
      partnerAccount.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

//Select Partner Field End*******************************************
const LocationForm = ({ theme }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, signOut } = useAuth();
  const partner = [];
  const [googlePlaceData, setGooglePlaceData] = useState({});
  const [placeId, setPlaceId] = useState("");
  const [CompanyData, setCompanyData] = useState({});
  const [companyId, setCompanyId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [selectLanguage, setSelectLanguage] = useState("");
  const [soConnectEmailAddress, setSoConnectEmailAddress] = useState("");
  const [companyRequired, setCompanyRequired] = useState(false);
  const [displayCompanyError, setDisplayCompanyError] = useState(false);
  const [partnerAccount, setPartnerName] = React.useState([]);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  /*  CompanyData?.data?.results?.forEach((element) => {
    partner.push(element.name);
  }); */
  // const { editProfile, user, isLoading } = useAuth();
  //Country Select Field Start*******************************************

  // const [countryName, setCountryName] = React.useState([]);
  // const handleChangeCountryName = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setCountryName(value === "string" ? value.split(",") : value);
  // };
  // let countryName1 = countryName.toString();

  //Country Select Field End*******************************************

  //Select Partner Field Start*******************************************

  useEffect(async () => {
    if (placeId) {
      try {
        const res = await api.get(`/location/place-details/${placeId}`);
        if (res.status === 200) {
          setGooglePlaceData(res.data.data.googleData.result);
        }
      } catch (error) {}
    }
  }, [placeId]);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_LOCATION_API_KEY}&libraries=places`;
    script.onload = () => {
      setScriptLoaded(true);
    };
    script.onerror = (error) => {
      console.error("Error loading Google Maps Places script:", error);
      // You might want to handle the error here, such as setting a flag to indicate that loading failed
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (displayCompanyError === true) {
      if (companyId === null) {
        setCompanyRequired(true);
      } else {
        setCompanyRequired(false);
      }
    }
  }, [displayCompanyError]);
  useEffect(async () => {
    try {
      const res = await api.get(`/company`);
      if (res.status === 200) {
        setCompanyData(res.data.data);
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  }, []);
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const handleChangePartnerName = (event) => {
    const {
      target: { value },
    } = event;
    setPartnerName(typeof value === "string" ? value.split(",") : value);
  };
  let partnerAccount1 = partnerAccount.toString();
  //Select Partner Field End*******************************************
  const [checked, setChecked] = React.useState(false);

  const handleChangeCheckButton = (e) => {
    setChecked(e.target.checked);
  };

  const setPlaceIdFromGoogle = (place) => {
    setPlaceId(place.value.place_id);
  };

  const partnerNames = [];
  var companyIds = [];

  const getSelectedCompanyId = companyIds
    .map((i) => i.name)
    .indexOf(partnerAccount1);

  return (
    <>
      <Grid>
        <Grid sx={{ padding: "10px", bgcolor: "white" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              accountName:
                googlePlaceData && googlePlaceData?.name
                  ? googlePlaceData?.name
                  : "",
              internalName:
                googlePlaceData && googlePlaceData?.name
                  ? googlePlaceData?.name
                  : "",
              addressOne:
                googlePlaceData &&
                googlePlaceData?.vicinity &&
                googlePlaceData?.vicinity
                  ? googlePlaceData?.vicinity
                  : "",
              addressTwo: "",
              postalCode:
                googlePlaceData &&
                googlePlaceData?.address_components &&
                googlePlaceData?.address_components.filter(
                  (address) => address.types[0] === "postal_code"
                )?.length > 0 &&
                googlePlaceData?.address_components.filter(
                  (address) => address.types[0] === "postal_code"
                )[0]["long_name"]
                  ? googlePlaceData?.address_components.filter(
                      (address) => address.types[0] === "postal_code"
                    )[0]["long_name"]
                  : "",
              city:
                googlePlaceData &&
                googlePlaceData?.address_components &&
                googlePlaceData?.address_components.filter(
                  (address) =>
                    address.types[0] === "administrative_area_level_2"
                )?.length > 0 &&
                googlePlaceData?.address_components.filter(
                  (address) =>
                    address.types[0] === "administrative_area_level_2"
                )[0]["long_name"]
                  ? googlePlaceData?.address_components.filter(
                      (address) =>
                        address.types[0] === "administrative_area_level_2"
                    )[0]["long_name"]
                  : "",
              countryName:
                googlePlaceData &&
                googlePlaceData?.address_components &&
                googlePlaceData?.address_components.filter(
                  (address) => address.types[0] === "country"
                )?.length > 0 &&
                googlePlaceData?.address_components.filter(
                  (address) => address.types[0] === "country"
                )[0]["long_name"]
                  ? googlePlaceData?.address_components.filter(
                      (address) => address.types[0] === "country"
                    )[0]["long_name"]
                  : "",
              // countryName:
              //   googlePlaceData &&
              //   googlePlaceData?.address_components &&
              //   googlePlaceData?.address_components.slice(-2)[0]?.long_name
              //     ? googlePlaceData?.address_components?.slice(-2)[0]?.long_name
              //     : "",
              telephone:
                googlePlaceData && googlePlaceData?.international_phone_number
                  ? googlePlaceData?.international_phone_number
                  : "",
              website:
                googlePlaceData && googlePlaceData?.website
                  ? googlePlaceData?.website
                  : "",
              /*  accountExternalReference: "", */
              defaultLanguage: selectLanguage,
              // soConnectEmail: soConnectEmailAddress
              //   ? soConnectEmailAddress
              //   : "",
              businessEmail:
                googlePlaceData && googlePlaceData?.businessEmail
                  ? googlePlaceData?.businessEmail
                  : "",
              companyId: "",
              // partnerAccount: "",

              // firstName: "",
              // lastName: "",
              // email: "",
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              /*   companyId: Yup.string().required(`${t("company_req")}`), */
              accountName: Yup.string()
                .max(255)
                .required(`${t("account_req")}`),
              addressOne: Yup.string()
                .max(255)
                .required(`${t("address_req")}`),

              city: Yup.string()
                .max(255)
                .required(`${t("city_req")}`),
              countryName: Yup.string()
                .max(255)
                .required(`${t("country_req")}`),
              postalCode: Yup.string().required(`${t("postal_req")}`),
              internalName: Yup.string().required(`${t("internal_name_req")}`),
              // .matches(/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/, `${t("invalid_postal")}`),

              // .min(9999, `${t("limit exceed")}`)
              // .max(99999, `${t("limit exceed")}`),

              // telephone: Yup.string().required(`${t("telephone_req")}`),
              businessEmail: Yup.string()
                .max(255)
                .required(`${t("email_req")}`)
                .matches(/\S+@\S+\.\S+/, `${t("valid_email")}`),
              /*   email: Yup.string()
                .email(`${t("valid_email")}`)
                .max(255)
                .required(`${t("email_req")}`)
                .matches(/\S+@\S+\.\S+/, `${t("valid_email")}`), */
              // soConnectEmail: Yup.string()
              //   .email(`${t("valid_email")}`)
              //   .max(255)
              //   .required(`${t("list_email_req")}`)
              //   .matches(/\S+@\S+\.\S+/, `${t("valid_email")}`),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setIsLoading(true);
                const res = await api.post("/location", {
                  name: values.accountName,
                  addressLine1: values.addressOne,
                  addressLine2: values.addressTwo,
                  postalCode: values.postalCode,
                  city: values.city,
                  country: values.countryName,
                  telephone: values.telephone,
                  website: values.website,
                  businessEmail: values.businessEmail,
                  internalName: values.internalName,
                  /*   externalReference: checked
                    ? ""
                    : values.accountExternalReference, */
                  companyId: companyId?.id,
                  placeId,
                  defaultLanguage: values.defaultLanguage,
                  // soConnectEmail: soConnectEmailAddress
                  //   ? soConnectEmailAddress
                  //   : "",
                  lat: googlePlaceData?.geometry?.location?.lat,
                  lng: googlePlaceData?.geometry?.location?.lng,
                });

                if (res.status === 201) {
                  // toast("Location Registered Successfully");

                  setMessage(res.data.message);
                  setIsLoading(false);
                  toast.success("Location Registered Successfully", {
                    onClose: () => {
                      navigate(
                        `/admin/edit_location?state=${res?.data?.data?.id}/`,
                        {
                          state: {
                            showProduct: true,
                            displayOn: true,
                          },
                        }
                      );
                    },
                  });
                }
              } catch (error) {
                // const message =
                //   error?.response?.data?.message || "Something went wrong";
                // setStatus({ success: false });
                // setErrors({ submit: message });
                // setSubmitting(false);
                // setIsLoading(false);
                setIsLoading(false);

                toast.error(error?.response?.data?.message);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {/* {errors.submit && (
                  <Alert mt={2} mb={1} severity="error">
                    {errors.submit}
                  </Alert>
                )} */}

                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("Company_Account")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Select_Company")}
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={CompanyData?.results ? CompanyData?.results : []}
                      getOptionLabel={(option) => option?.name}
                      fullWidth
                      value={companyId}
                      onChange={(e, value) => {
                        setCompanyId(value);
                        if (value) {
                          setCompanyRequired(false);
                          setDisplayCompanyError(false);
                        } else {
                          setCompanyRequired(true);
                          setDisplayCompanyError(true);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="companyId"
                          error={Boolean(companyRequired === true)}
                          helperText={
                            companyRequired === true && t("company_req")
                          }
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Divider variant="middle" />

                {scriptLoaded && (
                  <Grid>
                    <Grid sx={{ bgcolor: "white" }}>
                      <Grid>
                        {/* <Typography variant="subtitle1">
                {t("CreateYourAccount")}
              </Typography> */}

                        <Grid
                          container
                          sx={{
                            marginTop: "20px",
                            marginLeft: "2px",
                            marginRight: "2px",
                            marginBottom: "20px",
                          }}
                        >
                          <Grid item xs={12} sm={3}>
                            <Typography variant="h3">
                              {t("SetupLocation")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <GoogleMapInpuField
                              setPlaceIdFromGoogle={setPlaceIdFromGoogle}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Divider variant="middle" />

                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("Name")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {" "}
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Location_Name")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      name="accountName"
                      value={values.accountName}
                      error={Boolean(touched.accountName && errors.accountName)}
                      fullWidth
                      helperText={touched.accountName && errors.accountName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3"> {t("Internal_name")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {" "}
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("Internal_name_label")}
                      </Typography>
                      <BootstrapTooltip
                        title={
                          <h1 style={{ fontSize: "12px" }}>
                            {t("internalName_tooltip_text")}
                          </h1>
                        }
                      >
                        <IconButton>
                          <ErrorOutlineIcon
                            sx={{
                              color: "#1B2430",
                            }}
                          />
                        </IconButton>
                      </BootstrapTooltip>
                    </Box>
                    <TextField
                      type="text"
                      name="internalName"
                      value={values?.internalName}
                      error={Boolean(
                        touched?.internalName && errors?.internalName
                      )}
                      fullWidth
                      helperText={touched?.internalName && errors?.internalName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Divider variant="middle" />

                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("Address")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("AddressLineOne")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      name="addressOne"
                      value={values.addressOne}
                      //error={Boolean(touched.addressOne && errors.addressOne)}
                      fullWidth
                      //helperText={touched.addressOne && errors.addressOne}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("AddressLineTwo")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      name="addressTwo"
                      value={values.addressTwo}
                      // error={Boolean(touched.addressTwo && errors.addressTwo)}
                      fullWidth
                      // helperText={touched.addressTwo && errors.addressTwo}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <Grid
                      container
                      sx={{
                        marginTop: "20px",
                        marginLeft: "2px",
                        marginRight: "2px",
                        marginBottom: "20px",
                      }}
                    >
                      <Grid item xs={5} sx={{ backgroundColor: "" }}>
                        <Typography
                          sx={{
                            marginTop: "10px",
                            fontSize: "14px",
                            fontWeight: "700",
                            color: "rgba(0,0,0,0.87)",
                          }}
                        >
                          {t("PostalCode")}
                        </Typography>
                        <TextField
                          disabled={isLoading}
                          inputProps={{
                            maxLength: 12,
                          }}
                          name="postalCode"
                          value={values.postalCode}
                          error={Boolean(
                            touched.postalCode && errors.postalCode
                          )}
                          fullWidth
                          helperText={touched.postalCode && errors.postalCode}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid xs={1}></Grid>
                      <Grid item xs={6} sx={{ backgroundColor: "" }}>
                        <Typography
                          sx={{
                            marginTop: "10px",
                            fontSize: "14px",
                            fontWeight: "700",
                            color: "rgba(0,0,0,0.87)",
                          }}
                        >
                          {t("City")}
                        </Typography>
                        <TextField
                          disabled={isLoading}
                          type="text"
                          name="city"
                          value={values.city}
                          error={Boolean(touched.city && errors.city)}
                          fullWidth
                          helperText={touched.city && errors.city}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Country")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      name="countryName"
                      value={values.countryName}
                      error={Boolean(touched.countryName && errors.countryName)}
                      fullWidth
                      helperText={touched.countryName && errors.countryName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                {/* {isLoading && <Loader />} */}
                <Divider variant="middle" />
                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("ContactDetails")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Telephone")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      name="telephone"
                      value={values.telephone}
                      error={Boolean(touched.telephone && errors.telephone)}
                      fullWidth
                      helperText={touched.telephone && errors.telephone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Website")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="text"
                      name="website"
                      value={values.website}
                      error={Boolean(touched.website && errors.website)}
                      fullWidth
                      helperText={touched.website && errors.website}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Email_Address_Buisness")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="email"
                      name="businessEmail"
                      placeholder="e.g.  john@obenan.com"
                      value={values.businessEmail}
                      error={Boolean(
                        touched.businessEmail && errors.businessEmail
                      )}
                      fullWidth
                      helperText={touched.businessEmail && errors.businessEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                {/*    <Divider variant="middle" />
                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">
                      {t("So_Connect_heading")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("So_Connect_email")}
                    </Typography>
                    <TextField
                      disabled={isLoading}
                      type="email"
                      name="soConnectEmail"
                      placeholder="e.g.  john@obenan.com"
                      value={soConnectEmailAddress}
                      error={Boolean(
                        touched.soConnectEmail && errors.soConnectEmail
                      )}
                      fullWidth
                      helperText={
                        touched.soConnectEmail && errors.soConnectEmail
                      }
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setSoConnectEmailAddress(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid> */}
                <Divider variant="middle" />
                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("Auto_Replyy")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Auto_reply_text")}
                    </Typography>
                    <Select
                      defaultValue={"en"}
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={languagesData?.language}
                      // label="Select"
                      onChange={(e, value) => {
                        setSelectLanguage(e.target.value);
                      }}
                    >
                      {languagesData.map((item) => (
                        <MenuItem value={item.value}>{item.language}</MenuItem>
                      ))}
                    </Select>
                    {/* <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={defaultLanguage}
                      options={defaultLanguage}
                      getOptionLabel={(option) => option.language}
                      defaultValue={defaultLanguage[0]}
                      fullWidth
                      renderInput={(params) => (
                        <TextField {...params} label="Select Language" />
                      )}
                    /> */}
                  </Grid>
                </Grid>
                {/*   <Divider variant="middle" />
                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("Reference")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {!checked && (
                      <>
                        <Typography
                          sx={{
                            marginTop: "10px",
                            fontSize: "14px",
                            fontWeight: "700",
                            color: "rgba(0,0,0,0.87)",
                          }}
                        >
                          {t("AccountReference")}
                        </Typography>
                        <TextField
                          disabled={isLoading}
                          type="text"
                          name="accountExternalReference"
                          value={values.accountExternalReference}
                          error={Boolean(
                            touched.accountExternalReference &&
                              errors.accountExternalReference
                          )}
                          fullWidth
                          helperText={
                            touched.accountExternalReference &&
                            errors.accountExternalReference
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </>
                    )}

                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      <Checkbox
                        checked={checked}
                        onChange={(e) => handleChangeCheckButton(e)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      {t("NoExternalReference")}
                    </Typography>
                  </Grid>
                </Grid>
 */}
                <Divider variant="middle" />
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  my={3}
                >
                  <CommonButton
                    onSubmit={() => navigate("/")}
                    // type="submit"
                    displayWhite="true"
                    variant="contained"
                    // disabled={isSubmitting}

                    label={t("Cancel")}
                  />

                  <CommonButton
                    type="submit"
                    onSubmit={() => setDisplayCompanyError(true)}
                    disabled={isSubmitting}
                    loading={isLoading}
                    label={t("Add_Location")}
                  />
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default withTheme(LocationForm);
