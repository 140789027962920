import { toast } from "react-toastify";
import { api } from "../../../../contexts/JWTContext";
import React, { useEffect, useState } from "react";

import TitleHeader from "../../../../components/CustomComponents/title";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import Card from "@mui/material/Card";
import useAuth from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

import { Dialog } from "@material-ui/core";

import { useNavigate, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import Checkbox from "@mui/material/Checkbox";
import PauseIcon from "@mui/icons-material/Pause";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import QuestionMark from "../../../../assets/Icons/Question.svg";
import Iframe from "../../../../components/Models/IframeModal/Iframe";

import {
  Grid,
  IconButton,
  Divider as MuiDivider,
  Tooltip,
  TextField,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Loader from "../../../../components/Loaders/Loader";
import AllPostsCard from "./AllPostsCard";
import NotFound from "../../../../components/NotFound/NotFound";
import ReconnectCard from "../../../../components/CustomComponents/Cards/ReconnectCard";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  iconCard: {
    border: "1px solid #E0E0E0",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-around",
    width: "40px",
    height: "40px",
    padding: "8px",
  },
  iconContainer: {
    padding: "20px 0px 10px 0px",
  },
  iconStyle: {
    width: "20px",
    height: "18px",
  },
}));

const AllPost = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut } = useAuth();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [open, setOpen] = useState(false);
  const [openPauseModel, setOpenPauseModel] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const { t } = useTranslation();
  //const [postData, setPostData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getPost, setgetPost] = useState([]);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [deletePosts, setDeletePosts] = useState([]);
  const [selectAll, setSelectAll] = useState(null);
  //const [openModal, setOpenModal] = useState(false);
  const [allLocation, setAllLocation] = useState([]);
  const [locationId, setLocationId] = useState([]);
  const [expiredLocations, setExpiredLocation] = useState(null);
  const [displayReconnectButton, setDisplayReconnectButton] = useState(false);
  const [displayReconnectError, setDisplayReconnectError] = useState(false);

  useEffect(() => {
    // getAllLocations();
    getAllCompanies();
  }, []);
  useEffect(() => {
    if (allCompanies?.results?.locations?.length > 0) {
      let arr = [];
      const expiredLocation = allCompanies?.results?.locations?.forEach(
        (item) => {
          if (item?.socialLink?.length > 0) {
            item?.socialLink?.forEach((itemTwo) => {
              if (itemTwo?.type !== "google") {
                arr?.push(item);
              }
            });
          }
        }
      );

      if (arr?.length === 0) {
        setDisplayReconnectError(false);
      } else if (arr?.length === 1) {
        setDisplayReconnectError(true);
        setExpiredLocation(arr[0]);
        setDisplayReconnectButton(true);
      } else if (arr?.length > 1) {
        setDisplayReconnectError(true);
        setDisplayReconnectButton(false);
      }
    }
  }, [allCompanies]);
  useEffect(() => {
    getAllposts();
  }, [locationId, companyId]);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePauseModel = () => {
    setOpenPauseModel(false);
  };
  const handleOpen = () => setOpen(true);
  const handleOpenPauseModel = () => setOpenPauseModel(true);

  const getAllposts = async () => {
    let locationIds = [];
    if (locationId.length > 0) {
      locationId?.map((location) => {
        locationIds.push(location?.id);
      });
    }

    try {
      setIsLoading(true);
      const res = await api.get(
        `/post?locationIds=${locationIds ? locationIds : ""}&companyId=${
          companyId ? companyId?.id : ""
        }`
      );

      if (res.status === 200 || res.status === 201) {
        setgetPost(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleLocationChange = (option) => {
    setLocationId(option);
    {
    }
    if (option?.locations?.length > 0) {
      const arrr = option;
      let arr = [];
      const expiredLocation = arrr?.forEach((item) => {
        if (item?.socialLink?.length > 0) {
          item?.socialLink?.forEach((itemTwo) => {
            if (itemTwo?.type === "google" && itemTwo?.validToken === false) {
              arr?.push(item);
            }
          });
        }
      });

      if (arr?.length === 0) {
        setDisplayReconnectError(false);
      } else if (arr?.length === 1) {
        setDisplayReconnectError(true);
        setExpiredLocation(arr[0]);
        setDisplayReconnectButton(true);
      } else if (arr?.length > 1) {
        setDisplayReconnectError(true);
        setDisplayReconnectButton(false);
      }
    } else {
      getAllCompanies();
    }
  };
  const handleChangeCompany = (option) => {
    if (option !== null) {
      setCompanyId(option);

      let arr = [];
      if (option?.locations?.length > 0) {
        const googleConnectedLocations = option?.locations?.forEach((item) => {
          if (item?.socialLink?.length > 0) {
            item?.socialLink?.forEach((itemTwo) => {
              if (itemTwo?.type === "google") {
                arr?.push(item);
              }
            });
          }
        });
        const defaultLoc = arr.find(
          (item) => item.id === option.defaultLocationId
        );
        if (arr?.length > 0) {
          if (defaultLoc !== null && defaultLoc !== undefined) {
            locationId?.push(defaultLoc);
          } else {
            if (arr?.length > 0) {
              locationId?.push(arr?.[0]);
            }
          }
        } else {
          setLocationId([]);
        }

        {
        }
        //  setAllLocation(option?.locations);
        //   const defaultLoc = option?.locations.find(
        //   (item) => item.id===option.defaultLocationId
        // );
        // if (defaultLoc) {
        //   setLocationId(defaultLoc);
        // } else {
        //   setLocationId(option?.locations[0]);
        // }

        setAllLocation(arr);
      } else {
        setAllLocation([]);
        setLocationId([]);
      }
    } else {
      setAllLocation([]);
      setLocationId([]);
    }
  };

  // const handleChangeCompany = (option) => {
  //   setCompanyId(option);
  //   if (option?.locations?.length > 0) {
  //     let arr = [];
  //     const expiredLocation = allLocation?.results?.forEach((item) => {
  //       if (item?.socialLink?.length > 0) {
  //         item?.socialLink?.forEach((itemTwo) => {
  //           if (itemTwo?.type === "google" && itemTwo?.validToken===false) {
  //             arr?.push(item);
  //           }
  //         });
  //       }
  //     });

  //     if (arr?.length===0) {
  //       setDisplayReconnectError(false);
  //     } else if (arr?.length===1) {
  //       setDisplayReconnectError(true);
  //       setExpiredLocation(arr[0]);
  //       setDisplayReconnectButton(true);
  //     } else if (arr?.length > 1) {
  //       setDisplayReconnectError(true);
  //       setDisplayReconnectButton(false);
  //     }
  //   }
  //     };
  const getAllCompanies = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  /*  const getAllLocations = async () => {
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllLocation(res.data.data);
      }
    } catch (error) {}
  }; */
  const handleDeleteBulkPosts = async (deletePostIds) => {
    setIsLoading(true);
    try {
      const res = await api.patch(`/post/deleteBulk`, {
        postIds: deletePostIds,
      });
      if (res.status === 200) {
        getAllposts();
        toast.success("Posts Deleted Successfully");
        setOpen(false);
        closeMenu(false);
        setDeletePosts([]);
      }
      setIsLoading(false);
    } catch (error) {
      setDeletePosts([]);
      toast.error("Posts Delete Failed");
    }
  };
  const handleBulkPausePosts = async (deletePostIds) => {
    setIsLoading(true);
    try {
      const res = await api.patch(`/post/pausedPosts`, {
        postIds: deletePostIds,
      });
      if (res.status === 200) {
        getAllposts();
        toast.success("Posts Paused Successfully");
        setOpenPauseModel(false);
        closeMenu(false);
        setDeletePosts([]);
      }
      setIsLoading(false);
    } catch (error) {
      setOpenPauseModel(false);
      toast.error("Posts Pause Failed");
      setDeletePosts([]);
    }
  };

  /*  useEffect(() => {
    if (locationIdParams) {
      setLocationId(locationIdParams);
    }
  }, [locationIdParams]);
 */
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };

  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      for (var i = 0; i < getPost?.results.length; i++) {
        deletePosts.push(getPost?.results[i].id);
      }
      const uniquePosts = new Set(deletePosts);
      setDeletePosts([...uniquePosts]);
    } else {
      setDeletePosts([]);
    }
  };
  const handlePostChecked = (e, index, postId) => {
    if (e.target.checked) {
      deletePosts.push(postId);
    } else {
      var postIndex = deletePosts.indexOf(postId);
      deletePosts.splice(postIndex, 1);
    }
    setDeletePosts([...deletePosts]);
  };

  return (
    <>
      <TitleHeader
        title={t("All_Posts")}
        subHeading={t("post_subHeading")}
        createButton={true}
        // disableButton={locationId ? false : true}
        name={t("Add_Bulk_Post")}
        onClick={() => {
          navigate("/admin/posts", {
            state: { location: locationId },
          });
        }}
        extraButton={true}
        extraButtonName={
          <Tooltip title={t("Help")}>
            <img alt="" src={QuestionMark} />
          </Tooltip>
        }
        handleDefaultTemplate={handleOpenTemplateMenu}
        addPermission={true}
        viewPermission={true}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Autocomplete
            // multiple
            disablePortal
            id="combo-box-demo"
            options={
              allCompanies?.results?.length > 0 ? allCompanies?.results : []
            }
            getOptionLabel={(option) =>
              option?.name ? option?.name + "(" + option?.city + ")" : ""
            }
            className="Autocomplete-field"
            value={companyId ? companyId : []}
            onChange={(e, value) => {
              handleChangeCompany(value);
            }}
            /*  renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {`${option?.internalName} (${option.city})`}
              </li>
            )} */
            renderInput={(params) => (
              <TextField
                placeholder={t("Select_Company")}
                {...params}
                required
                name="company"
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Autocomplete
            multiple
            disablePortal
            id="combo-box-demo"
            options={allLocation?.length > 0 ? allLocation : []}
            getOptionLabel={(option) =>
              option?.internalName
                ? option?.internalName +
                  " " +
                  "(" +
                  (option?.addressLine1 !== "" &&
                  option?.addressLine1 !== null &&
                  option?.addressLine1 !== undefined
                    ? option?.addressLine1 + "," + " "
                    : "") +
                  (option?.city ? option?.city : "") +
                  ")"
                : ""
            }
            className="Autocomplete-field"
            value={locationId ? locationId : []}
            onChange={(e, value) => {
              handleLocationChange(value);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {`${option?.internalName} (${
                  option?.addressLine1 !== "" &&
                  option?.addressLine1 !== null &&
                  option?.addressLine1 !== undefined
                    ? option?.addressLine1 + "," + " "
                    : ""
                } ${option.city})`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                placeholder={t("SelectLocation")}
                {...params}
                required
                name="language"
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>

      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {/* {displayReconnectError && (
              <Grid container sx={{ marginTop: "10px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ReconnectCard
                    location={expiredLocations}
                    displayReconnectButton={displayReconnectButton}
                  />
                </Grid>
              </Grid>
            )} */}
            {getPost?.results?.length > 0 && (
              <Grid container className={classes.iconContainer}>
                <Grid item xs={6} sm={6} md={8} lg={8}>
                  <Checkbox
                    checked={selectAll}
                    onChange={(e) => handleChangeCheckButton(e)}
                    //   inputProps={{ "aria-label": "controlled" }}
                  />
                  {t("Select_All")}
                </Grid>
                {deletePosts.length > 0 && (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={4}
                    lg={4}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Card className={classes.iconCard}>
                      <Tooltip title="Delete">
                        <IconButton
                          className={classes.iconStyle}
                          aria-label="delete"
                          size="large"
                          // color="red"
                          onClick={() => handleOpen()}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Card>

                    <Card
                      className={classes.iconCard}
                      sx={{ marginLeft: "5px" }}
                    >
                      <Tooltip title="Pause">
                        <IconButton
                          sx={{ color: "#1B2430" }}
                          className={classes.iconStyle}
                          aria-label="delete"
                          size="large"
                          onClick={() => handleOpenPauseModel()}
                        >
                          <PauseIcon />
                        </IconButton>
                      </Tooltip>
                    </Card>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                {getPost?.results?.length > 0 ? (
                  <>
                    {getPost &&
                      getPost?.results?.map((item, index) => (
                        <>
                          <AllPostsCard
                            key={item.id}
                            item={item}
                            getAllposts={getAllposts}
                            handlePostChecked={handlePostChecked}
                            index={index}
                            deletePostsArr={deletePosts}
                          />
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    <NotFound text={t("No_Posts_Found")} />
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </>

      {open && (
        <Dialog
          // fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={() => handleDeleteBulkPosts(deletePosts)}
            onCancel={handleClose}
          />
        </Dialog>
      )}
      {openPauseModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openPauseModel}
          onClose={handleClosePauseModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={"Pause Bulk Posts"}
            description={"Are you sure you want to pause all selected posts?"}
            onConfirm={() => handleBulkPausePosts(deletePosts)}
            onCancel={handleClosePauseModel}
          />
        </Dialog>
      )}
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          onClose={handleCloseTemplateMenu}
        >
          <Iframe
            title={t("Post_Tutorial")}
            // description={<iframe src="https://ask.obenan.com/f8yxuj4dh"
            // allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
            // width="100%"
            // height="600px"
            // style="border: none; border-radius: 24px"
            // >
            // </iframe>}

            onCancel={handleCloseTemplateMenu}
            iframe={
              <iframe
                title="iframe"
                loading="lazy"
                src="https://ask.obenan.com/f8yxuj4dh"
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="1100px"
                height="500px"
                onLoad={() => {}}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              />
            }
          />
          {/* <iframe src="https://ask.obenan.com/f8yxuj4dh"
            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
            width="100%"
            height="600px"
            style="border: none; border-radius: 24px"
            >
            </iframe> */}
        </Dialog>
      )}
    </>
  );
};

export default AllPost;
