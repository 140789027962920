// ** React Imports ================================================================
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import CheckIcon from "@mui/icons-material/Check";
// ** File Imports ================================================================
import Loader from "../../../../components/Loaders/Loader";
import TitleHeader from "../../../../components/CustomComponents/title/index";
import UpdateTemplateCard from "./editTemplateCard";
import { api } from "../../../../contexts/JWTContext";
import { languagesData } from "../../../../assets/defaultLanguage";

// ** Material ui Imports ================================================================
import { Edit } from "@mui/icons-material";

import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  Divider as MuiDivider,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useStyles } from "../styles/style";
// ** Other Imports ================================================================

const Divider = styled(MuiDivider)(spacing);

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const EditTemplate = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const { id } = params;
  const [checkRequired, setCheckRequired] = useState(false);

  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // ** States ================================================================
  const [dataArray, setDataArray] = useState([
    { text: "", language: "", isDeleted: false },
  ]);
  const [templateId, setTemplateId] = useState(null);
  const [title, setTile] = useState("");
  const [editData, setEditData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [titleRequired, setTitleRequired] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [defaultTemplate, setDefaultTemplate] = useState([]);
  const [languagesDataArray, setLanguagesDataArray] = useState([]);
  const [deletedTemplates, setDeletedTemplates] = useState([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // ** Others ================================================================

  useEffect(() => {
    const getDataToEdit = async () => {
      setIsLoading(true);

      try {
        const res = await api.get(`/template/${id}`);
        if (res.status === 200) {
          let tempData = [...res.data.data.templateDescription];
          tempData?.forEach((item) => {
            item["isDeleted"] = false;
          });
          setDataArray(tempData);
          setTile(res.data.data.title);
          setTemplateId(res.data.data.id);
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    };
    getDataToEdit();
  }, []);

  useEffect(() => {
    getAllDefaultTemplate();
    // deleteTemplates();
  }, []);

  useEffect(() => {
    setLanguagesDataArray(languagesData);
  }, []);
  useEffect(() => {
    const results = languagesData?.filter(
      ({ value: id1 }) =>
        !dataArray.some(
          ({ language: id2, isDeleted: id3 }) => id2 === id1 && id3 === false
        )
    );
    setLanguagesDataArray(results);
  }, [dataArray]);

  // ** Handler Functions ================================================================
  // ** Helper Function to remove th last word of on curly brace is removed ============================
  function removeLastWord(str) {
    const lastIndexOfSpace = str.lastIndexOf(" ");

    if (lastIndexOfSpace === -1) {
      return str;
    }

    return str.substring(0, lastIndexOfSpace);
  }
  const getAllDefaultTemplate = async () => {
    try {
      const res = await api.get(`/template/default`);
      if (res.status === 200) {
        setDefaultTemplate(res?.data?.data);
      }
    } catch (error) {}
  };

  const deleteTemplate = (index) => {
    const filtered = dataArray?.filter((data, indx) => {
      return indx !== index;
    });
    //

    setDataArray(filtered);
    // setDescription(e.target.value);
  };

  const changeHandler = (
    e,
    index,
    isLanguage,
    isReviewer,
    currentRef,
    deleteTemplate
  ) => {
    let tempArray = [...dataArray];
    let tempData = { ...dataArray[index] };

    let tempValueForDelete =
      isLanguage !== true ? e?.target?.value : e !== undefined ? e.value : "";

    if (tempValueForDelete !== null && tempValueForDelete !== undefined) {
      if (isLanguage === true) {
        tempData["language"] = tempValueForDelete;
      } else {
        if (isReviewer === true) {
          let textBeforeCursorPosition = tempData["text"].substring(
            0,
            currentRef.current.selectionStart
          );
          let textAfterCursorPosition = tempData["text"].substring(
            currentRef.current.selectionStart,
            tempData["text"].length
          );

          tempData["text"] =
            textBeforeCursorPosition +
            " " +
            tempValueForDelete +
            " " +
            textAfterCursorPosition;
          currentRef.current.selectionStart =
            currentRef.current.selectionStart + tempValueForDelete.length + 2;
        } else {
          if (tempValueForDelete && tempValueForDelete?.slice(-1) === "}") {
            tempData["text"] = removeLastWord(tempValueForDelete);
          } else {
            tempData["text"] = tempValueForDelete;
          }
        }
      }
    }

    if (deleteTemplate === true) {
      // tempArray[index]["isDeleted"] = true;
      let data = tempArray[index];
      data.isDeleted = true;
      setDeletedTemplates((curr) => [...curr, data]);

      tempArray.splice(index, 1);
      setDataArray(tempArray);
    } else {
      tempArray.splice(index, 1, tempData);
      setDataArray(tempArray);
    }

    const isTemplatePresent = deletedTemplates.some(
      (t) => t.language === tempValueForDelete
    );

    if (isTemplatePresent) {
      const temporaryTemplates = deletedTemplates.filter(
        (t) => t.language !== tempValueForDelete
      );
      setDeletedTemplates(temporaryTemplates);
    }
  };

  const handleAdd = () => {
    const data = { text: "", language: "", isDeleted: false, id: null };
    setDataArray([...dataArray, data]);
  };

  const saveTemplateHandler = async () => {
    let tempRequiredCount = 0;

    dataArray.forEach((item) => {
      if (item.text.length < 1 || item.language.length < 1) {
        tempRequiredCount++;
      }
    });

    // CHecking for one english template ============================
    const result = dataArray.filter((item) => item.language === "en");
    if (result.length <= 0) {
      toast.warn(t("English_temp_msg"));
      return;
    }

    // CHecking if all the required fields are filled call api ============================
    if (tempRequiredCount === 0 && result.length > 0) {
      if (title?.length < 1) {
        setTitleRequired(true);
      } else {
        setIsLoading(true);
        const combinedArray = dataArray.concat(deletedTemplates);

        let objjj = JSON.stringify(combinedArray);
        const dummyArr = JSON.parse(objjj);

        dummyArr?.forEach((item) => {
          if (item?.isDeleted === false) {
            delete item["isDeleted"];
          }
        });

        const data = {
          title: title,
          updateTemplateDescriptions: dummyArr,
        };

        try {
          const res = await api.patch(`/template/${templateId}`, data);
          if (res.status === 200) {
            //  navigate("/user/template");
            toast.success("Templates updated successfully");
            // deleteTemplate(index);
            setIsLoading(false);
          }
        } catch (error) {
          // toast.error(error?.response?.data?.message);
          toast.error("Something went wrong");

          setIsLoading(false);
        }
      }
    } else {
      setCheckRequired(!checkRequired);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TitleHeader
            title={t("Update_Template")}
            subHeading={t("sub_Update_Template")}
            showButton={true}
          />

          <Paper style={{ height: "100%", boxShadow: "none", padding: "6px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={4} md={4}>
                {editData ? (
                  <>
                    <Box sx={{ ml: 2 }}>
                      <Typography className={classes.titleHead}>
                        {" "}
                        {t("Title")}{" "}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          id="standard-basic"
                          rows={1}
                          maxRows={10}
                          style={{
                            fontFamily: "Segoe UI",
                            fontStyle: "Normal",
                            width: "100%",

                            borderRadius: "8px",
                            color: "#1B2430",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                          label={null}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              setEditData(false);
                            }
                          }}
                          value={title}
                          error={titleRequired}
                          onChange={(event) => {
                            if (event.target.value.length === 0) {
                              setTitleRequired(true);
                            } else {
                              setTitleRequired(false);
                            }

                            setTile(event.target.value);
                          }}
                          multiline
                          fullWidth
                          /*   InputProps={{
                    disableUnderline: true,
                  }} */
                          inputProps={{ maxLength: 1000 }}
                          /*   InputProps={{
                inputComponent: TextareaAutosize,
              }} */
                        />

                        <IconButton
                          aria-label="done"
                          size="large"
                          onClick={() => {
                            setEditData(!editData);
                          }}
                          disabled={!title}
                        >
                          {" "}
                          <CheckIcon />
                        </IconButton>
                      </Box>
                      {titleRequired && (
                        <Typography className={classes.titleRequiredEdit}>
                          {t("title_req")}
                        </Typography>
                      )}
                    </Box>
                    {/* </Box> */}
                  </>
                ) : (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                      <Typography className={classes.titleEdit}>
                        {" "}
                        {title}
                      </Typography>
                      <CustomWidthTooltip title={t("Edit_Title")}>
                        <IconButton
                          aria-label="edit"
                          sx={{ fontSize: "14px" }}
                          onClick={() => {
                            setEditData(true);
                          }}
                        >
                          {" "}
                          <Edit />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12} lg={8} md={8}>
                <Grid container>
                  {dataArray.map((item, index) => {
                    if (item.isDeleted !== true) {
                      return (
                        <>
                          <UpdateTemplateCard
                            key={index}
                            item={item}
                            index={index}
                            deleteTemplate={deleteTemplate}
                            templateId={templateId}
                            checkRequired={checkRequired}
                            changeHandler={changeHandler}
                            defaultTemplate={defaultTemplate}
                            dataArray={dataArray}
                            languagesDataArray={languagesDataArray}
                          />
                        </>
                      );
                    }
                  })}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={6} lg={6}></Grid>
                <Grid item md={0.6} lg={0.6}></Grid>
                <Grid item xs={12} sm={12} md={5.4} lg={5.4}>
                  <IconButton
                    onClick={handleAdd}
                    // loading={loading}
                    displayWhite="true"
                  >
                    <AddIcon sx={{ color: "#0638C1", fontWeight: "700" }} />{" "}
                    <Typography className={classes.addMoreDescription}>
                      {" "}
                      {t("Add_More_Description")}{" "}
                    </Typography>
                  </IconButton>
                </Grid>
              </Grid>
              <Divider my={4} />
            </Grid>

            <Grid container sx={{ justifyContent: "flex-end" }}>
              <CommonButton
                displayWhite="true"
                // onSubmit={onCancel}
                onSubmit={() => {
                  navigate(-1);
                }}
                label={t("Cancel")}
              />

              <CommonButton
                label={t("Save_Template")}
                onSubmit={saveTemplateHandler}
                loading={isLoading}
              >
                {t("Save")}
              </CommonButton>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
};

export default EditTemplate;
