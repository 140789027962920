// ** React Imports ================================================================
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
// ** File Imports ================================================================
import TitleHeader from "../../../../components/CustomComponents/title";

import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import AutoResponseRule from "../../../../components/Models/DeleteModal/AutoResponseModal";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import LocationNotFound from "../../../../components/CustomComponents/Errors/LocationNotFound";
import { checkLocalStorageData } from "../../../../utils/LocalStorageHelpers";
// ** Material ui Imports ================================================================

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  IconButton,
  Dialog,
  Tooltip,
  tooltipClasses,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loader from "../../../../components/Loaders/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, styled } from "@mui/system";
import { TablePagination } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import NoLocationFound from "../../../../components/CustomComponents/Errors/NoLocationFound";
import Iframe from "../../../../components/Models/IframeModal/Iframe";

// ** Other Imports ================================================================
import moment from "moment";
import useDateFormat from "../../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const useStyles = makeStyles((theme) => ({
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },
}));

const Index = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDataValid = checkLocalStorageData();
  const [taskRules, setTaskRules] = useState(null);
  const [loading, setLoading] = useState(false);
  const { signOut, permissionsAcess } = useAuth();
  const [count, setCount] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteRule, setDeleteRule] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const classes = useStyles();
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [allLocation, setAllLocation] = useState([]);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [openIframeModel, setOpenIframeModel] = useState(false);

  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/tasks"
      );
      // setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDeleteRule(id);
  };
  const handleOpenDeleteModel = () => {
    setOpen(deleteRule);
    setAnchorEl(null);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };
  const handleDisplayDate = (item) => {
    let displayDate = "";
    if (companyData.dateFormat === "May 19,2022 3:05PM") {
      displayDate = moment(item).format("MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "Monday,May 19,2022 3:05PM") {
      displayDate = moment(item).format("dddd,MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "May 19,2022") {
      displayDate = moment(item).format("MMMM DD,YYYY");
    }
    if (companyData.dateFormat === "5/19/2022") {
      displayDate = moment(item).format("M/D/YYYY");
    }
    if (companyData.dateFormat === "05/19/2022") {
      displayDate = moment(item).format("MM/D/YYYY");
    }
    if (companyData.dateFormat === "28/12/2022") {
      displayDate = moment(item).format("D/MM/YYYY");
    } else {
      displayDate = moment(item).format("D/MM/YYYY");
    }
    if (displayDate.length > 0) {
      return displayDate;
    } else return null;
  };

  const getAllLocations = async () => {
    setLoading(true);
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });

      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          setAllLocation(res.data.data);
        } else {
          setAllLocation(null);
        }

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAllLocation(null);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const getAllRules = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `/taskRule?limit=${rowsPerPage}&page=${page + 1}`
      );
      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          setTaskRules(res?.data?.data);
        } else {
          setTaskRules(null);
        }

        setCount(res?.data?.data?.count);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setTaskRules(null);
      // toast.error(error?.response?.data?.message);
      /*    handleSessionOut(
        error?.response?.status,
        error?.response?.data?.message,
        handleSignOut
      ); */
    }
  };

  const DeleteRule = async (id) => {
    try {
      const res = await api.delete(`/taskRule/${id}`);
      if (res.status === 201) {
        setOpen(false);
        getAllRules();

        toast.success(res?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(async () => {
    if (isDataValid) {
      await getAllLocations();
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, []);
  useEffect(() => {
    if (isDataValid) {
      setLoading(true);
      getAllRules();
    }
  }, [page, rowsPerPage]);

  const AddRuleNavigateButton = () => {
    navigate(`/user/task-rules/add-rule`);
  };
  const handleAddTemplate = () => {
    navigate("/user/template");
  };
  const handleCloseTemplateModal = () => {
    setOpenDefaultModel(false);
  };

  const handleCloseTemplateMenu = () => {
    setOpenIframeModel(false);
  };
  return (
    <>
      {openIframeModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openIframeModel}
          onClose={handleCloseTemplateMenu}
        >
          <Iframe
            title={t("Task_Tutorial")}
            onCancel={handleCloseTemplateMenu}
            iframe={
              <iframe
                title="iframe"
                loading="lazy"
                src="https://ask.obenan.com/f4tkmskma"
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="1200px"
                height="500px"
                onLoad={() => {}}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              />
            }
          />
        </Dialog>
      )}
      {openDefaultModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openDefaultModel}
          onClose={handleCloseTemplateModal}
          aria-labelledby="responsive-dialog-title"
        >
          <AutoResponseRule
            title={t("No_Reviews_Template")}
            description={t("No_Review_Desc")}
            onConfirm={() => handleAddTemplate()}
            onCancel={handleCloseTemplateModal}
          />
        </Dialog>
      )}

      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Rule_title")}
          description={t("Delete_rule_subhead")}
          onConfirm={() => DeleteRule(deleteRule)}
          onCancel={handleClose}
        />
      </Dialog>

      <TitleHeader
        title={t("Task_Rule_Title")}
        subHeading={t("Task_Rule_SubTitle")}
        createButton={allLocation?.results?.length > 0 ? true : false}
        name={t("Add_Rule")}
        // IframeButton={true}
        // IframeButtonName={
        //   <Tooltip title={t("Help")}>
        //     img alt="" src={QuestionMark} />
        //   </Tooltip>
        // }
        // handleDefaultIframe={handleOpenTemplateMenu}
        onClick={AddRuleNavigateButton}
        count={count}
        addPermission={addPermission}
        viewPermission={viewPermission}
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          {allLocation?.results?.length > 0 ? (
            <>
              {/* <Paper> */}
              {taskRules?.count > 0 ? (
                <>
                  <TableContainer borderRadius="12" component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <CustomizedTableHead>
                        <CustomizedTableRow>
                          <CustomizedTableCell align="left">
                            {t("AccountTableID")}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="left">
                            {t("Rule_Name")}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center">
                            {t("Locations")}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center">
                            {t("Last_updated")}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center">
                            {t("Created_At")}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center">
                            {t("Action")}
                          </CustomizedTableCell>
                        </CustomizedTableRow>
                      </CustomizedTableHead>
                      <TableBody>
                        {taskRules?.results?.map((row) => (
                          <CustomizedTableRow>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row.id}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              align="left"
                              hover
                              sx={{ cursor: "pointer" }}
                              // onClick={() => navigate(`/user/template/${row?.reportId}`)}
                              // onClick={() => navigate(`/user/createtemplate`)}
                            >
                              {row?.title}
                            </CustomizedTableCell>
                            <CustomizedTableCell align="center">
                              {row?.TaskRuleLocations.length === 0
                                ? "--"
                                : row?.TaskRuleLocations.length > 1
                                ? t("Multilocation")
                                : row?.TaskRuleLocations[0]?.location !==
                                    null &&
                                  row?.TaskRuleLocations[0]?.location
                                    ?.internalName +
                                    "(" +
                                    row?.TaskRuleLocations[0]?.location.city +
                                    ")"}
                            </CustomizedTableCell>
                            <CustomizedTableCell align="center">
                              {/* {companyData && companyData.dateFormat
                                ? handleDisplayDate(row?.updatedAt)
                                : moment(row?.updatedAt).format("MMM DD YYYY")} */}
                              {/* {handleDisplayDate(row?.updatedAt)} */}
                              {formatDate(row?.updatedAt)}
                            </CustomizedTableCell>
                            <CustomizedTableCell align="center">
                              {/* {companyData && companyData.dateFormat
                                ? handleDisplayDate(row?.createdAt)
                                : moment(row?.createdAt).format("MMM DD YYYY")} */}
                              {/* {handleDisplayDate(row?.createdAt)} */}
                              {formatDate(row?.createdAt)}
                            </CustomizedTableCell>
                            <CustomizedTableCell align="center">
                              {viewPermission && editPermission ? (
                                <BootstrapTooltip title={t("Edit_Rule")}>
                                  <IconButton
                                    style={{ height: "16px" }}
                                    aria-label="edit"
                                    // size="large"
                                    onClick={() =>
                                      navigate(
                                        `/user/task-rules/add-rule/${row?.id}`
                                      )
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                </BootstrapTooltip>
                              ) : (
                                <BootstrapTooltip
                                  title={t("authorized_access")}
                                >
                                  <span>
                                    <IconButton variant="outlined" disabled>
                                      <Edit disabled />
                                    </IconButton>
                                  </span>
                                </BootstrapTooltip>
                              )}

                              <IconButton
                                onClick={(e) => handleClick(e, row?.id)}
                                variant="outlined"
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  "aria-labelledby": "long-button",
                                }}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                {viewPermission && deletePermission ? (
                                  <MenuItem
                                    onClick={() => {
                                      closeMenu();
                                      handleOpenDeleteModel();
                                      // handleOpenModal(row?.id);
                                    }}
                                  >
                                    <DeleteIcon
                                      sx={{
                                        color: "#545353",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    <Typography
                                      aria-label="delete"
                                      size="large"
                                      onClick={(e) => {
                                        setOpen(true);
                                      }}
                                    >
                                      {t("Delete")}
                                    </Typography>
                                  </MenuItem>
                                ) : (
                                  <BootstrapTooltip
                                    title={t("authorized_access")}
                                  >
                                    <span>
                                      <MenuItem disabled>
                                        <DeleteIcon
                                          disabled
                                          sx={{
                                            color: "#545353",
                                            paddingRight: "5px",
                                          }}
                                        />
                                        <Typography
                                          aria-label="delete"
                                          size="large"
                                        >
                                          {t("Delete")}
                                        </Typography>
                                      </MenuItem>
                                    </span>
                                  </BootstrapTooltip>
                                )}
                              </Menu>
                            </CustomizedTableCell>
                          </CustomizedTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={taskRules?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                taskRules === null && (
                  <>
                    {/* <NotFound text={t("No_Rule_Found")} /> */}
                    <Box className={classes.locationbox}>
                      <LocationNotFound
                        text={t("No_Rules_Added")}
                        subtext={t("Please_add_a_rule")}
                      />

                      <CommonButton
                        onSubmit={AddRuleNavigateButton}
                        label={t("Add_Rule")}
                        disabled={addPermission === false ? true : false}
                      />
                    </Box>
                  </>
                )
              )}
              {/* </Paper> */}
            </>
          ) : (
            allLocation === null && (
              <Box className={classes.locationbox}>
                <NoLocationFound
                  text={t("No_Location_Added")}
                  subtext={t("add_location_text")}
                />
              </Box>
            )
          )}
        </>
      )}
    </>
  );
};

export default Index;
