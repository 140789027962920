import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Dialog,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  MenuItem,
  Menu,
  Select,
} from "@mui/material";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import useAuth from "../../../../hooks/useAuth";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import Loader from "../../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import TitleHeader from "../../../../components/CustomComponents/title";
import NotFound from "../../../../components/NotFound/NotFound";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  searchIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  LinkStyle: {
    textDecoration: "none",
  },
}));

const AccountsOverView = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const { signOut } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [openAcceptModel, setOpenAcceptModel] = useState(false);
  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [filterWithCountry, setFilterWithCountry] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageFirstLoad, setPageFirstLoad] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    getAllCustomer();
    setPageFirstLoad(1);
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (pageFirstLoad > 0) {
      const delayDebounceFn = setTimeout(() => {
        getAllCustomer();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [filterWithName, page, rowsPerPage, filterWithCountry]);

  const headCells = [
    { id: "id", alignment: "left", label: t("AccountTableID") },
    { id: "name", alignment: "left", label: t("CompanyyName") },
    {
      id: "country",
      alignment: "left",
      label: t("country"),
    },

    { id: "approved", alignment: "left", label: t("Status") },
    { id: "state", alignment: "left", label: t("State") },
    { id: "actions", alignment: "left", label: t("Actions") },
  ];

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getAllCustomer = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `/company?filters[name][$contains]=${filterWithName}&limit=${rowsPerPage}&page=${
          page + 1
        }&filters[companyApproval][$equals]=false${
          filterWithCountry !== ""
            ? "&filters[country][$contains]=" + filterWithCountry
            : ""
        }`
      );
      if (res.status === 200) {
        setAllCompanies(res.data.data);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const handleAcceptAccount = async () => {
    try {
      const res = await api.patch(
        `/admin/company-approval/${selectedRow?.id}`,
        {
          companyApproval: true,
          state: "active",
        }
      );
      if (res.status === 200) {
        setIsLoading(false);
        handleCloseAcceptModel();
        toast.success(res?.data?.message);
        //  navigate(`/admin/accounts/${id}`);
        getAllCustomer();
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseAcceptModel();
    }
  };
  const handleRejectAccount = async () => {
    try {
      const res = await api.patch(
        `/admin/company-approval/${selectedRow?.id}`,
        {
          companyApproval: false,
          state: "rejected",
        }
      );
      if (res.status === 200) {
        setIsLoading(false);
        handleCloseRejectModel();
        toast.success(res?.data?.message);
        //  navigate(`/admin/accounts/${id}`);
        getAllCustomer();
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseRejectModel();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };
  const filterByCountry = (e) => {
    setFilterWithCountry(e.target.value);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    // setSelectedRow(null);
  };
  const handleOpenAcceptModel = () => {
    setOpenAcceptModel(true);
  };
  const handleCloseAcceptModel = () => {
    setOpenAcceptModel(false);
  };
  const handleOpenRejectModel = () => {
    setOpenRejectModel(true);
  };
  const handleCloseRejectModel = () => {
    setOpenRejectModel(false);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleClick = (event, selectedData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(selectedData);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allCompanies?.count - page * rowsPerPage);
  {
  }
  return (
    <>
      <TitleHeader
        showButton={false}
        title={t("Accounts_Approval")}
        //subHeading={t("ViewCustomers")}
      />

      <Grid container spacing={1}>
        <Grid item xs={6} sm={7} md={7} lg={7}>
          <SearchField
            filterByName={filterByName}
            onSubmit={getAllCustomer}
            placeholder={t("Filter_Accounts")}
            filterWithName={filterWithName}
          />
        </Grid>
        <Grid item xs={4} sm={3.5} md={3.5} lg={3.5}>
          <SearchField
            filterByName={filterByCountry}
            onSubmit={getAllCustomer}
            placeholder={t("Filter_Accounts_country")}
            filterWithName={filterWithCountry}
          />
        </Grid>
        <Grid item xs={2} sm={1.5} md={1.5} lg={1.5}>
          <CommonButton
            alignment={true}
            onSubmit={getAllCustomer}
            label={t("Search_Account")}
            leftMargin={true}
          />
        </Grid>
      </Grid>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {allCompanies?.results?.length > 0 ? (
            <>
              <TableContainer component={Paper} sx={{ marginTop: "2rem" }}>
                <Table aria-label="simple table">
                  <CustomizedTableHead>
                    <CustomizedTableRow>
                      {headCells.map((headCell) => (
                        <CustomizedTableCell
                          key={headCell.id}
                          align={headCell.alignment}
                          padding={headCell.disablePadding ? "none" : "normal"}
                        >
                          {headCell.label}
                        </CustomizedTableCell>
                      ))}
                    </CustomizedTableRow>
                  </CustomizedTableHead>

                  <TableBody>
                    {allCompanies?.results &&
                      allCompanies?.results?.length > 0 &&
                      allCompanies?.results?.map((row, index) => {
                        return (
                          <TableRow tabIndex={-1} key={`${row.id}-${index}`}>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.id}
                              {/*    */}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              sx={{ cursor: "pointer" }}
                              align="left"
                            >
                              <Typography
                                sx={{
                                  textTransform: "capitalize",
                                  textDecoration: "none",
                                }}
                              >
                                {row?.name}
                              </Typography>
                            </CustomizedTableCell>

                            <CustomizedTableCell align="left">
                              {row?.country}
                            </CustomizedTableCell>
                            {/*    */}

                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.companyApproval === true ? (
                                <Typography>{t("approved")}</Typography>
                              ) : (
                                <Typography>{t("Not_Approved")}</Typography>
                              )}
                            </CustomizedTableCell>

                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.state === "churn"
                                ? t("Churned_text")
                                : row?.state === "active"
                                ? t("Active")
                                : row?.state === "rejected"
                                ? t("Rejected")
                                : ""}
                            </CustomizedTableCell>

                            <CustomizedTableCell padding="none" align="left">
                              <Box mr={2}>
                                <IconButton
                                  aria-label="edit"
                                  size="large"
                                  onClick={(e) => handleClick(e, row)}
                                >
                                  <MoreVertIcon sx={{ color: "#757575" }} />
                                </IconButton>

                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      closeMenu();
                                      handleOpenAcceptModel();
                                    }}
                                  >
                                    <Typography>{t("approve")}</Typography>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      closeMenu();
                                      handleOpenRejectModel();
                                    }}
                                  >
                                    <Typography>{t("Reject")}</Typography>
                                  </MenuItem>
                                </Menu>
                              </Box>
                            </CustomizedTableCell>
                          </TableRow>
                        );
                      })}

                    {emptyRows > 0 && (
                      <TableRow>{/* <TableCell colSpan={8} /> */}</TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allCompanies?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <NotFound text="No Account Found" />
          )}
        </>
      )}

      {openAcceptModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openAcceptModel}
          onClose={handleCloseAcceptModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Approval_title")}
            description={t("accept_des")}
            onConfirm={() => handleAcceptAccount()}
            onCancel={handleCloseAcceptModel}
          />
        </Dialog>
      )}
      {openRejectModel && (
        <Dialog
          open={openRejectModel}
          onClose={handleCloseRejectModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Reject_title_account")}
            description={t("reject_des")}
            onConfirm={() => handleRejectAccount()}
            onCancel={handleCloseRejectModel}
          />
        </Dialog>
      )}
    </>
  );
};

export default AccountsOverView;
