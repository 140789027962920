import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  Button,
  Tooltip,
} from "@mui/material";
import Add from "../../../../../../../../assets/images/AddIcon.svg";
import Close from "../../../../../../../../assets/images/CloseIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { toast } from "react-toastify";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#1B2430",
    backgroundColor: "#f5f5f5",

    "& .MuiPickersLayout-root-fgPIAm ": {
      backgroundColor: "#f5f5f5",
    },
    "& .MuiPickersLayout-root": {
      backgroundColor: " #f5f5f5",
    },
    "& .MuiList-root": {
      width: "75px",
      textAlign: "-webkit-center",
    },

    "& .MuiList-root-fLOhBn": {
      width: "75px",
      textAlign: "-webkit-center",
    },
    "& .MuiDialogActions-root": {
      display: "none",
    },
  },
}));

const Hours = ({
  itemIndex,
  periodsIndex,
  item,
  handleChangeCloseTime,
  handleChangeOpenTime,
  handleChangeOpen,
  open,
  filteredPeriodsArr,
  setSelectedData,
  selectedData,
  getLocationDetails,
  periods,
  handleAddHours,
  handleRemoveHours,
  openTimesError,
  setOpenTimesError,
  setCloseTimesError,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openTimeValue, setOpenTimeValue] = useState("");
  const [closeTimeValue, setCloseTimeValue] = useState("");
  useEffect(() => {
    let OpenHoursValue;

    periods?.mergedTimes?.length > 0 &&
      periods?.mergedTimes?.map((item) => {
        if (
          item?.openHours?.hours === NaN &&
          item?.openHours?.minutes === NaN
        ) {
          setOpenTimesError(true);
        } else {
          setOpenTimesError(false);
        }
        if (item?.openHours !== undefined) {
          if (item?.openHours?.hours >= 10 || item?.openHours?.minutes >= 10) {
            if (item?.openHours?.hours === 24) {
              OpenHoursValue = "00:00";
            } else {
              OpenHoursValue =
                (item?.openHours?.hours && item?.openHours?.hours !== null
                  ? item?.openHours?.hours?.toString()
                  : "0") +
                ":" +
                (item?.openHours?.minutes
                  ? item?.openHours?.minutes?.toString() + ":" + "00"
                  : "00");
            }
          } else {
            OpenHoursValue =
              "0" +
              (item?.openHours?.hours && item?.openHours?.hours !== null
                ? item?.openHours?.hours?.toString()
                : "0") +
              ":" +
              (item?.openHours?.minutes && item?.openHours?.minutes !== null
                ? item?.openHours?.minutes?.toString() + ":" + "00"
                : "00");
          }
        } else {
          OpenHoursValue = "00:00";
        }

        const finalOpenValue = "Wed Jul 16 1997" + " " + OpenHoursValue; /* +
          " " +
          "GMT+0500 (Pakistan Standard Time)" */
        setOpenTimeValue(finalOpenValue);
        item["openTimesValueMerged"] = finalOpenValue;
      });
  }, [filteredPeriodsArr, selectedData]);
  useEffect(() => {
    let closeHoursValue;

    periods?.mergedTimes?.length > 0 &&
      periods?.mergedTimes?.map((item) => {
        if (
          item?.closedHours?.hours >= 10 ||
          item?.closedHours?.minutes >= 10
        ) {
          if (item?.closedHours?.hours === 24) {
            closeHoursValue = "00:00";
          } else {
            closeHoursValue =
              (item?.closedHours?.hours && item?.closedHours?.hours !== null
                ? item?.closedHours?.hours.toString()
                : "0") +
              ":" +
              (item?.closedHours?.minutes && item?.closedHours?.minutes !== null
                ? item?.closedHours?.minutes?.toString() + ":" + "00"
                : "00");
          }
        } else {
          closeHoursValue =
            "0" +
            (item?.closedHours?.hours && item?.closedHours?.hours !== null
              ? item?.closedHours?.hours.toString()
              : "0") +
            ":" +
            +(item?.closedHours?.minutes
              ? item?.closedHours?.minutes?.toString() + ":" + "00"
              : "00");
        }

        const finalCloseValue = "Wed Jul 16 1997" + " " + closeHoursValue; /* +
          " " +
          "GMT+0500 (Pakistan Standard Time)" */
        setCloseTimeValue(finalCloseValue);
        item["closeTimesValueMerged"] = finalCloseValue;
      });
  }, [filteredPeriodsArr, selectedData]);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              value={dayjs(item?.openTimesValueMerged)}
              ampm={false}
              classes={
                {
                  //  root: classes.root,
                }
              }
              onChange={(e) => {
                handleChangeOpenTime(e, itemIndex, item, periodsIndex);
                setSelectedData(periods);
                if (e === null) {
                  setOpenTimesError(true);
                } else {
                  setOpenTimesError(false);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <style>
              {`
           .MuiDialogActions-root {
            display: none;
          }
         
        `}
            </style>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <RemoveIcon sx={{ marginLeft: "7px", marginTop: "15px" }} />
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              // label="Time"
              // style={{ width: "100%" }}
              value={dayjs(item?.closeTimesValueMerged)}
              // disablePast
              // use12Hours
              ampm={false}
              classes={
                {
                  // root: classes.root,
                }
              }
              onChange={(e) => {
                handleChangeCloseTime(e, itemIndex, item, periodsIndex);
                setSelectedData(periods);
                if (e === null) {
                  setCloseTimesError(true);
                } else {
                  setCloseTimesError(false);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <style>
              {`
           .MuiDialogActions-root {
            display: none;
          }
         
        `}
            </style>
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          display={"flex"}
          alignItems={"center"}
        >
          {itemIndex === 0 ? (
            // <Add
            //   onClick={() => handleAddHours(periodsIndex, itemIndex)}
            //   fontSize="small"
            //   sx={{ marginTop: "10px", cursor: "pointer" }}
            // />
            <>
              <Tooltip title={t("Add_Hours")}>
                <IconButton
                  autoFocus
                  onClick={() => handleAddHours(periodsIndex, itemIndex)}
                  className="delete_button"
                >
                  <AddIcon sx={{ "&:hover ": { color: "#06BDFF" } }} />
                </IconButton>
                {/* <Grid
                  sx={{
                    marginTop: "10px",
                    marginLeft: "2px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => handleAddHours(periodsIndex, itemIndex)}
                >
                  <img 
alt="" src={Add} />
                </Grid> */}
              </Tooltip>
            </>
          ) : (
            <IconButton
              autoFocus
              onClick={() => handleRemoveHours(periodsIndex, itemIndex)}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>

            // <CloseIcon
            //   fontSize="small"
            //   onClick={() => handleRemoveHours(periodsIndex, itemIndex)}
            //   sx={{ marginTop: "10px", cursor: "pointer" }}
            // />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default Hours;
