import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  Box,
  TextField,
  Alert as MuiAlert,
} from "@mui/material";
import "./postAiModel.css";
import CloseIcon from "@mui/icons-material/Close";

import { fontWeight, spacing } from "@mui/system";
import styled from "styled-components/macro";
import { deepLanguages } from "../../../assets/defaultLanguage/deepL";
import { useStyles } from "./Styles/style";

const Alert = styled(MuiAlert)(spacing);

const KeywordAIModal = ({
  title,
  onCancel,
  tags,
  setTags,
  AiContentDescription,
  setAiContentDescription,
  setGeneratedContent,
  locationKeywords,
  setLocationKeywords,
  tagsAIRequired,
  contentRequired,
  setContentRequired,
  displayError,
  locationDetailsData,
}) => {
  const classes = useStyles();
  const [language, setLanguage] = useState([]);
  useEffect(() => {
    const defaultLanguage = deepLanguages.find(
      (item) =>
        item?.code ===
        locationDetailsData?.result?.google?.storefrontAddress?.languageCode
    );
    setLanguage(defaultLanguage);
  }, [locationDetailsData]);
  useEffect(() => {
    setAiContentDescription(
      "Generate a list of 15-20 comma separated without double quotes keywords for the following local business, based on the information provided. These keywords will be used for SEO purposes. Business category: {Primary-Category}. Local language: {User-language}"
    );
    setGeneratedContent("");
    setTags([]);
  }, [language]);

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([...tags, value]);
    e.target.value = "";
  };
  const { t } = useTranslation();
  const removeTag = (index) => {
    setLocationKeywords(locationKeywords.filter((el, i) => i !== index));
  };

  return (
    <>
      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Box sx={{ display: "flex" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>{title}</Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={onCancel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Divider />

      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Grid className={classes.mainKeyWordContainer}>
            <Typography className={classes.labelText}>
              {t("What_Would_Like")}
            </Typography>

            <TextField
              rows={7}
              maxRows={7}
              fullWidth
              multiline
              style={{
                minHeight: "124px",
              }}
              onChange={(e) => {
                setAiContentDescription(e.target.value);
                if (e.target.value?.length > 0) {
                  setContentRequired(false);
                } else {
                  setContentRequired(true);
                }
              }}
              error={contentRequired}
              helperText={contentRequired && "Content is required"}
              placeholder={t("What_Would_Like_Placeholder")}
              value={AiContentDescription}
              name="Description"
            />
            {displayError && (
              <Alert mt={2} mb={3} severity="error">
                {t("Ai_Error_Text")}
              </Alert>
            )}
          </Grid>

          <Box sx={{ paddingTop: "8px" }}>
            <Typography className={classes.labelText}>
              {t("AI_Generated_Keywords")}
            </Typography>
            <Box>
              <Grid
                className={
                  tagsAIRequired === false
                    ? "tags-input-container"
                    : "tags-input-container-error"
                }
                sx={{ maxHeight: "210px", overflowY: "scroll" }}
              >
                {locationKeywords?.length > 0 &&
                  locationKeywords?.map((tags, index) => (
                    <div className={classes.tagItemnew} key={index}>
                      <Box className={classes.keywordBoxx}>
                        <Typography className={classes.keywordTypo}>
                          {tags}
                        </Typography>

                        <CloseIcon
                          className={classes.keywordIcon}
                          onClick={() => removeTag(index)}
                        />
                      </Box>
                    </div>
                  ))}
                <input type="text" className="tags-input" />
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default KeywordAIModal;
