import React, { useState, useEffect } from "react";
import {
  Grid,
  TablePagination,
  Typography,
  Tooltip,
  tooltipClasses,
  Box,
  Card,
} from "@mui/material";
import styled from "styled-components/macro";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CollapsibleTable from "./Components/CollapsibleTable";
import { useTranslation } from "react-i18next";
import data from "./data.json";
import Loader from "../../../../components/Loaders/Loader";
import NotFound from "../../../../components/NotFound/NotFound";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useStyles } from "../Styles/style";
import ReconnectCard from "../../../../components/CustomComponents/Cards/ReconnectCard";
import { channelNames } from "../../../../assets/channelList";
import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import { useNavigate } from "react-router";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const Index = ({
  channelsLoading,
  location,
  channelData,
  locationDetailsData,
  handleSendUberAllRequest,
  uberAllLoading,
  setLocation,
  googleConnect,
  handleOpenGoogleModel,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  locationEditPermission,
  locationDeletePermission,
  locationViewPermission,
  locationAddPermission,
  defaultValueLocation,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [loading, setLoading] = React.useState(false);
  const [filterWithName, setFilterWithName] = useState("");
  const [facebookConnect, setFacebookConnected] = useState(false);
  const [displayFacebookIcon, setFacebookIcon] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    location?.socialLink?.map((item) => {
      if (item?.type === "facebook") {
        setFacebookConnected(true);
      }
    });
  }, []);

  async function fetchURL(id, locationId, fromReconnectWithValidToken) {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        if (fromReconnectWithValidToken === true) {
          window.location.href = res?.data.data + "&state=reconnect";
        } else {
          window.location.href = res?.data.data /* + "&state=" + id */;
        }
      }
    } catch (error) {}

    // if (response?.data?.url) {
    //   window.location.href = response.data.url;
    // }
  }
  const handleConnectWithGoogle = (fromReconnect) => {
    fetchURL(
      `${defaultValueLocation?.id}/${defaultValueLocation?.placeId}`,
      defaultValueLocation?.id,
      fromReconnect
    );
  };

  const handleNavigation = (data) => {
    navigate(`/user/token-expire/location/${data?.id}`);
  };

  const handleFacebookConnect = async (data) => {
    try {
      const res = await api.get(
        `facebookConnect/auth-url?locationId=${data?.id}`
      );

      if (res.data?.status === true) {
        window.location.href = res?.data.data;
      }
    } catch (error) {}
  };

  return (
    <>
      {location !== null &&
        googleConnect !== null &&
        googleConnect?.validToken === false &&
        locationDetailsData !== null && (
          <Box sx={{ marginTop: "8px" }}>
            <ReconnectCard
              location={location}
              displayReconnectButton={true}
              addPermission={locationAddPermission}
              editPermission={locationEditPermission}
              deletePermission={locationDeletePermission}
              viewPermission={locationViewPermission}
            />
          </Box>
        )}

      {channelsLoading ? (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              padding: "18px",
              marginTop: "100px",
              width: "100%",
            }}
          >
            <Loader />
          </Grid>
        </>
      ) : (
        <>
          {channelData !== null ? (
            <CollapsibleTable
              channelsLoading={channelsLoading}
              locationDetailsData={locationDetailsData}
              channelData={channelData}
              location={location}
              facebookConnect={facebookConnect}
              googleConnect={googleConnect}
              handleConnectWithGoogle={handleConnectWithGoogle}
              handleNavigation={handleNavigation}
              handleFacebookConnect={handleFacebookConnect}
              channelNames={channelNames}
            />
          ) : (
            <NotFound text={t("No_Data_Found")} />
          )}
        </>
      )}

      {(location?.enableUberAll === "pending" ||
        location?.enableUberAll === "rejected") && (
        <Card variant="outlined" sx={{ padding: "15px", marginTop: "15px" }}>
          <Grid
            container
            sx={{
              //  height: "350px",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
              margin: "auto",
            }}
          >
            <Grid xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.uberAllHeadingText}>
                {t("uberall_heading")}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.uberAllSubHeadingText}>
                {t("uberAll_subheading_text_two")}
              </Typography>
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                paddingBottom: "15px",
              }}
            >
              {addPermission && viewPermission ? (
                <CommonButton
                  onSubmit={
                    googleConnect === null
                      ? handleOpenGoogleModel
                      : handleSendUberAllRequest
                  }
                  label={
                    location?.enableUberAll === "requested"
                      ? t("uberAll_button_requested")
                      : t("uberAll_button_request")
                  }
                  isLoading={uberAllLoading}
                  disabled={
                    location?.enableUberAll === "requested" ||
                    location?.enableUberAll === "error"
                  }
                />
              ) : (
                <BootstrapTooltip title={t("authorized_access")}>
                  <span>
                    <CommonButton
                      onSubmit={
                        googleConnect === null
                          ? handleOpenGoogleModel
                          : handleSendUberAllRequest
                      }
                      label={
                        location?.enableUberAll === "requested"
                          ? t("uberAll_button_requested")
                          : t("uberAll_button_request")
                      }
                      isLoading={uberAllLoading}
                      disabled={true}
                    />
                  </span>
                </BootstrapTooltip>
              )}
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default Index;
