import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
// import { Legend, CartesianGrid, XAxis, YAxis, LineChart, Line  } from 'recharts';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, Checkbox, Typography, Box, Divider } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "../../UserListing/Styles/style";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfoIcon from "@mui/icons-material/Info";
import BouncingDotsLoader from "../../../../components/Loaders/smallBounceLoader";
import UserViews from "./UserViews";
import UserAction from "./UserActions";
import BuisnessConversions from "./BuisnessConversions";
import SearchesBreakdown from "./SearchesBreakdown";
import useDateFormat from "../../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CustomGoogleSearchCheckbox = withStyles({
  root: {
    color: "#0638C1",
    padding: "0px",
    "&$checked": {
      color: "#0638C1",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const CustomDirectionCheckbox = withStyles({
  root: {
    color: "#F86F35",
    padding: "0px",
    "&$checked": {
      color: "#F86F35",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Index({
  location,
  displayBox,
  graphData,

  googleInsightData,
  ratingLoading,

  selectedChartTab,
  setSelectedChartTab,
  metricsLoading,
  searchesBreakdownData,
  searchesBreakdownLoading,
  graphSearchesKeywordData,
  graphSearchKeywordsLoading,
  setSearchesBreakdownData,
  setGraphSearchesKeywordData,
  setGoogleInsightData,
  startDate,
  endDate,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [label, setLabel] = useState([]);
  const [desktopMapDataSet, setDesktopMapDataSet] = useState([]);
  const [mobileMapDataSet, setMobileMapDataSet] = useState([]);
  const [desktopSearchDataSet, setDesktopSearchDataSet] = useState([]);
  const [mobileSearchDataSet, setMobileSearchDataSet] = useState([]);
  const [websiteDataSet, setWebsiteDataSet] = useState([]);
  const [directionDataSet, setDirectionDataSet] = useState([]);
  const [buisnessConversationsDataSet, setBuisnessConversationsDataSet] =
    useState([]);
  const [buisnessBookingsDataSet, setBuisnessBookingsDataSet] = useState([]);
  const [buisnessFoodOrderDataSet, setBuisnessFoodOrderDataSet] = useState([]);
  const [totalValue, setTotalValue] = useState(null);
  const [totalValueBuisness, setTotalValueBuisness] = useState(null);
  const [totalValueConversion, setTotalValueConversion] = useState(null);
  const [totalKeywordsValue, setTotalKeywordsValue] = useState(null);
  const [keywordsLabel, setKeywordsLabel] = useState([]);
  const [keywordsLabel2, setKeywordsLabel2] = useState([]);
  const [brandedKeywordDataSet, setBrandedKeywordDataSet] = useState();
  const [discoveryKeywordsDataSet, setDiscoveryKeywordsDataSet] = useState([]);
  const [totalKeywordsCount, setTotalKeywordsCount] = useState(null);
  const [callDataSet, setCallDataSet] = useState([]);
  const [mobileMap, setMobileMap] = useState("positive");
  const [desktopMap, setDesktopMap] = useState("positive");
  const [mobileSearches, setMobileSearches] = useState("positive");
  const [desktopSearches, setDesktopSearches] = useState("positive");
  const [requestDirections, setRequestDirections] = useState("positive");
  const [visit, setVisit] = useState("positive");
  const [call, setCall] = useState("positive");
  const [branded, setBranded] = useState("positive");
  const [discovery, setDiscovery] = useState("positive");
  const [businessData, setBusinessData] = useState(null);
  const [foodOrderData, setFoodOderData] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [allLables, setAllLabels] = useState([]);
  const [tooltipContent, setTooltipContent] = useState("");
  const { formatDate, format } = useDateFormat();

  const handleChange = (event, newValue) => {
    setSelectedChartTab(newValue);
  };
  const randomData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  useEffect(() => {
    if (graphData !== null && graphData?.length > 0) {
      handleLabels();
      handleDesktopMapDataSet();
      handleMobileMapDataSet();
      handleDesktopSearchDataSet();
      handleMobileSearchDataSet();
      handleWebsiteDataSet();
      handleDirectionDataSet();
      handleCallDataSet();
      handleBuisnessConversationsDataSet();
      handleBuisnessBookingsDataSet();
      handleBuisnessFoodOrdersDataSet();
    }
  }, [graphData]);

  useEffect(() => {
    if (graphSearchesKeywordData !== null) {
      handleKeywordsLabels();
      handleBrandedKeywordsDataSet();
      //  handleKeywordsLabels2();
      handleDiscoveryKeywordsDataSet();
    }
  }, [graphSearchesKeywordData]);

  const handleLabels = () => {
    let labelArray = [];
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let combinedArray = [];
    graphData?.map((item) => {
      item?.data?.map((itemTwo) => {
        combinedArray?.push(itemTwo?.dateOfAverage);
      });
    });

    const uniqueArray = [...new Set(combinedArray)];

    const sortedData = uniqueArray.sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA - dateB;
    });
    setAllLabels(sortedData);
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const nextStartDate = start.subtract(1, "year");
    const nextEndDate = end.subtract(1, "year");
    const durationInMonths = end.diff(start, "days");

    console.log(nextStartDate, "nextStartDate");
    console.log(nextEndDate, "nextEndDate");
    console.log(formatDate(nextStartDate), "formatDate(nextStartDate)");

    setTooltipContent(
      `vs ${dayjs(nextStartDate).format(format)} - ${dayjs(nextEndDate).format(
        format
      )}`
    );

    sortedData?.map((item) => {
      if (durationInMonths <= 30) {
        const [year, month, day] = item.split("-");
        const date = new Date(`${year}-${month}-${day}`);
        if (!isNaN(date.getTime())) {
          const monthName = date.toLocaleString("default", {
            month: "short",
          });
          const formattedDate = `${day} ${monthName} ${year
            .split("-")[0]
            .slice(2)}`;
          labelArray?.push(formattedDate);
        }
      } else {
        const parts = item?.split("-");
        if (parts.length === 2) {
          const monthIndex = parseInt(parts[1]) - 1;
          const monthName = monthNames[monthIndex];
          const year = parts[0].substring(2);
          const formattedDate = `${monthName} ${year}`;
          labelArray?.push(formattedDate);
        }
      }
    });

    setLabel([...labelArray]);
  };
  const handleKeywordsLabels = () => {
    if (graphSearchesKeywordData !== null) {
      let labelArray = [];
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let combinedArray = [];
      graphSearchesKeywordData?.brandedKeywords.map((item) => {
        combinedArray.push(item?.dateOfAverage);
      });
      graphSearchesKeywordData?.discoveryKeywords.map((item) => {
        combinedArray.push(item?.dateOfAverage);
      });
      const sortedData = combinedArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });

      sortedData?.map((item) => {
        const parts = item?.split("-");
        if (parts?.length === 2) {
          const monthIndex = parseInt(parts[1]) - 1;
          const monthName = monthNames[monthIndex];
          const year = parts[0].substring(2);
          const formattedDate = `${monthName} ${year}`;
          labelArray?.push(formattedDate);
        }
      });

      const uniqueArray = [...new Set(labelArray)];
      setKeywordsLabel([...uniqueArray]);
    }
  };
  const handleBrandedKeywordsDataSet = () => {
    if (graphSearchesKeywordData !== null) {
      // const results = [];
      let combinedArray = [];
      graphSearchesKeywordData?.brandedKeywords.map((item) => {
        combinedArray.push(item?.dateOfAverage);
      });
      graphSearchesKeywordData?.discoveryKeywords.map((item) => {
        combinedArray.push(item?.dateOfAverage);
      });
      const uniqueArray = [...new Set(combinedArray)];
      const sortedData = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });
      const results = sortedData?.map((date) => {
        // Find the corresponding item in graphSearchesKeywordData.brandedKeywords
        const matchingItem = graphSearchesKeywordData?.brandedKeywords?.find(
          (item) => item?.dateOfAverage === date
        );

        // Check if a matching item is found
        if (matchingItem) {
          // Perform calculations based on your logic
          if (matchingItem.hasOwnProperty("lastMetricValue")) {
            return (
              (matchingItem.lastMetricValue !== null
                ? matchingItem.lastMetricValue
                : 0) +
              (matchingItem.metricValue !== null ? matchingItem.metricValue : 0)
            );
          } else {
            return matchingItem.metricValue || 0;
          }
        } else {
          // If no matching item is found, add 0
          return 0;
        }
      });

      setBrandedKeywordDataSet([...results]);
    }
  };

  const handleKeywordsLabels2 = () => {
    if (graphSearchesKeywordData !== null) {
      let labelArray = [];
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const sortedData = graphSearchesKeywordData?.discoveryKeywords.sort(
        (a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        }
      );
      sortedData?.map((item) => {
        const parts = item?.dateOfAverage.split("-");
        if (parts.length === 2) {
          const monthIndex = parseInt(parts[1]) - 1;
          const monthName = monthNames[monthIndex];
          const year = parts[0].substring(2);
          const formattedDate = `${monthName} ${year}`;
          labelArray?.push(formattedDate);
        }
      });
      setKeywordsLabel2([...labelArray]);
    }
  };

  const handleDiscoveryKeywordsDataSet = () => {
    if (graphSearchesKeywordData !== null) {
      let combinedArray = [];
      graphSearchesKeywordData?.brandedKeywords.map((item) => {
        combinedArray.push(item?.dateOfAverage);
      });
      graphSearchesKeywordData?.discoveryKeywords.map((item) => {
        combinedArray.push(item?.dateOfAverage);
      });
      const uniqueArray = [...new Set(combinedArray)];
      const sortedData = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });
      const results = sortedData?.map((date) => {
        // Find the corresponding item in graphSearchesKeywordData.brandedKeywords
        const matchingItem = graphSearchesKeywordData?.discoveryKeywords?.find(
          (item) => item?.dateOfAverage === date
        );

        // Check if a matching item is found
        if (matchingItem) {
          // Perform calculations based on your logic
          if (matchingItem.hasOwnProperty("lastMetricValue")) {
            return (
              (matchingItem.lastMetricValue !== null
                ? matchingItem.lastMetricValue
                : 0) +
              (matchingItem.metricValue !== null ? matchingItem.metricValue : 0)
            );
          } else {
            return matchingItem.metricValue || 0;
          }
        } else {
          // If no matching item is found, add 0
          return 0;
        }
      });

      setDiscoveryKeywordsDataSet([...results]);
    }
  };

  const handleDesktopMapDataSet = () => {
    if (graphData?.length > 0 && graphData !== null) {
      const desktopMapData = graphData?.find(
        (item) => item?.key === "BUSINESS_IMPRESSIONS_DESKTOP_MAPS"
      );
      let combinedArray = [];
      graphData?.map((item) => {
        item?.data?.map((itemTwo) => {
          combinedArray?.push(itemTwo?.dateOfAverage);
        });
      });

      const uniqueArray = [...new Set(combinedArray)];

      const sortedDataLabels = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });

      if (desktopMapData !== undefined) {
        const sortedData = desktopMapData.data.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
        const results = sortedDataLabels?.map((date) => {
          const matchingItem = sortedData?.find(
            (item) => item?.dateOfAverage === date
          );
          if (matchingItem) {
            return matchingItem.metricValue || 0;
          } else {
            return 0;
          }
        });
        setDesktopMapDataSet([...results]);
      }
    }
  };
  const handleMobileMapDataSet = () => {
    if (graphData?.length > 0 && graphData !== null) {
      const mobileMapData = graphData?.find(
        (item) => item?.key === "BUSINESS_IMPRESSIONS_MOBILE_MAPS"
      );
      let combinedArray = [];
      graphData?.map((item) => {
        item?.data?.map((itemTwo) => {
          combinedArray?.push(itemTwo?.dateOfAverage);
        });
      });

      const uniqueArray = [...new Set(combinedArray)];

      const sortedDataLabels = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });

      if (mobileMapData !== undefined) {
        const sortedData = mobileMapData.data.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
        const results = sortedDataLabels?.map((date) => {
          const matchingItem = sortedData?.find(
            (item) => item?.dateOfAverage === date
          );
          if (matchingItem) {
            return matchingItem.metricValue || 0;
          } else {
            return 0;
          }
        });
        setMobileMapDataSet([...results]);
      }
    }
  };
  const handleDesktopSearchDataSet = () => {
    if (graphData?.length > 0 && graphData !== null) {
      const desktopSearchData = graphData?.find(
        (item) => item?.key === "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH"
      );
      let combinedArray = [];
      graphData?.map((item) => {
        item?.data?.map((itemTwo) => {
          combinedArray?.push(itemTwo?.dateOfAverage);
        });
      });

      const uniqueArray = [...new Set(combinedArray)];

      const sortedDataLabels = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });

      if (desktopSearchData !== undefined) {
        const sortedData = desktopSearchData.data.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
        const results = sortedDataLabels?.map((date) => {
          const matchingItem = sortedData?.find(
            (item) => item?.dateOfAverage === date
          );
          if (matchingItem) {
            return matchingItem.metricValue || 0;
          } else {
            return 0;
          }
        });
        setDesktopSearchDataSet([...results]);
      }
    }
  };

  const handleBuisnessConversationsDataSet = () => {
    if (graphData?.length > 0 && graphData !== null) {
      const businessConversationData = graphData?.find(
        (item) => item?.key === "BUSINESS_CONVERSATIONS"
      );
      let combinedArray = [];
      graphData?.map((item) => {
        item?.data?.map((itemTwo) => {
          combinedArray?.push(itemTwo?.dateOfAverage);
        });
      });

      const uniqueArray = [...new Set(combinedArray)];

      const sortedDataLabels = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });
      if (businessConversationData !== undefined) {
        const sortedData = businessConversationData.data.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
        const results = sortedDataLabels?.map((date) => {
          const matchingItem = sortedData?.find(
            (item) => item?.dateOfAverage === date
          );
          if (matchingItem) {
            return matchingItem.metricValue || 0;
          } else {
            return 0;
          }
        });
        setBuisnessConversationsDataSet([...results]);
      }
    }
  };
  const handleMobileSearchDataSet = () => {
    if (graphData?.length > 0 && graphData !== null) {
      const mobileSearchData = graphData?.find(
        (item) => item?.key === "BUSINESS_IMPRESSIONS_MOBILE_SEARCH"
      );
      let combinedArray = [];
      graphData?.map((item) => {
        item?.data?.map((itemTwo) => {
          combinedArray?.push(itemTwo?.dateOfAverage);
        });
      });

      const uniqueArray = [...new Set(combinedArray)];

      const sortedDataLabels = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });
      if (mobileSearchData !== undefined) {
        const sortedData = mobileSearchData.data.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
        const results = sortedDataLabels?.map((date) => {
          const matchingItem = sortedData?.find(
            (item) => item?.dateOfAverage === date
          );
          if (matchingItem) {
            return matchingItem.metricValue || 0;
          } else {
            return 0;
          }
        });
        setMobileSearchDataSet([...results]);
      }
    }
  };
  const handleWebsiteDataSet = () => {
    if (graphData?.length > 0 && graphData !== null) {
      const websiteData = graphData?.find(
        (item) => item?.key === "WEBSITE_CLICKS"
      );
      let combinedArray = [];
      graphData?.map((item) => {
        item?.data?.map((itemTwo) => {
          combinedArray?.push(itemTwo?.dateOfAverage);
        });
      });

      const uniqueArray = [...new Set(combinedArray)];

      const sortedDataLabels = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });
      if (websiteData !== undefined) {
        const sortedData = websiteData.data.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
        const results = sortedDataLabels?.map((date) => {
          const matchingItem = sortedData?.find(
            (item) => item?.dateOfAverage === date
          );
          if (matchingItem) {
            return matchingItem.metricValue || 0;
          } else {
            return 0;
          }
        });
        setWebsiteDataSet([...results]);
      }
    }
  };
  const handleDirectionDataSet = () => {
    if (graphData?.length > 0 && graphData !== null) {
      const directionRequestData = graphData?.find(
        (item) => item?.key === "BUSINESS_DIRECTION_REQUESTS"
      );
      let combinedArray = [];
      graphData?.map((item) => {
        item?.data?.map((itemTwo) => {
          combinedArray?.push(itemTwo?.dateOfAverage);
        });
      });

      const uniqueArray = [...new Set(combinedArray)];

      const sortedDataLabels = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });
      if (directionRequestData !== undefined) {
        const sortedData = directionRequestData.data.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
        const results = sortedDataLabels?.map((date) => {
          const matchingItem = sortedData?.find(
            (item) => item?.dateOfAverage === date
          );
          if (matchingItem) {
            return matchingItem.metricValue || 0;
          } else {
            return 0;
          }
        });
        setDirectionDataSet([...results]);
      }
    }
  };
  const handleCallDataSet = () => {
    if (graphData?.length > 0 && graphData !== null) {
      const callData = graphData?.find((item) => item?.key === "CALL_CLICKS");
      let combinedArray = [];
      graphData?.map((item) => {
        item?.data?.map((itemTwo) => {
          combinedArray?.push(itemTwo?.dateOfAverage);
        });
      });

      const uniqueArray = [...new Set(combinedArray)];

      const sortedDataLabels = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });
      if (callData !== undefined) {
        const sortedData = callData.data.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
        const results = sortedDataLabels?.map((date) => {
          const matchingItem = sortedData?.find(
            (item) => item?.dateOfAverage === date
          );
          if (matchingItem) {
            return matchingItem.metricValue || 0;
          } else {
            return 0;
          }
        });
        setCallDataSet([...results]);
      }
    }
  };
  const handleBuisnessBookingsDataSet = () => {
    if (graphData?.length > 0 && graphData !== null) {
      const businessBookingData = graphData?.find(
        (item) => item?.key === "BUSINESS_BOOKINGS"
      );
      let combinedArray = [];
      graphData?.map((item) => {
        item?.data?.map((itemTwo) => {
          combinedArray?.push(itemTwo?.dateOfAverage);
        });
      });

      const uniqueArray = [...new Set(combinedArray)];

      const sortedDataLabels = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });
      if (businessBookingData !== undefined) {
        const sortedData = businessBookingData.data.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
        const results = sortedDataLabels?.map((date) => {
          const matchingItem = sortedData?.find(
            (item) => item?.dateOfAverage === date
          );
          if (matchingItem) {
            return matchingItem.metricValue || 0;
          } else {
            return 0;
          }
        });
        setBuisnessBookingsDataSet([...results]);
      }
    }
  };
  const handleBuisnessFoodOrdersDataSet = () => {
    if (graphData?.length > 0 && graphData !== null) {
      const foodOrderData = graphData?.find(
        (item) => item?.key === "BUSINESS_FOOD_ORDERS"
      );
      let combinedArray = [];
      graphData?.map((item) => {
        item?.data?.map((itemTwo) => {
          combinedArray?.push(itemTwo?.dateOfAverage);
        });
      });

      const uniqueArray = [...new Set(combinedArray)];

      const sortedDataLabels = uniqueArray.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });
      if (foodOrderData !== undefined) {
        const sortedData = foodOrderData.data.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
        const results = sortedDataLabels?.map((date) => {
          const matchingItem = sortedData?.find(
            (item) => item?.dateOfAverage === date
          );
          if (matchingItem) {
            return matchingItem.metricValue || 0;
          } else {
            return 0;
          }
        });
        setBuisnessFoodOrderDataSet([...results]);
      }
    }
  };

  const data = {
    labels:
      label?.length > 0
        ? label
        : [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
    datasets: [
      {
        label: `${t("Mobile_map")}`,
        data: mobileMapDataSet?.length > 0 ? mobileMapDataSet : randomData,
        fill: false,
        // backgroundColor: "",
        borderColor: "#0638C1",
      },
      {
        label: `${t("Mobile_Searches")}`,
        data:
          mobileSearchDataSet?.length > 0 ? mobileSearchDataSet : randomData,
        fill: false,
        // backgroundColor: "",
        borderColor: "#F86F35",
      },
      {
        label: `${t("Desktop_Maps")}`,
        data: desktopMapDataSet?.length > 0 ? desktopMapDataSet : randomData,
        fill: false,
        // backgroundColor: "",
        borderColor: "#06BDFF",
      },
      {
        label: `${t("Desktop_Searches")}`,
        data:
          desktopSearchDataSet?.length > 0 ? desktopSearchDataSet : randomData,
        fill: false,
        // backgroundColor: "",
        borderColor: "#FC3652",
      },
    ],
  };
  const dataSet2 = {
    labels:
      label?.length > 0
        ? label
        : [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
    datasets: [
      {
        label: `${t("Request_directions")}`,
        data: directionDataSet?.length > 0 ? directionDataSet : randomData,
        fill: false,
        // backgroundColor: "",
        borderColor: "#13CF8F",
      },
      {
        label: `${t("Visit_your_website")}`,
        data: websiteDataSet?.length > 0 ? websiteDataSet : randomData,
        fill: false,
        // backgroundColor: "",
        borderColor: "#F8A92B",
      },
      /*  {
        label: `${t("Call_You")}`,
        data: callDataSet?.length > 0 ? callDataSet : randomData,
        fill: false,
        // backgroundColor: "",
        borderColor: "#066DE8",
      }, */
    ],
  };
  const dataSet3 = {
    labels:
      label?.length > 0
        ? label
        : [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
    datasets: [
      {
        label: `${t("Buisness_Conversations")}`,
        data:
          buisnessConversationsDataSet?.length > 0
            ? buisnessConversationsDataSet
            : randomData,
        fill: false,
        // backgroundColor: "",
        borderColor: "#13CF8F",
      },
      {
        label: `${t("Buisness_Bookings")}`,
        data:
          buisnessBookingsDataSet?.length > 0
            ? buisnessBookingsDataSet
            : randomData,
        fill: false,
        // backgroundColor: "",
        borderColor: "#F8A92B",
      },
      {
        label: `${t("Buisness_Food_order")}`,
        data:
          buisnessFoodOrderDataSet?.length > 0
            ? buisnessFoodOrderDataSet
            : randomData,
        fill: false,
        // backgroundColor: "",
        borderColor: "#066DE8",
      },
    ],
  };

  const dataSet4 = {
    labels:
      keywordsLabel?.length > 0
        ? keywordsLabel
        : [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
    datasets: [
      {
        label: `${t("branded_keywords")}`,
        data:
          brandedKeywordDataSet?.length > 0
            ? brandedKeywordDataSet
            : randomData,

        fill: false,
        // backgroundColor: "",
        borderColor: "#13CF8F",
      },
      {
        label: `${t("discovery_keywords")}`,
        data:
          discoveryKeywordsDataSet?.length > 0
            ? discoveryKeywordsDataSet
            : randomData,

        fill: false,
        // backgroundColor: "",
        borderColor: "#F8A92B",
      },

      // {
      //   label: `${t("Visit_your_website")}`,
      //   data: websiteDataSet?.length > 0 ? websiteDataSet : randomData,
      //   fill: false,
      //   // backgroundColor: "",
      //   borderColor: "#F8A92B",
      // },
      // {
      //   label: `${t("Call_You")}`,
      //   data: callDataSet?.length > 0 ? callDataSet : randomData,
      //   fill: false,
      //   // backgroundColor: "",
      //   borderColor: "#066DE8",
      // },
    ],
  };

  function kFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (Math.abs(Number(num)) / 1.0e6).toFixed(2) + "M"; // convert to M for number from > 1 million
    } else if (num < 999) {
      return Math.sign(num) * Math.abs(num); // if value < 1000, nothing to do
    }
  }

  function checkNumber(num, key) {
    let finalNumber = num;
    if (parseInt(num) === 0) {
      if (key === "mobileMap") {
        setMobileMap("negative");
      } else if (key === "desktopMap") {
        setDesktopMap("negative");
      } else if (key === "mobileSearches") {
        setMobileSearches("negative");
      } else if (key === "desktopSearches") {
        setDesktopSearches("negative");
      } else if (key === "requestDirections") {
        setRequestDirections("negative");
      } else if (key === "visit") {
        setVisit("negative");
      } else if (key === "call") {
        setCall("negative");
      } else if (key === "branded") {
        setBranded("negative");
      } else if (key === "discovery") {
        setDiscovery("negative");
      }

      return (finalNumber = 0);
    } else {
      const output = Math.sign(parseInt(num));

      if (output === -1) {
        const splittedNum = num.split("-");
        if (key === "mobileMap") {
          setMobileMap("negative");
        } else if (key === "desktopMap") {
          setDesktopMap("negative");
        } else if (key === "mobileSearches") {
          setMobileSearches("negative");
        } else if (key === "desktopSearches") {
          setDesktopSearches("negative");
        } else if (key === "requestDirections") {
          setRequestDirections("negative");
        } else if (key === "visit") {
          setVisit("negative");
        } else if (key === "call") {
          setCall("negative");
        } else if (key === "branded") {
          setBranded("negative");
        } else if (key === "discovery") {
          setDiscovery("negative");
        }
        finalNumber = splittedNum[1];
      } else if (output === 1) {
        if (key === "mobileMap") {
          setMobileMap("positive");
        } else if (key === "desktopMap") {
          setDesktopMap("positive");
        } else if (key === "mobileSearches") {
          setMobileSearches("positive");
        } else if (key === "desktopSearches") {
          setDesktopSearches("positive");
        } else if (key === "requestDirections") {
          setRequestDirections("positive");
        } else if (key === "visit") {
          setVisit("positive");
        } else if (key === "call") {
          setCall("positive");
        } else if (key === "branded") {
          setBranded("positive");
        } else if (key === "discovery") {
          setDiscovery("positive");
        }
        finalNumber = num;
      } else if (output === NaN) {
        if (key === "mobileMap") {
          setMobileMap("positive");
        } else if (key === "desktopMap") {
          setDesktopMap("positive");
        } else if (key === "mobileSearches") {
          setMobileSearches("positive");
        } else if (key === "desktopSearches") {
          setDesktopSearches("positive");
        } else if (key === "requestDirections") {
          setRequestDirections("positive");
        } else if (key === "visit") {
          setVisit("positive");
        } else if (key === "call") {
          setCall("positive");
        } else if (key === "branded") {
          setBranded("positive");
        } else if (key === "discovery") {
          setDiscovery("positive");
        }
        finalNumber = 0;
      } else if (output === 0) {
        if (key === "mobileMap") {
          setMobileMap("positive");
        } else if (key === "desktopMap") {
          setDesktopMap("positive");
        } else if (key === "mobileSearches") {
          setMobileSearches("positive");
        } else if (key === "desktopSearches") {
          setDesktopSearches("positive");
        } else if (key === "requestDirections") {
          setRequestDirections("positive");
        } else if (key === "visit") {
          setVisit("positive");
        } else if (key === "call") {
          setCall("positive");
        } else if (key === "branded") {
          setBranded("positive");
        } else if (key === "discovery") {
          setDiscovery("positive");
        }
        finalNumber = 0;
      }
    }

    return finalNumber;
  }

  useEffect(() => {
    if (googleInsightData !== null && googleInsightData?.length > 0) {
      const businessDummyData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_CONVERSATIONS"
      );
      const bookingsData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_BOOKINGS"
      );
      const bookingFoodData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_FOOD_ORDERS"
      );
      setBusinessData(businessDummyData);
      setBookingData(bookingsData);
      setFoodOderData(bookingFoodData);
      const relevantMetricTypes = [
        "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
        "BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
        "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
        "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
      ];
      let total = 0;
      googleInsightData.forEach((item) => {
        if (relevantMetricTypes.includes(item.metricType)) {
          total += item.metricValue;
        }
      });
      setTotalValue(total);
    } else {
      setBusinessData(null);
      setBookingData(null);
      setFoodOderData(null);
      setTotalValue(0);
    }
  }, [googleInsightData]);
  useEffect(() => {
    if (googleInsightData !== null && googleInsightData?.length > 0) {
      const relevantMetricTypes = [
        "BUSINESS_DIRECTION_REQUESTS",
        "WEBSITE_CLICKS",
        //  "CALL_CLICKS",
      ];
      let buisnessValue = 0;
      googleInsightData.forEach((item) => {
        if (relevantMetricTypes.includes(item.metricType)) {
          buisnessValue += item.metricValue;
        }
      });
      setTotalValueBuisness(buisnessValue);

      const relevantMetricTypesTwo = [
        "BUSINESS_CONVERSATIONS",
        "BUSINESS_BOOKINGS",
        "BUSINESS_FOOD_ORDERS",
      ];
      let conversionValue = 0;
      googleInsightData.forEach((item) => {
        if (relevantMetricTypesTwo.includes(item.metricType)) {
          conversionValue += item.metricValue;
        }
      });
      setTotalValueConversion(conversionValue);
    } else {
      setTotalValueConversion(0);
      setTotalValueBuisness(0);
    }
  }, [googleInsightData]);

  useEffect(() => {
    if (searchesBreakdownData !== null) {
      const keyWordsValue =
        searchesBreakdownData?.brandedKeywords.length +
        searchesBreakdownData?.discoveryKeywords.length;

      setTotalKeywordsValue(keyWordsValue);
    }
  }, [searchesBreakdownData]);

  useEffect(() => {
    if (searchesBreakdownData !== null) {
      const keyWordsValue =
        searchesBreakdownData?.sumOfBranded +
        searchesBreakdownData?.sumOfDiscovery;

      setTotalKeywordsCount(keyWordsValue);
    }
  }, [searchesBreakdownData]);
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {googleInsightData !== null && (
          <Tabs
            className={classes.Tabs}
            value={selectedChartTab}
            onChange={handleChange}
            variant="scrollable"
          >
            <Tab className={classes.resultsTabs} label={t("User_Views")} />
            <Tab className={classes.resultsTabs} label={t("User_Actions")} />

            {businessData !== null &&
              bookingData !== null &&
              foodOrderData !== null &&
              (businessData?.metricValue > 0 ||
                bookingData?.metricValue > 0 ||
                foodOrderData?.metricValue > 0) && (
                <Tab className={classes.resultsTabs} label={t("Conversion")} />
              )}

            <Tab
              className={classes.resultsTabs}
              label={t("searches_breakdown")}
            />
          </Tabs>
        )}
      </Grid>

      {selectedChartTab === 0 && (
        <UserViews
          googleInsightData={googleInsightData}
          data={data}
          checkNumber={checkNumber}
          ratingLoading={ratingLoading}
          metricsLoading={metricsLoading}
          mobileMap={mobileMap}
          desktopMap={desktopMap}
          mobileSearches={mobileSearches}
          desktopSearches={desktopSearches}
          requestDirections={requestDirections}
          visit={visit}
          call={call}
          kFormatter={kFormatter}
          totalValue={totalValue}
          allLables={allLables}
          tooltipContent={tooltipContent}
        />
      )}
      {selectedChartTab === 1 && (
        <UserAction
          googleInsightData={googleInsightData}
          data={data}
          dataSet2={dataSet2}
          checkNumber={checkNumber}
          ratingLoading={ratingLoading}
          metricsLoading={metricsLoading}
          mobileMap={mobileMap}
          desktopMap={desktopMap}
          mobileSearches={mobileSearches}
          desktopSearches={desktopSearches}
          requestDirections={requestDirections}
          visit={visit}
          call={call}
          kFormatter={kFormatter}
          totalValueBuisness={totalValueBuisness}
          allLables={allLables}
          tooltipContent={tooltipContent}
        />
      )}
      {businessData !== null &&
        bookingData !== null &&
        foodOrderData !== null &&
        (businessData?.metricValue > 0 ||
          bookingData?.metricValue > 0 ||
          foodOrderData?.metricValue > 0) &&
        selectedChartTab === 2 && (
          <BuisnessConversions
            googleInsightData={googleInsightData}
            data={data}
            dataSet3={dataSet3}
            checkNumber={checkNumber}
            ratingLoading={ratingLoading}
            metricsLoading={metricsLoading}
            mobileMap={mobileMap}
            desktopMap={desktopMap}
            mobileSearches={mobileSearches}
            desktopSearches={desktopSearches}
            requestDirections={requestDirections}
            visit={visit}
            call={call}
            kFormatter={kFormatter}
            totalValueConversion={totalValueConversion}
          />
        )}
      {((businessData !== null &&
        bookingData !== null &&
        foodOrderData !== null &&
        businessData?.metricValue === 0 &&
        bookingData?.metricValue === 0 &&
        foodOrderData?.metricValue === 0 &&
        selectedChartTab === 2) ||
        selectedChartTab === 3) && (
        <SearchesBreakdown
          googleInsightData={googleInsightData}
          data={data}
          dataSet4={dataSet4}
          checkNumber={checkNumber}
          ratingLoading={ratingLoading}
          metricsLoading={metricsLoading}
          mobileMap={mobileMap}
          desktopMap={desktopMap}
          mobileSearches={mobileSearches}
          desktopSearches={desktopSearches}
          requestDirections={requestDirections}
          visit={visit}
          call={call}
          branded={branded}
          discovery={discovery}
          kFormatter={kFormatter}
          totalValueBuisness={totalKeywordsValue}
          totalKeywordsCount={totalKeywordsCount}
          searchesBreakdownData={searchesBreakdownData}
        />
      )}
    </Grid>
  );
}

export default Index;
