import {
  Button,
  Grid,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  IconButton,
  TextField,
  tooltipClasses,
  Menu,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/system";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonButton from "../../CustomComponents/Buttons/CommonButton";
import { Edit } from "@mui/icons-material";

const Divider = styled(MuiDivider)(spacing);

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const TitleHeader = ({
  title,
  subHeading,
  showButton,
  createButton,
  extraButtonName,
  IframeButtonName,
  extraButton,
  progressLoading,
  oldReviewButtonName,
  oldReviewButton,
  CreateNavigateButton,
  IframeButton,
  handleDefaultTemplate,
  handleOpenOldReviewsMenu,
  handleDefaultIframe,
  getOldReviewData,
  onClick,
  onChange,
  loading,
  color,
  size,
  name,
  templateTitle,
  count,
  disableButton,
  dumpReviewsLoading,
  setEditData,
  showEditButton,
  titleRequired,
  isLoading,
  setTitleRequired,
  setTile,
  editData,
  showDivider,
  googleButtonName,
  googleButton,
  handleConnectWithGoogle,
  handleBackButton,
  showBackButton,
  handleConnectWithFacebook,
  facebookButtonName,
  facebookButton,
  navigateToLocations,
  navigateToReviewsCampaigns,
  addPermission,
  viewPermission,
  emotionAiExportButton,
  emotionAiExportButtonName,
  anchorBulk,
  handleClose,
  handleClickMenu,
  handleClickMenuItem,
  sheduleMenu,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_BASE_URL;

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection="column"
          // justifyItems="center"
        >
          <Box>
            {showButton ? (
              <Box
                onClick={() => {
                  navigateToLocations === true
                    ? navigate("/user/locations")
                    : navigateToReviewsCampaigns
                    ? navigate("/user/review_request")
                    : navigate(-1);
                }}
                sx={{
                  display: "flex",
                  background: "#1B2430",
                  cursor: "pointer",
                  // paddingX: "14px",
                  paddingY: "10px",
                  width: "89px",
                  height: "48px",
                  alignItems: "center",

                  justifyContent: "center",
                  borderRadius: "8px",
                  marginBottom: "1rem",
                }}
              >
                <ArrowBackIcon
                  sx={{
                    color: "white",
                    mr: "0.5rem",
                    width: "18px",
                    height: "18px",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />

                <Typography
                  color="white"
                  variant="subtitle1"
                  fontWeight={700}
                  fontSize="14px"
                  lineHeight="20px"
                >
                  {t("Back")}
                </Typography>
              </Box>
            ) : (
              <Grid></Grid>
            )}
          </Box>
          <Box>
            {showBackButton ? (
              <Box
                onClick={() => {
                  handleBackButton();
                }}
                sx={{
                  display: "flex",
                  background: "#1B2430",
                  cursor: "pointer",
                  paddingX: "14px",
                  paddingY: "10px",
                  width: "89px",
                  height: "48px",
                  alignItems: "center",

                  justifyContent: "center",
                  borderRadius: "8px",
                  marginBottom: "1rem",
                }}
              >
                <ArrowBackIcon
                  sx={{
                    color: "white",
                    mr: "0.5rem",
                    width: "18px",
                    height: "18px",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />

                <Typography
                  color="white"
                  variant="subtitle1"
                  fontWeight={700}
                  fontSize="14px"
                  lineHeight="20px"
                >
                  {t("Back")}
                </Typography>
              </Box>
            ) : (
              <Grid></Grid>
            )}
          </Box>

          <Grid container>
            <Grid
              item
              lg={showEditButton ? 4 : 12}
              sx={{
                display: "flex",
              }}
            >
              <Typography fontWeight={700} fontSize="24px" lineHeight="32px">
                {count} {title}
              </Typography>
              {showEditButton && (
                <IconButton
                  aria-label="edit"
                  size="large"
                  sx={{
                    paddingTop: "5px",
                  }}
                  onClick={() => setEditData(true)}
                >
                  <Edit
                    sx={{
                      paddingTop: "0px",
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>

          <Typography
            fontWeight={400}
            fontSize="14px"
            lineHeight="20px"
            // variant="subtitle1"
            color="#495059"
            sx={{ marginBottom: "0.5rem" }}
          >
            {subHeading}
          </Typography>
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {IframeButton && (
            <>
              <CommonButton
                onSubmit={handleDefaultIframe}
                label={IframeButtonName}
                onChange={onChange}
                disabled={disableButton}
              />
            </>
          )}

          {oldReviewButton &&
            (addPermission && viewPermission ? (
              <CommonButton
                onSubmit={handleOpenOldReviewsMenu}
                label={oldReviewButtonName}
                onChange={onChange}
                disabled={progressLoading}
              />
            ) : (
              <BootstrapTooltip title={t("authorized_access")}>
                <span>
                  <CommonButton
                    // onSubmit={onClick}
                    label={oldReviewButtonName}
                    onChange={onChange}
                    disabled={true}
                  />
                </span>
              </BootstrapTooltip>
            ))}

          {extraButton &&
            (addPermission && viewPermission ? (
              <CommonButton
                onSubmit={handleDefaultTemplate}
                label={extraButtonName}
                onChange={onChange}
                disabled={dumpReviewsLoading}
              />
            ) : (
              <BootstrapTooltip title={t("authorized_access")}>
                <span>
                  <CommonButton
                    // onSubmit={onClick}
                    label={extraButtonName}
                    onChange={onChange}
                    disabled={true}
                  />
                </span>
              </BootstrapTooltip>
            ))}
          {facebookButton && (
            <CommonButton
              onSubmit={handleConnectWithFacebook}
              icon={
                <img
                  alt=""
                  style={{ width: 30, height: 30, marginRight: "3px" }}
                  src={`${baseURL}/assets/static/review_source/google.png`}
                />
              }
              label={facebookButtonName}
            />
          )}
          {googleButton && (
            <CommonButton
              onSubmit={handleConnectWithGoogle}
              icon={
                <img
                  alt=""
                  style={{ width: 30, height: 30, marginRight: "3px" }}
                  src={`${baseURL}/assets/static/review_source/google.png`}
                />
              }
              label={googleButtonName}
            />
          )}

          {createButton &&
            (addPermission && viewPermission ? (
              <CommonButton
                onSubmit={onClick}
                label={name}
                onChange={onChange}
                disabled={disableButton}
              />
            ) : (
              <BootstrapTooltip title={t("authorized_access")}>
                <span>
                  <CommonButton
                    // onSubmit={onClick}
                    label={name}
                    onChange={onChange}
                    disabled={true}
                  />
                </span>
              </BootstrapTooltip>
            ))}
          {editData && (
            <TextField
              error={titleRequired}
              disabled={isLoading}
              required
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  setEditData(false);
                }
              }}
              label="Title"
              value={templateTitle}
              onChange={(event) => {
                setTitleRequired(false);
                setTile(event.target.value);
              }}
              //  fullWidth
              variant="outlined"
            />
          )}
          {CreateNavigateButton && (
            <>
              <CommonButton
                onSubmit={CreateNavigateButton}
                label={name}
                onChange={onChange}
                disabled={disableButton}
              />
            </>
          )}
          {viewPermission && emotionAiExportButton && (
            <>
              <Button
                variant="contained"
                color="primary"
                aria-owns={anchorBulk ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClickMenu}
                endIcon={<KeyboardArrowDownIcon />}
                style={{
                  width: "150px",
                  height: "52px",
                  // radius: "8px",
                  // border: "1px solid #E0E0E0",
                  color: "#FFFF",
                  padding: "14px, 20px, 14px, 20px",
                  background: "#06BDFF",
                  borderRadius: "8px",
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginLeft: "8px",
                  cursor: "pointer",
                }}
              >
                {emotionAiExportButtonName}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorBulk}
                open={Boolean(anchorBulk)}
                onClose={handleClose}
                PaperProps={{
                  sx: {
                    background: "#EBEBEC", // Set your desired background color here
                  },
                }}
              >
                {addPermission && viewPermission ? (
                  <MenuItem
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#DBDBDC",
                      },
                    }}
                    onClick={(e) => {
                      handleClickMenuItem("download");
                      handleClose();
                    }}
                  >
                    {t("downlaod_pdf")}
                  </MenuItem>
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <MenuItem
                        disabled
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          lineHeight: "20px",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#DBDBDC",
                          },
                        }}
                        onClick={(e) => {
                          handleClickMenuItem("download");
                          handleClose();
                        }}
                      >
                        {t("downlaod_pdf")}
                      </MenuItem>
                    </span>
                  </BootstrapTooltip>
                )}
                <Divider />

                {addPermission && viewPermission ? (
                  <MenuItem
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#DBDBDC",
                      },
                    }}
                    onClick={(e) => {
                      handleClickMenuItem("email");
                      handleClose();
                    }}
                  >
                    {t("email_report")}
                  </MenuItem>
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <MenuItem
                        disabled
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          // background: "#EBEBEC",
                          lineHeight: "20px",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#DBDBDC",
                          },
                        }}
                        onClick={(e) => {
                          handleClickMenuItem("email");
                          handleClose();
                        }}
                      >
                        {t("email_report")}
                      </MenuItem>
                    </span>
                  </BootstrapTooltip>
                )}
                <Divider />

                {addPermission && viewPermission ? (
                  <MenuItem
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#DBDBDC",
                      },
                    }}
                    onClick={(e) => {
                      handleClickMenuItem("schedule");
                      handleClose();
                    }}
                  >
                    {t("Schedule_report")}
                  </MenuItem>
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <MenuItem
                        disabled
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          // background: "#EBEBEC",
                          lineHeight: "20px",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#DBDBDC",
                          },
                        }}
                        onClick={(e) => {
                          handleClickMenuItem("schedule");
                          handleClose();
                        }}
                      >
                        {t("Schedule_report")}
                      </MenuItem>
                    </span>
                  </BootstrapTooltip>
                )}
              </Menu>
            </>
          )}
        </Grid>
      </Grid>

      {!showDivider && <Divider my={4} />}
    </>
  );
};

export default TitleHeader;
