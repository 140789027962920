import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FDEDED",
    padding: "6px 16px",
    borderRadius: "7px",
    marginBottom: "10px",
  },
  errorText: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.43",
    color: "#ef5350",
    padding: "6px 0px",
  },
  errorIcon: {
    color: "#ef5350",
    marginTop: "5px",
  },
  sourceImage: {
    width: "40px",
    height: "40px",
  },
  sourceName: {
    //   fontFamily: "Work Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  subscribedText: {
    background: "#06BDFF",
    marginRight: "12px",
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "3px",
    gap: "4px",
    width: "67px",
    height: "20px",
    borderRadius: "800px",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  addRuleMainBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
    marginLeft: "1.5rem",
    marginRight: "2rem",
    padding: "8px",
  },
  Location: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "1rem",
    marginRight: "2rem",
    padding: "1rem",
  },
  locationTitle: {
    borderRadius: "10px",
    backgroundColor: "#e0e0e0",
    padding: "2rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  Condition: {
    backgroundColor: "#e0e0e0",
    padding: "2rem",
    margin: "0 1rem",
    borderRadius: "10px",
  },
  Reviews: {
    marginBottom: "1rem",
    marginLeft: "3rem",
  },
  autoReply: {
    marginTop: "2rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  Rating: {
    marginBottom: "1rem",
  },
}));
