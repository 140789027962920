import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Grid,
  Zoom,
  Stack,
  Divider,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";

const useStyles = makeStyles((theme) => ({
  formUpload: {
    height: "16rem",
    width: "100%",

    textAlign: "center",
    position: "relative",
  },
  stepLocation: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#1B2430",
  },
  lableFileUpload: {
    height: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: "#cbd5e1",
    flexDirection: "column",
  },
  dragFile: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
}));
const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));

Transition.displayName = "Transition";

const ProfilePictureModal = ({
  open,
  handleCloseNavigate,
  stepperData,
  setStepperData,
  setOpenPictureModal,
  setSelectedImages,
  setSurveyImageDisplay,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [textRequired, setTextRequired] = useState(false);
  const [headingRequired, setHeadingRequired] = useState(false);

  const [uploadImgData, setUploadImgData] = useState({
    heading: stepperData?.campaignSurveyData?.heading,
    description: stepperData?.campaignSurveyData?.description,
  });

  const handleImgSubmit = async () => {
    if (!uploadImgData?.description) {
      setTextRequired(true);
    } else {
      setTextRequired(false);
    }
    if (!uploadImgData?.heading) {
      setHeadingRequired(true);
    } else {
      setHeadingRequired(false);
    }

    if (uploadImgData?.description !== "" && uploadImgData?.heading !== "") {
      setStepperData({
        ...stepperData,
        campaignSurveyData: {
          image_url: stepperData?.campaignSurveyData?.image_url,
          heading: uploadImgData?.heading,
          description: uploadImgData?.description,
        },
      });

      setOpenPictureModal(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
              borderRadius: "8px",
            },
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNavigate}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ height: "420px", overflow: "scroll" }}
        >
          {uploadImgData?.heading !== "" ||
          uploadImgData?.description !== "" ? (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <DialogTitle
                  id="alert-dialog-slide-title"
                  sx={{
                    fontWeight: "700",
                    color: "#1B2430",
                    fontSize: "20px",
                    letterSpacing: "0.5px",
                    lineHeight: "28px",
                  }}
                >
                  {t("Edit_private_survey")}
                </DialogTitle>
              </Stack>
              <Divider />
            </>
          ) : (
            <></>
          )}
          <Box>
            <Box
              sx={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "8px",
              }}
            >
              <Typography className={classes.stepLocation}>
                {t("Heading")}
              </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter heading"
                defaultValue={uploadImgData?.heading}
                fullWidth
                error={headingRequired}
                onChange={(e) => {
                  if (e.target.value < 1 || e.target.value === "") {
                    setHeadingRequired(true);
                  } else {
                    setHeadingRequired(false);
                  }
                  setUploadImgData({
                    ...uploadImgData,
                    heading: e.target.value,
                  });
                }}
              />
              {headingRequired && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontWeight: 400,
                    fontSize: "0.6964285714285714rem",
                    lineHeight: "1.66",
                    textAlign: "left",
                    marginTop: "3px",
                    marginRight: "14px",
                    marginBottom: 0,
                  }}
                >
                  {t("Heading is required.")}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "8px",
              }}
            >
              <Typography className={classes.stepLocation}>
                {t("Description")}
              </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                rows={5}
                maxRows={20}
                multiline
                fullWidth
                defaultValue={uploadImgData?.description}
                placeholder="Write Description here"
                error={textRequired}
                onChange={(e) => {
                  if (e.target.value < 1 || e.target.value === "") {
                    setTextRequired(true);
                  } else {
                    setTextRequired(false);
                  }
                  setUploadImgData({
                    ...uploadImgData,
                    description: e.target.value,
                  });
                }}
              />
              {textRequired && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontWeight: 400,
                    fontSize: "0.6964285714285714rem",
                    lineHeight: "1.66",
                    textAlign: "left",
                    marginTop: "3px",
                    marginRight: "14px",
                    marginBottom: 0,
                    // marginLeft: "14px",
                  }}
                >
                  {t("desc_req")}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>

        <Divider />
        <DialogActions>
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            paddingTop="8px"
          >
            <CommonButton
              displayWhite="true"
              onSubmit={() => handleCloseNavigate()}
              label={t("Cancel")}
            />
            <CommonButton
              label={t("Save")}
              type="submit"
              disabled={headingRequired && textRequired}
              onSubmit={handleImgSubmit}
            />
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfilePictureModal;
