import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import { Typography, Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
    // height: "70vh",
  },
  locationsubhead: {
    // marginBottom: "8px",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
    color: "#495059",
  },
  locationhead: {
    // marginBottom: "8px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "32px",
    color: "#1B2430",
  },
  Imagesection: {
    height: "250px",
  },
}));

function InternalServerErrorModal({
  open,
  text,
  subtext1,
  subtext2,
  subtext3,
  handleReloadPage,
  src,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ width: "100%", height: "100%" }}
      >
        <DialogContent>
          <Box className={classes.locationbox}>
            <img alt="" alt={src} className={classes.Imagesection} src={src} />
            <Typography className={classes.locationhead}>{text}</Typography>
            <Typography className={classes.locationsubhead}>
              {subtext1}
            </Typography>
            <Typography className={classes.locationsubhead}>
              {subtext2}
            </Typography>
            <Typography className={classes.locationsubhead}>
              {subtext3}
            </Typography>
            <Box mt={5}>
              <CommonButton
                label={t("try_again")}
                onSubmit={handleReloadPage}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default InternalServerErrorModal;
