import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Dialog,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  MenuItem,
  Menu,
  Select,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as MakePrimaryUser } from "../../../assets/Icons/makePrimary.svg";
import { ReactComponent as TransferOwnership } from "../../../assets/Icons/transferOwnership.svg";
import { ReactComponent as ChurnIcon } from "../../../assets/Icons/Churn.svg";
import CustomizedTableCell from "../../../components/CustomComponents/table/tableCell";
import CustomizedTableRow from "../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../components/CustomComponents/table/tableHead";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import useAuth from "../../../hooks/useAuth";
import { handleSessionOut } from "../../../contexts/JWTContext";
import Loader from "../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import { api } from "../../../contexts/JWTContext";
import SearchField from "../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import TitleHeader from "../../../components/CustomComponents/title";
import NotFound from "../../../components/NotFound/NotFound";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Delete from "../../../components/Models/DeleteModal/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPrimaryUserModel from "../../../components/Models/DeleteModal/AddPrimaryUserModel";
import { WarningAmberOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CompanyLinkedModel from "../../../components/Models/CompanyLinkedModel";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
  divider: {
    padding: "1.25rem",
    paddingBottom: "1rem",
  },
  dialogBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogBoxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  dialogBoxHeading: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
  },
  dialogBoxText: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  toast: {
    width: "100%",
    display: "flex",
    padding: "0 1.25rem",
    paddingBottom: "1rem",
  },
  toastBox: {
    backgroundColor: "#FFF1D7",
    width: "100%",
    display: "flex",
    borderRadius: "4px",
    gap: "1rem",
  },
  toastBoxBorder: {
    backgroundColor: "#FAB431",
    width: "6px",
    borderRadius: "4px 0 0 4px",
  },
  toastBoxContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  toastBoxText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 0",
    gap: "1rem",
  },
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  searchIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  LinkStyle: {
    textDecoration: "none",
  },
}));

const AccountsOverView = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const { signOut } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openAcceptModel, setOpenAcceptModel] = useState(false);
  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [openUnChurnModel, setOpenUnChurnModel] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [approvalValue, setApprovalValue] = useState("All");
  const [selectedRow, setSelectedRow] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openDeleteLinkedModel, setOpenDeleteLinkedModel] = useState(false);
  const [companyLinkedModel, setCompanyLinkedModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [openOwnershipModel, setOpenOwnershipModel] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [modelType, setModelType] = useState(null);
  const [search, setSearch] = useState(false);
  let allCustomer = [];
  partners?.results?.forEach((customer) => {
    allCustomer?.results?.push(customer);
  });

  const [linkedUsers, setLinkedUsers] = useState([]);

  useEffect(() => {
    getAllCustomer();
  }, [page, rowsPerPage, filterObj]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        getAllCustomer();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName]);

  const headCells = [
    { id: "id", alignment: "left", label: t("AccountTableID") },
    { id: "name", alignment: "left", label: t("CompanyyName") },
    // { id: "Contact", alignment: "left", label: t("Namee") },
    {
      id: "Telephone number",
      alignment: "left",
      label: t("AccountTableTelephoneNumber"),
    },
    { id: "locations", alignment: "left", label: t("AccountTableLoactions") },
    /* { id: "approved", alignment: "left", label: t("Status") }, */
    { id: "state", alignment: "left", label: t("State") },
    { id: "PrimaryUser", alignment: "left", label: t("PrimaryUser") },
    { id: "actions", alignment: "left", label: t("Actions") },
  ];
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allCompanies?.count - page * rowsPerPage);

  const getLinkedUsers = async (data) => {
    try {
      setIsLoadingButton(true);
      const res = await api.get(
        `/company/${data.id}/linkedUsers?limit=500&pageNumber=${page + 1}`
      );
      if (res.status === 200) {
        setLinkedUsers(res.data.data);
        if (res.data.data.count === 0) {
          setOpenDeleteModel(true);
        } else {
          setOpenDeleteLinkedModel(true);
        }
        setIsLoadingButton(false);
      }
    } catch (error) {
      setIsLoadingButton(false);
      toast.error(error.response.data.message);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const getAllCustomer = async (pageValue) => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `/company/accounts?filters[name][$contains]=${filterWithName}&limit=${rowsPerPage}&page=${
          page + 1
        }&filters[companyApproval][$equals]=true`,
        { params: { ...filterObj } }
      );
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setPartners(res.data.data);
        setIsLoading(false);
        setSearch(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const handleAcceptAccount = async () => {
    try {
      const res = await api.patch(
        `/admin/company-approval/${selectedRow?.id}`,
        {
          companyApproval: true,
        }
      );
      if (res.status === 200) {
        setIsLoading(false);
        handleCloseAcceptModel();
        toast.success(res?.data?.message);
        //  navigate(`/admin/accounts/${id}`);
        getAllCustomer();
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseAcceptModel();
    }
  };
  const handleDeleteAccount = async () => {
    if (deleteId !== null) {
      setDeleteLoading(true);
      try {
        const res = await api.delete(`/company/${deleteId}?force=true`);
        if (res.status === 200) {
          setDeleteLoading(false);
          setOpenDeleteModel(false);
          toast.success(t("account_deleted_text"));

          getAllCustomer();
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setDeleteLoading(false);
        setOpenDeleteModel(false);
      }
    }

    // handleCloseDeleteModel();
  };
  const handleChurnAccount = async () => {
    try {
      const res = await api.post(`company/updateState/${selectedRow?.id}`, {
        state: "churn",
      });
      if (res.status === 201) {
        {
        }
        setIsLoading(false);
        handleCloseChurnModel();
        toast.success(res?.data?.message);
        //  navigate(`/admin/accounts/${id}`);
        getAllCustomer();
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseChurnModel();
    }
  };
  const handleUnChurnAccount = async () => {
    try {
      const res = await api.post(`company/updateState/${selectedRow?.id}`, {
        state: "active",
      });
      if (res.status === 201) {
        {
        }
        setIsLoading(false);
        handleCloseUnChurnModel();
        toast.success(res?.data?.message);
        //  navigate(`/admin/accounts/${id}`);
        getAllCustomer();
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseUnChurnModel();
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterByName = (e) => {
    setPage(0);
    setSearch(true);
    setFilterWithName(e.target.value);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    // setSelectedRow(null);
  };
  const handleOpenAcceptModel = () => {
    setOpenAcceptModel(true);
  };
  const handleCloseAcceptModel = () => {
    setOpenAcceptModel(false);
  };

  const handleOpenChurnModel = () => {
    setOpenRejectModel(true);
  };
  const handleOpenUnChurnModel = () => {
    setOpenUnChurnModel(true);
  };

  const handleCloseLinkedDeleteModel = () => {
    setOpenDeleteLinkedModel(false);
  };
  const handleOpenDeleteModel = () => {
    getLinkedUsers(selectedRow);
  };
  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
  };

  const handleOpenCompanyLinkedModel = () => {
    setOpenDeleteLinkedModel(false);
    setCompanyLinkedModel(true);
  };

  const handleCloseChurnModel = () => {
    setOpenRejectModel(false);
  };
  const handleCloseUnChurnModel = () => {
    setOpenUnChurnModel(false);
  };

  const handleChangeFilters = (e) => {
    setApprovalValue(e.target.value);
    let tempfilterObj = { ...filterObj };
    setPage(0);

    if (e.target.value === "approved") {
      tempfilterObj["filters[companyApproval][$equals]"] = true;
    } else if (e.target.value === "notApproved") {
      tempfilterObj["filters[companyApproval][$equals]"] = false;
    } else {
      delete tempfilterObj["filters[companyApproval][$equals]"];
    }
    if (e.target.value === "active") {
      tempfilterObj["filters[state][$equals]"] = "active";
    } else if (e.target.value === "churn") {
      tempfilterObj["filters[state][$equals]"] = "churn";
    } else {
      delete tempfilterObj["filters[state][$equals]"];
    }

    if (e.target.value === "withPrimary") {
      tempfilterObj["isPrimary"] = "true";
    } else if (e.target.value === "withOutPrimary") {
      tempfilterObj["isPrimary"] = "false";
    } else {
      delete tempfilterObj["isPrimary"];
    }

    setFilterObj(tempfilterObj);
  };

  const handleClick = (event, id, selectedData) => {
    setDeleteId(id);
    setAnchorEl(event.currentTarget);
    setSelectedRow(selectedData);
    setSelectedAccount(selectedData);
  };

  const handleDisplayPrimaryUserName = (usersArr) => {
    if (usersArr?.length > 0) {
      const primaryUser = usersArr?.find(
        (item) =>
          item?.CompanyUserRoles?.length > 0 &&
          item?.CompanyUserRoles[0]?.isPrimary === true
      );
      let userName = "--";
      if (primaryUser !== undefined) {
        userName =
          primaryUser?.firstName !== null
            ? primaryUser?.firstName.charAt(0).toUpperCase() +
              primaryUser?.firstName.slice(1) +
              " " +
              (primaryUser?.lastName !== null
                ? primaryUser?.lastName.charAt(0).toUpperCase() +
                  primaryUser?.lastName.slice(1)
                : "")
            : "--";
        return userName;
      } else {
        return userName;
      }
    }
  };

  const handleOpenChangeOwnershipModel = (keyValue) => {
    setOpenOwnershipModel(true);
    setModelType(keyValue);
  };
  const handleCloseChangeOwnershipModel = () => {
    setOpenOwnershipModel(false);
  };

  return (
    <>
      {openOwnershipModel && (
        <AddPrimaryUserModel
          title={
            modelType !== null &&
            (modelType === "ownership"
              ? t("Transfer_ownership")
              : t("Make_Primary_User"))
          }
          subTitle={t("Transfer_ownershipSubtext")}
          handleCloseModel={handleCloseChangeOwnershipModel}
          open={openOwnershipModel}
          selectedAccount={selectedAccount?.id}
          fromAccounts={true}
          getAllData={getAllCustomer}
          modelType={modelType}
        />
      )}
      <TitleHeader
        showButton={false}
        title={t("AccountTitle")}
        subHeading={t("ViewCustomers")}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4.5} lg={4.5}>
          <SearchField
            filterByName={filterByName}
            onSubmit={getAllCustomer}
            placeholder={t("Filter_Accounts")}
            filterWithName={filterWithName}
          />
          {/* &nbsp; */}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Select
            sx={{ backgroundColor: "#FFF" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            value={approvalValue}
            label=""
            defaultValue={"domain"}
            onChange={handleChangeFilters}
            //  error={domainRequired}
          >
            <MenuItem value="All">{t("All")}</MenuItem>

            <MenuItem value="active">{t("Active")}</MenuItem>
            <MenuItem value="churn">{t("Churned_text")}</MenuItem>
            <MenuItem value="withPrimary">{t("with_primary")}</MenuItem>
            <MenuItem value="withOutPrimary">{t("without_primary")}</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={3.5} lg={3.5}>
          <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
            <CommonButton
              alignment={true}
              onSubmit={getAllCustomer}
              label={t("Search_Account")}
            />
            <CommonButton
              alignment={true}
              onSubmit={() => navigate("/admin/accounts/add-user")}
              label={t("Add_Account")}
            />
          </Box>
        </Grid>
      </Grid>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {allCompanies?.results?.length > 0 ? (
            <>
              <TableContainer component={Paper} sx={{ marginTop: "2rem" }}>
                <Table aria-label="simple table">
                  <CustomizedTableHead>
                    <CustomizedTableRow>
                      {headCells.map((headCell) => (
                        <CustomizedTableCell
                          key={headCell.id}
                          align={headCell.alignment}
                          padding={headCell.disablePadding ? "none" : "normal"}
                        >
                          {headCell.label}
                        </CustomizedTableCell>
                      ))}
                    </CustomizedTableRow>
                  </CustomizedTableHead>

                  <TableBody>
                    {allCompanies?.results &&
                      allCompanies?.results?.length > 0 &&
                      allCompanies?.results?.map((row, index) => {
                        return (
                          <TableRow tabIndex={-1} key={`${row.id}-${index}`}>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.id}
                              {/*    */}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              sx={{ cursor: "pointer", width: "300px" }}
                              align="left"
                            >
                              <Link
                                className={classes.LinkStyle}
                                to={`${row.id}`}
                              >
                                <Typography
                                  sx={{
                                    textTransform: "capitalize",
                                    textDecoration: "none",
                                  }}
                                >
                                  {row?.name}
                                </Typography>
                              </Link>
                            </CustomizedTableCell>

                            <CustomizedTableCell align="left">
                              {row?.telephone}
                            </CustomizedTableCell>
                            {/*    */}
                            <CustomizedTableCell
                              sx={{ cursor: "pointer" }}
                              align="left"
                            >
                              {row?.locations?.length > 0
                                ? row?.locations?.length === 1
                                  ? `${t("Single")}`
                                  : row?.locations?.length > 1
                                  ? `${t("Multi")}`
                                  : "--"
                                : "--"}
                            </CustomizedTableCell>
                            {/*   <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.companyApproval===true ? (
                                <Typography>{t("approved")}</Typography>
                              ) : (
                                <Typography>{t("Not_Approved")}</Typography>
                              )}
                            </CustomizedTableCell> */}

                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.state === "churn"
                                ? t("Churned_text")
                                : row?.state === "active"
                                ? t("Active")
                                : ""}
                            </CustomizedTableCell>

                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.users?.length > 0
                                ? handleDisplayPrimaryUserName(row?.users)
                                : "--"}
                            </CustomizedTableCell>

                            <CustomizedTableCell padding="none" align="left">
                              <Box mr={2}>
                                {row.id === selectedRow?.id &&
                                isLoadingButton ? (
                                  <Box
                                    sx={{
                                      height: "44px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CircularProgress
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginLeft: "8px",
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <IconButton
                                    aria-label="edit"
                                    size="large"
                                    onClick={(e) => handleClick(e, row.id, row)}
                                  >
                                    <MoreVertIcon sx={{ color: "#757575" }} />
                                  </IconButton>
                                )}

                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem>
                                    <Link
                                      to={`${
                                        selectedRow !== null && selectedRow.id
                                      }`}
                                      style={{
                                        textDecoration: "none",
                                        display: "flex",
                                      }}
                                    >
                                      <EditIcon
                                        sx={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                        }}
                                      />
                                      <Typography sx={{ color: "#000" }}>
                                        {t("edit_account")}
                                      </Typography>
                                    </Link>
                                  </MenuItem>

                                  <Divider variant="middle" />
                                  <MenuItem
                                    onClick={() => {
                                      closeMenu();
                                      handleOpenDeleteModel(row);
                                      // handleOpenModal(row?.id);
                                    }}
                                  >
                                    <DeleteIcon
                                      sx={{
                                        color: "#545353",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    <Typography>{t("Delete")}</Typography>
                                  </MenuItem>
                                  <Divider variant="middle" />

                                  {selectedRow?.state === "active" && (
                                    <MenuItem
                                      onClick={() => {
                                        closeMenu();
                                        handleOpenChurnModel();
                                        // handleOpenModal(row?.id);
                                      }}
                                    >
                                      <ChurnIcon
                                        style={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                      <Typography>{t("Churn")}</Typography>
                                    </MenuItem>
                                  )}
                                  {selectedRow?.state === "churn" && (
                                    <MenuItem
                                      onClick={() => {
                                        closeMenu();
                                        handleOpenUnChurnModel();
                                        // handleOpenModal(row?.id);
                                      }}
                                    >
                                      <ChurnIcon
                                        style={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                      <Typography>{t("Unchurn")}</Typography>
                                    </MenuItem>
                                  )}
                                  <Divider variant="middle" />
                                  {selectedRow?.users.find(
                                    (item) =>
                                      item?.CompanyUserRoles?.length > 0 &&
                                      item?.CompanyUserRoles[0]?.isPrimary ==
                                        true
                                  ) !== undefined && (
                                    <MenuItem
                                      onClick={() => {
                                        closeMenu();
                                        handleOpenChangeOwnershipModel(
                                          "ownership"
                                        );
                                        // handleOpenModal(row?.id);
                                      }}
                                    >
                                      <TransferOwnership
                                        style={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                      <Typography>
                                        {t("change_ownership")}
                                      </Typography>
                                    </MenuItem>
                                  )}
                                  {selectedRow?.users.find(
                                    (item) =>
                                      item?.CompanyUserRoles?.length > 0 &&
                                      item?.CompanyUserRoles[0]?.isPrimary ==
                                        true
                                  ) === undefined && (
                                    <MenuItem
                                      onClick={() => {
                                        closeMenu();
                                        handleOpenChangeOwnershipModel();
                                        // handleOpenModal(row?.id);
                                      }}
                                    >
                                      <MakePrimaryUser
                                        style={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                      <Typography>
                                        {t("Add_primary_user")}
                                      </Typography>
                                    </MenuItem>
                                  )}
                                </Menu>
                              </Box>
                            </CustomizedTableCell>
                          </TableRow>
                        );
                      })}
                    {/* style={{ height: 53 * emptyRows }} */}
                    {emptyRows > 0 && (
                      <TableRow>{/* <TableCell colSpan={8} /> */}</TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allCompanies?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <NotFound text="No Account Found" />
          )}
        </>
      )}
      {openRejectModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openRejectModel}
          onClose={handleCloseChurnModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Churn_Account")}
            description={t("churn_subhead")}
            onConfirm={() => handleChurnAccount()}
            onCancel={handleCloseChurnModel}
          />
        </Dialog>
      )}
      {openDeleteModel && (
        <Dialog
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("delete_account")}
            description={t("delete_account_desc")}
            onConfirm={handleDeleteAccount}
            onCancel={handleCloseDeleteModel}
            loading={deleteLoading}
          />
        </Dialog>
      )}
      {openDeleteLinkedModel && (
        <Dialog
          open={openDeleteLinkedModel}
          onClose={handleCloseLinkedDeleteModel}
          sx={{
            "& .MuiDialog-paper": {
              width: "550px",
              minHeight: "355px",
              maxHeight: "400px",
            },
          }}
        >
          <DialogTitle>
            <Box className={classes.dialogBox}>
              <IconButton
                autoFocus
                onClick={handleCloseLinkedDeleteModel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogBoxContent}>
            <WarningAmberOutlined
              sx={{ color: "#FAB431", height: "80px", width: "80px" }}
            />
            <Typography className={classes.dialogBoxHeading}>
              {t("delete_company")}
            </Typography>
            <Typography className={classes.dialogBoxText}>
              {t("delete_company_text_one")} {selectedRow.name}{" "}
              {t("delete_company_text_two")}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: "20px" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CommonButton
                  displayWhite="true"
                  onSubmit={handleCloseLinkedDeleteModel}
                  label={t("Cancel")}
                />

                <CommonButton
                  onSubmit={handleOpenCompanyLinkedModel}
                  label={t("Proceed")}
                  className={classes.PostAiButton}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}

      {companyLinkedModel && (
        <CompanyLinkedModel
          open={companyLinkedModel}
          close={setCompanyLinkedModel}
          users={linkedUsers}
          company={selectedRow}
          handleDeleteAccount={handleDeleteAccount}
          getAllCustomer={getAllCustomer}
        />
      )}

      {openUnChurnModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openUnChurnModel}
          onClose={handleCloseUnChurnModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Unchurn_Account")}
            description={t("Unchurn_subhead")}
            onConfirm={() => handleUnChurnAccount()}
            onCancel={handleCloseUnChurnModel}
          />
        </Dialog>
      )}

      {openAcceptModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openAcceptModel}
          onClose={handleCloseAcceptModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Approval_title")}
            description={t("accept_des")}
            onConfirm={() => handleAcceptAccount()}
            onCancel={handleCloseAcceptModel}
          />
        </Dialog>
      )}
    </>
  );
};

export default AccountsOverView;
