import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const GoogleMapInpuField = (props) => {
  const [value] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (value && value.value && value?.value?.place_id) {
      props.setPlaceIdFromGoogle(value?.value?.place_id);
    }
  }, []);
  useEffect(() => {
    if (value && value.value && value?.value?.place_id) {
      props.setPlaceIdFromGoogle(value?.value?.place_id);
    }
  }, [value]);

  const setPlaceId = (id) => {
    props.setPlaceIdFromGoogle(id);
  };

  return (
    <>
      <Grid>
        <Grid sx={{ bgcolor: "white" }}>
          <Grid>
            {/* <Typography variant="subtitle1">
                {t("CreateYourAccount")}
              </Typography> */}

            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_LOCATION_API_KEY}
                  onLoadFailed={(error) => {
                    console.error("Could not inject Google script", error);
                    window.location.reload();
                  }}
                  selectProps={{
                    placeholder: `${t("Search_Location_on_Google")}`,
                    value,
                    onChange: (val) => {
                      setPlaceId(val);
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GoogleMapInpuField;
