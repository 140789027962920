import React, { useEffect, useState } from "react";
import { Grid, Typography, Divider, Paper } from "@mui/material";
import Loader from "../../../../../../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../Styles/style";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../../components/Models/ConfirmModalLodging";
import AttributeItem from "./attributesItem";
import { styled } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  border: "1px solid #EBEBEC",
  // minWidth: "101px",
  height: "30px",
  padding: "6px",
  margin: "0.3rem",
  paddingLeft: "15px",
  paddingRight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "800px",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const AttributesModal = ({
  onCancel,
  locationDetailsData,
  location,
  getLocationDetails,
  allAttributes,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  getLocationDetailsListing,
  setAttributesWithCategories,
  attributesWithCategories,
  handleChangeStringValue,
  handleChangeIntegerValue,
  handleChangeFloatValue,
  handleChangeDateValue,
  handleChangeChecked,
  addMultiAttribute,
  removeMultiAttribute,
  setCategoriesData,
  categoriesData,
  attributesLoading,
  setSelectedCategoryName,
  selectedCategoryName,
  setDisplayChildCategories,
  displayChildCategories,
  handleChangeEnum,
  setAttributesLoading,
  setIsEditable,
  isEditable,
  handleUpdateAttributesWithCategories,
  setOpenConfirmBox,
  openConfirmBox,
  nextCategory,
  setNextCategory,
  setIntegerError,
  integerError,
  locationCategoriesData,
  setLocationCategoriesData,
  errors,
  errorMessage,
  setErrors,
  setErrorMessage,
  setSelectedLanguagesArr,
  selectedLanguagesArr,
  addLanguageAttribute,
  removeLanguageAttribute,
  switchLanguageAttribute,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [expandAllPanels, setExpandAllPanels] = useState(true);

  function handleFieldFound(item, values) {
    let found = false;
    Object.entries(values)?.map(([keyOne, valueOne]) => {
      if (
        valueOne?.hasOwnProperty("depandant") &&
        valueOne?.depandant?.length > 0
      ) {
        valueOne?.depandant?.map((itemm) => {
          if (itemm?.key === item) {
            found = true;
          }
        });
      }
    });
    return found;
  }

  const handleCloseBox = () => {
    setOpenConfirmBox(false);
    setAttributesLoading(true);
    setSelectedCategoryName(nextCategory);
    setTouchedValue(false);
    setLocationCategoriesData(allAttributes);
    const locationCategories = {
      ...allAttributes,
    };

    const allFurtherCategories = Object.entries(
      locationCategories[nextCategory]
    );
    if (locationCategories[nextCategory]?.hasOwnProperty("type")) {
      let objOne = {
        name: nextCategory,
        type: locationCategories[nextCategory]?.type,
        value: locationCategories[nextCategory]?.value,
      };
      setAttributesWithCategories(objOne);
    }

    if (locationCategories[nextCategory].isEditable === false) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }

    let tempObj = {};
    tempObj[`${nextCategory}`] = locationCategories[nextCategory];
    setCategoriesData(tempObj);
    setDisplayChildCategories(true);
    setNextCategory(null);
    setTimeout(() => {
      setAttributesLoading(false);
    }, 1500);
  };

  const handleOpenCategory = (selectedCategory) => {
    // if (touchedValue === true) {
    //   setNextCategory(selectedCategory);
    //   // setOpenConfirmBox(true);
    // } else {
    // }
    setAttributesLoading(true);
    setSelectedCategoryName(selectedCategory);
    setLocationCategoriesData(allAttributes);

    let objjj = JSON.stringify(allAttributes);

    const locationCategories = JSON.parse(objjj);
    setLocationCategoriesData(locationCategories);
    const allFurtherCategories = Object.entries(
      locationCategories[selectedCategory]
    );
    if (locationCategories[selectedCategory]?.hasOwnProperty("type")) {
      let objOne = {
        name: selectedCategory,
        type: locationCategories[selectedCategory]?.type,
        value: locationCategories[selectedCategory]?.value,
      };
      setAttributesWithCategories(objOne);
    }

    if (locationCategories[selectedCategory].isEditable === false) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }

    let tempObj = {};
    tempObj[`${selectedCategory}`] = locationCategories[selectedCategory];
    setCategoriesData(tempObj);
    setDisplayChildCategories(true);

    setTimeout(() => {
      setAttributesLoading(false);
    }, 1500);
    setErrors([]);
    setErrorMessage([]);
  };

  return (
    <>
      {openConfirmBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={openConfirmBox}
          onClose={handleCloseBox}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateAttributesWithCategories}
            onCancel={handleCloseBox}
            location={location}
            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container>
          <Typography className={classes.attributesModalHeaderBulk}>
            {t("attributes_categories")}
          </Typography>
          <Grid container sx={{ marginTop: "6px" }}>
            {Object.entries(allAttributes)?.map(([key, value]) => {
              return (
                key !== "updateMask" &&
                key !== "name" &&
                key !== "metadata" && (
                  <Item
                    onClick={() => handleOpenCategory(key)}
                    className={classes.attributesItem}
                    key={key}
                    style={{
                      background:
                        allAttributes[key].isEditable === false
                          ? "#F0F0F0"
                          : selectedCategoryName !== null &&
                            selectedCategoryName === key &&
                            "#06BDFF",
                      color:
                        allAttributes[key].isEditable === false
                          ? "#1B2430"
                          : selectedCategoryName !== null &&
                            selectedCategoryName === key &&
                            allAttributes[key].isEditable === true
                          ? "#FFFFFF"
                          : "#1B2430",
                      border:
                        allAttributes[key].isEditable === false
                          ? "none"
                          : selectedCategoryName !== null &&
                            selectedCategoryName === key
                          ? "1px solid #06BDFF"
                          : "1px solid #EBEBEC",
                    }}
                  >
                    <Typography
                      className={classes.attributesItemText}
                      sx={{
                        color:
                          allAttributes[key].isEditable === false
                            ? "#1B2430"
                            : selectedCategoryName !== null &&
                              selectedCategoryName === key &&
                              allAttributes[key].isEditable === true
                            ? "#FFFFFF"
                            : "#1B2430",
                      }}
                    >
                      {key
                        ?.replace(/([A-Z])/g, " $1")
                        ?.replace(/^./, (str) => str.toUpperCase())
                        ?.trim()}
                    </Typography>
                  </Item>
                )
              );
            })}
          </Grid>
        </Grid>
        {selectedCategoryName !== null && (
          <Grid container className={classes.attributesHeadingContainer}>
            <Typography className={classes.attributesModalHeaderBulk}>
              {selectedCategoryName !== null
                ? selectedCategoryName
                    ?.replace(/([A-Z])/g, " $1")
                    ?.replace(/^./, (str) => str.toUpperCase())
                    ?.trim()
                : ""}
            </Typography>
          </Grid>
        )}

        {errors?.length > 0 && (
          <Grid container className={classes.mainContainer}>
            <Grid
              item
              xs={0.5}
              sm={0.5}
              md={0.5}
              lg={0.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <ErrorOutlineIcon className={classes.errorIcon} />
            </Grid>
            <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {errors?.map((item, index) => (
                  <Typography key={index} className={classes.errorText1}>
                    {"•" +
                      " " +
                      "Please Add " +
                      "'" +
                      item
                        ?.replace(/([A-Z])/g, " $1")
                        ?.replace(/^./, (str) => str.toUpperCase())
                        ?.trim() +
                      "'"}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        {errorMessage?.length > 0 && (
          <Grid container className={classes.mainContainer}>
            <Grid
              item
              xs={0.5}
              sm={0.5}
              md={0.5}
              lg={0.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <ErrorOutlineIcon className={classes.errorIcon} />
            </Grid>
            <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {errorMessage?.map((item, index) => (
                  <Typography key={index} className={classes.errorText1}>
                    {"•" + " " + item}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        {attributesLoading ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                width: "100%",
                padding: "18px",
                paddingTop: "50px",
                // width: "550px",
              }}
            >
              <Loader />
            </Grid>
          </>
        ) : (
          displayChildCategories === true &&
          selectedCategoryName !== null && (
            <Grid container className={classes.attributesCategoryContainer}>
              {locationCategoriesData[selectedCategoryName]?.hasOwnProperty(
                "type"
              ) ? (
                <Grid container className={classes.lodgingTextContainer}>
                  <AttributeItem
                    displayName={selectedCategoryName}
                    dataValues={locationCategoriesData[selectedCategoryName]}
                    handleChangeChecked={handleChangeChecked}
                    addMultiAttribute={addMultiAttribute}
                    removeMultiAttribute={removeMultiAttribute}
                    handleChangeStringValue={handleChangeStringValue}
                    handleChangeIntegerValue={handleChangeIntegerValue}
                    handleChangeFloatValue={handleChangeFloatValue}
                    handleChangeDateValue={handleChangeDateValue}
                    handleChangeEnum={handleChangeEnum}
                    isEditable={isEditable}
                    setIntegerError={setIntegerError}
                    integerError={integerError}
                    setSelectedLanguagesArr={setSelectedLanguagesArr}
                    selectedLanguagesArr={selectedLanguagesArr}
                  />
                </Grid>
              ) : (
                Object.entries(
                  locationCategoriesData[selectedCategoryName]
                )?.map(([keyOne, valueOne]) => {
                  return (
                    <Grid container className={classes.lodgingTextContainer}>
                      {valueOne?.hasOwnProperty("type") ||
                      valueOne?.hasOwnProperty("hours") ? (
                        <AttributeItem
                          displayName={keyOne}
                          dataValues={valueOne}
                          parent={selectedCategoryName}
                          handleChangeChecked={handleChangeChecked}
                          addMultiAttribute={addMultiAttribute}
                          removeMultiAttribute={removeMultiAttribute}
                          handleChangeStringValue={handleChangeStringValue}
                          handleChangeIntegerValue={handleChangeIntegerValue}
                          handleChangeFloatValue={handleChangeFloatValue}
                          handleChangeDateValue={handleChangeDateValue}
                          handleChangeEnum={handleChangeEnum}
                          isEditable={isEditable}
                          setIntegerError={setIntegerError}
                          integerError={integerError}
                          setSelectedLanguagesArr={setSelectedLanguagesArr}
                          selectedLanguagesArr={selectedLanguagesArr}
                        />
                      ) : (
                        <Grid
                          container
                          // className={classes.lodgingContainerFour}
                        >
                          {Array.isArray(valueOne) && keyOne === "codes" ? (
                            <AttributeItem
                              displayName={keyOne}
                              dataValues={valueOne[0]}
                              parent={selectedCategoryName}
                              handleChangeChecked={handleChangeChecked}
                              addMultiAttribute={addMultiAttribute}
                              removeMultiAttribute={removeMultiAttribute}
                              handleChangeStringValue={handleChangeStringValue}
                              handleChangeIntegerValue={
                                handleChangeIntegerValue
                              }
                              handleChangeFloatValue={handleChangeFloatValue}
                              handleChangeDateValue={handleChangeDateValue}
                              handleChangeEnum={handleChangeEnum}
                              isEditable={isEditable}
                              setIntegerError={setIntegerError}
                              integerError={integerError}
                              setSelectedLanguagesArr={setSelectedLanguagesArr}
                              selectedLanguagesArr={selectedLanguagesArr}
                            />
                          ) : keyOne === "languagesSpoken" ? (
                            <>
                              <Typography
                                className={classes.attributesSubHeading}
                              >
                                {" "}
                                {keyOne
                                  ?.replace(/([A-Z])/g, " $1")
                                  .replace(/^./, (str) => str.toUpperCase())
                                  .trim()}
                              </Typography>

                              <Grid container>
                                <AttributeItem
                                  displayName={keyOne}
                                  dataValues={
                                    locationCategoriesData[
                                      selectedCategoryName
                                    ][keyOne]
                                  }
                                  parent={selectedCategoryName}
                                  handleChangeChecked={handleChangeChecked}
                                  addMultiAttribute={addMultiAttribute}
                                  removeMultiAttribute={removeMultiAttribute}
                                  handleChangeStringValue={
                                    handleChangeStringValue
                                  }
                                  handleChangeIntegerValue={
                                    handleChangeIntegerValue
                                  }
                                  handleChangeFloatValue={
                                    handleChangeFloatValue
                                  }
                                  handleChangeDateValue={handleChangeDateValue}
                                  handleChangeEnum={handleChangeEnum}
                                  isEditable={isEditable}
                                  setIntegerError={setIntegerError}
                                  integerError={integerError}
                                  setSelectedLanguagesArr={
                                    setSelectedLanguagesArr
                                  }
                                  selectedLanguagesArr={selectedLanguagesArr}
                                  addLanguageAttribute={addLanguageAttribute}
                                  removeLanguageAttribute={
                                    removeLanguageAttribute
                                  }
                                  switchLanguageAttribute={
                                    switchLanguageAttribute
                                  }
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              {keyOne !== "isEditable" && (
                                <>
                                  <Typography
                                    className={classes.attributesSubHeading}
                                  >
                                    {" "}
                                    {keyOne
                                      ?.replace(/([A-Z])/g, " $1")
                                      .replace(/^./, (str) => str.toUpperCase())
                                      .trim()}
                                  </Typography>

                                  <Grid
                                    container
                                    className={classes.lodgingContainerFour}
                                  >
                                    {Object.entries(valueOne)?.map(
                                      ([keyTwo, valueTwo]) => {
                                        return valueTwo?.hasOwnProperty(
                                          "type"
                                        ) ? (
                                          <Grid
                                            container
                                            className={
                                              classes.lodgingContainerFour
                                            }
                                          >
                                            <AttributeItem
                                              displayName={keyTwo}
                                              parent={keyOne}
                                              dataValues={valueTwo}
                                              subParent={selectedCategoryName}
                                              addMultiAttribute={
                                                addMultiAttribute
                                              }
                                              removeMultiAttribute={
                                                removeMultiAttribute
                                              }
                                              handleChangeChecked={
                                                handleChangeChecked
                                              }
                                              handleChangeStringValue={
                                                handleChangeStringValue
                                              }
                                              handleChangeIntegerValue={
                                                handleChangeIntegerValue
                                              }
                                              handleChangeFloatValue={
                                                handleChangeFloatValue
                                              }
                                              handleChangeDateValue={
                                                handleChangeDateValue
                                              }
                                              handleChangeEnum={
                                                handleChangeEnum
                                              }
                                              isEditable={isEditable}
                                              setIntegerError={setIntegerError}
                                              integerError={integerError}
                                            />
                                          </Grid>
                                        ) : (
                                          <Grid container>
                                            {keyTwo !== "0" && (
                                              <Typography
                                                className={
                                                  classes.attributesSubHeading
                                                }
                                              >
                                                {keyTwo
                                                  ?.replace(/([A-Z])/g, " $1")
                                                  .replace(/^./, (str) =>
                                                    str.toUpperCase()
                                                  )
                                                  .trim()}
                                              </Typography>
                                            )}
                                            <Grid
                                              container
                                              className={
                                                classes.lodgingContainerFour
                                              }
                                            >
                                              {Array.isArray(valueTwo)
                                                ? valueTwo?.map((itemFour) =>
                                                    Object.entries(
                                                      itemFour
                                                    )?.map((itemFive) => {
                                                      return (
                                                        <Grid
                                                          container
                                                          /*  className={
                                                            classes.lodgingContainerFour
                                                          } */
                                                        >
                                                          <AttributeItem
                                                            displayName={
                                                              itemFive[0]
                                                            }
                                                            parent={keyOne}
                                                            dataValues={
                                                              itemFive[1]
                                                            }
                                                            subParent={
                                                              selectedCategoryName
                                                            }
                                                            grandParent={keyTwo}
                                                            addMultiAttribute={
                                                              addMultiAttribute
                                                            }
                                                            removeMultiAttribute={
                                                              removeMultiAttribute
                                                            }
                                                            handleChangeChecked={
                                                              handleChangeChecked
                                                            }
                                                            handleChangeStringValue={
                                                              handleChangeStringValue
                                                            }
                                                            handleChangeIntegerValue={
                                                              handleChangeIntegerValue
                                                            }
                                                            handleChangeFloatValue={
                                                              handleChangeFloatValue
                                                            }
                                                            handleChangeDateValue={
                                                              handleChangeDateValue
                                                            }
                                                            handleChangeEnum={
                                                              handleChangeEnum
                                                            }
                                                            isEditable={
                                                              isEditable
                                                            }
                                                            setIntegerError={
                                                              setIntegerError
                                                            }
                                                            integerError={
                                                              integerError
                                                            }
                                                          />
                                                        </Grid>
                                                      );
                                                    })
                                                  )
                                                : Object.entries(valueTwo)?.map(
                                                    ([
                                                      keyThree,
                                                      valueThree,
                                                    ]) => {
                                                      return (
                                                        <Grid
                                                          container
                                                          className={
                                                            classes.lodgingContainerFour
                                                          }
                                                        >
                                                          {valueThree.hasOwnProperty(
                                                            "type"
                                                          ) ? (
                                                            <AttributeItem
                                                              displayName={
                                                                keyThree
                                                              }
                                                              parent={keyOne}
                                                              dataValues={
                                                                valueThree
                                                              }
                                                              subParent={
                                                                selectedCategoryName
                                                              }
                                                              grandParent={
                                                                keyTwo
                                                              }
                                                              addMultiAttribute={
                                                                addMultiAttribute
                                                              }
                                                              removeMultiAttribute={
                                                                removeMultiAttribute
                                                              }
                                                              handleChangeChecked={
                                                                handleChangeChecked
                                                              }
                                                              handleChangeStringValue={
                                                                handleChangeStringValue
                                                              }
                                                              handleChangeIntegerValue={
                                                                handleChangeIntegerValue
                                                              }
                                                              handleChangeFloatValue={
                                                                handleChangeFloatValue
                                                              }
                                                              handleChangeDateValue={
                                                                handleChangeDateValue
                                                              }
                                                              handleChangeEnum={
                                                                handleChangeEnum
                                                              }
                                                              isEditable={
                                                                isEditable
                                                              }
                                                              setIntegerError={
                                                                setIntegerError
                                                              }
                                                              integerError={
                                                                integerError
                                                              }
                                                            />
                                                          ) : (
                                                            <>
                                                              {" "}
                                                              <Typography
                                                                className={
                                                                  classes.attributesSubHeading
                                                                }
                                                              >
                                                                {keyThree
                                                                  ?.replace(
                                                                    /([A-Z])/g,
                                                                    " $1"
                                                                  )
                                                                  .replace(
                                                                    /^./,
                                                                    (str) =>
                                                                      str.toUpperCase()
                                                                  )
                                                                  .trim()}
                                                              </Typography>
                                                              {Object.entries(
                                                                valueThree
                                                              )?.map(
                                                                ([
                                                                  itemFour,
                                                                  valueFour,
                                                                ]) => {
                                                                  return (
                                                                    <>
                                                                      <Grid
                                                                        container
                                                                        className={
                                                                          classes.lodgingContainerFour
                                                                        }
                                                                      >
                                                                        <AttributeItem
                                                                          displayName={
                                                                            itemFour
                                                                          }
                                                                          parent={
                                                                            keyThree
                                                                          }
                                                                          dataValues={
                                                                            valueFour
                                                                          }
                                                                          subParent={
                                                                            selectedCategoryName
                                                                          }
                                                                          grandParent={
                                                                            keyTwo
                                                                          }
                                                                          subChild={
                                                                            keyOne
                                                                          }
                                                                          addMultiAttribute={
                                                                            addMultiAttribute
                                                                          }
                                                                          removeMultiAttribute={
                                                                            removeMultiAttribute
                                                                          }
                                                                          handleChangeChecked={
                                                                            handleChangeChecked
                                                                          }
                                                                          handleChangeStringValue={
                                                                            handleChangeStringValue
                                                                          }
                                                                          handleChangeIntegerValue={
                                                                            handleChangeIntegerValue
                                                                          }
                                                                          handleChangeFloatValue={
                                                                            handleChangeFloatValue
                                                                          }
                                                                          handleChangeDateValue={
                                                                            handleChangeDateValue
                                                                          }
                                                                          handleChangeEnum={
                                                                            handleChangeEnum
                                                                          }
                                                                          isEditable={
                                                                            isEditable
                                                                          }
                                                                          setIntegerError={
                                                                            setIntegerError
                                                                          }
                                                                          integerError={
                                                                            integerError
                                                                          }
                                                                        />
                                                                      </Grid>
                                                                    </>
                                                                  );
                                                                }
                                                              )}
                                                            </>
                                                          )}
                                                        </Grid>
                                                      );
                                                    }
                                                  )}
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                </>
                              )}
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};

export default AttributesModal;
