import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  Tabs: {
    marginTop: "30px",
    backgroundColor: "white",
    paddingY: "8px",
  },
  stepLocation1: {
    fontSize: "30px",
    fontWeight: "700",
    color: "#1B2430",
    textAlign: "center",
    marginTop: "10px",
  },
  emojiRating: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingY: "16px",
  },
  emojiSize: {
    width: "15%",
  },
  feedbackNote: {
    fontSize: "22px",
    fontWeight: "700",
    color: "#1B2430",
    marginTop: "8px",
    marginBottom: "8px",
  },
  stepLocation: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
    color: "#1B2430",
  },
  stepLocationSubhead: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#495059",
    marginBottom: "12px",
  },
  stepLocationSubhead1: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#495059",
    marginBottom: "8px",
    marginTop: "8px",
    lineHeight: "28px",
  },
  socialBox: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    display: "flex",
    padding: "12px",
    marginY: "2px",
    alignItems: "center",
    width: "100%",
    height: "88px",
  },
  socialBoxUsers: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    display: "flex",
    padding: "12px",
    marginY: "2px",
    alignItems: "center",
    width: "100%",
    height: "60px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",

    "&:hover": {
      backgroundColor: "#f5fcff",
    },
  },
  socialBoxfirst: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    display: "flex",
    padding: "12px",
    marginY: "2px",
    gap: "0.5rem",
    alignItems: "center",
    width: "220px",
    marginRight: "8px",
  },
  socialBoxFeedbackStep3: {
    flex: 1,
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    padding: "10px",
    justifyContent: "space-between",
    margin: "8px",
  },

  socialBoxFeedback: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    display: "flex",
    padding: "10px",
    // height: "116px",
    gap: "0.5rem",
    alignItems: "center",
  },
  selectionText: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#1B2430",
  },
  selectText: {
    fontSize: "18px",
    fontWeight: "700",
    color: "#1B2430",
  },
  selectsubText: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#495059",
  },
  formUpload: {
    height: "16rem",
    width: "28rem",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
  },
  lableFileUpload: {
    height: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // borderWidth: "2px",
    // borderRadius: "1rem",
    // borderStyle: "dashed",
    // borderColor: "#cbd5e1",
    // backgroundColor: "#f8fafc",
  },

  mainStep2Box: {
    paddingY: "16px",
    paddingX: "16px",
    textAlign: "center",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
  },
  emojiBoxText: {
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "700",
    // padding: "8px",
    // marginX: "4px",
    textAlign: "center",
  },
  emojiBox1: {
    borderRadius: "4px",
    backgroundColor: "#1FDE3D",

    height: "122px",
  },
  emojiBox2: {
    borderRadius: "4px",
    backgroundColor: "#96DE1F",
    textAlign: "center",
    height: "122px",
  },
  emojiBox4: {
    borderRadius: "4px",
    backgroundColor: "#FF7E35",
    textAlign: "center",
    height: "122px",
  },
  emojiBox3: {
    borderRadius: "4px",
    backgroundColor: "#FFCC15",
    textAlign: "center",
    height: "122px",
  },
  stepMainbox: {
    paddingY: "16px",
    paddingX: "16px",
    textAlign: "center",
    border: "1px solid #E0E0E0",
    marginTop: "8px",
    borderRadius: "8px",
  },
  dragFile: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  stepLocationFormText: {
    fontSize: "14px",
    color: "#1B2430",
    fontWeight: "700",
    lineHeight: "20px",
    marginBottom: "8px",
  },
  stepLocationFormTextReview: {
    fontSize: "22px",
    color: "#1B2430",
    fontWeight: "700",
    lineHeight: "40px",
  },
  socialBoxImage: {
    width: "56px",
    marginY: "22px",
    paddingX: "4px",
    paddingTop: "10px",
    // width: "40px",
    //  height: "122px",
  },
  reviewsubHeadText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "4px",
    marginLeft: "6px",
  },
  reviewsubHeadTextError: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "4px",
    marginLeft: "6px",
    color: "#FF0000",
  },
  reviewsubHeadBox: {
    paddingY: "16px",
    backgroundColor: "#E0F7FF",
    borderRadius: "8px",
  },
  pictureModalButton: {
    position: "absolute",
    textAlign: "center",
    right: "3%",
    top: "6%",
    fontWeight: "700",
  },
  cropperButton: {
    borderColor: "#3c98eb",
    fontWeight: "600",
    borderRadius: "8px",
    fontSize: "1rem",
    lineHeight: "1.5",
    padding: "0.5rem 1rem",
    marginLeft: "4px",
    height: "50",
  },
  imageCrossIcon2: {
    position: "absolute",
    top: "5px",
    width: "5px",
    height: "5px",
    right: "5px",
    padding: "10px",
    backgroundColor: "#495059",
    "&:hover": {
      backgroundColor: "#495059",
    },
    cursor: "pointer",
  },
  formitableBox: {
    width: "100%",
    height: "48px",
    // paddingY: "26px",
    backgroundColor: "#E0F7FF",
    paddingLeft: "24px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
  },
  formitableBoxDisabled: {
    width: "100%",
    height: "48px",
    // paddingY: "26px",
    background: "#E0E0E0",
    paddingLeft: "24px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
  },
  copyBox: {
    display: "flex",
    border: "1px solid #06BDFF",
    borderRadius: "8px",
    cursor: "pointer",
    padding: "5px",
    marginLeft: "12px",
  },
  copyBoxDisabled: {
    display: "flex",
    border: "1px solid gray",
    borderRadius: "8px",
    cursor: "pointer",
    padding: "5px",
    marginLeft: "12px",
  },
  copyTextStyle: {
    color: "#06BDFF",
    paddingLeft: "5px",
    fontWeight: 700,
  },
  copyTextStyleDisabled: {
    color: "gray !important",
    paddingLeft: "5px",
    fontWeight: 700,
  },
  copyIconStyle: {
    color: "#06BDFF",
  },
  checkConnectionButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "170px",
    height: "48px",
    background: "#06BDFF",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "12px",
    color: "#FFFFFF",
    "&:hover ": {
      background: "#06BDFF",
      borderRadius: "8px",
      color: "#FFFFFF",
    },
  },
  checkConnectionButtonDisabled: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "170px",
    height: "48px",
    background: "#E0E0E0",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "12px",
    color: "#FFFFFF",
    "&:hover ": {
      background: "#E0E0E0",
      borderRadius: "8px",
      color: "#FFFFFF",
    },
  },

  connectedButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "134px",
    height: "48px",
    background: "#13CF8F",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "12px",
    color: "#FFFFFF",
    "&:hover ": {
      background: "#13CF8F",
      borderRadius: "8px",
      color: "#FFFFFF",
    },
  },
  connectedButtonDisabled: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "134px",
    height: "48px",
    background: "#E0E0E0",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "12px",
    color: "#FFFFFF",
    "&:hover ": {
      background: "#E0E0E0",
      borderRadius: "8px",
      color: "#FFFFFF",
    },
  },
  mainContainer: {
    background: "#FFF1D7",
    padding: "6px 16px",
    borderRadius: "7px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  errorIcon: {
    color: "#F8A92B",
    marginTop: "5px",
  },
  errorText: {
    color: "#330404",
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.43",
    padding: "6px 0px",
    // marginTop: "5px",
  },
  closeIcon: {
    color: "#330404",
    cursor: "pointer",
    marginTop: "3px",
  },
  mainContainerUser: {
    background: "#FFD0D0",
    padding: "6px 16px",
    borderRadius: "7px",
    marginBottom: "10px",
    marginTop: "13px",
  },
  errorTextUser: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.43",
    color: "#330404",
    padding: "6px 0px",
  },
  errorIconUser: {
    color: "#FF1616",
    marginTop: "5px",
  },
  cardbox: {
    backgroundColor: "#F6F6F7",
    borderRadius: "none",
    height: "auto",
  },
  containerSpacing: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  mainContainer: {
    padding: "24px 14px 24px 14px",
  },
  counters: {
    color: "#06BDFF",
    fontSize: "25px",
    fontWeight: 700,
  },
  counterText: {
    color: "#495059",
  },
  viewMoreFilterTypo: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
  },
  faqModelTitle: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "20px",
    color: "#1b2430",
  },
  typoOwner: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
  },
  typoDate: {
    lineHeight: "28px",
    fontSize: "14px",
    color: "#495059",
  },
  questionTypo: {
    lineHeight: "28px",
    fontSize: "14px",
    color: "#1B2430",
  },
  actionsClass: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "8px",
    marginRight: "16px",
  },
  mainContainerFormitable: {
    background: "#FFF1D7",
    padding: "6px 16px",
    borderRadius: "7px",
    marginBottom: "10px",
    marginTop: "10px",
  },
}));
