import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TextField,
} from "@mui/material";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { api } from "../../../../../../../contexts/JWTContext";
import Loader from "../../../../../../../components/Loaders/Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useStyles } from "../../../../Styles/style";

const FetchModel = ({
  title,
  subTitle,
  open,
  handleCloseAddressChangeModel,
  isFullWidth,
  isLoading,
  locationDetailsData,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [addressLineRequired, setAddressLineRequired] = useState(false);
  const [administrativeAreaRequired, setAdministrativeArea] = useState(false);
  const [postalCodeRequired, setPostalCodeRequired] = useState(false);
  const [regionCodeRequired, setRegionCodeRequired] = useState(false);
  const [localityRequired, setLocalityRequired] = useState(false);

  const [addressInfo, setAddressInfo] = useState({
    addressLine: "",
    administrativeArea: "",
    postalCode: "",
    regionCode: "",
    locality: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAddressInfo({
      addressLine:
        locationDetailsData?.result?.google?.storefrontAddress?.addressLines
          ?.length > 0
          ? locationDetailsData?.result?.google?.storefrontAddress
              ?.addressLines[0]
          : "",
      administrativeArea: locationDetailsData?.result?.google?.storefrontAddress
        ?.administrativeArea
        ? locationDetailsData?.result?.google?.storefrontAddress
            ?.administrativeArea
        : "",
      postalCode: locationDetailsData?.result?.google?.storefrontAddress
        ?.postalCode
        ? locationDetailsData?.result?.google?.storefrontAddress?.postalCode
        : "",
      regionCode: locationDetailsData?.result?.google?.storefrontAddress
        ?.regionCode
        ? locationDetailsData?.result?.google?.storefrontAddress?.regionCode
        : "",
      locality: locationDetailsData?.result?.google?.storefrontAddress?.locality
        ? locationDetailsData?.result?.google?.storefrontAddress?.locality
        : "",
    });
  }, [open]);

  const handleRequestAddressChange = async () => {
    if (addressInfo?.addressLine === "") {
      setAddressLineRequired(true);
    }
    if (addressInfo?.administrativeArea === "") {
      setAdministrativeArea(true);
    }
    if (addressInfo?.postalCode === "") {
      setPostalCodeRequired(true);
    }
    if (addressInfo?.regionCode === "") {
      setRegionCodeRequired(true);
    }
    if (addressInfo?.locality === "") {
      setLocalityRequired(true);
    }

    if (
      addressInfo?.addressLine !== "" &&
      addressInfo?.administrativeArea !== "" &&
      addressInfo?.postalCode !== "" &&
      addressInfo?.regionCode !== "" &&
      addressInfo?.locality !== ""
    ) {
      try {
        setLoading(true);
        const res = await api.patch(`user/approveUser`, {
          addressLine: addressInfo?.addressLine,
          administrativeArea: addressInfo?.administrativeArea,
          postalCode: addressInfo?.postalCode,
          regionCode: addressInfo?.regionCode,
          locality: addressInfo?.locality,
        });
        if (res.status === 201) {
          toast.success(res?.data?.message);
          handleCloseAddressChangeModel();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        handleCloseAddressChangeModel();
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <div>
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", height: "600px" } }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogTitle>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  lineHeight: "28px",
                  fontWeight: 700,
                  fontSize: "20px",
                  color: "#1b2430",
                }}
              >
                {title}
              </Typography>
            </Box>
          </DialogTitle>

          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid container>
                <Grid item sm={12} md={12} lg={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Grid
                      container
                      sx={{ width: !isFullWidth ? "450px" : "100%" }}
                    >
                      <Typography className={classes.nameLabel}>
                        {t("Addres_Line")}
                      </Typography>
                      <TextField
                        // disabled={loading}

                        type="text"
                        name="firstName"
                        placeholder={t("Addres_Line")}
                        value={addressInfo?.addressLine}
                        error={addressLineRequired}
                        fullWidth
                        helperText={addressLineRequired && t("Addre_line_req")}
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            addressLine: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            setAddressLineRequired(false);
                          } else {
                            setAddressLineRequired(true);
                          }
                        }}
                      />
                      <Typography className={classes.nameLabel}>
                        {t("Administrative_Area")}
                      </Typography>
                      <TextField
                        // disabled={loading}

                        type="text"
                        name="lastName"
                        autoComplete="off"
                        placeholder={t("Administrative_Area")}
                        inputProps={{ autoComplete: "off" }}
                        value={addressInfo?.administrativeArea}
                        fullWidth
                        error={administrativeAreaRequired}
                        helperText={
                          administrativeAreaRequired &&
                          t("Administrative_Area_req")
                        }
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            administrativeArea: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            setAdministrativeArea(false);
                          } else {
                            setAdministrativeArea(true);
                          }
                        }}
                      />

                      <Typography className={classes.nameLabel}>
                        {t("Postal_Code")}
                      </Typography>
                      <TextField
                        type="text"
                        placeholder={t("Postal_Code")}
                        defaultValue=""
                        autoComplete="none"
                        fullWidth
                        value={addressInfo?.postalCode}
                        error={postalCodeRequired}
                        helperText={postalCodeRequired && t("postal_req")}
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            postalCode: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            setPostalCodeRequired(false);
                          } else {
                            setPostalCodeRequired(true);
                          }
                        }}
                      />

                      <Typography className={classes.nameLabel}>
                        {t("Region_Code")}
                      </Typography>
                      <TextField
                        // disabled={loading}

                        type="text"
                        placeholder={t("Region_Code")}
                        defaultValue=""
                        autoComplete="off"
                        // inputProps={{
                        //   autoComplete: "none",
                        // }}
                        value={addressInfo?.regionCode}
                        error={regionCodeRequired}
                        fullWidth
                        helperText={regionCodeRequired && t("Region_Code_Req")}
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            regionCode: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            setRegionCodeRequired(false);
                          } else {
                            setRegionCodeRequired(true);
                          }
                        }}
                      />
                      <Typography className={classes.nameLabel}>
                        {t("Locality")}
                      </Typography>
                      <TextField
                        // disabled={loading}

                        type="text"
                        placeholder={t("Locality")}
                        value={addressInfo?.locality}
                        fullWidth
                        error={localityRequired}
                        helperText={localityRequired && t("Locality_Req")}
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            locality: e.target.value,
                          });
                          if (e.target.value?.length > 0) {
                            setLocalityRequired(false);
                          } else {
                            setLocalityRequired(true);
                          }
                        }}
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <CommonButton
              displayWhite="true"
              onSubmit={handleCloseAddressChangeModel}
              label={t("Cancel")}
            />

            <CommonButton
              // isLoading={loading}
              type="submit"
              label={t("Save")}
              onSubmit={handleRequestAddressChange}
            />
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
